import defineComponent from '../../../../components/flight/lib/component';
import DataTable from './../../table/data-table-net/data-table-net.tsx';
import Product from '../../constants/products';
import SubProduct from '../../constants/subProducts';
import axios from "axios";
import SantaType from "../../constants/santaType";

export default defineComponent(ProductMetaDataDataTable);

function ProductMetaDataDataTable() {

    this.attributes({
        url: "/api/admin/metadata/product",
        dataTableSelector: '[data-type="data-table"]'
    });

    this.createSchema = function () {
        const cols = [];
        cols.push({
            title: "Name",
            type: "text",
            data: "productName"
        });
        cols.push({
            title: "Product",
            type: "text",
            data: "product"
        });
        cols.push({
            title: "Sub Product",
            type: "badge",
            data: "subProductBadge"
        });
        cols.push({
            title: "Santa Type",
            type: "badge",
            data: "santaTypeBadge"
        });
        cols.push({
            title: "",
            type: "multiButton",
            width: "180px",
            data: "actions"
        });
        return cols;
    };


    this.paint = function () {
        const data = {
            columns: this.createSchema(),
            response: this.normalize(this.data)
        };

        if (this.dataReadyFired) {
            window.dispatchEvent(new CustomEvent('uiDataUpdate', { detail: data }));
        } else {
            this.dataReadyFired = true;
            this.trigger("uiDataReady", data);
        }
    };

    this.normalize = function (list) {
        return list.map(x => this.normalizeMetaData(x));
    };

    this.normalizeMetaData = function (metadata_) {
        const metadata = $.extend(true, {}, metadata_);
        metadata.product = Object.keys(Product).find(key => Product[key] === metadata.productId);
        metadata.actions = [
            {
                href: `/admin/product/metadata/${metadata.productId}/${metadata.subProductId}/${metadata.santaType}/edit`,
                caption: "Edit",
                className: "btn btn-primary",
                iconClassname: "bi bi-pencil",
                target: "_self"
            }
        ];
        metadata.subProductBadge = {
            label: Object.keys(SubProduct).find(key => SubProduct[key] === metadata.subProductId),
            colour: SubProduct.getBadgeColour(metadata.subProductId)
        };
        metadata.santaTypeBadge = {
            label: metadata.santaType,
            colour: SantaType.getBadgeColour(metadata.santaType)
        };
        return metadata;
    };

    this.getData = function () {
        axios.get(this.attr.url)
            .then(response => {
                this.data = response.data;
                this.paint();
                setTimeout(() => this.getData(), 1000 * 60 * 15);
            }).catch(error => this.trigger("log", {message: error}));
    };

    this.after('initialize', function () {
        DataTable.attachTo(this.select('dataTableSelector'));
        setTimeout(() => this.getData(), 10);
    });
}