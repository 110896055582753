import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import { Model, createDropdown, addListToDropdown } from 'ckeditor5/src/ui';
import { Collection } from 'ckeditor5/src/utils';
import axios from 'axios';
import WidgetEditing from "./widget-editing";
import CogIcon from '@ckeditor/ckeditor5-core/theme/icons/cog.svg';

export default class WidgetPlugin extends Plugin {

    static get requires() {
        return [WidgetEditing];
    }

    init() {
        const editor = this.editor;
        const t = editor.t;

        const accessibleLabel = t('Widget');

        // Register UI component.
        return this.getAvailableWidgets()
            .then((widgetOptions) => {

                editor.ui.componentFactory.add('widget', locale => {
                    const titles = {};
                    const itemDefinitions = new Collection();

                    const widgetCommand = editor.commands.get('widget');
                    const updateWidgetConfigCommand = editor.commands.get('updateWidgetConfig');
                    const commands = [];
                    for (const option of widgetOptions) {
                        const def = {
                            type: 'button',
                            model: new Model({
                                label: option.title,
                                name: option.name,
                                class: option.class,
                                configurationProperties: option.configurationProperties,
                                role: 'menuitemradio',
                                withText: true,
                            })
                        };

                        def.model.bind('isOn').to(widgetCommand, 'value', value => value === option.model);
                        def.model.set({
                            commandName: 'widget',
                            commandValue: option
                        });
                        commands.push(widgetCommand);

                        // Add the option to the collection.
                        itemDefinitions.add(def);
                        titles[option.model] = option.title;
                    }
                    const dropdownView = createDropdown(locale);
                    addListToDropdown(dropdownView, itemDefinitions, {
                        ariaLabel: accessibleLabel,
                        role: 'menu'
                    });
                    dropdownView.buttonView.set({
                        label: accessibleLabel,
                        icon: CogIcon,
                        ariaLabel: accessibleLabel,
                        ariaLabelledBy: undefined,
                        isOn: false,
                        withText: true,
                        tooltip: accessibleLabel
                    });
                    dropdownView.bind('isEnabled').toMany(commands, 'isEnabled', (...areEnabled) => {
                        return areEnabled.some(isEnabled => isEnabled);
                    });

                    this.listenTo(dropdownView, 'execute', evt => {
                        const { commandName, commandValue } = evt.source;
                        editor.execute(commandName, commandValue ? { value: commandValue } : undefined);
                        editor.editing.view.focus();
                    });

                    return dropdownView;
                });
            });
    }

    getAvailableWidgets() {
        const widgetListUrl = '/admin/api/ckeditor/widgets';

        return new Promise((resolve, reject) => {
            axios.get(widgetListUrl)
                .then(response => {
                    const availableWidgets = response.data.map(widgetOption => ({
                        model: 'widget',
                        title: widgetOption.label,
                        name: widgetOption.name,
                        configurationProperties: widgetOption.configurationProperties,
                        class: 'ck-widget'
                    }));
                    resolve(availableWidgets);
                })
                .catch(error => {
                    console.log('Error Retrieving Widget Options: ' + error);
                    reject(error);
                });
        });
    }
}