import React from 'react';

class CustomerCreditModal extends React.Component {
    constructor(...args) {
        super(...args);
        this.state = {open: false, loading: false};
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    componentDidMount() {
        this.setState({
            internalNotes: this.props.line.internalNotes,
            status: this.props.line.status,
            type: this.props.line.type,
            amount: this.props.line.amount,
            expiryDate: new Date(this.props.line.expiryDate.time).toISOString().substr(0,10)
        });
    }

    handleCustomerCreditUpdateClick(event) {
        event.preventDefault();
        const line = this.props.line;
        const url = `/admin/api/customer-credits/${line.id}/update`;
        const form = $(event.target).closest('form');
        const modal = `customerCreditModal-${line.id}`;
        this.setState({loading: true});
        $.post(url, form.serialize(), null, 'json').done(response => {
            this.setState({loading: false, open: false});
            bootstrap.Modal.getInstance(this.refs[modal]).hide();
        });
    }

    render() {
        return this.renderCustomerCreditDialog();
    }

    renderCustomerCreditDialog() {
        const line = this.props.line;
        const modalContent = this.createCustomerCreditDetailsDialog(line);

        return (
            <div>
                <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target={`#customerCreditModal-${line.id}`}>Manage</button>

                <div className="modal fade" id={`customerCreditModal-${line.id}`} ref={`customerCreditModal-${line.id}`} data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <form onSubmit={(event) => this.handleCustomerCreditUpdateClick(event)}>
                                <div className="modal-header">
                                    <h5 className="modal-title" id="staticBackdropLabel">Manage Credit Request</h5>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    {modalContent}
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                    <button type="button" onClick={(e) => this.handleCustomerCreditUpdateClick(e)}
                                            data-type="save-import"
                                            className="btn btn-primary">Save
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    createCustomerCreditDetailsDialog(line) {

        return (

            <div className="customer-credit-request-details">

                <div className="mb-3">
                    <label htmlFor={`type-${line.id}`} className="form-label">Credit Type</label>
                    <select className="form-select" required="" name="type"
                            id={`type-${line.id}`} value={this.state.type} onChange={this.handleChange}>
                        <option value="COMPENSATION">Compensation</option>
                        <option value="GOODWILL_GESTURE">Goodwill Gesture</option>
                        <option value="MARKETING">Marketing</option>
                        <option value="ORDER_TRANSFER">Order Transfer</option>
                    </select>
                </div>

                <div className="mb-3">
                    <label htmlFor={`status-${line.id}`} className="form-label">Status</label>
                    <select className="form-select" required="" name="status"
                            id={`status-${line.id}`} value={this.state.status} onChange={this.handleChange}>
                        <option value="PENDING">Pending</option>
                        <option value="APPROVED">Approved</option>
                        <option value="CANCELLED">Cancelled</option>
                        <option value="EXPIRED">Expired</option>
                    </select>
                </div>

                <div className="mb-3">
                    <label htmlFor={`amount-${line.id}`} className="form-label">Credit Amount</label>
                    <input type="number" step="0.01" min="0.01" className="form-control" ref="amount"
                           name="amount" id={`amount-${line.id}`}
                           value={Number.parseFloat(this.state.amount).toFixed(2) || ''} onChange={this.handleChange}/>
                </div>

                <div className="mb-3">
                    <label htmlFor={`expiryDate-${line.id}`} className="form-label">Expiry Date</label>
                    <input type="date" className="form-control" ref="expiryDate"
                           name="expiryDate" id={`expiryDate-${line.id}`}
                           value={this.state.expiryDate || ''} onChange={this.handleChange}></input>
                </div>

                <div className="mb-3">
                    <label htmlFor={`internalNotes-${line.id}`} className="form-label">Internal Notes</label>
                    <textarea type="text" rows="3" className="form-control" ref="internalNotes"
                              name="internalNotes" id={`internalNotes-${line.id}`}
                              value={this.state.internalNotes || ''} onChange={this.handleChange}></textarea>
                </div>

            </div>
        );
    }

}

export default CustomerCreditModal;
