import mixam from '../../boot/mixam';
import defineComponent from '../../../components/flight/lib/component';

export default defineComponent(MixamLocalUser);


function MixamLocalUser() {

    this.attributes({
        version: "1.01",
        itemKey: "mixam_user-"
    });

    this.getKey = function () {
        return this.attr.itemKey + this.attr.version;
    };

    this.sendUser = function () {
        this.read();
        this.trigger("notifyUserDetails", this.user);
    };

    this.save = function () {
        localStorage.setItem(this.getKey(), JSON.stringify(this.user));
    };

    this.update = function (event, newUser) {
        this.user = $.extend(true, this.user, newUser);
        this.save();
    };

    this.read = function () {
        var u = localStorage.getItem(this.getKey());
        if (u) {
            this.user = $.extend(true, this.user, JSON.parse(u));
        }
    };

    this.init = function () {
        this.user = {
            version: this.attr.version,
            email: "",
            name: "",
            icon: "",
            motto: "",
            environments: {
                advanced: false
            },
            table: {
                top: {
                    printPager: true,
                    printTotals: false
                },
                bottom: {
                    printPager: true,
                    printTotals: true
                }
            },
            lightbox: {
                continues: false
            },
            compare: {
                sum: true,
                percent: false
            },
            mru: [],
            splash: {
                mruActive: true
            },
            chart: {
                folder: true
            },
            notifications: {
                general: true
            }
        };

        // port old type email entry
        this.user.email = this.user.email || localStorage.getItem('mixam_user_email');
        if (this.user.email && !this.user.icon) {
            this.user.icon =  mixam.getAvatar( this.user.email);
        }
        if (this.user.email && !this.user.name) {
            this.user.name = this.user.email.replace(/@.+$/g, "").replace(/\./g, " ")
                .split(/\s+/).map(part => part.capitalize()).join(" ");
        }
        this.sendUser();
    };

    this.mruPush = function(event, data) {
        this.mruRemove(data.uri);
        this.user.mru.unshift(data);
        this.user.mru.length = Math.min(this.user.mru.length, 10);
        this.save();
    };

    this.mruRemove = function(uri) {
        var entry = this.user.mru.find(x => x.uri === uri),
            i = entry && this.user.mru.indexOf(entry);

        if (typeof i === "number" && i !== -1) {
            this.user.mru.splice(i, 1);
        }
    };

    this.after('initialize', function () {
        this.init();

        this.on(document, "requestUser", this.sendUser);
        this.on(document, "requestNotifyUserChange", this.sendUser);
        this.on(document, "requestUserUpdate", this.update);
        this.on(document, "requestUserMruPush", this.mruPush);
    });
}
