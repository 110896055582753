/* eslint-disable @typescript-eslint/ban-ts-comment */
import ReactDom from 'react-dom/client.js';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import reactToWebComponent from 'react-to-webcomponent';
import PropTypes from 'prop-types';
import { StyleSheetManager } from 'styled-components';
import { SearchResults } from "../../../../assets/react/search-results/SearchResults";
var styles = require('./bs-global-styles.css').toString();
var bootstrapLink = '<link ' +
    'rel="stylesheet" ' +
    'href="https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/css/bootstrap.min.css" ' +
    'integrity="sha384-rbsA2VBKQhggwzxH7pPCaAqO46MgnOM80zW1RWuH61DGLwZJEdK2Kadq2F9CUG65" ' +
    'crossorigin="anonymous" ' +
    '/>';
var SearchResultsStyleWrapper = function (_a) {
    var updatesearchresults = _a.updatesearchresults;
    // Do not remove this state.
    // For some strange reason, styled-component's StyleSheetManager needs React state to be set within the same component for it to render the styles.
    var _b = useState(false), _ = _b[0], setPointlessState = _b[1];
    useEffect(function () {
        setPointlessState(true);
    }, []);
    var refContainer = useRef(null);
    var hostStyles = useMemo(function () { return styles.replace(':root', ':host'); }, [styles]);
    return (React.createElement(StyleSheetManager, { target: refContainer.current },
        React.createElement(React.Fragment, null,
            React.createElement("style", null, hostStyles),
            React.createElement("div", { dangerouslySetInnerHTML: { __html: bootstrapLink } }),
            React.createElement("div", { ref: refContainer }),
            React.createElement(SearchResults, { updateSearchResults: JSON.parse(updatesearchresults) }))));
};
SearchResultsStyleWrapper.propTypes = {
    updatesearchresults: PropTypes.any
};
export var initialiseSearchResults = function () {
    window.customElements.define('search-results', reactToWebComponent(SearchResultsStyleWrapper, React, ReactDom, {
        shadow: 'open',
    }));
};
