import Mustache from '../../components/mustache/mustache';
import defineComponent from '../../components/flight/lib/component';
import proofThumbnailsTemplate from 'text!../../appes6/templates/shop/proof-thumbnails.mustache';
export default defineComponent(ProofDisplay);

function ProofDisplay() {

    this.attributes({
        panelSelector: '.proof-panel'
    });


    this.render = function (proof) {
        var i,
            urlBase = window.getProofUrl().replace(/\.pdf\.json$/, ""),
            perLine = 1,
            span = 12,
            urls = [],
            line = [];

        if (proof.count >= 2 && proof.count <= 4) {
            perLine = 2;
            span = 6;
        } else if (proof.count > 4) {
            perLine = 4;
            span = 3;
        }

        for (i = 0; i < proof.count; i++) {
            line.push({url: urlBase + "_" + (i + 1) + ".jpg", span: span, page: i + 1});
            if ((i + 1) % perLine === 0) {
                urls.push({items: line});
                line = [];
            }
        }
        if (line.length) {
            urls.push({items: line});
        }
        this.select("panelSelector").html(Mustache.render(proofThumbnailsTemplate, {lines: urls}));
    };

    // call the address/list api
    this.send = function () {
        var that = this,
            promise = $.get(window.getProofUrl(), null, null, 'json');

        promise.done(function (response) {
            response = $.extend({count: 0, orientation: "portrait", size: "a5"}, response);
            that.render(response);
        });

        promise.fail(function (response) {
        });
    };

    this.after('initialize', function () {
        this.on('loadProof', this.send);
    });

}