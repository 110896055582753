import defineComponent from '../../../../components/flight/lib/component';
import React from 'react';
import ReactDom from 'react-dom/client';
import { PlaidPaymentManager } from '../../react/plaid/index.tsx';

export default defineComponent(PlaidManager);

function PlaidManager() {

    this.attributes({
        containerSelector: '[data-type="plaid-container"]',
    });

    this.initPlaid = function () {
        const orderId = this.orderId;
        const amountToPay = this.amountToPay;
        const root = ReactDom.createRoot(this.select('containerSelector').get(0));
        root.render(
            React.createElement(PlaidPaymentManager, {
                orderId: orderId,
                amount: amountToPay
            })
        );
    };

    this.after('initialize', function () {
        this.orderId = this.$node.data('order-id');
        this.amountToPay = this.$node.data('amount');
        this.initPlaid();
    });
}
