var babelHelpers = {};

babelHelpers.interopRequireDefault = function (obj) {
    return obj && obj.__esModule ? obj : {
        "default": obj
    };
};

babelHelpers._extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
        var source = arguments[i];

        for (var key in source) {
            if (Object.prototype.hasOwnProperty.call(source, key)) {
                target[key] = source[key];
            }
        }
    }

    return target;
};

export default babelHelpers;