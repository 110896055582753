import mixam from '../../boot/mixam';
import defineComponent from '../../../components/flight/lib/component';
import WithNormalizeMap from "./../with-normalize-map";
import Mustache from '../../../components/mustache/mustache';
import PageFlipper from "./../page-flip";
import orderUpload from "./order-upload";
import FileRename from "./order-file-rename";
import AnimatedCheckbox from "../animated/animated-checkbox";
import ProofManager from "./proof-manager";
import templatedownloadItem from "text!../../../appes6/templates/order/downloadItem.mustache";
import templateFileList from "text!../../../appes6/templates/order/file-list.mustache";
import templateFlipPages from "text!../../../appes6/templates/order/artwork-flip-pages.mustache";
import templateItemToolbar from "text!../../../appes6/templates/order/item-toolbar.mustache";
import templateShareSnippet from "text!../../../appes6/templates/order/share-snippet.mustache";
import regional from '../../boot/regional';
import Product from '../constants/products';
import WithFolderUrl from '../with-get-folder-url';

import React from 'react';
import ReactDom from 'react-dom/client';
import {OrderItem} from '@mixam-platform/mixam-components';

export default defineComponent(Artwork, WithNormalizeMap, WithFolderUrl);

/*
 * TODO: it looks as though a large portion of this file is dead code; we should remove it!
 */

function Artwork() {

    this.attributes({
        displaySelector: '[data-type="art-display"]',
        containerSelector: '.files-container',
        thumbsSelector: '.art-page-list li > [data-type="page"]',
        uploadManagerSelector: '[data-type="upload-manager"]',
        apiCallSelector: '[data-type="api-call"]',
        listPreviewSelector: '.artwork-container',
        bookPreviewSelector: '.preview-container',
        flipbookSelector: '.preview-container .contain',
        toolbarSelector: '.toolbar',
        sharePanelSelector: '[data-type="share-panel"]',
        itemToolbarSelector: '.toolbar',
        itemToolbarLinkSelector: '.toolbar a.shared-link',
        shareEmbedCodeSelector: '.embed-code-snippet',
        togglePreviewSelector: '[data-type="view-mode"]',
        toggleReadingOrderSelector: '[data-type="reading-order"]',
        toggleReadingOrderDialogSelector: '.reading-order-modal',
        downloadFilesSelector: '[data-type="download-files"]',
        directionTextSelector: '[data-type="bind-direction"]',
        fileRenameSelector: '[data-type="file-rename"]',
        animatedCheckboxSelector: '[data-toggle="animated-checkbox"]',
        proofRequestSelector: '[data-type="proof-request"]',
        createUvMapSelector: '[data-type="create-uvmap"]',
        autoArrangeArtworkSelector: '[data-type="btn-auto-arrange"]',
        autoArrangeArtworkDlgSelector: '[data-type="dlg-auto-arrange"]',
        clearAllFilesSelector: '[data-type="btn-clear-all"]',
        clearAllFilesDlgSelector: '[data-type="dlg-clear-all"]'
    });

    this.renderFiles = function () {
        return Mustache.render(templateFileList, this.normalizeFiles(this.item), {printItem: templatedownloadItem});
    };

    this.renderToolBar = function () {
        // console.log(this.normalizeShare(this.order, this.item));
        return Mustache.render(templateItemToolbar, this.normalizeShare(this.order, this.item));
    };

    this.renderShareSnippet = function () {
        return Mustache.render(templateShareSnippet, this.normalizeShare(this.order, this.item), {printItem: templatedownloadItem});
    };

    this.normalizeFiles = function (item) {
        let hasAlternatives = 0;
        const files = [];
        const alternativeTypes = {};

        //noinspection JSUnresolvedVariable
        item.uploads.forEach((upl) => {
            hasAlternatives += upl.alternativeFiles ? 1 : 0;
            if(!alternativeTypes.mirrored) { alternativeTypes.mirrored = upl.alternativeFiles && !!upl.alternativeFiles.mirrored; }
            if(!alternativeTypes.original) { alternativeTypes.original = upl.alternativeFiles && !!upl.alternativeFiles.original; }
            if(!alternativeTypes.upscaled) { alternativeTypes.upscaled = upl.alternativeFiles && !!upl.alternativeFiles.upscaled; }
            if(!alternativeTypes.stretched) { alternativeTypes.stretched = upl.alternativeFiles && !!upl.alternativeFiles.stretched; }

            this.addNormalizeUpload(upl, files);
        });

        files.sort((a, b) => a.name.localeCompare(b.name, mixam.shop.locale.replace('_','-'), {
            numeric: true,
            sensitivity: 'base'
        }));

        return {
            itemId: item.id,
            files: files,
            hasAlternatives: this.order.orderStatusInt <= 10 &&
            hasAlternatives > 1 && mixam.user.hasRole("ROLE_MIXAM") ? true : null,
            canRemove: !this.order.locked,
            dict: regional().santa.order,
            alternativeTypes: alternativeTypes
        };
    };

    this.normalizeShare = function (order, item) {
        const o = {};
        const url = `${mixam.secureDomain}/share/`;
        const isMixam = mixam.user.hasRole("ROLE_MIXAM");

        if (item.uploads.length) {
            o.HAS_UPLOADS = true;
        }

        if (isMixam) {

            o.IS_MIXAM = true;

            /** @namespace item.downloadOriginalsUrl */
            if (item.downloadOriginalsUrl) {
                o.downloadOriginalsLink = item.downloadOriginalsUrl;
            } else {
                o.NEED_DOWNLOAD_REQUEST = true;
            }
        }

        if (order.artworkReady) {
            o.ARTWORK_READY = true;

            const availableMergedFileTypes = item.allMergedFiles.map(file => file.mergedFileType);
            if (availableMergedFileTypes.includes("ALL")) {
                const allFile = item.allMergedFiles.find(file => file.mergedFileType === "ALL")
                o.proofLink = allFile.href + "?t=" + new Date().getTime();
            } else if (availableMergedFileTypes.includes("COVER") && !availableMergedFileTypes.includes("BODY")) {
                const coverFile = item.allMergedFiles.find(file => file.mergedFileType === "COVER")
                o.proofLink = coverFile.href + "?t=" + new Date().getTime();
            } else if (availableMergedFileTypes.includes("JOB")) {
                const jobFile = item.allMergedFiles.find(file => file.mergedFileType === "JOB")
                o.proofLink = jobFile.href + "?t=" + new Date().getTime();
            } else {
                o.NEED_PROOF_REQUEST = true;
            }
        }

        o.index = order.items.indexOf(item) + 1;
        o.orderId = order.id;
        o.itemId = item.id;
        if (this.order.orderStatusInt >= 10) {
            o.CONFIRMED = 1;
        }

        if (item.share) {
            o.user = {
                name: this.order.contact.name
            };
            o.encodedShareUrl = encodeURIComponent(url + item.share.id);
            o.shareUrl = url + item.share.id;
            o.embedUrl = `${mixam.secureDomain}/embed/${item.share.id}`;
            o.title = encodeURIComponent(item.share.title);
            o.description = encodeURIComponent(item.share.description);
            o.coverThumb = encodeURIComponent(item.share.image);
            if (this.isBoundProduct(item) && this.order.orderStatusInt < 10) {
                o.HAS_READING_ORDER = true;
            }
            if (!item.folded && item.map.pages.length > 2) {
                o.HAS_VIEW_MODE = true;
            }
            if (item.isRtl) {
                o.isRtl = true;
            }
        }

        o.dict = regional().santa.order;
        return o;
    };

    this.isBoundProduct = function (item) {
        return item.query.productId === Product.BOOKLET || (item.query.productId >= Product.PHOTOBOOK && item.query.productId <= Product.BOOK);
    };

    this.addNormalizeUpload = function (upload, files) {
        const isMixam = mixam.user.hasRole("ROLE_MIXAM");

        //noinspection JSUnresolvedVariable
        upload.uploadedFiles.forEach(ufile => {
            const source = [],
                o = {
                    id: ufile.id.replace(/[a-zA-Z]+$/, "pdf"),
                    _id: ufile.id.replace(/\./g, ""),
                    date: new Date(ufile.date),
                    name: ufile.originalName,
                    url: ufile.url
                };

            if (ufile.pages) {
                if (ufile.pages.producer) {
                    source.push(ufile.pages.producer);
                }
                if (ufile.pages.creator && ufile.pages.creator !== ufile.pages.producer) {
                    source.push(ufile.pages.creator);
                }
                o.size = ufile.pages.size.formatNumber(0);
                o.pageCount = ufile.pages.count;
                o.producer = ufile.pages.producer;
                o.creator = ufile.pages.creator;
                o.dateCreated = ufile.pages.dateCreated;
                o.doubleSpread = ufile.doubleSpread;
                o.trimAdded = ufile.trimAdded;
                o.upscaleBleedAdded = ufile.upscaleBleedAdded;
                o.mirrorBleedAdded = ufile.mirrorBleedAdded;
                o.stretchBleedAdded = ufile.stretchBleedAdded;
                o.pixelBleedAdded = ufile.pixelBleedAdded;
                o.hasUpscaleBleed = upload.alternativeFiles && !!upload.alternativeFiles.upscaled;
                o.hasMirroBleed = upload.alternativeFiles && !!upload.alternativeFiles.mirrored;
                o.hasStretchBleed = upload.alternativeFiles && !!upload.alternativeFiles.stretched;
                o.coverSpreadAdded = ufile.coverSpread;
                if (isMixam) {
                    o.fileReport = `/ufiles/${this.order.id}/${this.item.id}/${ufile.id.replace(/\.pdf$/, "")}`;
                    o.originalFileUri = ufile.originalFileUri;
                    o.fileType = ufile.fileType;
                }
                o.source = source.join(", ");
            }

            if (upload.status === "ready") {
                o.ready = true;
            }

            if (isMixam && upload.alternativeFiles) {
                o.alternativeFiles = {};
                /** @namespace upload.uploadedFiles */
                /** @namespace upload.uploadedFiles[0].alternativeType */
                /** @namespace upload.uploadedFiles.alternativeType */
                o.alternativeFiles[upload.uploadedFiles[0].alternativeType] = 1;
            }
            files.push(o);
        });
    };

    /**
     * setOrder is called each time we get a new order version
     * be it ready (this.order.processStatus === "success") or partial update
     * @param event
     * @param data - the order itself
     */
    this.setOrder = function (event, data) {
        if (this.item) {
            if (this.item.id !== data.itemId) {
                return;
            }
        }
        this.order = data;
        this.item = this.order.getItem(this.$node.data("item"));
        this.topic = "/topic/" + this.order.id /*+ this.item.id*/;

        FileRename.teardownAll();
        this.select('containerSelector').html(this.renderFiles());
        FileRename.attachTo(this.select('fileRenameSelector'));

        // check / uncheck the Artwork tab according the state of the item.map

        // if we dont know the order status or if we know it is a success - render the pages thumbs
        if (!this.order.processStatus || this.order.processStatus === "success") {

            const $display = this.select('displaySelector');

            // Stomp Notifications Call The setOrder Method All The Time. We Need To Ensure That We Do Not Re-Render
            // The New Artwork Manager When We Receive These Notifications
            if(!this.newArtworkManagerInitialised) {

                // Use the new OrderItem component in React...
                const $artworkContainer = $display.html('<div class="artwork-container" />').find(">:first-child");
                const root = ReactDom.createRoot($artworkContainer.get(0));
                root.render(
                    <OrderItem
                        secureDomain={''}
                        orderId={this.order.id}
                        itemId={this.item.id}
                        refreshEventName={'uiRequestOrder'}
                        locale={mixam.shop.locale.replace('_', '-')}
                    ></OrderItem>
                );

                this.newArtworkManagerInitialised = true;

            }
            // Retain the view-mode == book functionality
            const viewModeBookData = {
                "map2": this.normalizeMap(this.order, this.item, false)
            };
            $display.append(Mustache.render(templateFlipPages, viewModeBookData));

            this.select('directionTextSelector').text(this.item.isRtl ? "Direction: Right to Left" : "");
            /* jshint ignore:start */
            if (this.order.orderStatusInt < 10 && this.item.map?.ready) {
                this.$node.removeClass("confirmed");
            } else {
                if (!mixam.user.hasRole("ROLE_MIXAM")) {
                    this.$node.addClass("confirmed");
                }
            }
            /* jshint ignore:end */

            // if we hacve atleast 3 full pages
            // add page flip view
            if (this.hasToolbar()) {
                this.displayToolBar();
                this.select('toolbarSelector').removeClass('hidden');
                this.displayShareEmbed();
                if (this.hasFlipView()) {
                    try {
                        PageFlipper.attachTo(this.select('flipbookSelector'), {
                            orientation: this.item.map.orientation,
                            pages: this.item.map.pages.length / 2 + 1,
                            bookLength: this.item.map.pages.length,
                            width: this.select('listPreviewSelector').width(),
                            pageWidth: this.item.dimensions.width - this.getGutterSize(false),
                            pageHeight: this.item.dimensions.height,
                            parentContainer: '[data-type="art-display"]',
                            direction: this.item.isRtl ? "rtl" : "ltr"
                        });
                    } catch (e) {
                        this.trigger("log", e);
                    }
                }
            }

            AnimatedCheckbox.attachTo(this.select('animatedCheckboxSelector'));
            this.select('createUvMapSelector').removeAttr('data-loading').attr('disabled', false);
        }

        // remove li(s) left by jquery upload
        // we dont need em if they are included in the file list
        this.removeUnusedUploadingFileLi();
    };

    this.displayToolBar = function () {
        this.select("itemToolbarSelector").html(this.renderToolBar()).removeClass("hidden");
        ProofManager.attachTo(this.select('proofRequestSelector'));
    };

    this.displayShareEmbed = function () {
        //this.select("sharePanelSelector").html(this.renderShareBar()).removeClass("hidden");
        this.select("shareEmbedCodeSelector").html(this.renderShareSnippet()).removeClass("hidden");
    };

    this.hasFlipView = function () {
        return this.item && this.isBound(this.item) && this.item.map && this.item.map.pages[2] && this.item.map.pages[2].file;
    };

    this.hasToolbar = function () {
        return this.item && this.item.folded /*|| this.item.query.productId === Product.ENVELOPE*/ ||
            (this.isBound(this.item) && this.item.map && this.item.map.pages[1] && this.item.map.pages[1].file) ||
            (/*this.item.query.productId === Product.LEAFLET &&*/ this.item.map && this.item.map.pages[0] && this.item.map.pages[0].file);
    };

    /**
     * compare the list of files to the li(s) added by jquery upload
     * and remove duplicates
     */
    this.removeUnusedUploadingFileLi = function () {
        const ul = this.$node.find("[data-type=upload-list]"),
            aRemove = ["[data-file-id='9999']"];

        //noinspection JSUnresolvedVariable
        this.item.uploads.forEach((upload) => {
            //noinspection JSUnresolvedVariable
            upload.uploadedFiles.forEach((ufile) => {
                aRemove.push("[data-file-id='" + ufile.id + "']");
                aRemove.push("[data-file-id='" + ufile.id.replace(/\.([a-zA-Z]+?)$/, "") + "']");
            });
        });
        if (aRemove.length) {
            ul.find(aRemove.join(",")).remove();
        }
    };

    this.removeFile = function (event, data) {
        const removeUrl = mixam.springUrl("/api/orders/{orderId}/remove/file".supplant({
            orderId: this.order.id
        }));

        if (data.id) {
            this.trigger("uiRequestAction", {url: removeUrl, fileId: data.id});
        }
    };

    this.renameFile = function (event, data) {
        const removeUrl = mixam.springUrl("/api/orders/{orderId}/rename/file".supplant({
            orderId: this.order.id
        }));

        this.trigger("uiRequestAction", {
            url: removeUrl,
            fileId: data.fileId,
            originalName: data.originalName,
            newName: data.newName
        });
    };

    this.changeFileBleedType = function (event, data) {
        const actionUrl = mixam.springUrl("/api/orders/{orderId}/{itemId}/{fileId}/changebleed".supplant({
            orderId: this.order.id,
            itemId: this.item.id,
            fileId: data.fileId
        }));

        this.trigger("uiRequestAction", {url: actionUrl, type: data.value});
    };

    this.changeAllBleedType = function (event, data) {
        const actionUrl = mixam.springUrl("/api/orders/{orderId}/{itemId}/changeallbleed".supplant({
            orderId: this.order.id,
            itemId: this.item.id
        }));

        this.trigger("uiRequestAction", {url: actionUrl, type: data.value});
    };


    this.fileAdded = function (event, data) {
        const actionUrl = `${$("#assetsDomain").val()}/api/upload/${this.order.id}/${this.item.id}/${data.type}`;

        if (data.type === "dropin") {
            this.trigger("uiRequestAction", {url: actionUrl, files: JSON.stringify(data.files)});
        } else if (data.type === "import") {
            this.trigger("uiRequestAction", {url: actionUrl, uri: data.url, uid: data.uid});
        }
        this.reportFileadded(data);
    };

    this.reportFileadded = function (data) {
        const actionUrl = mixam.springUrl(`/api/orders/${this.order.id}/file-added`);

        this.trigger("uiRequestAction", {url: actionUrl, type: data.type});
    };

    this.apiCommand = function (event) {
        const $target = $(event.target).closest("button");

        $target.attr('data-loading', '*').attr('disabled', true);
        $.post($target.data("href"), {interactive: true}, null, "json");
    };

    this.afterAction = function (event, response) {
        let message = "1028";

        if (response.itemId && response.itemId === this.item.id) {
            if (response.status === "success") {
                switch (response.action) {
                    case "delete":
                        message = "1027";
                        break;
                    case "insert":
                        message = "1026";
                        break;
                    case "reorder":
                        message = "1030";
                        break;
                    case "revert":
                        message = "1031";
                        break;
                    case "undo":
                        message = "1029";
                        break;
                    case "vat":
                        message = "1037";
                        if (response.reason === "remove/vat") {
                            message = "1043";
                        } else if (response.reason === "add/vat") {
                            message = "1042";
                        }
                        break;
                    case "readingorder":
                        message = "1034";
                        break;
                    default :
                        message = null;
                }
            }
            if (message) {
                this.trigger('showFixedMessage', {message: regional().messages[message], interval: 20000});
            }
        }
    };

    this.getProofInfo = function () {
        const map = this.normalizeMap(this.order, this.item, true),
            pages = [];

        map.pages.forEach(function (page) {
            pages.push(page);
        });
        if (map.spine) {
            pages.push(map.spine);
        }
        this.trigger(document, "uiSetProofInfo", {pages: pages, pageCount: this.item.map.pages.length || 1});
    };

    this.toggleReadingOrder = function (event) {
        event.stopPropagation();
        event.preventDefault();

        const $target = $(event.target).closest("button"),
            actionUrl = mixam.springUrl("/api/orders/{orderId}/{itemId}/readingorder".supplant({
                orderId: this.order.id,
                itemId: this.item.id
            }));

        this.select('toggleReadingOrderSelector').removeClass("active");
        $target.addClass("active");

        this.trigger("uiRequestAction", {
            url: actionUrl,
            action: "readingorder",
            isRtl: $target.data("order") === "rtl"
        });

        this.select('toggleReadingOrderDialogSelector').modal('hide');
    };

    this.createUvMap = function () {
        this.select('createUvMapSelector').attr('data-loading', '*').attr('disabled', true);
        $.post(`${mixam.assetsDomain}/api/orders/${this.order.id}/${this.item.id}/uvmap`,
            {shopUrl: mixam.shop.url, index: this.order.items.indexOf(this.item)});
    };

    this.after('initialize', function() {
        this.on(document, "setOrderData", this.setOrder);
        this.on(document, "uiAfterAction", this.afterAction);

        this.on("uiFileRemoveByFileId", this.removeFile);
        this.on("uiFileChangeBleedType", this.changeFileBleedType);
        this.on("uiFileChangeAllBleedType", this.changeAllBleedType);
        this.on('uiNeedProofInfo', this.getProofInfo);
        this.on('uiFileAdded', this.fileAdded);
        this.on('uiFileRenamed', this.renameFile);

        this.doShareClick = function(event) {
            const $target = $(event.target).closest('a');

            if ($target.hasClass('embed')) {
                event.preventDefault();
            }

            if (!mixam.user.hasRole("ROLE_MIXAM")) {
                $.post(mixam.springUrl(`/counter/SHARE_CLICK/${$target.data('count')}/add`));
            }
        };

        this.downloadFiles = function(/*event*/) {
            this.select('downloadFilesSelector').attr('data-loading', '*').attr('disabled', true);
            $.post(`${mixam.assetsDomain}/api/orders/${this.order.id}/${this.item.id}/originals/download`,
                {shopUrl: mixam.shop.url, index: this.order.items.indexOf(this.item)}).done(() => {
                this.trigger('showFixedMessage', {message: regional().messages[1050]});
            }).fail((err) => this.trigger("log", err), '/');
        };

        this.autoArrangeArtwork = function(/*event*/) {
            $.post(`/api/orders/${this.order.id}/${this.item.id}/auto/arrange`).done(() => {
                this.select('autoArrangeArtworkDlgSelector').modal('hide');
            }).fail((err) => this.trigger("log", err), '/');
        };

        this.clearAllFiles = function(/*event*/) {
            this.select('clearAllFilesSelector').attr('data-loading', '*').attr('disabled', true);

            $.post(`/api/orders/${this.order.id}/${this.item.id}/clear/files`).done(() => {
                this.select('clearAllFilesDlgSelector').modal('hide');
            }).fail((err) => this.trigger("log", err), '/');
        };

        this.togglePreview = function(event) {
            event.stopPropagation();
            event.preventDefault();

            const $target = $(event.target).closest("a"),
                view = $target.data("view");

            this.select('togglePreviewSelector').removeClass("active");
            $target.addClass("active");

            if (view === "list") {
                this.select('bookPreviewSelector').hide();
                this.select('listPreviewSelector').show("normal");
            } else {
                this.select('bookPreviewSelector').show("normal");
                this.select('listPreviewSelector').hide();
            }
        };

        this.on("click", {
            apiCallSelector: this.apiCommand,
            itemToolbarLinkSelector: this.doShareClick,
            toggleReadingOrderSelector: this.toggleReadingOrder,
            createUvMapSelector: this.createUvMap,
            downloadFilesSelector: this.downloadFiles,
            autoArrangeArtworkSelector: this.autoArrangeArtwork,
            clearAllFilesSelector: this.clearAllFiles,
            togglePreviewSelector: this.togglePreview
        });

        if (this.select('uploadManagerSelector').length) {
            orderUpload.attachTo(this.attr.uploadManagerSelector);
        }
    });
}
