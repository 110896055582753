var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React, { useState } from "react";
import { Modal, Button } from 'react-bootstrap';
import styled from 'styled-components';
var StyledModal = styled(Modal)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    opacity: 1; // overwriting some conflicting modal styles\n    .modal-content {\n        margin-top: 5rem;\n    }\n    label {\n        font-weight: bold;\n    }\n"], ["\n    opacity: 1; // overwriting some conflicting modal styles\n    .modal-content {\n        margin-top: 5rem;\n    }\n    label {\n        font-weight: bold;\n    }\n"])));
export var ConfirmAndDoButton = function (_a) {
    var label = _a.label, title = _a.title, body = _a.body, className = _a.className, onConfirm = _a.onConfirm;
    var _b = useState(false), show = _b[0], setShow = _b[1];
    var _c = useState(false), ordered = _c[0], setOrdered = _c[1];
    return (React.createElement(React.Fragment, null,
        ordered ? (React.createElement("div", null,
            React.createElement("i", { className: "fa fa-check", "aria-hidden": "true" }),
            " Reprint Ordered")) : (React.createElement("button", { className: className, type: "button", onClick: function () { return setShow(true); } }, label ? label : title)),
        React.createElement(StyledModal, { show: show, size: 'md', scrollable: false },
            React.createElement(Modal.Header, null,
                React.createElement(Modal.Title, null, title)),
            React.createElement(Modal.Body, null,
                React.createElement("p", null, body)),
            React.createElement(Modal.Footer, null,
                React.createElement(Button, { onClick: function () { return setShow(false); } }, "Cancel"),
                React.createElement(Button, { onClick: function () {
                        onConfirm();
                        setOrdered(true);
                        setShow(false);
                    } }, "OK")))));
};
var templateObject_1;
