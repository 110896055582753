import defineComponent from '../../../../components/flight/lib/component';
import WithSubmitAnimation from '../../with-submit-zoom-animation';

export default defineComponent(AmazonPayAddress, WithSubmitAnimation);

//const TEST_SCRIPT_URL = "https://static-eu.payments-amazon.com/OffAmazonPayments/uk/sandbox/lpa/js/Widgets.js";
const SCRIPT_URL = "https://static-eu.payments-amazon.com/OffAmazonPayments/gbp/lpa/js/Widgets.js";

function AmazonPayAddress() {

    this.attributes({
        amazonFormSelector: '[data-type="amazon-address-form"]',
        manualFormSelector: '[data-type="manual-address-form"]',
        addressFormSelector: '[data-type="amazon-address-order-form"]',
        accessTokenSelector: '[data-type="access-token"]',
        orderReferenceIdSelector: '[data-type="order-reference-id"]',
        saveBtnSelector: '[data-type="save"]',
    });

    this.AmazonLoginReady = function (/*event*/) {
        amazon.Login.setClientId(this.clientId);
    };

    this.AmazonPaymentsReady = function (/*event*/) {
        this.showLoginButton();
    };

    this.showLoginButton = function () {

        OffAmazonPayments.Button("AmazonPayButton", this.merchantId, {
            type: "PwA",
            color: "Gold",
            size: "medium",
            scope: "payments:shipping_address",
            language: this.language,

            authorization: () => {
                const loginOptions = {scope: "payments:widget payments:shipping_address payments:billing_address", popup: true};
                amazon.Login.authorize(loginOptions, (t) => {
                    this.accessToken = t.access_token;
                    //this.showAddressBookWidget("payments:billing_address", "addressBookWidgetDiv");
                    this.showAddressBookWidget("payments:shipping_address", "addressBookWidgetDiv");
                });
            }
        });
    };

    this.showAddressBookWidget = function (scope, container) {
        this.select('amazonFormSelector').removeClass('hidden');
        this.select('manualFormSelector').addClass('hidden');
        // AddressBook
        new OffAmazonPayments.Widgets.AddressBook({
            sellerId: this.merchantId,
            scope: scope,

            onReady: (orderReference) => {
                this.orderReferenceId = orderReference.getAmazonOrderReferenceId();
            },
            design: {
                designMode: 'responsive'
            },
            onError: function (error) {
                // Error handling code
                // We also recommend that you implement an onError handler in your code.
                // @see https://payments.amazon.com/documentation/lpwa/201954960
                let errorText = "";

                switch (error.getErrorCode()) {
                    case 'AddressNotModifiable':
                        errorText = "You cannot modify the shipping address when the order reference is in the given state.";
                        break;
                    case 'BuyerNotAssociated':
                        errorText = "The buyer is not associated with the given order reference. The buyer must sign in before you render the widget.";
                        break;
                    case 'BuyerSessionExpired':
                        errorText = "The buyer's session with Amazon has expired.  The buyer must sign in before you render the widget.";
                        break;
                    case 'InvalidAccountStatus':
                        errorText = "Your account is not in an appropriate state to execute this request. For example, it has been suspended or you have not completed registration.";
                        break;
                    case 'InvalidOrderReferenceId':
                        errorText = "The specified order reference identifier is invalid.";
                        break;
                    case 'InvalidParameterValue':
                        errorText = "The value assigned to the specified parameter is not valid.";
                        break;
                    case 'InvalidSellerId':
                        errorText = "The merchant identifier that you have provided is invalid. Specify a valid SellerId.";
                        break;
                    case 'MissingParameter':
                        errorText = "The specified parameter is missing and must be provided.";
                        break;
                    case 'PaymentMethodNotModifiable':
                        errorText = "You cannot modify the payment method when the order reference is in the given state.";
                        break;
                    case 'ReleaseEnvironmentMismatch':
                        errorText = "You have attempted to render a widget in a release environment that does not match the release environment of the Order Reference object. The release environment of the widget and the Order Reference object must match.";
                        break;
                    case 'StaleOrderReference':
                        errorText = "The specified order reference was not confirmed in the allowed time and is now canceled. You cannot associate a payment method and an address with a canceled order reference.";
                        break;
                    case 'UnknownError':
                        errorText = "There was an unknown error in the service.";
                        break;
                    default:

                }
                errorText += ` (${error.getErrorCode()} - ${error.getErrorMessage()})`;
                this.trigger('showFixedMessage', {type: 'error', body: errorText, interval: 20000});
            }
        }).bind(container);
    };

/*
    this.verifyLoggedIn = function () {
        const options = {
            scope: "profile postal_code payments:widget payments:shipping_address",
            popup: true,
            interactive: 'never'
        };

        // check if we are logged in
        const authRequest = amazon.Login.authorize(options, response => {

            if (response.error) {
                // USER NOT LOGGED IN
                console.log("verifyLoggedIn() - SESSION NOT ACTIVE - " + new Date());
            } else {
                // USER IS LOGGED IN
                console.log("verifyLoggedIn() - SESSION ACTIVE - " + new Date());

                // optionally, get the profile info
                console.dir('access_token= ' + response.access_token);

                amazon.Login.retrieveProfile((response) => {
                    // Display profile information.
                    console.dir('CustomerId= ' + response.profile.CustomerId);
                    console.dir('Name= ' + response.profile.Name);
                    console.dir('PostalCode= ' + response.profile.PostalCode);
                    console.dir('PrimaryEmail= ' + response.profile.PrimaryEmail);
                });
            }
        });
    };
*/

    this.doSubmit = function (event) {
        this.handleSubmit(event); // start animation
        this.select('accessTokenSelector').val(this.accessToken);
        this.select('orderReferenceIdSelector').val(this.orderReferenceId);
    };

    this.after('initialize', function () {
        this.clientId = $("#amazonClientId").val();
        this.language = $("#amazonLanguage").val();
        this.merchantId = $("#amazonMerchantId").val();
        this.orderId = $("#orderId").val();

        this.on('submit', {
            addressFormSelector: this.doSubmit
        });
        window.onAmazonLoginReady = (event) => this.AmazonLoginReady(event);
        window.onAmazonPaymentsReady = (event) => this.AmazonPaymentsReady(event);
        /*document.getElementById('Logout').onclick = function() {
            amazon.Login.logout();
        };*/
       // require([SCRIPT_URL]);
    });
}
