import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import { toWidget, toWidgetEditable } from '@ckeditor/ckeditor5-widget/src/utils';
import Widget from '@ckeditor/ckeditor5-widget/src/widget';
import InsertTwoColumnGridCommand from './two-column-grid-command';

export default class GridEditing extends Plugin {

    static get requires() {
        return [ Widget ];
    }

    init() {
        this._defineSchema();
        this._defineConverters();
        this.editor.commands.add( 'insertTwoColumnGrid', new InsertTwoColumnGridCommand( this.editor ) );
    }

    _defineSchema() {
        const schema = this.editor.model.schema;

        schema.register( 'twoColumnGrid', {
            isObject: true,
            allowWhere: '$block'
        } );

        schema.register( 'leftColumn', {
            isLimit: true,
            allowIn: 'twoColumnGrid',
            allowContentOf: '$root'
        } );

        schema.register( 'rightColumn', {
            isLimit: true,
            allowIn: 'twoColumnGrid',
            allowContentOf: '$root'
        } );

        schema.addChildCheck( ( context, childDefinition ) => {
            if ( context.endsWith( 'leftColumn' ) && childDefinition.name === 'twoColumnGrid' ) {
                return false;
            }
        } );
    }

    _defineConverters() {
        const conversion = this.editor.conversion;

        conversion.for( 'upcast' ).elementToElement( {
            model: 'twoColumnGrid',
            view: {
                name: 'div',
                classes: 'twoColumnGrid'
            }
        } );
        conversion.for( 'dataDowncast' ).elementToElement( {
            model: 'twoColumnGrid',
            view: {
                name: 'div',
                classes: 'twoColumnGrid'
            }
        } );
        conversion.for( 'editingDowncast' ).elementToElement( {
            model: 'twoColumnGrid',
            view: ( modelElement, { writer: viewWriter } ) => {
                const div = viewWriter.createContainerElement( 'div', { class: 'twoColumnGrid' } );

                return toWidget( div, viewWriter, { label: 'two column grid' } );
            }
        } );

        conversion.for( 'upcast' ).elementToElement( {
            model: 'leftColumn',
            view: {
                name: 'div',
                classes: 'twoColumnGrid-left'
            }
        } );
        conversion.for( 'dataDowncast' ).elementToElement( {
            model: 'leftColumn',
            view: {
                name: 'div',
                classes: 'twoColumnGrid-left'
            }
        } );
        conversion.for( 'editingDowncast' ).elementToElement( {
            model: 'leftColumn',
            view: ( modelElement, { writer: viewWriter } ) => {
                const div = viewWriter.createEditableElement( 'div', { class: 'twoColumnGrid-left' } );
                return toWidgetEditable( div, viewWriter );
            }
        } );

        conversion.for( 'upcast' ).elementToElement( {
            model: 'rightColumn',
            view: {
                name: 'div',
                classes: 'twoColumnGrid-right'
            }
        } );
        conversion.for( 'dataDowncast' ).elementToElement( {
            model: 'rightColumn',
            view: {
                name: 'div',
                classes: 'twoColumnGrid-right'
            }
        } );
        conversion.for( 'editingDowncast' ).elementToElement( {
            model: 'rightColumn',
            view: ( modelElement, { writer: viewWriter } ) => {
                const div = viewWriter.createEditableElement( 'div', { class: 'twoColumnGrid-right' } );
                return toWidgetEditable( div, viewWriter );
            }
        } );
    }

}