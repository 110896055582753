import defineComponent from '../../../components/flight/lib/component';

function AdminShopSwitcher() {

    this.attributes({
        shopSelector: '[data-type="shop-switch"]'
    });

    this.switchShop = function(event) {
        let domainToSwitchTo = $(event.target).closest('a').data('domain');
        let url = new URL(window.location.href);
        url.hostname = domainToSwitchTo;
        window.location.href = url.href;
        event.preventDefault();
    };

    this.after('initialize', function () {
        this.on('click', {
            shopSelector: this.switchShop
        });
    });
}

export default defineComponent(AdminShopSwitcher);