import mixam from '../boot/mixam';
import Mustache from '../../components/mustache/mustache';
import problemCategory from "./constants/problemCategory";
import orderStatus from "./constants/orderStatus";
import disputeRequestCategory from "./constants/disputeRequestCategory";
import disputeCulprit from "./constants/disputeCulpritValues";
import { getProductMetadata } from './home/getProductMetadata';
import orderTableTemplate from 'text!../../appes6/templates/member/member-table.mustache';
import orderLineTemplate from 'text!../../appes6/templates/member/member-line.mustache';
import pagerTemplate from 'text!../../appes6/templates/member/member-pager.mustache';
import itemTemplate from 'text!../../appes6/templates/member/member-item-line.mustache';
import itemContactTemplate from 'text!../../appes6/templates/member/member-contact-line.mustache';
import renderDisputesTemplate from 'text!../../appes6/templates/member/dispute.mustache';
import getProductUrl from './constants/productUrlResolver';
import regional from '../boot/regional';
import disputeResolutions from "./constants/disputeResolutionValues";

export default WithNormalizeMemberOrders;

function WithNormalizeMemberOrders() {
    const createHeader = (function () {
        const dict = regional().member;
        const cols = [
            {caption: dict.memberStatus, width: 9},
            {caption: dict.memberOrderNumber, width: 9, sortExpression: "caseNumber"},
            {caption: dict.memberCreated, width: 9, sortExpression: "time"},
            {caption: dict.memberModified, width: 9, sortExpression: "lastModifiedDate"},
            {caption: dict.memberStatus, width: 9, sortExpression: "orderStatusInt,lastModifiedDate"},
            {caption: dict.memberConfirmed, width: 9, sortExpression: "confirmDate"},
            {caption: dict.memberArtwork, width: 9/*, sortExpression: "artworkReady,lastModifiedDate"*/},
            {caption: dict.memberSettled, width: 9/*, sortExpression: "settled,lastModifiedDate"*/},
            {caption: dict.memberRemarks, width: 9},
            {caption: dict.memberExpected, width: 9, sortExpression: "expectedDate"},
            {caption: dict.memberSum, width: 10, sortExpression: "total,lastModifiedDate"}
        ];

        return function (currentSort) {
            return cols.map(function (sort) {
                var o = $.extend({}, sort);

                if (o.sortExpression && o.sortExpression === currentSort.key) {
                    o.currentSort = {
                        key: currentSort.key,
                        dir: currentSort.dir
                    };
                    o.currentSort[currentSort.key] = true;
                    o.currentSort[currentSort.dir] = true;
                }
                return o;
            });
        };
    }());

    function isMapNotEmpty(map) {
        if (map) {
            return map.pages.some(p => p.file) || map.spine?.file || map.jacket?.file; // jshint ignore:line
        }
        return false;
    }

    function itemsPerRow(totalNumberOfItems) {
        var nItemsPerRow = 2;

        if (totalNumberOfItems >= 12) {
            nItemsPerRow = 6;
        } else if (totalNumberOfItems >= 10) {
            nItemsPerRow = 5;
        } else if (totalNumberOfItems >= 4) {
            nItemsPerRow = 4;
        }

        return nItemsPerRow;
    }

    this.renderMemberOrders = function (list) {
        return Mustache.render(orderTableTemplate, this.normalize(list), {
            renderLine: orderLineTemplate,
            renderPager: pagerTemplate,
            renderItemLine: itemTemplate,
            renderContactLine: itemContactTemplate,
            renderDisputes: renderDisputesTemplate,
        });
    };

    this.normalize = function (list_) {
        var list = $.extend(true, {}, list_),
            start, end, i;

        // print 4 pages to left, 4 to right

        if (list.totalPages > 1) {
            list.pager = {pages: []};
            start = Math.max(this.page - 3, 1); //  page is zero based thus 3 and not 4
            end = Math.min(start + 9, list.totalPages);

            if (list.firstPage || this.page < 5) {
                list.pager.firstPage = true;
            }
            if (list.lastPage) {
                list.pager.lastPage = true;
            }
            for (i = start; i < end; i++) {
                list.pager.pages.push({id: i, selected: (this.page === (i - 1) ? true : null)});
            }

        }
        list.header = createHeader(this.sort);
        list.number += 1;
        list.content = list.content.map(order => this.normalizeLine(order));


        if (list.totalElements === 0) {
            list.empty = true;
        }
        list.user = mixam.user;
        if (mixam.user.hasRole("ROLE_MIXAM")) {
            list.IS_MIXAM = true;
        }
        list.formatNumber = function () {
            return mixam.mustachFormatNumber;
        };
        list.dict = regional().member;
        list.dict2 = regional().santa.shoppingCart;
        return list;
    };


    this.normalizeLine = function (order_) {
        const order = $.extend(true, {}, order_),
            timeText = this.timeToDateString(order.time),
            lastModifiedText = this.timeToDateString(order.lastModifiedDate);

        order.orderId = order.id;

        if (timeText) {
            order.timeString = timeText;
            order.timeTitle = new Date(order.time).toString().replace(/GMT.+$/, "").replace(/UTC.+$/, "");
        } else {
            order.timeString = new Date(order.time).toString().replace(/[0-9]{2}:[0-9]{2}:[0-9]{2}.+$/, "");
        }
        if (lastModifiedText) {
            order.lastModifiedString = lastModifiedText;
            order.lastModifiedTitle = new Date(order.lastModifiedDate).toString().replace(/GMT.+$/, "").replace(/UTC.+$/, "");
        } else {
            order.lastModifiedString = new Date(order.lastModifiedDate).toString().replace(/[0-9]{2}:[0-9]{2}:[0-9]{2}.+$/, "");
        }


        if (order.expectedDate) {
            order.expectedDateString = this.timeToDateString(order.expectedDate);
        }

        if (order.confirmDate) {
            order.confirmDate = this.timeToDateString(order.confirmDate);
        }
        order.remarkCount = order.remarks.length;
        while (order.remarks.length > 3) {
            order.remarks.shift();
        }

        if (!order.remarkCount) {
            delete order.remarkCount;
        }

        if (order.handleBy) {
            order.handleBy = order.handleBy.substr(order.handleBy.indexOf(";") + 1);
        } else {
            delete order.handleBy;
        }
        if (order.items[0]) {
            order.currency = order.items[0].response.currency;
        }
        this.normalizeItems(order.items);
        order.items.forEach(item => {
            var i,
                thumb,
                numberOfThumbnails = Math.min(20, item.map.pages.length);

            // setup readiness flags
            /* jshint ignore:start */
            if (item.map?.ready) {
                item.MAP_READY = 1;
            } else {
                if (isMapNotEmpty(item.map)) {
                    item.MAP_HALF_READY = 1;
                } else {
                    item.MAP_EMPTY = 1;
                }
            }
            /* jshint ignore:end */

            if (mixam.user.hasRole("ROLE_MIXAM")) { // TODO: remove! (feature-gating)
                const productMetadata = getProductMetadata(item.query.productId, item.query.subProductId);
                item.SUPPORTS_SELF_PUBLISHING = productMetadata?.publicationMetadata.supportsSelfPublishing;
            }

            item.query.bodyPages = item.query.pages;
            if (item.query.version < 2 && item.query.pages > 0 && item.query.coverType > 0) {
                item.query.bodyPages -= 4;
            }

            if(item.publicationConfig !== undefined) {
                item.isPublication = true;
            }

            item.productUrl = getProductUrl(item.query);
            //add thumbnails
            item.thumbnails = [];
            for (i = 0; i < numberOfThumbnails; i++) {
                thumb = this.getThumbUrl(order, item, i);
                if (thumb) {
                    item.thumbnails.push(thumb);
                } else {
                    break;
                }
            }

            item.preview = itemsPerRow(item.thumbnails.length);
            if (item.disputes && item.disputes.length) {
                item.hasDispute = true;
                item.disputes = item.disputes.map(d => this.normalizeDispute(d));
                item.canComplain = true;
                item.disputes.forEach(dispute => {
                    if(dispute.status !== 60 && dispute.status !== 94) {
                        item.canComplain = false;
                    }
                });
            } else if (mixam.shop.isMixamShop &&
                (order.orderStatusInt >= orderStatus.DISPATCHED || mixam.user.hasRole("ROLE_MIXAM") || mixam.user.impression) &&
                order.orderStatusInt < orderStatus.CLOSED &&
                order.status !== "contact") {
                item.canComplain = true;
            }

            item.isQuote = order.isQuote;

            item.publisherRevenue = item.response.total - (item.response.fullPrice * item.query.copies);
            item.publicationCost = item.response.fullPrice * item.query.copies;

        });

        if (order.remarkss) {
            order.remarkss.forEach(function (remark) {
                remark.date = timeToString(remark.date) || new Date(remark.date).toString().replace(/[0-9]{2}:[0-9]{2}:[0-9]{2}.+$/, "");
            });
        }

        ["firstPage", "lastPage"].forEach(function (prop) {
            if (!order[prop]) {
                delete order[prop];
            }
        });

        if (order.ntotal) {
            order.ntotal = (order.total - order.ntotal - order.delivery - order.vatSum || 0).formatNumber(2);
        }
        order.total = (order.totalAfterDiscount || 0).formatNumber(2);
        order[order.orderStatus] = 1;
        order[order.status.toUpperCase()] = 1;
        if (order.orderStatusInt < 10 && order.status !== "contact") {
            order.canCancel = true;
        }

        order.assetsDomain = mixam.assetsDomain;
        return order;
    };

    this.normalizeDispute = function (dispute_) {
        const dispute = $.extend(true, {}, dispute_);
        dispute.dateCreatedText = mixam.dateToDateTimeString(dispute.dateCreated);
        dispute.problemCategoryText = problemCategory[dispute.problemCategory];
        dispute.requestCategoryText = disputeRequestCategory[dispute.requestCategory];
        dispute.disputeCloseCategoryText = mixam.disputeRequestCategoryValues[dispute.disputeCloseCategory];
        dispute.disputeCulpritText = disputeCulprit[dispute.culprit];
        dispute.disputeResolutionsText = disputeResolutions[dispute.resolvedActionType];
        dispute.dateClosedText = mixam.dateToDateTimeString(dispute.dateClosed);
        dispute.reprintOrderLink = {
            href: `/orders/${dispute.reprintOrderId}/`,
            caption: dispute.reprintCaseNumber,
            target: "_blank"
        };
        return dispute;
    };

    this.normalizeItems = function (items) {
        var k;

        // replace santa codes with friendly regional().dictionary names
        return items.map((originalItem) => {
            var item = originalItem.resolved = $.extend(true, {}, originalItem);

            if (item.query.coat2 === 0) {
                delete item.query.coat2;
            }

            for (k in item.query) {
                if (typeof regional().dictionary[k] === 'function') {
                    item.query["_" + k] = item.query[k];
                    item.query[k] = regional().dictionary[k](item.query[k]);
                } else {
                    item.query["_" + k] = item.query[k];
                    item.query[k] = regional().dictionary[k] && regional().dictionary[k][item.query[k]] || (item.query[k] !== 0 ? item.query[k] : "");
                }
            }


            if (item.query.secondaryFormat) {
                item.query.format = item.query.secondaryFormat;
            }

            if (item.query.productId === 1 || item.query.productId === 7 || item.query.colors === item.query.colors2) {
                item.query.throughout = item.query.colors;
            }

            if (item.query.productId === 1) {
                item.query.boundProduct = true;
                if (originalItem.query.coverType === -1) {
                    item.query.selfCovered = true;
                } else {
                    item.query.bound = true;
                }
            }

            item.response.isDigital = item.response.printType === "DIGITAL";
            item.response.isInkjet = item.response.printType === "INKJET";
            item.response.isWideFormat = item.response.printType === "WIDE_FORMAT";
            item.response.isLitho = item.response.printType === "LITHO";

            return item;
        });
    };


    this.getThumbUrl = function (order, item, pageNo) {
        var map = item.map,
            page = map.pages[pageNo],
            file = this.getFileById(item, page.file),
            side = page.side ? "-" + page.side : "";

        if (file && page.file) {
            return {
                pageNo: page.page,
                file: page.file,
                thumb: this.getFolderFromFileUrl(file.url) + "/" + page.file.replace(/\.pdf$/, "_thumb_" + page.page + side + ".jpg"),
                hirez: this.getFolderFromFileUrl(file.url) + "/" + page.file.replace(/\.pdf$/, "_" + page.page + side + ".jpg"),
                thumb2: map.uri + "/" + page.file.replace(/\.pdf$/, "_thumb_" + page.page + side + ".jpg"),
                hirez2: map.uri + "/" + page.file.replace(/\.pdf$/, "_" + page.page + side + ".jpg"),
                thumbOrientation: map.orientation
            };
        }
    };

    this.getFileById = (function (cache) {

        function findFile(item, id) {
            var i,
                j,
                upload,
                file;

            //noinspection JSUnresolvedVariable
            for (i = 0; upload = item.uploads[i]; i++) {   // jshint ignore:line
                if (upload.status === "ready") {
                    //noinspection JSUnresolvedVariable
                    for (j = 0; file = upload.uploadedFiles[j]; j++) {   // jshint ignore:line
                        if (file.id === id) {
                            return file;
                        }
                    }
                }
            }
            return null;
        }

        return function (item, id) {
            if (!cache[id]) {
                cache[id] = findFile(item, id);
            }
            return cache[id];
        };
    }([]));

}
