// 3rd-Party Global Libraries
import './components/SimpleDropDownEffects/jquery.dropdown';
import './components/jquerytools/validator';
import './components/jquerytools/validator-mixam-effect';
import './components/dates/dates';
import './components/stomp/stomp171';

// Mixam Flight Components
import mixam from './appes6/boot/mixam';
import urlSubstitues from './appes6/component_ui/constants/url-substitues';
import Logger from './appes6/component_ui/logger';
import ScrollListener from "./appes6/component_ui/scroll-listener";
import santaTabs from "./appes6/component_ui/santa-tabs";
import santaEditor from "./appes6/component_ui/home/santa-editor";
import actionButton from "./appes6/component_ui/action-button";
import Ripple from "./appes6/component_ui/ripple";
import PremiumSubscribe from "./appes6/component_ui/premium-subscribe";
import FixedMessage from "./appes6/component_ui/fixed-message";
import proofDisplay from "./appes6/component_ui/proof-display";
import InframeCommunicator from "./appes6/component_ui/juno/inframe-communicator";
import SlideToTarget from "./appes6/component_ui/slide-to-target";
import EdocBuilder from "./appes6/component_ui/edoc-builder";
import FormWithAnim  from "./appes6/component_ui/form-with-anim-btn";
import signin from "./appes6/component_ui/signin/signin";
import Trustpilot from "./appes6/component_ui/trustpilot/trustpilot-manager";
import FixedMenu from "./appes6/component_ui/fixed-menu";
import ShareManager from "./appes6/component_ui/share/share-manager";
import SharePanel from "./appes6/component_ui/share/share-panel";
import PromotionManager from "./appes6/component_ui/share/promotion-manager";
import PromotionVisibilityMonitor from "./appes6/component_ui/share/promotion-visibility-monitor";
import FullPage from "./appes6/component_ui/full-screen";
import FixedDisplay from "./appes6/component_ui/home/fixed-display";
import QuoteFetch from "./appes6/fetch/quote-fetch";
import PromotionLoader from "./appes6/component_ui/home/promotion-loader";
import HeroCarousel from "./appes6/component_ui/hero-carousel";
import OdeonPlayer from "./appes6/component_ui/home/odeon-player";
import OdeonRemoteControl from "./appes6/component_ui/home/odeon-remote-control";
import SupportPromotion from "./appes6/component_ui/support/search-promo";
import CountryStateSelector from "./appes6/component_ui/country-state-selector/country-state-selector";
import MultiCountrySelector from "./appes6/component_ui/multi-country-selector/multi-country-selector";
import FrontAppLiveChat from "./appes6/component_ui/frontapp/frontapp-live-chat";
import KlaviyoWebTracking from "./appes6/component_ui/klaviyo/klaviyo-web-tracking";
import Header from "./appes6/component_ui/frontend/header";
import newShoppingCartIndicator from "./appes6/component_ui/react-shopping-cart/cart-indicator";
import OrderItemFourthwallAttributesManager from "./appes6/component_ui/order/fourthwall-attributes-manager";
import { initialiseMiniCartWebComponent } from '@mixam-platform/mixam-checkout';
import { initialiseMultiStepProgressBarWebComponent } from '@mixam-platform/mixam-checkout';
import CookieConsentManager from "./appes6/component_ui/cookies/cookie-consent-manager";

// Mixam packages
import {initialisePriceCalculator, initialiseDynamicTemplate} from '@mixam-platform/price-calculator';
import { initialiseSmsNotificationsWebComponent} from '@mixam-platform/mixam-checkout';

function initializePublic(callback = () => {}) {

    $(function() { // document must be ready
        // Attach Flight components...
        Logger.attachTo(document);                                                  // TODO: remove after NewRelic?
        Header.attachTo('[data-type="header"]');
        QuoteFetch.attachTo('[data-type="quoting-motor"]');                         // Santa
        FixedMessage.attachTo('[data-type="notification"]');
        ScrollListener.attachTo(document);

        santaTabs.attachTo("#app-product-tabs");                                    // Santa
        santaEditor.attachTo("#santa-main-frame");                                  // Santa

        signin.attachTo("#signInForm,#registerForm");                               // Login
        actionButton.attachTo('[data-type="action-button"]');                       // Kiosk
        FixedDisplay.attachTo('[data-type="fixed-display"]');                       // Santa
        FixedMenu.attachTo('[data-type="fixed-menu"]');                             // F.A.Q.
        Trustpilot.attachTo('[data-type="trustpilot"]');                            // Landing Pages
        ShareManager.attachTo('[data-type="share-manager"]');                       // Shares
        SharePanel.attachTo('[data-type="share-panel"]');                           // Promotions
        FullPage.attachTo('[data-type="full-screen"]');                             // Shares
        PromotionManager.attachTo('[data-type="promotion-manager"]');               // Shares
        PromotionVisibilityMonitor.attachTo('[data-type="promotion-visibility"]');  // Shares/Home
        Ripple.attachTo('.ripple');                                                 // Utility
        PromotionLoader.attachTo('[data-type="promo-frame"]');                      // Home/Why
        InframeCommunicator.attachTo('[data-type="inframe-communicator"]');         // Kiosk
        SlideToTarget.attachTo('[data-type="slide-to-target"]');                    // Product Pages
        HeroCarousel.attachTo('[data-type="hero-carousel"]');                       // Product Pages
        OdeonPlayer.attachTo('[data-type="odeon-player"]');                         // Home (Videos)
        OdeonRemoteControl.attachTo('[data-type="odeon-remote-control"]');          // Home (Videos)
        EdocBuilder.attachTo('[data-type="edoc-builder"]');                         // Aleyant
        SupportPromotion.attachTo('[data-type="search-promo"]');                    // Support
        FormWithAnim.attachTo('[data-action="form-with-anim-btn"]');                // Utility
        PremiumSubscribe.attachTo('[data-type="premium-subscribe"]');               // Premium Subscribe (PrintMX)
        CountryStateSelector.attachTo('[data-type="country-state-selector"]');      // Country State Selection Combo
        MultiCountrySelector.attachTo('[data-type="multi-country-selector"]');      // Multiple country selection combo
        FrontAppLiveChat.attachTo('[data-type="frontapp-live-chat"]');
        KlaviyoWebTracking.attachTo('[data-type="klaviyo-web-tracking"]');
        newShoppingCartIndicator.attachTo('[data-type="shopping-cart-indicator"]');
        OrderItemFourthwallAttributesManager.attachTo('[data-type="fourthwall-attributes-manager"]');
        CookieConsentManager.attachTo('[data-type="cookie-consent-manager"]');
        initialiseSmsNotificationsWebComponent();

        // Attach Flight components from other layers...
        callback();

        if (window.getProofUrl && window.getProofUrl()) {
            // proof mode
            proofDisplay.attachTo("#proof-cart");
            $('#proof-cart').trigger('loadProof');
        } else {
            // upload mode
            $('#upload-form').validator({
                effect: 'mixam',
                errorClass: 'validation-error',
                grouped: true
            });
        }

        mixam.initialize();

        initialisePriceCalculator();
        initialiseDynamicTemplate();
        initialiseMiniCartWebComponent();
        initialiseMultiStepProgressBarWebComponent();

        // BS3 Needs To Call This To Make Tooltips Work But It Causes An Error When We Are On A BS5 Page
        if (typeof $().tooltip === 'function') {
            $('[data-toggle="tooltip"]').tooltip();
        }

    });

}

export {initializePublic};
