import mixam from '../boot/mixam';
import regional from '../boot/regional';
import Product from './constants/products';
import Bind from './constants/bindings';

export default WithNormalizeShoppingCartItems;

function WithNormalizeShoppingCartItems() {

    this.format = function (sum) {
        return (Math.round(sum * 10) / 10).formatNumber(2);
    };

    this.normalizeItems = function (items) {
        return items.map((item, index) => this.normalizeItem(item, index));
    };

    /**
     * Copies originalItem by extension, manipulates the copy and returns it.
     */
    this.normalizeItem = function (originalItem, index) {
        // originalItem and originalSanta MUST not be mutated (it may be used for reference if we've corrupted the copy)
        const originalSanta = originalItem.santa || originalItem.query;

        // Deep copy of the item, ready to be manipulated
        const normalizedItem = $.extend(true, {}, originalItem);
        if(!normalizedItem.santa) {
            normalizedItem.santa = normalizedItem.query;
        }

        // Begin the normalization process

        normalizedItem.index = index + 1;
        if (normalizedItem.santa.coat1 === 9999) {
            normalizedItem.santa.coat1 = normalizedItem.santa.coat0;
        }

        for (let i = 0; i < 3; i++) {
            const key = 'coat' + i;
            if (!normalizedItem.santa[key]) {
                delete normalizedItem.santa[key];
            }
        }
        if (!normalizedItem.santa.coat2) {
            delete normalizedItem.santa.coat2;
        }

        for (let i = 0; i < 6; i++) {
            if (!normalizedItem.santa['ink' + (i + 1)]) {
                delete normalizedItem.santa['ink' + (i + 1)];
            }
        }

        let hasSpecialPaperContent = false;
        let hasSpecialPaperCover = false;

        if (normalizedItem.santa.paperFinish > 3 && normalizedItem.santa.paperFinish < 7) {
            hasSpecialPaperContent = true;
        }

        if (normalizedItem.santa.coverPaperFinish > 3 && normalizedItem.santa.coverPaperFinish < 7) {
            hasSpecialPaperCover = true;
        }


        /*
         * WARNING
         * From here, any or all of the initial values on our copy (normalizedItem.santa) may have been
         * localised. There for we must switch to using the originalSanta as the point of reference.
         *
         * Remember, deep copies may be needed if assigning anything from originalSanta.
         */


        /*
         * Backup all properties and potentially localisation of them from the regional dictionary, or
         * blank any zeros.
         */
        for (let k in normalizedItem.santa) {
            if (normalizedItem.santa.hasOwnProperty(k)) {
                if (typeof regional().dictionary[k] === 'function') {
                    normalizedItem.santa["_" + k] = normalizedItem.santa[k];
                    normalizedItem.santa[k] = regional().dictionary[k](normalizedItem.santa[k]);
                } else {
                    normalizedItem.santa["_" + k] = normalizedItem.santa[k];
                    normalizedItem.santa[k] = regional().dictionary[k] && regional().dictionary[k][normalizedItem.santa[k]] || (normalizedItem.santa[k] !== 0 ? normalizedItem.santa[k] : "");
                }
            }
        }

        // Localisation of special properties
        if (originalSanta.secondaryFormat) {
            //assigning the already localised version of secondaryFormat
            normalizedItem.santa.format = normalizedItem.santa.secondaryFormat;
            // the localisations for these are need to be looked up
            normalizedItem.santa.formatName = regional().dictionary.secondaryFormatName[originalSanta.secondaryFormat];
            normalizedItem.santa.formatSize = regional().dictionary.secondaryFormatSize[originalSanta.secondaryFormat];
        }

        //custom size
        if (originalSanta.width && originalSanta.height) {
            const numberOfDecimals = mixam.shop.sizeFormat === 'METRIC' ? 0 : 2;
            normalizedItem.santa.format = `${regional().santa.customSized} (${originalSanta.width.formatNumber(numberOfDecimals)}${regional().santa.shoppingCart.mm} x ${originalSanta.height.formatNumber(numberOfDecimals)}${regional().santa.shoppingCart.mm})`;
            normalizedItem.santa.formatName = regional().santa.customSized;
            normalizedItem.santa.formatSize = `${originalSanta.width.formatNumber(numberOfDecimals)}${regional().santa.shoppingCart.mm} x ${originalSanta.height.formatNumber(numberOfDecimals)}${regional().santa.shoppingCart.mm}`;
            normalizedItem.santa.customSize = true;
        }

        if (originalSanta.productId === 1 || originalSanta.productId === 7 || originalSanta.colors === originalSanta.colors2) {
            // we want the localised version of the colors
            normalizedItem.santa.throughout = normalizedItem.santa.colors;
        }

        for (let i = 0; i < 6; i++) {
            if (normalizedItem.santa['ink' + (i + 1)]) {
                normalizedItem.santa['ink' + (i + 1) + 'className'] = normalizedItem.santa['ink' + (i + 1)].replace(/\s+/g, "-");
            }
        }

        if (hasSpecialPaperContent) {
            // we want the localised version of the paperFinish and paperFinishColor
            normalizedItem.santa.paperFinishName = normalizedItem.santa.paperFinish + " " + normalizedItem.santa.paperFinishColor;

            // If this used the originalSanta, would the replacements still be necessary?
            normalizedItem.santa.paperFinishClassName = `paper-${normalizedItem.santa.paperFinish.replace(/[^a-zA-Z0-9]/g, '')}-${normalizedItem.santa.paperFinishColor.replace(/[^a-zA-Z0-9]/g, '')}`;
            normalizedItem.santa.paperFinish = "";
        }
        if (hasSpecialPaperCover) {
            normalizedItem.santa.coverPaperFinishName = normalizedItem.santa.coverPaperFinish + " " + normalizedItem.santa.coverPaperFinishColor;
            normalizedItem.santa.coverPaperFinishClassName = `paper-${normalizedItem.santa.coverPaperFinish.replace(/[^a-zA-Z0-9]/g, '')}-${normalizedItem.santa.coverPaperFinishColor.replace(/[^a-zA-Z0-9]/g, '')}`;
            normalizedItem.santa.coverPaperFinish = "";
        }

        /*
         * Now we're mutating our backup (underscore) copies!
         */
        ['colors', 'colors2', 'coverColors'].forEach(c => {
            normalizedItem.santa['_' + c] = {};
            switch (originalSanta[c]) {
                case 0:
                    normalizedItem.santa['_' + c].NONE = true;
                    break;
                case 1:
                    normalizedItem.santa['_' + c].BLACK = true;
                    break;
                case 5:
                    normalizedItem.santa['_' + c].COLOR = true;
                    break;
            }
        });

        normalizedItem.santa.bodyPages = 1;

        if (originalSanta.productId === Product.BOOKLET
            || originalSanta.productId === Product.PHOTOBOOK
            || originalSanta.productId === Product.BOOK
            || originalSanta.productId === Product.WALL_CALENDAR
            || originalSanta.productId === Product.DESK_CALENDAR) {

            normalizedItem.santa.boundProduct = true;
            if (originalSanta.bind === Bind.CASE) {
                normalizedItem.santa.hardcover = true;
            }
            normalizedItem.santa.bodyPages = originalSanta.pages;

            // hasn't orientation already need localised?
            normalizedItem.santa.orientationIcon = regional().icons.orientation[originalSanta.orientation];
            normalizedItem.santa.bindIcon = regional().icons.bind[originalSanta.bind];

            if (originalSanta.coverType === -1) {
                normalizedItem.santa.selfCovered = true;
            } else {
                normalizedItem.santa.bound = true;
                normalizedItem.santa.additionalPages = 4;
                if (originalSanta.productId === Product.WALL_CALENDAR || originalSanta.productId === Product.DESK_CALENDAR) {
                    if(originalSanta.backCover) {
                        normalizedItem.santa.additionalPages = 2;
                    } else {
                        normalizedItem.santa.additionalPages = "1";
                        normalizedItem.santa.SINGLE_ADDITIONAL_PAGE = true;
                    }
                }

                let coverComponent = originalSanta.itemSpecification.components.find((component) => {
                    return component.componentType === 'COVER';
                });

                if(coverComponent && coverComponent.backColours === 'NONE') {
                    normalizedItem.santa.additionalPages = 2;
                }
            }

            if (originalSanta.bind === Bind.WIRO || originalSanta.bind === Bind.COIL) {
                normalizedItem.santa.USE_BINDING_COLOUR = true;
            } else if (originalSanta.bind === Bind.LOOP) {
                normalizedItem.santa.LOOP = true;
            }

        }

        if (originalItem.response.forceVatable || originalItem.vatable) {
            normalizedItem.hasVat = this.format(originalItem.response.vat.rate * parseFloat(originalItem.response.total) / 100);
        }

        normalizedItem.vatBookmark = "leaflets";
        normalizedItem.productVatText = regional().santa.shoppingCart.flyersAreVat;

        if (originalSanta.pages > 0) {
            normalizedItem.vatBookmark = "brochures-and-pamphlets";
            normalizedItem.productVatText = regional().santa.shoppingCart.bookletsAreVat;
        }

        if (originalItem.response.forceVatable) {
            normalizedItem.productVatText = regional().santa.shoppingCart.standardVatRate;
        }

        if (originalSanta.roundCorners) {
            normalizedItem.santa.ROUND_CORNERS = true;
        }
        if (originalSanta.packaging) {
            normalizedItem.santa.PACKAGING = true;
        }
        if (originalSanta.backCover) {
            normalizedItem.santa.BACK_COVER = true;
        }

        if (originalSanta.sewing) {
            // set on the item, not its santa
            normalizedItem.SEWING = true;
        }

        if (originalSanta.dustJacketColors) {
            // set on the item, not its santa
            normalizedItem.DUST_JACTET = true;
        }


        normalizedItem.response._weight = originalItem.response.weight; // Preserve original, numeric value
        normalizedItem.response.weight = this.format(originalItem.response.weight);

        normalizedItem.response.isDigital = originalItem.response.printType === "DIGITAL";
        normalizedItem.response.isLitho = originalItem.response.printType === "Litho";
        normalizedItem.response.isInkjet = originalItem.response.printType === "INKJET";
        normalizedItem.response.isWideFormat = originalItem.response.printType === "WIDE_FORMAT";

        if (originalItem.response.spine) {
            normalizedItem.response.spine = originalItem.response.spine.formatNumber(mixam.shop.weightFormat === "IMPERIAL" ? 3 : 2);
        }

        if (originalItem.response.includeShipment) {
            normalizedItem.INCLUDES_SHIPMENT = true;
        }

        if (mixam.shop.isUsaShop()) {
            const catalogPaperWeights = this.getCatalogPaperWeights(originalSanta.paperFinish, originalSanta.productId, originalSanta.subProductId);
            const paper = catalogPaperWeights.filter(p => p.id === originalSanta.paper)[0];
            if(paper) {
                normalizedItem.textPaperName = paper.type === 1 ? ' Cover ' : ' Text ';
                normalizedItem.coverPaperName = ' Cover ';
            }
        }
        
        if (originalSanta.ribbonColor > 0) {
            normalizedItem.santa.hasRibbon = true;
        }
        if (originalSanta.headTailBand > 0) {
            normalizedItem.santa.hasHeadTailBand = true;
        }
        if (originalSanta.envelopeType > 0) {
            normalizedItem.santa.hasEnvelope = true;
        }

        let shrinkWrapComponent = originalSanta.itemSpecification.components.find((component) => {
            return component.componentType === 'SHRINK_WRAP';
        });

        if(shrinkWrapComponent) {
            normalizedItem.santa.hasShrinkWrapping = true;
            normalizedItem.santa.shrinkWrapBundleSize = shrinkWrapComponent.bundleSize;
        }

        // Foiling... We Need A Way To Inspect The ItemSpecification Here In Order To Get This Working
        // (Canvas Frame Depth Also Suffers From the Same Issue)
        normalizedItem.santa.hasFoiling = false;
        normalizedItem.santa.hasBackFoiling = false;
        normalizedItem.santa.hasCoverFoiling = false;
        normalizedItem.santa.hasDustJacketFoiling = false;

        return normalizedItem;
    };

}
