import mixam from '../../boot/mixam';
import defineComponent from '../../../components/flight/lib/component';


export default defineComponent(LiveLog);

function LiveLog() {


    this.attributes({
        terminalSelector: 'pre',
        terminalCodeSelector: 'pre code'
    });


    /**
     * subscribe to STOMP service that notify us about changes
     * in a specific order and a specific item
     */
    this.subscribe = function (onMessage) {
        this.trigger("log", {message: [`Subscribe to stomp channel ${this.topicId}`], title: "LiveLog.subscribe"});
        this.stomp = Stomp.client(mixam.stompServiceUrl);

        this.stomp.debug = (...args) => {
            if (args.join('').indexOf('Whoops! Lost connection to') !== -1) {
                setTimeout(() => this.subscribe(onMessage), 10);
            }
            this.trigger("log", {message: args, title: "LiveLog.subscribe"});
        };
        this.stomp.connect(mixam.stompWebUser, mixam.stompWebPass, () => {
            this.stomp.subscribe(`/topic/${this.topicId}`, (d) => {
                const p = JSON.parse(d.body);
                onMessage(p);
            });
        }, (err) => this.trigger("log", err), '/');
    };

    this.onMessage = function (messege) {
        this.messages.push(messege.data.message);
        const $terminal = this.select('terminalSelector');
        this.select('terminalCodeSelector').html(`${this.messages.join('\n')}<span class="blinking-cursor">|</span>` );
        $terminal.scrollTop($terminal.prop("scrollHeight"));
    };

    this.after('initialize', function () {
        this.topicId = this.$node.data("topic");
        this.subscribe((m) => this.onMessage(m));
        this.messages = [`Connecting to sri service on port ${this.topicId}`];
    });
}
