import React from 'react';
import ReactDom from  'react-dom';
import AnimatedCheckRadioBoxList from '../../react-bootstrap/animated/AnimatedCheckRadioBoxList';
import AnimatedCheckRadioBoxListItem from '../../react-bootstrap/animated/AnimatedCheckRadioBoxListItem';
import createTimebox from '../../react-bootstrap/timebox';
import proptypes from 'prop-types';

const rd = ReactDom;

const propTypes = {
  /**
   * Show the component; triggers the expand or collapse animation
   */
  in: proptypes.bool,

  /**
   * CSS class or classes applied when the component is exited
   */
  className: proptypes.string,

  /**
   * data-type associated with the button
   */
  datatype: proptypes.string,

  /**
   * Callback fired before the component expands
   */
  onEnter: proptypes.func,

  /**
   * Callback fired before the component collapses
   */
  onExit: proptypes.func,

  /**
   * icon associated with the button when not active
   */
  icon1ClassName: proptypes.string,

  /**
   * icon associated with the button when  active
   */
  icon2ClassName: proptypes.string,

  /**
   * title associated with the button when not active
   */
  title1: proptypes.string,

  /**
   * title associated with the button when active
   */
  title2: proptypes.string

};

function noop() {
}

const defaultProps = {
  in: false,
  className: "",
  onEnter: noop,
  onExit: noop
};


class Snoose extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = { open: props.in, active: false, loading: false, action: 0 };
  }

  onclick() {
    const open = !this.state.open;
    this.setState({ open });
  }


  componentDidMount() {
    this.taskId = this.props.line.id;
    const content = rd.findDOMNode(this.refs.content);
    content.addEventListener('transitionend', (event) => this.onTransitionEnd(event), false);
  }

  onTransitionEnd(event) {
    if (event.propertyName === "top") {
      this.setState({ active: this.state.open });
    }
  }

  handleSubmit(event) {
    event.preventDefault();
    const url = `/admin/api/tasks/${this.taskId}/snooze`;
    const data = {
      delay: this.state.action,
      customDate: this.refs.targetDate.value
    };
    this.setState({ loading: true });
    $.post(url, data, null, 'json').done(response => {
      this.refs.targetDate.value = "";
      this.setState({ action: 0, loading: false, open: false });
    });
  }

  getSnoozeOptions() {
    const options = [];
    const now = new Date();
    const nowHour = now.getHours();

    if (nowHour > 8 && nowHour < 11) {

      options.push({ title: "Midday", time: 1 });
    }

    if (nowHour >= 8 && now.getHours() < 15) {

      options.push({ title: "Afternoon", time: 2 });
    }

    options.push({ title: "Tomorrow", time: 5 });

    options.push({ title: "Day after tomorrow", time: 6 });

    options.push({ title: "Next week", time: 7 });

    return options;
  }

  onOptionChange(action) {
    this.setState({
      action
    });
  }

  render() {
    const className = (this.state.open ? "open " : "") +
      (this.state.active ? "active " : "") +
      'morph-dialog-button morph-dialog-button-overlay morph-dialog-button-fixed';

    const order = this.props.line.order;
    const options = this.getSnoozeOptions().map((o, i) => {
      return (
        <AnimatedCheckRadioBoxListItem
          key={i}
          checked={o.time === 5}
          value={o.time}
          label={o.title}
          />
      );
    });

    options.push(
      <AnimatedCheckRadioBoxListItem
        key={options.length}
        value={100}
        label="&#160;"
        />);

    const isCustom = this.state.action === 100;
    const dataLoading1 = this.state.loading ? "*" : null;
    const box = createTimebox(this.props.line.dateCreated);
    const typeIcon = this.props.line.typeIcon.icon;
    const repeat = this.props.line.repeat != 0 ? (<span className="badge badge-repeat">{this.props.line.repeat + 1}</span>) : null;
    const typeCaption = this.props.line.typeIcon.title;
    const typeClass = `type-icon type-icon-${this.props.line.type}`;
    const countryClass = `country-icon country-${this.props.line.country}`;

    return (
      <div>
        <div className="createdTimeBox">
          {box}
        </div>
        <div className={typeClass}>
          <div className="timeline-icon">
          <i className={typeIcon}/>
          </div>
          <div className="caption">
              {repeat}
            {typeCaption}
          </div>
        </div>
        <div className={countryClass}></div>
        <div className={className}>
          <button title="Snooze" onClick={(e) => this.onclick(e)}>
            <i className="fa fa-2x fa-clock-o" aria-hidden="true"/>
          </button>
          <div className="content-style-mask">
          </div>
          <div className="morph-dialog-content" ref='content'>
            <div>
              <div className="content-style-overlay">
                <button onClick={(e) => this.onclick(e)} className="close-btn state-close btn-shape-circle" title="Close">
                  <i className="fa fa-times"/>
                </button>

                <div className="dialog">
                  <h2 className="caption">Snooze until...</h2>

                  <p className="captionname">Order {order.caseNumber}</p>

                  <form onSubmit={ (event) => this.handleSubmit(event) } className="form-horizontal product-1" method="post" action="">
                    <div className="list-holder">
                      <AnimatedCheckRadioBoxList onChange={(value, time) => this.onOptionChange(value, time)}>
                        {options}
                      </AnimatedCheckRadioBoxList>
                      <input disabled={!isCustom} ref="targetDate" className="form-control target-date" type="datetime-local"/>
                    </div>

                    <div className="form-group last">
                      <div className="control-save-line">
                        <button type="submit" data-type="save-import" data-style="zoom-out" className="btn btn-outline-white btn-anime btn-anime-zoomout"
                                data-loading={dataLoading1}>
                          <span className="btn-anime-label">OK</span>
                          <span className="btn-anime-spinner"><i className="fa fa-spinner fa-lg fa-spin"></i></span>
                        </button>
                        <button type="button" onClick={(e) => this.onclick(e)} className="btn btn-outline-white">Cancel</button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    );
  }
}


Snoose.propTypes = propTypes;
Snoose.defaultProps = defaultProps;

export default Snoose;
