import { PublicationSpawner } from './PublicationSpawner';
import ReactDom from 'react-dom/client.js';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import reactToWebComponent from 'react-to-webcomponent';
import PropTypes from 'prop-types';
import { StyleSheetManager } from 'styled-components';
var styles = require('./bs-global-styles.css').toString();
var bootstrapLink = '<link ' +
    'rel="stylesheet" ' +
    'href="https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/css/bootstrap.min.css" ' +
    'integrity="sha384-rbsA2VBKQhggwzxH7pPCaAqO46MgnOM80zW1RWuH61DGLwZJEdK2Kadq2F9CUG65" ' +
    'crossorigin="anonymous" ' +
    '/>';
var PublicationSpawnerStyleWrapper = function (_a) {
    var orderid = _a.orderid, itemid = _a.itemid, locale = _a.locale, availableitemspecifications = _a.availableitemspecifications, previouspublications = _a.previouspublications, originalspecification = _a.originalspecification;
    // Do not remove this state.
    // For some strange reason, styled-component's StyleSheetManager needs React state to be set within the same component for it to render the styles.
    var _b = useState(false), _ = _b[0], setPointlessState = _b[1];
    useEffect(function () {
        setPointlessState(true);
    }, []);
    var refContainer = useRef(null);
    var hostStyles = useMemo(function () { return styles.replace(':root', ':host'); }, [styles]);
    return (React.createElement(StyleSheetManager, { target: refContainer.current },
        React.createElement(React.Fragment, null,
            React.createElement("style", null, hostStyles),
            React.createElement("div", { dangerouslySetInnerHTML: { __html: bootstrapLink } }),
            React.createElement("div", { ref: refContainer }),
            React.createElement(PublicationSpawner, { orderId: orderid, itemId: itemid, locale: locale, availableItemSpecifications: JSON.parse(availableitemspecifications), previousPublications: JSON.parse(previouspublications), originalSpecification: originalspecification }))));
};
PublicationSpawnerStyleWrapper.propTypes = {
    orderid: PropTypes.string,
    itemid: PropTypes.string,
    locale: PropTypes.string,
    availableitemspecifications: PropTypes.string,
    previouspublications: PropTypes.string,
    originalspecification: PropTypes.string
};
export var initialisePublicationSpawner = function () {
    window.customElements.define('publication-spawner', reactToWebComponent(PublicationSpawnerStyleWrapper, React, ReactDom, {
        shadow: 'open',
    }));
};
