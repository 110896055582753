import defineComponent from '../../components/flight/lib/component';

export default defineComponent(FixedMenu);

function FixedMenu() {

    this.attributes({
        fixedClassName: "fixed",
        linksSelector: "a[href^=#]"
    });

    this.render = function (event, data) {
        if (this.top <= data.offset) {
            if (!this.$node.hasClass(this.attr.fixedClassName)) {
                this.$node.addClass(this.attr.fixedClassName);
            }
        } else {
            if (this.$node.hasClass(this.attr.fixedClassName)) {
                this.$node.removeClass(this.attr.fixedClassName);
            }
        }

    };

    this.preventJump = function(event) {
        var $target = $(event.target),
            $article;

        event.preventDefault();

        $article = $($target.attr("href"));
        if (!$article.isVisible()) {
            $article.scrollToView(() => {
                this.highlight($article);
            });
        } else {
            this.highlight($article);
        }
    };

    this.highlight = function ($element) {
        $element.hilightElement();
        $element.next("dd").hilightElement();
    };

    this.onResize = function () {
        if (this.$node.hasClass(this.attr.fixedClassName)) {
            this.$node.removeClass(this.attr.fixedClassName);
        }
        this.$node.width();
        this.top = $(this.node).offset().top;
        this.render(null, {offset: document.documentElement.scrollTop || document.body.scrollTop});
    };

    this.after('initialize', function () {
        this.onResize();
        this.on(document, "uiPageScroll", this.render);
        $(window).resize($.proxy(this.onResize, this));
        this.on("click", {
            linksSelector: this.preventJump
        });
    });
}
