import mixam from '../boot/mixam';

export default WithCustomerDiscount;

/**
 * TODO Move this server side ready for the new React Santa.
 */
function WithCustomerDiscount() {

    this.applyDiscount = function (price, santa) {
        const rules = mixam.getUserDiscountRules();
        let newPrice = price;
        if (rules){
            rules.filter(d => this.isValid(d) &&
            (d.productId === undefined || d.productId === santa.productId) &&
            (d.copies === undefined || d.copies <= santa.copies) &&
            (d.bindId === undefined || d.bindId === santa.bind)).
            forEach(d => {
                const sum = d.method === "PERCENT" ? d.sum / 100 * price : d.sum;
                newPrice -= sum;
            });
        }
        return newPrice;
    };

    this.isValid = function (rule) {
        return rule.isActive &&
            ["PERCENT", "FIXED"].indexOf(rule.method) !== -1 &&
            rule.type &&
            rule.sum;
    };
}
