import defineComponent from '../../../../components/flight/lib/component';
import DataTable from './../../table/data-table-net/data-table-net.tsx';
import WithArticles from './with-articles';
import {Breakpoints} from "../../table/data-table-net/StyledDataTableNet.tsx";
import mixam from "../../../boot/mixam";

export default defineComponent(AdminArticlesManager, WithArticles);

function AdminArticlesManager() {

    this.attributes({
        url1: "/resources/list.json",
        url: "/admin/api/article/list",
        dataTableSelector: '[data-type="data-table"]'
    });

    this.createSchema = function () {
        var cols = [];

        cols.push({
            title: "",
            type: "thumbnail",
            data: "front"
        });

        cols.push({
            title: "Order",
            type: "link",
            data: "href"
        });

        cols.push({
            title: "Dispatch",
            type: "dispatch",
            data: "dateDispatch",
            width: "170px",
            update: "finalDate",
            sortFunction:(rowA, rowB) => {
                const dateA = this.calculateFinalDate(rowA.delays, rowA.dateDispatch);
                const dateB = this.calculateFinalDate(rowB.delays, rowB.dateDispatch);

                if (dateA < dateB) {
                    return -1;
                }
                if (dateA > dateB) {
                    return 1;
                }

                const delaysCountA = rowA.delays.length;
                const delaysCountB = rowB.delays.length;

                return delaysCountB - delaysCountA;
            }
        });

        cols.push({
            title: "Delivery",
            type: "date",
            data: "dateDelivery"
        });

        cols.push({
            title: "Behind",
            type: "number",
            decimal: 0,
            data: "daysBehind",
            defaultSort: true,
            sortOrder: 'desc'
        });

        cols.push({
            title: "Total Days Behind",
            type: "number",
            decimal: 0,
            data: "totalDaysBehindDispatchDate"
        });

        cols.push({
            title: "Delays",
            type: "number",
            decimal: 0,
            data: "delayCount"
        });

        cols.push({
            title: "Supplier",
            type: "text",
            data: "supplierName"
        });

        cols.push({
            title: "Status",
            type: "command",
            width: "130px",
            data: "status",
            allowSilentMode: true
        });

        cols.push({
            title: "Type",
            type: "order-type",
            data: "types",
            hide: Breakpoints.XL
        });

        cols.push({
            title: "Is Proof",
            type: "boolean",
            data: "proof"
        });

        cols.push({
            title: "Proof",
            type: "proof-update-modal",
            data: "proof",
            width: '150px'
        });

        cols.push({
             title: "Dispatch",
             type: "deliveries",
             data: "deliveries",
             width: "150px",
             hide: Breakpoints.MD
        });

        cols.push({
            title: "Confirmed",
            type: "timebox",
            data: "dateConfirmed",
            hide: Breakpoints.MD
        });

        cols.push({
            title: "Fulfilled",
            type: "timebox",
            data: "dateCreated",
            hide: Breakpoints.MD
        });

        cols.push({
            title: "Express",
            type: "boolean",
            data: "isExpress",
            hide: Breakpoints.LG
        });

        cols.push({
            title: "Digital",
            type: "boolean",
            data: "isDigital",
            hide: Breakpoints.LG

        });

        cols.push({
            title: "Machine",
            type: "text",
            data: "machine"
        });

        cols.push({
            title: "Copies",
            type: "number",
            data: "copies",
            hide: Breakpoints.LG
        });

        cols.push({
            title: "Size",
            type: "text",
            data: "size",
            hide: Breakpoints.LG
        });

        cols.push({
            title: "Orientation",
            type: "icon",
            data: "orientation",
            hide: Breakpoints.XL
        });

        cols.push({
            title: "Bind",
            type: "icon",
            data: "bind",
            hide: Breakpoints.XL
        });

        cols.push({
            title: "Pages",
            type: "number",
            data: "pages",
            hide: Breakpoints.XXL
        });

        cols.push({
            title: "Sum",
            type: "number",
            data: "total",
            sum: true,
            currency: true,
            decimal: 2,
            prepend: mixam.shop.currency.prefix,
            hide: Breakpoints.XXL
        });

        return cols;
    };

    this.calculateFinalDate = (delays, dateDispatch) => {
        let finalDate = new Date(dateDispatch);
        if (delays.length) {
            finalDate = new Date(delays[delays.length - 1].date);
        }
        return finalDate.getTime();
    };

    this.normalize = function (list) {
        const suppliers = this.getSuppliersMap();
        const providersData = {};
        const all = [];

        let total = 0;
        let count = 0;

        // filter by provider
        list.forEach((article, i) => {
            if (!providersData[article.providerId]) {
                providersData[article.providerId] = suppliers[article.providerId];
            }
            const normalArticle = this.normalizeItem(article, i);
            all.push(normalArticle);
            if (providersData[article.providerId]) {
                providersData[article.providerId].list.push(normalArticle);
                providersData[article.providerId].cost += article.item.fulfillment.cost;
                providersData[article.providerId].filterUrl = `{"and":{"supplierName":{"cn":"${providersData[article.providerId].name}"}},"or":{}}`;
                providersData[article.providerId].jobsBehind += normalArticle.daysBehind ? 1 : 0;
                total += article.item.fulfillment.cost;
                count += 1;
            }
            normalArticle.machine = article.item.fulfillment.machine;
        });

        // calculate percentage
        Object.keys(providersData).forEach(p => {
            const provider = providersData[p];
            if (provider) {
                provider.costPercent = Math.round(provider.cost / total * 100);
                provider.countPercent = Math.round(provider.list.length / count * 100);
            } else {
                delete providersData[p];
            }
        });

        providersData.total = total;
        providersData.count = count;

        this.trigger("articleDataReady", providersData);
        return {all, providersData};
    };

    this.getSuppliersMap = function () {
        const map = {};
        window.getSuppliers().forEach(s => (map[s.id] = s, s.list = [], s.cost = 0, s.jobsBehind = 0));
        return map;
    };

    this.getData = function () {
        $.getJSON(this.attr.url)
            .done(data => {
                this.data = data;
                this.paint();
                setTimeout(() => this.getData(), 1000 * 60 * 15);
            })
            .fail(() => this.error("data-file-missing"));
    };

    this.paint = function () {
        const data = {
            columns: this.createSchema(),
            fileName: this.attr.url.split("/").pop(),
            response: this.normalize(this.data.list).all
        };

        if (this.dataReadyFired) {
            window.dispatchEvent(new CustomEvent('uiDataUpdate', { detail: data }));
        } else {
            this.dataReadyFired = true;
            this.trigger("uiDataReady", data);
        }
    };

    this.after('initialize', function () {
        DataTable.attachTo(this.select('dataTableSelector'));
        setTimeout(() => this.getData(), 10);
        this.on(document, 'uiRequestArticleStatusChange', this.onStatusChangeCommand);
        this.on(document, 'uiRequestArticleDateChange', this.onDateChangeCommand);
        try {
            this.subscribe(this.updateArticleLine.bind(this));
        } catch (e) {

        }
    });
}
