import mixam from '../../boot/mixam';
import defineComponent from '../../../components/flight/lib/component';

export default defineComponent(SharePanel);

function SharePanel() {

    this.attributes({
        linkSelector: "a"
    });

    this.doClick = function(event) {
        var $target = $(event.target).closest('a');

        if ($target.hasClass('embed')) {
            event.preventDefault();
        }

        if (!mixam.user.hasRole("ROLE_MIXAM")) {
            $.post(mixam.springUrl(`/counter/SHARE_CLICK/${$target.data('count')}/add`));
        }
    };

    this.after('initialize', function () {
        this.on("click", {
            linkSelector: this.doClick
        });
    });
}
