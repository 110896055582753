/***
 *  Throttling services
 *  Provides less blocking services as a mixin
 *
 *  debounce - run after n milliseconds on inactivity.
 *  throttle - run as fast as once in n milliseconds.
 *  runUnblocking - do work on array in chunks of 50 milliseconds
 *
 *  effie.n@taboola.com
 *  30 Jan 2015
 */

export default function WithThrottling() {
    var todoItems,
        timer;
    /**
     * Debounce's decorator
     * @param {Function} fn original function
     * @param {Number} timeout timeout
     * @param {Boolean} [invokeAsap=false] invoke function as soon as possible
     * @param {Object} [ctx] context of original function
     */
    this.debounce = function (fn, timeout, invokeAsap, ctx) {

        if (arguments.length === 3 && typeof invokeAsap !== 'boolean') {
            ctx = invokeAsap;
            invokeAsap = false;
        }

        var timer;

        return function () {

            var args = arguments;
            ctx = ctx || this;

            invokeAsap && !timer && fn.apply(ctx, args);  // jshint ignore:line

            clearTimeout(timer);

            timer = setTimeout(function () {
                invokeAsap || fn.apply(ctx, args);// jshint ignore:line
                timer = null;
            }, timeout);

        };
    };

    /**
     * Throttle's decorator
     * @param {fn} fn original function
     * @param {Number} timeout timeout
     * @param {Object} [ctx] context of original function
     */
    this.throttle = function (fn, timeout, ctx) {
        var last,
            deferTimer;

        timeout = timeout || 250;

        return function () {
            var now = +new Date(),
                args = arguments;

            clearTimeout(deferTimer);

            if (last && now < last + timeout) {
                // hold on to it
                deferTimer = setTimeout(function () {
                    last = now;
                    fn.apply(ctx, args);
                }, timeout);
            } else {
                last = now;
                fn.apply(ctx, args);
            }
        };
    };


    // non blocking array processor
    // time-based chunking

    this.runUnblocking = function (items, process, context, interval, callback) {
        var TASK_INTERVAL = interval || 50,
            YIELD_INTERVAL = 0;

        //create a clone of the original
        todoItems = [].slice.call(items);

        function runTask() {
            var start = +new Date();

            do {
                process.call(context, todoItems.shift());
            } while (todoItems.length > 0 && (+new Date() - start < TASK_INTERVAL));

            if (todoItems.length > 0) {
                timer = setTimeout(runTask, YIELD_INTERVAL);
            } else {
                callback && callback.call(context, items);// jshint ignore:line
            }
        }

        clearTimeout(timer);
        timer = setTimeout(runTask, 0);
    };
}
