import defineComponent from '../../../../components/flight/lib/component';
import DataTable from '../../table/data-table-net/data-table-net.tsx';
import axios from "axios";

export default defineComponent(PageRangesDatatable);

function PageRangesDatatable() {

    this.attributes({
        dataTableSelector: '[data-type="data-table"]'
    });

    this.createSchema = function () {
        const cols = [];
        cols.push({
            title: "Binding",
            type: "text",
            data: "bindingTypes"
        });
        cols.push({
            title: "Paper Types",
            type: "text",
            data: "substrateTypes"
        });
        cols.push({
            title: "",
            type: "multiButton",
            data: "actions",
            width: '175px'
        });
        return cols;
    };


    this.paint = function () {
        const dataTableOptions = {
            columns: this.createSchema(),
            fileName: 'page-ranges',
            response: this.normalize(this.data)
        };

        if (this.dataReadyFired) {
            window.dispatchEvent(new CustomEvent('uiDataUpdate', { detail: dataTableOptions }));
        } else {
            this.dataReadyFired = true;
            this.trigger("uiDataReady", dataTableOptions);
        }
    };

    this.normalize = function (list) {
        return list.map(x => this.normalizePageRange(x));
    };

    this.normalizePageRange = function (pageRangeTuple) {
        const pageRange = $.extend(true, {}, pageRangeTuple);
        pageRange.actions = [
            {
                key: `delete-${pageRange.fingerprint}`,
                caption: "Delete",
                className: "btn btn-danger",
                callback: (event) => {
                    if (confirm('Are you sure you want to delete this record?')) {
                        axios.delete(
                            `/admin/catalogue/suppliers/${this.supplierId}/page-ranges/${pageRange.fingerprint}`
                        ).then(
                            () => {
                                // Re-sort the rows...
                                this.trigger('uiPageRangesUpdated', {});
                            }
                        ).catch(
                            error => {
                                console.log(error);
                                alert('Error Deleting Data: ' + error.response.data.message);
                            }
                        );
                    }
                    event.preventDefault();
                }
            },
            {
                href: `page-ranges/${pageRange.fingerprint}/edit`,
                caption: "Edit",
                target: "_self",
                className: "btn btn-primary"
            }
        ];
        pageRange.bindingTypes = pageRangeTuple.bindingPageRanges.bindingTypes
            ? pageRangeTuple.bindingPageRanges.bindingTypes.join(', ')
            : '';
        pageRange.substrateTypes = pageRangeTuple.substrateTypePageRanges.substrateTypes
            ? pageRangeTuple.substrateTypePageRanges.substrateTypes.join(', ')
            : '';
        return pageRange;
    };

    this.getData = function () {
        axios.get(`/admin/catalogue/suppliers/${this.supplierId}/page-ranges`)
            .then(response => {
                this.data = response.data;
                this.paint();
            }).catch(error => this.trigger("log", {message: error}));
    };

    this.after('initialize', function () {
        this.supplierId = $(this.node).data('supplier-id');
        DataTable.attachTo(this.select('dataTableSelector'));
        this.on(document, 'uiPageRangesUpdated', this.getData);
        setTimeout(() => this.getData(), 10);
    });

}
