var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { BootstrapConfig } from "@react-awesome-query-builder/bootstrap";
import AwesomeQueryBuilderHelper from "../../AwesomeQueryBuilder/AwesomeQueryBuilderHelper";
var InitialConfig = BootstrapConfig;
var ParticipationRuleConfig = {
    getConfig: function (configData) {
        var config = __assign(__assign({}, InitialConfig), { fields: {
                shop: {
                    label: "Shop",
                    type: "!struct",
                    subfields: {
                        id: {
                            label: "Shop ID",
                            type: 'select',
                            valueSources: ["value"],
                            fieldSettings: {
                                listValues: AwesomeQueryBuilderHelper.mapToOptionList(configData, 'shops')
                            }
                        }
                    },
                },
                supplier: {
                    label: "Supplier",
                    type: "!struct",
                    subfields: {
                        id: {
                            label: "Supplier ID",
                            type: 'select',
                            valueSources: ["value"],
                            fieldSettings: {
                                listValues: AwesomeQueryBuilderHelper.mapToOptionGroupList(configData, 'allShopSuppliers')
                            }
                        }
                    },
                },
                machine: {
                    label: "Machine",
                    type: "!struct",
                    subfields: {
                        id: {
                            label: "Machine ID",
                            type: 'select',
                            valueSources: ["value"],
                            fieldSettings: {
                                listValues: AwesomeQueryBuilderHelper.mapToOptionGroupList(configData, 'allShopMachines')
                            }
                        },
                        isDisabled: {
                            label: "Is Disabled",
                            type: 'boolean'
                        }
                    },
                },
                dimensions: {
                    label: "Dimensions",
                    type: "!struct",
                    subfields: {
                        width: {
                            label: "Width",
                            type: 'number',
                        },
                        height: {
                            label: "Height",
                            type: 'number',
                        }
                    },
                },
                spineWidth: {
                    label: "Spine Width",
                    type: "number"
                },
                longEdge: {
                    label: "Long Edge",
                    type: "number"
                },
                shortEdge: {
                    label: "Short Edge",
                    type: "number"
                },
                query: {
                    label: "Query",
                    type: "!struct",
                    subfields: {
                        subProductId: {
                            label: "Sub Product ID",
                            type: 'number',
                        },
                        santaType: {
                            label: "Santa Type",
                            type: 'select',
                            valueSources: ["value"],
                            fieldSettings: {
                                listValues: AwesomeQueryBuilderHelper.getConfigEnumOption(configData, 'santaTypes')
                            },
                            widgets: {
                                select: {
                                    widgetProps: {
                                        spelFormatValue: function (val) {
                                            return AwesomeQueryBuilderHelper.getEnumSpelFormat('com.mixam.shop.models.SantaType', val);
                                        }
                                    }
                                },
                                multiselect: {
                                    widgetProps: {
                                        spelFormatValue: function (val) {
                                            return AwesomeQueryBuilderHelper.getMultiSelectEnumSpelFormat('com.mixam.shop.models.SantaType', val);
                                        }
                                    }
                                }
                            }
                        }
                    },
                },
                itemSpecification: AwesomeQueryBuilderHelper.getItemSpecificationConfig(configData)
            } });
        return config;
    }
};
export default ParticipationRuleConfig;
