
        var result = require("!!../../../../../../../../../node_modules/css-loader/dist/cjs.js!./bs-global-styles.css");

        if (result && result.__esModule) {
            result = result.default;
        }

        if (typeof result === "string") {
            module.exports = result;
        } else {
            module.exports = result.toString();
        }
    