import mixam from '../../boot/mixam';
import defineComponent from '../../../components/flight/lib/component';
import DataTable from '../table/data-table-net/data-table-net.tsx';
import WithOrdersItemsMembers from './with-orders-items-members';
import WithOrdersItems from './with-order-items';
import WithSignDataRequest from './../with-sign-data-request';
import { Breakpoints } from '../table/data-table-net/StyledDataTableNet.tsx';

// NOTE: this import looks unused, but it's very much needed!
import dd from 'dates';

export default defineComponent(WithOrdersItemsMembers, WithOrdersItems, AdminAccountsPayable, WithSignDataRequest);

function AdminAccountsPayable() {

    this.attributes({
        firstTimeUrl: `${mixam.reporterOrigin}/reporter/admin/api/shop/${mixam.shop.id}/orders/fulfilments/first`,
        url: `${mixam.reporterOrigin}/reporter/admin/api/shop/${mixam.shop.id}/orders/fulfilments`,
        dataTableSelector: '[data-type="data-table"]'
    });

    this.error = function (err) {
        if (console) {
            console.log(err);
        }
    };

    this.createSchema = function () {
        var cols = [];

        cols.push({
            title: '',
            type: "thumbnail",
            data:"front"
        });

        cols.push({
            title: "Order",
            type: "link",
            data:"href"
        });

        cols.push({
            title: "Created",
            type: "timebox",
            data:"time",
            defaultSort: true,
            sortOrder: 'desc'
        });

        cols.push({
            title: "Username",
            type: "user",
            width: "9%",
            data: "user"
        });

        cols.push({
            title: "Status",
            type: "text",
            data: "status",
            width: "9%"
        });

        cols.push({
            title: "Fulfilment",
            type: "timebox",
            data:"deliveryDate"
        });

        cols.push({
            title: "Express",
            type: "boolean",
            data:"isHasExpress",
            hide: Breakpoints.XXL,
        });

        cols.push({
            title: "Supplier",
            type: "text",
            data: "supplier",
            width: '200px',
            hide: Breakpoints.XXL,
        });


        cols.push({
            title: "Value",
            decimal: 2,
            type: "number",
            data: "value",
            sum: true,
            currency: true,
            hide: Breakpoints.SM,
        });

        cols.push({
            title: "Sum",
            type: "number",
            decimal: 2,
            data: "total",
            sum: true,
            currency: true,
            hide: Breakpoints.SM,
        });

        cols.push({
            title: "Expected Cost",
            type: "number",
            decimal: 2,
            currency: true,
            data: "expectedCost",
            sum: true,
            hide: Breakpoints.MD,
        });

        cols.push({
            title: "Actual Cost",
            type: "CommandFulfilment",
            data: "fulfillmentSumDisplay",
            hide: Breakpoints.MD,
        });

        cols.push({
            title: "Packaging Cost",
            type: "number",
            data: "packagingCost",
            decimal: 2,
            currency: true,
            sum: true,
            hide: Breakpoints.MD,
        });

        cols.push({
            title: "Has Remark",
            type: "boolean",
            data: "hasRemark",
            hide: Breakpoints.LG,
        });

        cols.push({
            title: "Remark",
            type: "text",
            data: "fulfillmentRemark",
            hide: Breakpoints.LG,
        });

        cols.push({
            title: "In Dispute",
            type: "boolean",
            data: "fulfillmentDispute",
            hide: Breakpoints.LG,
        });

        cols.push({
            title: "Discrepancy",
            type: "number",
            decimal: 2,
            data: "overflow",
            sum: true,
            currency: true,
            hide: Breakpoints.XL,
        });

        cols.push({
            title: "%",
            type: "number",
            decimal: 2,
            "append": "%",
            data: "overflowPercent",
            hide: Breakpoints.XL,
        });

        cols.push({
            title: "",
            type: "fulfilment-update-modal",
            data: "fulfilmentUpdate",
            width: '150px'
        });

        return cols;
    };

    this.fulfilmentChange = function (event, data) {
        $.post(`/admin/api/fulfilment/${data.order}/${data.item}/update`, data).done((/*response*/) => true);
    };

    this.isRelevantMessage = function (data) {
        if (data.type && data.category && data.status) {
            if (data.order.shopId === mixam.shop.id && data.order.orderStatusInt >= 11 && data.order.orderStatusInt < 100 && data.order.shopId === mixam.shopId) {
                return true;
            }
        }
        return false;
    };

    this.after('initialize', function () {
        DataTable.attachTo(this.select('dataTableSelector'));
        this.defaultSort = {
            "deliveryDate": -1
        };

        this.on(document, "click.ask.for.notify.permissions", this.askForNotifyPermissions);

        this.on(document, "uiRequestFulfilmentStatusChange", this.fulfilmentChange);

        this.on(document, "uiDataImportComplete", () => {
            this.getData();
        });

        setTimeout(() => this.getData(), 10);

        try {
            this.subscribe(this.updateOrderLine.bind(this));
        } catch (e) {

        }
    });
}
