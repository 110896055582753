import mixam from '../../boot/mixam';
import defineComponent from '../../../components/flight/lib/component';
import MemberAddressManager from './member-address-manager';
import AnimatedCheckbox from '../animated/animated-checkbox';

// NOTE: these imports looks unused, but are very much needed!
import Notify from '../../../components/notify.js/notify';
import stomp from '../../../components/stomp/stomp171';
import lightbox from 'lightbox';

export default defineComponent(MemberManager);

function MemberManager() {

    this.attributes({
        addressManagerSelector: '[data-type="address-manager"]',
        tabsSelector: '[data-toggle=tab]',
        addressFormSelector: '#addressForm',
        detailsFormSelector: '#detailsForm',
        saveBtnSelector: 'button[type="submit"]',
        passwordFormSelector: '#passwordForm',
        animatedCheckboxSelector: '[data-toggle="animated-checkbox"]'
    });

    this.afterTabSelect = function (event) {
        const $target = $(event.target);
        let href = $target.attr('href').replace(/.*(?=#[^\s]*$)/, '');

        if (this.routing) {
            return;
        }

        requestAnimationFrame(() => {
            href = (href.charAt(0) !== '/' ? "/" : "") + href;
            this.$node.attr("data-tab", href.substr(1));
            dataLayer.push({event: 'pageview'});
        });
    };

    this.setUpForms = function (selectors) {
        selectors.forEach(formSelector => {
            const $form = this.select(formSelector);

            $form.validator({
                effect: 'mixam',
                errorClass: 'validation-error',
                errorInputEvent: 'keyup',
                formEvent: 'submit',
                inputEvent: null,
                grouped: true
            });

            $form.on("submit", (event) => {
                if (!event.isDefaultPrevented()) {
                    $form.find(this.attr.saveBtnSelector).attr('data-loading', '*').attr('disabled', true);
                }
            });
        });
    };

    this.after('initialize', function () {
        AnimatedCheckbox.attachTo(this.select('animatedCheckboxSelector'));
        this.on('shown.bs.tab', {
            'tabsSelector': this.afterTabSelect
        });

        MemberAddressManager.attachTo(this.select('addressManagerSelector'));

        this.setUpForms(['addressFormSelector', 'detailsFormSelector', 'passwordFormSelector']);

    });

}


