import defineComponent from '../../../components/flight/lib/component';
import axios from "axios";
import mixam from "../../boot/mixam";

export default defineComponent(MultiCountrySelector);

const apiKey = 'YXNoNGRtMGdkeXo5b2FFeGprODFEd0ZVRjNlZ1F1V0o3TmxaQVZTMg==';

function MultiCountrySelector() {

    this.attributes({
        countrySelectSelector: '[data-field="country-code"]',
        countryLoaderSelector: '[data-type="country-loader"]'
    });

    this.getAllCountries = async function () {
        const self = this;
        try {
            const response = await axios({
                method: 'get',
                url: 'https://api.countrystatecity.in/v1/countries',
                headers: {
                    'X-CSCAPI-KEY': apiKey
                }
            });
            self.initCountrySelect(response.data);
        } catch (error) {
            console.error("Error occurred:", error);
        }
    };

    this.initCountrySelect = function (allCountries) {
            let initialValues = this.select('countrySelectSelector').data('initial-value');
            let countryOptions = '';
            if (allCountries) {
                countryOptions = allCountries.map(country => {
                    const iso2 = country.iso2;
                    const name = country.name;
                    const selected = initialValues
                        .split(',')
                        .map(l => l.replace(/\W/ig, ""))
                        .includes(iso2) || iso2 === mixam.shop.locale.slice(-2);

                    return `<option value="${iso2}"${selected ? ' selected="true"' : ''}>${name}</option>`;
                }).join('');

                this.select('countrySelectSelector').empty().append(countryOptions);
                this.select('countrySelectSelector').prop('disabled', false);
                this.select('countryLoaderSelector').addClass('d-none');
                this.select('countrySelectSelector').removeClass('d-none');
            }
    };

    this.after('initialize', function () {
        this.countryCode = $(this.node).data('country-code');
        this.getAllCountries();
        this.on('change', {
            countrySelectSelector: this.initStates
        });
    });

}
