import defineComponent from '../../../../components/flight/lib/component';
import DataTable from './../../table/data-table-net/data-table-net.tsx';
import {Modal} from "bootstrap";
import axios from "axios";

export default defineComponent(RedirectsDataTable);

function RedirectsDataTable() {

    this.attributes({
        url: "/api/admin/redirects",
        dataTableSelector: '[data-type="data-table"]',
        confirmDeleteModal: '[data-type="delete-redirect-confirmation"]'
    });

    this.createSchema = function () {
        const cols = [];
        cols.push({
            title: "From URL",
            type: "text",
            data: "fromUrl"
        });
        cols.push({
            title: "To URL",
            type: "text",
            data: "toUrl"
        });
        cols.push({
            title: "Redirect Type",
            type: "text",
            data: "redirectType"
        });
        cols.push({
            title: "",
            type: "multiButton",
            width: "180px",
            data: "actions"
        });
        return cols;
    };

    this.paint = function () {
        const data = {
            columns: this.createSchema(),
            response: this.normalize(this.data)
        };

        if (this.dataReadyFired) {
            window.dispatchEvent(new CustomEvent('uiDataUpdate', { detail: data }));
        } else {
            this.dataReadyFired = true;
            this.trigger("uiDataReady", data);
        }
    };

    this.normalize = function (data) {
        return data.map(x => this.normalizeGroup(x));
    };

    this.normalizeGroup = function (redirect_) {
        const redirect = $.extend(true, {}, redirect_);

        redirect.actions = [
            {
                href: `/admin/shop/redirects/${redirect.id}/edit`,
                caption: "Edit",
                className: "btn btn-primary",
                iconClassname: "bi bi-pencil",
                target: "_self"
            },
            {
                href: `/api/admin/redirects/${redirect.id}`,
                caption: "Delete",
                className: "btn btn-danger",
                iconClassname: "bi bi-trash",
                key: "delete",
                role: "ROLE_MIXAM",
                target: "_self",
                callback: (event) => {
                    event.stopPropagation();
                    event.preventDefault();

                    let buttonTarget = $(event.target);

                    const confirmationDialogue = document.querySelector('[data-type="delete-redirect-confirmation"]');
                    let modal = Modal.getOrCreateInstance(confirmationDialogue);
                    modal.show();

                    $(document.querySelector('[data-type="confirm-button"]')).click(function() {
                        fetch(
                            buttonTarget.attr('href'),
                            {
                                method: 'DELETE',
                            }
                        ).then((response) => {
                            if(response.status === 200) {
                                buttonTarget.closest('.rdt_TableRow').remove();
                            }
                        });
                        modal.hide();
                    });
                }
            }
        ];
        return redirect;
    };

    this.getData = function () {
        axios.get(this.attr.url)
            .then(response => {
                this.data = response.data;
                this.paint();
                setTimeout(() => this.getData(), 1000 * 60 * 15);
            }).catch(error => this.trigger("log", {message: error}));
    };

    this.after('initialize', function () {
        DataTable.attachTo(this.select('dataTableSelector'));
        setTimeout(() => this.getData(), 10);
    });
}