import WithSubmitAnimation from './with-submit-zoom-animation';
import defineComponent from '../../components/flight/lib/component';

export default defineComponent(FormWithAnim, WithSubmitAnimation);

function FormWithAnim() {

    this.attributes({
        saveBtnSelector: '[type="submit"]',
    });

    this.after('initialize', function () {
        this.on('submit',  this.handleSubmit);
    });
}
