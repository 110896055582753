import defineComponent from '../../components/flight/lib/component';

var lastRipple = +new Date();


export default defineComponent(Ripple);

function Ripple() {
    this.attributes({
        rippleInkClassName: "ripple-ink",
        inkSelector: "span.ripple-ink:first"
    });

    this.handleClick = function (event) {
        var parentOffset = this.$node.offset(),
            clickX = event.pageX - parentOffset.left,
            clickY = event.pageY - parentOffset.top,
            $ink = this.$node.children(this.attr.inkSelector),
            now = +new Date(),
            maxSize;

        if ((now - lastRipple) < 200) {
            return;
        }
        lastRipple = +new Date();

        if (!$ink.length) {
            $ink = $("<span />", {
                "class": this.attr.rippleInkClassName,
                style: "width:0;height:0"
            }).appendTo(this.$node);
        }
        $ink.removeClass('animate');
        if (!$ink.height() && !$ink.width()) {
            maxSize = Math.max(100, Math.max(this.node.offsetWidth, this.node.offsetHeight));
            $ink.css("width", maxSize + 'px');
            $ink.css("height", maxSize + 'px');
        }
        // clickX = event.clientX - this.node.offsetLeft + window.pageXOffset - parseInt($ink.width()) / 2;
        // clickY = event.clientY - this.node.offsetTop + window.pageYOffset - parseInt($ink.height()) / 2;
        clickX = clickX - parseInt($ink.width()) / 2;
        clickY = clickY - parseInt($ink.height()) / 2;
        $ink.css("left", clickX + 'px');
        $ink.css("top", clickY + 'px');
        return $ink.addClass('animate');
    };

    this.animationEnds = function (event) {
        const $target = $(event.target);
        $target.removeClass('animate');
    };

    this.after('initialize', function () {
        this.on("click", this.handleClick);
        this.on("animationend", this.animationEnds);
    });
}

