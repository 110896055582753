import React, { useEffect, useState } from 'react';
import { Row, Col, Spinner } from 'react-bootstrap';
import ParticipationRuleEditor from "./Components/ParticipationRuleEditor";
import axios from "axios";
var participationPolicyUrl = "/admin/api/participation-policies";
var ParticipationRuleBuilder = function (props) {
    var _a = useState(true), isDataLoading = _a[0], setDataLoading = _a[1];
    var _b = useState({
        participationPolicyType: 'GLOBAL',
        active: true,
        shopIds: [],
        machineIds: [],
        supplierIds: []
    }), participationPolicy = _b[0], setParticipationPolicy = _b[1];
    var getPaticipationPolicy = function () {
        axios.get("".concat(participationPolicyUrl, "/").concat(props.participationPolicyId))
            .then(function (response) {
            setParticipationPolicy(response.data);
            setDataLoading(false);
        }).catch(function (error) {
            alert("Error Retrieving Participation Policy Data: ".concat(error));
            setDataLoading(false);
        });
    };
    useEffect(function () {
        if (props.participationPolicyId) {
            getPaticipationPolicy();
        }
        else {
            setDataLoading(false);
        }
    }, []);
    return (React.createElement(Row, null,
        React.createElement(Col, null,
            isDataLoading &&
                React.createElement(Spinner, { animation: "grow", variant: "primary" }),
            !isDataLoading &&
                React.createElement(ParticipationRuleEditor, { participationPolicy: participationPolicy }))));
};
export default ParticipationRuleBuilder;
