import React from 'react';
import axios from "axios";
import { Formik, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
var validationSchema = Yup.object().shape({
    sku: Yup.string().required('SKU is required'),
    asn: Yup.string().required('ASN is required')
});
export var FourthwallAttributesManager = function (props) {
    var fourthwallAttributesUrl = "/api/orders/".concat(props.orderId, "/items/").concat(props.itemId, "/fourthwall/attributes");
    return (React.createElement(Formik, { initialValues: props, validationSchema: validationSchema, onSubmit: function (values, actions) {
            axios.post(fourthwallAttributesUrl, {
                asn: values.asn,
                sku: values.sku
            })
                .then(function (response) {
                window.location.reload();
            })
                .catch(function (error) {
                console.error('Error saving fourthwall attributes:', error);
            })
                .finally(function () {
                actions.setSubmitting(false);
            });
        } }, function (formikProps) { return (React.createElement("form", { onSubmit: formikProps.handleSubmit },
        React.createElement("h4", null, "Fourthwall Attributes"),
        React.createElement("label", null, "SKU"),
        React.createElement(Field, { type: "text", name: "sku", className: "form-control", disabled: props.disabled }),
        React.createElement(ErrorMessage, { name: "sku", component: "div", className: "validation-error" }),
        React.createElement("label", null, "ASN"),
        React.createElement(Field, { type: "text", name: "asn", className: "form-control", disabled: props.disabled }),
        React.createElement(ErrorMessage, { name: "asn", component: "div", className: "validation-error" }),
        React.createElement("div", { className: "text-right", style: { marginTop: '1rem' } },
            React.createElement("button", { type: "submit", className: "btn btn-product-1" }, "Save Attributes")))); }));
};
