import React from 'react';
import ReactDom from  'react-dom/client';

export default render;

function render(data, element) {
    const root = ReactDom.createRoot(element);
    root.render(
        <SharesDashboard
            data={data}
        />
    );
}

class SharesDashboard extends React.Component {
    render() {
        const map = this.props.data;
        const shares = map.list.map(s => <Share key={s.id} data={s}></Share>);

        return <div className='popular-shares row row-cols-1 row-cols-lg-4 row-cols-md-2 g-4'>
            {shares}
        </div>;
    }
}

class Share extends React.Component {
    render() {
        const data = this.props.data;
        const referrers = data.referrers ? data.referrers.sort((a, b) => b.count - a.count).map((r, i) => <Ref key={r.domain} ind={i} data={r}></Ref>) : null;

        return <div className="col">
                <div className="card h-100">
                    <div className="card-header">
                        {data.title}
                    </div>
                    <img src={`${data.image}`} />
                    <div className="card-body">
                        <p className="card-text">{data.description}</p>
                    </div>
                    <ul className="list-group list-group-flush">
                        {referrers}
                    </ul>
                    <div className="card-footer">
                        <small className="text-muted">{data.memberId ? data.memberId : 'Unknown User'}</small>
                    </div>
                </div>
        </div>;
    }
}

class Ref extends React.Component {
    render() {
        const ref = this.props.data;
        const ind = this.props.ind;
        if (ind >= 4) {
            return null;
        }
        return <li className="list-group-item d-flex">
            <a className="card-link flex-grow-1" href={`{ref.domain}`}>
                {ref.domain}
            </a>
            <span className="badge bg-secondary">{ref.count.formatNumber(0)}</span>
        </li>
    }
}
