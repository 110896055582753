export default {
    QUOTE: 'QUOTE',
    PUBLICATION: 'PUBLICATION',
    PRINT_ON_DEMAND: 'PRINT_ON_DEMAND',
    FOURTHWALL: 'FOURTHWALL',
    ADOBE_EXPRESS: 'ADOBE_EXPRESS',

    getBadgeColour: function (orderStatus) {
        switch (orderStatus) {
            case this.QUOTE:
                return 'primary';
            case this.PUBLICATION:
                return 'info';
            case this.ADOBE_EXPRESS:
                return 'danger';
            case this.FOURTHWALL:
            default:
                return 'secondary';
        }
    }

};