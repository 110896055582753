import defineComponent from '../../../../components/flight/lib/component';
import React from 'react';
import ReactDom from 'react-dom/client';
import {PublicationApproval} from '../../react/self-publishing/PublicationApproval.tsx';

export default defineComponent(AdminPodApproval);

function AdminPodApproval() {

    /**
     * setOrder is called each time we get a new order version
     * be it ready (this.order.processStatus === "success") or partial update
     * @param event
     * @param data - the order itself
     */
    this.setOrder = function (event, data) {
        this.paint(
            this.$node.data('name'),
            this.$node.data('order'),
            this.$node.data('item'),
            data.orderStatus
        );
    };

    this.paint = function(name, orderId, itemId, status) {
        this.root.render(
            <PublicationApproval
                key={new Date().toISOString()} // Forces the component to re-mount each time, resetting state
                name={name}
                orderId={orderId}
                itemId={itemId}
                status={status}
            ></PublicationApproval>
        );
    }

    this.after('initialize', function () {
        this.root = ReactDom.createRoot(this.$node[0]);
        this.paint(
            this.$node.data('name'),
            this.$node.data('order'),
            this.$node.data('item'),
            this.$node.data('status')
        );
        this.on(document, "setOrderData", this.setOrder);
    });

}
