import defineComponent from '../../../components/flight/lib/component';
import mixam from '../../boot/mixam';
import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor';
import EssentialsPlugin from '@ckeditor/ckeditor5-essentials/src/essentials';
import AutoformatPlugin from '@ckeditor/ckeditor5-autoformat/src/autoformat';
import BoldPlugin from '@ckeditor/ckeditor5-basic-styles/src/bold';
import ItalicPlugin from '@ckeditor/ckeditor5-basic-styles/src/italic';
import BlockQuotePlugin from '@ckeditor/ckeditor5-block-quote/src/blockquote';
import HeadingPlugin from '@ckeditor/ckeditor5-heading/src/heading';
import ImagePlugin from '@ckeditor/ckeditor5-image/src/image';
import ImageCaptionPlugin from '@ckeditor/ckeditor5-image/src/imagecaption';
import ImageStylePlugin from '@ckeditor/ckeditor5-image/src/imagestyle';
import ImageToolbarPlugin from '@ckeditor/ckeditor5-image/src/imagetoolbar';
import ImageUploadPlugin from '@ckeditor/ckeditor5-image/src/imageupload';
import LinkPlugin from '@ckeditor/ckeditor5-link/src/link';
import ListPlugin from '@ckeditor/ckeditor5-list/src/list';
import ParagraphPlugin from '@ckeditor/ckeditor5-paragraph/src/paragraph';
import MediaEmbedPlugin from '@ckeditor/ckeditor5-media-embed/src/mediaembed';
import SimpleUploadAdapter from '@ckeditor/ckeditor5-upload/src/adapters/simpleuploadadapter';
import ImageResizePlugin from '@ckeditor/ckeditor5-image/src/imageresize';
import SourceEditingPlugin from '@ckeditor/ckeditor5-source-editing/src/sourceediting';
import PageBreakPlugin from '@ckeditor/ckeditor5-page-break/src/pagebreak';
import TablePlugin from '@ckeditor/ckeditor5-table/src/table';
import TableToolbarPlugin from '@ckeditor/ckeditor5-table/src/tabletoolbar';
import TablePropertiesPlugin from '@ckeditor/ckeditor5-table/src/tableproperties';
import TableCellPropertiesPlugin from '@ckeditor/ckeditor5-table/src/tablecellproperties';
import AlignmentPlugin from '@ckeditor/ckeditor5-alignment/src/alignment';
import HorizontalLinePlugin from '@ckeditor/ckeditor5-horizontal-line/src/horizontalline';
import PasteFromOfficePlugin from '@ckeditor/ckeditor5-paste-from-office/src/pastefromoffice';
import ClipboardPlugin from '@ckeditor/ckeditor5-clipboard/src/clipboard';
import GridPlugin from './ckeditor/grid/ckeditor5-grid-plugin';
import ColumnPlugin from './ckeditor/column/ckeditor5-column-plugin';
import WidgetPlugin from './ckeditor/widget/ckeditor5-widget-plugin';
import SpecialCharacters from '@ckeditor/ckeditor5-special-characters/src/specialcharacters';
import SpecialCharactersEssentials from '@ckeditor/ckeditor5-special-characters/src/specialcharactersessentials';
import {Style} from "@ckeditor/ckeditor5-style";
import { GeneralHtmlSupport } from '@ckeditor/ckeditor5-html-support';

export default defineComponent(CkEditor);

function CkEditor() {

    this.initAdvancedEditor = function () {

        ClassicEditor.create(
            this.node, {
                plugins: [
                    EssentialsPlugin,
                    AutoformatPlugin,
                    BoldPlugin,
                    ItalicPlugin,
                    BlockQuotePlugin,
                    HeadingPlugin,
                    ImagePlugin,
                    ImageCaptionPlugin,
                    ImageStylePlugin,
                    ImageToolbarPlugin,
                    ImageUploadPlugin,
                    LinkPlugin,
                    ListPlugin,
                    ParagraphPlugin,
                    MediaEmbedPlugin,
                    ImageResizePlugin,
                    SimpleUploadAdapter,
                    PageBreakPlugin,
                    SourceEditingPlugin,
                    TablePlugin,
                    TableToolbarPlugin,
                    TablePropertiesPlugin,
                    TableCellPropertiesPlugin,
                    AlignmentPlugin,
                    HorizontalLinePlugin,
                    PasteFromOfficePlugin,
                    ClipboardPlugin,
                    GridPlugin,
                    ColumnPlugin,
                    SpecialCharacters,
                    SpecialCharactersEssentials,
                    GeneralHtmlSupport,
                    Style,
                    WidgetPlugin
                ],
                language: 'en',
                toolbar: [
                    'heading',
                    '|',
                    'alignment',
                    'bold',
                    'italic',
                    '|',
                    'bulletedList',
                    'numberedList',
                    'link',
                    'blockQuote',
                    'specialCharacters',
                    '|',
                    'insertTable',
                    'uploadImage',
                    'mediaEmbed',
                    '|',
                    'horizontalLine',
                    'pageBreak',
                    '|',
                    'grid',
                    'column',
                    'style',
                    '|',
                    'sourceEditing',
                    'undo',
                    'redo',
                    '|',
                    'widget'
                ],
                heading: {
                    options: [
                        { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
                        { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
                        { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
                        { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' },
                        { model: 'heading4', view: 'h4', title: 'Heading 4', class: 'ck-heading_heading4' },
                        { model: 'heading5', view: 'h5', title: 'Heading 5', class: 'ck-heading_heading5' }
                    ]
                },
                image: {
                    toolbar: [
                        'imageStyle:inline',
                        'imageStyle:block',
                        'imageStyle:side',
                        '|',
                        'toggleImageCaption',
                        'imageTextAlternative'
                    ]
                },
                table: {
                    contentToolbar: [
                        'tableColumn',
                        'tableRow',
                        'mergeTableCells',
                        'tableProperties',
                        'tableCellProperties'
                    ],
                },
                mediaEmbed: {
                    previewsInData : true
                },
                simpleUpload: {
                    uploadUrl: mixam.secureDomain + '/admin/api/ckeditor/images',
                    withCredentials: true
                },
                htmlEmbed: {
                    showPreviews: false
                },
                style: {
                    definitions: [
                        {
                            name: 'Heading 1 Mixam',
                            element: 'h1',
                            classes: [ 'heading-1-mixam' ]
                        },
                        {
                            name: 'Heading 2 Mixam',
                            element: 'h2',
                            classes: [ 'heading-2-mixam' ]
                        },
                        {
                            name: 'Heading 3 Mixam',
                            element: 'h3',
                            classes: [ 'heading-3-mixam' ]
                        },
                        {
                            name: 'Heading 4 Mixam',
                            element: 'h4',
                            classes: [ 'heading-4-mixam' ]
                        },
                        {
                            name: 'Heading 5 Mixam',
                            element: 'h5',
                            classes: [ 'heading-5-mixam' ]
                        },
                        {
                            name: 'Heading 1 Green',
                            element: 'h1',
                            classes: [ 'heading-1-green' ]
                        },
                        {
                            name: 'Heading 2 Green',
                            element: 'h2',
                            classes: [ 'heading-2-green' ]
                        },
                        {
                            name: 'Heading 3 Green',
                            element: 'h3',
                            classes: [ 'heading-3-green' ]
                        },
                        {
                            name: 'Heading 4 Green',
                            element: 'h4',
                            classes: [ 'heading-4-green' ]
                        },
                        {
                            name: 'Heading 5 Green',
                            element: 'h5',
                            classes: [ 'heading-5-green' ]
                        },
                        {
                            name: 'Heading 1 Primary Block',
                            element: 'h1',
                            classes: [ 'heading-1-primary-block' ]
                        },
                        {
                            name: 'Paragraph Block',
                            element: 'p',
                            classes: [ 'paragraph-block' ]
                        }
                    ]
                },
                htmlSupport: {
                    allow: [
                        { name: 'h1', classes: ['heading-1-mixam'] },
                        { name: 'h2', classes: ['heading-2-mixam'] },
                        { name: 'h3', classes: ['heading-3-mixam'] },
                        { name: 'h4', classes: ['heading-4-mixam'] },
                        { name: 'h5', classes: ['heading-5-mixam'] },
                        { name: 'h1', classes: ['heading-1-green'] },
                        { name: 'h2', classes: ['heading-2-green'] },
                        { name: 'h3', classes: ['heading-3-green'] },
                        { name: 'h4', classes: ['heading-4-green'] },
                        { name: 'h5', classes: ['heading-5-green'] },
                        { name: 'h1', classes: ['heading-1-primary-block'] },
                        { name: 'p', classes: ['paragraph-block'] }
                    ],
                    disallow: [
                        { name: 'script' }
                    ]
                },
                licenseKey: ''
            })
            .then(editor => {
                this.initInitTemplate(editor);
            })
            .catch( error => {
                console.error( error );
            });
    };

    this.initBasicEditor = function() {

        ClassicEditor.create(
            this.node, {
                plugins: [
                    EssentialsPlugin,
                    AutoformatPlugin,
                    BoldPlugin,
                    ItalicPlugin,
                    HeadingPlugin,
                    LinkPlugin,
                    ListPlugin,
                    ParagraphPlugin,
                    SourceEditingPlugin,
                    PasteFromOfficePlugin,
                    ClipboardPlugin,
                ],
                language: 'en',
                toolbar: [
                    'heading',
                    '|',
                    'bold',
                    'italic',
                    '|',
                    'bulletedList',
                    'numberedList',
                    'link',
                    '|',
                    'sourceEditing',
                    'undo',
                    'redo'
                ],
                heading: {
                    options: [
                        { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
                        { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
                        { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' },
                        { model: 'heading4', view: 'h4', title: 'Heading 4', class: 'ck-heading_heading4' },
                        { model: 'heading5', view: 'h5', title: 'Heading 5', class: 'ck-heading_heading5' }
                    ]
                },
                licenseKey: ''
            })
            .then(editor => {
                this.initInitTemplate(editor);
            })
            .catch( error => {
                console.error( error );
            });
    };

    this.initInitTemplate = function(editor) {
        switch(this.template) {
            case 'product-page':
                if(!editor.getData()) {
                    editor.setData(
                        `
                            <h2>Lorem Ipsum</h2>
                            <p>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent quis ligula odio. 
                                Etiam quis eros orci. Praesent euismod metus arcu, non ultrices lectus rhoncus ut. 
                                Aliquam erat nisi, posuere et dolor eu, laoreet porta ipsum. Curabitur nec sollicitudin lacus. 
                                Cras eget purus posuere, tristique tellus dapibus, consectetur odio. Praesent iaculis tristique est, 
                                eu condimentum diam posuere facilisis. Aenean egestas sodales nunc id dictum. Donec eu facilisis purus, 
                                vitae consequat augue. Sed commodo eleifend egestas. Curabitur porttitor, felis a aliquet volutpat, 
                                lorem libero porttitor nisl, nec accumsan orci nunc vitae mi.
                            </p>
                        `
                    );
                }
                break;
        }
    };

    this.after('initialize', function () {
        this.editorStyle = $(this.node).data('editor-style');
        this.template = $(this.node).data('editor-template');
        switch(this.editorStyle) {
            case 'advanced':
                this.initAdvancedEditor();
                break;
            default:
                this.initBasicEditor();
                break;
        }
    });
}