import defineComponent from '../../../components/flight/lib/component';

export default defineComponent(TaxExemption);

function TaxExemption() {

    this.attributes({
        noExpiryDateCheckboxSelector: '[data-type="certificate-no-expiry-date"]',
        expiryDateSelector: '[data-type="expiry-date"]'
    });

    this.toggleExpiryDateRequired = function (event) {
        this.select('expiryDateSelector').prop('required', !event.currentTarget.checked);
    };

    this.after('initialize', function () {
        this.on('click', {
            noExpiryDateCheckboxSelector: this.toggleExpiryDateRequired
        });
    });

}


