import Command from '@ckeditor/ckeditor5-core/src/command';

export default class InsertTwoColumnGridCommand extends Command {
    execute() {
        this.editor.model.change( writer => {
            this.editor.model.insertContent( createTwoColumnGrid( writer ) );
        } );
    }

    refresh() {
        const model = this.editor.model;
        const selection = model.document.selection;
        const allowedIn = model.schema.findAllowedParent( selection.getFirstPosition(), 'twoColumnGrid' );
        this.isEnabled = allowedIn !== null;
    }
}

function createTwoColumnGrid( writer ) {
    const twoColumnGrid = writer.createElement( 'twoColumnGrid' );
    const leftColumn = writer.createElement( 'leftColumn' );
    const rightColumn = writer.createElement( 'rightColumn' );

    writer.append( leftColumn, twoColumnGrid );
    writer.append( rightColumn, twoColumnGrid );

    return twoColumnGrid;
}