import mixam from '../boot/mixam';
import defineComponent from '../../components/flight/lib/component';

export default  defineComponent(ActionButton);

function ActionButton() {

    this.doClick = function (/*event*/) {
        var action = this.$node.data('action'),
            scene = this.$node.data('action-param'),
            scroll = this.$node.data('action-scroll'),
            message = this.$node.data('action-trigger'),
            local = !!this.$node.data('action-trigger-local');

        if (scroll) {
            $('#scene-container').scrollToView();
        }

        if (message) {
            if (local) {
                this.trigger(message);
            } else {
                this.trigger(document, message);
            }
        }

        // update santa display
        setTimeout(() => {
            let offset = document.documentElement.scrollTop || document.body.scrollTop;
            this.trigger("uiPageScroll", {offset: offset});
        }, 20);
     };

    this.after('initialize', function () {
        this.pathname = location.pathname;
        this.on("click", this.doClick);
    });
}

