import defineComponent from '../../components/flight/lib/component';

export default defineComponent(SlideOnReady);

function SlideOnReady() {

    this.after('initialize', function() {
        setTimeout(() => this.$node.scrollToView(), 20);
    });

}