import Product from './products';
import SubProduct from './subProducts';

function getProductPageUrl(productId) {
    switch(productId) {
        case Product.BOOKLET: return 'brochures';
        case Product.LEAFLET: return 'leaflets';
        case Product.FOLDED: return 'folded';
        case Product.POSTER: return 'posters';
        case Product.LETTERHEAD: return 'letterheads';
        case Product.PHOTOBOOK: return 'photobook';
        case Product.BOOK: return 'hardcoverbooks';
        case Product.BUSINESS_CARD: return 'businesscards';
        case Product.POSTCARD: return 'postcards';
        case Product.GREETING_CARD: return 'greetingcards';
        case Product.COMPLIMENT_SLIP: return 'complimentslips';
        case Product.ENVELOPE: return 'envelopes';
        case Product.FOLDER: return 'folders';
        case Product.LAYFLAT: return 'layflat';
        case Product.WALL_CALENDAR: return 'wallcalendars';
        case Product.DESK_CALENDAR: return 'deskcalendars';
        case Product.WALL_VR_CALENDAR: return 'vwcalendars';
        case Product.DESK_VR_CALENDAR: return 'vdcalendars';
        case Product.TRADITIONAL_BOOK: return 'traditionalbooks';
        case Product.CANVAS: return 'canvases';
        case Product.FA_STICKERS_INDOORS:
        case Product.FA_STICKERS_OUTDOORS:
        case Product.FA_STICKERS_NEON:
        case Product.FA_STICKERS_GEL:
        case Product.FA_STICKERS_EXTREMELY_ADHESIVE:
        case Product.FA_STICKERS_HEAT_RESISTANT:
        case Product.FA_STICKERS_LUMINOUS:
        case Product.FA_STICKERS_REFLECTIVE:
        case Product.FA_STICKERS_REMOVABLE:
        case Product.FA_STICKERS_FOOD:
        case Product.FA_STICKERS_VEGAN_INDOOR:
        case Product.FA_STICKERS_VEGAN_OUTDOOR:
            return 'stickers';
        case Product.FA_STICKER_ROLL: return 'sticker-rolls';
        case Product.FA_STICKER_SHEET: return 'sticker-sheets';
        default: return '';
    }
}

function getSubProductPageUrl(subProductId) {
    switch(subProductId) {
        case SubProduct.HARDCOVER_BOOKS: return 'hardcoverbooks';
        case SubProduct.PAPERBACK_BOOKS: return 'paperbackbooks';
        case SubProduct.PERFECT_BOUND_BOOKLETS: return 'perfectbooklets';
        case SubProduct.WIRO_BOUND_BOOKLET: return 'wiro';
        case SubProduct.LOOP_BOUND_BOOKLET: return 'loop';
        case SubProduct.STAPLED_BOOKLET: return 'stapledbooklets';
        case SubProduct.MAGAZINE: return 'magazines';
        case SubProduct.CATALOGUE: return 'catalogs';
        case SubProduct.BOOKLETS: return 'booklets';
        case SubProduct.ZINE: return 'zines';
        case SubProduct.COMICS: return 'comicbooks';
        case SubProduct.ART_PRINTS: return 'artprints';
        case SubProduct.MANGA: return 'manga';
        case SubProduct.WEDDING_BOOK: return 'weddingbook';
        case SubProduct.YEARBOOK: return 'yearbook';
        case SubProduct.COOKBOOK: return 'cookbook';
        case SubProduct.COLORING_BOOK: return 'coloringbook';
        case SubProduct.ART_BOOK: return 'artbooks';
        case SubProduct.GRAPHIC_NOVELS: return 'graphicnovels';
        case SubProduct.LOOKBOOK: return 'lookbooks';
        case SubProduct.MENUS: return 'menus';
        case SubProduct.CLASSIC_BOOKS: return 'hardcoverbooks';
        case SubProduct.DIARIES: return 'diaries';
        case SubProduct.JOURNALS: return 'journals';
        case SubProduct.STICKER_SQUARE_RECTANGLE:
        case SubProduct.STICKER_ROUNDED_RECTANGLE:
        case SubProduct.STICKER_CIRCLE:
        case SubProduct.STICKER_OVAL:
                return 'stickers';
        case SubProduct.PAPERBACK_READING_BOOK: return 'paperbackbooks';
        default: return undefined;
    }
}

export default function getProductUrl(query) {
    let subProductPageUrl = getSubProductPageUrl(parseInt(query.subProductId));
    return subProductPageUrl ? subProductPageUrl : getProductPageUrl(query.productId);
}
