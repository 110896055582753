var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React from 'react';
import { Formik } from 'formik';
import FormikTableModal from '../FormikTableModal';
import { convertDate } from '../util';
import axios from "axios";
var ViewDispute = function (_a) {
    var line = _a.line;
    var reopenDispute = function () { return __awaiter(void 0, void 0, void 0, function () {
        var response, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, axios.put("/api/admin/dispute/".concat(line.id, "/status"), null, {
                            params: {
                                status: 'RESOLVING'
                            }
                        })];
                case 1:
                    response = _a.sent();
                    return [3 /*break*/, 3];
                case 2:
                    error_1 = _a.sent();
                    console.error('Error updating dispute status: ', error_1);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    return (React.createElement(Formik, { initialValues: {}, onSubmit: function () { return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/];
            });
        }); } }, function (_a) {
        var submitForm = _a.submitForm;
        var modalFooterButtons = [
            {
                label: 'Close',
                enabled: true,
                className: 'btn-secondary',
                closeAfterCallback: true,
                validateForm: false,
                onClickEvent: function () { return __awaiter(void 0, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        submitForm();
                        return [2 /*return*/];
                    });
                }); }
            }
        ];
        return (React.createElement(FormikTableModal, { headerText: "Dispute Details", noFooter: true, openBtnLabel: "View", modalSize: "xl", scrollable: true, footerButtons: modalFooterButtons },
            React.createElement("div", null,
                React.createElement("div", { className: "row" },
                    React.createElement("div", { className: "col" },
                        React.createElement("h4", { className: "mb-3" }, "Details"),
                        React.createElement("div", { className: "row" },
                            React.createElement("div", { className: "col" },
                                React.createElement("label", { htmlFor: "customerName", className: "form-label h6" }, "Customer Name"),
                                React.createElement("p", { id: "customerName" }, line.memberName)),
                            React.createElement("div", { className: "col" },
                                React.createElement("label", { htmlFor: "category", className: "form-label h6" }, "Category"),
                                React.createElement("p", { id: "categoryLabel" }, line.problemCategoryText))),
                        React.createElement("div", { className: "row" },
                            React.createElement("div", { className: "col" },
                                React.createElement("label", { htmlFor: "openedDate", className: "form-label h6" }, "Opened Date"),
                                React.createElement("p", { id: "openedDate" }, convertDate(line.dateCreated))),
                            React.createElement("div", { className: "col" },
                                React.createElement("label", { htmlFor: "askingFor", className: "form-label h6" }, "Asking For"),
                                React.createElement("p", { id: "askingFor" }, line.disputeRequestCategory))),
                        React.createElement("div", { className: "row" },
                            React.createElement("div", { className: "col" },
                                React.createElement("label", { htmlFor: "itemCost", className: "form-label h6" }, "Supplier Item Cost"),
                                React.createElement("p", { id: "itemCost" }, Number.parseFloat(line.itemSupplierCost.toString()).toFixed(2) || '')),
                            React.createElement("div", { className: "col" },
                                React.createElement("label", { htmlFor: "itemValue", className: "form-label h6" }, "Item Value"),
                                React.createElement("p", { id: "itemValue" }, Number.parseFloat(line.itemValue.toString()).toFixed(2) || ''))),
                        React.createElement("div", { className: "row" },
                            React.createElement("div", { className: "col" },
                                React.createElement("label", { htmlFor: "copiesAffected", className: "form-label h6" }, "Copies Affected"),
                                React.createElement("p", { id: "copiesAffected" }, line.copiesAffected))),
                        React.createElement("div", { className: "row" },
                            React.createElement("div", { className: "col" },
                                React.createElement("label", { htmlFor: "description", className: "form-label h6" }, "Description"),
                                React.createElement("p", { id: "description" }, line.problemDetails))),
                        line.attachments.length > 0 &&
                            React.createElement("div", { className: "row" },
                                React.createElement("label", { htmlFor: "imageCarousel", className: "form-label h6" }, "Attachments"),
                                React.createElement("div", { id: "imageCarousel", className: "carousel slide", "data-bs-ride": "carousel" },
                                    React.createElement("div", { className: "carousel-indicators" }, line.attachments.map(function (attachment, index) {
                                        return (React.createElement("button", { type: "button", "data-bs-target": "#imageCarousel", "data-bs-slide-to": index, className: index == 0 ? 'active' : '', "aria-label": "Image ".concat(index) }));
                                    })),
                                    React.createElement("div", { className: "carousel-inner" }, line.attachments.map(function (attachment, index) {
                                        return (React.createElement("div", { className: index == 0 ? 'active carousel-item' : 'carousel-item' },
                                            React.createElement("img", { key: attachment.url, src: attachment.url, alt: "carousel-image", className: "d-block w-100" })));
                                    })),
                                    React.createElement("button", { className: "carousel-control-prev", type: "button", "data-bs-target": "#imageCarousel", "data-bs-slide": "prev" },
                                        React.createElement("span", { className: "carousel-control-prev-icon", "aria-hidden": "true" }),
                                        React.createElement("span", { className: "visually-hidden" }, "Previous")),
                                    React.createElement("button", { className: "carousel-control-next", type: "button", "data-bs-target": "#imageCarousel", "data-bs-slide": "next" },
                                        React.createElement("span", { className: "carousel-control-next-icon", "aria-hidden": "true" }),
                                        React.createElement("span", { className: "visually-hidden" }, "Next"))),
                                React.createElement("div", { className: "row mt-3" }, line.attachments.map(function (attachment, index) {
                                    return (React.createElement("div", { className: "col-2" },
                                        React.createElement("img", { src: attachment.url, alt: "carousel-image-2", "data-bs-target": "#imageCarousel", "data-bs-slide-to": index, className: index == 0 ? 'active img-fluid img-thumbnail mb-2' : 'img-fluid img-thumbnail mb-2' })));
                                })),
                                React.createElement("div", { className: "row" },
                                    React.createElement("div", { className: "col" },
                                        React.createElement("a", { className: "btn btn-primary btn-sm", href: "/api/admin/dispute/".concat(line.id, "/download-images"), role: "button" }, "Download Attachments"))))),
                    React.createElement("div", { className: "col border-start" },
                        React.createElement("h4", { className: "mb-3" }, "Resolution"),
                        React.createElement("div", { className: "row" },
                            React.createElement("div", { className: "col" },
                                React.createElement("label", { htmlFor: "problemCategory", className: "form-label h6" }, "Problem Category"),
                                React.createElement("p", { id: "problemCategory" }, line.disputeCloseCategoryText)),
                            React.createElement("div", { className: "col" },
                                React.createElement("label", { htmlFor: "culprit", className: "form-label h6" }, "Responsibility"),
                                React.createElement("p", { id: "culprit" }, line.disputeCulpritText))),
                        React.createElement("div", { className: "row" },
                            React.createElement("div", { className: "col" },
                                React.createElement("label", { htmlFor: "closedBy", className: "form-label h6" }, "Closed By"),
                                React.createElement("p", { id: "closedBy" }, line.closedByName)),
                            React.createElement("div", { className: "col" },
                                React.createElement("label", { htmlFor: "closedDate", className: "form-label h6" }, "Closed Date"),
                                React.createElement("p", { id: "closedDate" }, line.dateClosedText))),
                        React.createElement("div", { className: "row" },
                            React.createElement("div", { className: "col" },
                                React.createElement("label", { htmlFor: "resolution", className: "form-label h6" }, "Resolution"),
                                React.createElement("p", { id: "resolution" }, line.disputeResolutionsText)),
                            React.createElement("div", { className: "col" },
                                React.createElement("label", { htmlFor: "reprintOrderNumber", className: "form-label h6" }, "Reprint Order Number"),
                                React.createElement("p", { id: "reprintOrderNumber" }, line.reprintCaseNumber != '' && (React.createElement("a", { href: "".concat(line.reprintOrderLink.href), target: "".concat(line.reprintOrderLink.target) }, line.reprintCaseNumber))))),
                        React.createElement("div", { className: "row" },
                            React.createElement("div", { className: "col" },
                                React.createElement("label", { htmlFor: "mixamRefundAMount", className: "form-label h6" }, "Mixam Refund Amount"),
                                React.createElement("p", { id: "mixamRefundAMount" }, Number.parseFloat(line.mixamRefundAmount.toString()).toFixed(2) || '0.00')),
                            React.createElement("div", { className: "col" },
                                React.createElement("label", { htmlFor: "mixamReprintCost", className: "form-label h6" }, "Mixam Reprint Cost"),
                                React.createElement("p", { id: "mixamReprintCost" }, Number.parseFloat(line.mixamReprintCost.toString()).toFixed(2) || '0.00'))),
                        React.createElement("div", { className: "row" },
                            React.createElement("div", { className: "col" },
                                React.createElement("label", { htmlFor: "supplierRefundAMount", className: "form-label h6" }, "Supplier Refund Amount"),
                                React.createElement("p", { id: "supplierRefundAMount" }, Number.parseFloat(line.supplierRefundAmount.toString()).toFixed(2) || '0.00')),
                            React.createElement("div", { className: "col" },
                                React.createElement("label", { htmlFor: "supplierReprintCost", className: "form-label h6" }, "Supplier Reprint Cost"),
                                React.createElement("p", { id: "supplierReprintCost" }, Number.parseFloat(line.supplierReprintCost.toString()).toFixed(2) || '0.00'))),
                        React.createElement("div", { className: "row" },
                            React.createElement("div", { className: "col" },
                                React.createElement("label", { htmlFor: "logisticsCost", className: "form-label h6" }, "Logistics Cost"),
                                React.createElement("p", { id: "logisticsCost" }, Number.parseFloat(line.logisticsCost.toString()).toFixed(2) || '0.00'))),
                        React.createElement("div", { className: "row" },
                            React.createElement("div", { className: "col" },
                                React.createElement("label", { htmlFor: "remarks", className: "form-label h6" }, "Remarks"),
                                React.createElement("p", { id: "remarks" }, line.remarks))),
                        React.createElement("div", { className: "row" },
                            React.createElement("div", { className: "col" },
                                React.createElement("button", { className: "btn btn-primary btn-sm", onClick: reopenDispute }, "Re-Open Dispute"))))))));
    }));
};
export default ViewDispute;
