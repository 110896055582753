import mixam from '../../boot/mixam';
import AnimatedCheckbox from '../animated/animated-checkbox';
import defineComponent from '../../../components/flight/lib/component';
import WithSubmitAnimation from '../with-submit-zoom-animation';
import regional from '../../boot/regional';

export default defineComponent(Signin, WithSubmitAnimation);

function Signin() {

    this.attributes({
        signInUrl: '/login',
        registerUrl: '/members/register',
        passwordUrl: '/members/passresetreq',
        signInCaption: `<i class="fa fa-sign-in"></i> ${regional().member.memberSignin}`,
        registerCaption: `<i class="fa fa-user"></i> ${regional().member.memberCreateAccount}`,
        submitCaptionSelector: '[data-type="sign-in"] .btn-anime-label',
        newMemberSelector: '[data-type="new-user"]',
        memberSelector: '[data-type=member]',
        passwordSelector: '[type=password]',
        rememberMeSelector: '[type=checkbox]',
        saveBtnSelector: '[data-type="sign-in"]',
        passwordResetSelector: '[data-type="password-reset"]',
        animatedCheckboxSelector: '[data-toggle="animated-checkbox"]',
        memberOnlySelector: '[data-type="has-account"]'
    });

    this.changeMode = function (event) {
        var mode = event.target.getAttribute("data-type");

        if (mode === "member") {
            this.$node.addClass("member-mode");
            this.$node.prop("action", this.attr.signInUrl);
            this.select("passwordSelector").attr("disabled", null);
            this.select("rememberMeSelector").attr("disabled", null);
            this.select("memberOnlySelector").removeClass("disabled");
            this.select("passwordResetSelector").show();
            this.select("submitCaptionSelector").html(this.attr.signInCaption);
        } else {
            this.$node.removeClass("member-mode");
            this.$node.prop("action", this.attr.registerUrl);
            this.select("passwordSelector").attr("disabled", true);
            this.select("rememberMeSelector").attr("disabled", true);
            this.select("memberOnlySelector").addClass("disabled");
            this.select("passwordResetSelector").hide();
            this.select("submitCaptionSelector").html(this.attr.registerCaption);
        }
    };

    this.after('initialize', function () {

        this.$node.validator({
            effect: 'mixam',
            errorClass: 'validation-error',
            errorInputEvent: 'keyup',
            formEvent: 'submit',
            inputEvent: null,
            grouped: true
        });

        // handle submit animation
        this.on('submit', this.handleSubmit);

        this.on("click", {
            newMemberSelector: this.changeMode,
            memberSelector: this.changeMode
        });
        AnimatedCheckbox.attachTo(this.attr.animatedCheckboxSelector);
    });
}