import defineComponent from '../../../../components/flight/lib/component';
import ck from '@credit-key/creditkey-js';

export default defineComponent(CreditKeyManager);

function CreditKeyManager() {

    this.attributes({
        buttonSelector: 'button',
        errorSelector: '[data-type="creditkey-error"]',
        messagingSelector: '[data-type="promotional-messaging"]'
    });

    this.checkout = function () {
        return fetch("/api/creditkey/orders", {
            method: "post",
            headers: {
                'Accept': 'application/json'
            },
            body: this.orderId
        }).then(response =>
            response.json()
        ).then(createOrderResponse => {
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth'
            });
            ck.checkout(createOrderResponse.url, 'modal');
        }).catch(error => {
            this.select('errorSelector').show();
        });
    };

    this.after('initialize', function () {
        const data = this.$node.data();
        this.orderId = data.orderId;
        this.amount = data.amount;

        this.client = new ck.Client(data.key, data.platform);

        this.select('errorSelector').hide();

        const charges = new ck.Charges(this.amount, 0, 0, 0, this.amount);
        this.select('messagingSelector').html(this.client.get_cart_display(charges, "left", "center"));

        this.on('click', {
            buttonSelector: this.checkout
        });
    });
}
