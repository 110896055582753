import defineComponent from '../../../components/flight/lib/component';
import React from 'react';
import ReactDom from 'react-dom/client';
import { ChargeItemsManager } from '../react/charge-items/ChargeItemsManager.tsx';
import mixam from "../../boot/mixam";

export default defineComponent(OrderItemChargeItemsManager);

function OrderItemChargeItemsManager() {

    this.attributes({
        containerSelector: '[data-type="charge-items-container"]',
    });

    this.initChargeItemsManager = function () {
        const orderId = this.orderId;
        const itemId = this.itemId;
        const root = ReactDom.createRoot(this.select('containerSelector').get(0));
        root.render(
            <ChargeItemsManager
                orderId={orderId}
                itemId ={itemId}
                locale={mixam.shop.locale.replace('_', '-')}
            />
        );
    };

    this.after('initialize', function () {
        this.orderId = this.$node.data('order');
        this.itemId = this.$node.data('item');
        this.initChargeItemsManager();
    });
}