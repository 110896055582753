export default {
    STAPLED: 0,
    PERFECT: 2,
    PUR: 4,
    CASE: 6,
    WIRO: 8,
    LOOP: 10,
    CALENDAR: 12,
    SEWN: 14,
    COIL: 16
};
