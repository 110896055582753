import ReactDom from  'react-dom';
import React from 'react';
import AnimatedCheckBox from './animated-checkbox';
import AnimatedCheckRadioBoxList from './animated-radiobox';
import Member from './type-member';
import TaskMessages from './type-messages';
import ThumbLink from './type-thumb-link';
import Snooze from './type-snooze';
import SamplesRequestModal from './samples-request-modal';
import OrderDescription from './type-order-description';
import createTimebox from '../../react-bootstrap/timebox';
import createArtworkReady from '../../react-bootstrap/artworkReady';
import ResellerGroup from "../constants/reseller-groups";
import TrustpilotStars from './trustpilot-stars';
import VideoEvents from "./video-events";
import DisputeAction from './dispute-action';
import CustomerCreditModal from './customer-credit-modal';
import MultiButton from './multi-button';

export default WithTableReactor;

function WithTableReactor() {

    class Col extends React.Component {
        render() {
            return (React.createElement("col", {width: this.props.data.width}));
        }
    }

    class Th extends React.Component {
        render() {
            if (this.props.data.sortKey) {
                return (React.createElement("th", {
                        "data-sort-key": this.props.data.data,
                        "data-sort-order": this.props.data.sortKey,
                        "data-sort-type": this.props.data.type,
                        title: this.props.data.title,
                        className: "ripple ripple-white type-" + this.props.data.type + " sortable sort-" + this.props.data.sortKey
                    },
                    React.createElement("div", null, this.props.data.title)
                ));
            } else if (this.props.data.sortable) {
                return (React.createElement("th", {
                        "data-sort-key": this.props.data.data,
                        "data-sort-order": this.props.data.defaultSortOrder,
                        "data-sort-type": this.props.data.type,
                        title: this.props.data.title,
                        className: "ripple ripple-white type-" + this.props.data.type + " sortable sort-" + this.props.data.sortable
                    },
                    React.createElement("div", null, this.props.data.title)
                ));
            }
            return (React.createElement("th", {
                    className: "type-" + this.props.data.type
                },
                React.createElement("div", null, this.props.data.title)
            ));
        }
    }

    class User extends React.Component {
        render() {
            const user = this.props.data;
            return (React.createElement("span", {
                    className: 'cell-content'
                },
                React.createElement("button", {
                        type: 'button',
                        className: 'btn btn-outline-inverse',
                        "data-type": "user-name",
                        "data-toggle": "popover",
                        "data-name": user.name,
                        "data-company": user.company,
                        "data-email": user.email,
                        "data-phone": user.phone
                    },

                    user.photo ? React.createElement("img", {
                        src: user.photo
                    }) : React.createElement("i", {
                        className: "fa fa-user"
                    }),
                    ' ',
                    React.createElement("span", {
                        className: "user-name-caption"
                    }, user.name),
                    React.createElement("div", {
                        className: "caret"
                    })
                )
            ));
        }
    }

    class Number_ extends React.Component {
        render() {
            const column = this.props.column;
            const data = this.props.data;

            return (React.createElement("span", {
                    className: 'cell-content'
                },
                data ? data.formatNumber(column.decimal) : ""
            ));
        }
    }

    class Text extends React.Component {
        render() {
            const column = this.props.column;
            const data = this.props.data;

            return (React.createElement("span", {
                    className: 'cell-content',
                    title: column.cellTitle === "self" ? data : null
                },
                data
            ));
        }
    }

    class Bool extends React.Component {
        render() {
            const column = this.props.column;
            const data = this.props.data;
            const className = (data ? column.trueIcon || 'bi bi-circle-fill' : column.falseIcon || "bi bi-circle") +
                " val-" + data;

            return React.createElement("span", {
                    className: 'cell-content'
                },
                React.createElement('i', {
                    className: className
                })
            );
        }
    }

    class Timebox extends React.Component {
        render() {
            const time = this.props.data.value;
            const box = createTimebox(time);

            return (React.createElement("span", {
                    className: 'cell-content'
                },
                box
            ));
        }
    }

    class Button extends React.Component {
        render() {
            const data = this.props.data;

            let link;

            link = React.createElement("a", {
                    title: data.title,
                    target: data.target,
                    href: data.href,
                    className: data.className
                },
                React.createElement("i", {
                    className: data.iconClassname
                }),
                data.caption
            );

            return (React.createElement("span", {
                    className: 'cell-content col-button'
                },
                link
            ));
        }
    }

    class Link extends React.Component {
        render() {
            const data = this.props.data;

            let link;

            if (data.href) {
                link = React.createElement("a", {
                        title: data.title,
                        target: data.target,
                        href: data.href
                    },
                    data.caption
                );
            } else {
                link = React.createElement("span", {
                        title: data.title
                    },
                    data.caption
                );
            }
            return (React.createElement("span", {
                    className: 'cell-content'
                },
                link
            ));
        }
    }

    class Thumbnail extends React.Component {
        render() {
            const data = this.props.data;

            let thumb;
            if (data.href) {
                let style = data.src && {
                    backgroundImage: `url(${data.src})`
                };
                thumb = React.createElement("a", {
                        className: 'thumbnail',
                        title: data.title,
                        target: data.target,
                        href: data.href,
                        style: style
                    },
                    React.createElement("div", {
                        className: 'aspect-keeper'
                    })
                );
            } else {
                let style = {
                    backgroundImage: `url(${data})`
                };

                thumb = React.createElement("div", {
                        title: data.title,
                        className: 'thumbnail',
                        style: style
                    },
                    React.createElement("div", {
                        className: 'aspect-keeper'
                    })
                );
            }
            return (React.createElement("span", {
                    className: 'cell-content'
                },
                thumb
            ));
        }
    }

    class Date_ extends React.Component {
        render() {
            const data = this.props.data;

            return (React.createElement("span", {
                    className: 'cell-content'
                },
                data ? data.text : ""
            ));
        }
    }


    class Remarks extends React.Component {
        render() {
            const data = this.props.data;

            const text = data.list.map(x => `${x.nostro}^|^${x.body}^|^${x.date}`).join('~|~');
            const remarks = React.createElement("button", {
                    className: 'btn btn-outline-inverse',
                    type: 'button',
                    "data-toggle": "popover",
                    "data-type": "user-remarks",
                    "data-remarks": text,
                },
                React.createElement("span", {
                    className: `badge isClient${data.clientLastRemark}`
                }, data.count)
            );

            return (React.createElement("span", {
                    className: 'cell-content'
                },
                remarks
            ));
        }
    }

    class Artwork extends React.Component {
        render() {
            const data = this.props.data;

            const icons = createArtworkReady(data);
            return (React.createElement("span", {
                    className: 'cell-content'
                },
                icons
            ));
        }
    }

    class Roles extends React.Component {
        render() {
            const data = this.props.data;

            const roles = data.map((x, i) => {
                return React.createElement("li", {key: i},
                    React.createElement("span", {
                        className: `label label-${x.role}`
                    }, x.role), ' '
                );
            });

            return (React.createElement("span", {
                    className: 'cell-content'
                },
                React.createElement("ul", {
                        key: 'stebget42rqwg',
                        className: 'pillbox'
                    },
                    roles
                )
            ));
        }
    }

    class TextBlob extends React.Component {
        render() {
            const data = this.props.data;

            let blob;
            if (data.ref) {
                blob = React.createElement("div", {
                        className: "text-blob-holder",
                        "data-type": "text-blob",
                        "data-refe": data.ref
                    },
                    React.createElement("span", {
                        className: "text-blob-sample"
                    }, data.text)
                );
            }

            return (React.createElement("span", {
                    className: 'cell-content'
                },
                blob
            ));
        }
    }

    class Lightbox extends React.Component {
        render() {
            const column = this.props.column;
            const data = this.props.data;

            const lightbox = React.createElement("div", {
                    className: "canvas-holder"
                },
                React.createElement("canvas", {
                    className: "lb-thumbnail",
                    "data-col": "image",
                    "data-low-rez": !!column['low-rez'],
                    "data-type": "lightbox",
                    "data-src": column.data
                }, data.text)
            );

            return (React.createElement("span", {
                    className: 'cell-content'
                },
                lightbox
            ));
        }
    }


    class Image_ extends React.Component {
        render() {
            const data = this.props.data;

            let image;
            if (data && data.href) {
                image = React.createElement("a", {
                        title: data.title,
                        target: data.target,
                        href: data.href
                    },
                    React.createElement("img", {
                        className: "table-image",
                        src: data.src
                    })
                );
            } else {
                image = React.createElement("img", {
                        className: "table-image",
                        src: data
                    }
                );
            }

            return (React.createElement("span", {
                    className: 'cell-content'
                },
                image
            ));
        }
    }


    class Icon extends React.Component {
        render() {
            const data = this.props.data;

            let icon;

            if (data.href) {
                icon = React.createElement("div", null,
                    React.createElement("a", {
                            title: data.title,
                            target: data.target,
                            href: data.href
                        },
                        React.createElement("i", {
                                className: `${data.prefix} ${data.icon}`
                            }
                        )
                    ),
                    data.caption && React.createElement("a", {
                        title: data.title,
                        target: data.target,
                        href: data.href
                    },
                    React.createElement("span", {
                            className: 'caption'
                        },
                        data.caption
                    )
                    )
                );
            } else {
                icon = React.createElement("div", {
                        title: data.title,
                    },
                    React.createElement("div", null,
                        React.createElement("i", {
                                className: `${data.prefix} ${data.icon}`
                            }
                        )
                    ),
                    data.caption && React.createElement("div", null,
                    React.createElement("span", {
                            className: 'caption'
                        },
                        data.caption
                    )
                    )
                );
            }
            return (React.createElement("span", {
                    className: 'cell-content'
                },
                icon
            ));
        }
    }

    const rd = ReactDom;

    class Command extends React.Component {
        constructor(props) {
            super(props);
            this.state = {open: false, active: false, loading: false, value: 0, silent: false};
        }

        handleClick() {
            this.setState({open: !this.state.open});
        }

        handleSubmit(event) {
            this.setState({loading: true});
            const id = $(event.target).closest('tr').attr('id').substr(2);
            //const date = rd.findDOMNode(this.refs.eventDate).value;

            $(document).trigger('uiRequestArticleStatusChange', {
                status: this.state.value,
                tracking: this.state.tracking,
                silent: `${this.state.silent}`,
                articleId: id
            });

            setTimeout(() => this.setState({open: false, loading: false}), 500);
        }

        handleClose() {
            this.setState({open: false, loading: false});
        }

        onTransitionEnd(event) {
            if (event.propertyName === "top") {
                this.setState({active: this.state.open});
            }
        }

        componentDidMount() {
            const data = this.getStatusOptions();
            const content = rd.findDOMNode(this.refs.content);
            content.addEventListener('transitionend', (e) => this.onTransitionEnd(e), false);
            this.setState({value: data[0].value});
        }

        getStatusOptions() {
            const data = this.props.data;
            const result = [{value: 36, description: 'Labelled'}, {value: -1, description: 'Declined'}];
            if ('ACCEPTED' !== data) {
                result.unshift({value: 32, description: 'Accepted'});
            }
            return result;
        }

        onStatusChange(e) {
            if (e.target.type === 'radio' && e.target.checked) {
                this.setState({
                    value: +e.target.value
                });
            } else if (e.target.classList.contains("target-order")) {
                this.setState({tracking: e.target.value});
            }
        }

        toggleCallback(silent) {
            this.setState({ silent });
        }

        createDispatchDialog() {
            const buttonDataType = this.state.loading ? '?' : null;
            const data = this.getStatusOptions();

            // Allow "silent mode"?
            const silentModeToggle = React.createElement('div', {
                    className: 'form-group'
                },
                React.createElement(AnimatedCheckBox, {
                    name: 'silent',
                    label: 'Silent mode (prevent emails)',
                    toggleCallback: (silent) => this.toggleCallback(silent),
                    className: 'sssss'
                })
            );
            const silentModeFormSection = this.props.column.allowSilentMode ? silentModeToggle : React.createElement('div');

            return (React.createElement('div', {
                    onChange: (e) => this.onStatusChange(e),
                    className: 'dialog'
                },
                React.createElement('h2', {
                        className: 'caption'
                    },
                    'Mark Article As'
                ),
                React.createElement('form', {
                        className: 'form-horizontal long-labels scene-form product-1'
                    },

                    // Status options
                    React.createElement('div', {
                            className: 'form-group'
                        },
                        React.createElement(AnimatedCheckRadioBoxList, {
                            data: data,
                            className: 'sssss'
                        })
                    ),

                    silentModeFormSection, // Or empty div

                    React.createElement('div', {
                            className: 'form-group last'
                        },

                        React.createElement('div', {
                                className: 'control-save-line'
                            },
                            React.createElement('button', {
                                    type: 'button',
                                    'data-type': 'save-import',
                                    'data-style': 'zoom-out',
                                    'data-loading': buttonDataType,
                                    onClick: (e) => this.handleSubmit(e),
                                    className: 'btn btn-outline-white btn-anime btn-anime-zoomout'
                                },
                                React.createElement("span", {
                                        className: 'btn-anime-label'
                                    },
                                    'OK'
                                ),
                                React.createElement("span", {
                                        className: 'btn-anime-spinner'
                                    },
                                    React.createElement("i", {
                                            className: 'fa fa-spinner fa-lg fa-spin'
                                        }
                                    )
                                )
                            ),
                            React.createElement("button", {
                                    type: 'button',
                                    onClick: (e) => this.handleClose(e),
                                    'data-type': 'cancel-import',
                                    className: 'btn btn-outline-white'
                                },
                                'Cancel'
                            )
                        )
                    )
                )
            ));
        }

        render() {
            const data = this.props.data;
            const className = (this.state.open ? 'open ' : '') +
                (this.state.active ? "active " : '') +
                'morph-dialog-button morph-dialog-button-overlay morph-dialog-button-fixed';
            const button = React.createElement("button", {
                    button: 'button',
                    onClick: (e) => this.handleClick(e)
                },
                React.createElement('i', {
                        className: 'fa fa-forward'
                    }
                ),
                ' ',
                data
            );

            const dialog = this.state.open ? this.createDispatchDialog() : null;

            return (React.createElement("span", {
                        className: 'cell-content'
                    },
                    React.createElement("div", {
                            className: className
                        },

                        button,

                        React.createElement("div", {
                                className: 'content-style-mask',
                                onClick: (e) => this.handleClose(e)
                            }
                        ),

                        React.createElement("div", {
                                ref: 'content',
                                className: 'morph-dialog-content'
                            },
                            React.createElement("div", null,
                                React.createElement("div", {
                                        className: 'content-style-overlay'
                                    },

                                    React.createElement("button", {
                                            className: 'close-btn state-close btn-shape-circle',
                                            title: "Close",
                                            onClick: (e) => this.handleClick(e)
                                        },
                                        React.createElement("i", {
                                                className: 'fa fa-times'
                                            }
                                        )
                                    ),

                                    dialog
                                )
                            )
                        )
                    ))
            );
        }
    }

    class Deliveries extends React.Component {
        constructor(props) {
            super(props);
            this.state = {open: false, active: false, loading: false, value: 0};
        }

        handleClick() {
            this.setState({open: !this.state.open});
        }

        handleClose() {
            this.setState({open: false, loading: false});
        }

        render() {
            const data = this.props.data;
            const className = (this.state.open ? "open " : "") +
                (this.state.active ? "active " : "") +
                'morph-dialog-button morph-dialog-button-overlay morph-dialog-button-fixed';

            let links = data && data.length > 1 && data.map(delivery => React.createElement("a", {
                    className: 'delivery-link',
                    href: delivery.href,
                    target: "_blank"
                },
                delivery.caption
                )
            );

            let style = data && data.length > 1 && {
                height: `${1 +  2 * data.length}rem`
            };

            if (data.length === 1) {
                return (React.createElement("span", {
                        className: 'cell-content'
                    },
                    React.createElement("a", {
                            className: 'btn btn-dark',
                            href: data[0].href,
                            target: "_blank"
                        },
                        React.createElement("i", {
                                className: 'fa fa-truck'
                            }
                        ),
                        ' ',
                        'Delivery'
                    )
                ));
            } else if (data.length > 1) {
                return (React.createElement("span", {
                        className: 'cell-content'
                    },
                    React.createElement("div", {
                            className: className
                        },
                        React.createElement("a", {
                                className: 'btn btn-dark',
                                onClick: (e) => this.handleClick(e),
                                href: '#',

                            },
                            React.createElement("i", {
                                    className: 'fa fa-truck'
                                }
                            ),
                            ' ',
                            'Deliveries',
                            ' ',
                            React.createElement("div", {
                                className: "caret"
                            }),

                            React.createElement("div", {
                                    className: 'content-style-mask',
                                    onClick: (e) => this.handleClose(e)
                                }
                            ),
                            React.createElement("div", {
                                    ref: 'content',
                                    style: style,
                                    className: 'morph-dialog-content'
                                },
                                links)
                        ))));
            }
        }
    }

    class ResellerCommand extends React.Component {
        constructor(props) {
            super(props);
            this.state = {open: false, active: false, loading: false, value: 0};
        }

        handleClick() {
            this.setState({open: !this.state.open});
        }

        handleSubmit(event) {
            this.setState({loading: true});
            const id = $(event.target).closest('tr').attr('id').substr(2);
            //const date = rd.findDOMNode(this.refs.eventDate).value;

            $(document).trigger("uiRequestResellerStatusChange", {
                status: this.state.value,
                requestId: id
            });

            setTimeout(() => this.setState({open: false, loading: false}), 500);
        }

        handleClose() {
            this.setState({open: false, loading: false});
        }

        onTransitionEnd(event) {
            if (event.propertyName === "top") {
                this.setState({active: this.state.open});
            }
        }

        componentDidMount() {
            const data = this.getStatusOptions();
            const content = rd.findDOMNode(this.refs.content);
            content.addEventListener('transitionend', (e) => this.onTransitionEnd(e), false);
            this.setState({value: data[0].value});
        }

        getStatusOptions() {
            return Object.keys(ResellerGroup).filter(k => k > 0).map(k => {
                return {
                    value: parseInt(k, 10),
                    description: ResellerGroup[k]
                };
            });
        }

        onStatusChange(e) {
            if (e.target.checked) {
                this.setState({
                    value: +e.target.value
                });
            } else if (e.target.classList.contains("target-order")) {
                this.setState({target: e.target.value});
            }
        }

        createDispatchDialog(line) {
            const buttonDataType = this.state.loading ? "?" : null;
            const data = this.getStatusOptions();
            const title = `Mark Reseller As`;

            return (React.createElement("div", {
                    onChange: (e) => this.onStatusChange(e),
                    className: 'dialog'
                },
                React.createElement("h2", {
                        className: 'caption'
                    },
                    `${line.name}`
                ),

                React.createElement("p", {
                        className: 'captionp'
                    },
                    React.createElement("i", {
                        className: line.confirm
                    }),
                    title
                ),
                React.createElement("form", {
                        className: 'form-horizontal long-labels scene-form product-1 inplace-edit-form'
                    },
                    React.createElement("div", {
                            className: 'form-group'
                        },

                        React.createElement(AnimatedCheckRadioBoxList, {
                            data: data,
                            className: 'sssss'
                        })
                    ),

                    React.createElement("div", {
                            className: 'form-group last'
                        },

                        React.createElement("div", {
                                className: 'control-save-line'
                            },
                            React.createElement("button", {
                                    type: 'button',
                                    "data-type": "save-import",
                                    "data-style": "zoom-out",
                                    "data-loading": buttonDataType,
                                    onClick: (e) => this.handleSubmit(e),
                                    className: 'btn btn-outline-white btn-anime btn-anime-zoomout'
                                },
                                React.createElement("span", {
                                        className: 'btn-anime-label'
                                    },
                                    'OK'
                                ),
                                React.createElement("span", {
                                        className: 'btn-anime-spinner'
                                    },
                                    React.createElement("i", {
                                            className: 'fa fa-spinner fa-lg fa-spin'
                                        }
                                    )
                                )
                            ),
                            React.createElement("button", {
                                    type: 'button',
                                    onClick: (e) => this.handleClose(e),
                                    "data-type": "cancel-import",
                                    className: 'btn btn-outline-white'
                                },
                                'Cancel'
                            )
                        )
                    )
                )
            ));
        }

        render() {
            const line = this.props.line;
            const className = (this.state.open ? "open " : "") +
                (this.state.active ? "active " : "") +
                'morph-dialog-button morph-dialog-button-overlay morph-dialog-button-fixed';
            const button = React.createElement("button", {
                    button: 'button',
                    onClick: (e) => this.handleClick(e)
                },
                'Set Reseller'
            );

            if (line.reseller) {
                return (React.createElement("span", {
                        className: 'cell-content'
                    },
                    React.createElement("div", {
                            ref: 'content',
                            className: className
                        },
                        ResellerGroup[line.reseller]
                    )
                ));
            }

            const dialog = this.state.open ? this.createDispatchDialog(line) : null;

            return (React.createElement("span", {
                        className: 'cell-content'
                    },
                    React.createElement("div", {
                            className: className
                        },

                        button,

                        React.createElement("div", {
                                className: 'content-style-mask',
                                onClick: (e) => this.handleClose(e)
                            }
                        ),

                        React.createElement("div", {
                                ref: 'content',
                                className: 'morph-dialog-content'
                            },
                            React.createElement("div", null,
                                React.createElement("div", {
                                        className: 'content-style-overlay'
                                    },

                                    React.createElement("button", {
                                            className: 'close-btn state-close btn-shape-circle',
                                            title: "Close",
                                            onClick: (e) => this.handleClick(e)
                                        },
                                        React.createElement("i", {
                                                className: 'fa fa-times'
                                            }
                                        )
                                    ),

                                    dialog
                                )
                            )
                        )
                    ))
            );
        }
    }

    class Dispatch extends React.Component {
        constructor(props) {
            super(props);
            this.state = {open: false, active: false, loading: false};
        }

        handleClick() {
            this.setState({open: !this.state.open});
        }

        handleSubmit(event) {
            this.setState({loading: true});
            const id = $(event.target).closest('tr').attr('id').substr(2);
            const date = rd.findDOMNode(this.refs.eventDate).value;

            $(document).trigger("uiRequestArticleDateChange", {
                articleId: id,
                date: (new Date(date)).format('isoDate')
            });

            setTimeout(() => this.setState({open: false, loading: false}), 500);
        }

        handleClose() {
            this.setState({open: false, loading: false});
        }

        onTransitionEnd(event) {
            if (event.propertyName === "top") {
                this.setState({active: this.state.open});
            }
        }

        componentDidMount() {
            //const data = this.getStatusOptions();
            const content = rd.findDOMNode(this.refs.content);
            content.addEventListener('transitionend', (e) => this.onTransitionEnd(e), false);
            //this.setState({value: data[0].value});
        }

        createDispatchDialog(data) {
            const buttonDataType = this.state.loading ? "?" : null;
            const defaultdate = data.updateTimestamp ? new Date(data.updateTimestamp).format("yyyy-mm-dd") : new Date(data.value).format("yyyy-mm-dd");

            return (React.createElement("div", {
                    className: 'dialog'
                },
                React.createElement("h2", {
                        className: 'caption'
                    },
                    "Update Dispatch Date"
                ),
                React.createElement("form", {
                        className: 'form-horizontal long-labels scene-form product-1'
                    },
                    React.createElement("div", {
                            className: 'form-group'
                        },

                        React.createElement('label', {
                                className: 'control-label'
                            },
                            'Date'
                        ),
                        React.createElement('div', {
                                className: 'control-size-frame'
                            },
                            React.createElement('input', {
                                    type: 'date',
                                    ref: 'eventDate',
                                    name: "date",
                                    required: true,
                                    defaultValue: defaultdate,
                                    className: 'form-control'
                                }
                            )
                        )
                    ),


                    React.createElement("div", {
                            className: 'form-group last'
                        },

                        React.createElement("div", {
                                className: 'control-save-line'
                            },
                            React.createElement("button", {
                                    type: 'button',
                                    "data-type": "save-import",
                                    "data-style": "zoom-out",
                                    "data-loading": buttonDataType,
                                    onClick: (e) => this.handleSubmit(e),
                                    className: 'btn btn-outline-white btn-anime btn-anime-zoomout'
                                },
                                React.createElement("span", {
                                        className: 'btn-anime-label'
                                    },
                                    'OK'
                                ),
                                React.createElement("span", {
                                        className: 'btn-anime-spinner'
                                    },
                                    React.createElement("i", {
                                            className: 'fa fa-spinner fa-lg fa-spin'
                                        }
                                    )
                                )
                            ),
                            React.createElement("button", {
                                    type: 'button',
                                    onClick: (e) => this.handleClose(e),
                                    "data-type": "cancel-import",
                                    className: 'btn btn-outline-white'
                                },
                                'Cancel'
                            )
                        )
                    )
                )
            ));
        }

        render() {
            const data = this.props.data;
            const className = (this.state.open ? "open " : "") +
                (this.state.active ? "active " : "") +
                'morph-dialog-button morph-dialog-button-overlay morph-dialog-button-fixed';

            let caption = data.text;
            if (data.update) {
                caption = React.createElement("span", null,
                    React.createElement("span", {
                            className: 'depricated'
                        },
                        data.text
                    ),
                    React.createElement("span", {
                            className: 'updated'
                        },
                        data.update
                    )
                );
            }
            const button = React.createElement("button", {
                    button: 'button',
                    onClick: (e) => this.handleClick(e)
                },
                caption
            );

            const dialog = this.state.open ? this.createDispatchDialog(data) : null;

            return (React.createElement("span", {
                        className: 'cell-content'
                    },
                    React.createElement("div", {
                            className: className
                        },

                        button,

                        React.createElement("div", {
                                className: 'content-style-mask',
                                onClick: (e) => this.handleClose(e)
                            }
                        ),

                        React.createElement("div", {
                                ref: 'content',
                                className: 'morph-dialog-content'
                            },
                            React.createElement("div", null,
                                React.createElement("div", {
                                        className: 'content-style-overlay'
                                    },

                                    React.createElement("button", {
                                            className: 'close-btn state-close btn-shape-circle',
                                            title: "Close",
                                            onClick: (e) => this.handleClick(e)
                                        },
                                        React.createElement("i", {
                                                className: 'fa fa-times'
                                            }
                                        )
                                    ),

                                    dialog
                                )
                            )
                        )
                    ))
            );
        }
    }

    class CommandFulfilment extends React.Component {
        constructor(props) {
            super(props);
            this.state = {open: false, active: false, loading: false, sum: 0, indispute: false};
        }

        handleClick() {
            this.setState({open: !this.state.open});
        }

        handleSubmit() {
            this.setState({loading: true});
            $(document).trigger("uiRequestFulfilmentStatusChange", {
                indispute: this.state.indispute,
                sum: this.state.sum,
                remark: this.state.remark,
                order: this.orderId,
                item: this.itemId
            });

            setTimeout(() => this.setState({open: false, loading: false}), 500);
        }

        handleClose() {
            this.setState({open: false, loading: false});
        }

        onTransitionEnd(event) {
            if (event.propertyName === "top") {
                this.setState({active: this.state.open});
            }
        }

        componentDidMount() {
            this.orderId = this.props.line.order.id;
            this.itemId = this.props.line.itemId;

            const content = rd.findDOMNode(this.refs.content);
            content.addEventListener('transitionend', (e) => this.onTransitionEnd(e), false);
            this.setState({
                sum: this.props.line.fulfillmentSum === -1 ? this.props.line.ntotal : this.props.line.fulfillmentSum,
                remark: this.props.line.fulfillmentRemark,
                indispute: this.props.line.fulfillmentDispute
            });
        }

        onStatusChange(e) {
            if (e.target.type === 'checkbox') {
                this.setState({
                    indispute: e.target.checked
                });
            } else {
                const o = {};
                o[e.target.name] = e.target.value;
                this.setState(o);
            }
        }

        createDispatchDialog(line) {
            const buttonDataType = this.state.loading ? "?" : null;

            const defaultSum = this.state.sum;
            const defaultRemark = this.state.remark;
            const defaultDispute = this.state.indispute; //line.fulfillmentDispute;

            return (React.createElement("div", {
                    onChange: (e) => this.onStatusChange(e),
                    className: 'dialog'
                },
                React.createElement("h2", {
                        className: 'caption'
                    },
                    `Item ${line.href.caption}`
                ),
                React.createElement("p", {
                        className: 'captionname'
                    },
                    `Update fulfilment (${line.supplier})`
                ),


                React.createElement("form", {
                        className: 'form-horizontal long-labels scene-form product-1'
                    },

                    React.createElement("div", {
                            className: 'form-group'
                        },

                        React.createElement('label', {
                                className: 'control-label'
                            },
                            'Sum'
                        ),
                        React.createElement('div', {
                                className: 'control-size-frame'
                            },
                            React.createElement('input', {
                                    type: 'text',
                                    ref: 'fulfilmentSum',
                                    step: 'any',
                                    name: "sum",
                                    required: true,
                                    defaultValue: defaultSum,
                                    className: 'form-control'
                                }
                            )
                        )
                    ),

                    React.createElement("div", {
                            className: 'form-group'
                        },
                        React.createElement('label', {
                                className: 'control-label'
                            },
                            ''
                        ),
                        React.createElement('div', {
                                className: 'control-size-frame'
                            },
                            React.createElement(AnimatedCheckBox, {
                                checked: defaultDispute,
                                name: 'indispute',
                                label: 'In dispute',
                                className: 'sssss'
                            })
                        )
                    ),

                    React.createElement("div", {
                            className: 'form-group'
                        },

                        React.createElement('label', {
                                className: 'control-label'
                            },
                            'Remark'
                        ),
                        React.createElement('div', {
                                className: 'control-size-frame'
                            },
                            React.createElement('textarea', {
                                    ref: 'fulfilmentRemark',
                                    name: "remark",
                                    defaultValue: defaultRemark,
                                    className: 'form-control'
                                }
                            )
                        )
                    ),

                    React.createElement("div", {
                            className: 'form-group last'
                        },

                        React.createElement("div", {
                                className: 'control-save-line'
                            },
                            React.createElement("button", {
                                    type: 'button',
                                    "data-type": "save-import",
                                    "data-style": "zoom-out",
                                    "data-loading": buttonDataType,
                                    onClick: (e) => this.handleSubmit(e),
                                    className: 'btn btn-outline-white btn-anime btn-anime-zoomout'
                                },
                                React.createElement("span", {
                                        className: 'btn-anime-label'
                                    },
                                    'OK'
                                ),
                                React.createElement("span", {
                                        className: 'btn-anime-spinner'
                                    },
                                    React.createElement("i", {
                                            className: 'fa fa-spinner fa-lg fa-spin'
                                        }
                                    )
                                )
                            ),
                            React.createElement("button", {
                                    type: 'button',
                                    onClick: (e) => this.handleClose(e),
                                    "data-type": "cancel-import",
                                    className: 'btn btn-outline-white'
                                },
                                'Cancel'
                            )
                        )
                    )
                )
            ));
        }

        render() {
            const data = this.props.data;
            const line = this.props.line;
            const text = data === -1 ? "Update" : data.formatNumber(2);
            const btnClassName = data === -1 ? "" : "valid";

            const className = (this.state.open ? "open " : "") +
                (this.state.active ? "active " : "") +
                'morph-dialog-button morph-dialog-button-overlay morph-dialog-button-fixed';
            const button = React.createElement("button", {
                    button: 'button',
                    className: btnClassName,
                    onClick: (e) => this.handleClick(e)
                },
                text
            );

            const dialog = this.state.open ? this.createDispatchDialog(line) : null;

            return (React.createElement("span", {
                        className: 'cell-content'
                    },
                    React.createElement("div", {
                            className: className
                        },

                        button,

                        React.createElement("div", {
                                className: 'content-style-mask',
                                onClick: (e) => this.handleClose(e)
                            }
                        ),

                        React.createElement("div", {
                                ref: 'content',
                                className: 'morph-dialog-content'
                            },
                            React.createElement("div", null,
                                React.createElement("div", {
                                        className: 'content-style-overlay'
                                    },

                                    React.createElement("button", {
                                            className: 'close-btn state-close btn-shape-circle',
                                            title: "Close",
                                            onClick: (e) => this.handleClick(e)
                                        },
                                        React.createElement("i", {
                                                className: 'fa fa-times'
                                            }
                                        )
                                    ),

                                    dialog
                                )
                            )
                        )
                    ))
            );
        }
    }

    class Td extends React.Component {
        render() {
            const column = this.props.column;
            const data = this.props.data;
            const title = this.props.title;
            const datum = data[column.data];
            const colType = column.type.toUpperCase();
            let className = `type-${column.type} col-${column.data} `;


            if (column["class"]) {
                className += column["class"] + ' ';
            }
            if (column.size) {
                className += ` size-${column.size} `;
            }

            if (data.override && data.override.td && data.override.td[column.data]) {
                className += data.override.td[column.data];
            }

            let component = types[colType];
            let cell = null;
            if (title) {
                cell = (React.createElement("span", null, title));
            } else if (component && typeof datum !== 'undefined') {
                    cell = (React.createElement(component, {
                        column: column,
                        data: datum,
                        line: data
                    }));
            }

            return (React.createElement("td", {
                    className: className
                },
                datum && column.prepend ? (React.createElement("span", {
                    className: 'prepand'
                }, column.prepend)) : null,

                cell,

                datum && column.append ? (React.createElement("span", {
                    className: 'append'
                }, column.append)) : null
            ));
        }
    }

    class Tr extends React.Component {
        render() {
            const schema = this.props.columns;
            const data = this.props.data;
            let className = this.props.className;
            const title = this.props.title;

            const override = this.props.data.override;
            if (override && override.tr) {
                className = `${className || ""} ${override.tr}`;
            }
            const tds = schema.filter(x => x.toRender !== false).map((node, i) => {
                const key = 'td' + i;

                return (
                    React.createElement(Td, {key: key, data: data, column: node, title: i === 0 ? title : null})
                );
            });

            return (React.createElement("tr", {
                id: data.id ? "PK" + data.id : null,
                className: className
            }, tds));
        }
    }

    const types = {
        USER: User,
        DELIVERIES: Deliveries,
        NUMBER: Number_,
        TIMEBOX: Timebox,
        IMAGE: Image_,
        ROLES: Roles,
        LINK: Link,
        ICON: Icon,
        THUMBNAIL: Thumbnail,
        TEXT: Text,
        COMMAND: Command,
        "SAMPLES-REQUEST-MODAL": SamplesRequestModal,
        RESELLERCOMMAND: ResellerCommand,
        DISPATCH: Dispatch,
        BOOLEAN: Bool,
        DATE: Date_,
        TIME: Date_,
        SNOOZE: Snooze,
        DATETIME: Date_,
        REMARKS: Remarks,
        ARTWORK: Artwork,
        "TEXT-BLOB": TextBlob,
        LIGHTBOX: Lightbox,
        ORDERDESCRIPTION: OrderDescription,
        MEMBER: Member,
        TASKMESSAGES: TaskMessages,
        THUMBLINK: ThumbLink,
        COMMANDFULFILMENT: CommandFulfilment,
        "TRUSTPILOT-STARS": TrustpilotStars,
        "VIDEO-EVENTS": VideoEvents,
        "DISPUTE-ACTION": DisputeAction,
        "CUSTOMER-CREDIT-MODAL": CustomerCreditModal,
        "BUTTON": Button,
        "MULTIBUTTON": MultiButton
    };

    const getTotalConfigObject = function (item, schema) {
        return {
            key: item.key,
            data: item.node,
            columns: schema,
            className: item.className,
            title: item.title
        };
    };

    class ZinkTable extends React.Component {
        render() {
            const node = this.props.data;
            const className = "table table-hover main-table " + node.class;
            const schema = node.columns;
            const columns = schema.map((node, i) => {
                const key = 'col' + i;
                return (
                    React.createElement(Col, {key: key, data: node})
                );
            });
            const theads = schema.map((node, i) => {
                const key = 'th' + i;
                return (
                    React.createElement(Th, {key: key, data: node})
                );
            });

            let totalTop;
            let totalBottom;
            if (node.totalTop) {
                totalTop = [];
                [
                    {node: node.pageTotals, className: "page-totals", key: "top-page-total", title: "Page Total"},
                    {
                        node: node.filteredTotals,
                        className: "filter-totals",
                        key: "top-filter-total",
                        title: "Filter Total"
                    },
                    {node: node.grandTotals, className: "totals", key: "top-grand-total", title: "Grand Total"},
                    {
                        node: node.pageAverages,
                        className: "page-averages",
                        key: "top-page-average",
                        title: "Page Average"
                    },
                    {
                        node: node.filteredAverages,
                        className: "filter-averages",
                        key: "top-filter-average",
                        title: "Filter Average"
                    },
                    {node: node.grandAverages, className: "average", key: "top-grand-average", title: "Grand Average"}
                ].forEach(x => {
                    if (x.node) {
                        totalTop.push(React.createElement(Tr, getTotalConfigObject(x, schema)));
                    }
                });
            }

            if (node.totalBottom) {
                totalBottom = [];
                [
                    {node: node.pageTotals, className: "page-totals", key: "bottom-page-total", title: "Page Total"},
                    {
                        node: node.filteredTotals,
                        className: "filter-totals",
                        key: "bottom-filter-total",
                        title: "Filter Total"
                    },
                    {node: node.grandTotals, className: "totals", key: "bottom-grand-total", title: "Grand Total"},
                    {
                        node: node.pageAverages,
                        className: "page-averages",
                        key: "bottom-page-average",
                        title: "Page Average"
                    },
                    {
                        node: node.filteredAverages,
                        className: "filter-averages",
                        key: "bottom-filter-average",
                        title: "Filter Average"
                    },
                    {
                        node: node.grandAverages,
                        className: "average",
                        key: "bottom-grand-average",
                        title: "Grand Average"
                    }
                ].forEach(x => {
                    if (x.node) {
                        totalBottom.push(React.createElement(Tr, getTotalConfigObject(x, schema)));
                    }
                });
            }

            const body = node.data.map(node => {
                const key = 'tr' + node.id;
                return (
                    React.createElement(Tr, {key: key, data: node, columns: schema})
                );
            });

            return (React.createElement("div", null,

                /* React.createElement("div", {
                     dangerouslySetInnerHTML: {__html: "<h1>Effie was here</h1>"}
                 }),
 */
                React.createElement("table", {
                        className: className,
                        data: node
                    },
                    React.createElement("colgroup", null, columns),
                    React.createElement("thead", null,
                        React.createElement('tr', null, theads)
                    ),
                    totalTop && React.createElement("thead", null,
                    totalTop
                    ),
                    totalBottom && React.createElement("tfoot", null,
                    totalBottom
                    ),
                    React.createElement("tbody", null,
                        body
                    )
                )
            ));
        }
    }

    this.getTableReactor = function () {
        return ZinkTable;
    };

}
