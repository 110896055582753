import mixam from '../../boot/mixam';
import defineComponent from '../../../components/flight/lib/component';
import regional from '../../boot/regional';

// NOTE: this import looks unused, but it's very much needed!
import '../../../components/loqate-3.91/address';

export default defineComponent(MemberAddressManager);

function MemberAddressManager() {

    this.attributes({
        hideMethod: "fade",
        populateOnEvent: undefined,
        sameSelector: '[data-type="is-same-as-billing-address"]',
        deliveryAddressSelector: '[data-type="deliveryAddress"]',
        billingAddressSelector: '[data-type="billingAddress"]',
        regionContainerSelector: '[data-region]',
        firstNameSelector: '[data-type="first-name"]',
        lastNameSelector: '[data-type="last-name"]',
        companySelector: '[data-type="company"]',
        phoneSelector: '[data-type="phone"]',
        postcodeSelector: '[data-type="postcode"]',
        addressFormSelector: '#addressForm',
        line1Selector: '[data-type="line1"]',
        line2Selector: '[data-type="line2"]',
        townSelector: '[data-type="town"]',
        countySelector: '[data-type="county"]',
        countrySelector: '[data-type="country"]',
        manualEntryContainerSelector: '[data-type="manualEntry"]',
        manualEntryActionSelector: '[data-action="manualEntry"]',
        searchSelector: '[data-type="search"]',
        findAnotherSelector: '[data-type="findAnother"]',
        clearFormSelector: '[data-type="clearForm"]'
    });

    this.updateDisabledByVisibility = function(isSame) {
        this.select('regionContainerSelector')
            .find('.form-control:not(:visible)').prop("disabled", true)
            .end()
            .find('.form-control:visible').prop("disabled", false);

        // since the form is being hidden by animation
        // delivery address fields might still be visible when this function is called.
        // make sure delivery address fields are always disabled.
        if (isSame) {
            this.select('deliveryAddressSelector').find('.form-control').attr("disabled", true);
        }
    };

    this.doSameAsClick = function (event) {
        const $target = $(event.target),
            isSame = $target.prop("checked");

        const doHideToggle = this.attr.hideMethod === "fade" ?
            ($element) => {
                $element.toggleClass(this.attr.hideMethod);
            } :
            ($element) => {
                $element.toggle("normal");
            };

        doHideToggle(this.select('deliveryAddressSelector'));
        this.updateDisabledByVisibility(isSame);
    };

    this.validatePostcode = function (event) {
        const postcodeInput = this.select('postcodeSelector'),
            value = postcodeInput.val();

        if (!(value && value.length >= 6)) {
            $(this.attr.addressFormSelector).data("validator").invalidate([
                {
                    input: postcodeInput,
                    messages: [regional().address.postcodeMinLengthError]
                }
            ], event);
        } else {
            this.resetForm();
        }
    };

    this.resetForm = function () {
        $(this.attr.addressFormSelector).data("validator").reset();
    };

    this.resetToSearchMode = function() {
        this.$node.find('.validation-error').remove(); //remove any server-side validation errors
        this.postcodeControl.load();
        this.select('manualEntryContainerSelector').addClass('hidden');
        this.select('searchSelector').removeClass('hidden');
        this.select('searchSelector').find('.form-control').prop("disabled", false).val('');
    };

    this.clearForm = function (event) {
        const defaultCountryCode = event.currentTarget.dataset.defaultcountrycode;
        this.select(`${event.currentTarget.dataset.scope}Selector`).find('.form-control:visible').each(function() {
            const type = $(this)[0].dataset.type;
            if (type === 'country') {
                $(this).val(defaultCountryCode);
            } else if (type === 'county' && defaultCountryCode === 'US') {
                $(this).val(this.options[0].value);
            } else {
                $(this).val('');
            }
        });
    };

    this.enableManual = function () {
        this.select('searchSelector').addClass('hidden');
        this.select('manualEntryContainerSelector').removeClass('hidden');
        this.postcodeControl.destroy();
    };

    this.populateForm = function(address) {
        this.select('line1Selector').val(address.street1);
        this.select('line2Selector').val(address.street2);
        this.select('townSelector').val(address.town);
        this.select('countySelector').val(address.county);
        this.select('countrySelector').val(address.country);

        // Present for some use cases
        if (address.firstName) {
            this.select('firstNameSelector').val(address.firstName);
        }
        if (address.lastName) {
            this.select('lastNameSelector').val(address.lastName);
        }
        if (address.company) {
            this.select('companySelector').val(address.company);
        }
        if (address.phone) {
            this.select('phoneSelector').val(address.phone);
        }
        if (address.postcode) {
            this.select('postcodeSelector').val(address.postcode);
        }
    };

    this.doCountryChange = function(event, data, silent= false) {
        let countryCode = this.select('countrySelector').val();
        // Only the 4 countries with Mixam shops have dedicated handling...
        if (!['GB', 'US', 'CA', 'AU', 'DE'].includes(countryCode)) {
            countryCode = 'OTHER';
        }

        this.select('regionContainerSelector').attr('data-region', countryCode);
        this.updateDisabledByVisibility();
        if(!silent) {
            this.trigger("uiAddressEditCountryChange", {countryCode: countryCode});
        }
    };

    this.doPopulate = function(event, data) {
        setTimeout(() => {
            this.populateForm(data);
            this.enableManual();
            this.doCountryChange(event, data, true);
        }, 500);
    };

    this.initPostcodeControl = function(scope) {
        const language = mixam.shop.locale.replace('_', '-');
        const options = { key: mixam.loqateKey, manualEntryItem: true, culture: language, search: { language: language } };
        const pca = window.pca;

        const fields = [
            { element: scope+".search", field: "", mode: pca.fieldMode.SEARCH },

            { element: scope+".country", field: "CountryName", mode: pca.fieldMode.COUNTRY },

            { element: scope+".company", field: "Company", mode: pca.fieldMode.DEFAULT | pca.fieldMode.PRESERVE },
            { element: scope+".line1", field: "Line1", mode: pca.fieldMode.POPULATE },
            { element: scope+".line2", field: "Line2"},
            { element: scope+".town", field: "City", mode: pca.fieldMode.POPULATE },
            { element: scope+".county.GB", field: "ProvinceName", mode: pca.fieldMode.POPULATE },
            { element: scope+".county.US", field: "ProvinceName", mode: pca.fieldMode.POPULATE },
            { element: scope+".county.CA", field: "ProvinceName", mode: pca.fieldMode.POPULATE },
            { element: scope+".postcode.GB", field: "PostalCode", mode: pca.fieldMode.POPULATE },
            { element: scope+".postcode.US", field: "PostalCode", mode: pca.fieldMode.POPULATE },
            { element: scope+".postcode.CA", field: "PostalCode", mode: pca.fieldMode.POPULATE },
            { element: scope+".postcode.AU", field: "PostalCode", mode: pca.fieldMode.POPULATE },
            { element: scope+".postcode.DE", field: "PostalCode", mode: pca.fieldMode.POPULATE },
            { element: scope+".postcode.OTHER", field: "PostalCode", mode: pca.fieldMode.POPULATE }
        ];

        return new pca.Address(fields, options);
    };

    this.after('initialize', function () {
        const showFormCallback = () => {
            this.enableManual();
            this.updateDisabledByVisibility();
        };

        this.on('click', {
            sameSelector: this.doSameAsClick,
            findAnotherSelector: this.resetToSearchMode,
            manualEntryActionSelector: showFormCallback,
            clearFormSelector: this.clearForm
        });

        this.on('change', {
            countrySelector: this.doCountryChange
        });

        if (this.attr.populateOnEvent) {
            this.on(document, this.attr.populateOnEvent, this.doPopulate);
        }

        this.on(document, "uiCancelAddressEdit", this.resetToSearchMode);

        const scope = this.$node.attr("data-address")+"Address"; //based on current conventions
        this.postcodeControl = this.initPostcodeControl(scope);

        this.postcodeControl.listen("manual", showFormCallback);
        this.postcodeControl.listen("populate", showFormCallback);
        this.postcodeControl.listen("error", showFormCallback);
        this.postcodeControl.listen("error", (error) => {this.trigger("log", {params: ["msg=PCA Error - "+error]});});

        // data-init-manual is a one-time initialization setting on page load to support server-side validation
        if(this.$node.attr("data-init-manual")) {
            this.$node.removeAttr("data-init-manual"); // removed to be safe in case this component gets re-initialized
            this.enableManual();
        }
    });
}

