import Chartjs from '../../../../../components/Chart.js';
import WithChartColors from "./with-chart-colors";
import WithChartData from "./with-chart-data";

import defineComponent from '../../../../../components/flight/lib/component';

export default defineComponent(YearViewBar, WithChartColors, WithChartData);

function YearViewBar() {

    this.attributes({
        chartSelector: '.chart',
        legendSelector: '.legend',
    });

    this.drawChart = function () {
        const data =  this.getChartData(),
            barChartData = {
            labels: this.getChartLabels(data),
            datasets: data
        };
        const ctx = this.select('chartSelector').get(0).getContext("2d");
        // noinspection JSValidateTypes
        this.chart = new Chartjs(ctx).Bar(barChartData, {
            responsive: true,
            title: {
                display: true,
                text: 'Year View'
            }
        });
    };

    this.getChartLabels = function (data) {
        let year = parseInt(data[0].label, 10) - 2;
        let prevValue = 0;
        return data[0].data.map((y, i) => {
            let label = `${year}`;
            if (i) {
                const growth = Math.round((y - prevValue) / prevValue * 100);
                label = `${i === 1 ? 'Previous' : 'This'} year (${growth}%)`;
            }
            prevValue = y;
            year += 1;
            return label;
        });
    };

    this.getChartData = function () {
        const totals = [];
        const dayOfMonth = (new Date()).getDate();
        const offset = dayOfMonth > 20 ? 0 : 1;
        const data = [
            {
                data: totals,
                label: this.data[offset].year,
                fillColor: this.getColor(4, 0.5),
                strokeColor: this.getColor(4, 0.95),
                highlightFil: this.getColor(4, 0.75),
                highlightStroke: this.getColor(4, 1),
            }
        ];

        for(let year = 0; year < 3; year++) {
            let sum = 0;
            for(let month = 0; month < 12; month++) {
                const index = year * 12 + month + offset;
                if (index < this.data.length) {
                    sum += this.data[index].total;
                } else {
                    break;
                }
            }
            totals.unshift(Math.round(sum));
        }
        return data;
    };

    this.setData = function (event, data) {
        this.data = data.response;
        this.drawChart();
    };


    this.after('initialize', function () {
        this.on(document, "uiDataReady", this.setData);
    });
}
