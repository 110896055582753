import mixam from '../../boot/mixam';
import ReactDom from  'react-dom';
import React from 'react';

const rd = ReactDom;

class ReviewStarts extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        const stars = this.props.review.stars;
        const className = 'stars stars-' + stars;
        const starElements = [];

        for (let i = 0; i < stars; i++) {
            starElements.push(<div  key={i} className="trustpilot-green-star">
                <img src="https://cdn.trustpilot.net/brand-assets/1.3.0/single-star-transparent.svg" />
            </div>)
        }
        return (<div className="review-stars">
            <span className={className}>
                {starElements}
            </span>
        </div>);
    }
}


class Member extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        const member = this.props.data || {};
        const dateTemplate = "dd/mm/yy";
        const timeTemplate = "h:MM TT";

        const review =  member.reviews && member.reviews[0];
        const name = `${member.firstName} ${member.lastName}`;
        const meberSince = member.dateCreated ? (new Date(member.dateCreated)).format(dateTemplate) : "";
        const lastSeen = member.lastSigninDate ? (new Date(member.lastSigninDate)).format(dateTemplate) + " " + (new Date(member.lastSigninDate)).format(timeTemplate) : "";
        const prefix = mixam.shop.currency.prefix;
        const postfix = mixam.shop.currency.postfix;

        const status = member.status > 0 ? "Verified account" : "Inactive"

        let company;
        let address;

        if (member.billingAddress) {
            company = member.billingAddress.company;
            address = ((member.billingAddress.town || "") + " " + (member.billingAddress.county || "") + " " + (member.billingAddress.country || "")).trim();
        }
        const style = {
            backgroundImage: `url(${member.photo})`
        };

        const confirmedOrderCount = member.confirmedOrderCount ? (<div className='member-order-count'>
            <span>{member.confirmedOrderCount.formatNumber(0)} orders for {prefix}{member.purchasesCount.formatNumber(0)}{postfix} </span>
            <span>(in average {prefix}{(member.purchasesCount / member.confirmedOrderCount).formatNumber(0)}{postfix} )</span>
        </div>) : null;

        const memberRemark = member.remark ? (<div className='member-remark'>
            {member.remark}
        </div>) : null;

        return (<div className='member-type in-table-box trustpilot trustpilot-mini'>
             <h3>
                 {member.photo && ( <div className="member-icon" style={style}></div>)}
                 <div>
                     <div>{name}</div>
                     {company && (<div className="company">{company}</div> )}
                     <div className="secondary">{member.email}</div>
                 </div>

             </h3>

            {review && ( <ReviewStarts review={review}></ReviewStarts>)}

            {meberSince && (<div>Member since: {meberSince}</div>)}
            {lastSeen && (<div>Last seen: {lastSeen}</div>)}
            {status && (<div>status: {status}</div>)}
            {address && (<div className="address">{address}</div> )}
            {confirmedOrderCount}
            {memberRemark}
        </div>);
    }
}

export default Member;
