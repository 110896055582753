import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
function ConfirmationModal(_a) {
    var handleClose = _a.handleClose, show = _a.show, content = _a.content, heading = _a.heading, closeButtonText = _a.closeButtonText, saveButtonText = _a.saveButtonText, handleSubmit = _a.handleSubmit;
    return (React.createElement(React.Fragment, null,
        React.createElement(Modal, { show: show, onHide: handleClose },
            React.createElement(Modal.Header, { closeButton: true },
                React.createElement(Modal.Title, null, heading)),
            React.createElement(Modal.Body, null, content),
            React.createElement(Modal.Footer, null,
                React.createElement(Button, { variant: "secondary", onClick: handleClose }, closeButtonText),
                React.createElement(Button, { variant: "primary", onClick: handleSubmit }, saveButtonText)))));
}
export default ConfirmationModal;
