import defineComponent from '../../../components/flight/lib/component';
import WithCustomerDiscount from "../with-customer-discount";

export default defineComponent(ReprintVat, WithCustomerDiscount);

/**
 * Shows a VAT line if the offer item's QueryResponse has (vat-system) data on it.
 */
function ReprintVat() {

    this.attributes({
        sumSelector: '[data-type="tax-sum"]',
        percentSelector: '[data-type="tax-percent"]'
    });

    this.reprintPriceAvailable = function (event, data) {
        this.item = data.item;
        this.render();
    };

    this.calcTotal = function () {
        let zeroRated = 0;
        let vatable = 0;

        const sum = this.item.response.total;
        const price = this.applyDiscount(sum, this.item.query || this.item.santa);

        if (this.item.response.forceVatable || this.item.vatable) {
            vatable += price;
        } else {
            zeroRated += sum;
        }

        // Proofs are always vatable TODO Support hardproof tax in other markets
        if (this.item.response.hardProofRequired || (Array.isArray(this.item.proofRequests) && this.item.proofRequests.length)) {
            vatable += this.item.response.hardProofTotal || 0;
        }

        this.zeroRated = zeroRated;
        this.vatable = vatable;
    };

    this.render = function () {
        const rate = this.item && this.item.response.vat ? this.item.response.vat.rate : 0;
        const prefix = this.item && this.item.response.currency ? this.item.response.currency.prefix || "" : "";
        const postfix = this.item && this.item.response.currency ? this.item.response.currency.postfix || "" : "";

        this.calcTotal();
        if (this.vatable) {
            if (this.vatable >= this.zeroRated) {
                this.$node.removeClass('hidden');
                if (this.prevVisibility && this.prevVisibility !== 'visible') {
                    requestAnimationFrame(() => this.$node.hilightElement());
                }
                this.prevVisibility = 'visible';
            } else {
                this.select('cartTaxSelector').addClass('hidden');
                this.$node.addClass('hidden');
                this.prevVisibility = 'hidden';
            }

            this.select('percentSelector').text(rate + '%');
            this.vat = Math.round(this.vatable * rate) / 100;
            this.select('sumSelector').text(`${prefix}${this.vat.formatNumber(2)}${postfix}`);
            if (this.prevVat && this.prevVat !== this.vat) {
                requestAnimationFrame(() => this.select('sumSelector').hilightElement());
            }
            this.prevVat = this.vat;
            this.trigger('afterReprintVatRender', {sum: this.vat});
        } else {
            this.$node.addClass('hidden');
            this.prevVisibility = 'hidden';
            this.trigger('afterReprintVatRender', {sum: 0});
        }
    };

    this.after('initialize', function () {
        this.prevVisibility = undefined;
        this.prevVat = undefined;

        this.on(document, 'reprintPriceAvailable', this.reprintPriceAvailable);
    });

}