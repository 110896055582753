import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import { toWidget } from '@ckeditor/ckeditor5-widget/src/utils';
import Widget from '@ckeditor/ckeditor5-widget/src/widget';
import WidgetCommand from './widget-command';
import UpdateWidgetConfigCommand from './update-widget-config-command';
import {Modal} from 'bootstrap';
import axios from 'axios';

export default class WidgetEditing extends Plugin {

    static get requires() {
        return [ Widget ];
    }

    init() {
        const widgetEditing = this;
        const editor = this.editor;

        this._defineSchema();
        this._defineConverters();

        editor.commands.add( 'widget', new WidgetCommand(editor));
        editor.commands.add('updateWidgetConfig', new UpdateWidgetConfigCommand(editor));

        // Widget Configuration Option Modal
        editor.editing.view.document.on('click', (evt, data) => {
            const widgetElement = data.domTarget.closest('.ck-widget');
            if (widgetElement) {

                const widgetView = editor.editing.view.domConverter.mapDomToView(widgetElement);

                const widgetName = widgetElement.getAttribute('name');
                const configValues = JSON.parse(widgetElement.getAttribute('data-config'));

                // Get The Configuration Data For The Widget & Show A Modal
                if(widgetName && configValues) {
                    axios.get(`/admin/api/ckeditor/widgets/${widgetName}`)
                        .then((response) => {
                            widgetEditing.constructWidgetConfigModal(widgetView, response.data, configValues);
                        })
                        .catch((error) => {
                            console.log('Error Retrieving Widget Configuration: ' + error);
                        });
                }
            }
        });
    }

    _defineSchema() {
        const schema = this.editor.model.schema;

        schema.register( 'widget', {
            isObject: true,
            allowWhere: '$block',
            allowAttributes: ['name', 'label', 'data-config']
        } );
    }

    _defineConverters() {
        const conversion = this.editor.conversion;

        conversion.for('upcast').elementToElement({
            view: {
                name: 'widget',
                attributes: {
                    'name': true,
                    'label': true,
                    'data-config': true
                }
            },
            model: ( viewElement, { writer } ) => {
                return writer.createElement('widget', {
                    name: viewElement.getAttribute('name'),
                    label: viewElement.getAttribute('label'),
                    'data-config': JSON.parse(viewElement.getAttribute('data-config'))
                });
            }
        });

        conversion.for('dataDowncast').elementToElement({
            model: 'widget',
            view: (modelElement, { writer: viewWriter }) => {
                const widgetElementView = viewWriter.createContainerElement('widget', {
                    'name': modelElement.getAttribute('name'),
                    'label': modelElement.getAttribute('label'),
                    'data-config': JSON.stringify(modelElement.getAttribute('data-config'))
                });

                return widgetElementView;
            }
        });

        conversion.for( 'editingDowncast' ).elementToElement( {
            model: 'widget',
            view: ( modelElement, { writer: viewWriter } ) => {

                const containerElementView = viewWriter.createContainerElement('div', {
                    class: 'btn btn-common bnt-block bg-light rounded p-2 border',
                    disabled: true,
                    'name': modelElement.getAttribute('name'),
                    'label': modelElement.getAttribute('label'),
                    'data-config': JSON.stringify(modelElement.getAttribute('data-config'))
                });

                viewWriter.insert( viewWriter.createPositionAt(containerElementView, 0), viewWriter.createEmptyElement('i', {
                    class: 'bi bi-gear-fill'
                }));
                viewWriter.insert(viewWriter.createPositionAt(containerElementView, 1), viewWriter.createText(' ' + modelElement.getAttribute('label') + ' Widget'));

                return toWidget( containerElementView, viewWriter, { label: 'Widget' } );
            }
        } );
    }

    constructWidgetConfigModal(widgetView, widgetConfiguration, existingValues) {

        const configFormOptions = widgetConfiguration.configurationProperties.map(configProperty => {
            const inputType = configProperty.type === 'TEXT' ? 'text' : 'select';
            const configValue = configProperty.name in existingValues ? existingValues[configProperty.name] : configProperty.value || '';

            if (inputType === 'text') {
                return `
                  <div class="mb-3">
                    <label for="${configProperty.name}" class="form-label">${configProperty.label}</label>
                    <input type="${inputType}" class="form-control" id="${configProperty.name}" placeholder="${configProperty.label}" value="${configValue}" ${configProperty.required ? 'required' : ''}>
                  </div>
                `;
            } else if (inputType === 'select') {
                // Generate select element for select type
                const options = configProperty.options.map(option => {
                    const isSelected = option === configValue ? 'selected' : '';
                    return `<option value="${option}" ${isSelected}>${option}</option>`;
                }).join('');

                return `
                  <div class="mb-3">
                    <label for="${configProperty.name}" class="form-label">${configProperty.label}</label>
                    <select class="form-control" id="${configProperty.name}" ${configProperty.required ? 'required' : ''}>
                      ${options}
                    </select>
                  </div>
                `;
            }
        }).join('');

        var modalElement = document.createElement('div');
        modalElement.className = 'modal';
        modalElement.innerHTML = `
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">${widgetConfiguration.label} Configuration</h5>
              </div>
              <div class="modal-body">
                <form id="widgetConfigForm">
                    ${configFormOptions}
                </form>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-primary" data-type="update-widget-config">Save</button>
              </div>
            </div>
          </div>
        `;

        document.body.appendChild(modalElement);

        const modal = Modal.getOrCreateInstance(modalElement);
        modal.show();

        document.querySelector('[data-type="update-widget-config"]').addEventListener('click', () => {

            const formElements = modalElement.querySelectorAll('input, select');
            const formData = {};

            formElements.forEach(element => {
                const elementType = element.tagName.toLowerCase();
                const elementValue = elementType === 'select' ? element.options[element.selectedIndex].value : element.value;
                formData[element.id] = elementValue;
            });

            // Update The View
            widgetView._setAttribute('data-config', JSON.stringify(formData));

            // Update The Model For Upcasting
            this.editor.execute('updateWidgetConfig', formData);

            modal.hide();

            document.body.removeChild(modalElement);

        });
    }


}