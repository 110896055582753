export var DiscountCodeApplicationType;
(function (DiscountCodeApplicationType) {
    DiscountCodeApplicationType["ORDER"] = "ORDER";
    DiscountCodeApplicationType["ITEM"] = "ITEM";
})(DiscountCodeApplicationType || (DiscountCodeApplicationType = {}));
export var DiscountCodeType;
(function (DiscountCodeType) {
    DiscountCodeType["FIXED"] = "FIXED";
    DiscountCodeType["PERCENTAGE"] = "PERCENTAGE";
    DiscountCodeType["FREE_DELIVERY"] = "FREE_DELIVERY";
})(DiscountCodeType || (DiscountCodeType = {}));
