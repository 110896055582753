import defineComponent from '../../../../components/flight/lib/component';

export default defineComponent(SupplierDelayEditor);

function SupplierDelayEditor() {

    this.attributes({
        removeDelayBtn: '[data-type="remove-delay-btn"]',
        addDelayBtn: '[data-type="add-delay-btn"]',
        delayValue: '[data-field="delay-value"]',
        delayDays: '[data-field="delay-days"]',
    });

    this.removeDelay = function(event) {
        const removeSupplierDelayUrl = `/admin/catalogue/suppliers/${this.supplierId}/delay/remove`;
        const data = {
            supplierId: this.supplierId,
            fieldName: this.delayType,
            id: $(event.target).data('delay-id')
        };
        $.post(removeSupplierDelayUrl, data).done((response) => {
            $(this.node).html(response);
        });
        event.preventDefault();
    };

    this.addDelay = function(event) {
        const addSupplierDelayUrl = `/admin/catalogue/suppliers/${this.supplierId}/delay/add`;
        const data = {
            supplierId: this.supplierId,
            fieldName: this.delayType,
            value: this.select('delayValue').val(),
            delay: this.select('delayDays').val(),
        };
        $.post(addSupplierDelayUrl, data).done((response) => {
            $(this.node).html(response);
        });
        event.preventDefault();
    };


    this.after('initialize', function () {
        this.supplierId = $(this.node).data('supplier-id');
        this.delayType = $(this.node).data('delay-type');
        this.on('click', {
            removeDelayBtn: this.removeDelay,
            addDelayBtn: this.addDelay
        });
    });
}
