import defineComponent from '../../../../components/flight/lib/component';
import axios from "axios";

export default defineComponent(CreatePageRanges);

function CreatePageRanges() {

    this.attributes({
        savePageRangeButton: '[data-type="save-page-range-btn"]',
        bindingButton: '[data-type="binding-btn"]',
        substrateButton: '[data-type="substrate-btn"]'
    });

    this.savePageRange = function() {

        if (this.node.checkValidity()) {

            const editor = this;
            editor.disableSaveBtn();

            const request = {
                bindingTypes: this.select('bindingButton').val(),
                substrateTypes: this.select('substrateButton').val()
            };

            axios.post(
                `/admin/catalogue/suppliers/${editor.supplierId}/page-ranges`,
                request
            ).then(
                response => {
                    // Take the user directly to edit the ranges...
                    window.location.href = `/admin/catalogue/suppliers/${editor.supplierId}/page-ranges/${response.data.fingerprint}/edit`;
                }
            ).catch(
                error => {
                    alert('Error Saving Data: ' + error.response.data.message);
                    editor.resetSaveBtn();
                }
            );
        } else {
            $(this.node).addClass('was-validated');
        }
    };

    this.disableSaveBtn = function() {
        let saveBtn = this.select('savePageRangeButton');
        setTimeout( function() {
            saveBtn.addClass('disabled');
            saveBtn.find('[data-type="spinner"]').removeClass('d-none');
            saveBtn.find('[data-type="btn-label"]').text("Saving");
        }, 1);
    };

    this.resetSaveBtn = function(event) {
        let saveBtn = this.select('savePageRangeButton');
        setTimeout( function() {
            saveBtn.find('[data-type="spinner"]').addClass('d-none');
            saveBtn.find('[data-type="btn-label"]').text("Add Page Range");
            saveBtn.removeClass('disabled');
        }, 2000);
    };

    this.after('initialize', function () {
        this.supplierId = $(this.node).data('supplier-id');
        this.on('click', {
            savePageRangeButton: this.savePageRange
        });
    });

}
