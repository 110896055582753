import mixam from '../boot/mixam';
import regional from '../boot/regional';
import constants from "./constants";
import Product from './constants/products';
import geometry from "./constants/geometry";

export default WithNormalizeMap;

const BLANK_IMAGE_URL = "https://s3-eu-west-1.amazonaws.com/static3.mixam.com/images/blank.png";
const SIZE_MISMATCH_ERROR = "Your artwork contains pages in different sizes";

function calcLeftRight(page) {
    const box = page.trimBox;

    if (page.hits && page.hits.length) {
        for (let i = 0; i < page.hits.length; i++) {    // jshint ignore:line
            const h = page.hits[i];
            /** @namespace h.urx */
            if (h.severity === "Warning" && h.urx) {
                /** @namespace h.lly */
                /** @namespace h.ury */
                if ((h.lly > box.top && h.ury < box.bottom) || Math.abs(h.llx - box.right) <= 1 || Math.abs(h.urx - box.left) <= 1) {
                    if (h.displayName.indexOf("Object is closer than 1.5 mm inside to TrimBox") !== -1) {
                        h.objectRight = null;
                        h.objectLeft = null;
                        if (h.urx > (box.right - 1) && h.llx > box.left) {
                            h.objectRight = true;
                        }
                        /** @namespace h.llx */
                        if (h.llx < (box.left + 1) && h.urx < box.right) {
                            h.objectLeft = true;
                        }
                    }
                }
            }
        }
    }
}

function getMediaWidth(page) {
    //noinspection JSUnresolvedVariable
    return (page.cropBox && page.cropBox.right - page.cropBox.left) || (page.mediaBox && page.mediaBox.right - page.mediaBox.left) ||
        (page.bleedBox && page.bleedBox.right - page.bleedBox.left) || (page.trimBox && page.trimBox.right - page.trimBox.left);
}

function getMediaHeight(page) {
    //noinspection JSUnresolvedVariable
    return (page.cropBox && page.cropBox.bottom - page.cropBox.top) || (page.mediaBox && page.mediaBox.bottom - page.mediaBox.top) ||
        (page.bleedBox && page.bleedBox.bottom - page.bleedBox.top) || (page.trimBox && page.trimBox.bottom - page.trimBox.top);
}

function getElasticBox(mapPage, page, box, cropBox) {
    if (box) {
        return translateBoxCords(box.left - cropBox.left, box.top - cropBox.top, box.bottom - cropBox.top, box.right - cropBox.left, {
            mediaWidth: getMediaWidth(page),
            mediaHeight: getMediaHeight(page)
        });
    }
    return null;
}

function translateBoxCords(left, top, bottom, right, container) {
    return {
        left: (left / container.mediaWidth * 100) + "%",
        bottom: (top / container.mediaHeight * 100) + "%",
        right: Math.max(0, (container.mediaWidth - right) / container.mediaWidth * 100) + "%",
        top: Math.max(0, (container.mediaHeight - bottom) / container.mediaHeight * 100) + "%"
    };
}


function getWarningBoxes(page, severity) {
    const boxes = [],
        paperWidth = getMediaWidth(page),
        paperHeight = getMediaHeight(page);

    if (page.hits && page.hits.length) {
        for (let i = 0; i < page.hits.length; i++) {
            const h = page.hits[i];
            //noinspection JSUnresolvedVariable
            if (h.severity === severity && h.urx) {
                if (mixam.user.hasRole("ROLE_MIXAM") || h.displayName.indexOf('mixam_hide_') === -1) {
                    //noinspection JSUnresolvedVariable
                    boxes.push({
                        description: h.displayName.replace(/mixam_hide_/, '') + (h.fontName ? " (" + h.fontName + ")" : "") + (h.type === "Image" && h.vppi ? " (Image: ppi:" + h.hppi + " x " + h.vppi + ")" : "") +
                            (h.type === "Fill" ? " (Fill)" : ""),
                        left: (h.llx / paperWidth * 100) + "%",
                        bottom: (h.lly / paperHeight * 100) + "%",
                        right: Math.max(0, 100 - (h.urx / paperWidth * 100)) + "%",
                        top: Math.max(0, 100 - (h.ury / paperHeight * 100)) + "%"
                    });
                }
            }
        }
    }
    return boxes;
}


function WithNormalizeMap() {

    this.PERFECT_GUTTER_SIZE = 7;
    this.DUSTJACKET_FLAP_WRAP_WIDTH = 7;

    this.isBound = function (item) {
        if (item.query.backCover === 0 && (item.query.productId === Product.WALL_CALENDAR || item.query.productId === Product.DESK_CALENDAR)) {
            return false;
        }
        return item.map.pages.length > 4 && item.bound; // TODO: remove item.bound!
    };

    this.getGutterSize = function (showMerged) {
        if (this.isBound(this.item) && this.item.query.bind !== 0) {
            return showMerged ? this.PERFECT_GUTTER_SIZE : 0;
        }
        return 0;
    };

    this.countAndFilterHits = function (item, page, severity, file, pageno) {
        let count = 0,
            collection = item[severity.toLowerCase() + "Pages"];

        if (page.hits && page.hits.length) {
            const hits = [...page.hits];

            for (let i = 0; i < hits.length; i++) {
                const h = hits[i];
                if (h.severity === severity) {
                    if (mixam.user.hasRole("ROLE_MIXAM") || h.displayName.indexOf('mixam_hide_') === -1) {
                        //noinspection JSUnresolvedVariable
                        if ("The page does not have a bleed box" === h.displayName) {
                            page.hits.splice(i, 1);

                        } else { //if ("Object is closer than 1.5 mm inside to TrimBox" != h.displayName) {

                            // if we have a booklet (pages > 4)
                            if (this.isBound(item)) {
                                if (pageno % 2) {  // right side page
                                    if (h.objectLeft) {
                                        //hits[i] = null;
                                        continue;
                                    }
                                } else {            // left page
                                    if (h.objectRight) {
                                        //hits[i] = null;
                                        continue;
                                    }
                                }
                            }
                            count += 1;
                        }
                    }
                }
            }
        }

        if (count && collection) {
            page.file = file;
            page.pageno = pageno;
            collection.push(page);
        }
        return count;
    };

    this.normalizeMap = function (order, item, showMerged) {
        let side,
            page,
            sizeError,
            coverPagesCount = 0,
            pagesOffset = 0,
            hit,
            classNames = item.query.sides !== 0 ? ['front', 'back'] : item.isRtl ? ['right', 'left'] : ['left', 'right'],
            result = $.extend(true, {}, item.map);


        item.showMerged = showMerged;
        item.errorCount = 0;
        item.warningCount = 0;
        item.errorPages = [];
        item.warningPages = [];

        result.sides = item.query.sides;
        result.hasDustJacket = item.hasDustJacket;
        result.simpleFold = item.query.simpleFold;
        result.productId = item.query.productId;
        result.sizeMatchingType = item.sizeMatchingType;
        /** @namespace item.query._secondaryFormat */
        result.sizeClassName = `${item.query._secondaryFormat ? regional().dictionary.secondaryFormat[item.query._secondaryFormat].replace(/(\s|\(|\))+/g, '_') : 'A' + item.map.size}-${item.query.sides}sides-${item.query.orientation ? 'landscape' : 'portrait'}-${item.query.simpleFold}`;
        if (/^[0-9]+/.test(result.sizeClassName)) {
            result.sizeClassName = "S" + result.sizeClassName;
        }
        if (!item.query.secondaryFormat) {
            result.jobClassName = `job-size-a${item.map.size}`;
        }
        result.itemOrientation = item.query.orientation;
        if (this.isBound(item)) {
            result.isBook = true;
        } else if (item.query.sides !== 0) {
            result.sides = item.query.sides;
            if (result.sides > 4) {
                result.hasManySides = true;
            }

            if (item.query.simpleFold === 1 && result.pages.length > 2) {
                result.halfPages = true;
            }
        }
        if (order.orderStatusInt >= 10) {
            result.confirmed = true;
        }

        result.secondaryFormat = item.query.secondaryFormat;

        // remove first 2 pages and last 2 pages
        if (showMerged && this.isShowMergeCover(order, item)) {
            result.hasMergedFiles = true;
            result.pages.length -= 1;
            result.pages.splice(0, 1);
            coverPagesCount += 2;
            pagesOffset += 1;
        }

        // spine
        const spineAccuracy = "IMPERIAL" === mixam.shop.sizeFormat ? 3 : 2;
        if (item.response && this.isBound(item) && item.query.bind !== 0 && item.query.bind < 8) {
            if (result.spine && result.spine.file) {
                page = result.spine;

                page.spineWidth = (item.response.spine || 0).formatNumber(spineAccuracy);
                if (page.file === "blank") {
                    page.blank = true;
                    page.thumb = BLANK_IMAGE_URL;
                    page.hirez = BLANK_IMAGE_URL;
                } else {
                    let file = this.getFileById(item, result.spine.file);
                    if (file) {
                        let spineFileName = result.spine.page;
                        page.thumb = this.getFolderFromFileUrl(file.url) + "/" + result.spine.file.replace(/\.pdf$/, "_thumb_" + spineFileName + ".jpg");
                        page.hirez = this.getFolderFromFileUrl(file.url) + "/" + result.spine.file.replace(/\.pdf$/, "_" + spineFileName + ".jpg");
                    }
                    page.id = "CS";
                }
            } else {
                result.spine = {
                    spineWidth: (item.response ? item.response.spine : 0).formatNumber(spineAccuracy)
                };
            }
        }

        // jacket
        if (item.hasDustJacket) {
            if (result.jacket && result.jacket.file) {
                page = result.jacket;
                const file = this.getFileById(item, result.jacket.file);
                if (file) {
                    const jacketPage = file.pages.pages[0];
                    page.thumb = this.getFolderFromFileUrl(file.url) + "/" + result.jacket.file.replace(/\.pdf$/, "_thumb_" + result.jacket.page + ".jpg");
                    page.hirez = this.getFolderFromFileUrl(file.url) + "/" + result.jacket.file.replace(/\.pdf$/, "_" + result.jacket.page + ".jpg");
                    page.trimBox = getElasticBox(null, jacketPage, jacketPage.trimBox, jacketPage.cropBox);
                    page.bleedBox = getElasticBox(null, jacketPage, jacketPage.bleedBox, jacketPage.cropBox);

                    page.mediaWidth = getMediaWidth(jacketPage);
                    page.mediaHeight = getMediaHeight(jacketPage);
                    page.aspect = page.mediaHeight / page.mediaWidth * 100;

                    let gutterBox = {};
                    let spineWidth = result.spine.spineWidth;
                    if(order.sizeFormat === 1) { //IMPERIAL
                        spineWidth = spineWidth * 25.4;
                    }
                    let center = (jacketPage.mediaBox.right - jacketPage.mediaBox.left)/2;
                    gutterBox.top = 0;
                    gutterBox.bottom = page.mediaHeight;
                    gutterBox.left = center - (parseFloat(spineWidth)/2);
                    gutterBox.right = gutterBox.left + parseFloat(spineWidth);
                    page.gutterBox = getElasticBox(null, jacketPage, gutterBox, jacketPage.cropBox);

                    if(item.dimensions != null) {
                        // Dimension's Might Be Null Because They Are Only Calculated Once A Body Page Has Been Uploaded
                        let flapWidth = this.getFlapWidth(item.dimensions.width, order.sizeFormat);
                        if (flapWidth != null) {
                            let flapFoldBackBox = {};
                            flapFoldBackBox.top = 0;
                            flapFoldBackBox.bottom = page.mediaHeight;
                            flapFoldBackBox.left = jacketPage.trimBox.left + flapWidth;
                            flapFoldBackBox.right = flapFoldBackBox.left + this.DUSTJACKET_FLAP_WRAP_WIDTH;
                            page.flapFoldBackBox = getElasticBox(null, jacketPage, flapFoldBackBox, jacketPage.cropBox);

                            let flapFoldFrontBox = {};
                            flapFoldFrontBox.top = 0;
                            flapFoldFrontBox.bottom = page.mediaHeight;
                            flapFoldFrontBox.left = jacketPage.trimBox.right - flapWidth - this.DUSTJACKET_FLAP_WRAP_WIDTH;
                            flapFoldFrontBox.right = flapFoldFrontBox.left + this.DUSTJACKET_FLAP_WRAP_WIDTH;
                            page.flapFoldFrontBox = getElasticBox(null, jacketPage, flapFoldFrontBox, jacketPage.cropBox);
                        }
                    }
                    page.id = "JK";
                }
            }
        }

        // body
        let latestASpect = 0;

        result.pages.forEach((page, index) => {
            page.id = pagesOffset + index + 1;

            page.className = this.getClassName(item, page.id, classNames); // classNames[page.id % 2];
            if (item.query.bind >= 6 && item.query.bind <= 7) {
                page.color = item.query.endPaperColor;
            }
            if (item.query.sides !== 0) {
                //page.foldSide = index < result.sides / 2 ? 'Outside' : 'Inside';
                page.pageNumber = index + 1;   //(index % (result.sides / 2)) + 1;
            }
            if (page.file) {
                if (page.file === "blank") {
                    page.blank = true;
                    page.thumb = BLANK_IMAGE_URL;
                    page.hirez = BLANK_IMAGE_URL;
                    page.aspect = latestASpect !== 0 ? latestASpect : null;
                } else {
                    side = page.side ? "-" + page.side : "";
                    page.thumb = result.uri + "/" + page.file.replace(/\.pdf$/, "_thumb_" + page.page + side + ".jpg");
                    page.hirez = result.uri + "/" + page.file.replace(/\.pdf$/, "_" + page.page + side + ".jpg");

                    this.addConvertResult(item, page, page.id);

                    if (result.productId === Product.CANVAS) {
                        this.addCanvasBoundryLines(item, page);
                    }

                    if (Product.isSticker(result.productId)) {
                        const stickerComponent = item.query.itemSpecification.components.find(c => c.componentType === "STICKER");
                        if(stickerComponent.shape === "CIRCLE" || stickerComponent.shape === "OVAL") {
                            page.trimBox.radius = 50;
                        }
                    }

                    const coverSpreads = item.map.coverSpread || 0;
                    if (!coverSpreads || (index > 0 && index < result.pages.length - 1)) {
                        latestASpect = page.aspect;
                    }
                }
            }
        });

        result.pages.forEach(page => {
            if (page.file && page.file === "blank") {
                page.aspect = latestASpect !== 0 ? latestASpect : null;
            }
        });

        result.backCover = item.query.backCover;
        result.pageCount = coverPagesCount + result.pages.length;
        if (result.pageCount > 12) {
            result.hasMore = result.pageCount - 12;
        }

        if (showMerged) {
            if (this.isShowMergeCover(order, item)) {
                //result.pages.unshift(this.createCoverPage(item, 2));
                result.pages.unshift(this.createCoverPage(item, 1));
                result.pages[0].pageCount = item.map.pages.length;

                if (mixam.user.hasRole("ROLE_MIXAM")) {
                    sizeError = this.getSizeError(item);
                    if (sizeError) {
                        result.pages[0].hits = result.pages[0].hits || [];
                        hit = {
                            displayName: sizeError,
                            severity: "Error",
                            type: "Mismatch"
                        };
                        result.pages[0].hits.push(hit);
                        //result.pages[0].file = "na";
                        //result.pages[0].pageno = 1;
                        item.errorPages.push(hit);
                        item.errorCount = (item.errorCount || 0) + 1;
                    }
                }
            } else if (item.map.coverSpread) {
                for (let i = 0; i < item.map.coverSpread; i++) {
                    result.pages[i].isCoverPage = 'cover-page';
                    result.pages[i].className = item.isRtl ? "right" : "left";
                    result.pages[i].thumb = result.pages[i].thumb.replace(/-left\.jpg$/, ".jpg");
                    result.pages[i].hirez = result.pages[i].hirez.replace(/-left\.jpg$/, ".jpg");
                    result.pages[i].coverSpreadPage = true;
                    result.pages[i].orientation = 1;
                }
                result.isCoverSpread = 'true';
                result.coverSpreadCount = item.map.coverSpread;
                result.pages.length -= item.map.coverSpread;
            } else if (this.isBound(item)) {
                result.pages.splice(1, 0, result.pages.pop());
                result.pages[0].isCoverPage = 'cover-page';
                result.pages[1].isCoverPage = 'cover-page';
                result.pages[0].className = item.isRtl ? "right" : "left";
                result.pages[1].className = item.isRtl ? "left" : "right";
                result.isBackCoverPage = 'true';
            }
        } else {
            if (item.map.coverSpread) {
                for (let i = 0; i < item.map.coverSpread; i++) {
                    const endPageIndex = result.pages.length - (i + 1);
                    const page_ = result.pages[i];
                    const endPage_ = result.pages[endPageIndex];
                    const spineWidth = item.response ? item.response.spine : 0;
                    result.pages[i] = this.shiftBox(page_, result.pages[i + 2], spineWidth, i !== 0);
                    result.pages[endPageIndex] = this.shiftBox(endPage_, result.pages[i + 2], spineWidth, i === 0);

                    let coverSpreadFileName =  "-left.jpg";
                    if (i === 0) {
                        result.pages[i].thumb = page_.thumb.replace(/-left\.jpg$/, "-right.jpg");
                        result.pages[i].hirez = page_.hirez.replace(/-left\.jpg$/, "-right.jpg");
                        if (result.pages[endPageIndex].thumb) {
                            result.pages[endPageIndex].thumb = result.pages[item.map.pages.length - 1].thumb.replace(/-right\.jpg$/, coverSpreadFileName);
                            result.pages[endPageIndex].hirez = result.pages[item.map.pages.length - 1].hirez.replace(/-right\.jpg$/, coverSpreadFileName);
                        }
                    }
                }
            }
        }

        result.coverFirst = result.template === "cover-first" ? true : null;
        result.next = 0;
        result.orientationName = result.orientation ? "landscape" : "portrait";
        result.BLANK_IMAGE_URL = BLANK_IMAGE_URL;
        if (item.query.sides > 0 && item.sizeMatchingType === 'FINAL') {
            result.pages = this.reArrangeFoldedMapToFolded(item, result.pages, item.query.simpleFold, item.query.sides);
        }
        return result;
    };

    this.getClassName = function (item, pageId, classNames) {
        if (item.geometry) {
            let page = this.getPageGeometry(item.geometry, pageId);
            if (page) {
                return page.name;
            }
        } else {
            return classNames[pageId % 2];
        }
    };

    this.getPageGeometry = function (geometry, pageId) {

        for (let i = 0; i < geometry.front.length; i++) {
            for (let j = 0; j < geometry.front[i].length; j++) {
                if (geometry.front[i][j].id === pageId) {
                    return geometry.front[i][j];
                }
            }
        }
        for (let i = 0; i < geometry.back.length; i++) {
            for (let j = 0; j < geometry.back[i].length; j++) {
                if (geometry.back[i][j].id === pageId) {
                    return geometry.back[i][j];
                }
            }
        }
    };

    this.isShowMergeCover = function (order, item) {
        return item.allMergedFiles && order.orderStatusInt >= 10 && !item.map.coverSpread &&
            (item.query.bind === constants.BIND.BIND_PERFECT_BOUND ||
                item.query.bind === constants.BIND.BIND_PERFECT_BOUND_PUR ||
                item.query.bind === constants.BIND.BIND_CASE);
    };

    this.shiftBox = function (originalPage, newPage, spineWidth, isLeft) {
        const page = $.extend({}, originalPage);
        page.mediaWidth = originalPage.mediaWidth / 2;
        page.width = newPage.width;
        let left;
        let right;
        if (isLeft) {
            left = 100 - (newPage.width + spineWidth / 2) / page.mediaWidth * 100;
            right = spineWidth / 2 / page.mediaWidth * 100;
        } else {
            right = 100 - (newPage.width + spineWidth / 2) / page.mediaWidth * 100;
            left = spineWidth / 2 / page.mediaWidth * 100;
        }
        page.trimBox = page.trimBox || {};
        page.trimBox.left = left + "%";
        page.trimBox.right = right + "%";

        const x = left + right;
        const y = parseFloat(page.trimBox.bottom) + parseFloat(page.trimBox.top);
        //const xOffset = (100 - x) / 2 + left;
        const xOffset = left / x * 100;

        page.backgroundPosition = xOffset + "% " + page.backgroundPositionY + "%";
        page.backgroundSize = (100 + (100 + x) / 100 * x) + "% " + (100 + (100 + y) / 100 * y) + "%";

        return page;
    };

    this.reArrangeFoldedMapToFolded = function (item, originalPages, simpleFold, sides) {
        const pages = [];

        if (simpleFold !== 5) {
            const middle = sides / 2;

            for (let i = middle + 2; i <= sides; i++) {
                pages.push(this.refreshPage(originalPages[i - 1], this.getClassName(item, originalPages[i - 1].id, [])));
            }
            pages.push(this.refreshPage(originalPages[0], this.getClassName(item, originalPages[0].id, [])));

            for (let i = 2; i < middle + 2; i++) {
                pages.push(this.refreshPage(originalPages[i - 1], this.getClassName(item, originalPages[i - 1].id, [])));
            }
        } else {
            pages.push(this.refreshPage(originalPages[2], "Outside"));
            pages.push(this.refreshPage(originalPages[1], "Outside"));
            pages.push(this.refreshPage(originalPages[4], "Inside"));
            pages.push(this.refreshPage(originalPages[5], "Inside"));
            pages.push(this.refreshPage(originalPages[3], "Back - outside"));
            pages.push(this.refreshPage(originalPages[0], "Front - outside"));
            pages.push(this.refreshPage(originalPages[6], "Inside"));
            pages.push(this.refreshPage(originalPages[7], "Inside"));
        }
        return pages;
    };

    this.refreshPage = function (page, side) {
        page.foldSide = side;
        return page;
    };

    this.getSizeError = function (item) {
        const mapPage = item.map.pages[0],
            file = this.getFileById(item, mapPage.file),
            page = file && file.pages.pages[mapPage.page - 1];

        if (page && page.hits && page.hits.length) {
            const errs = page.hits.filter(h => h.displayName.indexOf(SIZE_MISMATCH_ERROR) !== -1);
            if (errs.length) {
                return errs[0].displayName;
            }
        }
    };

    this.createCoverPage = function (item, pageNo) {
        const allFile = item.allMergedFiles.find(file => file.mergedFileType === "ALL");
        const imageUrl = allFile.href.replace(/\/([^\/]+?)\.pdf$/, "/merged_page_");
        if (!allFile.trimBox) {
            return {
                thumb: `${imageUrl}thumb_${pageNo}.jpg`,
                hirez: `${imageUrl}${pageNo}.jpg`,
                id: pageNo,
                errorCount: 0,
                warningCount: 0,
                orientation: 1
            };
        }

        const trimBox = getElasticBox(null, allFile, allFile.trimBox, allFile.cropBox);
        const x = parseFloat(trimBox.right) * 2;
        const y = parseFloat(trimBox.bottom) * 2;
        const mediaWidth = getMediaWidth(allFile);
        const mediaHeight = getMediaHeight(allFile);
        const fileName = allFile.href.split("/").pop();
        return {
            thumb: imageUrl + "thumb_" + pageNo + ".jpg",
            hirez: imageUrl + pageNo + ".jpg",
            id: pageNo,
            page: pageNo,
            file: fileName,
            tootip: this.getCoverTooltipData(allFile, fileName, pageNo),
            errorCount: 0,
            warningCount: 0,
            orientation: 1,
            width: allFile.trimBox.right - allFile.trimBox.left,
            height: allFile.trimBox.bottom - allFile.trimBox.top,
            mediaWidth: mediaWidth,
            mediaHeight: mediaHeight,
            trimBox: trimBox,
            bleedBox: getElasticBox(null, allFile, allFile.bleedBox, allFile.cropBox),
            backgroundPosition: "center center",
            backgroundSize: (100 + (100 + x) / 100 * x) + "% " + (100 + (100 + y) / 100 * y) + "%",
            aspect: mediaHeight / mediaWidth * 100
        };
    };

    this.getCoverTooltipData = function (mergedFile, fileName, pageNo) {
        const {plateName, width, height} = mergedFile;
        const x = Math.round(width);
        // noinspection JSSuspiciousNameCombination
        const y = Math.round(height);
        return `<div class='tooltip-content ${plateName.split(",").join(" ")}'>
            <h4>
                <i class='fa fa-file'></i> ${fileName}
            </h4>
            <h4>
                <span class='colors'>
                    <span class='colors-c'></span>
                    <span class='colors-m'></span>
                    <span class='colors-y'></span>
                    <span class='colors-k'></span>
                </span>
                Page: ${pageNo}
            </h4>
            <div>
                Size: ${x}mm. X ${y}mm.
            </div>
        </div>`;
    };

    this.addCanvasBoundryLines = function (item, mapPage) {
        const file = this.getFileById(item, mapPage.file);
        const finalSize = geometry.find(item);

        if (file) {
            const canvasPage = file.pages.pages[0];

            const trimWidth = canvasPage.trimBox.right - canvasPage.trimBox.left;
            const trimHeight = canvasPage.trimBox.bottom - canvasPage.trimBox.top;
            const trimCenterX = canvasPage.trimBox.left + trimWidth/2;
            const trimCenterY = canvasPage.trimBox.top + trimHeight/2;

            const frontFaceBox = {};
            frontFaceBox.left = trimCenterX - (finalSize.width/2);
            frontFaceBox.right = frontFaceBox.left + finalSize.width;
            frontFaceBox.top = trimCenterY - (finalSize.height/2);
            frontFaceBox.bottom = frontFaceBox.top + finalSize.height;
            mapPage.frontFaceBox = getElasticBox(null, canvasPage, frontFaceBox, canvasPage.cropBox);
        }
    };

    this.addConvertResult = function (item, mapPage, pageNo, alternativePage) {
        let file = this.getFileById(item, mapPage.file),
            page = alternativePage || file && file.pages.pages[mapPage.page - 1],
            x, y, side,
            xOffset, yOffset;

        mapPage.errorCount = 0;
        mapPage.warningCount = 0;

        if (page) {
            side = mapPage.side ? "-" + mapPage.side : "";

            let plateNames = page.plateName.split(',');
            let processedPlates = ['Cyan','Magenta','Yellow','Black'];
            let remainingPlates = plateNames.filter(value => !processedPlates.includes(value));
            mapPage.hasSeparationFile = remainingPlates.length > 0;

            if (file) {
                mapPage.hirez = this.getFolderFromFileUrl(file.url) + "/" + mapPage.file.replace(/\.pdf$/, "_" + mapPage.page + side + ".jpg");
                if (item.query.pages < 4) {
                    mapPage.thumb = mapPage.hirez;
                } else {
                    mapPage.thumb = this.getFolderFromFileUrl(file.url) + "/" + mapPage.file.replace(/\.pdf$/, "_thumb_" + mapPage.page + side + ".jpg");
                }
            }
            calcLeftRight(page, pageNo);
            if (mapPage.side !== "right") {
                page.errorCount = mapPage.errorCount = this.countAndFilterHits(item, page, "Error", mapPage.file, pageNo);
                page.warningCount = mapPage.warningCount = this.countAndFilterHits(item, page, "Warning", mapPage.file, pageNo);
            }
            mapPage.width = page.trimBox ? page.trimBox.right - page.trimBox.left : null;
            mapPage.height = page.trimBox ? page.trimBox.bottom - page.trimBox.top : null;
            mapPage.mediaWidth = getMediaWidth(page);
            mapPage.mediaHeight = getMediaHeight(page);
            mapPage.trimBox = getElasticBox(mapPage, page, page.trimBox, page.cropBox);
            mapPage.bleedBox = getElasticBox(mapPage, page, page.bleedBox, page.cropBox);
            mapPage.warningBoxes = getWarningBoxes(page, "Warning");
            mapPage.errorBoxes = getWarningBoxes(page, "Error");

            let trimBox = $.extend({}, page.trimBox);
            let effectiveBox = mapPage.trimBox;
            if (pageNo > 1 && pageNo < item.map.pages.length && this.isBound(item) && item.query.bind !== 0) {
                if (mapPage.className === 'left') {
                    trimBox.right -= this.getGutterSize(item.showMerged);
                } else {
                    trimBox.left += this.getGutterSize(item.showMerged);
                }
                effectiveBox = getElasticBox(mapPage, page, trimBox, page.cropBox);

                let box = $.extend(true, {}, page.trimBox);
                box.top = 0;
                box.bottom = mapPage.mediaHeight;
                if (mapPage.className === 'left') {
                    box.left = box.right - this.getGutterSize(item.showMerged);
                    box.right = mapPage.mediaWidth;
                } else {
                    box.right = box.left + this.getGutterSize(item.showMerged);
                    box.left = 0;
                }
                mapPage.gutterBox = getElasticBox(mapPage, page, box, page.cropBox);
            }

            x = parseFloat(effectiveBox.right) + parseFloat(effectiveBox.left);
            y = parseFloat(effectiveBox.bottom) + parseFloat(effectiveBox.top);

            // xOffset = x ? parseFloat(effectiveBox.left) / x * 100 : 50;
            // xOffset = (100 - x) / 2 + parseFloat(effectiveBox.left);
            // xOffset = x ? parseFloat(effectiveBox.left) / x * 100 : 50;
            xOffset = x ? 50 + (parseFloat(effectiveBox.left) - parseFloat(effectiveBox.right)) / 2 : 50;
            yOffset = y ? parseFloat(effectiveBox.top) / y * 100 : 50;

            const pages = item.query.sides / (item.query.simpleFold === 5 ? 4 : 2);
            const rows = item.query.simpleFold === 5 ? 2 : 1;

            mapPage.backgroundPosition = xOffset + "% " + yOffset + "%";
            /*
                Check for trimbox information on the page. If the page has no trimbox data then it will cause the whole
                artwork manager not to render. Better to not show the trimbox around the effected page and display
                everything else.
            */
            if(page.trimBox) {
                mapPage.backgroundPositionLeftX = page.cropBox.left - page.trimBox.left;
                mapPage.backgroundPositionRightX = page.trimBox.right - page.cropBox.right;
                mapPage.backgroundPositionTopY = page.cropBox.top - page.trimBox.top;
                mapPage.backgroundPositionBottomY = page.trimBox.bottom - page.cropBox.bottom;
            } else {
                // Use Cropbox Data Because We Should Have It
                mapPage.backgroundPositionLeftX = page.cropBox.left;
                mapPage.backgroundPositionRightX = page.cropBox.right;
                mapPage.backgroundPositionTopY = page.cropBox.top;
                mapPage.backgroundPositionBottomY = page.cropBox.bottom;
            }
            mapPage.backgroundPositionY = yOffset;

            if (item.query.simpleFold === 4 && item.query.sides === 6) {
                // 2 edge pages are 0nly 50% thus need a larger factor
                mapPage.backgroundSizeFlat = (pageNo % 2 ? 2 : 4) * (100 + (100 + x) / 100 * x) + "% " + rows * (100 + (100 + y) / 100 * y) + "%";
            } else {
                mapPage.backgroundSizeFlat = pages * (100 + (100 + x) / 100 * x) + "% " + rows * (100 + (100 + y) / 100 * y) + "%";
            }
            mapPage.backgroundSize = (100 + (100 + x) / 100 * x) + "% " + (100 + (100 + y) / 100 * y) + "%";

            mapPage.aspect = mapPage.mediaHeight / mapPage.mediaWidth * 100;
            mapPage.canRotate = file && file.originalFileUri && file.originalFileUri.match(/(\.png|\.jp?.g|\.tiff|\.tif|\.ai|\.eps|\.ps)/i) ? true : null;
            item.errorCount += mapPage.errorCount;
            item.warningCount += mapPage.warningCount;
        }
    };

    this.getFlapWidth = function (itemWidth, sizeFormat) {
        if (itemWidth >= 290) {
            return null;
        }
        if(sizeFormat === 1) { // IMPERIAL
            return 63.5; // 2.5 Inches Converted To mm
        } else {
            if (itemWidth >= 150) {
                return 80.0;
            }
            return 60.0;
        }
    };

    this.getFileById = (function (cache) {

        function findFile(item, id) {
            for (let i = 0; i < item.uploads.length; i++) {
                const upload = item.uploads[i];
                if (upload.status === "ready") {
                    for (let j = 0; j < upload.uploadedFiles.length; j++) {
                        const file = upload.uploadedFiles[j];
                        if (file.id === id) {
                            return file;
                        }
                    }
                }
            }
            return null;
        }

        return function (item, id) {
            if (!cache[id]) {
                cache[id] = findFile(item, id);
            }
            return cache[id];
        };
    }([]));

}
