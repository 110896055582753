import mixam from '../boot/mixam';
import defineComponent from '../../components/flight/lib/component';
import Mustache from '../../components/mustache/mustache';
import thankyouMessageContactTemplate from 'text!../../appes6/templates/shop/thankyou-message-contact.mustache';
import regional from '../boot/regional';

export default defineComponent(PremiumSubscribe);

function PremiumSubscribe() {
    this.attributes({
        formSelector: 'form',
        formGroupSelector: '.form-group',
        saveBtnSelector: 'button[type="submit"]',
        emailSelector: '[data-type="email"]',
        companySelector: '[data-type="company"]',
        nameSelector: '[data-type="name"]',
        surnameSelector: '[data-type="surname"]',
        phoneSelector: '[data-type="phone"]',
        companyRegNoSelector: '[data-type="companyRegNo"]',
        vatNoSelector: '[data-type="vatNo"]',
        spendSelector: '[data-type="spend"]',
        flatSelector: '[data-type="flat"]',
        foldedSelector: '[data-type="folded"]',
        boundSelector: '[data-type="bound"]',
        editSelector: '[data-type=edit]',
        thankyouSelector: '[data-type="thankyou"]'
    });

    this.resetForm = function () {
        this.select('formSelector').data("validator").reset();
        this.select('formGroupSelector').removeClass('has-error');
        this.select('saveBtnSelector').attr('data-loading', null).attr('disabled', false);
        this.select('editSelector').removeClass("hidden");
        this.select('thankyouSelector').addClass("hidden");
    };

    this.handleSubmit = function (event) {
        try {
            if (!event.isDefaultPrevented()) {
                this.select('saveBtnSelector').attr('data-loading', '*').attr('disabled', true);
                this.sendOrder();
            }
        } catch (ex) {
            const args = [];
            args.push("type=premium-subscribe");
            args.push("function=" + encodeURIComponent("PremiumSubscribe.handleSubmit"));
            args.push("msg=" + encodeURIComponent(ex.message));
            args.push("file=" + encodeURIComponent(ex.fileName || "unknown"));
            args.push("linenumber=" + encodeURIComponent(ex.lineNumber || 0));
            args.push("count=" + 1);
            args.push("col=" + 0);
            args.push("errorObj=" + encodeURIComponent(JSON.stringify({function: 'PremiumSubscribe.handleSubmit'})));
            args.push("csuser=" + encodeURIComponent(mixam.user.username || "n/a"));
            args.push("shop=" + encodeURIComponent(mixam.shop.name || "n/a"));
            mixam.log(args);
        }
        event.stopPropagation();
        event.preventDefault();
    };

    this.sendOrder = function () {
        const promise = $.post(this.select('formSelector').attr('action'), {
            email: this.select('emailSelector').val(),
            name: this.select('nameSelector').val(),
            surname: this.select('surnameSelector').val(),
            company: this.select('companySelector').val(),
            vatNo: this.select('vatNoSelector').val(),
            companyRegNo: this.select('companyRegNoSelector').val(),
            phone: this.select('phoneSelector').val(),
            flat: this.select('flatSelector').is(':checked') ? this.select('flatSelector').val() : "",
            folded: this.select('foldedSelector').is(':checked') ? this.select('foldedSelector').val() : "",
            bound: this.select('boundSelector').is(':checked') ? this.select('boundSelector').val() : "",
            spend: this.select('spendSelector').val()
        }, null, 'json');

        promise.done((response) => {
            this.resetForm();
            if (!response.name) {
                response.name = response.email;
            }
            if (response.status === "success") {
                this.select('thankyouSelector').html(this.renderThankyou(response));
                this.select('editSelector').addClass("hidden");
                this.select('thankyouSelector').removeClass("hidden");

            }
        });

        promise.fail((/*response*/) => this.resetForm());

    };

    this.renderThankyou = function (response) {
        response.dict = regional().contact;
        return Mustache.render(thankyouMessageContactTemplate, response);
    };

    this.after('initialize', function () {
        this.items = [];
        this.select('formSelector').validator({
            effect: 'mixam',
            errorClass: 'validation-error',
            errorInputEvent: 'keyup',
            formEvent: 'submit',
            inputEvent: null,
            grouped: true
        });

        this.on('submit', this.handleSubmit);
    });
}