var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useEffect, useState } from 'react';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Formik, Form as FormikForm } from 'formik';
import * as Yup from 'yup';
import { Spinner, Alert, Button, Form, Card, Table, Modal, Row, Col } from 'react-bootstrap';
import axios from 'axios';
import ConfirmationModal from './ConfirmationModal';
var ItemTypes = {
    ROW: 'row',
};
var validationSchema = Yup.object().shape({
    weights: Yup.array()
        .min(1, 'At least one weight must be selected.')
        .of(Yup.object().shape({
        weight: Yup.string().required(),
        weightUnit: Yup.string().required(),
        santaDefault: Yup.boolean(),
    }))
        .test('default-check', 'One of the weights must be set as default.', function (weights) {
        return weights ? weights.some(function (weight) { return weight.santaDefault; }) : false;
    })
});
var SubstrateTypesMetaDataEditor = function (_a) {
    var productId = _a.productId, subProductId = _a.subProductId, santaType = _a.santaType, substrateTypes = _a.substrateTypes, substratesAvailable = _a.substratesAvailable, coverSubstratesAvailable = _a.coverSubstratesAvailable, envelopeSubstratesAvailable = _a.envelopeSubstratesAvailable;
    var _b = useState(false), isLoading = _b[0], setLoading = _b[1];
    var _c = useState(null), error = _c[0], setError = _c[1];
    var _d = useState(null), successMessage = _d[0], setSuccessMessage = _d[1];
    var _e = useState(null), metadataDocumentWrapper = _e[0], setMetadataDocumentWrapper = _e[1];
    var _f = useState(false), useCustom = _f[0], setUseCustom = _f[1];
    var _g = useState(false), showModal = _g[0], setShowModal = _g[1];
    var _h = useState(null), modalOptionType = _h[0], setModalOptionType = _h[1];
    var _j = useState(''), newSubstrate = _j[0], setNewSubstrate = _j[1];
    var _k = useState(''), selectedColor = _k[0], setSelectedColor = _k[1];
    var _l = useState([]), weights = _l[0], setWeights = _l[1];
    var _m = useState([]), selectedWeights = _m[0], setSelectedWeights = _m[1];
    var _o = useState(false), allowLamination = _o[0], setAllowLamination = _o[1];
    var _p = useState(null), editIndex = _p[0], setEditIndex = _p[1];
    var _q = useState(false), showConfirmationModal = _q[0], setShowConfirmationModal = _q[1];
    useEffect(function () {
        setLoading(true);
        axios
            .get("/api/admin/metadata/product/substrate-types/products/".concat(productId, "/sub-products/").concat(subProductId, "/santa-types/").concat(santaType))
            .then(function (response) {
            setMetadataDocumentWrapper(response.data);
            if (subProductId !== 0) {
                setUseCustom(response.data.productMetadataDocument !== null);
            }
            setLoading(false);
        })
            .catch(function () {
            setLoading(false);
            setError('Failed to load substrate types metadata document.');
        });
    }, [productId, subProductId, santaType]);
    var handleColorChange = function (substrateColour, substrateType, skipReset) {
        if (skipReset === void 0) { skipReset = false; }
        setSelectedColor(substrateColour);
        var selectedSubstrate = substrateTypes.find(function (st) { return st.substrateType === substrateType; });
        var colorObj = selectedSubstrate === null || selectedSubstrate === void 0 ? void 0 : selectedSubstrate.substrateColours.find(function (c) { return c.substrateColour === substrateColour; });
        setWeights((colorObj === null || colorObj === void 0 ? void 0 : colorObj.weights) || []);
        if (!skipReset) {
            setSelectedWeights([]);
        }
    };
    var initializeSubstrateAndColor = function (availableSubstrateTypes) {
        var _a;
        var existingSubstrates = ((_a = metadataDocumentWrapper === null || metadataDocumentWrapper === void 0 ? void 0 : metadataDocumentWrapper.productMetadataDocument) === null || _a === void 0 ? void 0 : _a.substrates) || [];
        var firstAvailableSubstrate = availableSubstrateTypes.find(function (st) {
            return !existingSubstrates.some(function (option) { return option.substrateType === st.substrateType; });
        });
        var defaultSubstrateType = (firstAvailableSubstrate === null || firstAvailableSubstrate === void 0 ? void 0 : firstAvailableSubstrate.substrateType) || '';
        var colors = (firstAvailableSubstrate === null || firstAvailableSubstrate === void 0 ? void 0 : firstAvailableSubstrate.substrateColours) || [];
        var defaultColor = colors.length === 1 ? colors[0].name : '';
        setNewSubstrate(defaultSubstrateType);
        setSelectedColor(defaultColor);
        if (defaultColor) {
            handleColorChange(defaultColor, defaultSubstrateType);
        }
    };
    useEffect(function () {
        if (newSubstrate) {
            handleColorChange(selectedColor, newSubstrate, true);
        }
    }, [newSubstrate]);
    if (isLoading) {
        return React.createElement(Spinner, { animation: "grow", variant: "primary" });
    }
    if (!metadataDocumentWrapper) {
        return React.createElement(Alert, { variant: "danger" }, "No Substrate Types Metadata available");
    }
    var activeDocument = useCustom
        ? metadataDocumentWrapper === null || metadataDocumentWrapper === void 0 ? void 0 : metadataDocumentWrapper.productMetadataDocument
        : metadataDocumentWrapper === null || metadataDocumentWrapper === void 0 ? void 0 : metadataDocumentWrapper.defaultProductMetadataDocument;
    var isEditable = subProductId === 0 || useCustom;
    var saveSubstrateTypeMetadata = function (values, _a) {
        var _b;
        var setSubmitting = _a.setSubmitting;
        setLoading(true);
        setError(null);
        setSuccessMessage(null);
        var newDocument = activeDocument
            ? __assign({}, activeDocument) : {
            shopId: (_b = metadataDocumentWrapper === null || metadataDocumentWrapper === void 0 ? void 0 : metadataDocumentWrapper.defaultProductMetadataDocument) === null || _b === void 0 ? void 0 : _b.shopId,
            productId: productId,
            subProductId: subProductId,
            santaType: santaType
        };
        var saveRequest = {
            useDefault: !useCustom,
            substrateTypesMetadataDocument: __assign(__assign({}, newDocument), { substrates: values.substrates, coverSubstrates: values.coverSubstrates, envelopeSubstrates: values.envelopeSubstrates }),
        };
        axios
            .post("/api/admin/metadata/product/substrate-types/products/".concat(productId, "/sub-products/").concat(subProductId, "/santa-types/").concat(santaType), saveRequest)
            .then(function (response) {
            setMetadataDocumentWrapper(response.data);
            setLoading(false);
            setSubmitting(false);
            setSuccessMessage('Substrate Types Metadata saved successfully!');
        })
            .catch(function (error) {
            var _a;
            setLoading(false);
            setSubmitting(false);
            setError("Failed to save substrate types metadata: ".concat(((_a = error.response) === null || _a === void 0 ? void 0 : _a.data) || error.message));
        });
    };
    var handleSave = function (values, setSubmitting) {
        if (!useCustom && subProductId !== 0) {
            setShowConfirmationModal(true);
        }
        else {
            saveSubstrateTypeMetadata(values, setSubmitting);
        }
    };
    var handleCopyFromDefault = function () {
        if (metadataDocumentWrapper === null || metadataDocumentWrapper === void 0 ? void 0 : metadataDocumentWrapper.defaultProductMetadataDocument) {
            var defaultDocument = metadataDocumentWrapper.defaultProductMetadataDocument;
            var updatedCustomDocument = __assign(__assign({}, metadataDocumentWrapper.productMetadataDocument || {
                shopId: defaultDocument.shopId,
                productId: productId,
                subProductId: subProductId,
                santaType: santaType,
            }), { substrates: defaultDocument.substrates || [], coverSubstrates: defaultDocument.coverSubstrates || [], envelopeSubstrates: defaultDocument.envelopeSubstrates || [] });
            setMetadataDocumentWrapper(__assign(__assign({}, metadataDocumentWrapper), { productMetadataDocument: updatedCustomDocument }));
        }
    };
    var handleAddOption = function (type) {
        setEditIndex(null);
        setModalOptionType(type);
        initializeSubstrateAndColor(substrateTypes);
        setAllowLamination(false);
        if (newSubstrate && selectedColor) {
            handleColorChange(selectedColor, newSubstrate);
        }
        setShowModal(true);
    };
    var handleEditOption = function (type, index, values) {
        setEditIndex(index);
        setModalOptionType(type);
        var existingOption = values[type][index];
        setNewSubstrate(existingOption.substrateType);
        var selectedSubstrate = substrateTypes.find(function (st) { return st.substrateType === existingOption.substrateType; });
        if (selectedSubstrate) {
            var color_1 = existingOption.substrateColours[0];
            var colorObj = selectedSubstrate.substrateColours.find(function (c) { return c.substrateColour === color_1.colour; });
            setSelectedColor(color_1.colour);
            setWeights(colorObj ? colorObj.weights : []);
            var selectedWeightsUpdated = color_1.weights.map(function (colorWeight) { return (__assign(__assign({}, colorWeight), { santaDefault: !!color_1.weights.find(function (w) { return w.weight === colorWeight.weight && w.weightUnit === colorWeight.weightUnit && w.santaDefault; }) })); });
            setSelectedWeights(selectedWeightsUpdated);
        }
        else {
            setSelectedColor('');
            setWeights([]);
            setSelectedWeights([]);
        }
        setAllowLamination(existingOption.allowLamination);
        setShowModal(true);
    };
    var handleModalSave = function (setFieldValue, values) {
        validationSchema.validate({ weights: selectedWeights })
            .then(function () {
            if (modalOptionType) {
                var sortedWeights = __spreadArray([], selectedWeights, true).sort(function (a, b) { return parseFloat(a.weight) - parseFloat(b.weight); });
                var newOption = {
                    substrateType: newSubstrate,
                    substrateColours: [
                        {
                            colour: selectedColor,
                            weights: sortedWeights,
                        }
                    ],
                    allowLamination: allowLamination,
                    santaDefault: false,
                };
                var existingOptions = values[modalOptionType] || [];
                if (editIndex !== null) {
                    existingOptions[editIndex] = newOption;
                    setFieldValue(modalOptionType, __spreadArray([], existingOptions, true));
                }
                else {
                    var isDuplicate = existingOptions.some(function (option) {
                        return option.substrateType === newSubstrate &&
                            option.substrateColours.some(function (c) { return c.substrateColour === selectedColor; });
                    });
                    if (isDuplicate) {
                        setError('This substrate type and color combination already exists in the list.');
                        return;
                    }
                    setFieldValue(modalOptionType, __spreadArray(__spreadArray([], existingOptions, true), [newOption], false));
                }
                setShowModal(false);
                setError(null);
                setSelectedWeights([]);
            }
        })
            .catch(function (validationError) {
            setError(validationError.errors[0]);
        });
    };
    var handleRemoveOption = function (type, index, setFieldValue, values) {
        var updatedOptions = values[type].filter(function (_, i) { return i !== index; });
        setFieldValue(type, updatedOptions);
    };
    var moveRow = function (type, dragIndex, hoverIndex, setFieldValue, values) {
        var updatedOptions = __spreadArray([], values[type], true);
        var removed = updatedOptions.splice(dragIndex, 1)[0];
        updatedOptions.splice(hoverIndex, 0, removed);
        setFieldValue(type, updatedOptions);
    };
    var DraggableRow = function (_a) {
        var index = _a.index, type = _a.type, option = _a.option, setFieldValue = _a.setFieldValue, values = _a.values;
        var ref = React.useRef(null);
        var _b = useDrop({
            accept: ItemTypes.ROW,
            hover: function (item) {
                if (!ref.current) {
                    return;
                }
                var dragIndex = item.index;
                var hoverIndex = index;
                if (item.type !== type || dragIndex === hoverIndex) {
                    return;
                }
                moveRow(type, dragIndex, hoverIndex, setFieldValue, values);
                item.index = hoverIndex;
            },
        }), drop = _b[1];
        var _c = useDrag({
            type: ItemTypes.ROW,
            item: { type: type, index: index },
            collect: function (monitor) { return ({
                isDragging: monitor.isDragging(),
            }); },
        }), isDragging = _c[0].isDragging, drag = _c[1];
        drag(drop(ref));
        var colorRows = option.substrateColours.map(function (color) {
            var _a, _b, _c;
            return ({
                colour: ((_b = (_a = substrateTypes
                    .find(function (st) { return st.substrateType === option.substrateType; })) === null || _a === void 0 ? void 0 : _a.substrateColours.find(function (c) { return c.substrateColour === color.colour; })) === null || _b === void 0 ? void 0 : _b.name) || color.colour,
                weights: color.weights ? color.weights.map(function (w) { return w.weight; }).join(', ') : '',
                weightUnit: color.weights.length > 0 ? color.weights[0].weightUnit : '',
                defaultWeight: ((_c = color.weights.find(function (w) { return w.santaDefault; })) === null || _c === void 0 ? void 0 : _c.weight) || 'None',
            });
        });
        return (React.createElement(React.Fragment, null, colorRows.map(function (colorRow, colorIndex) {
            var _a;
            return (React.createElement("tr", { ref: ref, style: { opacity: isDragging ? 0.5 : 1 }, key: "".concat(index, "-").concat(colorIndex) },
                colorIndex === 0 && (React.createElement("td", { rowSpan: colorRows.length }, ((_a = substrateTypes.find(function (st) { return st.substrateType === option.substrateType; })) === null || _a === void 0 ? void 0 : _a.label) || option.substrateType)),
                React.createElement("td", null, colorRow.colour),
                React.createElement("td", null, colorRow.weights),
                React.createElement("td", null, colorRow.weightUnit),
                React.createElement("td", null, option.allowLamination ? 'Yes' : 'No'),
                React.createElement("td", null, colorRow.defaultWeight),
                React.createElement("td", { className: "text-end" },
                    React.createElement(Button, { className: "btn-sm me-2", variant: "primary", onClick: function () { return handleEditOption(type, index, values); }, disabled: !isEditable }, "Edit"),
                    React.createElement(Button, { className: "btn-sm", variant: "danger", onClick: function () { return handleRemoveOption(type, index, setFieldValue, values); }, disabled: !isEditable }, "Remove"))));
        })));
    };
    return (React.createElement("div", null,
        React.createElement(DndProvider, { backend: HTML5Backend },
            React.createElement(Formik, { initialValues: {
                    substrates: (activeDocument === null || activeDocument === void 0 ? void 0 : activeDocument.substrates) || [],
                    coverSubstrates: (activeDocument === null || activeDocument === void 0 ? void 0 : activeDocument.coverSubstrates) || [],
                    envelopeSubstrates: (activeDocument === null || activeDocument === void 0 ? void 0 : activeDocument.envelopeSubstrates) || [],
                }, enableReinitialize: true, onSubmit: handleSave }, function (_a) {
                var _b, _c, _d, _e;
                var isSubmitting = _a.isSubmitting, values = _a.values, setFieldValue = _a.setFieldValue, setSubmitting = _a.setSubmitting;
                return (React.createElement(React.Fragment, null,
                    React.createElement(Card, { className: "p-4 shadow-sm bg-light" },
                        React.createElement(FormikForm, null,
                            React.createElement("h5", { className: "mb-4" }, "Substrate Types Metadata"),
                            successMessage && (React.createElement(Alert, { variant: "success", onClose: function () { return setSuccessMessage(null); }, dismissible: true }, successMessage)),
                            error && (React.createElement(Alert, { variant: "danger", onClose: function () { return setError(null); }, dismissible: true }, error)),
                            subProductId !== 0 && (React.createElement(Form.Group, { className: "mb-4" },
                                React.createElement(Form.Check, { type: "radio", label: "Use Default Metadata", checked: !useCustom, onChange: function () { return setUseCustom(false); } }),
                                React.createElement(Form.Check, { type: "radio", label: "Customize Metadata", checked: useCustom, onChange: function () { return setUseCustom(true); } }),
                                React.createElement("hr", null),
                                useCustom && (React.createElement(Button, { className: "mt-1 btn-sm", variant: "secondary", onClick: handleCopyFromDefault }, "Copy Default Values")))),
                            React.createElement(Row, null,
                                React.createElement(Col, { md: 12 },
                                    React.createElement("h5", { className: "mt-4" }, "Substrates"),
                                    React.createElement(Table, { bordered: true, hover: true, variant: "light", style: { width: '100%' } },
                                        React.createElement("thead", null,
                                            React.createElement("tr", null,
                                                React.createElement("th", null, "Type"),
                                                React.createElement("th", null, "Color"),
                                                React.createElement("th", null, "Weights"),
                                                React.createElement("th", null, "Unit Type"),
                                                React.createElement("th", null, "Lamination"),
                                                React.createElement("th", null, "Default Weight"),
                                                React.createElement("th", null))),
                                        React.createElement("tbody", null, values.substrates.map(function (option, index) { return (React.createElement(DraggableRow, { key: index, index: index, type: "substrates", option: option, setFieldValue: setFieldValue, values: values })); }))),
                                    React.createElement("div", { className: "text-end" },
                                        React.createElement(Button, { variant: "secondary", onClick: function () { return handleAddOption('substrates'); }, disabled: !isEditable || (!useCustom && subProductId !== 0) }, "Add Substrate"))),
                                coverSubstratesAvailable && (React.createElement(Col, { md: 12 },
                                    React.createElement("h5", { className: "mt-4" }, "Cover Substrates"),
                                    React.createElement(Table, { bordered: true, hover: true, variant: "light", style: { width: '100%' } },
                                        React.createElement("thead", null,
                                            React.createElement("tr", null,
                                                React.createElement("th", null, "Type"),
                                                React.createElement("th", null, "Color"),
                                                React.createElement("th", null, "Weights"),
                                                React.createElement("th", null, "Unit Type"),
                                                React.createElement("th", null, "Lamination"),
                                                React.createElement("th", null, "Default Weight"),
                                                React.createElement("th", null))),
                                        React.createElement("tbody", null, values.coverSubstrates.map(function (option, index) { return (React.createElement(DraggableRow, { key: index, index: index, type: "coverSubstrates", option: option, setFieldValue: setFieldValue, values: values })); }))),
                                    React.createElement("div", { className: "text-end" },
                                        React.createElement(Button, { variant: "secondary", onClick: function () { return handleAddOption('coverSubstrates'); }, disabled: !isEditable || (!useCustom && subProductId !== 0) }, "Add Cover Substrate")))),
                                envelopeSubstratesAvailable && (React.createElement(Col, { md: 12 },
                                    React.createElement("h5", { className: "mt-4" }, "Envelope Substrates"),
                                    React.createElement(Table, { bordered: true, hover: true, variant: "light", style: { width: '100%' } },
                                        React.createElement("thead", null,
                                            React.createElement("tr", null,
                                                React.createElement("th", null, "Type"),
                                                React.createElement("th", null, "Color"),
                                                React.createElement("th", null, "Weights"),
                                                React.createElement("th", null, "Unit Type"),
                                                React.createElement("th", null, "Lamination"),
                                                React.createElement("th", null, "Default Weight"),
                                                React.createElement("th", null))),
                                        React.createElement("tbody", null, values.envelopeSubstrates.map(function (option, index) { return (React.createElement(DraggableRow, { key: index, index: index, type: "envelopeSubstrates", option: option, setFieldValue: setFieldValue, values: values })); }))),
                                    React.createElement("div", { className: "text-end" },
                                        React.createElement(Button, { variant: "secondary", onClick: function () { return handleAddOption('envelopeSubstrates'); }, disabled: !isEditable || (!useCustom && subProductId !== 0) }, "Add Envelope Substrate"))))),
                            React.createElement(Button, { type: "submit", disabled: isSubmitting, variant: "primary", className: "mt-4 px-4 py-2" }, "Save Changes"))),
                    React.createElement(Modal, { show: showModal, onHide: function () { return setShowModal(false); } },
                        React.createElement(Modal.Header, { closeButton: true },
                            React.createElement(Modal.Title, null, editIndex !== null ? 'Edit Substrate Type' : 'Add New Substrate Type')),
                        React.createElement(Modal.Body, null,
                            React.createElement(Form, null,
                                error && (React.createElement(Alert, { variant: "danger", className: "mt-3" }, error)),
                                React.createElement(Form.Group, null,
                                    React.createElement(Form.Label, null, "Select Substrate Type"),
                                    React.createElement(Form.Control, { as: "select", value: newSubstrate, disabled: editIndex !== null, onChange: function (e) {
                                            setNewSubstrate(e.target.value);
                                            handleColorChange(selectedColor, e.target.value);
                                        } }, editIndex !== null
                                        ? React.createElement("option", { value: newSubstrate }, ((_b = substrateTypes.find(function (st) { return st.substrateType === newSubstrate; })) === null || _b === void 0 ? void 0 : _b.label) || newSubstrate)
                                        : substrateTypes.map(function (substrate) {
                                            var relevantOptions = modalOptionType === 'substrates'
                                                ? values.substrates
                                                : modalOptionType === 'coverSubstrates'
                                                    ? values.coverSubstrates
                                                    : values.envelopeSubstrates;
                                            var isDisabled = relevantOptions.some(function (option) {
                                                return option.substrateType === substrate.substrateType;
                                            });
                                            return (React.createElement("option", { key: substrate.id, value: substrate.substrateType, disabled: isDisabled }, substrate.label));
                                        }))),
                                React.createElement(Form.Group, { className: "mt-3" },
                                    React.createElement(Form.Label, null, "Select Color"),
                                    React.createElement(Form.Control, { as: "select", value: selectedColor, disabled: editIndex !== null, onChange: function (e) { return handleColorChange(e.target.value, newSubstrate); } }, editIndex !== null
                                        ? React.createElement("option", { value: selectedColor }, ((_d = (_c = substrateTypes.find(function (st) { return st.substrateType === newSubstrate; })) === null || _c === void 0 ? void 0 : _c.substrateColours.find(function (c) { return c.substrateColour === selectedColor; })) === null || _d === void 0 ? void 0 : _d.name) || selectedColor)
                                        : (_e = substrateTypes.find(function (st) { return st.substrateType === newSubstrate; })) === null || _e === void 0 ? void 0 : _e.substrateColours.map(function (color) {
                                            var relevantOptions = modalOptionType === 'substrates'
                                                ? values.substrates
                                                : modalOptionType === 'coverSubstrates'
                                                    ? values.coverSubstrates
                                                    : values.envelopeSubstrates;
                                            var isDisabled = relevantOptions.some(function (option) {
                                                return option.substrateType === newSubstrate &&
                                                    option.substrateColours.some(function (c) { return c.substrateColour === color.substrateColour; });
                                            });
                                            return (React.createElement("option", { key: color.substrateColour, value: color.substrateColour, disabled: isDisabled }, color.name));
                                        }))),
                                React.createElement(Table, { bordered: true, hover: true, variant: "light", className: "mt-3" },
                                    React.createElement("thead", null,
                                        React.createElement("tr", null,
                                            React.createElement("th", null, "Weight"),
                                            React.createElement("th", null, "Unit"),
                                            React.createElement("th", null, "Default"),
                                            React.createElement("th", null, "Select"))),
                                    React.createElement("tbody", null, weights.map(function (weight, idx) {
                                        var isChecked = selectedWeights.some(function (w) { return w.weight === weight.weight && w.weightUnit === weight.weightUnit; });
                                        var isDefault = selectedWeights.some(function (w) { return w.weight === weight.weight && w.weightUnit === weight.weightUnit && w.santaDefault; });
                                        return (React.createElement("tr", { key: "".concat(weight.weight, "-").concat(idx) },
                                            React.createElement("td", null, weight.weight),
                                            React.createElement("td", null, weight.weightUnit),
                                            React.createElement("td", null,
                                                React.createElement(Form.Check, { type: "radio", name: "santaDefault", checked: isDefault, onChange: function () {
                                                        setSelectedWeights(function (prev) {
                                                            return prev.map(function (w) {
                                                                return w.weight === weight.weight && w.weightUnit === weight.weightUnit
                                                                    ? __assign(__assign({}, w), { santaDefault: true }) : __assign(__assign({}, w), { santaDefault: false });
                                                            });
                                                        });
                                                    } })),
                                            React.createElement("td", null,
                                                React.createElement(Form.Check, { type: "checkbox", checked: isChecked, onChange: function (e) {
                                                        if (e.target.checked) {
                                                            setSelectedWeights(function (prev) {
                                                                if (!prev.some(function (w) { return w.weight === weight.weight && w.weightUnit === weight.weightUnit; })) {
                                                                    return __spreadArray(__spreadArray([], prev, true), [__assign(__assign({}, weight), { santaDefault: false })], false);
                                                                }
                                                                return prev;
                                                            });
                                                        }
                                                        else {
                                                            setSelectedWeights(function (prev) {
                                                                return prev.filter(function (w) { return !(w.weight === weight.weight && w.weightUnit === weight.weightUnit); });
                                                            });
                                                        }
                                                    } }))));
                                    }))),
                                React.createElement(Form.Group, { className: "mt-3" },
                                    React.createElement(Form.Check, { type: "checkbox", label: "Allow Lamination", checked: allowLamination, onChange: function (e) { return setAllowLamination(e.target.checked); } })))),
                        React.createElement(Modal.Footer, null,
                            React.createElement(Button, { variant: "secondary", onClick: function () { return setShowModal(false); } }, "Close"),
                            React.createElement(Button, { variant: "primary", onClick: function () { return handleModalSave(setFieldValue, values); } }, editIndex !== null ? 'Save Changes' : 'Add Option'))),
                    React.createElement(ConfirmationModal, { show: showConfirmationModal, onHide: function () { return setShowConfirmationModal(false); }, onConfirm: function () {
                            setShowConfirmationModal(false);
                            saveSubstrateTypeMetadata(values, setSubmitting);
                        } })));
            }))));
};
export default SubstrateTypesMetaDataEditor;
