export var Capability;
(function (Capability) {
    Capability["PRINTING"] = "PRINTING";
    Capability["LAMINATION"] = "LAMINATION";
    Capability["BINDING"] = "BINDING";
    Capability["FOLDING"] = "FOLDING";
})(Capability || (Capability = {}));
/*
 * There's a similar enum in mixam-types, but it uses strings
 * (where the "real" Java type serializes to numbers).
 */
export var UnitFormat;
(function (UnitFormat) {
    UnitFormat[UnitFormat["METRIC"] = 0] = "METRIC";
    UnitFormat[UnitFormat["IMPERIAL"] = 1] = "IMPERIAL";
})(UnitFormat || (UnitFormat = {}));
