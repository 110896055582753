import mixam from '../../boot/mixam';

export default WithStompClient;

function WithStompClient() {

    this.subscribe = function (onMessage) {
        this.trigger("log", {message: [`Subscribe to stomp channel ${this.topic}`], title: "RecruitTester.subscribe"});
        this.stomp = Stomp.client(mixam.stompServiceUrl);

        this.stomp.debug = (...args) => {
            if (args.join('').indexOf('Whoops! Lost connection to') !== -1) {
                setTimeout(() => this.subscribe(onMessage), 10);
            }
            this.trigger("log", {message: args, title: "RecruitTester.subscribe"});
        };

        this.stomp.connect(mixam.stompWebUser, mixam.stompWebPass, () => {
            this.stomp.subscribe(this.topic, d => {
                const p = JSON.parse(d.body);
                if (onMessage) {
                    onMessage(p);
                }
            });

        },  (err) => this.trigger("log", err), '/');

    };
}