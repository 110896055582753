export default [
    {
        id: 100,
        name: 'Damages',
        items: [
            {id: 101, name: 'Box damaged / split'},
            {id: 102, name: 'Product Damaged'},
            {id: 103, name: 'Damaged corners'},
            {id: 104, name: 'Poster packing damage'},
            {id: 105, name: 'Water damage'},
        ]
    },
    {
        id: 200,
        name: 'Print Quality',
        items: [
            {id: 201, name: 'Colour variance'},
            {id: 202, name: 'Colours weak and faded'},
            {id: 203, name: 'Print quality generally poor'},
            {id: 204, name: 'Misregistration'},
            {id: 205, name: 'Banding'},
            {id: 206, name: 'Colour quality and dot gain'},
            {id: 207, name: 'Colours dark'},
            {id: 208, name: '1 colour too strong (e.g heavy magenta)'},
            {id: 209, name: 'print quality varies'},
            {id: 210, name: 'Set off'},
        ]
    },
    {
        id: 300,
        name: 'Orientation',
        items: [
            {id: 301, name: 'Artwork wrong way round'},
            {id: 302, name: 'Back up proof misleading'},
            {id: 303, name: 'Reverse side print is upside down'},
            {id: 304, name: 'Wrong orientation/pages upside down'},
        ]
    },
    {
        id: 400,
        name: 'Finishing Quality',
        items: [
            {id: 401, name: 'Creased pages'},
            {id: 402, name: 'Finishing quality generally poor'},
            {id: 403, name: 'Poor binding '},
            {id: 404, name: 'Poor PUR - excess glue'},
            {id: 405, name: 'Stitched off centre'},
            {id: 406, name: 'Trim/fold misaligned'},
            {id: 407, name: 'Trimmed beyond bleed'},
            {id: 408, name: 'Trimmed off centre'},
            {id: 409, name: 'Trimmed too small'},
            {id: 410, name: 'Binding poor'},
            {id: 411, name: 'Not trimmed to size'},
            {id: 412, name: 'Pages wrong order'},
            {id: 413, name: 'Laminate peeling'},
            {id: 414, name: 'Lamination missing'},
        ]
    },
    {
        id: 500,
        name: 'Shipping',
        items: [
            {id: 501, name: 'Customer out /DPD failed'},
            {id: 502, name: 'Delivered to the wrong address - Address not updated'},
            {id: 503, name: 'Delivered but not received'},
            {id: 504, name: 'Delivery upgrade missed'},
            {id: 505, name: 'Delayed & Estimated date missed'},
            {id: 506, name: 'Split address missed'},
            {id: 507, name: 'Wrong order received'},
            {id: 508, name: 'Tracking not updated by courier'},
            {id: 509, name: 'Lost in transit'},
        ]
    },
    {
        id: 600,
        name: 'Other',
        items: [
            {id: 601, name: 'Duplicated pages'},
            {id: 602, name: 'Declined laminate & covers scuffed'},
            {id: 603, name: 'Expectation not realistic'},
            {id: 604, name: 'Not shrink wrapped'},
            {id: 605, name: 'Pages/Covers missing'},
            {id: 606, name: 'Production days incorrect'},
            {id: 607, name: 'Production delays'},
            {id: 608, name: 'Shortage - delivered under quantity ordered'},
            {id: 609, name: 'Wrong pagination'},
            {id: 610, name: 'Wrong quantity split'},
            {id: 611, name: 'Wrong stock'},
            {id: 612, name: 'End paper colour error'},
            {id: 613, name: 'Delivered Flat not folded'},
            {id: 614, name: 'Paper quality varies'},
            {id: 615, name: 'Ttest vs main order variances'},
            {id: 616, name: 'Wrong cover finish'},
        ]
    },
    {
        id: 700,
        name: 'Marking',
        items: [
            {id: 701, name: 'Ink Marks'},
            {id: 702, name: 'Scuffing/scratches'},
            {id: 703, name: 'Roller/other marks'},
            {id: 704, name: 'Ink spots and streaks'},
        ]
    },
    {
        id: 800,
        name: 'File',
        items: [
            {id: 801, name: 'Mirror Bleed not checked/white lines'},
            {id: 802, name: 'Spine set up incorrectly'},
            {id: 803, name: 'Text too close to trim edge'},
            {id: 804, name: 'Font/glyph error'},
            {id: 805, name: 'Single colour black printed not Rich Black'},
            {id: 806, name: 'Spine file too big'},
        ]
    },
];
