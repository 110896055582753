import defineComponent from '../../../components/flight/lib/component.js';
import axios from "axios";
import mixam from "../../boot/mixam";

function NewShoppingCartIndicator() {

    /**
     * TODO:
     * remove after mini cart is fully integrated and no other
     * functionality is tied to calling updateIndicator method.
     */
    this.attributes({
        indicatorSelector: '[data-type="shopping-cart-indicator"]',
        itemCountSelector: '[data-type="cart-item-count"]'
    });

    this.updateIndicator = function (numberItems) {
        if (this.numberItems === numberItems) {
            return;
        }
        this.numberItems = numberItems;
        this.select('itemCountSelector').text(numberItems);
        if (numberItems > 0) {
            this.select('indicatorSelector').removeClass('hidden');
            this.select('itemCountSelector').removeClass('hidden');
        } else {
            this.select('indicatorSelector').addClass('hidden');
            this.select('itemCountSelector').addClass('hidden');
        }
    };

    this.subscribe = function (orderId) {
        if(this.stompReady) {
            if (this.subscription && this.subscription.orderId !== orderId) {
                this.stomp.unsubscribe(this.subscription.id);
                this.subscription = undefined;
            }
            if (!this.subscription) {
                this.subscription = {
                    id: this.stomp.subscribe(`/topic/${orderId}`, d => this.stompHandler(d)),
                    orderId: orderId
                };
            }
        } else {
            this.subscription = undefined;
        }
    };

    this.stompHandler = function (d) {
        const message = JSON.parse(d.body);
        if (message.status === "success") {
            switch (message.reason) {
                case 'change/delivery':
                    window.dispatchEvent(new CustomEvent('stompDeliveryReceived', {
                        composed: true,
                        bubbles: true,
                    }));
                    break;
            }
        }
    };

    this.connectStomp = function () {
        this.stomp = Stomp.client(mixam.stompServiceUrl);
        this.stomp.debug = (...args) => {
            if (args.join('').indexOf('Whoops! Lost connection to') !== -1) {
                this.stompReady = false;
                setTimeout(() => this.connectStomp(), 10);
            }
        };
        this.stomp.connect(mixam.stompWebUser, mixam.stompWebPass,
            () => this.stompReady = true,
            (err) => this.trigger("log", err), '/');
    };

    this.addReorderItemToCart = function (e, addReorderItemToCartRequest) {
        fetch("/api/cart/items/reorder", {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify(addReorderItemToCartRequest)
        }).then(response =>
            response.json()
        ).then(cartResponse => {
                this.updateIndicator(cartResponse.itemCount);
                that.trigger("dataStoreCartRefresh", {reason: "reorderItemAdded", cartResponse: cartResponse});
            }
        ).catch(function(err) {
            that.trigger('showFixedMessage', { type: 'error', body: err, interval: 10000 });
        });
    };

    this.after('initialize', function () {
        axios.get('/api/cart').then(({ data: cartResponse }) => {
            this.updateIndicator(cartResponse.itemCount);
        });
        window.addEventListener('reactCartUpdated', ({detail}) => {
            this.updateIndicator(detail.cartResponse.itemCount);
            this.subscribe(detail.cartResponse.orderId);
        });
        window.addEventListener('priceCalculatorInstantDestinationRequest', ({detail}) => {
            this.subscribe(detail.orderId);
        });
        this.on(document, "uiAddReorderItemRequested", this.addReorderItemToCart);

        this.connectStomp();

    });
}

export default defineComponent(NewShoppingCartIndicator);