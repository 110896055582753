import defineComponent from '../../../../components/flight/lib/component';

export default defineComponent(AdminFormValidator);


// Bootstrap 5 Form Validation: This Will Probably Be Used In The Frontend When We Migrate It Over To Thymeleaf
function AdminFormValidator() {

    this.validateForm = function(event) {
        const form = this.node;
        if (!form.checkValidity()) {
            // TODO: We Need To Scroll First Invalid Form Control
            event.preventDefault();
            event.stopPropagation();
        }
        $(form).addClass('was-validated');
    };

    this.after('initialize', function () {
        this.on('submit', this.validateForm);
    });
}