var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useEffect, useState, useRef } from 'react';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Formik, Form as FormikForm } from 'formik';
import * as Yup from 'yup';
import { Spinner, Alert, Button, Table, Modal, Form, Card } from 'react-bootstrap';
import axios from 'axios';
import { Colours } from '@mixam-platform/mixam-types';
import { ColourLabelType } from '../types';
import ConfirmationModal from "./ConfirmationModal";
var ItemTypes = {
    ROW: 'row',
};
var validationSchema = Yup.object().shape({
    coloursOptions: Yup.array()
        .of(Yup.object().shape({
        labelType: Yup.string().required(),
        santaDefault: Yup.boolean(),
        sameAsFront: Yup.boolean(),
        colours: Yup.mixed().nullable(),
    }))
        .test('unique-colours', 'Each colour must be unique.', function (options) {
        var colours = options.map(function (opt) { return opt.colours; });
        return colours.length === new Set(colours).size;
    }),
    // Similar validation for other sections...
});
var ColoursMetaDataEditor = function (_a) {
    var productId = _a.productId, subProductId = _a.subProductId, santaType = _a.santaType, coloursOptionsAvailable = _a.coloursOptionsAvailable, backColoursOptionsAvailable = _a.backColoursOptionsAvailable, outerCoverColoursOptionsAvailable = _a.outerCoverColoursOptionsAvailable, innerCoverColoursOptionsAvailable = _a.innerCoverColoursOptionsAvailable, jacketColoursOptionsAvailable = _a.jacketColoursOptionsAvailable;
    var _b = useState(false), isLoading = _b[0], setLoading = _b[1];
    var _c = useState(null), error = _c[0], setError = _c[1];
    var _d = useState(null), successMessage = _d[0], setSuccessMessage = _d[1];
    var _e = useState(null), metadataDocumentWrapper = _e[0], setMetadataDocumentWrapper = _e[1];
    var _f = useState(false), useCustom = _f[0], setUseCustom = _f[1];
    var _g = useState(false), showModal = _g[0], setShowModal = _g[1];
    var _h = useState(false), showConfirmationModal = _h[0], setShowConfirmationModal = _h[1];
    var _j = useState(null), editIndex = _j[0], setEditIndex = _j[1];
    var _k = useState(''), sectionKey = _k[0], setSectionKey = _k[1];
    var _l = useState({
        labelType: ColourLabelType.NONE,
        santaDefault: false,
        sameAsFront: false,
        colours: Colours.NONE,
    }), newOption = _l[0], setNewOption = _l[1];
    useEffect(function () {
        setLoading(true);
        axios
            .get("/api/admin/metadata/product/colours/products/".concat(productId, "/sub-products/").concat(subProductId, "/santa-types/").concat(santaType))
            .then(function (response) {
            setMetadataDocumentWrapper(response.data);
            if (subProductId !== 0) {
                setUseCustom(response.data.productMetadataDocument !== null);
            }
            setLoading(false);
        })
            .catch(function () {
            setLoading(false);
            setError('Failed to load colours metadata document.');
        });
    }, [productId, subProductId, santaType]);
    var getAvailableLabelTypes = function (section) {
        if (section === 'innerCoverColoursOptions') {
            return [
                ColourLabelType.NONE,
                ColourLabelType.GREYSCALE,
                ColourLabelType.FULL_COLOUR,
                ColourLabelType.INNER_COVER_PRINTING_YES,
                ColourLabelType.INNER_COVER_PRINTING_NO,
            ];
        }
        return [ColourLabelType.NONE, ColourLabelType.GREYSCALE, ColourLabelType.FULL_COLOUR];
    };
    var getColourOptions = function (section, values) {
        var existingColours = (values[section] || []).map(function (option) { return option.colours; });
        return [
            { value: Colours.NONE, label: 'NONE', disabled: existingColours.includes(Colours.NONE) },
            { value: Colours.PROCESS, label: 'PROCESS', disabled: existingColours.includes(Colours.PROCESS) },
            { value: Colours.GRAYSCALE, label: 'GRAYSCALE', disabled: existingColours.includes(Colours.GRAYSCALE) },
            { value: Colours.SAME_AS_FRONT, label: 'SAME AS FRONT', disabled: existingColours.includes(Colours.SAME_AS_FRONT) },
            { value: null, label: 'NOT SPECIFIED', disabled: existingColours.includes(null) },
        ];
    };
    var handleSave = function (values, _a) {
        var setSubmitting = _a.setSubmitting;
        if (!useCustom && subProductId !== 0) {
            setShowConfirmationModal(true);
            setSubmitting(false);
            return;
        }
        saveColoursMetadata(values, setSubmitting);
    };
    var saveColoursMetadata = function (values, setSubmitting) {
        var _a;
        setLoading(true);
        setError(null);
        setSuccessMessage(null);
        var activeDocument = useCustom
            ? metadataDocumentWrapper === null || metadataDocumentWrapper === void 0 ? void 0 : metadataDocumentWrapper.productMetadataDocument
            : metadataDocumentWrapper === null || metadataDocumentWrapper === void 0 ? void 0 : metadataDocumentWrapper.defaultProductMetadataDocument;
        var newDocument = activeDocument
            ? __assign({}, activeDocument) : {
            shopId: (_a = metadataDocumentWrapper === null || metadataDocumentWrapper === void 0 ? void 0 : metadataDocumentWrapper.defaultProductMetadataDocument) === null || _a === void 0 ? void 0 : _a.shopId,
            productId: productId,
            subProductId: subProductId,
            santaType: santaType,
        };
        var saveRequest = {
            useDefault: !useCustom,
            coloursMetadataDocument: __assign(__assign({}, newDocument), { coloursOptions: values.coloursOptions, backColoursOptions: values.backColoursOptions, outerCoverColoursOptions: values.outerCoverColoursOptions, innerCoverColoursOptions: values.innerCoverColoursOptions, jacketColoursOptions: values.jacketColoursOptions }),
        };
        axios
            .post("/api/admin/metadata/product/colours/products/".concat(productId, "/sub-products/").concat(subProductId, "/santa-types/").concat(santaType), saveRequest)
            .then(function (response) {
            setMetadataDocumentWrapper(response.data);
            setLoading(false);
            setSubmitting(false);
            setSuccessMessage('Colours Metadata saved successfully!');
        })
            .catch(function (error) {
            var _a;
            setLoading(false);
            setSubmitting(false);
            setError("Failed to save colours metadata: ".concat(((_a = error.response) === null || _a === void 0 ? void 0 : _a.data) || error.message));
        });
    };
    var handleCopyFromDefault = function () {
        var _a;
        if (metadataDocumentWrapper === null || metadataDocumentWrapper === void 0 ? void 0 : metadataDocumentWrapper.defaultProductMetadataDocument) {
            var defaultData = metadataDocumentWrapper.defaultProductMetadataDocument;
            var updatedCustomDocument = __assign(__assign({}, metadataDocumentWrapper.productMetadataDocument || {
                shopId: (_a = metadataDocumentWrapper === null || metadataDocumentWrapper === void 0 ? void 0 : metadataDocumentWrapper.defaultProductMetadataDocument) === null || _a === void 0 ? void 0 : _a.shopId,
                productId: productId,
                subProductId: subProductId,
                santaType: santaType,
            }), { coloursOptions: defaultData.coloursOptions || [], backColoursOptions: defaultData.backColoursOptions || [], outerCoverColoursOptions: defaultData.outerCoverColoursOptions || [], innerCoverColoursOptions: defaultData.innerCoverColoursOptions || [], jacketColoursOptions: defaultData.jacketColoursOptions || [] });
            setMetadataDocumentWrapper(__assign(__assign({}, metadataDocumentWrapper), { productMetadataDocument: updatedCustomDocument }));
        }
    };
    var moveRow = function (section, dragIndex, hoverIndex, setFieldValue, values) {
        var updatedOptions = __spreadArray([], values[section], true);
        var removed = updatedOptions.splice(dragIndex, 1)[0];
        updatedOptions.splice(hoverIndex, 0, removed);
        setFieldValue(section, updatedOptions);
    };
    var DraggableRow = function (_a) {
        var index = _a.index, section = _a.section, option = _a.option, setFieldValue = _a.setFieldValue, values = _a.values;
        var ref = useRef(null);
        var _b = useDrop({
            accept: ItemTypes.ROW,
            hover: function (item) {
                if (!ref.current)
                    return;
                var dragIndex = item.index;
                var hoverIndex = index;
                if (item.section !== section || dragIndex === hoverIndex)
                    return;
                moveRow(section, dragIndex, hoverIndex, setFieldValue, values);
                item.index = hoverIndex;
            },
        }), drop = _b[1];
        var _c = useDrag({
            type: ItemTypes.ROW,
            item: { section: section, index: index },
            collect: function (monitor) { return ({
                isDragging: monitor.isDragging(),
            }); },
        }), isDragging = _c[0].isDragging, drag = _c[1];
        drag(drop(ref));
        var isSameAsFrontDisabled = section !== 'backColoursOptions' && section !== 'innerCoverColoursOptions';
        return (React.createElement("tr", { ref: ref, style: { opacity: isDragging ? 0.5 : 1 } },
            React.createElement("td", null, option.colours === null ? 'NOT SPECIFIED' : option.colours),
            React.createElement("td", null, option.labelType),
            React.createElement("td", null,
                React.createElement(Form.Check, { type: "radio", name: "".concat(section, ".santaDefault"), checked: option.santaDefault, onChange: function () {
                        var updatedOptions = values[section].map(function (opt, i) {
                            return i === index
                                ? __assign(__assign({}, opt), { santaDefault: true }) : __assign(__assign({}, opt), { santaDefault: false });
                        });
                        setFieldValue(section, updatedOptions);
                    } })),
            React.createElement("td", null,
                React.createElement(Form.Check, { type: "checkbox", checked: option.sameAsFront, disabled: isSameAsFrontDisabled, onChange: function () {
                        if (!isSameAsFrontDisabled) {
                            var updatedOptions = __spreadArray([], values[section], true);
                            updatedOptions[index] = __assign(__assign({}, option), { sameAsFront: !option.sameAsFront });
                            setFieldValue(section, updatedOptions);
                        }
                    } })),
            React.createElement("td", { className: "text-end" },
                React.createElement(Button, { className: "btn-sm me-2", variant: "primary", onClick: function () {
                        setEditIndex(index);
                        setNewOption(option);
                        setSectionKey(section);
                        setShowModal(true);
                    }, disabled: !isEditable }, "Edit"),
                React.createElement(Button, { className: "btn-sm", variant: "danger", onClick: function () {
                        var updatedOptions = __spreadArray([], values[section], true);
                        updatedOptions.splice(index, 1);
                        setFieldValue(section, updatedOptions);
                    }, disabled: !isEditable }, "Delete"))));
    };
    var renderTable = function (sectionKey, sectionLabel, values, setFieldValue) { return (React.createElement("div", { key: sectionKey, className: "mb-4" },
        React.createElement("h5", null, sectionLabel),
        React.createElement(Table, { bordered: true, hover: true, variant: "light" },
            React.createElement("thead", null,
                React.createElement("tr", null,
                    React.createElement("th", null, "Colours"),
                    React.createElement("th", null, "Label"),
                    React.createElement("th", null, "Default"),
                    React.createElement("th", null, "Same As Front"),
                    React.createElement("th", null, "Actions"))),
            React.createElement("tbody", null, values[sectionKey].length > 0 ? (values[sectionKey].map(function (option, index) { return (React.createElement(DraggableRow, { key: index, index: index, section: sectionKey, option: option, setFieldValue: setFieldValue, values: values })); })) : (React.createElement("tr", null,
                React.createElement("td", { colSpan: 5, className: "text-center" }, "No options available."))))),
        React.createElement("div", { className: "text-end" },
            React.createElement(Button, { variant: "secondary", onClick: function () {
                    var _a;
                    setEditIndex(null);
                    var defaultColour = ((_a = getColourOptions(sectionKey, values).find(function (option) { return !option.disabled; })) === null || _a === void 0 ? void 0 : _a.value) || Colours.NONE;
                    setNewOption({
                        labelType: ColourLabelType.NONE,
                        santaDefault: false,
                        sameAsFront: false,
                        colours: defaultColour,
                    });
                    setSectionKey(sectionKey);
                    setShowModal(true);
                }, disabled: !isEditable }, "Add Option")))); };
    if (isLoading) {
        return React.createElement(Spinner, { animation: "grow", variant: "primary" });
    }
    if (!metadataDocumentWrapper) {
        return React.createElement(Alert, { variant: "danger" }, "No Colours Metadata available");
    }
    var activeDocument = useCustom
        ? metadataDocumentWrapper === null || metadataDocumentWrapper === void 0 ? void 0 : metadataDocumentWrapper.productMetadataDocument
        : metadataDocumentWrapper === null || metadataDocumentWrapper === void 0 ? void 0 : metadataDocumentWrapper.defaultProductMetadataDocument;
    var isEditable = useCustom || subProductId === 0;
    return (React.createElement(DndProvider, { backend: HTML5Backend },
        React.createElement(Formik, { initialValues: {
                coloursOptions: (activeDocument === null || activeDocument === void 0 ? void 0 : activeDocument.coloursOptions) || [],
                backColoursOptions: (activeDocument === null || activeDocument === void 0 ? void 0 : activeDocument.backColoursOptions) || [],
                outerCoverColoursOptions: (activeDocument === null || activeDocument === void 0 ? void 0 : activeDocument.outerCoverColoursOptions) || [],
                innerCoverColoursOptions: (activeDocument === null || activeDocument === void 0 ? void 0 : activeDocument.innerCoverColoursOptions) || [],
                jacketColoursOptions: (activeDocument === null || activeDocument === void 0 ? void 0 : activeDocument.jacketColoursOptions) || [],
            }, enableReinitialize: true, onSubmit: handleSave, validationSchema: validationSchema }, function (_a) {
            var isSubmitting = _a.isSubmitting, values = _a.values, setFieldValue = _a.setFieldValue, setSubmitting = _a.setSubmitting;
            return (React.createElement(React.Fragment, null,
                React.createElement(Card, { className: "p-4 shadow-sm bg-light" },
                    React.createElement(FormikForm, null,
                        React.createElement("h5", { className: "mb-4" }, "Colours Metadata"),
                        successMessage && (React.createElement(Alert, { variant: "success", onClose: function () { return setSuccessMessage(null); }, dismissible: true }, successMessage)),
                        error && (React.createElement(Alert, { variant: "danger", onClose: function () { return setError(null); }, dismissible: true }, error)),
                        subProductId !== 0 && (React.createElement(Form.Group, { className: "mb-4" },
                            React.createElement(Form.Check, { type: "radio", label: "Use Default Metadata", checked: !useCustom, onChange: function () { return setUseCustom(false); } }),
                            React.createElement(Form.Check, { type: "radio", label: "Customize Metadata", checked: useCustom, onChange: function () { return setUseCustom(true); } }),
                            React.createElement("hr", null),
                            useCustom && (React.createElement(Button, { className: "mt-1 btn-sm", variant: "secondary", onClick: handleCopyFromDefault }, "Copy Default Values")))),
                        coloursOptionsAvailable &&
                            renderTable('coloursOptions', 'Colours Options', values, setFieldValue),
                        backColoursOptionsAvailable &&
                            renderTable('backColoursOptions', 'Back Colours Options', values, setFieldValue),
                        outerCoverColoursOptionsAvailable &&
                            renderTable('outerCoverColoursOptions', 'Outer Cover Colours Options', values, setFieldValue),
                        innerCoverColoursOptionsAvailable &&
                            renderTable('innerCoverColoursOptions', 'Inner Cover Colours Options', values, setFieldValue),
                        jacketColoursOptionsAvailable &&
                            renderTable('jacketColoursOptions', 'Dust Jacket Colours Options', values, setFieldValue),
                        React.createElement(Button, { type: "submit", disabled: isSubmitting, variant: "primary", className: "mt-4" }, "Save Changes"))),
                React.createElement(ConfirmationModal, { show: showConfirmationModal, onHide: function () { return setShowConfirmationModal(false); }, onConfirm: function () {
                        setShowConfirmationModal(false);
                        saveColoursMetadata(values, setSubmitting);
                    } }),
                React.createElement(Modal, { show: showModal, onHide: function () { return setShowModal(false); } },
                    React.createElement(Modal.Header, { closeButton: true },
                        React.createElement(Modal.Title, null, editIndex !== null ? 'Edit Option' : 'Add New Option')),
                    React.createElement(Modal.Body, null,
                        React.createElement(Form, null,
                            React.createElement(Form.Group, { className: "mb-3" },
                                React.createElement(Form.Label, null, "Colours"),
                                React.createElement(Form.Control, { as: "select", value: newOption.colours === null ? '' : newOption.colours, onChange: function (e) {
                                        return setNewOption(function (prev) { return (__assign(__assign({}, prev), { colours: e.target.value === '' ? null : e.target.value })); });
                                    } }, getColourOptions(sectionKey, values).map(function (option) {
                                    var _a, _b;
                                    return (React.createElement("option", { key: (_a = option.value) !== null && _a !== void 0 ? _a : 'null', value: (_b = option.value) !== null && _b !== void 0 ? _b : '', disabled: option.disabled }, option.label));
                                }))),
                            React.createElement(Form.Group, { className: "mb-3" },
                                React.createElement(Form.Label, null, "Label Type"),
                                React.createElement(Form.Control, { as: "select", value: newOption.labelType, onChange: function (e) {
                                        return setNewOption(function (prev) { return (__assign(__assign({}, prev), { labelType: e.target.value })); });
                                    } }, getAvailableLabelTypes(sectionKey).map(function (label) { return (React.createElement("option", { key: label, value: label }, label)); }))),
                            React.createElement(Form.Group, { className: "mb-3" },
                                React.createElement(Form.Check, { type: "checkbox", label: "Set as Default", checked: newOption.santaDefault, onChange: function (e) {
                                        return setNewOption(function (prev) { return (__assign(__assign({}, prev), { santaDefault: e.target.checked })); });
                                    } })),
                            React.createElement(Form.Group, { className: "mb-3" },
                                React.createElement(Form.Check, { type: "checkbox", label: "Same as Front", checked: newOption.sameAsFront, disabled: sectionKey !== 'backColoursOptions' && sectionKey !== 'innerCoverColoursOptions', onChange: function (e) {
                                        return setNewOption(function (prev) { return (__assign(__assign({}, prev), { sameAsFront: e.target.checked })); });
                                    } })))),
                    React.createElement(Modal.Footer, null,
                        React.createElement(Button, { variant: "secondary", onClick: function () { return setShowModal(false); } }, "Close"),
                        React.createElement(Button, { variant: "primary", onClick: function () {
                                var updatedOptions = __spreadArray([], values[sectionKey], true);
                                if (editIndex !== null) {
                                    updatedOptions[editIndex] = newOption;
                                }
                                else {
                                    updatedOptions.push(newOption);
                                }
                                setFieldValue(sectionKey, updatedOptions);
                                setShowModal(false);
                            } }, editIndex !== null ? 'Save Changes' : 'Add Option')))));
        })));
};
export default ColoursMetaDataEditor;
