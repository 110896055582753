export var serialize = function (obj) { return new URLSearchParams(obj).toString(); };
export var sleep = function (ms) {
    return new Promise(function (r) { return setTimeout(r, ms); });
};
export var convertDate = function (date) {
    try {
        return new Date(date).toISOString().split('T')[0];
    }
    catch (e) {
        return null;
    }
};
export var setupInitialValues = function (formInfo, apiData) {
    var initialValues = {};
    Object.keys(formInfo).forEach(function (key) {
        var value;
        if (typeof formInfo[key] === 'object') {
            // convert date
            value = convertDate(apiData[formInfo[key].data]);
        }
        else {
            value = apiData[formInfo[key]];
        }
        return (initialValues[key] = value);
    });
    return initialValues;
};
