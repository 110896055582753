import defineComponent from '../../../../components/flight/lib/component';
import React from 'react';
import ReactDom from 'react-dom/client';
import FeaturedProductManager from '../React/Homepage/FeaturedProductManager/FeaturedProductManager.tsx';

export default defineComponent(AdminHomepageFeaturedProductManager);

function AdminHomepageFeaturedProductManager() {

    this.initManager = function () {
        const manager = $(this.node);
        const root = ReactDom.createRoot(manager.get(0));
        root.render(
            <FeaturedProductManager homepageSettingsId={manager.data('homepage-id')}></FeaturedProductManager>
        );
    };

    this.after('initialize', function () {
        this.initManager();
    });

}
