var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useEffect, useState } from 'react';
import axios from "axios";
import Table from 'react-bootstrap/Table';
import Card from 'react-bootstrap/Card';
import { Locale } from "@mixam-platform/mixam-types";
import { FormCheck } from './components/styled';
var getPublications = function (memberId) { return __awaiter(void 0, void 0, void 0, function () {
    var response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, axios.get("/member/api/".concat(memberId, "/publications/"))];
            case 1:
                response = _a.sent();
                if (response.status === 200) {
                    return [2 /*return*/, response.data];
                }
                return [2 /*return*/, undefined];
        }
    });
}); };
var formatCurrency = function (amount, locale) {
    return new Intl.NumberFormat(locale, {
        style: 'currency',
        currency: getCurrencyCodeFromLocale(locale)
    }).format(amount);
};
var getCurrencyCodeFromLocale = function (locale) {
    switch (locale) {
        case Locale.EN_GB:
            return "GBP";
        case Locale.EN_US:
            return "USD";
        case Locale.EN_CA:
            return "CAD";
        case Locale.EN_AU:
            return "AUD";
        case Locale.DE_DE:
        case Locale.EN_DE:
            return "EUR";
        default:
            return undefined;
    }
};
export var Publications = function (props) {
    var _a = useState([]), publicationsData = _a[0], setPublicationsData = _a[1];
    useEffect(function () {
        getPublications(props.memberId)
            .then(function (data) {
            setPublicationsData(data);
        });
    }, []);
    return (React.createElement(Card, { className: "p-3 mt-3" }, publicationsData.length > 0 ? (React.createElement("div", { className: "table-responsive" },
        React.createElement(Table, { striped: true, variant: "light" },
            React.createElement("thead", null,
                React.createElement("tr", null,
                    React.createElement("th", null, "Publication"),
                    React.createElement("th", null),
                    React.createElement("th", null, "Mixam Cost"),
                    React.createElement("th", null, "Price"),
                    React.createElement("th", null, "Commission"),
                    React.createElement("th", null, "Active"),
                    React.createElement("th", null))),
            React.createElement("tbody", null, publicationsData.map(function (publication, index) {
                var _a, _b;
                return (React.createElement("tr", { key: index },
                    React.createElement("td", null,
                        React.createElement("img", { src: publication.thumbnailUrl, width: 100 })),
                    React.createElement("td", { style: { whiteSpace: 'pre-line' } },
                        React.createElement("h5", null, ((_a = publication.publicationConfig) === null || _a === void 0 ? void 0 : _a.title) ? publication.publicationConfig.title : ''),
                        React.createElement("div", null,
                            React.createElement("a", { href: "/orders/".concat(publication.orderId) }, publication.caseNumber)),
                        publication.description),
                    React.createElement("td", null, formatCurrency(publication.cost, props.locale)),
                    React.createElement("td", null, formatCurrency(publication.total, props.locale)),
                    React.createElement("td", null, formatCurrency(publication.commission, props.locale)),
                    React.createElement("td", null,
                        React.createElement(FormCheck, { disabled: true, type: "switch", checked: ((_b = publication.publicationConfig) === null || _b === void 0 ? void 0 : _b.active) || false })),
                    React.createElement("td", { align: "right" },
                        React.createElement("a", { className: 'btn btn-primary', href: "/orders/".concat(publication.orderId), target: "_blank" }, "Edit"))));
            }))))) : (React.createElement("div", null, "No Publications"))));
};
