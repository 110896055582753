import mixam from '../boot/mixam';
import defineComponent from '../../components/flight/lib/component';

export default defineComponent(OneTimeDialog);

function OneTimeDialog() {

    this.attributes({
        ontimeSelector: '[data-type="one-time-help"]',
        gotItSelector: '[data-action="got-it"]',
    });

    this.removeOneTimeDialog = function (event) {
        event.preventDefault();
        mixam.cookie.write(this.cookieName, "1", 60 * 60 * 24 * 999, '/');
        this.$node.remove();
    };

    this.after('initialize', function () {
        this.cookieName = this.$node.data('cookie');
        this.on('click', {
            gotItSelector: this.removeOneTimeDialog
        });
    });

}
