import defineComponent from '../../components/flight/lib/component';

export default defineComponent(SlideToTarget);

function SlideToTarget() {

    this.slideTo = function(event) {
        const $target = $(event.target).closest('a');
        const location = $target.data("location");
        if(location !== "off-page") {
            event.preventDefault();
            const targetId = $target.attr('href');

            if(location) {
                document.querySelector(location).shadowRoot.getElementById(targetId.slice(1)).scrollIntoView();
            } else {
                const $targetElement = $(targetId);
                $targetElement.scrollToView(() => $targetElement.hilightElement());
            }
        }
    };

    this.after('initialize', function() {
        this.on('click', this.slideTo);
    });
}
