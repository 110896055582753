import defineComponent from '../../../../components/flight/lib/component';
import React from 'react';
import ReactDom from 'react-dom/client';

import TooltipsList from '../React/Tooltips/TooltipsList.tsx';

export default defineComponent(AdminTooltipManager);

function AdminTooltipManager() {

    this.initTooltipManager = function (event) {
        const tooltipEditor = this;

        const root = ReactDom.createRoot($(tooltipEditor.node).get(0));
        root.render(
            <TooltipsList shopId={tooltipEditor.shopId}
                          santaPageMetadataId={tooltipEditor.santaPageMetadataId}
                          productId={tooltipEditor.productId}
                          subProductId={tooltipEditor.subProductId}
                          mode={tooltipEditor.mode}>
            </TooltipsList>
        );
    };

    this.after('initialize', function () {
        this.shopId = $(this.node).data('shop-id');
        this.santaPageMetadataId = $(this.node).data('santapage-metadata-id');
        this.productId = $(this.node).data('product-id');
        this.subProductId = $(this.node).data('sub-product-id');
        this.mode = $(this.node).data('mode');
        this.initTooltipManager();
    });
}
