import defineComponent from '../../../../components/flight/lib/component';
import React from 'react';
import ReactDom from 'react-dom/client';
import ProductMetaDataEditor from '../React/ProductMetaDataEditor/ProductMetaDataEditor.tsx';

export default defineComponent(AdminProductMetaDataEditor);

function AdminProductMetaDataEditor() {

    this.initProductMetaDataEditor = function () {
        const editor = $(this.node);
        const root = ReactDom.createRoot(editor.get(0));
        root.render(
            <ProductMetaDataEditor
                productId={editor.data('product-id')}
                subProductId={editor.data('sub-product-id')}
                santaType={editor.data('santa-type')}
            ></ProductMetaDataEditor>
        );
    };

    this.after('initialize', function () {
        this.initProductMetaDataEditor();
    });

}
