import mixam from '../../boot/mixam';
import defineComponent from '../../../components/flight/lib/component';
import Mustache from '../../../components/mustache/mustache';
import deliverySelectTemplate from 'text!../../../appes6/templates/order/delivery-select1.mustache';
import WithWorkdaysCalculator from '../shopping-cart/with-workdays-calculator';
import React from 'react';
import ReactDom from 'react-dom/client';
import {ConfirmAndDoButton} from '../react/dialog/ConfirmAndDoButton.tsx';

export default defineComponent(WithWorkdaysCalculator, FulfilmentTab);

function FulfilmentTab() {

    this.attributes({
        url: "/api/delivery/option/list",
        dispatchSummarySelector: '[data-type="item-dispatch-summary"]',
        dispatchFormSelector: '[data-type="item-dispatch-form"]',
        changeBtnSelector: '[data-type="change-fulfilment"]',
        sendEmailSelector: '[data-type="send-input"]',
        deleteFulfilmentSelector: '[data-type="delete-fulfilment"]',
        resendFulfilmentSelector: '[data-type="resend-fulfilment"]',
        reprintPodItemSelector: '[data-type="reprint-pod-item"]',
        dropDownSelector: '[data-toggele="cd-dropdown"]',
        supplierDeliveryValueSelector: '[data-type="supplier-delivery-value"]',
        unlockFormSelector: '[data-type="admin-unlock-form"]',
        costInputSelector: '.form-group-parent [data-type="cost-input"]',
        spineSelector: '[data-type="requested-spine-width"]',
        expressSelector: '[data-type="priority"]',
        digitalSelector: '.form-group-parent [data-type="digital"]',
        includeShipmentSelector: '.form-group-parent [data-type="includeShipment"]',
        offerPriceTypeSelector: '.form-group-parent [data-type="offerPriceType"]',
        originPostcodeSelector: '[data-type="origin-postcode"]',
        dropdownHiddenFiledSelector: '[data-type="dropdown-value"]',
        machineSelector: '.form-group-parent [data-type="machine"]',
        itemOrgCostSelector: '[data-type="original-cost"]',
        parentSelector: '.form-group-parent',
        spanUpdateSelector: '.selectlabel .update',
        deliveryAlternativeSelector: '[data-type="fulfilment-delivery-method"]',
        fieldsSelector: '[data-type="requested-spine-width"], [data-type="cost-input"], [data-type="remark-input"],' +
            '[data-type="send-input"], [data-type="dropdown-value"], [data-type="fulfilment-delivery-method"]',
        supplierDiversionContainerSelector: '[data-type="supplier-diversion"]',
        diversionSelectElementSelector: '[data-type="diversion-selection"]',
        diversionExplanationElementSelector: '[data-type="diversion-explanation"]',
        diversionSelectionErrorSelector: '[data-type="diversion-selection-error"]',
        diversionExplanationErrorSelector: '[data-type="diversion-explanation-error"]',
        diversionExplainLabelSelector: '[data-type="diversion-explanation-label"]'
    });

    this.costChange = function (event) {
        const $target = $(event.target);

        if (this.value && this.cost && $target.val()) {
            if (Math.abs($target.val() - this.cost) < 0.01) {
                // no change
                this.select('spanUpdateSelector').text("")
                    .parent().removeClass("has-change");
            } else {
                const newValue = this.value - ($target.val() - this.cost);
                this.select('spanUpdateSelector').text(newValue.formatNumber(2))
                    .parent().addClass("has-change");
            }
        }
        //console.log("costChange", this.cost, $target.val(), newValue);
    };

    this.getItemProperty = function ($element, selector) {
        const value = $element.find(selector).val();

        if (value) {
            return Math.round(parseFloat(value) * 100) / 100;
        }
        return null;
    };

    this.changeToForm = function (event) {
        event.preventDefault();
        this.select('dispatchSummarySelector').hide("fast");
        this.select('dispatchFormSelector').removeClass("baking").show("normal");
    };

    this.supplierChange = function (event) {
        const $target = $(event.target);
        event.stopPropagation();

        this.supplierId = $target.data('value');
        this.cost = this.getItemProperty($target, '[data-type="cost"]');
        this.machine = $target.find('[data-type="machine"]').val();
        this.days = $target.find('[data-type="days"]').val();
        this.originalPid = $target.find('[data-type="original-supplier-pid"]').val();
        this.originalMid = $target.find('[data-type="original-supplier-mid"]').val();
        this.originalDays = $target.find('[data-type="original-supplier-days"]').val();
        this.orgCost = $target.find('[data-type="cost"]').val();
        this.express = "TRUE" === ($target.find('[data-type="express"]').val() || '').toUpperCase();
        this.digital = "TRUE" === ($target.find('[data-type="digital"]').val() || '').toUpperCase();
        this.offerPriceType = $target.find('[data-type="offerPriceType"]').val();
        this.spine = $target.find('[data-type="spine"]').val();
        this.value = this.getItemProperty($target, '[data-type="value"]');
        this.originPostcode = $target.find('[data-type="originPostcode"]').val();
        this.includeShipment = "true" === $target.find('[data-type="includeShipment"]').val();

        const supplierDiversionContainer = this.select('supplierDiversionContainerSelector');
        const diversionSelect = this.select('diversionSelectElementSelector');
        const diversionTextArea = this.select('diversionExplanationElementSelector');
        const diversionSelectError = this.select('diversionSelectionErrorSelector');
        const diversionExplanationError = this.select('diversionExplanationErrorSelector');
        const explainLabel = this.select('diversionExplainLabelSelector');

        // Default values for select and text area
        diversionSelect.prop('selectedIndex',-1);
        diversionTextArea.val('');

        // Check whether selected offer is the one that the user originally selected or not (based on pid, mid and days)
        if(this.originalPid === this.supplierId && this.originalMid === this.machine && this.originalDays === this.days) {
            supplierDiversionContainer.hide();
            diversionSelect.removeAttr('required');
            diversionTextArea.removeAttr('required');
        } else {
            supplierDiversionContainer.show();
            diversionSelect.attr('required', 'true');
            diversionTextArea.attr('required', 'true');

            diversionSelect.on('change', function () {
                const diversionSelectValues = Array.from($(diversionSelect).find('option:selected')).map(selectedOption => selectedOption.value);

                diversionTextArea.val('');
                diversionSelectError.hide();
                if (diversionSelectValues.includes("OTHER")) {
                    explainLabel.show();
                    diversionTextArea.show();
                    diversionTextArea.attr('required', 'true');
                } else {
                    explainLabel.hide();
                    diversionTextArea.removeAttr('required');
                    diversionTextArea.hide();
                    diversionExplanationError.hide();
                }
            });
        }

        if (this.supplierId) {
            //console.log("supplierChange", this.cost, this.supplierId, this.machine);
            this.select('parentSelector').show("slow");
            this.select('fieldsSelector').removeAttr("disabled");
            this.select('costInputSelector').val(this.textToNumber(this.cost) || "");
            this.select('spineSelector').val(this.textToNumber(this.spine) || "");
            this.select('machineSelector').val(this.machine);
            this.select('itemOrgCostSelector').val(this.orgCost);
            this.select('expressSelector').val(this.express);
            this.select('digitalSelector').val(this.digital);
            this.select('includeShipmentSelector').val(this.includeShipment);
            this.select('offerPriceTypeSelector').val(this.offerPriceType);
            this.select('originPostcodeSelector').val(this.originPostcode);

            const supplierSpine = parseFloat(this.spine),
                    customerSpine = parseFloat(this.customerSpineWidth);
            if (supplierSpine && customerSpine && Math.abs(supplierSpine - customerSpine) > 0.03) { // 0.762mm if IMPERIAL
                /*
                 * This offer calls for a spine width that doesn't match what we told the customer;
                 * there is potential trouble ahead.
                 */
                this.trigger('showFixedMessage', {
                    type: 'warning',
                    body: `Spine Mismatch: the supplier spine with (${supplierSpine.toFixed(2)}) does not match the value given to the customer (${customerSpine.toFixed(2)}). Manual adjustment may be required.`,
                    interval: 30000
                });
            }

            this.fetchDeliveryAlternatives();
        } else {
            this.select('fieldsSelector').attr("disabled", true);
            this.select('parentSelector').hide("fast");
            this.select('dropdownHiddenFiledSelector').removeAttr("disabled");
        }

        // Record whether (or not) this selection represents a loss on the item...
        const lossOnItem = $target.data('lossOnItem') || false;
        this.$node.data('lossOnItem', lossOnItem);

    };

    this.fetchDeliveryAlternatives = function () {

        const $saveBtn = $('[data-type="save-fulfilment"]');
        $saveBtn.prop('disabled', true);

        this.select('deliveryAlternativeSelector').each((index, element) => {
            const $element = $(element);

            if (this.includeShipment || $element.data('automaticDelivery')) {
                $element.html(this.renderDeliveryList('', {deliveryRates: []}, $element.data('avoidAutomaticDelivery'), $element.data('deliveryOption')));
            } else {
                $element.html(`<span class="select-bkg" data-type="paper-weight-picker-content"><div class="wait">
                    <div class="dropdown negative-select-dropdown"><i class="fa fa-spin fa-spinner"></i> Checking delivery options</div></div></span>`);

                $.post(this.attr.url, {
                    originZip: this.originPostcode,
                    destinationZip: $element.data('destinationPostcode'),
                    weight: $element.data('weight'),
                    valueOfGoods: this.cost,
                    locationType: $element.data('locationType'),
                    pid: this.supplierId
                }, null, 'json').done(response => {
                    $(element).html(this.renderDeliveryList($element.data('deliveryId'), response, $element.data('avoidAutomaticDelivery'), $element.data('deliveryOption')));
                }).fail(response => {
                    this.trigger("log", {message: response});
                });
            }
        });

        $saveBtn.prop('disabled', false);

    };

    this.renderDeliveryList = function (deliveryId, rates, avoidAutomaticDelivery, requestedDeliveryOption) {
        /** @namespace rates.deliveryRates */
        return Mustache.render(deliverySelectTemplate, {
            deliveryId,
            index: this.index,
            avoidAutomaticDelivery: avoidAutomaticDelivery ? true : undefined,
            list: this.normalist(rates.deliveryRates, requestedDeliveryOption),
            formatNumber: () => mixam.mustachFormatNumber,
            currency: mixam.shop.currency
        });
    };

    this.normalist = function (list, requestedDeliveryOption) {
        /** @namespace list[0].canonicalUri */
        return list.map((r, i) => {
            r.index = i;
            r.deliveryDate = mixam.dateToLongString(this.addWorkingDays(this.deliveryDays + r.days));
            r.deliveryDateString = r.deliveryDate;
            r.selected = r.canonicalUri === requestedDeliveryOption;
            return r;
        });
    };

    this.textToNumber = function (text) {
        return parseFloat((text + "").replace(/,/g, ""));
    };

    this.sendEmail = function (event) {
        event.stopPropagation();
    };

    this.deleteFulfilment = function (event) {
        const $btn = $(event.target).closest("button"),
            $form = $(this.attr.unlockFormSelector),
            $input = $form.find("input");

        $input.val($btn.data('item'));
        $form.submit();
    };

    this.resendFulfilment = function (event) {
        const $btn = $(event.target).closest("button"),
            orderId = $btn.data('order'),
            itemId = $btn.data('item'),
            url = `/api/orders/${orderId}/${itemId}/merge`;
        $.post(url, () => {
            this.trigger('showFixedMessage', {
                type: 'info',
                body: 'Fulfilment data for this item has been re-submitted to the supplier; please be certain the supplier is prepared and will not print the item twice!',
                interval: 30000
            });
        });
    };

    this.addWorkingDays = function (days) {
        if (!this.workdays) {
            throw new Error('workdays collection is empty');
        }
        return new Date(this.workdays[days]);
    };

    this.setWorkdaysList = function (event, data) {
        this.workdays = data.list;
    };

    this.populateSupplierDeliveryValue = function(/* event */) {
        if (!this.supplierDeliveryValuesPopulated) {
            const $deliveryMethods = this.select('deliveryAlternativeSelector');

            /*
             * NOTE: be very careful about using $.data().
             *
             * jQuery makes "every attempt" (lol) to convert the attribute's string value to a
             * JavaScript value (e.g. Number or Boolean), but it will not convert values like '10.0'
             * that would loose characters when converted back into a string.
             */

            this.select('supplierDeliveryValueSelector').each((index, element) => {
                const $supplierDeliveryValue = $(element),
                    $li = $supplierDeliveryValue.closest('li'); // The <li> already contains all the information we need

                if ($li.length) { // Ignore the placeholder <span> for when the control is closed
                    // Split Delivery (there can be multiple delivery costs per item)
                    $supplierDeliveryValue.data('deliveryCharge', 0);
                    $supplierDeliveryValue.data('deliveryCost', 0);
                    $deliveryMethods.each((index, element) => {
                        const $method = $(element);

                        $supplierDeliveryValue.html('<i class="fa fa-truck" aria-hidden="true"></i> <i class="fa fa-spinner fa-spin" aria-hidden="true"></i>');
                        $.post(this.attr.url, {
                            originZip: $li.find('[data-type="originPostcode"]').val(),
                            destinationZip: $method.data('destinationPostcode'),
                            weight: parseFloat($method.attr('data-weight')),
                            valueOfGoods: parseFloat($method.attr('data-value-of-goods')),
                            locationType: $method.data('locationType'),
                            pid: $li.find('[data-type="supplierId"]').val()
                        }, null, 'json').done(response => {
                            if ($supplierDeliveryValue.data('deliveryCost') !== -1) {
                                const bestRateMatch = this.identifyBestRateMatch($method.data('deliveryOption'), $method.data('rateCategory'), $method.data('rateDays'), response.deliveryRates);
                                if (bestRateMatch) {
                                    const deliveryCharge = $supplierDeliveryValue.data('deliveryCharge') + parseFloat($method.attr('data-delivery-charge'));
                                    $supplierDeliveryValue.data('deliveryCharge', deliveryCharge);
                                    const deliveryCost = $supplierDeliveryValue.data('deliveryCost') + bestRateMatch.rate.total;
                                    $supplierDeliveryValue.data('deliveryCost', deliveryCost);
                                    const deliveryDaysDifference = $supplierDeliveryValue.data('deliveryDaysDifference') &&
                                    $supplierDeliveryValue.data('deliveryDaysDifference') > bestRateMatch.daysDifference ? $supplierDeliveryValue.data('deliveryDaysDifference') : bestRateMatch.daysDifference;
                                    $supplierDeliveryValue.data('deliveryDaysDifference', deliveryDaysDifference);
                                    const deliveryValue = deliveryCharge - deliveryCost;
                                    let daysDifferenceText = ''; // default
                                    if (deliveryDaysDifference > 0) {
                                        daysDifferenceText = `+ ${deliveryDaysDifference}`;
                                    } else if (deliveryDaysDifference < 0) {
                                        daysDifferenceText = `- ${Math.abs(deliveryDaysDifference)}`;
                                    }
                                    $supplierDeliveryValue.html(`${daysDifferenceText} <i class="fa fa-truck" aria-hidden="true"></i> ${mixam.shop.currency.prefix}${deliveryValue.toFixed(2)}`);
                                    const $combinedValue = $li.find('[data-type="supplier-combined-value"]'),
                                        printValue = parseFloat($combinedValue.attr('data-print-value')),
                                        totalValue = printValue + deliveryValue;
                                    $combinedValue.data('totalValue', totalValue);
                                    $combinedValue.html(`${mixam.shop.currency.prefix}${totalValue.toFixed(2)}`);
                                    $combinedValue.addClass(totalValue < 0 ? 'text-danger' : 'text-success');
                                    $li.data('lossOnItem', totalValue < 0);
                                } else {
                                    // We don't have a rate matching the user's selection so we can't show an estimate...
                                    $supplierDeliveryValue.data('deliveryCost', -1);
                                    $supplierDeliveryValue.html('<i class="fa fa-truck" aria-hidden="true"></i> <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>');
                                }
                            }
                        }).fail(response => {
                            this.trigger("log", {message: response});
                        });
                    });
                }

            });

            this.supplierDeliveryValuesPopulated = true;
        }
    };

    this.identifyBestRateMatch = function(canonicalUri, rateCategory, rateDays, deliveryRates) {
        let bestMatch;
        for (const rate of deliveryRates) {
            if (rate.canonicalUri === canonicalUri) {
                bestMatch = {
                    rate: rate,
                    daysDifference: 0
                };
                break; // This form of matching trumps all
            }
            // We're looking for a quote in the same category, and we need to compare days...
            const matchingRateCategory = rate.rateCategory === rateCategory || rateCategory === 5; // RATE_FREE_CARRIER
            if (matchingRateCategory && Number.isSafeInteger(rateDays)) {
                const possibleMatch = {
                    rate: rate,
                    daysDifference: rateCategory === 5 ? 0 : rate.days - rateDays // Real rates don't compete against RATE_FREE_CARRIER on days
                };
                if (!bestMatch) {
                    // We don't have any sort of match, so this one is "best" so far...
                    bestMatch = possibleMatch;
                    continue;
                }
                const isSlowerThanPreviousBest = bestMatch && possibleMatch.daysDifference > bestMatch.daysDifference;
                if (possibleMatch.daysDifference <= 0 || !isSlowerThanPreviousBest) {
                    // This rate _isn't_ slower than both (1) what we sold to the customer & (2) the previous best; we choose by price...
                    bestMatch = possibleMatch.rate.total < bestMatch.rate.total ? possibleMatch : bestMatch;
                }
                // Otherwise we keep the previous best, which is faster...
            }
        }
        return bestMatch;
    };

    this.reprintPodItem = function (orderId, itemId) {
        fetch(`/api/print-on-demand/orders/${orderId}/items/${itemId}/reprint`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        }).catch(function(err) {
            this.trigger('showFixedMessage', { type: 'error', body: err, interval: 10000 });
        });
    };

    this.after('initialize', function () {
        this.index = this.$node.data('index');
        this.deliveryDays = this.$node.data('deliveryDays');
        this.customerSpineWidth = this.$node.data('spine');
        this.select('dropDownSelector').removeClass('hidden').cdDropdown();
        this.on(document, 'dropdownOpen', this.populateSupplierDeliveryValue);
        this.on('dropdownChange', this.supplierChange);
        this.on(document, 'uiSetWorkdaysList', this.setWorkdaysList);
        this.on('change', {
            costInputSelector: this.costChange
        });

        this.on('input', {
            costInputSelector: this.costChange
        });

        this.on('click', {
            changeBtnSelector: this.changeToForm,
            deleteFulfilmentSelector: this.deleteFulfilment,
            resendFulfilmentSelector: this.resendFulfilment,
            sendEmailSelector: this.sendEmail
        });

        if (this.select('dispatchFormSelector').hasClass('baking')) {
            this.select('fieldsSelector').attr('disabled', true);
        } else {
            this.select('dropdownHiddenFiledSelector').attr('disabled', true);
        }

        const $reprintPodContainer = this.select('reprintPodItemSelector'),
            orderId = $reprintPodContainer.data('order'),
            itemId = $reprintPodContainer.data('item');
        if($reprintPodContainer.length > 0) {
            const root = ReactDom.createRoot($reprintPodContainer.get(0));
            root.render(
                <ConfirmAndDoButton
                    title={'Reprint POD Item'}
                    body={'Create a new, zero-cost order with the same item, quantity, customer, and delivery address?'}
                    className={'btn btn-product-3'}
                    onConfirm={() => this.reprintPodItem(orderId, itemId)}
                ></ConfirmAndDoButton>
            );
        }
    });
}
