// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
    --bs-primary: #6bd3cc !important;
    --bs-secondary: #787878 !important;
    --bs-white: #fff !important;
    --bs-gray: #b4b5b4 !important;
    --gray-medium: #e0e0e0 !important;
    --gray-background: #f5f5f5;
    --bs-gray-dark: #454f5b !important;
    --bs-danger: #ff0000 !important;
    --bs-info: #44c7be !important;

    --bs-success: #198754 !important;
    --bs-warning: #ffc853 !important;
    --bs-light: #f8f9fa !important;
    --bs-dark: #212529 !important;
    --bs-primary-rgb: rgb(107, 211, 204) !important;
    --bs-card-border-color: rgba(0, 0, 0, 0.125) !important;
    --bs-border-color-translucent: rgba(0, 0, 0, 0.125) !important;
}
`, "",{"version":3,"sources":["webpack://./src/main/webapp/resources/appes6/component_ui/react/self-publishing/PublicationSettings/bs-global-styles.css"],"names":[],"mappings":"AAAA;IACI,gCAAgC;IAChC,kCAAkC;IAClC,2BAA2B;IAC3B,6BAA6B;IAC7B,iCAAiC;IACjC,0BAA0B;IAC1B,kCAAkC;IAClC,+BAA+B;IAC/B,6BAA6B;;IAE7B,gCAAgC;IAChC,gCAAgC;IAChC,8BAA8B;IAC9B,6BAA6B;IAC7B,+CAA+C;IAC/C,uDAAuD;IACvD,8DAA8D;AAClE","sourcesContent":[":root {\n    --bs-primary: #6bd3cc !important;\n    --bs-secondary: #787878 !important;\n    --bs-white: #fff !important;\n    --bs-gray: #b4b5b4 !important;\n    --gray-medium: #e0e0e0 !important;\n    --gray-background: #f5f5f5;\n    --bs-gray-dark: #454f5b !important;\n    --bs-danger: #ff0000 !important;\n    --bs-info: #44c7be !important;\n\n    --bs-success: #198754 !important;\n    --bs-warning: #ffc853 !important;\n    --bs-light: #f8f9fa !important;\n    --bs-dark: #212529 !important;\n    --bs-primary-rgb: rgb(107, 211, 204) !important;\n    --bs-card-border-color: rgba(0, 0, 0, 0.125) !important;\n    --bs-border-color-translucent: rgba(0, 0, 0, 0.125) !important;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
