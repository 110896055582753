export default {
    dateDelimiter: "/",
    monthNames: ["Jan", "Feb", "Mär", "Apr", "Mai", "Jun", "Jul", "Aug", "Sep", "Okt", "Nov", "Dez"],
    monthLongNames: ["Januar", "Februar", "März", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Dezember"],
    dayNames: ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"],
    dayLongNames: ["Sonntag", "Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag"],
    validationErrors: {
        '[id=billingAddress.postcode.US][pattern]': 'Bitte gib eine <strong>fünfstellige Postleitzahl</strong> ein',
        '[id=deliveryAddress.postcode.US][pattern]': 'Bitte gib eine <strong>fünfstellige Postleitzahl</strong> ein',
        '[id=additionalAddress.postcode.US][pattern]': 'Bitte gib eine <strong>fünfstellige Postleitzahl</strong> ein',

        '[id=billingAddress.postcode.CA][pattern]': 'Bitte gib einen <strong>gültigen kanadischen Postcode (A9A 9A9)</strong> ein',
        '[id=deliveryAddress.postcode.CA][pattern]': 'Bitte gib einen <strong>gültigen kanadischen Postcode (A9A 9A9)</strong> ein',
        '[id=additionalAddress.postcode.CA][pattern]': 'Bitte gib einen <strong>gültigen kanadischen Postcode (A9A 9A9)</strong> ein',

        '[id=billingAddress.postcode.AU][pattern]': 'Bitte gib eine <strong>vierstellige Postleitzahl</strong> ein',
        '[id=deliveryAddress.postcode.AU][pattern]': 'Bitte gib eine <strong>vierstellige Postleitzahl</strong> ein',
        '[id=additionalAddress.postcode.AU][pattern]': 'Bitte gib eine <strong>vierstellige Postleitzahl</strong> ein',

        '[id=billingAddress.postcode.OTHER][pattern]': 'Bitte gib eine <strong>gültige Postleitzahl</strong> für deine Region ein',
        '[id=deliveryAddress.postcode.OTHER][pattern]': 'Bitte gib eine <strong>gültige Postleitzahl</strong> für deine Region ein',
        '[id=additionalAddress.postcode.OTHER][pattern]': 'Bitte gib eine <strong>gültige Postleitzahl</strong> für deine Region ein',

        '[name=username][required]': 'Bitte gib deine <strong>E-Mail-Adresse</strong> ein',
        '[name=password][required]': 'Bitte gib dein <strong>Passwort</strong> ein',
        '[id=expiryMonth][required]': 'Bitte gib einen gültigen <strong>Ablaufmonat</strong> ein',
        '[id=expiryYear][required]': 'Bitte gib ein gültiges <strong>Ablaufjahr</strong> ein',
        '[name=billingAddress.phone]': 'Bitte gib eine gültige <strong>Telefonnummer</strong> ein, die nur aus Zahlen besteht',
        '[name=additionalAddress.phone]': 'Bitte gib eine gültige <strong>Telefonnummer</strong> ein, die nur aus Zahlen besteht',
        '[data-min-length]': '<strong>{field-label}</strong> muss mindestens $1 Zeichen lang sein',
        '[data-must-match]': 'Die Passwortwerte müssen identisch mit dem ersten Passwortwert sein',
        '[required]': "Bitte gib ein '{field-label}' ein"
    },
    onDirtyWindowUnload: "Du hast einen unvollendeten Datei-Upload. Das Verlassen dieser Seite wird den Upload-Prozess abbrechen.",
    currencySymbol: "€",
    currencyPrefixSymbol: "",
    currencyPostfixSymbol: " €",
    decimalSymbol: ".",
    digitGroupingSymbol: ",",
    weightSymbol: "kg",
    deliveryOption: {
      label_DHL_48_HOUR_TRACKED_zxa_2: "Standardversand 2 Werktage",
    },
    santa: {
        bindChange: "Die Bindung wurde geändert, um sich der Seitenzahl anzupassen",
        inkChange: "Die Farbe wurde auf Vollfarbe geändert",
        coverChange: "Ein Umschlag wurde hinzugefügt, um sich an die Klebebindung anzupassen",
        laminationChange: "Lamination was set to 'None' because the paper weight is less than 170GSM",
        pagesChange: "Die minimale Seitenanzahl von Broschüren ohne Umschlag beträgt 8",
        paperChange: "Papiergewicht wurde auf '170GSM' eingestellt, da eine Laminierungsoption gewählt wurde",
        paperWeightForced: "Der von dir gesuchte Papiertyp ({from}gsm) ist derzeit nicht verfügbar. Wir schlagen vor, stattdessen {to}gsm zu verwenden.",
        paperWeightUpgrade: "Papiergewicht wurde aufgrund der Seitenanzahländerung auf {to} gsm geändert",
        minCopiesSewing: "Für fadengeheftete Bücher ist eine Mindestanzahl von {minimum} Exemplaren erforderlich",
        oneProductionDay: "Ein Werktag",
        zeroProductionDay: "Druck am selben Tag bis 10 Uhr morgens",
        deliveryDatesEstimated: "Zustelltermine können nicht garantiert werden.",
        totalWeightOfOrder: "Gesamtgewicht:",
        deliveredToYouOn: "Voraussichtliche Lieferung am:",
        deliveredToYouOn2: "Voraussichtliche Lieferung am:",
        tryBeforeYouBuy: "Probieren Sie es aus, bevor Sie kaufen",
        orderFreeTest: "Musterprobe bestellen",
        postageFeesOnly: "Porto- und Bearbeitungsgebühren:",
        bestValue: "Bestes Preis-Leistungs-Verhältnis",
        partialResults: "Teilübereinstimmung",
        notExactly: "Achtung! Sie können Geld sparen, indem Sie auf",
        notExactly2: "anstatt auf",
        notExactly3: "drucken",
        vatIncluded: "MwSt. inbegriffen",
        yourDiscount: "Ihr Rabatt",
        fastest: "Express",
        productionDays: "Produktionstage",
        printedOn: "Gedruckt auf einem",
        digital: "Digital",
        litho: "Litho",
        inkjet: "Inkjet",
        wideFormat: "Großformat",
        addToCart: "In den Warenkorb",
        updateOrder: "Ändern zu",
        permanentLink: "Ein dauerhafter Link zu diesem Angebot",
        itemNotAvailable: "Artikel nicht verfügbar",
        pleaseTryAnother: "Bitte versuchen Sie eine andere Auswahl oder ",
        errorContact: "kontaktieren Sie",
        usForAssistance: "uns für Unterstützung",
        frontSide: "Vorderseite",
        backSide: "Rückseite",
        outSide: "Außenseite",
        inSide: "Innenseite",
        shipDoorToDoor: "Preis beinhaltet alle Versandkosten",
        printedIn: "Gedruckt in",
        customSized: 'Freies Format',
        shoppingCart: {
            reprintAddedToCart: "Nachdruck in den Warenkorb gelegt",
            deliveryIncluded: "Kostenlose Lieferung",
            copies: "Kopien",
            total: "Gesamt",
            delivery: "Lieferung",
            vat: "MwSt",
            tax: "Steuer",
            zeroRated: "steuerfrei",
            addVat: "MwSt. hinzufügen",
            info: "Info",
            removeThisItem: "Diesen Artikel entfernen",
            spineWidth: "Buchrückenbreite",
            mm: "mm",
            sides: "Seiten",
            pages: "Seiten",
            size: "Größe",
            gsm: "g/m²",
            paper: "Papier",
            kg: 'kg',
            on: "Am",
            in: "In",
            item: "Artikel",
            discount: "Rabatt",
            discountOn: "Rabatt auf",
            throughout: "Während",
            digitallyPrinted: "Digital gedruckt",
            inkjetPrinted: "Inkjetdruck",
            wideFormatPrinted: "Großformatdruck",
            specialInksOnFront: "Spezialfarben auf der Vorderseite",
            specialInksOnBack: "Spezialfarben auf der Rückseite",
            binding: "Bindung",
            coatingOnFront: "Veredelung auf der Vorderseite",
            coatingOnBack: "Veredelung auf der Rückseite",
            onTheFront: "auf der Vorderseite",
            onTheBack: "auf der Rückseite",
            folded: "Gefaltet",
            hardCover: "Hardcover",
            dustJacket: "Schutzumschlag",
            twoAdditionalPages: "(2 zusätzliche Seiten)",
            fourAdditionalPages: "(4 zusätzliche Seiten)",
            additionalPages: "zusätzliche Seiten",
            oneAdditionalPage: "(eine zusätzliche Seite)",
            inLowerCase: "in",
            cover: "Umschlag",
            envelope: "Umschläge",
            inside: "innen",
            onTheOutside: "auf der Außenseite",
            specialInksOnCover: "Spezialfarben auf dem Umschlag",
            and: "und",
            endPaperColour: "Vorsatzpapierfarbe",
            ribbonColour: "Lesebandfarbe",
            headTailBand: "Kapitalband",
            foiling: "Folienprägung",
            sewing: "Fadenheftung",
            coating: "Veredelung",
            outside: "(außen)",
            estimatedDeliveryDate: "Voraussichtliches Lieferdatum",
            productionDays: "Produktionstage",
            fulfiler: "Produktion",
            pallets: "Paletten",
            weightUnit: "kg",
            onePallet: "eine Palette",
            oneConsignment: "Eine Sendung",
            consignments: "Sendungen",
            by: "von",
            item2: "Artikel",
            clearBag: "Klarsichtbeutel",
            backCover: "Rückseite",
            packaging: "Verpackung",
            estimatedDeliveryDateOn: "Voraussichtliche Lieferung am",
            flyersAreVat: "Die meisten Flyer & Falzflyer sind mehrwertsteuerbefreit, obwohl dies von der endgültigen Verwendung des Produkts abhängt.",
            bookletsAreVat: "Broschüren & Booklets sind mehrwertsteuerbefreit, obwohl dies von der endgültigen Verwendung des Produkts abhängt.",
            standardVatRate: "Dieser Artikel unterliegt dem regulären Mehrwertsteuersatz. Wenn Sie glauben, dass dies fälschlicherweise so gekennzeichnet ist, hinterlassen Sie bitte eine Anmerkung.",
            vatZeroRated: "MwSt.: steuerfrei",
            emptyCart: "Warenkorb leeren",
            quoteReference: "Angebotsreferenz"
        },
        offer: {
            producedIn: {
                "GB": 'Hergestellt im Vereinigten Königreich',
                "DE": 'Hergestellt in Deutschland'
            },
        },
        display: {
            singlePriceText: "Preis & <span class='hidden-sm'>voraussichtliches Liefer</span>datum",
            multiPriceText: "Preise & <span class='hidden-sm'>voraussichtliche Liefer</span>daten",
            offerText: "Unser Angebot",
            priceText: "Preis",
            turnaround: "Lieferung innerhalb von {taroundMin} bis {taroundMax} Arbeitstagen"
        },
        order: {
            itemToolbarPreview: "Vorschau",
            previewJob: "Vorschau",
            viewFinalPdf: "Produktionsbereites PDF anzeigen",
            itemToolbarProof: "Proof",
            itemToolbarLeftToRight: "Von Links nach Rechts (Englisch, Latein)",
            itemToolbarRightToLeft: "Von Rechts nach Links (Arabisch, Hebräisch)",
            itemToolbarShare: "Teilen",
            itemToolbarViewAsList: "Als Liste anzeigen",
            itemToolbarViewAsBook: "Als Buch anzeigen",
            downloadOriginals: "Datei runterladen",
            itemToolbarDownload: "Download",
            itemToolbarAutoArrange: "Druckdaten automatisch anordnen",
            itemToolbarClearAll: "Alle hochgeladenen Dateien löschen",
            showGuideLines: "Führungslinien anzeigen",
            trimBoundaries: "Schnittlinien",
            bleedBoundaries: "Beschnitt",
            bleedBoundariesAnd: "und",
            mm: "mm",
            spineGutterBoundaries: "Falzrand",
            frontFaceBox: "Rahmengrenze",
            showMore: "Mehr anzeigen",
            showLess: "Weniger anzeigen",
            yourFileIsBeingProcessed: "Die Verarbeitung kann bis zu 15 Minuten dauern. Sobald fertig, wird diese Seite automatisch aktualisiert.",
            thisFileWasCreatedByYou: 'Diese Datei wurde von dir erstellt. Es ist nur',
            aLowResolutionPreview: 'eine niedrigauflösende Vorschau des Originalwerks',
            toUseThePrintQualityVersion: 'Um die druckfähige Version der Datei zu verwenden',
            forAdditional: 'für zusätzliche',
            pleaseCheckTheBox: 'bitte das Kästchen unten markieren:',
            usePrintQualityVersion: 'Druckfähige Version verwenden',
            doubleSpread: 'Doppelseite',
            trimboxAdded: 'Trimbox hinzugefügt',
            upscaleBleedAdded: 'Zusätzliche Beschnittzugabe hinzugefügt',
            mirrorBleedAdded: 'Gespiegelte Beschnittzugabe hinzugefügt',
            stretchBleedAdded: 'Dehnbeschnitt hinzugefügt',
            pixelBleedAdded: 'Pixelbeschnitt hinzugefügt',
            coverSpread: 'Umschlagseite',
            alternativeFileOriginal: 'Original',
            alternativeFileUpScale: 'Vergrößerter Beschnitt',
            alternativeFileMirrored: 'Gespiegelter Beschnitt',
            alternativeFileStretched: "Gedehnter Beschnitt",
            alternativeFileAlternatives: 'Alternativen:',
            fileRenameOk: 'OK',
            fileRenameCancel: 'Abbrechen',
            fileRenameRenameFile: 'Datei umbenennen',
            aBleedBoxWasAddedAutomatically: 'Eine Beschnittbox wurde automatisch durch Skalierung hinzugefügt und 1,5mm als Beschnittbereich zugewiesen. Bitte überprüfen Sie alle Ihre Seiten auf Objekte, die beschnitten worden sein könnten.',
            addedAutomaticallyByMirroring: 'Eine Beschnittbox wurde automatisch durch Spiegelung jeder Seite hinzugefügt und 1,5mm als Beschnittbereich zugewiesen. Bitte überprüfen Sie alle Ihre Seiten auf Objekte, die beschnitten worden sein könnten.',
            thisFileContainsACoverSpread: 'Diese Datei enthält eine Umschlagseite',
            fileInfoPages: 'Seiten',
            originalFileTitle: 'Originaldatei',
            removeThisFile: 'Diese Datei entfernen',
            preflightReport: 'Preflight-Bericht',
            alternativeDatesProductionDays: 'Produktionstage',
            alternativeDatesPrintedOn: 'Gedruckt auf',
            alternativeDatesPrintedOnDigital: 'Digital',
            alternativeDatesPrintedOnLitho: 'Litho',
            alternativeDatesPrintedOnInkjet: 'Tintenstrahl',
            alternativeDatesYourChoice: 'Deine Wahl',
            alternativeDatesChange: 'Ändern',
            alternativeDatesSorry: 'Entschuldigung!',
            alternativeDatesWeDoNotHave: 'Wir haben keine alternativen Angebote für deine Art von Arbeit verfügbar.',
            chooseYourAddress: 'Wähle deine Adresse',
            countDownHoursLeft: 'ungefähr {hours} Stunden',
            countDownMinutesLeft: '{minutes} Minuten',
            insertBlankBefore: 'Vor dieser Seite eine leere Seite einfügen',
            insertBlankAfter: 'Nach dieser Seite eine leere Seite einfügen',
            rotateClockwise: 'Diese Seite um 90 Grad im Uhrzeigersinn drehen',
            rotateCounterClockwise: 'Diese Seite um 90 Grad gegen den Uhrzeigersinn drehen',
            deleteThisPpage: 'Diese Seite löschen',
            cropImage: 'Bild zuschneiden',
            cropCancel: 'Abbrechen',
            cropSave: 'OK',
            weAreSearchingForSuitable: 'Wir suchen nach passenden Lieferoptionen. Wir werden die Bestellung aktualisieren und dich per E-Mail benachrichtigen, sobald wir die Lieferdetails für dich haben.',
            yourFileIsBeingDownloadedFrom: 'Deine Datei wird heruntergeladen von',
            pageIsUpdatedAutomaticallyAfterFileIsReady: 'Das Hochladen der Datei kann bis zu 15 Minuten dauern. Sobald fertig, wird diese Seite automatisch aktualisiert.',
        },
    },
    member: {
        memberStatus: "Status",
        memberOrderNumber: "Bestellnummer",
        memberCreated: "Erstellt",
        memberModified: "Geändert",
        memberConfirmed: "Bestätigt",
        memberArtwork: "Kunstwerk",
        memberSettled: "Abgerechnet",
        memberRemarks: "Bemerkungen",
        memberExpected: "Erwartet",
        memberSum: "Summe",
        pager: {
            page: "Seite",
            orders: "Bestellungen"
        },
        memberCancel: "Abbrechen",
        memberReorder: "Nachbestellen",
        memberSelfPublish: "Selbstveröffentlichen",
        memberProblem: "Ein Problem melden",
        memberContact: "Kontaktiere uns, um nachzubestellen",
        memberView: "Dieses Angebot anzeigen",
        memberYourOrder: "Deine Bestellung",
        memberYourShoppingCart: "Dein Warenkorb",
        memberCheckingAvailability: "Verfügbarkeit wird geprüft...",
        memberGetStarted: "Los geht's!",
        addReorderItem: "Zum Warenkorb hinzufügen",
        memberVat: "MwSt",
        memberDelivery: "Lieferung",
        memberTotal: "Gesamt",
        memberAccount: "Konto",
        memberTargetAccount: "Zielkonto-E-Mail",
        memberCreateAccount: "Ein Konto erstellen",
        memberSignin: "Anmelden",
        memberYouDidNot: "Du hast keine aktuellen oder früheren Bestellungen",
        memberAsSoon: "Sobald du eine Bestellung aufgibst, wird sie hier erscheinen.",
        INIT: "Neue Bestellung",
        PENDING: "Warten auf Druckdaten & Zahlung",
        RIPE: "Warten auf Kundenbestätigung",
        ONHOLD: "Angehalten (Aktion erforderlich)",
        CONFIRMED: "Bestätigt",
        INPRODUCTION: "Zur Produktion gesendet",
        ACCEPTED: "In Produktion",
        DISPATCHED: "Versandt",
        CLOSED: "Abgeschlossen",
        CANCELED: "Storniert",
        problemCategoryType: {
            placeholder: "Choose a category",
            otherIssue: "Anderes Problem",
            packageDidNotArrive: "Paket ist nicht angekommen",
            missingItems: "Fehlende Artikel",
            wrongOrder: "Ich habe eine falsche Bestellung erhalten",
            arrivedDamaged: "Beschädigter oder defekter Artikel",
            deliveryIssue: "Lieferproblem",
            bindingIssue: "Bindungsproblem",
            colourVariance: "Die Farben sind anders als erwartet",
            inkMarks: "Tintenflecken auf dem Produkt",
            finishQuality: "Probleme mit der Veredelungsqualität",
            printingQuality: "Druckqualitätsprobleme",
            marks: "Walzenmarkierungen/Banding/Streifen",
            shortage: "Fehlende Exemplare",
            trimmedIncorrectly: "Falsch zugeschnitten",
            incorrectPaper: "Falsches Papier",
            incorrectPageOrder: "Falsche Seitenreihenfolge",
            whiteStripsOnEdges: "Weiße Streifen an den Seitenrändern",
            unknownContent: "Unbekannte Bilder oder Texte auf der Seite sichtbar",
            differentToWhatIOrdered: "Anders als bestellt",
        },
        problemAction: {
            none: "Keine Aktion",
            discussion: "Die Angelegenheit besprechen",
            reprint: "Nachdruck anfordern",
            discount: "Rabatt anfordern",
            refund: "Vollständige Rückerstattung anfordern",
        },
        publication: {
            title: "Publikationstitel",
            orderDate: "Bestelldatum",
            orderStatus: "Bestellstatus",
            totalCost: "Gesamtkosten",
            customerName: "Kundenname",
            mixamCost: "Mixam-Kosten",
            buyerPrice: "Käuferpreis",
            yourRevenue: "Dein Umsatz"
        }
    },
    dispatch: {
        weightUnit: "kg",
        paperUnit: "g/m²",
        pallets: "Paletten",
        parcels: "Pakete"
    },
    quote: {
        thankYou: "Danke",
        thankYouForYour: "Danke für deine Anfrage.",
        yourRequest: "Deine Anfrage wurde empfangen.",
        ourCustomerService: "Unser Kundenservice wird sich so bald wie möglich bei dir melden.",
        home: "Start",
        theQuoteWas: "Das Angebot wurde dir zugesendet.",
    },
    contact: {
        thankYou: "Danke",
        yourRequest: "Wir haben deine Nachricht erhalten.",
        ourCustomerService: "Unser Team wird sich so bald wie möglich bei dir melden.",
        home: "Start",
    },
    priceType: {
        SUPERSAVER: "Sparangebot",
        STANDARD: "Standard",
        EXPRESS: "Express"
    },
    datastore: {
        localstorageNotSupported: "Dein lokaler Speicher ist im privaten Browsing-Modus nicht zugänglich."
    },
    address: {
        postcodeMinLengthError: '<strong>Postleitzahl</strong> muss 6 Zeichen oder mehr haben',
        overseasDelivery: 'Bitte beachte, dass internationale Bestellungen einschließlich der EU zusätzliche Zoll- und Verbrauchsteuern enthalten können. _shopname_ ist nicht verantwortlich für etwaige Zoll- und Verbrauchsteuern, die durch den Import oder Export deiner Waren entstehen.'
    },
    shoppingCart: {
        itemDeletedWarning: "Artikel wurde gelöscht. <a id={id} href=#>Rückgängig machen</a>"
    },
    chat: {
        chatWithUs: "Chatte mit uns",
        wouldYouLike: `Möchtest du mit einem Mitglied des Kundenservice-Teams von <strong>_shopname_</strong> chatten?<br>Gib unten eine Nachricht ein und drücke <em>Enter</em>`
    },
    paper: {
        bodyWeightNoLongerAvailable: 'Das ausgewählte Papiergewicht (_weight_gsm) für den Inhalt ist nicht mehr verfügbar',
        coverWeightNoLongerAvailable: 'Das ausgewählte Papiergewicht (_weight_gsm) für den Umschlag ist nicht mehr verfügbar'
    },
    messages: {
        "1025": {
            type: "info",
            body: "Deine Zahlung wurde erfolgreich verarbeitet.",
            interval: 10000
        },
        "1026": {
            type: "info",
            body: "Eine leere Seite wurde eingefügt. <a data-type='undo' href=#>Rückgängig machen</a>",
            interval: 20000
        },
        "1027": {
            type: "info",
            body: "Die Seite wurde gelöscht. <a data-type='undo' href=#>Rückgängig machen</a>",
            interval: 20000
        },
        "1028": {
            type: "error",
            body: "Unser Server konnte die Aufgabe nicht abschließen. Bitte versuche es später erneut.",
            interval: 10000
        },
        "1029": {
            type: "info",
            body: "Rückgängig gemacht",
            interval: 1
        },
        "1030": {
            type: "info",
            body: "Die Seitenreihenfolge wurde gespeichert. <a data-type='undo' href=#>Rückgängig machen</a>",
            interval: 20000
        },
        "1031": {
            type: "info",
            body: "Die Seitenreihenfolge wurde zurückgesetzt. <a data-type='undo' href=#>Rückgängig machen</a>",
            interval: 20000
        },
        "1032": {
            type: "success",
            body: "Die Bestellung wurde storniert.",
            interval: 10000
        },
        "1033": {
            type: "failure",
            body: "Fehler beim Stornieren der Bestellung. Bitte versuche es später erneut.",
            interval: 20000
        },
        "1034": {
            type: "success",
            body: "Die Lesereihenfolge wurde erfolgreich geändert.",
            interval: 20000
        },
        "1035": {
            type: "error",
            body: "Fehler beim Abrufen der Artikeldaten von Mixam. Bitte versuche es später.",
            interval: 20000
        },
        "1036": {
            type: "error",
            body: "Fehler beim Laden der Bestellung.",
            interval: 10000
        },
        "1037": {
            type: "success",
            body: "Die MwSt. wurde nicht geändert",
            interval: 10000
        },
        "1038": {
            type: "normal",
            body: "Erstellung einer Proof-Datei. Der Vorgang kann mehrere Minuten dauern.",
            interval: 10000
        },
        "1039": {
            type: "success",
            body: "Daten wurden auf deinem Google Drive gespeichert",
            interval: 10000
        },
        "1040": {
            type: "error",
            body: "Aktion abgebrochen. Du kannst keine inneren Vorder- oder Rückseiten verschieben",
            interval: 10000
        },
        "1042": {
            type: "success",
            body: "MwSt. wurde hinzugefügt",
            interval: 10000
        },
        "1043": {
            type: "success",
            body: "MwSt. wurde entfernt",
            interval: 10000
        },
        "1045": {
            type: "normal",
            body: "Auf fluoreszierendem farbigem Papier ist nur schwarzer Druck verfügbar",
            interval: 10000
        },
        "1046": {
            type: "normal",
            body: "Schmuckfarbe ist nur auf 90g/m² Papier verfügbar",
            interval: 10000
        },
        "1047": {
            type: "normal",
            body: "Spot-UV ist nur auf 300g/m² Seidenpapier auf einer Seite verfügbar",
            interval: 10000
        },
        "1048": {
            type: "normal",
            body: "Schmuckfarbe ist nur auf 170, 250 und 300g/m² Seiden- oder Glanzpapier verfügbar",
            interval: 10000
        },
        "1049": {
            type: "normal",
            body: "Wetterfestes Vinyl ist in dieser Größe nicht verfügbar",
            interval: 10000
        },
        "1050": {
            type: "normal",
            body: "Erstellung einer Zip-Datei. Der Vorgang kann mehrere Minuten dauern.",
            interval: 10000
        },
        "1051": {
            type: "normal",
            body: "450g/m² Visitenkarten müssen laminiert werden",
            interval: 10000
        },
        "1052": {
            type: "error",
            body: "A3-Kalender sind nur in Hochformat verfügbar",
            interval: 10000
        },
        "1053": {
            type: "error",
            body: "Wir konnten deine Postleitzahl nicht finden. Bitte überprüfe, ob du den richtigen Code hast.",
            interval: 10000
        },
        "1054": {
            type: "error",
            body: "A7-Broschüren sind nur im Hochformat verfügbar",
            interval: 10000
        },
        "1055": {
            type: "error",
            body: "Du musst mindestens einen Shop auswählen.",
            interval: 10000
        },
        "1056": {
            type: "normal",
            body: "Bitte beachte: Laminierung ist nur für 'Cover'-Papiertypen verfügbar",
            interval: 10000
        },
        "1057": {
            type: "error",
            body: "Verfügbare Lieferdienste konnten nicht abgerufen werden",
            interval: 10000
        },
        "1058": {
            type: "normal",
            body: "Danke für deine Frage. Wir werden so schnell wie möglich antworten.",
            interval: 10000
        },
        "1059": {
            type: "success",
            body: "Die Einstellungen der Veröffentlichung wurden erfolgreich geändert.",
            interval: 10000
        },
    },
    icons: {
        orientation: {
            0: "mx-portrait",
            1: "mx-landscape"
        },
        bind: {
            0: "mx-saddle",
            2: "mx-perfect",
            4: "mx-pur",
            6: "mx-casebind",
            8: "mx-wiro",
            10: "mx-loop",
            16: "mx-wiro"
        }
    },
    ordinal: ['', 'erster', 'zweiter', 'dritter', 'vierter', 'fünfter', 'sechster', 'siebter', 'achter', 'neunter', 'zehnter', 'elfter',
        'zwölfter', 'dreizehnter', 'vierzehnter', 'fünfzehnter', 'sechzehnter', 'siebzehnter', 'achtzehnter', 'neunzehnter', 'zwanzigster',
        'einundzwanzigster', 'zweiundzwanzigster', 'dreiundzwanzigster', 'vierundzwanzigster', 'fünfundzwanzigster', 'sechsundzwanzigster', 'siebenundzwanzigster',
        'achtundzwanzigster', 'neunundzwanzigster', 'dreißigster', 'einunddreißigster', 'zweiunddreißigster', 'dreiunddreißigster', 'vierunddreißigster',
        'fünfunddreißigster', 'sechsunddreißigster', 'siebenunddreißigster', 'achtunddreißigster', 'neununddreißigster', 'vierzigster'],
    admin: {
        'mostViewed': 'Am meisten angesehen',
    },
    dictionary: {
        copies: function (value) {
            return (+value).formatNumber(0);
        },
        packaging: {
            "0": '',
            "1": 'Klarer Beutel',
        },
        backCover: {
            "0": '',
            "1": 'Ja',
        },
        colors: {
            "0": "kein Druck",
            "1": "schwarz-weiß",
            "5": "farbig"
        },
        colors2: {
            "0": "kein Druck",
            "1": "schwarz-weiß",
            "5": "farbig"
        },
        coverColors: {
            "0": "kein Druck",
            "1": "schwarz-weiß",
            "5": "farbig"
        },
        coverColors2: {
            "0": "kein Druck",
            "1": "schwarz-weiß",
            "5": "farbig"
        },
        dustJacketColors: {
            "0": "kein Druck",
            "1": "schwarz-weiß",
            "5": "farbig"
        },
        format: {
            "1": "DIN A1",
            "2": "DIN A2",
            "3": "DIN A3",
            "4": "DIN A4",
            "5": "DIN A5",
            "6": "DIN A6",
            "7": "DIN A7"
        },
        secondaryFormat: {
            "1": "Novel (203mm x 127mm)",
            "2": "Standard (197mm x 132mm)",
            "3": "Demy (216mm x 138mm)",
            "4": "US Royal (229mm x 152mm)",
            "5": "Royal (234mm x 156mm)",
            "6": "210mm Square",
            "7": "210mm x 280mm",
            "8": "Dl Small 100",
            "9": "DL 105mm x 210mm",
            "10": "A5 Long (105mm x 297mm)",
            "11": "148mm Square",
            "12": "105mm Square",
            "13": "120mm Square",
            "14": "A1 Long",
            "15": "A2 Long",
            "16": "DL Small",
            "17": "B1",
            "18": "B2",
            "19": "183mm x 273mm",
            "20": "150mm x 265mm",
            "21": "100mm Square",
            "22": "55mm x 85mm",
            "23": "DL",
            "24": "C5",
            "25": "C4",
            "26": "C6",
            "27": "Square 155",
            "28": "Square 170",
            "29": "DL large",
            "30": "50mm x 90mm",
            "31": "54mm x 86mm",
            "32": "55mm Square",
            "33": "B5",
            "34": "B4",
            "35": "98mm Square",
            "36": "A6 Long",
            "37": "A7 Long",
            "38": "B0",
            "39": "170mm x 590mm",
            "40": "Large format",
            "41": "A7 mini",
            "42": "170mm x 240mm",
            "43": "508mm x 762mm",
            "44": "762mm x 1016mm",
            "45": "1016mm x 1524mm",
            "46": '5.5" x 8.5"',
            "47": '8.5" x 11"',
            "48": '8.5" x 22"',
            "49": '4.25" x 5.5"',
            "50": '4" x 6"',
            "51": '5" x 7"',
            "52": '6" x 9"',
            "53": '8" x 10"',
            "54": '6" x 11"',
            "55": '11" x 17"',
            "56": '6" x 6"',
            "57": '12" x 12"',
            "58": '4.75" x 4.75"',
            "59": '12" x 18"',
            "60": '13" x 19"',
            "61": '18" x 24"',
            "62": '19" x 27"',
            "63": '24" x 36"',
            "64": '26" x 39"',
            "65": '8.5" x 14"',
            "66": 'UK Standard (157mm x 240mm)',
            "67": 'US Standard (170mm x 260mm)',
            "68": 'Manga Standard (127mm x 191mm)',
            "69": '5" x 8"',
            "70": '5.06" x 7.81"',
            "71": '5.25" x 8"',
            "72": '6.14" x 9.21"',
            "73": '6.69" x 9.61"',
            "74": '7" x 10"',
            "75": '7.44" x 9.69"',
            "76": '7.5" x 9.25"',
            "77": '6" x 8.25"',
            "78": '8.25" x 8.25"',
            "79": '8.5" x 8.5"',
            "80": '7" x 7"',
            "81": "140mm Square",
            "82": "B6 (125mm x 176mm)",
            "83": 'US Trade 6" x 9"',
            "84": 'Business card 3.5" x 2"',
            "85": 'Postcard 5.5" x 3.5"',
            "86": 'Postcard 4.25" x 6"',
            "87": '8" x 11"',
            "88": '11" x 14"',
            "89": '8" x 8"',
            "90": "B format UK",
            "91": "Pinched Crown Quarto",
            "92": "Crown Quarto",
            "93": "280 Square",
            "94": '7.25" x 9.5"',
            "95": 'Pocket Book 4.25" x 6.87"',
            "96": 'Small Square 7.5"',
            "97": 'Quarto 9.5" × 12"',
            "98": '7" × 9"',
            "99": '200mm x 200mm',
            "100": '200mm x 250mm',
            "101": '200mm x 300mm',
            "102": '200mm x 600mm',
            "103": '300mm x 300mm',
            "104": '300mm x 400mm',
            "105": '300mm x 600mm',
            "106": '300mm x 1000mm',
            "107": '400mm x 400mm',
            "108": '400mm x 500mm',
            "109": '400mm x 600mm',
            "110": '500mm x 500mm',
            "111": '500mm x 600mm',
            "112": '500mm x 700mm',
            "113": '500mm x 750mm',
            "114": '500mm x 1000mm',
            "115": '600mm x 600mm',
            "116": '600mm x 750mm',
            "117": '600mm x 800mm',
            "118": '750mm x 1000mm',
            "119": '254mm x 254mm',
            "120": '300mm x 300mm',
            "121": '89mm x 216mm',
            "122": '108mm x 280mm',
            "123": '216mm x 178mm',
            "124": '216mm x 191mm',
            "125": '229mm x 305mm',
            "126": '229mm x 406mm',
            "127": '216mm x 432mm',
            "128": '292mm x 445mm',
            "129": '432mm x 559mm',
            "130": '279mm x 648mm',
            "131": '203mm x 305mm',
            "132": '254mm x 381mm',
            "133": '5.5" x 8.5"',
            "134": '3.5" x 8.5"',
            "135": '4" x 9"',
            "136": '2" x 8"',
            "137": '3.66" x 4.25"',
            "138": '3" x 4"',
            "139": '4" x 4"',
            "140": '9.25" x 5.8"',
            "141": '8.375" x 10.875"',
            "142": '5.375" x 8.375"',
            "143": '5.25" x 8.375"',
            "144": '8.27" x 11.69"',
            "145": '6.63" x 10.25"',
            "146": '20mm x 20mm',
            "147": '21mm x 21mm',
            "148": '25mm x 25mm',
            "149": '10mm x 30mm',
            "150": '30mm x 30mm',
            "151": '15mm x 35mm',
            "152": '35mm x 35mm',
            "153": '35mm x 105mm',
            "154": '35mm x 210mm',
            "155": '35mm x 316mm',
            "156": '20mm x 40mm',
            "157": '40mm x 40mm',
            "158": '15mm x 45mm',
            "159": '45mm x 45mm',
            "160": '48mm x 70mm',
            "161": '20mm x 50mm',
            "162": '25mm x 50mm',
            "163": '40mm x 50mm',
            "164": '50mm x 50mm',
            "165": '51mm x 298mm',
            "166": '51mm x 420mm',
            "167": '55mm x 85mm',
            "168": '60mm x 60mm',
            "169": '21mm x 68mm',
            "170": '70mm x 70mm',
            "171": '71mm x 96mm',
            "172": '80mm x 80mm',
            "173": '45mm x 95mm',
            "174": '95mm x 95mm',
            "175": '95mm x 145mm',
            "176": '98mm x 420mm',
            "177": '120mm x 125mm',
            "178": '140mm x 297mm',
            "179": '124mm x 140mm',
            "180": '98mm x 210mm',
            "181": '6.875" x 10.25"',
            "182": '7.375" x 10.25"',
            "183": '8.75" x 11"',
            "184": '6.63" x 10.25"'
        },
        secondaryFormatSize: {
            "1": "203mm x 127mm",
            "2": "197mm x 132mm",
            "3": "216mm x 138mm",
            "4": "229mm x 152mm",
            "5": "234mm x 156mm",
            "6": "210mm X 210mm",
            "7": "210mm x 280mm",
            "8": "100mm x 210mm",
            "9": "105mm x 210mm",
            "10": "105mm x 297mm",
            "11": "148mm x 148mm",
            "12": "105mm x 105mm",
            "13": "120mm x 120mm",
            "14": "420mm x 1188mm",
            "15": "297mm x 840mm",
            "16": "99mm x 210mm",
            "17": "707mm x 1000mm",
            "18": "500mm x 707mm",
            "19": "183mm x 273mm",
            "20": "150mm x 265mm",
            "21": "100mm x 100mm",
            "22": "55mm x 85mm",
            "23": "220mm x 110mm",
            "24": "229mm x 162mm",
            "25": "229mm x 324mm",
            "26": "162mm x 114mm",
            "27": "155mm x 155mm",
            "28": "170mm x 170mm",
            "29": "229mm x 114mm",
            "30": "50mm x 90mm",
            "31": "54mm x 86mm",
            "32": "55mm x 55mm",
            "33": "175mm x 245mm",
            "34": "248mm x 346mm",
            "35": "98mm x 98mm",
            "36": "74mm x 210mm",
            "37": "52mm x 149mm",
            "38": "1000mm x 1414mm",
            "39": "170mm x 590mm",
            "40": "1185mm x 1750mm",
            "41": "74mm x 98mm",
            "42": "170mm x 240mm",
            "43": "508mm x 762mm",
            "44": "762mm x 1016mm",
            "45": "1016mm x 1524mm",
            "46": '140mm x 216mm"',
            "47": '216mm x 279mm',
            "48": '559mm x 216mm',
            "49": '108mm x 140mm',
            "50": '102mm x 152mm',
            "51": '127mm x 178mm',
            "52": '152mm x 229mm',
            "53": '203mm x 254mm',
            "54": '152mm x 279mm',
            "55": '279mm x 432mm',
            "56": '152mm x 152mm',
            "57": '305mm x 305mm',
            "58": '121mm x 121mm',
            "59": '305mm x 457mm',
            "60": '330mm x 483mm',
            "61": '457mm x 610mm',
            "62": '483mm x 686mm',
            "63": '610mm x 914mm',
            "64": '660mm x 991mm',
            "65": '216mm x 356mm',
            "66": '157mm x 240mm',
            "67": '170mm x 260mm',
            "68": '127mm x 191mm',
            "69": '127mm x 203mm',
            "70": '129mm x 198mm',
            "71": '133mm x 203mm',
            "72": '156mm x 234mm',
            "73": '170mm x 244mm',
            "74": '178mm x 254mm',
            "75": '189mm x 246mm',
            "76": '191mm x 235mm',
            "77": '210mm x 152mm',
            "78": '210mm x 210mm',
            "79": '216mm x 216mm',
            "80": '178mm x 178mm',
            "81": '140mm x 140mm',
            "82": "125mm x 176mm",
            "83": '6" x 9"',
            "84": '3.5" x 2"',
            "85": '5.5" x 3.5"',
            "86": '4.25" x 6"',
            "87": '8" x 11"',
            "88": '11" x 14"',
            "89": '8" x 8"',
            "90": '12" x 12"',
            "91": '246mm x 171mm',
            "92": '246mm x 189mm',
            "93": '280mm x 280mm',
            "94": '7.25" x 9.5"',
            "95": '4.25" x 6.87"',
            "96": '7.5" x 7.5"',
            "97": '9.5" × 12"',
            "98": '7" × 9"',
            "99": '200mm x 200mm',
            "100": '200mm x 250mm',
            "101": '200mm x 300mm',
            "102": '200mm x 600mm',
            "103": '300mm x 300mm',
            "104": '300mm x 400mm',
            "105": '300mm x 600mm',
            "106": '300mm x 1000mm',
            "107": '400mm x 400mm',
            "108": '400mm x 500mm',
            "109": '400mm x 600mm',
            "110": '500mm x 500mm',
            "111": '500mm x 600mm',
            "112": '500mm x 700mm',
            "113": '500mm x 750mm',
            "114": '500mm x 1000mm',
            "115": '600mm x 600mm',
            "116": '600mm x 750mm',
            "117": '600mm x 800mm',
            "118": '750mm x 1000mm',
            "119": '254mm x 254mm',
            "120": '300mm x 300mm',
            "121": '89mm x 216mm',
            "122": '108mm x 280mm',
            "123": '216mm x 178mm',
            "124": '216mm x 191mm',
            "125": '229mm x 305mm',
            "126": '229mm x 406mm',
            "127": '216mm x 432mm',
            "128": '292mm x 445mm',
            "129": '432mm x 559mm',
            "130": '279mm x 648mm',
            "131": '203mm x 305mm',
            "132": '254mm x 381mm',
            "133": '5.5" x 8.5"',
            "134": '3.5" x 8.5"',
            "135": '4" x 9"',
            "136": '2" x 8"',
            "137": '3.66" x 4.25"',
            "138": '3" x 4"',
            "139": '4" x 4"',
            "140": '9.25" x 5.8"',
            "141": '213mm x 276mm',
            "142": '137mm x 213mm',
            "143": '133mm x 213mm',
            "144": '8.27" x 11.69"',
            "145": '6.63" x 10.25"',
            "146": '20mm x 20mm',
            "147": '21mm x 21mm',
            "148": '25mm x 25mm',
            "149": '10mm x 30mm',
            "150": '30mm x 30mm',
            "151": '15mm x 35mm',
            "152": '35mm x 35mm',
            "153": '35mm x 105mm',
            "154": '35mm x 210mm',
            "155": '35mm x 316mm',
            "156": '20mm x 40mm',
            "157": '40mm x 40mm',
            "158": '15mm x 45mm',
            "159": '45mm x 45mm',
            "160": '48mm x 70mm',
            "161": '20mm x 50mm',
            "162": '25mm x 50mm',
            "163": '40mm x 50mm',
            "164": '50mm x 50mm',
            "165": '51mm x 298mm',
            "166": '51mm x 420mm',
            "167": '55mm x 85mm',
            "168": '60mm x 60mm',
            "169": '21mm x 68mm',
            "170": '70mm x 70mm',
            "171": '71mm x 96mm',
            "172": '80mm x 80mm',
            "173": '45mm x 95mm',
            "174": '95mm x 95mm',
            "175": '95mm x 145mm',
            "176": '98mm x 420mm',
            "177": '120mm x 125mm',
            "178": '140mm x 297mm',
            "179": '124mm x 140mm',
            "180": '98mm x 210mm',
            "181": '6.875" x 10.25"',
            "182": '7.375" x 10.25"',
            "183": '8.75" x 11"',
            "184": '6.63" x 10.25"'
        },
        secondaryFormatName: {
            "1": "Novel",
            "2": "Standard",
            "3": "Demy",
            "4": "US Royal",
            "5": "Royal",
            "6": "Square",
            "7": "",
            "8": "DL Small 100",
            "9": "DL",
            "10": "A5 Long",
            "11": "Square",
            "12": "Square",
            "13": "Square",
            "14": "A1 Long",
            "15": "A2 Long",
            "16": "DL Small",
            "17": "B1",
            "18": "B2",
            "19": "",
            "20": "",
            "21": "Square",
            "22": "",
            "23": "DL",
            "24": "C5",
            "25": "C4",
            "26": "C6",
            "27": "Square",
            "28": "Square",
            "29": "DL large",
            "30": "",
            "31": "",
            "32": "Square",
            "33": "B5",
            "34": "B4",
            "35": "Square",
            "36": "A6 Long",
            "37": "A7 Long",
            "38": "B0",
            "39": "",
            "40": "Large format",
            "41": "A7 mini",
            "42": "170mm x 240mm",
            "43": "20in x 30in",
            "44": "30in x 40in",
            "45": "40in x 60in",
            "46": '5.5" x 8.5"',
            "47": '8.5" x 11"',
            "48": '8.5" x 22"',
            "49": '4.25" x 5.5"',
            "50": '4" x 6"',
            "51": '5" x 7"',
            "52": '6" x 9"',
            "53": '8" x 10"',
            "54": '6" x 11"',
            "55": '11" x 17"',
            "56": "Square",
            "57": "Square",
            "58": "Square",
            "59": '12" x 18"',
            "60": '13" x 19"',
            "61": '18" x 24"',
            "62": '19" x 27"',
            "63": '24" x 36"',
            "64": '26" x 39"',
            "65": '8.5" x 14"',
            "66": 'UK Standard',
            "67": 'US Standard',
            "68": 'Manga Standard',
            "69": '5" x 8"',
            "70": '5.06" x 7.81"',
            "71": '5.25" x 8"',
            "72": '6.14" x 9.21"',
            "73": '6.69" x 9.61"',
            "74": '7" x 10"',
            "75": '7.44" x 9.69"',
            "76": '7.5" x 9.25"',
            "77": '6" x 8.25"',
            "78": "Square",
            "79": "Square",
            "80": "Square",
            "81": "Square",
            "82": "B6",
            "83": 'US Trade',
            "84": 'Business card',
            "85": 'Postcard',
            "86": 'Postcard',
            "87": '8" x 11"',
            "88": '11" x 14"',
            "89": '8" x 8"',
            "90": "B format UK",
            "91": "Pinched Crown Quarto",
            "92": "Crown Quarto",
            "93": "280 Square",
            "94": '7.25" x 9.5"',
            "95": 'Pocket Book 4.25" x 6.87"',
            "96": 'Small Square 7.5"',
            "97": 'Quarto 9.5" × 12"',
            "98": '7" × 9"',
            "99": '200mm x 200mm',
            "100": '200mm x 250mm',
            "101": '200mm x 300mm',
            "102": '200mm x 600mm',
            "103": '300mm x 300mm',
            "104": '300mm x 400mm',
            "105": '300mm x 600mm',
            "106": '300mm x 1000mm',
            "107": '400mm x 400mm',
            "108": '400mm x 500mm',
            "109": '400mm x 600mm',
            "110": '500mm x 500mm',
            "111": '500mm x 600mm',
            "112": '500mm x 700mm',
            "113": '500mm x 750mm',
            "114": '500mm x 1000mm',
            "115": '600mm x 600mm',
            "116": '600mm x 750mm',
            "117": '600mm x 800mm',
            "118": '750mm x 1000mm',
            "119": 'Square',
            "120": 'Square',
            "121": '3.5" x 8.5"',
            "122": '4.25" x 11"',
            "123": '7" x 8.5"',
            "124": '7.5" x 8.5"',
            "125": '9" x 12"',
            "126": '9" x 16"',
            "127": '8.5" x 17"',
            "128": '11.5" x 17.5"',
            "129": '17" x 22"',
            "130": '11" x 25.5"',
            "131": '8" x 12"',
            "132": '10" x 15"',
            "133": '5.5" x 8.5"',
            "134": '3.5" x 8.5"',
            "135": '4" x 9"',
            "136": '2" x 8"',
            "137": '3.66" x 4.25"',
            "138": '3" x 4"',
            "139": '4" x 4"',
            "140": '9.25" x 5.8"',
            "141": '8.375" x 10.875"',
            "142": '5.375" x 8.375"',
            "143": '5.25" x 8.375"',
            "144": '8.27" x 11.69"',
            "145": '6.63" x 10.25"',
            "146": '20mm x 20mm',
            "147": '21mm x 21mm',
            "148": '25mm x 25mm',
            "149": '10mm x 30mm',
            "150": '30mm x 30mm',
            "151": '15mm x 35mm',
            "152": '35mm x 35mm',
            "153": '35mm x 105mm',
            "154": '35mm x 210mm',
            "155": '35mm x 316mm',
            "156": '20mm x 40mm',
            "157": '40mm x 40mm',
            "158": '15mm x 45mm',
            "159": '45mm x 45mm',
            "160": '48mm x 70mm',
            "161": '20mm x 50mm',
            "162": '25mm x 50mm',
            "163": '40mm x 50mm',
            "164": '50mm x 50mm',
            "165": '51mm x 298mm',
            "166": '51mm x 420mm',
            "167": '55mm x 85mm',
            "168": '60mm x 60mm',
            "169": '21mm x 68mm',
            "170": '70mm x 70mm',
            "171": '71mm x 96mm',
            "172": '80mm x 80mm',
            "173": '45mm x 95mm',
            "174": '95mm x 95mm',
            "175": '95mm x 145mm',
            "176": '98mm x 420mm',
            "177": '120mm x 125mm',
            "178": '140mm x 297mm',
            "179": '124mm x 140mm',
            "180": '98mm x 210mm',
            "181": '6.875" x 10.25"',
            "182": '7.375" x 10.25"',
            "183": '8.75" x 11"',
            "184": '6.63" x 10.25"'
        },
        paper: {
            "0": "90",
            "1": "105",
            "2": "115",
            "3": "130",
            "4": "150",
            "5": "170",
            "6": "225",
            "7": "250",
            "8": "300",
            "9": "350",
            "13": "400",
            "14": "200",
            "15": "450",
            "16": "240",
            "50": "80",
            "100": "90",
            "101": "105",
            "102": "115",
            "103": "130",
            "104": "150",
            "105": "170",
            "106": "225",
            "107": "250",
            "108": "300",
            "109": "350",
            "110": "80",
            "111": "100",
            "112": "120",
            "113": "200",
            "114": "74",
            "115": "104",
            "116": "80",
            "120": "70",
            "5001": "135",
            "6001": "120",
            "4001": "270",
            "4002": "350",
            "5002": "400",
            "6002": "300",
            "7001": "80",
            "7002": "170",
            "7003": "250",
            "7004": "350",
            "7005": "300",
            "7006": "100",
            "7007": "120",
            "7008": "135",
            "7009": "150",
            "7010": "190",
            "8001": "80",
            "8002": "170",
            "8003": "250",
            "8004": "350",
            "8005": "300",
            "8006": "120",
            "8007": "100",
            "8008": "135",
            "9001": "80",
            "10001": "115",
            "11001": "280",
            "11002": "350",
            "32001": "520",
            "45001": "80",
            "45002": "90",
            "45003": "100",
            "45004": "120",
            "45005": "135",
            "45006": "150",
            "45007": "170",
            "45008": "190",
            "45009": "250",
            "45010": "300",
            "45011": "350",
            "55000": "260",
            "60000": "74",
            "60001": "80",
            "61000": "67",
            "62000": "74",
            "63000": "80"
        },
        paperFinish: {
            "1": "Matt",
            "2": "Glänzend",
            "3": "Ungestrichen",
            "4": "Colorplan",
            "5": "Heaven 42",
            "6": "Original Gmund",
            "7": "Recycling Ungestrichen",
            "8": "Naturpapier",
            "9": "Fluorescent",
            "10": "Blueback (poster paper)",
            "11": "Postcard Board",
            "12": "Linen",
            "13": "Transparent",
            "14": "Coloured Offset",
            "15": "SBS Board",
            "16": "CHROMOLUX Pearl",
            "17": "Silverstar",
            "18": "Board with Metallic Effect",
            "19": "Board with Peelable White Sticker",
            "20": "Polypropylene Translucent",
            "21": "Wood Pulp Board",
            "22": "White Multilayer",
            "23": "Plastic vinyl",
            "24": "600µm PVC card Silk",
            "25": "600µm PVC card Gloss",
            "26": "gold paper",
            "27": "50µm Stafix�� white electrostatic self-adhesive film",
            "28": "210µm backlit film",
            "29": "Glossy photo paper",
            "30": "Polypropylene twin-wall sheet, 2.5mm",
            "31": "Cardboard with film coating",
            "32": "Weatherproof Vinyl",
            "33": "Lux",
            "34": "Tearproof",
            "35": "Cream",
            "36": "Tintoretto Gesso",
            "37": "River Dot",
            "38": "Rives Tweed",
            "39": "Kraft",
            "40": "Magnecote",
            "41": "Ice Gold",
            "42": "Fresco Gesso",
            "43": "Rives Shetland",
            "44": "Conqueror Gold",
            "45": "Recycled Silk",
            "56": "Polyester",
            "57": "Créme",
            "58": "Groundwood",
            "59": "Matt",
            "60": "Uncoated Natural"
        },
        coverType: {
            "0": "Ohne Umschlag",
            "3": "130",
            "4": "150",
            "5": "170",
            "6": "225",
            "7": "250",
            "8": "300",
            "9": "350",
            "11": "74",
            "12": "104",
            "13": "400",
            "14": "200",
            "16": "240",
            "104": "150",
            "105": "170",
            "106": "225",
            "107": "250",
            "108": "300",
            "109": "350",
            "110": "80",
            "111": "100",
            "112": "120",
            "113": "200",
            "114": "74",
            "4001": "270",
            "4002": "350",
            "5002": "400",
            "6002": "300",
            "7001": "80",
            "7002": "170",
            "7003": "250",
            "7004": "350",
            "7005": "300",
            "7006": "100",
            "7007": "120",
            "7008": "135",
            "7009": "150",
            "7010": "190",
            "8001": "80",
            "8002": "170",
            "8003": "250",
            "8004": "350",
            "8005": "300",
            "8006": "120",
            "8007": "100",
            "8008": "135",
            "9001": "80",
            "10001": "115",
            "11001": "280",
            "11002": "350",
            "32001": "520",
            "45001": "80",
            "45002": "90",
            "45003": "100",
            "45004": "120",
            "45005": "135",
            "45006": "150",
            "45007": "170",
            "45008": "190",
            "45009": "250",
            "45010": "300",
            "45011": "350",
            "62000": "74",
            "62001": "104"
        },
        orientation: {
            "0": "Hochformat",
            "1": "Querformat"
        },
        coverPaperFinish: {
            "1": "Matt",
            "2": "Glänzend",
            "3": "Ungestrichen (Offset)",
            "4": "Colorplan",
            "5": "Heaven 42",
            "6": "Original Gmund",
            "7": "Recycled Uncoated",
            "8": "Recycled Natural",
            "9": "Fluorescent",
            "10": "Blueback (poster paper)",
            "11": "Postcard Board",
            "12": "Linen",
            "13": "Transparent",
            "14": "Coloured Offset",
            "45": "Recycled Silk",
        },
        coat0: {
            "0": "Ohne",
            "5": "Folienkaschierung Matt",
            "4": "Folienkaschierung Glänzend",
            "6": "Folienkaschierung Soft-Touch",
            "7": "UV Lack Matt",
            "8": "UV Lack Glänzend",
            "10": "Spot UV",
            "12": "Spot 3D UV",
            "14": "Matt lamination with Spot UV",
            "15": "Folienkaschierung Matt Kratzfest"
        },
        coat1: {
            "0": "Ohne",
            "5": "Folienkaschierung Matt",
            "4": "Folienkaschierung Glänzend",
            "6": "Folienkaschierung Soft-Touch",
            "7": "UV Lack Matt",
            "8": "UV Lack Glänzend",
            "10": "Spot UV",
            "12": "Spot 3D UV",
            "14": "Matt lamination with Spot UV",
            "15": "Folienkaschierung Matt Kratzfest"
        },
        coat2: {
            "0": "Ohne",
            "5": "Folienkaschierung Matt",
            "4": "Folienkaschierung Glänzend",
            "6": "Folienkaschierung Soft-Touch",
            "7": "UV Lack Matt",
            "8": "UV Lack Glänzend",
            "10": "Spot UV",
            "12": "Spot 3D UV",
            "14": "Matt lamination with Spot UV",
            "15": "Folienkaschierung Matt Kratzfest"
        },
        coat3: {
            "0": "Ohne",
            "5": "Folienkaschierung Matt",
            "4": "Folienkaschierung Glänzend",
            "6": "Folienkaschierung Soft-Touch",
            "7": "UV Lack Matt",
            "8": "UV Lack Glänzend",
            "10": "Spot UV",
            "12": "Spot 3D UV",
            "14": "Matt lamination with Spot UV",
            "15": "Folienkaschierung Matt Kratzfest"
        },
        bind: {
            "0": "Klammerheftung",
            "2": "Klebebindung",
            "4": "Klebebindung",
            "6": "Hardcover",
            "8": "Wire-O Bindung",
            "10": "Ringösenheftung",
            "12": "Calendar Wiro Binding",
            "16": "Spiralbindung"
        },
        bindColor: {
            "0": "Schwarz",
            "1": "Weiß",
            "2": "Silber"
        },
        bindLoops: {
            "0": "Two loops",
            "1": "Four loops"
        },
        endPaperColor: {
            "0": "Stock White",
            "1": "Stock Black",
            "10": "Adriatic",
            "11": "Amethyst",
            "12": "Azure Blue",
            "13": "Brown",
            "14": "Bitter Chocolate",
            "15": "Bright Red",
            "16": "Bright White",
            "17": "Candy Pink",
            "18": "China White",
            "19": "Citrine",
            "20": "Claret",
            "21": "Cobalt",
            "22": "Cool Blue",
            "23": "Cool Grey",
            "24": "Dark Grey",
            "25": "Ebony",
            "26": "Emerald",
            "27": "Factory Yellow",
            "28": "Forest",
            "29": "Fuschia Pink",
            "30": "Harvest",
            "31": "Hot Pink",
            "32": "Ice White",
            "33": "Imperial Blue",
            "34": "Lavender",
            "35": "Lockwood Green",
            "36": "Mandarin",
            "37": "Marrs Green",
            "38": "Mid Green",
            "39": "Mist",
            "40": "Natural",
            "41": "New Blue",
            "42": "Nubuck Brown",
            "43": "Pale Grey",
            "44": "Park Green",
            "45": "Pistachio",
            "46": "Powder Green",
            "47": "Pristine White",
            "48": "Purple",
            "49": "Racing Green",
            "50": "Real Grey",
            "51": "Royal Blue",
            "52": "Sapphire",
            "53": "Scarlet",
            "54": "Smoke",
            "55": "Sorbet Yellow",
            "56": "Stone",
            "57": "Tabriz Blue",
            "58": "Turquoise",
            "59": "Vellum White",
            "60": "Vermillion",
            "61": "White Frost",
        },
        ribbonColor: {
            "0": "Ohne",
            "1": "Weiß",
            "2": "Elfenbein",
            "3": "Gold",
            "4": "Orange",
            "5": "Leuchtend Rot",
            "6": "Weinrot",
            "7": "Babyrosa",
            "8": "Lila",
            "9": "Hellblau",
            "10": "Babyblau",
            "11": "Marineblau",
            "12": "Frischgrün",
            "13": "Dunkelgrün",
            "14": "Grau",
            "15": "Schwarz"
        },
        headTailBand: {
            "0": "None",
            "1": "Navy Blue & White",
            "2": "Black & Green",
            "3": "Red & Black",
            "4": "Purple & White",
            "5": "Red & Grey",
            "6": "Red & White",
            "7": "Yellow & Brown",
            "8": "Dark Brown & White",
            "9": "Blue & Yellow",
            "10": "Red & Green",
            "11": "Yellow & Green",
            "12": "Blue & White",
            "13": "Dark Blue & White",
            "14": "Red & Yellow",
            "15": "Maroon & White",
            "16": "Yellow & Black",
            "17": "Green & White",
            "18": "Black & White",
            "19": "Dark Green & Black",
            "20": "Dark Green & White",
            "21": "Red",
            "22": "Black",
            "23": "Grey",
            "24": "White",
            "25": "Dark Blue",
            "26": "Yellow"
        },
        sewing: {
            "0": "Nein",
            "1": "Ja",
        },
        simpleFold: {
            "1": "Half fold",
            "2": "Roll fold",
            "3": "Z fold",
            "4": "Gate fold",
            "5": "Cross fold",
            "6": "Double parallel fold"
        },
        delivery: {
            "0": "folded",
            "1": "flat"
        },
        paperFinishColor: {
            "0": "",
            "4001": "Bitter Chocolate",
            "4002": "Bright Red",
            "4003": "Claret",
            "4004": "Dark Grey",
            "4005": "Ebony",
            "4006": "Factory Yellow",
            "4007": "Imperial Blue",
            "4008": "Racing Green",
            "4009": "White Frost",
            "5001": "Heaven 42",
            "6001": "10% Cotton Blanc Tactile",
            "9001": "Yellow",
            "9002": "Green",
            "9003": "Red",
            "14001": "Cherry Red",
            "14002": "Cobalt Blue",
            "14003": "May Green",
            "14004": "Sun Yellow"
        },
        coverPaperFinishColor: {
            "0": "",
            "4001": "Bitter Chocolate",
            "4002": "Bright Red",
            "4003": "Claret",
            "4004": "Dark Grey",
            "4005": "Ebony",
            "4006": "Factory Yellow",
            "4007": "Imperial Blue",
            "4008": "Racing Green",
            "4009": "White Frost",
            "5001": "Heaven 42",
            "6001": "10% Cotton Blanc Tactile",
            "9001": "Yellow",
            "9002": "Green",
            "9003": "Red",
            "14001": "Cherry Red",
            "14002": "Cobalt Blue",
            "14003": "May Green",
            "14004": "Sun Yellow",
        },
        envelopeType: {
            "111": "100",
            "112": "120"
        },
        envelopePaperFinish: {
            "3": "uncoated",
        },
        "roundCorners": {
            "0": "Square corners",
            "1": "Rounded corners"
        },
        "paymentMethod": {
            0: "Kauf auf Rechnung (Geschäftskunden)",
            1: "Kredit/Debit-Karte",
            2: "Banküberweisung",
            3: "PayPal",
            4: "Kredit/Debit-Karte",
            5: "Stripe",
            6: "Phone",
            7: "Amazon pay",
            8: "Digital wallet (via stripe)",
            9: "Paypal (manual)",
            10: "Kauf auf Rechnung (Geschäftskunden)",
            11: "Clearpay",
            12: "Volt",
            13: "Credit Key",
            14: "Proformarechnung",
            15: "Plaid ACH Transfer"
        }
    }
};