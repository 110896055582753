import React from "react";
import mixam from "../../boot/mixam";

class MultiButton extends React.Component {
    render() {
        const data = this.props.data || {};
        let links = [];
        data.forEach(function (buttonData) {
            const showBtn = !(buttonData.role && mixam.user.roleList.includes(buttonData.role));
            if (showBtn) {
                const link = (
                    <a
                        title={buttonData.title}
                        target={buttonData.target}
                        href={buttonData.href}
                        className={buttonData.className}
                        key={buttonData.key}
                        onClick={buttonData.callback}
                    >
                        <i className={`${buttonData.iconClassname} d-none`}/>
                        {buttonData.caption}
                    </a>
                );
                links.push(link);
            }
        });

        return <span className="cell-content col-buttons">{links}</span>
    }
}

export default MultiButton;
