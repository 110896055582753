export default {
    NONE: 'NONE',
    CXML: 'CXML',
    TRADEPRINT_API: 'TRADEPRINT_API',
    MXJDF4: 'MXJDF4',
    FLYER_ALARM: 'FLYER_ALARM',
    SITE_FLOW: 'SITE_FLOW',
    PRECISION_PROCO_SITE_FLOW: 'PRECISION_PROCO_SITE_FLOW',
    DOCUMATION: 'DOCUMATION',
    BLUETREE: 'BLUETREE',
    TAYLORS: 'TAYLORS',
    PRINTED: 'PRINTED',
    RPI: 'RPI',
    SOLOPRESS: 'SOLOPRESS'
};
