import defineComponent from '../../../components/flight/lib/component';

export default defineComponent(OdeonRemoteControl);

function OdeonRemoteControl() {
    this.doClick = function (event) {
        event.preventDefault();
        event.stopPropagation();
        const $video = $(`[data-odeon-id="${this.videoId}"]`);
        $video.scrollToView(() => $video[0].play());
    };

    this.after('initialize', function () {
        this.videoId = this.$node.data('videoId');
        this.on('click', this.doClick);
    });
}
