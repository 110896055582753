import AnalogClock from "../analog-clock";
import Mustache from '../../../components/mustache/mustache';
import cutoffTemplate from 'text!../../../appes6/templates/admin/cutoff.mustache';
import defineComponent from '../../../components/flight/lib/component';

export default defineComponent(CutoffZoneManager);

function CutoffZoneManager() {


    this.attributes({
        counterSelector: '[data-type="count"]',
        circleSelector: '.count-down-holder svg circle',
        counterHoldSelector: '.count-down-holder',
        clockSelector: '[data-type="analog-clock"]',
        timeSelector: '[data-type="time"]',
        dateSelector: '[data-type="date"]'
    });

    this.render = function (data) {
        return Mustache.render(cutoffTemplate, data);
    };

    this.getNow = function () {
        const d = new Date();
        //d.setHours(18);
        const zonedTime = d.toLocaleString("en-US", {timeZone: this.timeZone});
        return new Date(zonedTime);
    };

    this.getDayNightMode = function (time) {
        const hour = time.getHours();
        if (hour >= 20 || hour < 7) {
            return 'night';
        }

        if (hour >= 7 && hour < 12 ) {
            return 'morning';
        }

        if (hour >= 12 && hour < 16) {
            return 'afternoon';
        }

        if (hour >= 16 && hour < 20) {
            return 'evening';
        }

        return 'day';
    };

    this.setDayNightMode = function (mode) {
        if (mode !== this.dayNightMode) {
            if (this.dayNightMode) {
                this.node.classList.remove(this.dayNightMode);
            }
            this.dayNightMode = mode;
            this.node.classList.add(this.dayNightMode);
        }
    };

    this.onTime = function () {
        const now = this.getNow();
        this.cutoffMode = now.getHours() === this.cutoff - 1;
        const min = 60 - now.getMinutes();
        const date = new Date();

        this.setDayNightMode(this.getDayNightMode(now));

        if (this.cutoffMode) {
            this.node.classList.add('cutoff-selected');
            this.select('circleSelector').css('stroke-dashoffset', min / 60 * 598 );
            if (min <= 30) {
                this.select('circleSelector').css('stroke', '#ff4f42');
            } else {
                this.select('circleSelector').css('stroke', '#ffc853');
            }
        } else {
            this.node.classList.remove('cutoff-selected');
        }

        this.select('timeSelector').text(new Intl.DateTimeFormat("en-US", this.timeOptions).format(date));
        this.select('dateSelector').text(new Intl.DateTimeFormat("en-US", this.dateOptions).format(date));
        this.select('counterSelector').text(min);
        setTimeout(() => this.onTime(), 1000);
    };

    this.after('initialize', function () {
        this.timeZone = this.$node.data('timezone');
        this.title = this.$node.data('title');
        this.cutoff = +this.$node.data('cutoff');

        this.timeOptions = { timeZone: this.timeZone,  hour12: false, hour: 'numeric', minute: 'numeric', second1: 'numeric'};
        this.dateOptions = { timeZone: this.timeZone,  month: "long", day: "numeric" };

        this.$node.html(this.render({
            timeZone: this.timeZone,
            title: this.title,
            cutoff: this.cutoff,
        }));

        AnalogClock.attachTo(this.select('clockSelector'));
        setTimeout(() => this.onTime(), 0);
    });
}
