import OrderStatus from "../constants/orderStatus";
import Order from '../constants/order';

function timeToString(time) {
    const now = +new Date(),
        dateDiff = Math.round((now - time) / 1000 / 60),
        timeTemplate = "h:MM TT";

    if (dateDiff < 240) {
        return "<div class='timebox'><span class=value><span class=hours>" + Math.floor(dateDiff / 60) + "</span><span class='seperator'>:</span>" +
            "<span class='minutes'>" + padl(dateDiff % 60, "0", 2) + "</span>" +
            "<span class=caption>hours ago</span></div>";
    } else if (time > getStartOfDay()) {
        return "<div class='timebox'><span class=value><span class=hours>" + Math.round(dateDiff / 60) + "</span></span><span class=caption>hours ago</span></div>";
    } else if (time > getStartOfDay(-1)) {
        return "<div class='timebox'><div class=date-part>Yesterday</div><div class=time-part>" + (new Date(time)).format(timeTemplate) + "</div></div>";
    } else if (dateDiff < 60 * 24 * 21) {
        return "<div class='timebox'><span class=value><span class=days>" + Math.ceil(dateDiff / 60 / 24) + "</span><span class=caption>days ago</span></div>";
    }
    return null;
}

function padl(n, c, len) {
    return ("" + replicate(c, len) + n).slice(-1 * len);
}

function getStartOfDay(days) {
    const now = new Date();
    return new Date(now.getFullYear(), now.getMonth(), now.getDate() + (days || 0), 0, 0, 0, 0).getTime();
}

function replicate(c, len) {
    return new Array(len + 1).join(c);
}

export default function WithOrdersOrders() {

    this.normalize = function (list) {
        return list.map(order => this.normalizeLine(order));
    };

    // noinspection DuplicatedCode,DuplicatedCode
    this.normalizeLine = function (order) {
        const {thumb, title, orientation} = this.getThumbUrl(order);

        order = $.extend({}, order);

        order.orderUrl = "/orders";
        order.override = order.override || {};
        order.override.td = order.override.td || {};


        if ([Order.ORDER_STATUS_ORDER, Order.ORDER_STATUS_PRINT_ON_DEMAND, Order.ORDER_STATUS_PUBLICATION].includes(order.status)) {
            order.href = {
                href: `${order.orderUrl}/${order.id}/artwork`,
                caption: order.caseNumber,
                title: title,
                target: '_blank'
            };
        } else {
            order.href = {
                href: null,
                caption: order.caseNumber,
                title: title
            };

            if ("contact" === order.status) {
                order.remarkCount = 1;
                order.clientLastRemark = true;
                order.remarkss = [{
                    "from": order.contact.email,
                    "body": order.contact.remarks,
                    "date": order.time,
                    "nostro": false
                }];
            }
        }

        if (!order.remarkCount) {
            delete order.remarkCount;
        }

        //order.contact = "xxx";

        if (order.handleBy) {
            order.handleBy = order.handleBy.substr(order.handleBy.indexOf(";") + 1);
        } else {
            delete order.handleBy;
        }

        order.override = order.override || {};
        order.override.td = order.override.td || {};
        order.override.td.orderStatus = "label-" + order.orderStatus;
        order.override.td.settled = "verify-" + order.isPaymentVerified + " " + (order.leftToPay < 0 ? 'refund' : '');
        order.statusBadge = {
            label: order.orderStatus,
            colour: OrderStatus.getBadgeColour(order.orderStatusInt)
        };
        order.reprint = order.reprint && order.itemCount > 0;
        order.customerGroupsList = order.member && order.member.customerGroupReferences ?
            order.member.customerGroupReferences.map(customerGroup => customerGroup.name) :
            [];
        order.artwork = [];

        const re = /[0-9]{2}:[0-9]{2}:[0-9]{2}.+$/;
        if (order.remarkss && order.remarkss.length) {
            order.remarkData = {
                value: order.clientLastRemark ? 2 : 1,
                count: order.remarkCount,
                clientLastRemark: order.clientLastRemark,
                list: order.remarkss.map(remark => {
                    remark.date = remark.date ? remark.date : new Date();
                    return remark;
                })
            };
        }
        order.shop = this.shops[order.shopId] && this.shops[order.shopId].icon;

        if (order.item) {
            // New strategy: aggregation+ListOrderEntity
            const o = {};
            if (order.isArtworkReady) {
                o.MAP_READY = 1;
            } else {
                o.MAP_EMPTY = 1;
            }
            order.artwork.push(o);
        } else if (order.items) {
            // Old strategy: ListOrder
            order.items.forEach(item => {
                const o = {};
                /* jshint ignore:start */
                if (item.map?.ready) {
                    o.MAP_READY = 1;
                } else {
                    if (this.isMapNotEmpty(item.map)) {
                        o.MAP_HALF_READY = 1;
                    } else {
                        o.MAP_EMPTY = 1;
                    }
                }
                /* jshint ignore:end */

                order.artwork.push(o);
            });
        }

        if (order.member) {
            order.user = {
                name: order.member.firstName + " " + order.member.lastName,
                email: order.member.email,
                phone: order.contact.phone,
                confirmedItemCount: order.member.confirmedItemCount,
                confirmedOrderCount: order.member.confirmedOrderCount,
                dateCreated: order.member.dateCreated,
                description: order.member.description,
                id: order.member.id,
                lastModifiedDate: order.member.lastModifiedDate,
                lastSigninDate: order.member.lastSigninDate,
                photo: order.member.photo,
                purchasesCount: order.member.purchasesCount,
                remark: order.member.remark,
                status: 1
            };
        } else {
            order.user = {
                name: order.contact.name,
                email: order.contact.email,
                phone: order.contact.phone
            };
        }

        if (thumb) {
            order.front = {
                src: thumb,
                href: `${order.orderUrl}/${order.id}/artwork`,
                title: title,
                target: "_blank"
            };
            order.override.td.front = "orient-" + orientation;
        }

        if (order.ntotal) {
            // noinspection JSUnresolvedVariable
            order.value = order.total - order.ntotal - order.delivery - order.vatSum || 0;
        }

        return order;
    };

    this.getThumbUrl = function (order) {
        const leadItem = order.items ? order.items[0] || false : order.item, // Supports both new (aggregation+ListOrderEntity) and old (ListOrder)
            map = leadItem && leadItem.map,
            page = map && map.pages[0],
            side = page && page.side ? "-" + page.side : "";

        if (page && page.file) {
            return {
                thumb: leadItem.thumbnailUrl,
                title: leadItem.name,
                orientation: map.orientation
            };
        }
        return {};
    };

}