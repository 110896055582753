import {getProductMetadata} from "./getProductMetadata";

export default WithCatalogPaperTypes;

function WithCatalogPaperTypes() {

    this.initCatalogPaperTypes = function() {
        if (!this.initiated) {
            this.on(document, "santaChange", this.setSanta);
            this.initiated = true;
        }
    };

    this.getProductMetadata = getProductMetadata;

    this.setSanta = function (event, data) {
        this.santa = data.query || data;
        // console.log("setSanta", this.santa);
    };

    /**
     * Returns the available papers for the specified paperFinish, productId, subProductId, and role.
     */
    this.getCatalogPaperWeights = function(paperFinish, productId, subProductId, role = 'body') {
        const substrateColour = this.getSubstrateColour(productId, subProductId, paperFinish, role);

        return substrateColour?.weights.map(weight => {
            // Convert the new data structure (mostly) to the old one for this function...
            const result = {
                id: weight.id,
                gsm: weight.gsm,
                label: weight.label,
                unit: weight.unit,
                active: true
            };
            switch (weight.unit) {
                case 'GSM':
                    // All set...
                    break;
                case 'LBS_TEXT':
                    result.libs = weight.weight;
                    result.type = 0;
                    break;
                case 'LBS_COVER':
                    result.libs = weight.weight;
                    result.type = 1;
                    break;
                case 'MICRONS':
                    result.microns = weight.weight;
                    break;
            }
            return result;
        });
    };

    this.getSubstrateType = function(productId, subProductId, substrateTypeId, role) {
        const productMetadata = this.getProductMetadata(productId, subProductId);
        switch (role) {
            case 'cover':
                return productMetadata?.coverSubstrateTypes.find(type => type.id === substrateTypeId);
            case 'envelope':
                return productMetadata?.envelopeSubstrateTypes.find(type => type.id === substrateTypeId);
            default:
                return productMetadata?.substrateTypes.find(type => type.id === substrateTypeId);
        }
    };

    this.getSubstrateColour = function(productId, subProductId, substrateTypeId, role) {
        const substrateType = this.getSubstrateType(productId, subProductId, substrateTypeId, role);
        return substrateType?.substrateColours[0]; // ATM all colours have the same data; this needs to change
    };

    this.getPageCountMetadata = function(productId, subProductId, substrateTypeId, role, substrateWeightId) {
        const substrateColour = this.getSubstrateColour(productId, subProductId, substrateTypeId, role),
                substrateWeight = substrateColour?.weights.find(weight => weight.id === substrateWeightId);
        return substrateWeight?.pageCounts;
    };

}