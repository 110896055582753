import mixam from '../../../boot/mixam';
import defineComponent from '../../../../components/flight/lib/component';
/*
 * TODO: replace! (old DataTable)
 *
 * NOTE: this code seems to be unreachable currently. It's only attached to a DOM element in
 * member.vm, which has been replaced (afaik) by the new My Account UI.
 */
import DataTable from './../../table/data-table';

// NOTE: this import looks unused, but it's very much needed!
import dd from 'dates';

export default defineComponent(MemberRevShareManager);

function MemberRevShareManager() {


    this.attributes({
        url: "/member/api/revshare/list",
        dataTableSelector: '[data-type="data-table"]'
    });


    this.createSchema = function () {
        var cols = [];

        cols.push({
            title: "front",
            type: "thumbnail",
            "width": "4%",
            data: "front"
        });

        cols.push({
            title: "Order",
            type: "link",
            "width": "9%",
            data: "href"
        });
        cols.push({
            title: "Created",
            type: "date",
            "width": "9%",
            data: "dateCreated"
        });

        cols.push({
            title: "Full Price",
            type: "number",
            "width": "9%",
            decimal: 2,
            data: "fullPrice"
        });

        cols.push({
            title: "Share",
            type: "number",
            "width": "9%",
            "append": "%",
            decimal: 2,
            data: "share"
        });

        cols.push({
            title: "Credit",
            type: "number",
            "width": "9%",
            decimal: 2,
            "prepand": "£",
            data: "credit"
        });

        cols.push({
            title: "Debit",
            type: "number",
            "width": "9%",
            decimal: 2,
            "prepand": "£",
            data: "debit"
        });

        return cols;
    };

    this.normalize = function (list) {
        return list.map(x => this.normalizeRevshare(x));
    };

    this.normalizeRevshare = function (revshare_) {
        const revshare = $.extend(true, {}, revshare_);
        revshare.override = revshare.override || {};
        revshare.override.td = revshare.override.td || {};
        revshare.front = {
            "src": revshare.thumbnailUrl,
            "href": `/orders/${revshare.orderId}/artwork`,
            title: revshare.caseNumber,
            "target": "_blank"
        };
        revshare.override.td.front = "orient-" + revshare.thumbnailOrientaion;
        revshare.href = {
            "href": `/orders/${revshare.orderId}/artwork`,
            "caption": revshare.caseNumber
        };
        if (revshare.type === "CREDIT") {
            revshare.credit = revshare.sum;
        } else {
            revshare.debit = revshare.sum;
        }
        return revshare;
    };

    this.paint = function () {
        const data = {
            columns: this.createSchema(),
            fileName: this.attr.url.split("/").pop(),
            response: this.normalize(this.data.list),
            "class": 'table-striped table-order-list',
            "default-sort": {
                "dateCreated": -1
            }
        };

        if (this.dataReadyFired) {
            window.dispatchEvent(new CustomEvent('uiDataUpdate', { detail: data }));
        } else {
            this.dataReadyFired = true;
            this.trigger("uiDataReady", data);
        }
    };

    /**
     * subscribe to STOMP service that notify us about changes
     * in a specific order and a specific item
     */
    this.subscribe = function (onMessage) {
        this.trigger("log", {
            message: ["Subscribe to stomp channel allirevenuesharechannel"],
            title: "MemberRevShareManager.subscribe"
        });
        this.stomp = Stomp.client(mixam.stompServiceUrl);

        this.stomp.debug = (...args) => {
            if (args.join('').indexOf('Whoops! Lost connection to') !== -1) {
                setTimeout(() => this.subscribe(onMessage), 10);
            }
            this.trigger("log", {message: args, title: "MemberRevShareManager.subscribe"});
        };


        this.stomp.connect(mixam.stompWebUser, mixam.stompWebPass, () => {
            this.stomp.subscribe("/topic/allirevenuesharechannel", (d) => {
                const p = JSON.parse(d.body);
                onMessage(p);
            });
        }, (err) => this.trigger("log", err), '/');
    };

    this.updateRevShareLine = function (data) {
        if (!this.data) {
            return;
        }
        let verb = data.type;
        const mergeJob = data.data;
        if (mergeJob.publisherId !== this.publisherId) {
            return;
        }
        if (mergeJob) {
            const index = this.data.list.map(x => x.id).indexOf(mergeJob.id);

            if (verb === "UPDATE" || verb === "INSERT") {
                if (index !== -1) {
                    this.data.list[index] = mergeJob;
                } else {
                    this.data.list.unshift(mergeJob);
                }

            } else if (verb === "DELETE" && index !== -1) {
                this.data.list.splice(index, 1);
            }

            this.paint();
            requestAnimationFrame(() => $("#PK" + mergeJob.id).hilightTableLine());
        }
    };

    this.getData = function () {
        $.getJSON(this.attr.url, {publisherId: this.publisherId})
            .done(data => {
                this.data = data;
                this.paint();
                setTimeout(() => this.getData(), 1000 * 60 * 15);
            })
            .fail((err) => this.trigger("log", {message: err}));
    };

    this.after('initialize', function () {
        DataTable.attachTo(this.select('dataTableSelector'));
        this.publisherId = this.$node.data('publisherId');

        setTimeout(() => this.getData(), 10);
        this.subscribe(d => this.updateRevShareLine(d));
    });
}
