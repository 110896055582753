var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useEffect, useState } from 'react';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Formik, Form as FormikForm } from 'formik';
import { Spinner, Alert, Button, Form, Card, Table, Modal, Row, Col } from 'react-bootstrap';
import axios from 'axios';
import ConfirmationModal from './ConfirmationModal';
var ItemTypes = {
    ROW: 'row',
};
var LaminationsMetadataEditor = function (_a) {
    var _b;
    var productId = _a.productId, subProductId = _a.subProductId, santaType = _a.santaType, laminations = _a.laminations, frontAvailable = _a.frontAvailable, backAvailable = _a.backAvailable, coverAvailable = _a.coverAvailable, dustJacketAvailable = _a.dustJacketAvailable;
    var _c = useState(false), isLoading = _c[0], setLoading = _c[1];
    var _d = useState(null), error = _d[0], setError = _d[1];
    var _e = useState(null), successMessage = _e[0], setSuccessMessage = _e[1];
    var _f = useState(null), metadataDocumentWrapper = _f[0], setMetadataDocumentWrapper = _f[1];
    var _g = useState(false), useCustom = _g[0], setUseCustom = _g[1];
    var _h = useState(false), showModal = _h[0], setShowModal = _h[1];
    var _j = useState(false), showConfirmationModal = _j[0], setShowConfirmationModal = _j[1];
    var _k = useState(null), modalOptionType = _k[0], setModalOptionType = _k[1];
    var _l = useState(''), newLamination = _l[0], setNewLamination = _l[1];
    useEffect(function () {
        setLoading(true);
        axios
            .get("/api/admin/metadata/product/laminations/products/".concat(productId, "/sub-products/").concat(subProductId, "/santa-types/").concat(santaType))
            .then(function (response) {
            setMetadataDocumentWrapper(response.data);
            if (subProductId !== 0) {
                setUseCustom(response.data.productMetadataDocument !== null);
            }
            setLoading(false);
        })
            .catch(function () {
            setLoading(false);
            setError('Failed to load lamination metadata document.');
        });
    }, [productId, subProductId, santaType]);
    if (isLoading) {
        return React.createElement(Spinner, { animation: "grow", variant: "primary" });
    }
    if (!metadataDocumentWrapper) {
        return React.createElement(Alert, { variant: "danger" }, "No Lamination Metadata available");
    }
    var activeDocument = useCustom
        ? metadataDocumentWrapper === null || metadataDocumentWrapper === void 0 ? void 0 : metadataDocumentWrapper.productMetadataDocument
        : metadataDocumentWrapper === null || metadataDocumentWrapper === void 0 ? void 0 : metadataDocumentWrapper.defaultProductMetadataDocument;
    var isEditable = subProductId === 0 || useCustom;
    var saveLaminationsMetadata = function (values, setSubmitting) {
        var _a;
        setLoading(true);
        setError(null);
        setSuccessMessage(null);
        var newDocument = activeDocument
            ? __assign({}, activeDocument) : {
            shopId: (_a = metadataDocumentWrapper === null || metadataDocumentWrapper === void 0 ? void 0 : metadataDocumentWrapper.defaultProductMetadataDocument) === null || _a === void 0 ? void 0 : _a.shopId,
            productId: productId,
            subProductId: subProductId,
            santaType: santaType
        };
        var transformOptions = function (options) {
            return options.map(function (option) {
                if (option.sameAsFront) {
                    return { lamination: null, sameAsFront: true, santaDefault: option.santaDefault };
                }
                return __assign(__assign({}, option), { sameAsFront: false });
            });
        };
        var saveRequest = {
            useDefault: !useCustom,
            laminationMetadataDocument: __assign(__assign({}, newDocument), { frontOptions: transformOptions(values.frontOptions), backOptions: transformOptions(values.backOptions), coverOptions: transformOptions(values.coverOptions), dustJacketOptions: transformOptions(values.dustJacketOptions) }),
        };
        axios
            .post("/api/admin/metadata/product/laminations/products/".concat(productId, "/sub-products/").concat(subProductId, "/santa-types/").concat(santaType), saveRequest)
            .then(function (response) {
            setMetadataDocumentWrapper(response.data);
            setLoading(false);
            setSubmitting(false);
            setSuccessMessage('Lamination Metadata saved successfully!');
        })
            .catch(function (error) {
            var _a;
            setLoading(false);
            setSubmitting(false);
            setError("Failed to save lamination metadata: ".concat(((_a = error.response) === null || _a === void 0 ? void 0 : _a.data) || error.message));
        });
    };
    var handleSave = function (values, setSubmitting) {
        if (!useCustom && subProductId !== 0) {
            setShowConfirmationModal(true);
        }
        else {
            saveLaminationsMetadata(values, setSubmitting);
        }
    };
    var handleCopyFromDefault = function () {
        var _a;
        if (metadataDocumentWrapper === null || metadataDocumentWrapper === void 0 ? void 0 : metadataDocumentWrapper.defaultProductMetadataDocument) {
            var defaultData = metadataDocumentWrapper.defaultProductMetadataDocument;
            var updatedCustomDocument = __assign(__assign({}, metadataDocumentWrapper.productMetadataDocument || {
                shopId: (_a = metadataDocumentWrapper === null || metadataDocumentWrapper === void 0 ? void 0 : metadataDocumentWrapper.defaultProductMetadataDocument) === null || _a === void 0 ? void 0 : _a.shopId,
                productId: productId,
                subProductId: subProductId,
                santaType: santaType,
            }), { frontOptions: defaultData.frontOptions || [], backOptions: defaultData.backOptions || [], coverOptions: defaultData.coverOptions || [], dustJacketOptions: defaultData.dustJacketOptions || [] });
            setMetadataDocumentWrapper(__assign(__assign({}, metadataDocumentWrapper), { productMetadataDocument: updatedCustomDocument }));
        }
    };
    var handleAddOption = function (type) {
        setModalOptionType(type);
        setNewLamination(laminations[0]);
        setShowModal(true);
    };
    var handleModalSave = function (setFieldValue, values) {
        if (modalOptionType) {
            var newOption = void 0;
            if (newLamination === 'SAME_AS_FRONT') {
                newOption = { lamination: null, santaDefault: false, sameAsFront: true };
            }
            else {
                newOption = { lamination: newLamination, santaDefault: false, sameAsFront: false };
            }
            var existingOptions = values[modalOptionType] || [];
            var isDuplicate = existingOptions.some(function (option) {
                if (option.sameAsFront && newLamination === 'SAME_AS_FRONT')
                    return true;
                return option.lamination === newLamination;
            });
            if (isDuplicate) {
                setError('This option already exists in the list.');
            }
            else {
                setFieldValue(modalOptionType, __spreadArray(__spreadArray([], existingOptions, true), [newOption], false));
                setShowModal(false);
                setError(null);
            }
        }
    };
    var handleRemoveOption = function (type, index, setFieldValue, values) {
        var updatedOptions = values[type].filter(function (_, i) { return i !== index; });
        setFieldValue(type, updatedOptions);
    };
    var isOptionDisabled = function (option, options) {
        return options.some(function (opt) {
            if (opt.sameAsFront && option === 'SAME_AS_FRONT')
                return true;
            return opt.lamination === option;
        });
    };
    var moveRow = function (type, dragIndex, hoverIndex, setFieldValue, values) {
        var updatedOptions = __spreadArray([], values[type], true);
        var removed = updatedOptions.splice(dragIndex, 1)[0];
        updatedOptions.splice(hoverIndex, 0, removed);
        setFieldValue(type, updatedOptions);
    };
    var DraggableRow = function (_a) {
        var index = _a.index, type = _a.type, option = _a.option, setFieldValue = _a.setFieldValue, values = _a.values;
        var ref = React.useRef(null);
        var _b = useDrop({
            accept: ItemTypes.ROW,
            hover: function (item) {
                if (!ref.current) {
                    return;
                }
                var dragIndex = item.index;
                var hoverIndex = index;
                if (item.type !== type || dragIndex === hoverIndex) {
                    return;
                }
                moveRow(type, dragIndex, hoverIndex, setFieldValue, values);
                item.index = hoverIndex;
            },
        }), drop = _b[1];
        var _c = useDrag({
            type: ItemTypes.ROW,
            item: { type: type, index: index },
            collect: function (monitor) { return ({
                isDragging: monitor.isDragging(),
            }); },
        }), isDragging = _c[0].isDragging, drag = _c[1];
        drag(drop(ref));
        return (React.createElement("tr", { ref: ref, style: { opacity: isDragging ? 0.5 : 1 } },
            React.createElement("td", null, option.sameAsFront ? 'SAME AS FRONT' : option.lamination),
            React.createElement("td", null,
                React.createElement(Form.Check, { type: "radio", name: "".concat(type, "SantaDefault"), checked: option.santaDefault, onChange: function () {
                        setFieldValue(type, values[type].map(function (opt, i) { return (__assign(__assign({}, opt), { santaDefault: i === index })); }));
                    }, disabled: !isEditable })),
            React.createElement("td", { className: "text-end" },
                React.createElement(Button, { className: "btn-sm", variant: "danger", onClick: function () { return handleRemoveOption(type, index, setFieldValue, values); }, disabled: !isEditable }, "Remove"))));
    };
    return (React.createElement("div", null,
        React.createElement(DndProvider, { backend: HTML5Backend },
            React.createElement(Formik, { initialValues: {
                    frontOptions: (activeDocument === null || activeDocument === void 0 ? void 0 : activeDocument.frontOptions) || [],
                    backOptions: ((_b = activeDocument === null || activeDocument === void 0 ? void 0 : activeDocument.backOptions) === null || _b === void 0 ? void 0 : _b.map(function (option) { return (__assign(__assign({}, option), { lamination: option.sameAsFront ? null : option.lamination })); })) || [],
                    coverOptions: (activeDocument === null || activeDocument === void 0 ? void 0 : activeDocument.coverOptions) || [],
                    dustJacketOptions: (activeDocument === null || activeDocument === void 0 ? void 0 : activeDocument.dustJacketOptions) || [],
                }, enableReinitialize: true, onSubmit: handleSave }, function (_a) {
                var isSubmitting = _a.isSubmitting, values = _a.values, setFieldValue = _a.setFieldValue, setSubmitting = _a.setSubmitting;
                return (React.createElement(React.Fragment, null,
                    React.createElement(Card, { className: "p-4 shadow-sm bg-light" },
                        React.createElement(FormikForm, null,
                            React.createElement("h5", { className: "mb-4" }, "Laminations Metadata"),
                            successMessage && (React.createElement(Alert, { variant: "success", onClose: function () { return setSuccessMessage(null); }, dismissible: true }, successMessage)),
                            error && (React.createElement(Alert, { variant: "danger", onClose: function () { return setError(null); }, dismissible: true }, error)),
                            subProductId !== 0 && (React.createElement(Form.Group, { className: "mb-4" },
                                React.createElement(Form.Check, { type: "radio", label: "Use Default Metadata", checked: !useCustom, onChange: function () { return setUseCustom(false); } }),
                                React.createElement(Form.Check, { type: "radio", label: "Customize Metadata", checked: useCustom, onChange: function () { return setUseCustom(true); } }),
                                React.createElement("hr", null),
                                useCustom && (React.createElement(Button, { className: "mt-1 btn-sm", variant: "secondary", onClick: handleCopyFromDefault }, "Copy Default Values")))),
                            React.createElement(Row, null,
                                React.createElement(Col, { md: 6 },
                                    frontAvailable && (React.createElement(React.Fragment, null,
                                        React.createElement("h5", { className: "mt-4" }, "Front Options"),
                                        React.createElement(Table, { bordered: true, hover: true, variant: "light" },
                                            React.createElement("thead", null,
                                                React.createElement("tr", null,
                                                    React.createElement("th", null, "Lamination"),
                                                    React.createElement("th", null, "Default"),
                                                    React.createElement("th", null))),
                                            React.createElement("tbody", null, values.frontOptions.map(function (option, index) { return (React.createElement(DraggableRow, { key: index, index: index, type: "frontOptions", option: option, setFieldValue: setFieldValue, values: values })); }))),
                                        React.createElement("div", { className: "text-end" },
                                            React.createElement(Button, { variant: "secondary", className: "btn-sm", onClick: function () { return handleAddOption('frontOptions'); }, disabled: !isEditable }, "Add Lamination")))),
                                    coverAvailable && (React.createElement(React.Fragment, null,
                                        React.createElement("h5", { className: "mt-4" }, "Cover Options"),
                                        React.createElement(Table, { bordered: true, hover: true, variant: "light" },
                                            React.createElement("thead", null,
                                                React.createElement("tr", null,
                                                    React.createElement("th", null, "Lamination"),
                                                    React.createElement("th", null, "Default"),
                                                    React.createElement("th", null))),
                                            React.createElement("tbody", null, values.coverOptions.map(function (option, index) { return (React.createElement(DraggableRow, { key: index, index: index, type: "coverOptions", option: option, setFieldValue: setFieldValue, values: values })); }))),
                                        React.createElement("div", { className: "text-end" },
                                            React.createElement(Button, { variant: "secondary", className: "btn-sm", onClick: function () { return handleAddOption('coverOptions'); }, disabled: !isEditable }, "Add Lamination"))))),
                                React.createElement(Col, { md: 6 },
                                    backAvailable && (React.createElement(React.Fragment, null,
                                        React.createElement("h5", { className: "mt-4" }, "Back Options"),
                                        React.createElement(Table, { bordered: true, hover: true, variant: "light" },
                                            React.createElement("thead", null,
                                                React.createElement("tr", null,
                                                    React.createElement("th", null, "Lamination"),
                                                    React.createElement("th", null, "Default"),
                                                    React.createElement("th", null))),
                                            React.createElement("tbody", null, values.backOptions.map(function (option, index) { return (React.createElement(DraggableRow, { key: index, index: index, type: "backOptions", option: option, setFieldValue: setFieldValue, values: values })); }))),
                                        React.createElement("div", { className: "text-end" },
                                            React.createElement(Button, { variant: "primary", className: "btn-sm", onClick: function () { return handleAddOption('backOptions'); }, disabled: !isEditable }, "Add Lamination")))),
                                    dustJacketAvailable && (React.createElement(React.Fragment, null,
                                        React.createElement("h5", { className: "mt-4" }, "Dust Jacket Options"),
                                        React.createElement(Table, { bordered: true, hover: true, variant: "light" },
                                            React.createElement("thead", null,
                                                React.createElement("tr", null,
                                                    React.createElement("th", null, "Lamination"),
                                                    React.createElement("th", null, "Default"),
                                                    React.createElement("th", null))),
                                            React.createElement("tbody", null, values.dustJacketOptions.map(function (option, index) { return (React.createElement(DraggableRow, { key: index, index: index, type: "dustJacketOptions", option: option, setFieldValue: setFieldValue, values: values })); }))),
                                        React.createElement("div", { className: "text-end" },
                                            React.createElement(Button, { variant: "secondary", className: "btn-sm", onClick: function () { return handleAddOption('dustJacketOptions'); }, disabled: !isEditable }, "Add Lamination")))))),
                            React.createElement(Button, { type: "submit", disabled: isSubmitting, variant: "primary", className: "mt-4 px-4 py-2" }, "Save Changes"))),
                    React.createElement(Modal, { show: showModal, onHide: function () { return setShowModal(false); } },
                        React.createElement(Modal.Header, { closeButton: true },
                            React.createElement(Modal.Title, null, "Add New Lamination Option")),
                        React.createElement(Modal.Body, null,
                            React.createElement(Form, null,
                                React.createElement(Form.Group, null,
                                    React.createElement(Form.Label, null, "Select Lamination Type"),
                                    React.createElement(Form.Control, { as: "select", value: newLamination, onChange: function (e) { return setNewLamination(e.target.value); } },
                                        Object.values(laminations).map(function (lamination) { return (React.createElement("option", { key: lamination, value: lamination, disabled: isOptionDisabled(lamination, values[modalOptionType] || []) }, lamination)); }),
                                        modalOptionType === 'backOptions' && (React.createElement("option", { value: "SAME_AS_FRONT", disabled: isOptionDisabled('SAME_AS_FRONT', values[modalOptionType] || []) }, "SAME AS FRONT")))))),
                        React.createElement(Modal.Footer, null,
                            React.createElement(Button, { variant: "secondary", onClick: function () { return setShowModal(false); } }, "Close"),
                            React.createElement(Button, { variant: "primary", onClick: function () { return handleModalSave(setFieldValue, values); } }, "Add Option"))),
                    React.createElement(ConfirmationModal, { show: showConfirmationModal, onHide: function () { return setShowConfirmationModal(false); }, onConfirm: function () {
                            setShowConfirmationModal(false);
                            saveLaminationsMetadata(values, setSubmitting);
                        } })));
            }))));
};
export default LaminationsMetadataEditor;
