import canUseDOM from '../util/inDOM';
var on = function on() {
};

if (canUseDOM) {
  on = (function () {

    if (document.addEventListener) {
      return function (node, eventName, handler, capture) {
        return node.addEventListener(eventName, handler, capture || false);
      };
    } else if (document.attachEvent) {
      return function (node, eventName, handler) {
        return node.attachEvent('on' + eventName, handler);
      };
    }
  })();
}

export default on;