import defineComponent from '../../../../components/flight/lib/component';
import React from 'react';
import ReactDom from 'react-dom/client';
import TinyUrlEditor from '../React/TinyUrlEditor/TinyUrlEditor.tsx';

export default defineComponent(AdminTinyUrlEditor);

function AdminTinyUrlEditor() {

    this.initEditor = function () {
        const editor = $(this.node);
        const root = ReactDom.createRoot(editor.get(0));
        root.render(
            <TinyUrlEditor
                tinyUrlId={editor.data('tinyurl-id')}
                secureDomain={editor.data('secure-domain')}
            ></TinyUrlEditor>
        );
    };

    this.after('initialize', function () {
        this.initEditor();
    });

}
