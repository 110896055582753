
class Page {
    constructor(id, box) {
        this.id = id;
        this.plates = 4;
        this.doubleSpread = false;
        this.hits = null;
        this.fixups = null;
        this.plateName = "Cyan,Magenta,Yellow,Black";
        this.rotate = 0;
        this.trimBox = box;
        this.bleedBox = box;
        this.mediaBox = box;
        this.cropBox = box;
    }
}

export default Page;

