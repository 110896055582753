import defineComponent from '../../../../../components/flight/lib/component';
import WithDashboardComponent from '../../../with-dashbord-component';
import Chartjs from '../../../../../components/Chart.js';

export default defineComponent(AnalyticsUploads, WithDashboardComponent);

function AnalyticsUploads() {
    var MARKER_COLORS = {uploads: "#F7464A", "dropins": "#fdb45c", "imports": "#46BFBD"},
        MARKER_COLORS_HIGHLIGHT = {uploads: "#FF5A5E", "dropins": "#fc9d2a", "imports": "#5AD3D1"};

    this.attributes({
        field: "count",
        chartSelector: '.uploads-chart',
        clipperSelector: '.donat-clipper'
    });


    this.isInteresting = function (counter) {
        return counter.cycle === "DAILY" && (counter.type === "DROPIN" || counter.type === "UPLOAD" || counter.type === "IMPORT");
    };

    this.reset = function () {
        this.counters.UPLOAD = this.createCounter("UPLOAD");
        this.counters.IMPORT = this.createCounter("IMPORT");
        this.counters.DROPIN = this.createCounter("DROPIN");
        this.counters.TOTAL = this.createCounter("TOTAL");
        this.display(true);
    };

    this.hasValue = function () {
        var i;

        for (i = 0; i < this.types.length; i++) {
            if (this.counters[this.types[i].counter].count) {
                return true;
            }
        }
    };

    this.display = function (isSilent) {
        if (this.timer) {
            clearTimeout(this.timer);
        }
        this.timer = setTimeout(() => {
            this.timer = null;
            if (this.chart && this.hasValue()) {
                this.types.forEach(type => this.chart.segments[this.index[type.counter]].value = this.counters[type.counter].count);
                this.select('clipperSelector').addClass("in");
                this.chart.update();
            } else {
                this.select('clipperSelector').removeClass("in");
            }

            this.counters.TOTAL.value = this.counters.TOTAL.count = this.counters.UPLOAD.count + this.counters.DROPIN.count;

            Object.keys(this.counters).forEach(key => this.updateUiCounters(this.counters[key], isSilent));
        }, 200);
    };


    this.init = function () {
        var that = this,
            ctx = this.select('chartSelector').get(0).getContext("2d");

        this.index = {};
        /** @namespace Chartjs.Pie */
        this.chart = new Chartjs(ctx).Pie(this.types.map(function (type, index) {
            var typelc = type.name.toLowerCase();
            that.index[type.counter] = index;
            return {
                value: 1,
                color: MARKER_COLORS[typelc],
                highlight: MARKER_COLORS_HIGHLIGHT[typelc],
                label: type.name
            };
        }));
    };


    this.after('initialize', function () {
        this.types = [{name: "Uploads", counter: "UPLOAD"},
            {name: "Imports", counter: "IMPORT"},
            {name: "Dropins", counter: "DROPIN"}
        ];
        this.counters = {};

        this.init();
        this.attr.field = this.$node.data("field");
        this.reset();
        this.on(document, "dashbordReset", this.reset);
        this.on(document, "dashbordCounterUpdate", this.counterUpdate);
    });
}