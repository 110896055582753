export default {
    "0": "Other issue",
    "1": "Package did not arrive",
    "2": "Missing items",
    "3": "Arrived damaged",
    "4": "Delivery issue",
    "5": "Binding issue",
    "6": "Colour variance",
    "7": "Ink marks",
    "8": "Finish quality",
    "9": "Printing quality",
    "10": "Marks",
    "11": "Shortage",
    "12": "Trimmed incorrectly",
    "13": "Wrong order",
    "14": "Incorrect paper",
    "15": "Incorrect page order",
    "16": "White strips on edges",
    "17": "Unknown content",
    "18": "Different to what i ordered"
};
