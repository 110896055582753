import mixam from '../../../../boot/mixam';
import defineComponent from '../../../../../components/flight/lib/component';
import Mustache from '../../../../../components/mustache/mustache';
import usersTableTemplate from 'text!../../../../templates/admin/analytics/current-users-table.mustache';
import tabUsersTableTemplate from 'text!../../../../templates/admin/analytics/tab-images.mustache';

export default defineComponent(AdminAnalyticsUsersList);

function makeUnique(arr) {
    const map = {};
    arr.forEach(v => map[v.user.email] = v);
    return Object.keys(map).map(key => map[key]);
}

function AdminAnalyticsUsersList() {
    this.attributes({
        counterElement: "",
        filter: "",
        contentSelector: '.content',
        tabCurrentUsersSelector: '.tab-current-users',
        adminTabTitleSelector: '.admin-tab-title',
        moreSelector: '[data-more-for]'
    });

    this.display = function () {
        this.select('contentSelector').empty().html(this.render(this.visits));
    };

    this.render = function (list) {
       // console.log("this.render", list);
        return Mustache.render(usersTableTemplate, {list: list});
    };

    this.getSessionIndex = function (sessionId) {
        this.visits = this.visits || [];
        const len = this.visits.length;
        for (let i = 0; i < len; i++) {
            if (this.visits[i].id === sessionId) {
                return i;
            }
        }
        return -1;
    };

    this.displaySession = function (session) {
        const $div = $("[data-session='" + session.id + "']");

        if ($div.length) {
            const $new = $(this.render([session]));
            $div.replaceWith($new);
            $new.find("table  tr:last-of-type table tr:last-of-type").hilightTableLine();
        } else {
            $(this.render([session])).prependTo(this.select("contentSelector")).hilightElement();
        }
    };

    this.filterList = function (originalList) {
        const result = [];

        if (originalList && originalList.length) {
            originalList.forEach(session => {
                if (session?.user?.type === this.attr.filter || session?.user?.type === -1) { // jshint ignore:line
                    result.push(session);
                }
            });
        }
        return result;
    };

    this.onVisitsListChange = function (event, data) {
        this.visits = this.filterList(data.sessions);
        this.updateVisitsCounter();
        this.updateTabImages();
        this.display();
    };

    this.onVisitsListDelete = function (event, data) {
        const list = this.filterList(data.sessions);

        if (list.length) {
            list.forEach(session => {
                $("[data-session = '" + session.id + "']").remove();
                const originalSessionIndex = this.getSessionIndex(session.id);

                if (originalSessionIndex !== -1) {
                    this.visits.splice(originalSessionIndex, 1);
                }
            });
            this.updateVisitsCounter();
            this.updateTabImages();
        }
    };

    this.updateVisitsCounter = function () {
        if (this.visits.length) {
            this.attr.counterElement.text(this.visits.length);
            this.attr.counterElement.removeClass("hidden");
        } else {
            this.attr.counterElement.addClass("hidden");
        }
        this.updateTabImages();
    };

    this.onVisitsListUpsert = function (event, data) {
        const list = this.filterList(data.sessions);

        if (list.length) {
            list.forEach(session => {
                const originalSessionIndex = this.getSessionIndex(session.id);

                session.lastModified = new Date();
                session.ipList.forEach(ip => ip.userList.forEach(u => u.views.forEach(v => {
                    if (v.url.indexOf('/orders/') !== -1 || v.url.indexOf('/share/') !== -1) {
                        v.needLink = true;
                    }
                })));
                if (originalSessionIndex !== -1) {
                    this.visits[originalSessionIndex] = session;
                } else {
                    this.visits.unshift(session);
                }
                this.displaySession(session);
            });
            this.updateVisitsCounter();
            this.updateTabImages();
        }
    };

    this.updateTabImages = function () {
        if (this.attr.filter === 'mixam') {
            const photos = Mustache.render(tabUsersTableTemplate, {list: makeUnique(this.visits)});
            $(this.attr.tabCurrentUsersSelector).empty().html(photos);
            if (this.visits && this.visits && this.visits.length > 2) {
                $(this.attr.adminTabTitleSelector).addClass('hidden');
            } else {
                $(this.attr.adminTabTitleSelector).removeClass('hidden');
            }
        }
    };


    this.showMore = function (event) {
        const moreFor = $(event.target).closest("button").data("moreFor");
        $("[data-for-session=" + moreFor + "]").toggle();
    };

    this.after('initialize', function () {
        this.visits = [];
        this.on(document, "visitsListChangeNormalized", this.onVisitsListChange);
        this.on(document, "visitsListDelete", this.onVisitsListDelete);
        this.on(document, "visitsListUpsert", this.onVisitsListUpsert);
        this.on("click", {
            moreSelector: this.showMore
        });
    });
}
