import defineComponent from '../../../../components/flight/lib/component';
import DataTable from "../../table/data-table-net/data-table-net.tsx";

export default defineComponent(AdminMembersExporter);

function AdminMembersExporter() {

    this.attributes({
        url: "/admin/api/member/export",
        dataTableSelector: '[data-type="data-table"]',
        formSelector: 'form',
        updatesSelector: '[data-action="updates"]',
        saveBtnSelector: '[type="submit"]',
    });

    this.createSchema = function () {
        const cols = [];

        cols.push({
            title: "Created",
            type: "date",
            data: "dateCreated",
            defaultSort: true,
            sortOrder: 'desc'
        });

        cols.push({
            title: "Created By",
            type: "text",
            data: "createdBy",
        });

        cols.push({
            title: "Status",
            type: "text",
            data: "status",
        });

        cols.push({
            title: "",
            type: "button",
            data: "action"
        });

        return cols;
    };

    this.paint = function () {
        const data = {
            columns: this.createSchema(),
            response: this.normalize(this.data)
        };

        if (this.dataReadyFired) {
            window.dispatchEvent(new CustomEvent('uiDataUpdate', { detail: data }));
        } else {
            this.dataReadyFired = true;
            this.trigger("uiDataReady", data);
        }
    };

    this.normalize = function (list) {
        return list.map(x => this.normalizeJob(x));
    };

    this.normalizeJob = function (job_) {
        const job = $.extend(true, {}, job_);
        // Note. The Date Is A Java LocalDateTime...
        const [year, month, day, hour, minute, second, millisecond] = job_.createdDate;
        job.dateCreated = new Date(year, month - 1, day, hour, minute, second, millisecond / 1000000).getTime();
        job.action = {
            href: job.url,
            caption: 'Download',
            target: "_blank",
            className: `btn btn-primary position-absolute end-0 ${job.status !== 'COMPLETE' ? 'disabled' : ''}`,
            iconClassname: "bi bi-download"
        };
        return job;
    };

    this.getData = function () {
        $.getJSON(this.attr.url)
            .done(data => {
                this.data = data;
                this.paint();
            })
            .fail((err) => this.trigger("log", {message: err}));
    };

    this.after('initialize', function () {
        DataTable.attachTo(this.select('dataTableSelector'));
        setTimeout(() => this.getData(), 10);
    });
}
