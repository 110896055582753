import mixam from '../../boot/mixam';
import defineComponent from '../../../components/flight/lib/component';
import PreviewPanel from './preview-panel';
import regional from '../../boot/regional';

export default defineComponent(PreviewManager);

function PreviewManager() {

    this.attributes({
        panelSelector: '[data-type="preview-panel"]',
        previewOrderIdSelector: '#preview-orderId',
        previewItemIdSelector: '#preview-itemId'
    });

    this.getOrder = function () {
        $.getJSON(mixam.springUrl("api/orders/") + this.orderId)
            .done( response => {
                this.trigger("setPreviewData", {
                    order: response,
                    itemId: this.itemId
                });
            })
            .fail( response => {
                this.trigger('showFixedMessage', {message: regional().messages[1035]});
            });
    };

    this.after('initialize', function () {
        this.orderId = this.select('previewOrderIdSelector').val();
        this.itemId = this.select('previewItemIdSelector').val();

        PreviewPanel.attachTo(this.select('panelSelector'));

        if (this.orderId && this.itemId) {
            this.getOrder();
        }
    });
}
