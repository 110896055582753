import React, { useEffect, useState } from 'react';
import axios from "axios";
import { ChargeItemTypeMap } from "./types";
import { ChargeItemType, CurrencyMap } from "@mixam-platform/mixam-types";
import { Formik, Field, FieldArray, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { ChargeItemTable } from "./styled";
var validationSchema = Yup.object().shape({
    chargeItems: Yup.array().of(Yup.object().shape({
        description: Yup.string().optional(),
        nprice: Yup.number().required('Cost is required'),
        price: Yup.number().required('Price is required'),
    })),
});
export var ChargeItemsManager = function (props) {
    var _a = useState(null), chargeItemData = _a[0], setChargeItemData = _a[1];
    useEffect(function () {
        axios.get("/api/orders/".concat(props.orderId, "/items/").concat(props.itemId, "/charge-items"))
            .then(function (response) {
            setChargeItemData(response.data);
        });
    }, []);
    function formatCurrency(amount) {
        return new Intl.NumberFormat(props.locale, { style: 'currency', currency: CurrencyMap[props.locale] }).format(amount);
    }
    return (React.createElement(React.Fragment, null, chargeItemData == null ? (React.createElement(React.Fragment, null)) : (React.createElement(Formik, { initialValues: chargeItemData, validationSchema: validationSchema, onSubmit: function (values, actions) {
            axios.put("/api/orders/".concat(props.orderId, "/items/").concat(props.itemId, "/charge-items"), values.chargeItems)
                .then(function (response) {
                window.location.reload();
            })
                .catch(function (error) {
                console.error('Error saving charge items:', error);
            })
                .finally(function () {
                actions.setSubmitting(false);
            });
        } }, function (formikProps) { return (React.createElement("form", { onSubmit: formikProps.handleSubmit },
        React.createElement("h4", null, "Additional Charges"),
        React.createElement("table", { className: "table" },
            React.createElement("thead", null,
                React.createElement("th", null, "Cost"),
                React.createElement("th", null, "Weight"),
                React.createElement("th", null, "Price")),
            React.createElement("tbody", null,
                React.createElement("tr", null,
                    React.createElement("td", null, formatCurrency(chargeItemData.costPrice)),
                    React.createElement("td", null, chargeItemData.weight.toFixed(2)),
                    React.createElement("td", null, formatCurrency(chargeItemData.totalPrice))))),
        React.createElement("table", { className: "table" },
            React.createElement(FieldArray, { name: "chargeItems", render: function (arrayHelpers) { return (React.createElement(React.Fragment, null,
                    React.createElement("tbody", null, formikProps.values.chargeItems && formikProps.values.chargeItems.map(function (chargeItem, index) { return (React.createElement("tr", { key: index },
                        React.createElement(ChargeItemTable, { className: "table" },
                            React.createElement("thead", null,
                                React.createElement("tr", null,
                                    React.createElement("th", null, "Type"),
                                    React.createElement("th", null, "Cost"),
                                    React.createElement("th", null, "Price"))),
                            React.createElement("tbody", null,
                                React.createElement("tr", null,
                                    React.createElement("td", null,
                                        React.createElement("div", { className: "control-size-frame" },
                                            React.createElement("div", { className: "clearfix" },
                                                React.createElement("span", { className: "select-bkg" },
                                                    React.createElement("select", { name: "chargeItems[".concat(index, "].type"), value: chargeItem.type, onChange: formikProps.handleChange }, Object.values(ChargeItemType).map(function (type, typeIndex) { return (React.createElement("option", { key: typeIndex, value: type }, ChargeItemTypeMap[type])); })))))),
                                    React.createElement("td", null,
                                        React.createElement(Field, { type: "number", name: "chargeItems[".concat(index, "].nprice"), className: "form-control", step: "0.01" }),
                                        React.createElement(ErrorMessage, { name: "chargeItems[".concat(index, "].nprice"), component: "div", className: "error-message" })),
                                    React.createElement("td", null,
                                        React.createElement(Field, { type: "number", name: "chargeItems[".concat(index, "].price"), className: "form-control", step: "0.01" }),
                                        React.createElement(ErrorMessage, { name: "chargeItems[".concat(index, "].price"), component: "div", className: "error-message" }))),
                                React.createElement("tr", null,
                                    React.createElement("td", { colSpan: 3 },
                                        "Additional Details",
                                        React.createElement(Field, { type: "text", name: "chargeItems[".concat(index, "].description"), className: "form-control" }),
                                        React.createElement(ErrorMessage, { name: "chargeItems[".concat(index, "].description"), component: "div", className: "error-message" }))),
                                React.createElement("tr", null,
                                    React.createElement("td", { colSpan: 3 },
                                        React.createElement("button", { className: "btn btn-primary", "aria-hidden": "true", onClick: function () { return arrayHelpers.remove(index); } },
                                            React.createElement("i", { className: "fa fa-trash-o", "aria-hidden": "true" }),
                                            " Remove"))))))); })),
                    React.createElement("div", { className: "row" },
                        React.createElement("div", { className: "col-md-6" },
                            React.createElement("button", { type: "button", className: "btn btn-product-1", onClick: function () {
                                    return arrayHelpers.push({
                                        type: ChargeItemType.OTHER,
                                        description: '',
                                        nprice: 0,
                                        price: 0,
                                        weight: 0,
                                    });
                                } },
                                React.createElement("i", { className: "fa fa-plus-circle" }),
                                " Add Additional Charge")),
                        React.createElement("div", { className: "col-md-6 text-right" },
                            React.createElement("button", { type: "submit", className: "btn btn-product-1" },
                                React.createElement("i", { className: "fa fa-check", "aria-hidden": "true" }),
                                " Apply Changes"))))); } })))); }))));
};
