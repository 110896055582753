import defineComponent from '../../../../components/flight/lib/component';
import DataTable from '../../table/data-table-net/data-table-net.tsx';

export default defineComponent(ApiRequestLog);

function ApiRequestLog() {

    this.attributes({
        url: "/api/admin/api-log/requests",
        dataTableSelector: '[data-type="data-table"]'
    });

    this.createSchema = function () {
        const cols = [];

        cols.push({
            title: 'User',
            type: 'link',
            data: 'memberLink'
        });

        cols.push({
            title: 'Request Date',
            type: 'timebox',
            data: 'requestDate',
            defaultSort: true,
            sortOrder: 'desc'
        });

        cols.push({
            title: 'Request URL',
            type: 'text',
            data: 'requestUrl'
        });

        cols.push({
            title: 'Method',
            type: 'text',
            data: 'method'
        });

        cols.push({
            title: 'Status',
            type: 'text',
            data: 'responseStatus'
        });

        cols.push({
            title: 'Details',
            type: 'api-log-modal',
            data: 'details'
        });

        return cols;
    };


    this.paint = function () {
        const data = {
            columns: this.createSchema(),
            response: this.normalize(this.data)
        };

        if (this.dataReadyFired) {
            window.dispatchEvent(new CustomEvent('uiDataUpdate', { detail: data }));
        } else {
            this.dataReadyFired = true;
            this.trigger("uiDataReady", data);
        }
    };

    this.normalize = function (list) {
        return list.map(x => this.normalizeLogEntry(x));
    };

    this.normalizeLogEntry = function (apiPost) {
        const result = $.extend(true, {}, apiPost);

        result.memberLink = {
            href: `/account/member/${result.memberId}`,
            caption: `${result.memberEmailAddress}`,
            title: `${result.memberEmailAddress}`,
            target: '_blank'
        };
        result.details = '';
        result.requestDate = result.requestDate.timestamp;

        return result;
    };


    this.getData = function () {
        $.getJSON(this.attr.url)
            .done(data => {
                this.data = data;
                this.paint();
            })
            .fail((err) => this.trigger("log", {message: err}));
    };

    this.after('initialize', function () {
        DataTable.attachTo(this.select('dataTableSelector'));
        setTimeout(() => this.getData(), 10);
    });
}
