import defineComponent from '../../../../../components/flight/lib/component';
import DataTable from '../../../table/data-table-net/data-table-net.tsx';

export default defineComponent(AdminSmsNotifications);

function AdminSmsNotifications() {

    this.attributes({
        url: "/api/admin/sms-notifications",
        dataTableSelector: '[data-type="data-table"]'
    });

    this.createSchema = function () {
        const cols = [];

        cols.push({
            title: 'Sent',
            type: 'timebox',
            data: 'dateSent',
            defaultSort: true,
            sortOrder: 'desc'
        });

        cols.push({
            title: 'Phone Number',
            type: 'text',
            data:'phoneNumber'
        });

        cols.push({
            title: 'Order',
            type: 'link',
            data: 'orderLink'
        });

        cols.push({
            title: 'Message',
            type: 'text',
            data: 'message'
        });

        return cols;
    };

    this.paint = function () {
        const data = {
            columns: this.createSchema(),
            response: this.normalize(this.data)
        };

        if (this.dataReadyFired) {
            window.dispatchEvent(new CustomEvent('uiDataUpdate', { detail: data }));
        } else {
            this.dataReadyFired = true;
            this.trigger("uiDataReady", data);
        }
    };

    this.normalize = function (list) {
        return list.map(x => this.normalizeSmsNotification(x));
    };

    this.normalizeSmsNotification = function (smsNotification_) {
        const smsNotification = $.extend(true, {}, smsNotification_);
        smsNotification.orderLink = {
            href: `/orders/${smsNotification.orderId}`,
            caption: smsNotification.orderCaseNumber
        };

        return smsNotification;
    };

    this.getData = function () {
        $.getJSON(this.attr.url)
            .done(data => {
                this.data = data;
                this.paint();
                setTimeout(() => this.getData(), 1000 * 60 * 15);
            })
            .fail((err) => this.trigger("log", {message: err}));
    };


    this.after('initialize', function () {
        DataTable.attachTo(this.select('dataTableSelector'));
        setTimeout(() => this.getData(), 10);
    });
}
