import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import ButtonView from '@ckeditor/ckeditor5-ui/src/button/buttonview';
import ColumnIcon from '@ckeditor/ckeditor5-table/theme/icons/table-column.svg';
import ColumnEditing from './column-editing';

export default class ColumnPlugin extends Plugin {
    static get requires() {
        return [ColumnEditing];
    }
    init() {
        const editor = this.editor;
        const t = editor.t;

        editor.ui.componentFactory.add( 'column', locale => {

            const command = editor.commands.get( 'insertTwoColumn' );
            const buttonView = new ButtonView( locale );

            buttonView.set( {
                label: t( 'Two Column' ),
                icon: ColumnIcon,
                tooltip: true
            } );

            buttonView.bind( 'isOn', 'isEnabled' ).to( command, 'value', 'isEnabled' );
            this.listenTo( buttonView, 'execute', () => editor.execute( 'insertTwoColumn' ) );
            return buttonView;
        } );
    }
}