var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useState } from 'react';
import { Formik, Field } from 'formik';
import FormikTableModal from '../FormikTableModal';
import { formInfo } from '../consts';
import { setupInitialValues } from '../util';
import { submit } from '../api';
import mixam from '../../../../../boot/mixam.js';
import { initDefaultModalFooterButtons } from "../FormikTableModal/FormikTableModal";
var ArticleStatus = function (_a) {
    var line = _a.line;
    // @ts-ignore
    var _b = useState(mixam.user.hasRole('ROLE_MIXAM')), isAllowed = _b[0], setAllowed = _b[1];
    var articleStatusMap = {
        INIT: 24,
        PENDING: 28,
        ACCEPTED: 32,
        LABELED: 36,
        COLLECTED: 40,
        DELIVERED: 50
    };
    var tmpLine = __assign({}, line);
    tmpLine.status = articleStatusMap[line.status];
    return (React.createElement(Formik, { initialValues: setupInitialValues(formInfo.ArticleStatus.data, tmpLine), onSubmit: function (values) { return __awaiter(void 0, void 0, void 0, function () {
            var newValues;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        newValues = {
                            status: values.status,
                            tracking: values.tracking !== undefined ? values.tracking : '',
                            estimatedDeliveryCost: values.estimatedDeliveryCost !== undefined ? values.estimatedDeliveryCost : '',
                            silent: values.silent !== undefined ? values.silent : false
                        };
                        return [4 /*yield*/, submit(formInfo.ArticleStatus.apiUrlTemplate(line.id), newValues)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); } }, function (_a) {
        var setFieldValue = _a.setFieldValue, submitForm = _a.submitForm;
        var modalFooterButtons = initDefaultModalFooterButtons(isAllowed, submitForm);
        return (React.createElement(FormikTableModal, { headerText: "Article Status", openBtnLabel: line.status, openBtnClassName: line.status === 'INIT' ? 'btn-danger' : 'btn-primary', footerButtons: modalFooterButtons },
            React.createElement("div", { className: "form-group mb-3" },
                React.createElement("label", { htmlFor: "status" }, "Mark Article As"),
                React.createElement(Field, { className: "form-select", as: "select", name: "status", onChange: function (e) {
                        setFieldValue('status', e.target.value);
                    }, disabled: !isAllowed },
                    React.createElement("option", { value: 24 }, "Init"),
                    React.createElement("option", { value: 32 }, "Accepted"),
                    React.createElement("option", { value: 36 }, "Labelled"),
                    React.createElement("option", { value: -1 }, "Declined"))),
            React.createElement("div", { className: "form-group mb-3" },
                React.createElement("label", { htmlFor: "tracking" }, "Tracking Number"),
                React.createElement(Field, { className: "form-control", type: "text", name: "tracking", disabled: !isAllowed })),
            React.createElement("div", { className: "form-group mb-3" },
                React.createElement("label", { htmlFor: "estimatedDeliveryCost" }, "Delivery Cost"),
                React.createElement(Field, { className: "form-control", type: "number", name: "estimatedDeliveryCost", disabled: !isAllowed })),
            React.createElement("div", { className: "form-group mb-3 d-flex" },
                React.createElement("div", { className: "input-group-text mr-2" },
                    React.createElement(Field, { type: "checkbox", name: "silent", disabled: !isAllowed })),
                React.createElement("label", { htmlFor: "silent", className: "p-1" }, "Silent Mode (Prevent Emails)"))));
    }));
};
export default ArticleStatus;
