import defineComponent from '../../../components/flight/lib/component';
import Mustache from '../../../components/mustache/mustache';
import pagerTemplate from  'text!../../../appes6/templates/table/pager.mustache';

import Ripple from   '../ripple';

export default defineComponent(DataPager);

function DataPager() {

    this.attributes({
        rippleSelector: '.ripple',
        linkSelector: "a[data-page]"
    });

    this.render = function () {
        const isNeedPager = this.user &&  this.user.table ? this.user.table[this.location].printPager : true;
        if (this.page && this.pageSize && this.data && isNeedPager) {
            return Mustache.render(pagerTemplate, this.getPagerData());
        }
        return "";
    };

    this.getPagerData = function () {
        var pager = [],
            i = Math.max(1, this.page - 4),
            end;

        this.pages = Math.ceil(this.data.length / this.pageSize);
        end = Math.min(i + 9, this.pages) + 1;

        pager.push({
            title: '<span aria-hidden="true">&laquo;</span>',
            page: 1,
            disabled: this.page === 1 ? true : null
        });
        pager.push({
            title: '<span aria-hidden="true">&lsaquo;</span>',
            page: Math.max(1, this.page - 1),
            disabled: this.page === 1 ? true : null
        });
        for (; i < end; i++) {
            pager.push({
                title: i,
                page: i,
                active: this.page === i ? true : null
            });
        }
        pager.push({
            title: '<span aria-hidden="true">&rsaquo;</span>',
            page: this.page + 1,
            disabled: this.page < this.pages ? null : true
        });
        pager.push({
            title: '<span aria-hidden="true">&raquo;</span>',
            page: this.pages,
            disabled: this.page === this.pages ? true : null
        });
        return {
            list: this.pages > 1 ? pager : "",
            current: this.page,
            count: this.pages,
            csvFile: this.isTop() ? this.csvFile : "",
            csvFileName: this.csvFile ? this.csvFile.split("/").pop() : "",
            recordCount: this.data.length.formatNumber(0)
        };
    };

    this.isTop = function () {
        return !this.$node.hasClass("bottom");
    };

    this.setPageNumber = function (event, data) {
        this.page = data.page;
        this.paint();
    };

    this.setPageSize = function (event, data) {
        this.pageSize = data.size;
        this.paint();
    };

    this.setData = function (event, data) {
        this.data = data.list;
        this.csvFile = data.csvFile;
        this.paint();
    };

    this.paint = function () {
        this.$node.html(this.render());
        Ripple.attachTo(this.select('rippleSelector'));
    };

    this.onLinkClick = function (event) {
        var $target = $(event.target).closest("a"),
            page = $target.data("page"),
            section = $target.closest("section");

        event.preventDefault();

        if (this.clickTimer) {
            clearTimeout(this.clickTimer);
        }

        this.clickTimer = setTimeout(() => {
            if (!$target.parent().hasClass("disabled")) {
                this.trigger("uiRequestPageChange", {page: page});
            }

            if (section.hasClass("bottom")) {
                $('[data-type="report-info"]').scrollToView();
            }
        }, 200);
    };


    this.setUser = function (event, data) {
        this.user = data;
    };

    this.after('initialize', function () {
        this.location = this.node.classList.contains('bottom') ? "bottom" : "top";

        this.on(document, "uiSetPageNumber", this.setPageNumber);
        this.on(document, "uiSetPageSize", this.setPageSize);
        this.on(document, "uiSetData", this.setData);
        this.on(document, "notifyUserDetails", this.setUser);
        this.on("click", {
            linkSelector: this.onLinkClick
        });
     });
}

