export var CELL_TYPES = {
    THUMBNAIL: 'thumbnail',
    LINK: 'link',
    LINK_LIST: 'link-list',
    ICON: 'icon',
    DATETIME: 'datetime',
    DATE: 'date',
    TIMEBOX: 'timebox',
    USER: 'user',
    BOOLEAN: 'boolean',
    LIST: 'list',
    VIDEO_EVENTS: 'video-events',
    ARTWORK: 'artwork',
    REMARKS: 'remarks',
    IMAGE: 'image',
    CUSTOMER_CREDIT_MODAL: 'customer-credit-modal',
    ROLES: 'roles',
    DISPATCH: 'dispatch',
    COMMAND: 'command',
    BUTTON: 'button',
    RESELLERCOMMAND: 'resellercommand',
    MULTIBUTTON: 'multiButton',
    TAX_EXEMPTION_ACTION: 'tax-exemption-action',
    DISPUTE_CREDIT_ACTION: 'dispute-credit-action',
    DISPUTE_ACTION: 'dispute-action',
    CREDIT_COMMAND: 'creditcommand',
    BADGE: 'badge',
    SHOW_MORE_TEXT: 'showMoreText',
    DELIVERIES: 'deliveries',
    SAMPLES_REQUEST_ACTION: 'SAMPLES-REQUEST-MODAL',
    FULFILMENT_UPDATE_MODAL: 'fulfilment-update-modal',
    PROOF_UPLOAD_MODAL: 'proof-update-modal',
    CHECKBOX: 'checkbox',
    PUBLISHER_SETTLEMENT_MODAL: 'publisher-settlement-modal',
    DELIVERY_RECONCILIATION: 'delivery-reconciliation-modal',
    INVOICE_JOB_REQUEUE: 'invoice-job-requeue',
    ORDER_TYPE: 'order-type',
    API_LOG_MODAL: 'api-log-modal'
};
export var FILTERABLE_DATA_TYPES = {
    TEXT: 'text',
    NUMBER: 'number',
    BOOLEAN: 'boolean',
    USER: 'user',
    DATETIME: 'datetime',
    DATE: 'date',
    TIMEBOX: 'timebox',
    BADGE: 'badge',
    SHOW_MORE_TEXT: 'showMoreText',
    LINK: 'link'
};
export var FILTER_METHODS = {
    EQUALS: 'EQUALS',
    CONTAINS: 'CONTAINS',
    STARTS_WTIH: 'STARTS_WTIH',
    DOES_NOT_START_WITH: 'DOES_NOT_START_WITH',
    NOT: 'NOT',
    DOES_NOT_CONTAIN: 'DOES_NOT_CONTAIN',
    ENDS_WITH: 'ENDS_WITH',
    DOES_NOT_END_WTIH: 'DOES_NOT_END_WTIH',
    EMPTY: 'EMPTY',
    NOT_EMPTY: 'NOT_EMPTY',
    LESS_THAN: 'LESS_THAN',
    LESS_THAN_EQUAL_TO: 'LESS_THAN_EQUAL_TO',
    GREATER_THAN_EQUAL_TO: 'GREATER_THAN_EQUAL_TO',
    GREATER_THAN: 'GREATER_THAN',
    BETWEEN: 'BETWEEN',
    NOT_BETWEEN: 'NOT_BETWEEN',
    TRUE: 'TRUE',
    FALSE: 'FALSE',
    DATE_EQUALS: 'DATE_EQUALS',
    DATE_LESS_THAN: 'DATE_LESS_THAN',
    DATE_LESS_THAN_EQUAL_TO: 'DATE_LESS_THAN_EQUAL_TO',
    DATE_GREATER_THAN: 'DATE_GREATER_THAN',
    DATE_GREATER_THAN_EQUAL_TO: 'DATE_GREATER_THAN',
    DATE_BETWEEN: 'DATE_BETWEEN',
    DATE_NOT_BETWEEN: 'DATE_NOT_BETWEEN',
};
// filter methods that do not require a user input to be on screen once selected
export var FILTER_METHODS_NO_INPUT = [
    FILTER_METHODS.EMPTY,
    FILTER_METHODS.NOT_EMPTY,
    FILTER_METHODS.TRUE,
    FILTER_METHODS.FALSE,
];
export var FILTER_METHODS_TWO_INPUTS = [
    FILTER_METHODS.BETWEEN,
    FILTER_METHODS.NOT_BETWEEN,
];
export var FILTER_METHODS_DATE = [
    FILTER_METHODS.DATE_EQUALS,
    FILTER_METHODS.DATE_LESS_THAN,
    FILTER_METHODS.DATE_LESS_THAN_EQUAL_TO,
    FILTER_METHODS.DATE_GREATER_THAN,
    FILTER_METHODS.DATE_GREATER_THAN_EQUAL_TO,
];
export var FILTER_METHODS_TWO_DATES = [
    FILTER_METHODS.DATE_BETWEEN,
    FILTER_METHODS.DATE_NOT_BETWEEN,
];
export var USER_INPUT_METHODS = {
    TEXT_INPUT: 'TEXT_INPUT',
    NONE: 'NONE',
};
export var FILTER_OPTIONS = {
    stringTypeOptions: [
        { text: 'Condition', data: 'default' },
        { text: 'Equals', data: FILTER_METHODS.EQUALS },
        { text: 'Contains', data: FILTER_METHODS.CONTAINS },
        { text: 'Starts With', data: FILTER_METHODS.STARTS_WTIH },
        {
            text: 'Does Not Start With',
            data: FILTER_METHODS.DOES_NOT_START_WITH,
        },
        { text: 'Not', data: FILTER_METHODS.NOT },
        { text: 'Does Not Contain', data: FILTER_METHODS.DOES_NOT_CONTAIN },
        { text: 'Ends With', data: FILTER_METHODS.ENDS_WITH },
        { text: 'Does Not End With', data: FILTER_METHODS.DOES_NOT_END_WTIH },
        { text: 'Empty', data: FILTER_METHODS.EMPTY },
        { text: 'Not Empty', data: FILTER_METHODS.NOT_EMPTY },
    ],
    numberTypeOptions: [
        { text: 'Condition', data: 'default' },
        { text: 'Equals', data: FILTER_METHODS.EQUALS },
        { text: 'Not', data: FILTER_METHODS.NOT },
        { text: 'Less Than', data: FILTER_METHODS.LESS_THAN },
        {
            text: 'Less Than Or Equal To',
            data: FILTER_METHODS.LESS_THAN_EQUAL_TO,
        },
        {
            text: 'Greater Than Or Equal To',
            data: FILTER_METHODS.GREATER_THAN_EQUAL_TO,
        },
        { text: 'Greater Than', data: FILTER_METHODS.GREATER_THAN },
        { text: 'Between', data: FILTER_METHODS.BETWEEN },
        { text: 'Not Between', data: FILTER_METHODS.NOT_BETWEEN },
        { text: 'Empty', data: FILTER_METHODS.EMPTY },
        { text: 'Not Empty', data: FILTER_METHODS.NOT_EMPTY },
    ],
    booleanTypeOptions: [
        { text: 'Condition', data: 'default' },
        { text: 'True', data: FILTER_METHODS.TRUE },
        { text: 'False', data: FILTER_METHODS.FALSE },
    ],
    dateTypeOptions: [
        { text: 'Condition', data: 'default' },
        { text: 'Equals', data: FILTER_METHODS.DATE_EQUALS },
        { text: 'Less Than', data: FILTER_METHODS.DATE_LESS_THAN },
        {
            text: 'Less Than Or Equal To',
            data: FILTER_METHODS.DATE_LESS_THAN_EQUAL_TO,
        },
        {
            text: 'Greater Than Or Equal To',
            data: FILTER_METHODS.DATE_GREATER_THAN_EQUAL_TO,
        },
        { text: 'Greater Than', data: FILTER_METHODS.DATE_GREATER_THAN },
        { text: 'Between', data: FILTER_METHODS.DATE_BETWEEN },
        { text: 'Empty', data: FILTER_METHODS.EMPTY },
        { text: 'Not Empty', data: FILTER_METHODS.NOT_EMPTY },
    ],
};
