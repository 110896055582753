import ReactDom from  'react-dom';
import React from 'react';
import proptypes from 'prop-types';
const rd = ReactDom;


const propTypes = {

    /**
     * CSS class or classes applied when the component is exited
     */
    className: proptypes.string,

};

function noop() {
}

const defaultProps = {
    in: false,
    onEnter: noop,
    onExit: noop
};

class AnimatedCheckRadioBoxListItem extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.randomKey = Math.floor(Math.random() * 2147483648).toString(36);
    }

    render() {
        const item = this.props;
        const id = `ewsert${this.randomKey}${item.value}`;
        const checked = item.checked;

        const style = {
            "strokeDasharray": '275.003, 275.003',
            "strokeDashoffset": item.checked ? 0 : 275.003,
            transition: `stroke-dashoffset ${item.checked ? '0.2s' : '0.08s'} ease-in-out 0s`
        };

        return (<li
            key={item.value}
            data-toggle="animated-checkbox"
            data-type="radio"
            data-mark="circle"
            className="animated-radiobox"
        >
            <input
                id={id}
                defaultChecked={checked}
                type="radio"
                value={item.value}
                name="dispatchOption"
            />
            <label
                htmlFor={id}
            >{item.label}</label>
            <svg
                viewBox="0 0 100 100"
                xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M34.745,7.183C25.078,12.703,13.516,26.359,8.797,37.13 c-13.652,31.134,9.219,54.785,34.77,55.99c15.826,0.742,31.804-2.607,42.207-17.52c6.641-9.52,12.918-27.789,7.396-39.713 C85.873,20.155,69.828-5.347,41.802,13.379"
                    style={style}></path>
            </svg>
        </li>);
    }

}


AnimatedCheckRadioBoxListItem.propTypes = propTypes;
AnimatedCheckRadioBoxListItem.defaultProps = defaultProps;

export default AnimatedCheckRadioBoxListItem;
