import mixam from '../../../boot/mixam';
import defineComponent from '../../../../components/flight/lib/component';
import DataTable from '../../table/data-table-net/data-table-net.tsx';

export default defineComponent(ReviewsTrustpilotManager);

function ReviewsTrustpilotManager() {

    this.attributes({
        url: "/admin/api/reviews/list",
        dataTableSelector: '[data-type="data-table"]'
    });

    this.createSchema = function () {
        const cols = [];

        cols.push({
            title: "",
            type: "thumbnail",
            data: "front"
        });

        cols.push({
            title: "Email",
            type: "text",
            data: "memberEmail",
            width: '300px'
        });

        cols.push({
            title: "Order",
            type: "link",
            data: "order"
        });

        cols.push({
            title: "Created",
            type: "timebox",
            data: "createdAt",
            defaultSort: true,
            sortOrder: 'desc'
        });

        cols.push({
            title: "Stars",
            type: "trustpilot-stars",
            data: "stars"
        });

        cols.push({
            title: "Source",
            type: "text",
            data: "source"
        });

        cols.push({
            title: "Title",
            type: "link",
            data: "revlink"
        });

        cols.push({
            title: "Message",
            data: "text",
            type: "showMoreText",
            width: "400px"
        });

        return cols;
    };

    this.paint = function () {
        const data = {
            columns: this.createSchema(),
            fileName: this.attr.url.split("/").pop(),
            response: this.normalize(this.data.list)
        };

        if (this.dataReadyFired) {
            window.dispatchEvent(new CustomEvent('uiDataUpdate', { detail: data }));
        } else {
            this.dataReadyFired = true;
            this.trigger("uiDataReady", data);
        }
    };

    this.normalize = function (list) {
        return list.map(x => this.normalizeReview(x));
    };

    this.normalizeReview = function (normalizeReview) {
        const review = $.extend(true, {}, normalizeReview);

        review.revlink = {
            href: `https://www.trustpilot.com/reviews/${review.id}`,
            caption: review.title
        };

        if (review.referenceId) {
            review.order = {
                href: `/orders/${review.orderId}/artwork`,
                caption: review.referenceId || 'Order',
                title: review.referenceId
            };
        }

        if (review.thumbnailUrl) {
            review.front = {
                src: review.thumbnailUrl,
                href: `/orders/${review.orderId}/artwork`
            };
            review.override = review.override || {};
            review.override.td = review.override.td || {};
            review.override.td.front = "orient-" + review.thumbnailOrientaion;
        }

        if (review.memberName) {
            review.member = {
                href: `/member/${review.memberId}`,
                title: review.memberName,
                target: "_blank"
            };
        }

        review.text = {
            lines: 3,
            text: review.text
        };

        return review;
    };


    this.getData = function () {
        $.getJSON(this.attr.url)
            .done(data => {
                this.data = data;
                this.paint();
            })
            .fail((err) => this.trigger("log", {message: err}));
    };

    /**
     * subscribe to STOMP service that notify us about changes
     * in a specific invoice
     */
    this.subscribe = function (onMessage) {
        this.trigger("log", {message: ["Subscribe to stomp channel allfeedbackchanel"], title: "FeedbackManager.subscribe"});
        this.stomp = Stomp.client(mixam.stompServiceUrl);

        this.stomp.debug = (...args) => {
            if (args.join('').indexOf('Whoops! Lost connection to') !== -1) {
                setTimeout(() => this.subscribe(onMessage), 10);
            }
            this.trigger("log", {message: args, title: "ReviewsTrustpilotManager.subscribe"});
        };

        this.stomp.connect(mixam.stompWebUser, mixam.stompWebPass, () => {
            this.stomp.subscribe("/topic/allreviewschanel", (d) => {
                const p = JSON.parse(d.body);
                onMessage(p);
            });
        }, (err) => this.trigger("log", err), '/');
    };

    this.updateMessageLine = function (data) {
        if (!this.data) {
            return;
        }

        let verb = data.type;
        const message = data.data;
        if (message.shopId !== mixam.shop.id) {
            return;
        }

        const index = this.data.list.map(x => x.id).indexOf(message.id);

        if (verb === "UPDATE" || verb === "INSERT") {
            if (index !== -1) {
                this.data.list[index] = message;
            } else {
                this.data.list.unshift(message);
            }

        } else if (verb === "DELETE" && index !== -1) {
            this.data.list.splice(index, 1);
        }

        this.paint();
        requestAnimationFrame(() => $("#PK" + message.id).hilightTableLine());
    };

    this.after('initialize', function () {
        DataTable.attachTo(this.select('dataTableSelector'));
        setTimeout(() => this.getData(), 10);
        this.subscribe(d => this.updateMessageLine(d));
    });
}
