import defineComponent from '../../../../components/flight/lib/component';
import DataTable from './../../table/data-table-net/data-table-net.tsx';
import {Modal} from "bootstrap";
import axios from "axios";

export default defineComponent(ParticipationRulesDatatable);

function ParticipationRulesDatatable() {

    this.attributes({
        url: "/admin/api/participation-policies",
        dataTableSelector: '[data-type="data-table"]',
        confirmDeleteModal: '[data-type="delete-rule-confirmation"]'
    });

    this.createSchema = function () {
        const cols = [];
        cols.push({
            title: "Name",
            type: "text",
            data: "name",
            defaultSort: true
        });
        cols.push({
            title: "Active",
            type: "boolean",
            data: "active"
        });
        cols.push({
            title: "Description",
            type: "text",
            data: "description"
        });
        cols.push({
            title: "Suppliers",
            type: "text",
            data: "suppliers"
        });
        cols.push({
            title: "Machines",
            type: "text",
            data: "machines"
        });
        cols.push({
            title: "Shops",
            type: "text",
            data: "shops"
        });
        cols.push({
            title: "Can't Participate Message",
            type: "text",
            data: "cantParticipateMessage"
        });
        cols.push({
            title: "",
            type: "multiButton",
            data: "actions"
        });
        return cols;
    };


    this.paint = function () {
        const data = {
            columns: this.createSchema(),
            fileName: this.attr.url.split("/").pop(),
            response: this.normalize(this.data)
        };

        if (this.dataReadyFired) {
            window.dispatchEvent(new CustomEvent('uiDataUpdate', { detail: data }));
        } else {
            this.dataReadyFired = true;
            this.trigger("uiDataReady", data);
        }
    };

    this.normalize = function (list) {
        return list.map(rule => this.normalizeParticipationRule(rule));
    };

    this.normalizeParticipationRule = function (participationRule_) {
        const participationRule = $.extend(true, {}, participationRule_);
        const participationPolicy = participationRule.participationPolicy;

        participationRule.name = participationPolicy.name;
        participationRule.cantParticipateMessage = participationPolicy.cantParticipateMessage;
        participationRule.description = participationPolicy.description;
        participationRule.active = participationPolicy.active;
        participationRule.suppliers = participationRule.supplierNames.join(', ');
        participationRule.machines = participationRule.machineNames.join(', ');
        participationRule.shops = participationRule.shopNames.join(', ');
        participationRule.actions = [
            {
                href: `/admin/catalogue/participation-policies/${participationPolicy.id}/edit`,
                caption: "Edit",
                target: "_self",
                className: "btn btn-primary"
            },
            {
                href: `/admin/catalogue/participation-policies/${participationPolicy.id}`,
                caption: "Delete",
                className: "btn btn-danger",
                iconClassname: "bi bi-trash",
                key: "delete",
                target: "_self",
                callback: (event) => {
                    event.stopPropagation();
                    event.preventDefault();

                    let buttonTarget = $(event.target);

                    const confirmationDialogue = document.querySelector('[data-type="delete-rule-confirmation"]');
                    let modal = Modal.getOrCreateInstance(confirmationDialogue);
                    modal.show();

                    $(document.querySelector('[data-type="confirm-button"]')).click(function() {
                        fetch(
                            buttonTarget.attr('href'),
                        {
                                method: 'DELETE',
                            }
                        ).then((response) => {
                            if(response.status === 200) {
                                buttonTarget.closest('.rdt_TableRow').remove();
                            }
                        });
                        modal.hide();
                    });
                }
            }
        ];
        return participationRule;
    };

    this.getData = function () {
        axios.get(this.attr.url)
            .then(response => {
                this.data = response.data;
                this.paint();
                setTimeout(() => this.getData(), 1000 * 60 * 15);
            }).catch(error => this.trigger("log", {message: error}));
    };

    this.after('initialize', function () {
        DataTable.attachTo(this.select('dataTableSelector'));
        setTimeout(() => this.getData(), 10);
    });
}
