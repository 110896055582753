import defineComponent from '../../../../components/flight/lib/component';
import DataTable from './../../table/data-table-net/data-table-net.tsx';

export default defineComponent(CustomerGroup);

function CustomerGroup() {

    this.attributes({
        url: "/api/admin/customer-groups",
        dataTableSelector: '[data-type="data-table"]'
    });

    this.createSchema = function () {
        var cols = [];

        cols.push({
            title: "Customer Group",
            type: "text",
            data: "name"
        });

        cols.push({
            title: "Rule Count",
            type: "number",
            data: "ruleCount"
        });

        cols.push({
            title: "Rules",
            type: "text",
            data: "rulesText"
        });

        cols.push({
            title: "",
            type: "multiButton",
            data: "actions",
            width: "300px"
        });

        return cols;
    };

    this.paint = function () {
        const data = {
            columns: this.createSchema(),
            fileName: this.attr.url.split("/").pop(),
            response: this.normalize(this.data)
        };

        if (this.dataReadyFired) {
            window.dispatchEvent(new CustomEvent('uiDataUpdate', { detail: data }));
        } else {
            this.dataReadyFired = true;
            this.trigger("uiDataReady", data);
        }
    };

    this.normalize = function (list) {
        return list.map(x => this.normalizeGroup(x));
    };

    this.normalizeGroup = function (group_) {
        const group = $.extend(true, {}, group_);
        group.actions = [
            {
                href: `/admin/customers/groups/${group.id}/customers`,
                caption: 'View Customers',
                className: 'btn btn-secondary',
                target: '_self'
            },
            {
                href: `/admin/customers/groups/edit/${group.id}`,
                caption: 'Edit',
                className: 'btn btn-primary',
                target: '_self'
            },
        ];
        group.ruleCount = group.discountRules.length;
        group.rulesText = group.discountRules.map(rule => rule.name).join("\r\n");
        return group;
    };

    this.getData = function () {
        $.getJSON(this.attr.url)
            .done(data => {
                this.data = data;
                this.paint();
                setTimeout(() => this.getData(), 1000 * 60 * 15);
            })
            .fail((err) => this.trigger("log", {message: err}));
    };

    this.after('initialize', function () {
        DataTable.attachTo(this.select('dataTableSelector'));
        setTimeout(() => this.getData(), 10);
    });
}
