import defineComponent from '../../../components/flight/lib/component';

export default defineComponent(PromotionVisibilityMonitor);

function PromotionVisibilityMonitor() {

    this.onScroll = function(event, data) {
        if (!this.done) {
            if (this.top <= data.offset) {
                this.done = true;
                // 'share_embed_frame' is the name of the promotion frame
                if ('share_embed_frame' in window.frames) {
                    window.frames.share_embed_frame.postMessage("mpc gotopage 9", "*");
                }
            }
        }
    };

    this.onResize = function () {
        this.top = this.node.offsetTop - window.innerHeight + this.node.offsetHeight / 2;
        this.onScroll(null, {offset: document.documentElement.scrollTop || document.body.scrollTop});
    };


    this.after('initialize', function () {
        $(window).resize($.proxy(this.onResize, this));
        this.on(document, "uiPageScroll", this.onScroll);
        this.onResize();
    });
}
