var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useEffect, useState } from 'react';
import { Row, Col, Spinner, FormControl, Button } from 'react-bootstrap';
import axios from "axios";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";
import { Formik } from 'formik';
import * as yup from "yup";
import moment from 'moment';
var tinyUrlApiEndpoint = "/api/admin/tinyurls";
var schema = yup.object().shape({
    url: yup.string().url('Must be a valid URL').required('URL is required'),
    noExpiry: yup.boolean(),
    expiryDate: yup
        .date()
        .when('noExpiry', function (noExpiry, schema) {
        return noExpiry
            ? schema.notRequired()
            : schema.required('Expiry date is required').min(new Date(), 'Expiry date must be in the future');
    }),
});
var TinyUrlEditor = function (props) {
    var _a = useState(true), isDataLoading = _a[0], setDataLoading = _a[1];
    var _b = useState(undefined), tinyUrl = _b[0], setTinyUrl = _b[1];
    var _c = useState(undefined), errorMessage = _c[0], setErrorMessage = _c[1];
    var _d = useState(false), shouldSubmitForm = _d[0], setShouldSubmitForm = _d[1];
    var init = function () {
        if (props.tinyUrlId) {
            getTnyUrl();
        }
        else {
            setTinyUrl(function (prevState) { return (__assign(__assign({}, prevState), { key: '', url: '' })); });
            setDataLoading(false);
        }
    };
    var getTnyUrl = function () {
        axios.get("".concat(tinyUrlApiEndpoint, "/").concat(props.tinyUrlId))
            .then(function (response) {
            setTinyUrl(response.data);
            setDataLoading(false);
        }).catch(function (error) {
            console.log("Error Retrieving TinyUrl Data: ".concat(error));
            setDataLoading(false);
        });
    };
    useEffect(function () {
        init();
    }, []);
    return (React.createElement(Row, null,
        React.createElement(Col, null,
            isDataLoading &&
                React.createElement(Spinner, { animation: "grow", variant: "primary" }),
            !isDataLoading &&
                React.createElement(Formik, { validationSchema: schema, onSubmit: function (values) { return __awaiter(void 0, void 0, void 0, function () {
                        var valuesToSubmit;
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    if (!shouldSubmitForm) return [3 /*break*/, 2];
                                    valuesToSubmit = {
                                        id: tinyUrl.id,
                                        url: values.url,
                                        expiryDate: values.noExpiry ? null : values.expiryDate
                                    };
                                    return [4 /*yield*/, axios.post(tinyUrlApiEndpoint, valuesToSubmit)
                                            .then(function (response) {
                                            setTinyUrl(response.data);
                                        })
                                            .catch(function (error) {
                                            setErrorMessage(error);
                                        })];
                                case 1:
                                    _a.sent();
                                    _a.label = 2;
                                case 2: return [2 /*return*/];
                            }
                        });
                    }); }, initialValues: {
                        url: tinyUrl.url,
                        expiryDate: tinyUrl.expiryDate ? moment(new Date(tinyUrl.expiryDate)).format('YYYY-MM-DD') : moment().add(31, 'days').format('YYYY-MM-DD'),
                        noExpiry: tinyUrl.expiryDate ? false : true
                    } }, function (_a) {
                    var handleSubmit = _a.handleSubmit, handleChange = _a.handleChange, handleBlur = _a.handleBlur, values = _a.values, touched = _a.touched, errors = _a.errors, isSubmitting = _a.isSubmitting;
                    return (React.createElement(Form, { noValidate: true, onSubmit: handleSubmit },
                        errorMessage &&
                            React.createElement(Alert, { variant: "danger", className: "mb-3" }, errorMessage),
                        React.createElement(Row, { className: "mb-3" },
                            React.createElement(Col, { className: "col col-md-12 col-lg-4" },
                                React.createElement(Form.Group, { as: Col, md: "12", controlId: "url" },
                                    React.createElement(Form.Label, null, "URL"),
                                    React.createElement(FormControl, { type: "url", name: "url", value: values.url, onChange: handleChange, onBlur: handleBlur, isInvalid: touched.url && !!errors.url }),
                                    React.createElement(Form.Control.Feedback, { type: "invalid" }, errors.url),
                                    React.createElement(Form.Text, { id: "urlHelpBlock", muted: true }, "The URL this Tiny URL redirects to")))),
                        React.createElement(Row, { className: "mb-3" },
                            React.createElement(Col, { className: "col col-md-12 col-lg-4" },
                                React.createElement(Form.Group, { as: Col, md: "12", controlId: "expiryDate" },
                                    React.createElement(Form.Label, null, "Expiry Date"),
                                    React.createElement(FormControl, { type: "date", name: "expiryDate", value: values.expiryDate, onChange: handleChange, onBlur: handleBlur, isInvalid: touched.expiryDate && !!errors.expiryDate, disabled: values.noExpiry })),
                                React.createElement(Form.Control.Feedback, { type: "invalid" }, errors.expiryDate),
                                React.createElement(Form.Text, { id: "validExpiryDateHelpBlock", muted: true }, "The date this URL expires"),
                                React.createElement(Form.Group, { controlId: "noExpiry" },
                                    React.createElement(Form.Check, { type: "checkbox", label: "No Expiry Date", name: "noExpiry", checked: values.noExpiry, onChange: handleChange, onBlur: handleBlur })))),
                        tinyUrl.key && (React.createElement(Row, { className: "mb-3" },
                            React.createElement(Col, { className: "col col-md-12 col-lg-4" },
                                React.createElement(Form.Group, { as: Col, md: "12", controlId: "tinyUrlKey" },
                                    React.createElement(Form.Label, null, "Tiny URL"),
                                    React.createElement(FormControl, { type: "text", name: "tinyUrlKey", disabled: true, readOnly: true, value: "".concat(props.secureDomain, "/t/").concat(tinyUrl.key) }))))),
                        React.createElement(Row, { className: "mt-3" },
                            React.createElement(Col, null,
                                isSubmitting &&
                                    React.createElement(Button, { type: "submit", variant: "primary", disabled: true },
                                        React.createElement(Spinner, { as: "span", animation: "grow", size: "sm", role: "status", "aria-hidden": "true" }),
                                        "Saving..."),
                                !isSubmitting &&
                                    React.createElement(Button, { type: "submit", variant: "primary", disabled: errors > 0, onClick: function () { return setShouldSubmitForm(true); } }, "Save")))));
                }))));
};
export default TinyUrlEditor;
