import mixam from '../../boot/mixam';
import Mustache from '../../../components/mustache/mustache';
import defineComponent from '../../../components/flight/lib/component';
import WithGoogleAnalytics from './../with-google-analytics';
// noinspection JSFileReferences
import uploadItemTemplate from 'text!../../../appes6/templates/order/uploadItem.mustache';
// noinspection JSFileReferences
import importItemTemplate from 'text!../../../appes6/templates/order/import-item.mustache';
import WithSubmitAnimation from '../with-submit-zoom-animation';
import '../../../components/simpleupload/simpleUpload';
import axios from "axios";
import ReactDom from "react-dom/client";
import {PRINTESS_TEMPLATE_TYPE, PrintessTemplateOptionsModal} from "../react/printess/PrintessTemplateOptionsModal.tsx";
import {PrintessDesign, PrintessDesignOptionsModal} from "../react/printess/PrintessDesignOptionsModal.tsx";
import React from "react";
import regional from "../../boot/regional";

export default defineComponent(OrderUpload, WithGoogleAnalytics, WithSubmitAnimation);

function OrderUpload() {

    this.attributes({
        itemContainer: false,
        shoppingCartItemId: 0,
        lastId: 1,
        dragging: $(),
        urls: [],
        closeBtnSelector: '.close-btn',
        bleedTypeSelector: '[data-type="alternative-file"] li a',
        bleedTypeAllSelector: '[data-type="alternative-file-all"] li a',
        fileListSelector: '.upload-file-list.secondary',
        uploadSelector: '[data-type="upload-input"]',
        progressBarSelector: '[data-type="upload-progress"] .bar',
        dropInSelector: '[data-type="drop-in"]',
        importBtnSelector: '[data-type="import-url"]',
        printessSelector: '[data-type="printess-editor"]',
        importFormSelector: '[data-type="import-form"]',
        importHrefSelector: '[data-type="import-href"]',
        cancelImportBtnSelector: '[data-type="cancel-import"]',
        saveBtnSelector: '[data-type="save-import"]',
        printboxSelector: '[data-type="printbox-editor"]',
        printboxResetSelector: '[data-type="printbox-editor-delete"]',
        printboxEditorReturnSelector: '[data-type="printbox-editor-return"]',
        printboxRender: '[data-type="printbox-render"]',
        printboxPreflightSelector: '[data-type="printbox-preflight"]',
        printboxReorderSelector: '[data-type="printbox-reorder"]'
    });

    this.incInProcess = function (i) {
        this.inProcess += i;
        this.inProcess = Math.max(this.inProcess, 0);
        this.trigger("uploadInProcessChange", {count: this.inProcess});
    };

    this.cancelClick = function (event) {
        const $target = $(event.target),
            $item = $target.closest(".upload-item"),
            data = $item.data('uploadData'),
            args = [];

        event.preventDefault();
        if (data) {
            if (data.uploadObject) {
                data.errorThrown = 'abort';
                data.uploadObject.cancel();
                delete data.uploadObject;
                this.incInProcess(-1);

                dataLayer.push({'event': "santa-upload-cancel", 'cartId': this.attr.shoppingCartItemId});

                args.push("type=orderupload_cancelClick");
                args.push("msg=" + encodeURIComponent("upload cancelled by user"));
                args.push("item=" + encodeURIComponent(this.attr.shoppingCartItemId));
                args.push("file=" + encodeURIComponent("n/a"));
                args.push("linenumber=" + encodeURIComponent(0));
                args.push("count=1");
                args.push("col=" + 0);
                args.push("errorObj=" + encodeURIComponent(JSON.stringify({function: 'order-upload.cancelClick'})));
                args.push("csuser=" + encodeURIComponent(mixam.user.username || "n/a"));
                args.push("shop=" + encodeURIComponent(mixam.shop.name || "n/a"));
                mixam.log(args);
            }
        }
        //this.removeUploadedUrl($item.data("url"));
        this.trigger("uiFileRemoveByFileId", {
            id: $item.data('file-id')
        });
        $item.remove();
    };

    this.changeBleedType = function (event) {
        const $target = $(event.target),
            $item = $target.closest(".upload-item"),
            fileId = $item.data('file-id'),
            value = $target.data("type");

        event.preventDefault();
        $target.closest('[data-type="alternative-file"]').html("<i class='fa fa-spinner fa-lg fa-spin'></i>");
        this.trigger("uiFileChangeBleedType", {
            fileId: fileId,
            value: value
        });
    };

    this.changeBleedTypeAll = function (event) {
        const $target = $(event.target),
            $itemElement = $target.closest('[data-item]'),
            itemId = $itemElement.data('item'),
            value = $target.data("type");

        event.preventDefault();
        $itemElement.find('[data-type="alternative-file"], [data-type="alternative-file-all"]').html("<i class='fa fa-spinner fa-lg fa-spin'></i>");
        this.trigger("uiFileChangeAllBleedType", {
            itemId,
            value
        });
    };

    this.dragEnter = function (event) {
        event.preventDefault();
        if (this.hasFiles(event)) {
            if (this.attr.dragging.size() === 0) {
                this.trigger('dndHoverStart');
            }
            this.attr.dragging = this.attr.dragging.add(event.target);
        }
    };

    this.dragLeave = function (event) {
        event.preventDefault();
        setTimeout(() => {
            this.attr.dragging = this.attr.dragging.not(event.target);
            if (this.attr.dragging.size() === 0) {
                this.trigger('dndHoverEnd');
            }
        }, 1);
    };

    this.hasFiles = function (event) {
        try {
            if (event.originalEvent.dataTransfer) {
                for (let dataTransferItem of event.originalEvent.dataTransfer.items) {
                    if (dataTransferItem.kind === "file") {
                        return true;
                    }
                }
            }
            return false;
        } catch (ex) {
            console.error(ex);
        }
        return true;
    };

    this.dragHoverStarts = function (/*event*/) {
        this.getItemContainer().addClass('drag-over');
    };

    this.dragHoverEnds = function (event) {
        event.preventDefault();
        this.getItemContainer().removeClass('drag-over');
        this.attr.dragging = $();
        /* jshint ignore:start */
        const files = event.originalEvent?.dataTransfer?.files;
        /* jshint ignore:end */
        if (files) {
            Array.from(files).forEach(file => this.dropUploadStart(file));
        }
    };

    this.getItemContainer = function () {
        if (!this.attr.itemContainer) {
            this.attr.itemContainer = this.$node.closest(".art-item");
        }
        return this.attr.itemContainer;
    };

    this.dropIn = function () {
        const options = {
            success: (files) => {
                if (files && files.length) {
                    this.trigger("uiFileAdded", {
                        type: "dropin",
                        files: files
                    });
                }
            },

            cancel: () => true,
            linkType: "direct",
            multiselect: true,
            extensions: ['text', 'documents', 'images']
        };

        // noinspection JSUnresolvedVariable
        if (window.Dropbox) {
            // noinspection JSUnresolvedFunction,JSUnresolvedVariable
            Dropbox.choose(options);
        }
    };

    this.importUrl = function (/*event*/) {
        this.isOpen = true;
        this.select("importBtnSelector").addClass("hidden");
        setTimeout(() => this.select("importHrefSelector").focus(), 100);
    };

    this.cancelImportUrl = function (/*event*/) {
        this.close();
    };

    this.close = function () {
        this.isOpen = false;
        this.select("importBtnSelector").removeClass("hidden");
        this.select("importFormSelector").collapse('hide');
    };

    this.submitImport = function (event) {
        if (!event.isDefaultPrevented()) {
            this.handleSubmit(event);
            this.uploadId = Math.floor(Math.random() * 2147483648).toString(36);
            this.trigger("uiFileAdded", {type: "import", url: this.select("importHrefSelector").val(), uid: this.uploadId});
        }
        event.preventDefault();
        event.stopPropagation();
    };

    this.onAfterAction = function (event, data) {
        if (this.uploadId && data.uid && this.uploadId === data.uid && data.status === "success") {
            this.select("importHrefSelector").val("");
            this.select('saveBtnSelector').removeAttr('data-loading').removeAttr('disabled');
            this.close();
            data.dict = regional().santa.order;
            this.uploadId = null;
            $("<li>", {
                "class": "upload-item",
                "data-file-index": 0,
                "data-file-id": "9999",
                html: Mustache.render(importItemTemplate, data)
            }).appendTo(this.select('fileListSelector'));
        }
    };

    this.uploadStart = function (/*event*/) {
        const that = this;
        const data = {};
        this.select('uploadSelector').simpleUpload(this.uploadUrl, {
            expect: 'json',
            start: function (file) {
                data.uploadObject = this.upload;
                that.add(file, data);
            },
            progress: progress => this.progress(data, progress),
            success: response => this.uploadSuccess(data, response),
            error: error => this.trigger("log", error)
        });
    };

    this.dropUploadStart = function (file) {
        const that = this;
        const data = {};
        $.fn.simpleUpload(this.uploadUrl, {
            expect: 'json',
            files: [file],
            start: function (file) {
                data.uploadObject = this.upload;
                that.add(file, data);
            },
            progress: progress => this.progress(data, progress),
            success: response => this.uploadSuccess(data, response),
            error: error => this.trigger("log", error)
        });
    };

    // noinspection DuplicatedCode
    this.add = function (file, data) {
        data.files = [file];
        const o = data.files[0];
        o.fileSize = data.files[0].size / 1000;
        o.date = mixam.dateToLongString(o.lastModified);
        o.canRemove = 1;

        this.incInProcess(1);

        o.formatNumber = () => mixam.mustachFormatNumber;
        data.itemContext = $("<li>", {
            "class": "upload-item",
            "data-file-index": 0,
            html: Mustache.render(uploadItemTemplate, o)
        }).appendTo(this.select('fileListSelector'));

        data.itemContext.data('uploadData', data);
        this.trigger("uiFileAdded", {
            type: "upload",
            files: data.files
        });
        this.progress(data, 0);
    };

    this.progress = function (data, percent) {
        const progress = Math.round(percent);

        if (data.itemContext) {
            data.itemContext.find('[data-type=upload-progress] .bar').css(
                'width',
                progress + '%'
            );
        }
    };

    this.refreshCart = function () {
        window.dispatchEvent(new CustomEvent('reactCartRefresh'));
    };

    this.uploadSuccess = function (data, response) {
        if (data.itemContext) {
            if ("success" === response.status && response.id) {
                response.formatNumber = () => mixam.mustachFormatNumber;
                response.fileSize = response.size / 1000;

                // render the file
                data.itemContext.attr("data-file-id", response.id.replace(/[a-zA-Z]+$/, "pdf"));
                data.itemContext.attr("data-url", response.url);
                const o = $.extend({}, response);
                o.wait = true;
                o._id = o.id.replace(/\./g, ""); // legal id

                o.type = data.files[0].type;
                o.date = mixam.dateToLongString(data.files[0].lastModified);
                data.itemContext.html(Mustache.render(uploadItemTemplate, o));

                // tell the world that we have just added a new file
                this.trigger("uiAddFileRequested", {
                    id: response.id,
                    shoppingCartItemId: this.attr.shoppingCartItemId,
                    file: response
                });
                delete data.uploadObject;
                this.attr.urls.push(o.name);
            }
            this.incInProcess(-1);
        }
    };

    this.printboxReset = async function (event) {
        await axios.delete(`/api/print-box/orders/${$(event.currentTarget).data('order-id')}/items/${$(event.currentTarget).data('item-id')}`)
            .then(() => window.location.reload())
            .catch(error => console.log(error));
    };

    this.printboxEditorReturn = function (event) {
        this.printboxEditor(event);
    };

    this.printboxPreflight = function (event) {
        let $target = $(event.currentTarget),
            orderId = $target.data('order-id'),
            itemId = $target.data('item-id'),
            projectId = $target.data('pbx-project-id'),
            printboxPreflightRequested = $target.data(``),
            printboxPreflightReady = $target.data(`pbx-preflight-ready-id-${itemId}`);

        const printboxItemIdentifiers = {
            order_id: orderId,
            item_id: itemId,
            project_id: projectId,
            preflight_requested: true,
            preflight_ready: true,
            render_requested: false,
            render_ready: false,
            customer_verified_order: true
        };

        const response = axios.post(`/api/print-box/orders/${orderId}/items/${itemId}/preflight`, printboxItemIdentifiers, {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(() => {
            const preflightRequested = document.querySelector(`[data-type="pbx-preflight-requested-id-${itemId}"`);
            const preflightReady = document.querySelector(`[data-type="pbx-preflight-ready-id-${itemId}"`);
            $(preflightRequested).removeClass('printboxButtonsFail');
            $(preflightRequested).removeClass('fa-times');
            $(preflightRequested).addClass('fa-check');
            $(preflightRequested).addClass('printboxButtons');
            $(preflightReady).removeClass('printboxButtonsFail');
            $(preflightReady).removeClass('fa-times');
            $(preflightReady).addClass('fa-check');
            $(preflightReady).addClass('printboxButtons');
        });
    };

    this.printboxReorder = function (event) {
        let $target = $(event.currentTarget),
            orderId = $target.data('order-id'),
            itemId = $target.data('item-id'),
            isReorder = $target.data('pbx-is-reorder'),
            isDuplicate = $target.data('pbx-is-duplicate');

        const getPrintboxReorder = function () {
            try {
                return axios.get(`/api/print-box/orders/${orderId}/items/${itemId}/reorder`, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });
            } catch (error) {
                console.log(error);
            }
        };

        const duplicatePrintbox = () => {
            if (isReorder && !isDuplicate) {
                getPrintboxReorder().then(() =>
                    window.location.reload(true)
                );
            }
        };
        duplicatePrintbox();
    };

    this.printboxRenderer = function (event) {
        let $target = $(event.currentTarget),
            orderId = $target.data('order-id'),
            requestsMade = $target.data('requests-made');

        if (requestsMade) {
            return;
        }
        const spinner = $(document.getElementById('fullPageSpinner'));
        const renderRequestedElements = document.querySelectorAll('[data-type="printbox-render-requested"]');
        const renderReadyElements = document.querySelectorAll('[data-type="printbox-render-ready"]');
        const printboxTabElement = document.querySelector('[data-type="printbox-tab"]');
        const form2ContainerElements = document.querySelectorAll('[data-type="printbox-outer-container"]');
        const printboxContainerElements = document.querySelectorAll('[data-type="printbox-thumbnail-container"]');
        const printboxFinalRenderCounterElements = document.querySelectorAll('[data-type="printbox-final-render-counter"]');
        const fulfilmentTabElement = document.querySelector('[data-type="save-fulfilment"]');
        const printboxRenderNotification = document.querySelector('[data-type="requested-render-notification"]');
        spinner.removeClass('hidden');

        printboxFinalRender(
            orderId
        ).then()
            .catch((error) => {
                console.log("Assets Render Error:");
                console.log(error);
            }).finally(() => {
                for (const renderRequestedElement of renderRequestedElements) {
                    $(renderRequestedElement).removeClass('printboxButtonsFail');
                    $(renderRequestedElement).removeClass('fa-times');
                    $(renderRequestedElement).addClass('fa-check');
                    $(renderRequestedElement).addClass('printboxButtons');
                }
                for (const renderReadyElement of renderReadyElements) {
                    $(renderReadyElement).removeClass('printboxButtonsFail');
                    $(renderReadyElement).removeClass('fa-times');
                    $(renderReadyElement).addClass('fa-check');
                    $(renderReadyElement).addClass('printboxButtons');
                }
                for (const form2Container of form2ContainerElements) {
                    $(form2Container).removeClass('hidden');
                }
                for (const printboxContainer of printboxContainerElements) {
                    $(printboxContainer).addClass('hidden');
                }
                for (const printboxFinalRenderCounter of printboxFinalRenderCounterElements) {
                    let counter = printboxFinalRenderCounter.innerHTML;
                    counter++;
                    printboxFinalRenderCounter.innerHTML = counter;
                }
            $target.addClass('disabled');
            $target.attr("data-requests-made","true");
            $(printboxTabElement).removeClass('active');
            $(fulfilmentTabElement).removeClass('hidden');
            $(printboxRenderNotification).removeClass('hidden');
            spinner.addClass('hidden');
            });
    };

    const printboxFinalRender = async (orderId) => {
        const printboxItemIdentifiers = {
            order_id: orderId,
            item_id: null,
            project_id: null,
            preflight_ready: true,
            preflight_requested: false,
            render_requested: true,
            render_ready: true,
            customer_verified_order: true
        };
        return axios.post(`/api/print-box/orders/${orderId}/printbox-render`, printboxItemIdentifiers, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    };

    this.printboxEditor = function(event) {
        let $target = $(event.currentTarget),
            orderId = $target.data('order-id'),
            itemId = $target.data('item-id'),
            sessionToken = $target.data('token'),
            pageCount = $target.data('page-count'),
            theme = $target.data('pbx-theme'),
            productConfig = $target.data('pbx-product-config'),
            projectId = $target.data('pbx-project-id'),
            spineWidthDynamic = $target.data('pbx-spine-width'),
            isReorder = $target.data('pbx-is-reorder'),
            isDuplicate = $target.data('pbx-is-duplicate');

        let isPreflightReady = false;
        let isPreflightRequested = false;
        let printboxAttributes = {
            "theme": theme,
            "productConfig": productConfig
        };

        // If we have a projectId then the project has been saved in Printbox,
        // therefore pass null as attributes to go straight to editor
        if (projectId) {
            printboxAttributes = null;
        }

        $(`<div id="app" data-type="printbox-viewer" hidden></div>`).appendTo('body');
        const printboxViewer = document.querySelector("div[data-type='printbox-viewer']");
        const printboxItemIdentifiers =  {
            order_id: orderId,
            item_id: itemId,
            project_id: projectId,
            preflight_requested: isPreflightRequested,
            preflight_ready: isPreflightReady,
            render_requested: false,
            render_ready: false,
            customer_verified_order: false
        };

        const showPrintbox = () => {
            printboxViewer.hidden = printboxViewer.hidden !== true;
            document.documentElement.scrollTop = 0;
            document.body.scrollTop = 0;
        };
        showPrintbox();

        const printboxCurrency = () => {
            switch (mixam.shop.currency.prefix) {
                case '£':
                    return "GBP";
                case '$':
                    if (mixam.shop.locale === 'en_AU') {
                        return "AUD";
                    }
                    if (mixam.shop.locale === 'en_CA') {
                        return "CAD";
                    }
                    return "USD";
                case '€':
                    return "EUR";
                default:
                    return "GBP";
            }
        };

        const printboxCountry = () => {
            switch (mixam.shop.locale) {
                case 'en_GB':
                    return "UK_store";
                case 'en_US':
                    return "US_store";
                case 'en_CA':
                    return "CAN_store";
                case 'en_AU':
                    return "AUS_store";
                case 'en_DE':
                case 'de_DE':
                    return "DE_store";
            }
        };

        // Printbox Editor Params
        printbox.setEditorConfig({
            projectId: projectId,
            productFamilyId: "62",
            storeName: printboxCountry(),
            sessionId: sessionToken,
            showPrice: false,
            language: mixam.shop.locale.split('_')[0],
            translationsLanguage: mixam.shop.locale.split('_')[0],
            contentLanguage: mixam.shop.locale.split('_')[0],
            locale: mixam.shop.locale.replace('_', '-'),
            currency: printboxCurrency(),
            storeDisplayName: mixam.shop.title,
            pagesInitial: pageCount,
            attributeValues: printboxAttributes
        });

        // Called when returning to e-commerce and redirects back to order page
        printbox.showECommerce = function () {
            printboxViewer.hidden=true;
            window.location.assign(`/orders/${orderId}/artwork`);
        };

        // Creates an anonymous user and returns a session ID for use in Printbox Session
        printbox.authUserRequest = async function () {
            try {
                const response = await axios.post('/api/print-box/sessions', printboxItemIdentifiers, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });
                return response.data.session_key;
            } catch (error) {
                console.log(error);
            }
        };

        // Called when clicking Save button, must return void as expected by Printbox and saving is handled in Mixam backend
        printbox.savingFinished = async function (projectId) {
            try {
                printboxItemIdentifiers.project_id = projectId;
                await axios.put(`/api/print-box/orders/${orderId}/items/${itemId}/config`, printboxItemIdentifiers, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });
            } catch (error) {
                console.log(error);
            }
        };

        printbox.refreshSession = async function() {
            try {
                const response = await axios.post('/api/print-box/sessions/refresh', printboxItemIdentifiers, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });
                return response.data.session_key;
            } catch (error) {
                console.log(error);
            }
        };

        // Called when clicking Order button, however we don't want to use Printbox Order system,
        // so this is unimplemented until we get information from Printbox about returning files to us without the user paying on their system
        printbox.goToCartFinished = async function(projectId) {
            try {
                printboxItemIdentifiers.project_id = projectId;
                printboxItemIdentifiers.preflight_requested = false;
                printboxItemIdentifiers.customer_verified_order = true;
                await axios.put(`/api/print-box/orders/${orderId}/items/${itemId}/config`, printboxItemIdentifiers, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });
            } catch (error) {
                console.log(error);
            }
            printbox.showECommerce(true);
            return true;
        };

        // Printbox Dynamic Spine Width Implementation
        printbox.getSpineWidthMM = async () => {
            try {
                return spineWidthDynamic;
            } catch (error) {
                console.log(error);
            }
        };
    };

    this.printessEditor = function(event) {
        const $target = $(event.currentTarget),
            orderId = $target.data('order'),
            itemId = $target.data('item'),
            templateName = $target.data('template'),
            spineTemplate = $target.data('spine-template'),
            shopToken = $target.data('token'),
            documentToken = $target.attr('data-document-token'),
            spineToken = $target.attr('data-spine-token'),
            editorUrl = $target.data('editor'),
            documentSize = $target.data('document-size'),
            memberId = $target.data('member-id'),
            spineWidth = $target.data('spine-width'),
            pageCount = $target.data('page-count'),
            spineDocumentSize = $target.data('spine-document-size'),
            isAllowTemplateOptions = $target.data('allow-template-options'),
            isAllowDesignOptions = $target.data('allow-design-options'),
            sides = $target.data('form-sides'),
            hasInnerCoverPrinting = $target.data('inner-cover-printing'),
            $spinner = $(document.getElementById('fullPageSpinner'));

        const printessDocumentTemplate = documentToken || templateName;
        const printessSpineTemplate = spineToken || spineTemplate;

        const launchEditor = (templateName, documentSize, templateType) => {
            const $iFrame = $(`<iframe id="designOnline" src="${editorUrl}" allow="clipboard-read; clipboard-write"></iframe>`)
                .appendTo('body')
                .on('load', () => {
                    /*
                     * Printess User Manual: https://www.printess.com/kb/user-manual/
                     */
                    window.addEventListener("message", (event) => {
                        // this instance of the EventListener will only function for as long as this frame is attached.
                        if ($.contains(document, $iFrame[0])) {
                            switch (event.data.cmd) {
                                case "back":
                                    $iFrame.remove();
                                    $spinner.addClass('hidden');
                                    $('html').animate({
                                        scrollTop: $target.offset().top
                                    }, 1000);
                                    break;
                                case "basket":
                                    $iFrame.remove();
                                    $spinner.removeClass('hidden');
                                    if(templateType === PRINTESS_TEMPLATE_TYPE.SPINE) {
                                        $target.attr('data-spine-token', event.data.token);
                                    } else {
                                        $target.attr('data-document-token', event.data.token);
                                    }
                                    const url =`/api/printess/production/orders/${orderId}/items/${itemId}/template-types/${templateType}/token/${event.data.token}`;
                                    $.post(url).done(() => {
                                        $(window).one('uiRequestOrder', function (event) {
                                            setTimeout(() => $spinner.addClass('hidden'), 1000);
                                        });
                                        this.refreshCart();
                                    });
                                    $('html').animate({
                                        scrollTop: $target.offset().top
                                    }, 1000);
                                    break;
                                case "loaded":
                                    if (spineWidth) {
                                        $iFrame[0].contentWindow.postMessage({
                                            cmd: "setSpineFormular",
                                            parameters: [`${spineWidth}`]
                                        }, '*');
                                    }
                                    break;
                                case "save":
                                    axios.patch(`/api/printess/orders/${orderId}/items/${itemId}/template-types/${templateType}/token/${event.data.token}`)
                                        .then(response => {
                                            if(templateType === PRINTESS_TEMPLATE_TYPE.SPINE) {
                                                $target.attr('data-spine-token', event.data.token);
                                            } else {
                                                $target.attr('data-document-token', event.data.token);
                                            }
                                            this.refreshCart();
                                        });
                                    break;
                            }
                        }
                    });

                    const properties = {
                        token: shopToken,
                        templateName: templateName,
                        basketId: itemId,
                        formFields: [
                            {
                                name: "DOCUMENT_SIZE",
                                value: documentSize
                            },
                            {
                                name: "PAGE_COUNT",
                                value: pageCount
                            },
                            {
                                name: "SIDES",
                                value: sides
                            }
                        ],
                    };

                    if (memberId) {
                        properties.shopUserId = memberId;
                    }

                    if(templateType) {
                        properties.formFields.push({
                            name: "pageSettings",
                            value: templateType
                        });
                        properties.formFields.push({
                            name: "innerCovers",
                            value: `${hasInnerCoverPrinting}`
                        });
                    }

                    if (pageCount > 0) {
                        properties.bookInsidePages = pageCount;
                    }

                    $iFrame[0].contentWindow.postMessage({
                        cmd: "attach",
                        properties: properties
                    }, '*');
                });
        };

        if(isAllowTemplateOptions || isAllowDesignOptions) {
            
            if(isAllowDesignOptions) {

                if(documentToken) {
                    launchEditor(printessDocumentTemplate, documentSize);
                } else {

                    const $designOptionsModalContainer = $('<div data-type="printess-design-options"></div>')
                        .appendTo('body');
                    const root = ReactDom.createRoot($designOptionsModalContainer[0]);
                    root.render(
                        React.createElement(PrintessDesignOptionsModal, {
                            orderId: orderId,
                            itemId: itemId,
                            onCancelBtnClick: function () {
                                $designOptionsModalContainer.remove();
                                $spinner.addClass('hidden');
                            },
                            onSelectDesign: function (printessDesign) {
                                launchEditor(printessDesign.template, documentSize);
                                $designOptionsModalContainer.remove();
                                $spinner.addClass('hidden');
                            }
                        })
                    );
                }
                
            } else {
                if (isAllowTemplateOptions) {
                    const $templateOptionsModalContainer = $('<div data-type="printess-template-options"></div>')
                        .appendTo('body');
                    const root = ReactDom.createRoot($templateOptionsModalContainer[0]);
                    root.render(
                        React.createElement(PrintessTemplateOptionsModal, {
                            orderId: orderId,
                            itemId: itemId,
                            onCancelBtnClick: function () {
                                $templateOptionsModalContainer.remove();
                                $spinner.addClass('hidden');
                            },
                            onSelectTemplate: function (templateType) {
                                const templateToUse = templateType === PRINTESS_TEMPLATE_TYPE.SPINE ? printessSpineTemplate : printessDocumentTemplate;
                                const documentSizeToUse = templateType === PRINTESS_TEMPLATE_TYPE.SPINE ? spineDocumentSize : documentSize;
                                launchEditor(templateToUse, documentSizeToUse, templateType);
                                $templateOptionsModalContainer.remove();
                                $spinner.addClass('hidden');
                            }
                        })
                    );
                }
            }
            
        } else {
            launchEditor(printessDocumentTemplate, documentSize);
        }

    };

    this.after('initialize', function () {
        const itemDiv = this.getItemContainer();
        this.uploadUrl = this.$node.data('uploadUrl');
        this.designService = this.$node.data('designService');
        this.designServiceItemId = this.$node.data('designServiceItem');
        this.inProcess = 0;

        this.attr.shoppingCartItemId = this.attr.itemContainer.data('item');
        this.on("change", {
            uploadSelector: this.uploadStart
        });

        this.on("click", {
            closeBtnSelector: this.cancelClick,
            bleedTypeSelector: this.changeBleedType,
            bleedTypeAllSelector: this.changeBleedTypeAll,
            dropInSelector: this.dropIn,
            cancelImportBtnSelector: this.cancelImportUrl,
            importBtnSelector: this.importUrl,
            printessSelector: this.printessEditor,
            printboxSelector: this.printboxEditor,
            printboxResetSelector: this.printboxReset,
            printboxEditorReturnSelector: this.printboxEditorReturn,
            printboxPreflightSelector: this.printboxPreflight,
            printboxRender: this.printboxRenderer,
            printboxReorderSelector: this.printboxReorder
        });

        this.on("submit", {
            importFormSelector: this.submitImport
        });

        this.on(document, 'uiAfterAction', this.onAfterAction);
        this.on('dndHoverStart', this.dragHoverStarts);
        this.on('dndHoverEnd', this.dragHoverEnds);

        itemDiv.on({
            dragover: (event) => event.preventDefault(),
            dragenter: (event) => this.dragEnter(event),
            dragleave: (event) => this.dragLeave(event),
            drop: (event) => this.dragHoverEnds(event)
        });

        // Auto Open The Online Designer If We Need To
        if (this.designService && this.designServiceItemId === this.attr.shoppingCartItemId) {
           let elementName = 'printessSelector';
            const urlParams = new URLSearchParams(window.location.search);
            if (urlParams.get('designService') === "PRINTBOX") {
                elementName = 'printboxSelector';
            }
            const targetElement = this.select(elementName);
            $('html').animate({
                scrollTop: targetElement.offset().top
            }, 1000, function() {
                targetElement.click();
            });
        }
    });
}