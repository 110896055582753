import mixam from '../../../boot/mixam';
import defineComponent from '../../../../components/flight/lib/component';
import WithNormalizeMap from './../../with-normalize-map';
import WithFancyDate from './../../with-fancy-time';
import WithNormalizeMemberOrders from './../../with-normalize-member-orders';
import ReprintManager from './../../share/reprint-manager';
import ProblemEditor from './../problem-editor';
import regional from '../../../boot/regional';
import WithFolderUrl from '../../with-get-folder-url';

export default defineComponent(MemberOrdersManager, WithNormalizeMap, WithFancyDate, WithNormalizeMemberOrders, WithFolderUrl);

const GET_ORDER_DELAY = 250;

function genPopoverContent(instance) {
    return function () {
        return $.proxy(instance.getRemarksPopoverContent, instance, $(this));
    };
}

function handleClickPopover(popOver) {
    return function (event) {
        $(popOver).popover('hide');
        event.preventDefault();
    };
}

function handleKeyUpPopover(popOver) {
    return function (event) {
        if (event.keyCode === 27) {
            $(popOver).popover('hide');
        }
    };
}

/**
 * @deprecated Replaced by mixam-account
 */
function MemberOrdersManager() {

    this.attributes({
        mode: '',
        tableContainerSelector: '[data-type="table-container"]',
        tableSelector: '.table-member-order-list',
        popoverSelector: '[data-toggle="popover"]',
        sortBtnSelector: '[data-sort-key]',
        pagerPageSelector: '[data-page]',
        deleteOrderDialogueSelector: '#deleteOrderModalDialog',
        deleteOrderSelector: 'button[data-order-id]',
        deleteOrder2Selector: '[data-type="api-call"]',
        caseNumberSelector: '[data-type="order-case-number"]',
        reprintSelector: '[data-type="reprint-santa-editor"]',
        problemEditorSelector: '[data-type="problem-editor"]',
        checkPublishSelector: '[data-type="check-publish"]',
        selfPublishButtonSelector: '[data-type="self-publish"]',
        checkingAvailabilitySelector: '[data-type="publish-checking-availability"]',
        getStartedSelector: '[data-type="publish-get-started"]'
    });

    this.handlePagerClick = function (event) {
        const value = +$(event.target).closest("a[data-page]").data("page") - 1;

        event.preventDefault();
        if (this.page !== value) {
            this.page = value;
            this.getOrders();
        }

    };

    this.handleSortClick = function (event) {
        event.preventDefault();
        const $th = $(event.target).closest("[data-sort-key]"),
            isActive = $th.hasClass("active");

        this.page = 0;
        if (isActive) {
            this.sort.dir = this.sort.dir === "DESC" ? "ASC" : "DESC";
        } else {
            this.sort.key = $th.data("sort-key");
            this.sort.dir = "DESC";
        }

        this.getOrders();

    };

    this.enterDeleteOrder = function (event) {
        const $target = $(event.target).closest("[data-order-id]"),
            orderId = $target.data("order-id"),
            $tr = $target.closest("[data-order]"),
            caseNumber = $tr.data("order"),
            $childRows = $("tr[data-order-parent=" + caseNumber + "]");

        this.select('caseNumberSelector').text(caseNumber);
        this.select('deleteOrder2Selector').data("order", orderId);
        $tr.addClass("in-edit");
        $childRows.addClass("in-edit");
    };

    this.deleteOrder = function (event) {
        const $target = $(event.target).closest("button"),
            data = {},
            url = $target.data("href").supplant({orderId: $target.data("order")});

        if (!event.isDefaultPrevented()) {
            this.select('deleteOrder2Selector').attr('data-loading', '*').prop('disabled', true);
            if (mixam.user.impression) {
                data.memberId = mixam.user.id;
            }
            const promise = $.post(url, data, null, "json");

            promise.done((response) => {
                const $tr = $("tr.in-edit");

                if (response && response.status && response.status === "success") {
                    this.select('deleteOrderDialogueSelector').modal('hide');
                    this.trigger('showFixedMessage', {message: regional().messages[1032]});
                    $tr.hilightTableLine(() => $tr.remove());
                } else {
                    this.onCancelFail(response);
                }
            });
            promise.fail(response => this.onCancelFail(response));
        }
    };

    this.onCancelFail = function (/*response*/) {
        this.select('deleteOrderDialogueSelector').modal('hide');
        this.trigger('showFixedMessage', {message: regional().messages[1033]});
    };

    this.getProofInfo = function (event) {
        const $target = $(event.target),
            order = this.getOrder($target.data("order")),
            item = order && this.getItem(order, $target.data("item")),
            map = item && this.normalizeMap(order, item),
            pages = [];

        if (map) {
            map.pages.forEach(function (page) {
                pages.push(page);
            });
            if (map.spine) {
                pages.push(map.spine);
            }
            this.trigger(document, "uiSetProofInfo", {pages: pages});
        }
    };

    this.format = function (sum) {
        return (Math.round(sum * 10) / 10).formatNumber(2);
    };

    this.getOrders = function () {
        if (this.timer) {
            clearTimeout(this.timer);
        }
        this.timer = setTimeout(() => this.getOrdersImple(), GET_ORDER_DELAY);
    };

    this.getOrdersImple = function () {
        const userId = $("#historyMemberId").val() || (mixam.user.impression ? mixam.user.id : ""),
            url = `/member/api/${userId ? userId + '/' : ''}${this.attr.mode}/pages/${this.page}/${this.sort.key}/${this.sort.dir}`,
            promise = $.getJSON(url);

        this.select('tableContainerSelector').addClass("loading");

        promise.done(response => {
            this.select('tableContainerSelector').removeClass("loading");
            this.response = response;
            response.orderUrl = "/orders";
            this.select('tableContainerSelector').html(this.renderMemberOrders(response));
            if (this.attr.mode === 'orders') {
                ReprintManager.teardownAll();
                ProblemEditor.teardownAll();
                ReprintManager.attachTo(this.select('reprintSelector'));
                ProblemEditor.attachTo(this.select('problemEditorSelector'));
                response.content.forEach(order => order.items.forEach(item => this.trigger("dataShareItemReady", {
                    id: item.id,
                    order: order,
                    item: item
                })));
            }
        });
    };

    this.getOrder = function (orderId) {
        if (this.response) {
            for (let i = 0; i < this.response.content.length; i++) {
                const order = this.response.content[i];
                if (order.id === orderId) {
                    return order;
                }
            }
        }
        return null;
    };

    this.getItem = function (order, itemId) {
        let item;

        for (let i = 0; item = order.items[i]; i++) {  // jshint ignore:line
            if (item.id === itemId) {
                return item;
            }
        }
        return null;
    };

    this.getRemarksPopoverContent = function ($element) {
        const content = $element.data("remarks"),
            writer = [];

        if (content) {
            writer.push("<ul class='messages-container'>");
            content.split("~|~").forEach(remark => {
                const a = remark.split("^|^");
                if (a[0]) {
                    writer.push("<li class='" + ("true" === a[0] ? "" : "none-") + "nostro'>");
                    writer.push(a[1] + ", " + this.timeToDateString(new Date(+a[2])));
                    writer.push("</li>");
                }
            });
            writer.push("</ul>");
        }
        return writer.join("");
    };

    this.windowWasOpened = function (event) {
        if (event.target.id !== "collapseUploadImage" &&
            event.target.id !== "collapseUploadImageBtn" &&
            event.target.id !== "collapseResetImageBtn" &&
            event.target.id !== "collapseResetImage") {
            const $target = $(event.target);
            $target.scrollToView();
        }
    };

    this.checkPublish = function(event) {
        const $target = $(event.target),
            shareId = $target.data('share');
        $.ajax({
            url: '/api/offer/share',
            contentType: 'application/json',
            type: 'POST',
            data: JSON.stringify({shareId: shareId})
        }).done(response => {
            if (response.offerTable && response.offerTable[0]) {
                const offerItem = response.offerTable[0];
                this.select('checkingAvailabilitySelector').addClass('hidden');
                this.select('getStartedSelector').removeClass('hidden');
                this.select('selfPublishButtonSelector')
                    .data('mid', offerItem.mid)
                    .data('pid', offerItem.pid)
                    .data('days', offerItem.days)
                    .prop('disabled', false);
            }
        });
    };

    this.selfPublish = function(event) {
        const $target = $(event.currentTarget),
                orderId = $target.data('order'),
                itemId = $target.data('item'),
                mid = $target.data('mid'),
                pid = $target.data('pid'),
                days = $target.data('days');
        fetch('/api/publications', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify({
                orderId: orderId,
                itemId: itemId,
                mid: mid,
                pid: pid,
                days: days
            })
        }).then(async response => {
            const json = await response.json();
            window.location.href = `/orders/${json.itemReference.orderId}`;
        });
    };

    this.after('initialize', function () {
        this.page = 0;
        this.sort = {key: "time", dir: "DESC"};
        this.getOrders();

        this.select('tableContainerSelector').popover({
            trigger: "click", //"hover",
            selector: this.attr.popoverSelector,
            placement: "bottom",
            title: "User Details<button type='button' onclick=\"$(this).closest('td').find('[data-toggle=popover]').popover('hide')\" class='close' aria-hidden='true'>&times;</button>",
            content: genPopoverContent(this),
            html: true
        }).on('shown.bs.popover', (e) => {
            const target = e.target,
                isRemarks = "user-remarks" === $(target).data("type");

            if (isRemarks) {
                $(".popover-title").text("User Remarks");
            }

            this.on(document, "click.admin.manager", handleClickPopover(target));
            this.on(document, "keyup.admin.manager", handleKeyUpPopover(target));
        }).on('hidden.bs.popover', (/*event*/) => {
            this.off(document, "click.admin.manager");
        });

        this.on('uiNeedProofInfo', this.getProofInfo);
        this.on('shown.bs.collapse', this.windowWasOpened);

        this.select('deleteOrderDialogueSelector').on('hidden.bs.modal', () => {
            $("tr.in-edit").removeClass("in-edit");
            this.select('deleteOrder2Selector').removeAttr('data-loading').removeAttr('disabled');
        });

        if(this.attr.mode === 'quotes') {
            this.attr.deleteOrderDialogueSelector = '#deleteQuoteModalDialog';
        }

        this.on("click", {
            pagerPageSelector: this.handlePagerClick,
            sortBtnSelector: this.handleSortClick,
            deleteOrderSelector: this.enterDeleteOrder,
            deleteOrder2Selector: this.deleteOrder,
            checkPublishSelector: this.checkPublish,
            selfPublishButtonSelector: this.selfPublish
        });

    });
}
