var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import axios from "axios";
var configDataUrl = "/admin/api/awesome-query-builder/config";
var AwesomeQueryBuilderHelper = {
    getConfigData: function () { return __awaiter(void 0, void 0, void 0, function () {
        var response, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, axios.get(configDataUrl)];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response.data];
                case 2:
                    error_1 = _a.sent();
                    alert("Error Retrieving Configuration Data: ".concat(error_1));
                    throw error_1;
                case 3: return [2 /*return*/];
            }
        });
    }); },
    getEnumSpelFormat: function (classPath, value) {
        return "T(".concat(classPath, ").").concat(value);
    },
    getMultiSelectEnumSpelFormat: function (classPath, values) {
        var enumValueArray = values.map(function (val) { return AwesomeQueryBuilderHelper.getEnumSpelFormat(classPath, val); });
        return "{".concat(enumValueArray.join(', '), "}");
    },
    getConfigEnumOption: function (configData, key) {
        return configData[key].map(function (option) {
            return { value: option, title: option };
        });
    },
    mapToOptionList: function (configOptions, key) {
        var optionList = [];
        Object.entries(configOptions[key]).forEach(function (_a) {
            var id = _a[0], value = _a[1];
            optionList.push({ value: id, title: value });
        });
        return optionList;
    },
    mapToOptionGroupList: function (configOptions, key) {
        // Note: Option Group Lists Not Supported By Awesome Query Builder ATM So Display As List With The Key At The Start
        var optionList = [];
        Object.entries(configOptions[key]).map(function (_a) {
            var groupLabel = _a[0], groupValues = _a[1];
            return (Object.entries(groupValues).map(function (_a) {
                var value = _a[0], label = _a[1];
                return (optionList.push({ value: value, title: "".concat(groupLabel, " - ").concat(label) }));
            }));
        });
        return optionList;
    },
    initSubstrateWeightsList: function (configOptions) {
        var optionList = [];
        Object.entries(configOptions.substrateWeights).forEach(function (_a) {
            var substrateTypeId = _a[0], substrateWeights = _a[1];
            var weights = new Object(substrateWeights);
            Object.entries(weights).forEach(function (_a) {
                var weightId = _a[0], label = _a[1];
                // Remove The Duplicate Satin & Gloss Entries... I Wish We Had Split These Up Properly
                var existingOption = optionList.find(function (option) { return option.value === weightId; });
                var existingOptionIndex = optionList.indexOf(existingOption);
                if (existingOption) {
                    existingOption.title = "".concat(existingOption.title, " [Silk & Gloss]");
                    optionList[existingOptionIndex] = existingOption;
                }
                else {
                    optionList.push({ value: weightId, title: label });
                }
            });
        });
        return optionList;
    },
    getItemSpecificationConfig: function (configData) {
        return {
            label: "Item Specification",
            tooltip: "Item Specification",
            type: "!struct",
            subfields: {
                copies: {
                    label: "Copies",
                    type: "number"
                },
                bound: {
                    label: "Is Bound",
                    type: "boolean"
                },
                customSize: {
                    label: 'Is Custom Size',
                    type: 'boolean'
                },
                hasFoiling: {
                    label: 'Has Foiling',
                    type: 'boolean'
                },
                product: {
                    label: "Product",
                    type: "select",
                    valueSources: ["value"],
                    fieldSettings: {
                        listValues: AwesomeQueryBuilderHelper.getConfigEnumOption(configData, 'products')
                    },
                    widgets: {
                        select: {
                            widgetProps: {
                                spelFormatValue: function (val) {
                                    return AwesomeQueryBuilderHelper.getEnumSpelFormat('com.mixam.model.key.Product', val);
                                }
                            }
                        },
                        multiselect: {
                            widgetProps: {
                                spelFormatValue: function (val) {
                                    return AwesomeQueryBuilderHelper.getMultiSelectEnumSpelFormat('com.mixam.model.key.Product', val);
                                }
                            }
                        }
                    }
                },
                components: {
                    label: "Component",
                    type: "!group",
                    mode: "array",
                    subfields: {
                        componentType: {
                            label: "Component Type",
                            type: "select",
                            valueSources: ["value"],
                            fieldSettings: {
                                listValues: AwesomeQueryBuilderHelper.getConfigEnumOption(configData, 'componentTypes')
                            },
                            widgets: {
                                select: {
                                    widgetProps: {
                                        spelFormatValue: function (val) {
                                            return AwesomeQueryBuilderHelper.getEnumSpelFormat('com.mixam.model.key.ComponentType', val);
                                        }
                                    }
                                },
                                multiselect: {
                                    widgetProps: {
                                        spelFormatValue: function (val) {
                                            return AwesomeQueryBuilderHelper.getMultiSelectEnumSpelFormat('com.mixam.model.key.ComponentType', val);
                                        }
                                    }
                                }
                            }
                        },
                        hasCustomSize: {
                            label: 'Has Custom Size',
                            type: 'boolean'
                        },
                        laminatedComponent: {
                            label: 'Is Laminated Component',
                            type: 'boolean'
                        },
                        foiledComponent: {
                            label: 'Is Foiled Component',
                            type: 'boolean'
                        },
                        twoSidedComponent: {
                            label: 'Is Two Sided Component',
                            type: 'boolean'
                        },
                        customSize: {
                            label: 'Custom Size',
                            type: '!struct',
                            subfields: {
                                width: {
                                    label: 'Custom Size Width',
                                    type: 'number'
                                },
                                height: {
                                    label: 'Custom Size Height',
                                    type: 'number'
                                },
                                unitFormat: {
                                    label: "Custom Size Unit",
                                    type: "select",
                                    valueSources: ["value"],
                                    fieldSettings: {
                                        listValues: [
                                            { value: 'METRIC', title: 'METRIC' },
                                            { value: 'IMPERIAL', title: 'IMPERIAL' }
                                        ],
                                    },
                                    widgets: {
                                        select: {
                                            widgetProps: {
                                                spelFormatValue: function (val) {
                                                    return AwesomeQueryBuilderHelper.getEnumSpelFormat('com.mixam.shop.models.shop.UnitFormat', val);
                                                }
                                            }
                                        },
                                        multiselect: {
                                            widgetProps: {
                                                spelFormatValue: function (val) {
                                                    return AwesomeQueryBuilderHelper.getMultiSelectEnumSpelFormat('com.mixam.shop.models.shop.UnitFormat', val);
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        },
                        binding: {
                            label: "Binding (Bound Components)",
                            type: "!struct",
                            subfields: {
                                colour: {
                                    label: "Binding Colour",
                                    type: "select",
                                    valueSources: ["value"],
                                    fieldSettings: {
                                        listValues: AwesomeQueryBuilderHelper.getConfigEnumOption(configData, 'bindingColours')
                                    },
                                    widgets: {
                                        select: {
                                            widgetProps: {
                                                spelFormatValue: function (val) {
                                                    return AwesomeQueryBuilderHelper.getEnumSpelFormat('com.mixam.model.key.BindingColour', val);
                                                }
                                            }
                                        },
                                        multiselect: {
                                            widgetProps: {
                                                spelFormatValue: function (val) {
                                                    return AwesomeQueryBuilderHelper.getMultiSelectEnumSpelFormat('com.mixam.model.key.BindingColour', val);
                                                }
                                            }
                                        }
                                    }
                                },
                                edge: {
                                    label: "Binding Edge",
                                    type: "select",
                                    valueSources: ["value"],
                                    fieldSettings: {
                                        listValues: AwesomeQueryBuilderHelper.getConfigEnumOption(configData, 'bindingEdges')
                                    },
                                    widgets: {
                                        select: {
                                            widgetProps: {
                                                spelFormatValue: function (val) {
                                                    return AwesomeQueryBuilderHelper.getEnumSpelFormat('com.mixam.model.key.BindingEdge', val);
                                                }
                                            }
                                        },
                                        multiselect: {
                                            widgetProps: {
                                                spelFormatValue: function (val) {
                                                    return AwesomeQueryBuilderHelper.getMultiSelectEnumSpelFormat('com.mixam.model.key.BindingEdge', val);
                                                }
                                            }
                                        }
                                    }
                                },
                                loops: {
                                    label: "Binding Loops",
                                    type: "select",
                                    valueSources: ["value"],
                                    fieldSettings: {
                                        listValues: AwesomeQueryBuilderHelper.getConfigEnumOption(configData, 'bindingLoops')
                                    },
                                    widgets: {
                                        select: {
                                            widgetProps: {
                                                spelFormatValue: function (val) {
                                                    return AwesomeQueryBuilderHelper.getEnumSpelFormat('com.mixam.model.key.BindingLoops', val);
                                                }
                                            }
                                        },
                                        multiselect: {
                                            widgetProps: {
                                                spelFormatValue: function (val) {
                                                    return AwesomeQueryBuilderHelper.getMultiSelectEnumSpelFormat('com.mixam.model.key.BindingLoops', val);
                                                }
                                            }
                                        }
                                    }
                                },
                                type: {
                                    label: "Binding Type",
                                    type: "select",
                                    valueSources: ["value"],
                                    fieldSettings: {
                                        listValues: AwesomeQueryBuilderHelper.getConfigEnumOption(configData, 'bindingTypes')
                                    },
                                    widgets: {
                                        select: {
                                            widgetProps: {
                                                spelFormatValue: function (val) {
                                                    return AwesomeQueryBuilderHelper.getEnumSpelFormat('com.mixam.model.key.BindingType', val);
                                                }
                                            }
                                        },
                                        multiselect: {
                                            widgetProps: {
                                                spelFormatValue: function (val) {
                                                    return AwesomeQueryBuilderHelper.getMultiSelectEnumSpelFormat('com.mixam.model.key.BindingType', val);
                                                }
                                            }
                                        }
                                    }
                                },
                                headAndTailBands: {
                                    label: "Head & Tail Band",
                                    type: "select",
                                    valueSources: ["value"],
                                    fieldSettings: {
                                        listValues: AwesomeQueryBuilderHelper.getConfigEnumOption(configData, 'headAndTailBands'),
                                    },
                                    widgets: {
                                        select: {
                                            widgetProps: {
                                                spelFormatValue: function (val) {
                                                    return AwesomeQueryBuilderHelper.getEnumSpelFormat('com.mixam.model.key.HeadAndTailBands', val);
                                                }
                                            }
                                        },
                                        multiselect: {
                                            widgetProps: {
                                                spelFormatValue: function (val) {
                                                    return AwesomeQueryBuilderHelper.getMultiSelectEnumSpelFormat('com.mixam.model.key.HeadAndTailBands', val);
                                                }
                                            }
                                        }
                                    }
                                },
                                sewn: {
                                    label: "Sewn",
                                    type: "boolean"
                                },
                            }
                        },
                        substrate: {
                            label: "Substrate",
                            type: "!struct",
                            subfields: {
                                design: {
                                    label: "Substrate Design",
                                    type: "select",
                                    valueSources: ["value"],
                                    fieldSettings: {
                                        listValues: AwesomeQueryBuilderHelper.getConfigEnumOption(configData, 'substrateDesigns'),
                                    },
                                    widgets: {
                                        select: {
                                            widgetProps: {
                                                spelFormatValue: function (val) {
                                                    return AwesomeQueryBuilderHelper.getEnumSpelFormat('com.mixam.model.key.SubstrateDesign', val);
                                                }
                                            }
                                        },
                                        multiselect: {
                                            widgetProps: {
                                                spelFormatValue: function (val) {
                                                    return AwesomeQueryBuilderHelper.getMultiSelectEnumSpelFormat('com.mixam.model.key.SubstrateDesign', val);
                                                }
                                            }
                                        }
                                    }
                                },
                                typeId: {
                                    label: "Substrate Type",
                                    type: "select",
                                    valueSources: ["value"],
                                    fieldSettings: {
                                        listValues: AwesomeQueryBuilderHelper.mapToOptionList(configData, 'substrateTypes'),
                                    },
                                    widgets: {
                                        select: {
                                            widgetProps: {
                                                spelFormatValue: function (val) {
                                                    return val;
                                                }
                                            }
                                        },
                                        multiselect: {
                                            widgetProps: {
                                                spelFormatValue: function (val) {
                                                    return "{".concat(val, "}");
                                                }
                                            }
                                        }
                                    }
                                },
                                weightId: {
                                    label: "Substrate Weight ID",
                                    type: "select",
                                    valueSources: ["value"],
                                    fieldSettings: {
                                        listValues: AwesomeQueryBuilderHelper.initSubstrateWeightsList(configData)
                                    },
                                    widgets: {
                                        select: {
                                            widgetProps: {
                                                spelFormatValue: function (val) {
                                                    return val;
                                                }
                                            }
                                        },
                                        multiselect: {
                                            widgetProps: {
                                                spelFormatValue: function (val) {
                                                    return "{".concat(val, "}");
                                                }
                                            }
                                        }
                                    }
                                },
                                // This Might Become An Issue In The Future. Maybe By Then Option Group Selects Will Be Implemented
                                // So We Can Show A List Of All Colour Options Grouped By The Type Of Colour. For Now The only Way A
                                // Substrate Can Have A Colour Is End Papers
                                colourId: {
                                    label: "Substrate Colour ID",
                                    type: "select",
                                    valueSources: ["value"],
                                    fieldSettings: {
                                        listValues: AwesomeQueryBuilderHelper.mapToOptionList(configData, 'endPaperColours'),
                                    },
                                    widgets: {
                                        select: {
                                            widgetProps: {
                                                spelFormatValue: function (val) {
                                                    return val;
                                                }
                                            }
                                        },
                                        multiselect: {
                                            widgetProps: {
                                                spelFormatValue: function (val) {
                                                    return "{".concat(val, "}");
                                                }
                                            }
                                        }
                                    }
                                },
                            }
                        },
                        borderType: {
                            label: "Border Type",
                            type: "select",
                            valueSources: ["value"],
                            fieldSettings: {
                                listValues: AwesomeQueryBuilderHelper.getConfigEnumOption(configData, 'borderTypes')
                            },
                            widgets: {
                                select: {
                                    widgetProps: {
                                        spelFormatValue: function (val) {
                                            return AwesomeQueryBuilderHelper.getEnumSpelFormat('com.mixam.model.key.BorderType', val);
                                        }
                                    }
                                },
                                multiselect: {
                                    widgetProps: {
                                        spelFormatValue: function (val) {
                                            return AwesomeQueryBuilderHelper.getMultiSelectEnumSpelFormat('com.mixam.model.key.BorderType', val);
                                        }
                                    }
                                }
                            }
                        },
                        colours: {
                            label: "Colours",
                            type: "select",
                            valueSources: ["value"],
                            fieldSettings: {
                                listValues: AwesomeQueryBuilderHelper.getConfigEnumOption(configData, 'colours'),
                            },
                            widgets: {
                                select: {
                                    widgetProps: {
                                        spelFormatValue: function (val) {
                                            return AwesomeQueryBuilderHelper.getEnumSpelFormat('com.mixam.model.key.Colours', val);
                                        }
                                    }
                                },
                                multiselect: {
                                    widgetProps: {
                                        spelFormatValue: function (val) {
                                            return AwesomeQueryBuilderHelper.getMultiSelectEnumSpelFormat('com.mixam.model.key.Colours', val);
                                        }
                                    }
                                }
                            }
                        },
                        backColours: {
                            label: "Back Colours",
                            type: "select",
                            valueSources: ["value"],
                            fieldSettings: {
                                listValues: AwesomeQueryBuilderHelper.getConfigEnumOption(configData, 'colours'),
                            },
                            widgets: {
                                select: {
                                    widgetProps: {
                                        spelFormatValue: function (val) {
                                            return AwesomeQueryBuilderHelper.getEnumSpelFormat('com.mixam.model.key.Colours', val);
                                        }
                                    }
                                },
                                multiselect: {
                                    widgetProps: {
                                        spelFormatValue: function (val) {
                                            return AwesomeQueryBuilderHelper.getMultiSelectEnumSpelFormat('com.mixam.model.key.Colours', val);
                                        }
                                    }
                                }
                            }
                        },
                        coverArea: {
                            label: "Cover Area",
                            type: "select",
                            valueSources: ["value"],
                            fieldSettings: {
                                listValues: AwesomeQueryBuilderHelper.getConfigEnumOption(configData, 'coverAreas'),
                            },
                            widgets: {
                                select: {
                                    widgetProps: {
                                        spelFormatValue: function (val) {
                                            return AwesomeQueryBuilderHelper.getEnumSpelFormat('com.mixam.model.key.CoverArea', val);
                                        }
                                    }
                                },
                                multiselect: {
                                    widgetProps: {
                                        spelFormatValue: function (val) {
                                            return AwesomeQueryBuilderHelper.getMultiSelectEnumSpelFormat('com.mixam.model.key.CoverArea', val);
                                        }
                                    }
                                }
                            }
                        },
                        endPaperColour: {
                            label: "End Paper Colour",
                            type: "select",
                            valueSources: ["value"],
                            fieldSettings: {
                                listValues: AwesomeQueryBuilderHelper.getConfigEnumOption(configData, 'endPaperColours'),
                            },
                            widgets: {
                                select: {
                                    widgetProps: {
                                        spelFormatValue: function (val) {
                                            return AwesomeQueryBuilderHelper.getEnumSpelFormat('com.mixam.model.key.EndPaperColour', val);
                                        }
                                    }
                                },
                                multiselect: {
                                    widgetProps: {
                                        spelFormatValue: function (val) {
                                            return AwesomeQueryBuilderHelper.getMultiSelectEnumSpelFormat('com.mixam.model.key.EndPaperColour', val);
                                        }
                                    }
                                }
                            }
                        },
                        foiling: {
                            label: "Foiling Colour",
                            type: "select",
                            valueSources: ["value"],
                            fieldSettings: {
                                listValues: AwesomeQueryBuilderHelper.getConfigEnumOption(configData, 'foilingColours'),
                            },
                            widgets: {
                                select: {
                                    widgetProps: {
                                        spelFormatValue: function (val) {
                                            return AwesomeQueryBuilderHelper.getEnumSpelFormat('com.mixam.model.key.FoilingColour', val);
                                        }
                                    }
                                },
                                multiselect: {
                                    widgetProps: {
                                        spelFormatValue: function (val) {
                                            return AwesomeQueryBuilderHelper.getMultiSelectEnumSpelFormat('com.mixam.model.key.FoilingColour', val);
                                        }
                                    }
                                }
                            }
                        },
                        backFoiling: {
                            label: "Back Foiling Colour",
                            type: "select",
                            valueSources: ["value"],
                            fieldSettings: {
                                listValues: AwesomeQueryBuilderHelper.getConfigEnumOption(configData, 'foilingColours'),
                            },
                            widgets: {
                                select: {
                                    widgetProps: {
                                        spelFormatValue: function (val) {
                                            return AwesomeQueryBuilderHelper.getEnumSpelFormat('com.mixam.model.key.FoilingColour', val);
                                        }
                                    }
                                },
                                multiselect: {
                                    widgetProps: {
                                        spelFormatValue: function (val) {
                                            return AwesomeQueryBuilderHelper.getMultiSelectEnumSpelFormat('com.mixam.model.key.FoilingColour', val);
                                        }
                                    }
                                }
                            }
                        },
                        folded: {
                            label: "Is Folded",
                            type: "boolean"
                        },
                        format: {
                            label: "Format",
                            type: "select",
                            valueSources: ["value"],
                            fieldSettings: {
                                listValues: AwesomeQueryBuilderHelper.getConfigEnumOption(configData, 'formats'),
                            },
                            widgets: {
                                select: {
                                    widgetProps: {
                                        spelFormatValue: function (val) {
                                            return val;
                                        }
                                    }
                                },
                                multiselect: {
                                    widgetProps: {
                                        spelFormatValue: function (val) {
                                            return "{".concat(val, "}");
                                        }
                                    }
                                }
                            }
                        },
                        frameDepth: {
                            label: "Frame Depth",
                            type: "select",
                            valueSources: ["value"],
                            fieldSettings: {
                                listValues: AwesomeQueryBuilderHelper.getConfigEnumOption(configData, 'frameDepths'),
                            },
                            widgets: {
                                select: {
                                    widgetProps: {
                                        spelFormatValue: function (val) {
                                            return AwesomeQueryBuilderHelper.getEnumSpelFormat('com.mixam.model.key.FrameDepth', val);
                                        }
                                    }
                                },
                                multiselect: {
                                    widgetProps: {
                                        spelFormatValue: function (val) {
                                            return AwesomeQueryBuilderHelper.getMultiSelectEnumSpelFormat('com.mixam.model.key.FrameDepth', val);
                                        }
                                    }
                                }
                            }
                        },
                        lamination: {
                            label: "Lamination",
                            type: "select",
                            valueSources: ["value"],
                            fieldSettings: {
                                listValues: AwesomeQueryBuilderHelper.getConfigEnumOption(configData, 'laminations'),
                            },
                            widgets: {
                                select: {
                                    widgetProps: {
                                        spelFormatValue: function (val) {
                                            return AwesomeQueryBuilderHelper.getEnumSpelFormat('com.mixam.model.key.Lamination', val);
                                        }
                                    }
                                },
                                multiselect: {
                                    widgetProps: {
                                        spelFormatValue: function (val) {
                                            return AwesomeQueryBuilderHelper.getMultiSelectEnumSpelFormat('com.mixam.model.key.Lamination', val);
                                        }
                                    }
                                }
                            }
                        },
                        backLamination: {
                            label: "Back Lamination",
                            type: "select",
                            valueSources: ["value"],
                            fieldSettings: {
                                listValues: AwesomeQueryBuilderHelper.getConfigEnumOption(configData, 'laminations'),
                            },
                            widgets: {
                                select: {
                                    widgetProps: {
                                        spelFormatValue: function (val) {
                                            return AwesomeQueryBuilderHelper.getEnumSpelFormat('com.mixam.model.key.Lamination', val);
                                        }
                                    }
                                },
                                multiselect: {
                                    widgetProps: {
                                        spelFormatValue: function (val) {
                                            return AwesomeQueryBuilderHelper.getMultiSelectEnumSpelFormat('com.mixam.model.key.Lamination', val);
                                        }
                                    }
                                }
                            }
                        },
                        orientation: {
                            label: "Orientation",
                            type: "select",
                            valueSources: ["value"],
                            fieldSettings: {
                                listValues: AwesomeQueryBuilderHelper.getConfigEnumOption(configData, 'orientations'),
                            },
                            widgets: {
                                select: {
                                    widgetProps: {
                                        spelFormatValue: function (val) {
                                            return AwesomeQueryBuilderHelper.getEnumSpelFormat('com.mixam.model.key.Orientation', val);
                                        }
                                    }
                                },
                                multiselect: {
                                    widgetProps: {
                                        spelFormatValue: function (val) {
                                            return AwesomeQueryBuilderHelper.getMultiSelectEnumSpelFormat('com.mixam.model.key.Orientation', val);
                                        }
                                    }
                                }
                            }
                        },
                        pages: {
                            label: "Pages",
                            type: "number"
                        },
                        preDrilledHoles: {
                            label: "Pre Drilled Holes",
                            type: "select",
                            valueSources: ["value"],
                            fieldSettings: {
                                listValues: AwesomeQueryBuilderHelper.getConfigEnumOption(configData, 'preDrilledHoles')
                            },
                            widgets: {
                                select: {
                                    widgetProps: {
                                        spelFormatValue: function (val) {
                                            return AwesomeQueryBuilderHelper.getEnumSpelFormat('com.mixam.model.key.PreDrilledHoles', val);
                                        }
                                    }
                                },
                                multiselect: {
                                    widgetProps: {
                                        spelFormatValue: function (val) {
                                            return AwesomeQueryBuilderHelper.getMultiSelectEnumSpelFormat('com.mixam.model.key.PreDrilledHoles', val);
                                        }
                                    }
                                }
                            }
                        },
                        ribbonColour: {
                            label: "Ribbon Colour",
                            type: "select",
                            valueSources: ["value"],
                            fieldSettings: {
                                listValues: AwesomeQueryBuilderHelper.getConfigEnumOption(configData, 'ribbonColours'),
                            },
                            widgets: {
                                select: {
                                    widgetProps: {
                                        spelFormatValue: function (val) {
                                            return AwesomeQueryBuilderHelper.getEnumSpelFormat('com.mixam.model.key.RibbonColour', val);
                                        }
                                    }
                                },
                                multiselect: {
                                    widgetProps: {
                                        spelFormatValue: function (val) {
                                            return AwesomeQueryBuilderHelper.getMultiSelectEnumSpelFormat('com.mixam.model.key.RibbonColour', val);
                                        }
                                    }
                                }
                            }
                        },
                        roundCorners: {
                            label: "Rounded Corners",
                            type: "boolean"
                        },
                        simpleFold: {
                            label: "Simple Fold",
                            type: "select",
                            valueSources: ["value"],
                            fieldSettings: {
                                listValues: AwesomeQueryBuilderHelper.getConfigEnumOption(configData, 'simpleFolds'),
                            },
                            widgets: {
                                select: {
                                    widgetProps: {
                                        spelFormatValue: function (val) {
                                            return AwesomeQueryBuilderHelper.getEnumSpelFormat('com.mixam.model.key.SimpleFold', val);
                                        }
                                    }
                                },
                                multiselect: {
                                    widgetProps: {
                                        spelFormatValue: function (val) {
                                            return AwesomeQueryBuilderHelper.getMultiSelectEnumSpelFormat('com.mixam.model.key.SimpleFold', val);
                                        }
                                    }
                                }
                            }
                        },
                        standardSize: {
                            label: "Standard Size",
                            type: "select",
                            valueSources: ["value"],
                            fieldSettings: {
                                listValues: AwesomeQueryBuilderHelper.getConfigEnumOption(configData, 'standardSizes'),
                            },
                            widgets: {
                                select: {
                                    widgetProps: {
                                        spelFormatValue: function (val) {
                                            return AwesomeQueryBuilderHelper.getEnumSpelFormat('com.mixam.model.key.StandardSize', val);
                                        }
                                    }
                                },
                                multiselect: {
                                    widgetProps: {
                                        spelFormatValue: function (val) {
                                            return AwesomeQueryBuilderHelper.getMultiSelectEnumSpelFormat('com.mixam.model.key.StandardSize', val);
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        };
    }
};
export default AwesomeQueryBuilderHelper;
