import defineComponent from '../../../../components/flight/lib/component';
import React from 'react';
import ReactDom from 'react-dom/client';
import TcoConfigurationEditor from '../React/TcoConfiguration/TcoConfigurationEditor.tsx';

export default defineComponent(AdminTcoConfiguration);

function AdminTcoConfiguration() {

    this.init = function () {

        const tcoConfigurationEditor = $(this.node);

        const root = ReactDom.createRoot(tcoConfigurationEditor.get(0));
        root.render(
            <TcoConfigurationEditor
                machineName={tcoConfigurationEditor.data('machine')}
            ></TcoConfigurationEditor>
        );
    };

    this.after('initialize', function () {
        this.init();
    });

}
