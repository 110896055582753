import defineComponent from '../../../../components/flight/lib/component';
import DataTable from './../../table/data-table-net/data-table-net.tsx';
import {Breakpoints} from "../../table/data-table-net/StyledDataTableNet.tsx";
import axios from "axios";

export default defineComponent(SuppliersDataTable);

function SuppliersDataTable() {

    this.attributes({
        url: "suppliers/data.json",
        dataTableSelector: '[data-type="data-table"]'
    });

    this.createSchema = function () {
        const cols = [];
        cols.push({
            title: "",
            type: "thumbnail",
            data:"logo"
        });
        cols.push({
            title: "Name",
            type: "text",
            data: "name",
            defaultSort: true
        });
        cols.push({
            title: "Active",
            type: "boolean",
            data: "active",
            hide: Breakpoints.LG
        });
        cols.push({
            title: "Machines",
            type: "text",
            data: "machines",
            width: '400px',
            hide: Breakpoints.LG
        });
        cols.push({
            title: "Maximum Daily Fulfilment's",
            type: "number",
            data: "maxDailyFulfilments",
            hide: Breakpoints.LG
        });
        cols.push({
            title: "",
            type: "multiButton",
            data: "actions",
            width: '175px'
        });
        return cols;
    };


    this.paint = function () {
        const data = {
            columns: this.createSchema(),
            fileName: this.attr.url.split("/").pop(),
            response: this.normalize(this.data.list)
        };

        if (this.dataReadyFired) {
            window.dispatchEvent(new CustomEvent('uiDataUpdate', { detail: data }));
        } else {
            this.dataReadyFired = true;
            this.trigger("uiDataReady", data);
        }
    };

    this.normalize = function (list) {
        return list.map(x => this.normalizeSupplier(x));
    };

    this.normalizeSupplier = function (supplier_) {
        const supplier = $.extend(true, {}, supplier_);
        supplier.logo = {
            src: supplier.logo,
            href: `suppliers/${supplier_.id}`,
            title: supplier.name,
            target: "_self"
        };
        supplier.machines = supplier_.machines ? supplier_.machines.join(', ') : '';
        supplier.actions = [
            {
                href: `suppliers/${supplier_.id}/edit`,
                caption: "Edit",
                target: "_self",
                className: "btn btn-primary"
            },
            {
                href: `suppliers/${supplier_.id}/export-prices`,
                caption: "Export",
                target: "_self",
                className: "btn btn-secondary"
            }
        ];
        supplier.api = supplier.orderingApi ? supplier.orderingApi.type : '';
        supplier.webhookToken = '';
        if(supplier.orderingApi && supplier.orderingApi.webhook) {
            supplier.webhookToken = supplier.orderingApi.webhook.pathToken;
        }
        supplier.maxDailyFulfilments = supplier.fulfilmentLimits ? supplier.fulfilmentLimits.maxDailyFulfilments : '';
        return supplier;
    };

    this.getData = function () {
        axios.get(this.attr.url)
            .then(response => {
                this.data = response.data;
                this.paint();
                setTimeout(() => this.getData(), 1000 * 60 * 15);
            }).catch(error => this.trigger("log", {message: error}));
    };

    this.after('initialize', function () {
        DataTable.attachTo(this.select('dataTableSelector'));
        setTimeout(() => this.getData(), 10);
    });
}
