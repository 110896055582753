import defineComponent from '../../../../components/flight/lib/component';
import ApiType from '../../constants/apiType';
import ApiMethod from '../../constants/apiMethod';

export default defineComponent(SupplierEditor);

function SupplierEditor() {

    this.attributes({
        collectionCentreSelect: '[data-field="collection-centre"]',
        collectionCentreName: '[data-field="collection-centre-name"]',
        collectionCentreOrganisation:'[data-field="collection-centre-organisation"]',
        collectionCentreStreet: '[data-field="collection-centre-street"]',
        collectionCentreLocality: '[data-field="collection-centre-locality"]',
        collectionCentreTown: '[data-field="collection-centre-town"]',
        collectionCentreCounty: '[data-field="collection-centre-county"]',
        collectionCentrePostcode: '[data-field="collection-centre-postcode"]',
        collectionCentreCountryCode: '[data-field="collection-centre-country-code"]',
        collectionCentreAddress: '[data-type="collection-centre-address"]',
        apiTypeSelector: '[data-type="api-type"]',

        apiProductionPortContainerSelector: '[data-type="api-production-port-container"]',
        apiStagingPortContainerSelector: '[data-type="api-staging-port-container"]',

        apiProductionPathContainerSelector: '[data-type="api-production-path-container"]',
        apiStagingPathContainerSelector: '[data-type="api-staging-path-container"]',

        apiSettingsContainerSelector: '[data-type="api-settings-container"]',
        apiMethodSelector: '[data-field="api-method"]',
        addAdditionalUserBtn: '[data-type="add-additional-user-btn"]',
        additionalUser: '[data-field="additional-user"]',
        additionalUsersContainer: '[data-type="additional-users"]',
        removeAdditionalUserBtn: '[data-type="remove-additional-user-btn"]',
        customApiHeadersTableBodyprod: '[data-type="custom-api-headers-table-body-prod"]',
        customApiHeadersTableBodydev: '[data-type="custom-api-headers-table-body-dev"]',
        addCustomApiHeaderBtn: '[data-type="btn-add-custom-header"]',
        deleteCustomApiHeaderBtn: '[data-type="btn-delete-custom-header"]'
    });

    this.displayCollectionCentre = function(event) {
        const collectionCentreUrl = `/admin/api/collection-centre/`;
        const data = {
            collectionCentreId: $(event.target).val()
        };

        if(data.collectionCentreId) {

            $.post(collectionCentreUrl, data, null, 'json')
                .done(data => {
                    this.select('collectionCentreName').text(data.name);
                    this.select('collectionCentreOrganisation').text(data.collectionDetails.address.organisation);
                    this.select('collectionCentreStreet').text(data.collectionDetails.address.street);
                    this.select('collectionCentreLocality').text(data.collectionDetails.address.locality);
                    this.select('collectionCentreTown').text(data.collectionDetails.address.town);
                    this.select('collectionCentreCounty').text(data.collectionDetails.address.county);
                    this.select('collectionCentrePostcode').text(data.collectionDetails.address.postcode);
                    this.select('collectionCentreCountryCode').text(data.collectionDetails.address.countryCode);
                    this.select('collectionCentreAddress').removeClass('d-none');
                })
                .fail((err) => this.trigger("log", {message: err}));
        } else {
            this.select('collectionCentreAddress').addClass('d-none');
        }
    };

    this.onApiTypeChange = function(event) {
        const apiType = $(event.target).val();
        switch(apiType) {
            case ApiType.MXJDF4:
            case ApiType.PRECISION_PROCO_SITE_FLOW:
                this.select('apiSettingsContainerSelector').removeClass('d-none');
                this.select('apiSettingsContainerSelector').find('[data-mxjdf="true"]').removeClass('d-none');
                this.select('apiSettingsContainerSelector').find('[data-mxjdf="false"]').addClass('d-none');
                break;
            case ApiType.SITE_FLOW:
                this.select('apiSettingsContainerSelector').removeClass('d-none');
                this.select('apiSettingsContainerSelector').find('[data-siteflow="true"]').removeClass('d-none');
                this.select('apiSettingsContainerSelector').find('[data-siteflow="false"]').addClass('d-none');
                break;
            case ApiType.SOLOPRESS:
            case ApiType.TRADEPRINT_API:
            case ApiType.FLYER_ALARM:
            case ApiType.DOCUMATION:
            case ApiType.BLUETREE:
            case ApiType.TAYLORS:
            case ApiType.PRINTED:
            case ApiType.CXML:
            case ApiType.RPI:
                this.select('apiSettingsContainerSelector').removeClass('d-none');
                this.select('apiSettingsContainerSelector').find('[data-generic="true"]').removeClass('d-none');
                this.select('apiSettingsContainerSelector').find('[data-generic="false"]').addClass('d-none');
                break;
            case ApiType.NONE:
            default:
                this.select('apiSettingsContainerSelector').addClass('d-none');
                break;
        }
    };

    this.onApiMethodChange = function(event) {
        const apiMethod = $(event.target).val();
        const apiForm = $(event.target).data('type');

        const apiPortField = apiForm === 'production' ? this.select('apiProductionPortContainerSelector') : this.select('apiStagingPortContainerSelector');
        const apiPathField = apiForm === 'production' ? this.select('apiProductionPathContainerSelector') : this.select('apiStagingPathContainerSelector');

        switch(apiMethod) {
            case ApiMethod.FTP:
            case ApiMethod.SFTP:
                apiPortField.removeClass('d-none');
                apiPathField.removeClass('d-none');
                break;
            case ApiMethod.POST:
                apiPortField.addClass('d-none');
                apiPathField.addClass('d-none');
                break;
        }
    };

    this.addAdditionalUser = function(event) {
        const addUserUrl = '/admin/catalogue/suppliers/user/add';
        const additionalUserEmail = this.select('additionalUser').val();
        const data = {
            supplierId: this.supplierId,
            emailAddress: additionalUserEmail
        };

        $.post(addUserUrl, data).done((response) => {
            this.select('additionalUsersContainer').html(response);
        });
    };

    this.removeAdditionalUser = function(event) {
        const addUserUrl = '/admin/catalogue/suppliers/user/remove';
        const additionalUserId = $(event.target).data('user-id');
        const data = {
            supplierId: this.supplierId,
            contactId: additionalUserId
        };

        $.post(addUserUrl, data).done((response) => {
            this.select('additionalUsersContainer').html(response);
        });
    };

    this.addCustomApiHeader = function(event) {
        const apiType = $(event.target).data('api-type');
        const customHeaderTable = `customApiHeadersTableBody${apiType}`;
        const newIndex = this.select(customHeaderTable).children().length;
        const newHeaderRow = `
            <tr>
                <td>
                    <input type="text" class="form-control" name="orderingApi.${apiType}.customApiHeaders[${newIndex}].key" required/>
                </td>
                <td>
                    <input type="text" class="form-control" th:field="orderingApi.${apiType}.customApiHeaders[${newIndex}].value" required/>
                </td>
                <td class="text-right">
                    <button class="btn btn-danger delete-button" data-type="btn-delete-custom-header">Delete</button>
                </td>
            </tr>
        `;
        this.select(customHeaderTable).append(newHeaderRow);
    };

    this.deleteCustomApiHeader = function(event) {
        $(event.target).closest('tr').remove();
    };

    this.after('initialize', function () {
        this.supplierId = $(this.node).data('supplier-id');
        this.on('change', {
            collectionCentreSelect: this.displayCollectionCentre,
            apiTypeSelector: this.onApiTypeChange,
            apiMethodSelector: this.onApiMethodChange
        });
        this.on('click', {
            addAdditionalUserBtn: this.addAdditionalUser,
            removeAdditionalUserBtn: this.removeAdditionalUser,
            addCustomApiHeaderBtn: this.addCustomApiHeader,
            deleteCustomApiHeaderBtn: this.deleteCustomApiHeader
        });
        this.select('apiTypeSelector').trigger('change');
        this.select('apiMethodSelector').trigger('change');
    });
}
