import mixam from '../../boot/mixam';
import defineComponent from '../../../components/flight/lib/component';
import DataTable from '../table/data-table-net/data-table-net.tsx';
import productMap from '../constants/product-list';

export default defineComponent(MergeProcess);

const mergeStateMap = {
    0: 'INIT',
    1: 'STEP1',
    2: 'COMPLETED',
    11: 'MIGHT_FAILED',
    15: 'FAILED'
};

function MergeProcess() {

    this.attributes({
        url: "/admin/api/mergeprocess/list",
        dataTableSelector: '[data-type="data-table"]'
    });

    this.createSchema = function () {
        const cols = [];

        cols.push({
            title: '',
            type: "thumbnail",
            width: "6%",
            data:"front"
        });
        cols.push({
            title: "Order",
            type: "link",
            width: "10%",
            data:"href"
        });

        cols.push({
            title: "Product",
            type: "text",
            width: "8%",
            data:"product"
        });

        cols.push({
            title: "Date Created",
            type: "datetime",
            width: "10%",
            data:"dateCreated"
        });

        cols.push({
            title: "Date Ended",
            type: "datetime",
            width: "10%",
            data:"dateEnded",
            defaultSort: true,
            sortOrder: 'desc'
        });

        cols.push({
            title: "status",
            type: "text",
            width: "12%",
            data:"currentState"
        });

        cols.push({
            title: "Success",
            type: "boolean",
            width: "4%",
            data:"success"
        });

        cols.push({
            title: "Server",
            type: "text",
            width: "14%",
            data:"hostname"
        });

        cols.push({
            title: "Error",
            type: "text",
            width: "18%",
            data:"error"
        });

        cols.push({
            title: "Expected Cost",
            type: "number",
            decimal: 2,
            currency: true,
            width: "8%",
            data:"ourCost",
            sum: true
        });

        return cols;
    };

    this.paint = function () {
        const data = {
            columns: this.createSchema(),
            fileName: this.attr.url.split("/").pop(),
            response: this.normalize(this.data.list),
            "class": 'table-striped table-order-list',
            "default-sort": {
                "dateCreated": -1
            }
        };

        if (this.dataReadyFired) {
            window.dispatchEvent(new CustomEvent('uiDataUpdate', { detail: data }));
        } else {
            this.dataReadyFired = true;
            this.trigger("uiDataReady", data);
        }
    };

    this.normalize = function (list) {
        return list.map(x => this.normalizeJob(x));
    };

    this.normalizeJob = function (job) {
        const so = $.extend(true, {}, job);

        so.front = {
            src: job.thumbnailUrl,
            href: `/orders/${job.orderId}/artwork`,
            title: "",
            target: "_blank"
        };
        so.override = so.override || {};
        so.override.td = so.override.td || {};
        so.override.td.front = "orient-" + job.thumbnailOrientaion;

        so.href = {
            href: `/orders/${job.orderId}/artwork`,
            caption: `${job.caseNumber}${job.index ? "/" + job.index : ''}`,
            title: ""
        };
        so.product = productMap[job.productId];
        so.currentState = mergeStateMap[job.status];

        return so;
    };

    this.getData = function () {
        $.getJSON(this.attr.url)
            .done(data => {
                this.data = data;
                this.paint();
                setTimeout(() => this.getData(), 1000 * 60 * 15);
            })
            .fail((err) => this.trigger("log", {message: err}));
    };

    /**
     * subscribe to STOMP service that notify us about changes
     * in a specific order and a specific item
     */
    this.subscribe = function (onMessage) {
        this.trigger("log", {message: ["Subscribe to stomp channel allmergechanel"], title: "MergeProcess.subscribe"});
        this.stomp = Stomp.client(mixam.stompServiceUrl);

        this.stomp.debug = (...args) => {
            if (args.join('').indexOf('Whoops! Lost connection to') !== -1) {
                setTimeout(() => this.subscribe(onMessage), 10);
            }
            this.trigger("log", {message: args, title: "MergeProcess.subscribe"});
        };

        this.stomp.connect(mixam.stompWebUser, mixam.stompWebPass, () => {
            this.stomp.subscribe("/topic/allmergechanel", (d) => {
                const p = JSON.parse(d.body);
                onMessage(p);
            });
        }, (err) => this.trigger("log", err), '/');
    };

    this.updateJobLine = function(data) {
        if (!this.data || !data.data) {
            return;
        }
        let verb = data.type;
        const mergeJob = data.data;
        if (mergeJob.shopId !== mixam.shop.id) {
            return;
        }
        if (mergeJob) {
            const index = this.data.list.map(x => x.id).indexOf(mergeJob.id);

            if (verb === "UPDATE" || verb === "INSERT") {
                if (index !== -1) {
                    this.data.list[index] = mergeJob;
                } else {
                    this.data.list.unshift(mergeJob);
                }

            } else if (verb === "DELETE" && index !== -1) {
                this.data.list.splice(index, 1);
            }

            this.paint();
            requestAnimationFrame(() => $("#PK" + mergeJob.id).hilightTableLine());
        }
    };

    this.after('initialize', function () {
        DataTable.attachTo(this.select('dataTableSelector'));
        setTimeout(() => this.getData(), 10);
        this.subscribe(this.updateJobLine.bind(this));

        try {
        } catch (e) {

        }

    });
}
