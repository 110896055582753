import defineComponent from '../../../components/flight/lib/component';

export default defineComponent(FilterUnit);

function FilterUnit() {

    this.attributes({
        filterComponentSelector: '[data-type="filter-input"]:not(.tt-hint), [data-type="filter-checkbox"]',
        filterCheckboxSelector: '[data-type="filter-checkbox"]',
        filterInputSelector: '[data-type="filter-input"]',
        dropdownSelector: '.dropdown-toggle',
        operatorValueSelector: '[data-type="operator"]',
        operatorCaptionSelector: '.operator .dropdown-toggle .caption',
        linkOptionSelector: '.operator .dropdown-menu a',
        linkOptionIconsSelector: '.icons .dropdown-menu a',
        operatorValueIconsSelector: '.icons [data-type="filter-input"]',
        operatorCaptionIconsSelector: '.icons .dropdown-toggle .caption'
    });

    this.render = function () {
        if (this.hasMouse || this.hasFocus || this.hasValue()) {
            if (!this.$node.hasClass('active')) {
                this.$node.addClass("active");
            }
        } else {
            if (this.$node.hasClass('active')) {
                this.$node.removeClass("active");
            }
        }
    };

    this.hasValue = function () {
        var $input = this.select('filterComponentSelector');

        if ($input.hasClass("tristate")) {
            return Boolean(+$input.val());
        }

        return Boolean($input.val());
    };

    this.mouseEnter = function (event) {
        this.hasMouse = true;
        //this.render();
    };

    this.mouseLeave = function (event) {
        this.hasMouse = false;
        this.render();
    };

    this.onFocus = function () {
        this.hasFocus = true;
        this.render();
    };

    this.onBlur = function () {
        this.hasFocus = false;
        this.render();
    };

    this.operatorChange = function (event) {
        var $target = $(event.target).closest("a");

        event.preventDefault();
        this.select('operatorCaptionSelector').text($target.data('caption'));
        this.select('operatorValueSelector').val($target.attr('href'));
        this.trigger('uiFilterOperatorChange');
    };

    this.iconsChange = function (event) {
        var $target = $(event.target).closest("a"),
            value = $target.attr('href').replace("None", ""),
            htmlValue = value ? `<i class="fa ${value}"></i>` : "None";

        event.preventDefault();
        this.select('operatorCaptionIconsSelector').html(htmlValue);
        this.select('operatorValueIconsSelector').val(value);
        this.trigger('uiFilterOperatorChange');
    };

    this.after('initialize', function () {
        this.hasMouse = false;
        this.hasFocus = false;

        this.on("mouseenter", this.mouseEnter);
        this.on("mouseleave", this.mouseLeave);

        this.$node.find(this.attr.filterComponentSelector)
            .on("focus", $.proxy(this.onFocus, this))
            .on("blur", $.proxy(this.onBlur, this));

        this.on("click", {
            linkOptionSelector: this.operatorChange,
            linkOptionIconsSelector: this.iconsChange
        });
    });
}

