export var MarkupType;
(function (MarkupType) {
    MarkupType["NONE"] = "NONE";
    MarkupType["FIXED_RETAIL"] = "FIXED_RETAIL";
    MarkupType["PERCENTAGE"] = "PERCENTAGE";
    MarkupType["FIXED_MARKUP"] = "FIXED_MARKUP";
    MarkupType["DISTRIBUTION"] = "DISTRIBUTION";
})(MarkupType || (MarkupType = {}));
export var PreviewType;
(function (PreviewType) {
    PreviewType["NONE"] = "NONE";
    PreviewType["ALL_PAGES"] = "ALL_PAGES";
    PreviewType["LIMITED_PAGES"] = "LIMITED_PAGES";
})(PreviewType || (PreviewType = {}));
