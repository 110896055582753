import defineComponent from '../../../../../components/flight/lib/component';

export default defineComponent(CounterDisplay);

function CounterDisplay() {
    var INTERVAL = 100;

    this.attributes({
        counterId: ''
    });

    this.clock = function () {
        if (this.targetValue !== this.value) {
            this.value += this.targetValue > this.value ? 1 : -1;
            this.display();

            if (this.targetValue !== this.value) {
                setTimeout($.proxy(this.clock, this), INTERVAL);
            }
        }
    };

    this.display = function () {
        this.$node.text(this.value);
    };

    this.updateCounter = function (event, data) {
        if (data.counterId === this.attr.counterId) {
            if (this.value !== data.value) {
                this.targetValue = data.value;
                this.clock();
            }
        }
    };

    this.after('initialize', function () {
        this.attr.counterId = this.attr.counterId || this.data('counterId');
        this.value = 0;
        this.on(document, "updateCounter", this.updateCounter);
        this.display();
    });
}