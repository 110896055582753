var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import mixam from '../../../../../boot/mixam.js';
import React, { useEffect, useState } from 'react';
import { Formik, Field } from 'formik';
import FormikTableModal from "../FormikTableModal";
import Card from 'react-bootstrap/Card';
import axios from "axios";
import regional from "../../../../../boot/regional.js";
import { ConfirmAndDoButton } from "../../../../react/dialog/ConfirmAndDoButton";
var PreviousEntry = function (_a) {
    var reconciliationEntry = _a.reconciliationEntry, index = _a.index, removeEntry = _a.removeEntry;
    return (React.createElement(Card, { className: "mb-3" },
        React.createElement(Card.Body, null,
            reconciliationEntry.charges && reconciliationEntry.charges.map(function (charge, index) {
                return (React.createElement("div", { className: "d-flex justify-content-between", key: "charge-".concat(index) },
                    React.createElement("span", null, charge.type),
                    React.createElement("span", null, charge.textValue),
                    React.createElement("span", null, "".concat(regional().currencySymbol).concat(charge.sum.toFixed(2)))));
            }),
            React.createElement("div", { className: "d-flex justify-content-between border-top border-secondary" },
                React.createElement("span", null, "Total: "),
                React.createElement("span", null, "".concat(regional().currencySymbol).concat(reconciliationEntry.total.toFixed(2)))),
            reconciliationEntry.remark && (React.createElement("div", { className: "mt-3" },
                React.createElement("span", { className: "me-2" }, "Remark: "),
                React.createElement("span", null, reconciliationEntry.remark))),
            React.createElement(ConfirmAndDoButton, { label: "Remove", title: "Remove Reconciliation Entry", body: "Are you sure you want to remove this reconciliation data?", className: "btn btn-sm btn-danger float-end mt-3", onConfirm: function () { return removeEntry(index); } }))));
};
var AddCharge = function (_a) {
    var chargeType = _a.chargeType;
    return (React.createElement("div", { datatype: "charge-form-fields" },
        chargeType === 'OTHER' ?
            React.createElement("div", { className: "form-group mb-3" },
                React.createElement("label", { htmlFor: "OTHER_TEXT" }, "OTHER_TEXT"),
                React.createElement(Field, { id: "OTHER_TEXT", className: "form-control", type: "text", name: "OTHER_TEXT" })) : null,
        React.createElement("div", { className: "form-group mb-3" },
            React.createElement("label", { htmlFor: chargeType }, chargeType),
            React.createElement(Field, { id: chargeType, className: "form-control", type: "number", name: chargeType, min: "0" }))));
};
var createInitialValues = function (chargeTypes) {
    var values = {
        remark: ''
    };
    chargeTypes && chargeTypes.forEach(function (type) { return values[type] = ''; });
    return values;
};
var ReconcileDelivery = function (_a) {
    var line = _a.line;
    var _b = useState(line.reconcile.previousEntries), previousEntries = _b[0], setPreviousEntries = _b[1];
    var _c = useState([]), reconciliationCharges = _c[0], setReconciliationCharges = _c[1];
    var _d = useState(line.reconcile.chargeTypes), chargeTypes = _d[0], setChargeTypes = _d[1];
    var _e = useState(line.reconcile.reconciledBool), reconciledBoolean = _e[0], setReconciledBoolean = _e[1];
    var _f = useState(true), allowSave = _f[0], setAllowSave = _f[1];
    useEffect(function () {
        if (line.reconcile.previousEntries === previousEntries) {
            setAllowSave(false);
        }
        else {
            setAllowSave(true);
        }
    }, [previousEntries]);
    // @ts-ignore
    var saveAllowed = mixam.user.hasRole('ROLE_MIXAM') && allowSave;
    var removeEntry = function (index) {
        var entries = __spreadArray([], previousEntries, true);
        entries.splice(index, 1);
        setPreviousEntries(entries);
    };
    var addCharge = function (type) {
        var charges = __spreadArray([], reconciliationCharges, true);
        charges.push({ type: type, sum: 0, textValue: '' });
        setReconciliationCharges(charges); // Copy array to trigger state change
        var types = __spreadArray([], chargeTypes, true).filter(function (t) { return t !== type; });
        setChargeTypes(types);
    };
    var resetHandler = function (line) {
        axios.delete("/admin/api/finance/orders/".concat(line.orderId, "/deliveries/").concat(line.deliveryId, "/delivery-groups/").concat(line.deliveryGroupId, "/reconciliation")).then(function () {
            window.dispatchEvent(new CustomEvent('deliveryReconciliationUpdated'));
        });
    };
    return (React.createElement(Formik, { initialValues: createInitialValues(line.reconcile.chargeTypes), onSubmit: function (values) { return __awaiter(void 0, void 0, void 0, function () {
            var reconciliation, nextEntry, putUrl;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        reconciliation = {
                            entries: []
                        };
                        previousEntries.forEach(function (entry) { return reconciliation.entries.push(entry); });
                        nextEntry = {
                            charges: [],
                            remark: values.remark
                        };
                        Object.keys(values).forEach(function (key) {
                            if (key !== 'remark') { // Handle 'remark' above...
                                if (key === 'OTHER_TEXT' || key === 'OTHER') {
                                    var contains = nextEntry.charges.some(function (entry) {
                                        return JSON.stringify({ type: 'OTHER', sum: Number(values['OTHER']), textValue: values['OTHER_TEXT'] }) === JSON.stringify(entry);
                                    });
                                    if (!contains) {
                                        values[key] && nextEntry.charges.push({ type: 'OTHER', sum: Number(values['OTHER']), textValue: values['OTHER_TEXT'] });
                                    }
                                }
                                else {
                                    values[key] && nextEntry.charges.push({ type: key, sum: Number(values[key]), textValue: null });
                                }
                            }
                        });
                        if (nextEntry.charges.length > 0) {
                            reconciliation.entries.push(nextEntry);
                        }
                        putUrl = "/admin/api/finance/orders/".concat(line.orderId, "/deliveries/").concat(line.deliveryId, "/delivery-groups/").concat(line.deliveryGroupId, "/reconciliation");
                        return [4 /*yield*/, axios.put(putUrl, reconciliation)];
                    case 1:
                        _a.sent();
                        window.dispatchEvent(new CustomEvent('deliveryReconciliationUpdated'));
                        return [2 /*return*/];
                }
            });
        }); } }, function (_a) {
        var values = _a.values, submitForm = _a.submitForm;
        var modalFooterButtons = initReconcileModalFooterButtons(saveAllowed, submitForm, reconciledBoolean, function () { return resetHandler(line); });
        var newEntryTotal = Object.keys(values)
            .filter(function (key) { return typeof values[key] === 'number'; })
            .map(function (key) { return values[key]; })
            .reduce(function (total, amount) { return total + amount; }, 0);
        return (React.createElement(FormikTableModal, { headerText: 'Delivery Reconciliation', openBtnLabel: line.reconcile.label, openBtnClassName: reconciledBoolean ? 'btn-danger' : 'btn-primary', footerButtons: modalFooterButtons },
            !!previousEntries.length && React.createElement("h4", { className: "mb-3" }, "Previous Entries:"),
            !!previousEntries.length && previousEntries.map(function (entry, index) { return React.createElement(PreviousEntry, { reconciliationEntry: entry, index: index, removeEntry: removeEntry, key: "entry-".concat(index) }); }),
            React.createElement("h4", { className: "mb-3" },
                "New Entry: ", "".concat(regional().currencySymbol).concat(newEntryTotal.toFixed(2))),
            React.createElement("div", { className: "form-group mb-3" },
                React.createElement("label", { htmlFor: "entryRemark" }, "Remark"),
                React.createElement(Field, { id: "entryRemark", className: "form-control", name: "remark" })),
            reconciliationCharges.map(function (charge) { return React.createElement(AddCharge, { chargeType: charge.type, key: charge.type }); }),
            React.createElement("div", { className: "dropup mt-5" },
                React.createElement("button", { type: "button", className: "btn btn-primary dropdown-toggle", "data-bs-toggle": "dropdown" }, "Add Charge"),
                React.createElement("ul", { className: "dropdown-menu" }, chargeTypes && chargeTypes.map(function (type) { return React.createElement("li", { key: type },
                    React.createElement("a", { className: "dropdown-item", onClick: function () {
                            setAllowSave(true);
                            addCharge(type);
                        } }, type)); })))));
    }));
};
function initReconcileModalFooterButtons(saveAllowed, saveHandler, resetAllowed, resetHandler) {
    var _this = this;
    var modalFooterButtons = [
        {
            label: 'Reset',
            enabled: resetAllowed,
            className: 'btn-danger',
            closeAfterCallback: true,
            validateForm: true,
            onClickEvent: function () { return __awaiter(_this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    resetHandler();
                    return [2 /*return*/];
                });
            }); }
        },
        {
            label: 'Close',
            enabled: true,
            className: 'btn-secondary',
            closeAfterCallback: true,
            validateForm: false
        },
        {
            label: 'Save',
            enabled: saveAllowed,
            className: 'btn-primary',
            closeAfterCallback: true,
            validateForm: true,
            onClickEvent: function () { return __awaiter(_this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    saveHandler();
                    return [2 /*return*/];
                });
            }); }
        }
    ];
    return modalFooterButtons;
}
export default ReconcileDelivery;
