import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { FaqList } from "./Components/FaqList";
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
var FaqManager = function (props) {
    return (React.createElement(Row, { className: "mb-3" },
        React.createElement(Col, null,
            React.createElement(DndProvider, { backend: HTML5Backend },
                React.createElement(FaqList, { faqType: props.faqType, id: props.id })))));
};
export default FaqManager;
