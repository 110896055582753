export default WithGetReferrerType;

function WithGetReferrerType() {

    /**
     * parse a url to components
     * @param url
     * @returns {*} Object
     *
     *  console.log("protocol", parser.protocol); // => "http:"
     *  console.log("hostname", parser.hostname); // => "example.com"
     *  console.log("port",     parser.port);     // => "8080"
     *  console.log("pathname", parser.pathname); // => "/pathname/"
     *  console.log("search",   parser.search);   // => "?search=test"
     *  console.log("hash",     parser.hash);     // => "#hash"
     *  console.log("host",     parser.host);     // => "example.com:8080"
     *
     */
    this.parseUrl = function (url) {
        var parser;

        if (url) {
            parser = document.createElement('a');
            parser.href = url;
        }
        return parser;
    };

    this.getReferrerTyleByReferrerUrl = function (url) {
        var type = "direct",
            referrer = this.parseUrl(url);

        if (referrer) {
            if (referrer.hostname.indexOf("google.co.uk") !== -1 || referrer.hostname.indexOf("google.com") !== -1) {
                type = "organic";

                if (referrer.pathname.indexOf("/aclk") !== -1) {
                    type = "paid";
                }
            } else if (referrer.hostname.indexOf("search.yahoo.com") !== -1) {
                type = "organic";
            } else if (referrer.hostname.indexOf("bing.com") !== -1) {
                type = "organic";
            } else if (referrer.hostname.indexOf("facebook.com") !== -1) {
                type = "social";
            } else if (referrer.hostname.indexOf("mixam") === -1) {
                type = "referral";
            }
        }
        return type;
    };

    this.getReferrerType = function (user, session) {

        if (user && user.isMixam) {
            return "mixam";
        }
        return session.referrerType;
    };

}