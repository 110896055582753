var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
// @ts-ignore
import defineComponent from '../../../../components/flight/lib/component.js';
import React, { useEffect, useState } from 'react';
import ReactDom from 'react-dom/client.js';
import Table from 'react-bootstrap/Table';
import axios from "axios";
var ReactTableCalendar = function () {
    var _a = useState(), currentData = _a[0], setCurrentData = _a[1];
    var _b = useState(false), loading = _b[0], setLoading = _b[1];
    var _c = useState(new Date().getFullYear()), currentYear = _c[0], setCurrentYear = _c[1];
    var _d = useState([{ value: currentYear }]), options = _d[0], setOptions = _d[1];
    useEffect(function () {
        axios.get("/admin/shop/calendar/holidays", { params: { year: currentYear } }).then(function (_a) {
            var data = _a.data;
            setCurrentData(data);
        });
        handleYears();
    }, []);
    function handleSave() {
        setLoading(true);
        var newCurrentData = currentData.map(function (data) {
            if (data.hasTempId) {
                data.id = undefined;
                delete data.hasTempId;
            }
            return data;
        });
        axios.put("/admin/shop/calendar/holidays", newCurrentData).then(function () {
            axios.get("/admin/shop/calendar/holidays", { params: { year: currentYear } }).then(function (_a) {
                var data = _a.data;
                setCurrentData(data);
            });
            setLoading(false);
        }).catch();
    }
    function handleInputChange(e, index) {
        var tempCurrentData = __spreadArray([], currentData, true);
        var id = e.currentTarget.id;
        if (e.currentTarget.value) {
            tempCurrentData[index][id] = e.currentTarget.value;
        }
        setCurrentData(tempCurrentData);
    }
    function handleDelete(e, index) {
        var tempCurrentData = __spreadArray([], currentData, true);
        var deletedRow = __spreadArray([], currentData, true)[index];
        setLoading(true);
        axios.delete("/admin/shop/calendar/" + deletedRow.id + "/delete").then(function () {
            setLoading(false);
        }).catch();
        tempCurrentData.splice(index, 1);
        setCurrentData(__spreadArray([], tempCurrentData, true));
    }
    function handleCreate() {
        var tempCurrentData = __spreadArray([], currentData, true);
        var item = {
            id: Math.floor(Math.random() * 100000),
            date: new Date(),
            name: "",
            countryCode: "",
            hasTempId: true
        };
        tempCurrentData.push(item);
        setCurrentData(tempCurrentData);
    }
    function handleYears() {
        axios.get("/admin/shop/calendar/years").then(function (_a) {
            var data = _a.data;
            var list = [];
            data.map(function (value) {
                list.push({ value: value });
            });
            setOptions(list);
        });
    }
    function handleSelectChange(e) {
        setCurrentYear(e.target.value);
        axios.get("/admin/shop/calendar/holidays", { params: { year: e.target.value } }).then(function (_a) {
            var data = _a.data;
            setCurrentData(data);
        });
    }
    return React.createElement(React.Fragment, null,
        " ",
        currentData ?
            (React.createElement("div", null,
                React.createElement("select", { style: { width: "8%" }, className: "form-control form-select", id: "yearSelector", onChange: function (e) { return handleSelectChange(e); }, defaultValue: new Date().getFullYear() }, options.map(function (option) { return React.createElement("option", { key: option.value, value: option.value }, option.value); })),
                React.createElement("br", null),
                React.createElement(Table, { striped: true, bordered: true, hover: true },
                    React.createElement("thead", null,
                        React.createElement("tr", null,
                            React.createElement("th", { key: "Date" }, "Date"),
                            React.createElement("th", { key: "Name" }, "Name"))),
                    React.createElement("tbody", null, currentData.map(function (holiday, index) { return (React.createElement("tr", { key: holiday.id },
                        React.createElement("td", null,
                            React.createElement("input", { className: "form-control", id: "date", onChange: function (e) { return handleInputChange(e, index); }, defaultValue: new Date(holiday.date).toISOString().substring(0, 10), type: "date" })),
                        React.createElement("td", null,
                            React.createElement("input", { className: "form-control", id: "name", onChange: function (e) { return handleInputChange(e, index); }, defaultValue: holiday.name, type: "string" })),
                        React.createElement("td", null,
                            React.createElement("button", { style: { float: "right" }, className: "btn btn-danger", onClick: function (e) { return handleDelete(e, index); } }, "Delete")))); }))),
                React.createElement("div", null,
                    React.createElement("button", { className: "btn btn-primary", onClick: handleCreate }, "Add Date"),
                    React.createElement("button", { style: { float: "right" }, className: "btn btn-primary", onClick: handleSave, disabled: loading }, loading ? 'Saving...' : 'Save')))) : React.createElement("div", null, "Loading..."));
};
// flight component
function CalendarTable() {
    this.attributes({
        containerSelector: 'section.data-table'
    });
    this.renderDataTable = function () {
        var root = ReactDom.createRoot(this.select('containerSelector')[0]);
        root.render(React.createElement(ReactTableCalendar, null));
    };
    this.after('initialize', function () {
        this.renderDataTable();
    });
}
var flightCalendarTable = defineComponent(CalendarTable);
function initializeCalendarTable() {
    this.attributes({
        dataTableSelector: '[data-type="calendar-data-table"]'
    });
    this.after('initialize', function () {
        flightCalendarTable.attachTo(this.select('dataTableSelector'));
    });
}
export default defineComponent(initializeCalendarTable);
