import defineComponent from '../../../../components/flight/lib/component';

export default defineComponent(MachineEditor);

function MachineEditor() {

    this.attributes({

        // Machine Configuration Editor
        saveMachineConfigurationButton: '[data-type="save-machine-configuration-btn"]',
        machineNameInputSelector: '[data-field="machine-name"]',
        machineDescriptionInputSelector: '[data-field="machine-description"]',
        machineExternalIdInputSelector: '[data-field="machine-external-id"]',
        machineProductionCentreInputSelector: '[data-field="machine-production-centre"]',
        machinePrintTypeInputSelector: '[data-field="machine-print-type"]',
        machineCalculationTypeInputSelector: '[data-field="machine-calculation-type"]',
        machineIsAllowTurnInputSelector: '[data-field="machine-is-allow-turn"]',
        machineIsCanWinInputSelector: '[data-field="machine-is-can-win"]',
        machineIsManualQuoteSelector: '[data-field="machine-is-manual-quote"]',
        machineFulfilmentOnlyInputSelector: '[data-field="machine-fulfilment-only"]',
        machinePlateSizeInputSelector: '[data-field="machine-plate-size"]',
        machineFoldingFactorInputSelector: '[data-field="machine-folding-factor"]',
        machineWidthInputSelector: '[data-field="machine-width"]',
        machineHeightInputSelector: '[data-field="machine-height"]',
        machineMinimumFlatFeeInputSelector: '[data-field="machine-minimum-flat-fee"]',
        machineMinimumPurFeeInputSelector: '[data-field="machine-minimum-pur-fee"]',
        machineMinimumSaddleStitchFeeInputSelector: '[data-field="machine-minimum-saddle-stitch-fee"]',
        machineOversheetsInputSelector: '[data-field="machine-oversheets"]',
        machinePrintRunPerDayInputSelector: '[data-field="machine-print-run-per-day"]',
        machineCountryOfOriginInputSelector: '[data-field="machine-country-of-origin"]',
        machineAirShippingInputSelector: '[data-field="machine-air-shipping"]',
        machineExcludePackagingInCostInputSelector: '[data-field="machine-exclude-packing-in-cost"]',
        machinePackagingCostInputSelector: '[data-field="machine-packaging-cost"]',
        machinePackagingPerWeightInputSelector: '[data-field="machine-packaging-per-weight"]',
    });

    this.saveMachineConfiguration = function(event) {

        if(this.node.checkValidity()) {
            const saveUrl = `/admin/catalog/${this.catalogId}/machines/${this.machineId}/save-configuration`;
            const machineData = {
                name: this.select('machineNameInputSelector').val(),
                description: this.select('machineDescriptionInputSelector').val(),
                externalId: this.select('machineExternalIdInputSelector').val(),
                centerId: this.select('machineProductionCentreInputSelector').val(),
                printType: this.select('machinePrintTypeInputSelector').val(),
                machineCalculationType: this.select('machineCalculationTypeInputSelector').val(),
                isAllowTurn: this.select('machineIsAllowTurnInputSelector').val(),
                isCanWin: this.select('machineIsCanWinInputSelector').val(),
                manualQuote: this.select('machineIsManualQuoteSelector').val(),
                fulfilmentAllowed: this.select('machineFulfilmentOnlyInputSelector').val(),
                plateSize: this.select('machinePlateSizeInputSelector').val(),
                foldingFactor: this.select('machineFoldingFactorInputSelector').val(),
                width: this.select('machineWidthInputSelector').val(),
                height: this.select('machineHeightInputSelector').val(),
                minimumFlatFee: this.select('machineMinimumFlatFeeInputSelector').val(),
                minimumPerfectFee: this.select('machineMinimumPurFeeInputSelector').val(),
                minimumSaddleFee: this.select('machineMinimumSaddleStitchFeeInputSelector').val(),
                minimumOversSheets: this.select('machineOversheetsInputSelector').val(),
                printRunPerDay: this.select('machinePrintRunPerDayInputSelector').val(),
                countryOfOrigin: this.select('machineCountryOfOriginInputSelector').val(),
                needAirShipment: this.select('machineAirShippingInputSelector').val(),
                excludePackagingInCost: this.select('machineExcludePackagingInCostInputSelector').val(),
                packaging: this.select('machinePackagingCostInputSelector').val(),
                packagingPerWeight: this.select('machinePackagingPerWeightInputSelector').val(),
            };

            $(event.target).button('loading');

            $.ajax({
                url: saveUrl,
                type: 'POST',
                data: {machineData: JSON.stringify(machineData)},
                success: function (data) {
                    setTimeout(function () {
                        $(event.target).button('reset');
                    }, 1000);
                },
                fail: function (error) {
                    setTimeout(function () {
                        $(event.target).button('reset');
                    }, 1000);
                    alert('Error Saving Data');
                }
            });
            
            event.preventDefault();
        }

    };

    this.after('initialize', function () {
        this.machineId = $(this.node).data('machine-id');
        this.catalogId = $(this.node).data('catalog-id');
        this.on('click', {
            saveMachineConfigurationButton: this.saveMachineConfiguration
        });
    });
}
