import defineComponent from '../../../components/flight/lib/component';
import mixam from '../../boot/mixam';
import WithNormalizeMap from "../with-normalize-map";
import Page from "./Page";
import Mustache from '../../../components/mustache/mustache';
import templateFolding from "text!../../../appes6/templates/order/folding-viewer.mustache";
import WithFolderUrl from '../with-get-folder-url';

export default defineComponent(WithNormalizeMap, Folding3dViewer, WithFolderUrl);

const simpleFolds = {
    "1": 'h',
    "2": 'roll',
    "3": 'z',
    "4": 'gate',
    "5": 'cross',
    "6": 'par'
};

const orientationMap = {
    "0": 'port',
    "1": 'land'
};

const secondarySizeMap = {
    "10": 'a5long',
    "16": 'dl',
    "20": '20',
    "22": '22',
    "21": '100',
    "12": '105',
    "13": '120',
    "11": '148',
    "6": '210',
    '46': '46',
    '47': '47',
    '48': '20',
    '55': '55',
};

const getRectTemplateUrl = function (fold, sides, orientation, finalSize, side, dir) {
    return `${mixam.placeHolderUrl}/place-hold-${simpleFolds[fold]}-${sides}-${orientation}-${finalSize}-${side}-${dir}.png`;
};
/*
function getBrowserName() {
    var name = "Unknown";
    if(navigator.userAgent.indexOf("MSIE") !== -1){
        name = "MSIE";
    }
    else if(navigator.userAgent.indexOf("Firefox") !== -1){
        name = "Firefox";
    }
    else if(navigator.userAgent.indexOf("Opera") !== -1){
        name = "Opera";
    }
    else if(navigator.userAgent.indexOf("Chrome") !== -1){
        name = "Chrome";
    }
    else if(navigator.userAgent.indexOf("Safari") !== -1){
        name = "Safari";
    }
    return name;
}*/

function Folding3dViewer() {

    this.attributes({
        containerSelector: ".content",
        rootSelector: ".content",
        cubeSelector: ".uber-cube .cube",
        workspaceSelector: ".workspace",
        page0UberSelector: ".uber-cube .side.page-0.row-0",
        page1UberSelector: ".uber-cube .side.page-1.row-0",
        page3UberSelector: ".uber-cube .side.page-0.row-1",
        page4UberSelector: ".uber-cube .side.page-1.row-1",
        page0ShadowSelector: ".shadow .side.page-0.row-0",
        page1ShadowSelector: ".shadow .side.page-1.row-0",
        page3ShadowSelector: ".shadow .side.page-0.row-1",
        page4ShadowSelector: ".shadow .side.page-1.row-1",
        gate8page0UberSelector: ".uber-cube .side.page-0.row-0",
        gate8page1UberSelector: ".uber-cube .side.page-1.row-0",
        gate8page2UberSelector: ".uber-cube .side.page-2.row-0",
        gate8page3UberSelector: ".uber-cube .side.page-3.row-0",
        gate8page0ShadowSelector: ".shadow .side.page-0.row-0",
        gate8page1ShadowSelector: ".shadow .side.page-1.row-0",
        gate8page2ShadowSelector: ".shadow .side.page-2.row-0",
        gate8page3ShadowSelector: ".shadow .side.page-3.row-0",
        sideSelector: ".side",
        foldBarSelector: ".fold-bar",
        measureSelector: '[data-type="measure"]',
        posBtnSelector: '[data-pos]',
        animateBtnSelector: '[data-type="animate"]',
        openCloseBtnSelector: '[data-type="open-close"]'
    });

    this.render = function () {
        return Mustache.render(templateFolding, this.normalize(this.item));
    };


    this.getTemplateName = function (item, side) {
        const {simpleFold, sides, orientation, secondaryFormat} = item.query;

        const finalSize = secondaryFormat ? secondarySizeMap[item.query.secondaryFormat] : `a${item.query.format}`;
        const dir = item.isRtl ? "rtl" : "ltr";
        let orientation_ = orientationMap[orientation];
        if (secondaryFormat === 21 || secondaryFormat === 12 || secondaryFormat === 13 || secondaryFormat === 11 || secondaryFormat === 6) {
            orientation_ = 'square';
        }

        return getRectTemplateUrl(simpleFold, sides, orientation_, finalSize, side, dir);
    };

    this.getScalingFactor = function (item) {
        const containerWidth = this.select('measureSelector').width() || this.$node.width() - 50;
        const maxHeight = window.innerHeight - 60;

        if (containerWidth <= 0) {
            return -1;
        }
        /** @namespace item.geometry.flatSize */
        let factor =  (containerWidth / item.geometry.flatSize.trimBox.width) * 0.70;
        factor = Math.min(factor,  (maxHeight / item.geometry.flatSize.trimBox.height) * 0.70);
        return factor;
    };

    this.normalize = function (item) {
        const fold = {};
        /** @namespace item.foldingMap */
        item.geometry = item.geometry || item.foldingMap;
        if (item.geometry) {
            const scalingFactor = this.getScalingFactor(item);
            if (scalingFactor <= 0) {
                return;
            }
            //console.log('scalingFactor', scalingFactor);

            fold.width = scalingFactor * item.geometry.flatSize.trimBox.width;
            fold.height = scalingFactor * item.geometry.flatSize.trimBox.height;
            fold.className = `fold-${item.query.simpleFold} sides-${item.query.sides} sizing-${item.sizeMatchingType} format-a${item.query.format} secondaryFormat-${item.query.secondaryFormat}`;
            fold.openCloseClassName = this.mode;
            let verticalOffset = 0;
            fold.side = {};

            /** @namespace item.geometry.front */
            fold.rows = item.geometry.front.map((row, rowIndex) => {
                let offset = 0;
                let frontRowArray = row;
                /** @namespace item.geometry.back */
                let backRowArray = item.geometry.back[rowIndex];

                if ( item.sizeMatchingType === 'FINAL' && item.isRtl ) {
                    frontRowArray = row.reverse();
                    backRowArray = item.geometry.back[rowIndex].reverse();
                }
                const row_ = {
                    sides: frontRowArray.map((side, sideIndex) => {
                        let front;
                        let back;
                        let backSidePageId =  row.length - sideIndex - 1;
                        const middlePage = item.query.sides / 4;

                        let frontId = side.id - 1;
                        let backId = backRowArray[backSidePageId].id - 1;

                        if (item.sizeMatchingType === 'FLAT') {
                            frontId = 0;
                            backId = 1;
                        }

                        const mapFrontPage = item.map.pages[frontId];
                        const mapBackPage = item.map.pages[backId];

                        if (mapFrontPage && mapFrontPage.file && item.sizeMatchingType === 'FINAL') {
                            front = this.getFinalPageGeomerty(item, side, frontId, sideIndex, middlePage, 'front');
                        } else {
                            front = this.getFlatPageGeomerty(item, side, frontId, sideIndex, rowIndex, middlePage, 'front', scalingFactor);
                        }

                        if (mapBackPage && mapBackPage.file && item.sizeMatchingType === 'FINAL') {
                            back = this.getFinalPageGeomerty(item, backRowArray[backSidePageId], backId, sideIndex, middlePage, 'back');
                        } else {
                            back = this.getFlatPageGeomerty(item, backRowArray[backSidePageId], backId, backSidePageId, rowIndex, middlePage, 'back', scalingFactor);
                        }

                        if (item.isRtl && item.sizeMatchingType === 'FLAT') {
                            [front, back].forEach(s => {
                                if (s.name === 'front') {
                                    s.name = 'back';
                                } else if (s.name === 'back') {
                                    s.name = 'front';
                                }
                            });

                        }

                        front.width *= scalingFactor;
                        front.height *= scalingFactor;
                        back.width *= scalingFactor;
                        back.height *= scalingFactor;

                        const page = {
                            id: sideIndex,
                            rowId: rowIndex,
                            left: offset,
                            top: verticalOffset,
                            width: front.width,
                            height: front.height,
                            front,
                            back
                        };

                        offset += front.width;
                        return page;
                    })
                };
                verticalOffset += row[0].height;
                return row_;
            });
        }
        //console.log(fold);
        return fold;
    };

    this.getFlatPageGeomerty = function (item, side, pageNo, sideIndex, rowIndex, middlePage, direction, scalingFactor) {
        const imageTemplateBkg = this.getTemplateName(item, direction);
        const {backgroundPosition, backgroundSizeFlat, thumb, hirez, backgroundPositionLeftX, backgroundPositionRightX, backgroundPositionY, backgroundPositionTopY} = this.getItemGeometry(item, pageNo, sideIndex);

        let newPosition;

        let positionY = `${backgroundPositionY}%`;
        if (item.query.simpleFold === 5) {
            // cross fold
            positionY = `${scalingFactor * (rowIndex * side.height * -1 + backgroundPositionTopY)}px`;
        }

        if (item.query.simpleFold === 4 && item.query.sides === 6) {
            if (sideIndex % 2 === 0) {
                if (sideIndex === 2) {
                    sideIndex = 3;
                }
                newPosition = `${scalingFactor * (sideIndex * side.width * -1 + backgroundPositionLeftX)}px ${positionY}`;
            }
        } else {
            if (sideIndex < Math.floor(middlePage)) {
                newPosition = `${scalingFactor * (sideIndex * side.width * -1 + backgroundPositionLeftX)}px ${positionY}`;
            } else if (sideIndex >= middlePage) {
                newPosition = `${scalingFactor * (sideIndex * side.width * -1 + backgroundPositionRightX)}px ${positionY}`;
            }
        }

        return $.extend(true, {}, side, {
            image: (this.resolution === 'high' ? hirez : thumb) || imageTemplateBkg,
            backgroundPosition: newPosition || backgroundPosition,
            backgroundSize: backgroundSizeFlat
        });
    };

    this.getFinalPageGeomerty = function (item, side, pageNo, sideIndex, middlePage, direction) {
        const imageTemplateBkg = this.getTemplateName(item, direction);
        const {backgroundPosition, backgroundSize, thumb, hirez} = this.getItemGeometry(item, pageNo, pageNo);
        return $.extend(true, {}, side, {
            image: (this.resolution === 'high' ? hirez : thumb) || imageTemplateBkg,
            backgroundPosition: backgroundPosition,
            backgroundSize: backgroundSize
        });
    };

    this.getItemGeometry = function (item, pageNo, index) {
        const mapPage = $.extend(item.map.pages[pageNo], {});
        const page = mapPage.file ? null : new Page(pageNo, item.geometry.finalSize.trimBox);
        this.addConvertResult(item, mapPage, index, page);
        if (mapPage.file === 'blank') {
            mapPage.thumb = mapPage.hirez = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAC0lEQVQImWP4DwQACfsD/eNV8pwAAAAASUVORK5CYII=';
            mapPage.backgroundSize = 'cover';
            mapPage.backgroundPosition = 'center';
        }
        return mapPage;
    };

    this.changeFoldAngel = function (position) {
        this.mode = position;
        this.select('posBtnSelector').removeClass('mode-open mode-middle mode-close');
        setTimeout(() => this.select('rootSelector').removeClass('pos-close pos-middle pos-open').addClass(`pos-${position}`), 0);
        setTimeout(() => this.select('posBtnSelector').addClass(`mode-${this.mode}`), 1000);
    };

    this.changeAnimation = function (isAnimating) {
        this.animating = isAnimating;
        if (this.animating) {
            this.select('rootSelector').addClass('animating');
        } else {
            this.select('rootSelector').removeClass('animating');
        }
    };

    this.getNextMode = function () {
        let mode = 'middle';
        if (this.mode === 'close') {
            mode = 'open';
        } else if (this.mode === 'middle') {
            mode = 'close';
        }
        return mode;
    };

    this.onFoldAngelChange = function (/*event*/) {
        this.changeFoldAngel(this.getNextMode());
    };

    this.onAnimateChange = function (/*event*/) {
        this.changeAnimation(!this.animating);
    };

    this.afterResize = function (/*event*/) {
        this.paint();
    };

    this.paint = function () {
        if (this.order) {
            this.select('containerSelector').html(this.render());

            // roll fold and z fold sides == 8
            if (this.item.query.simpleFold >= 2 && this.item.query.simpleFold <= 3 && this.item.query.sides === 8) {
                this.select('page0UberSelector').appendTo(this.select('page1UberSelector'));
                this.select('page0ShadowSelector').appendTo(this.select('page1ShadowSelector'));
            } else if (this.item.query.simpleFold === 4 && this.item.query.sides === 8) {
                // gate fold X 8
                this.select('gate8page0UberSelector').appendTo(this.select('gate8page1UberSelector'));
                this.select('gate8page3UberSelector').appendTo(this.select('gate8page2UberSelector'));
                this.select('gate8page0ShadowSelector').appendTo(this.select('gate8page1ShadowSelector'));
                this.select('gate8page3ShadowSelector').appendTo(this.select('gate8page2ShadowSelector'));
            } else if (this.item.query.simpleFold === 5) {
                // cross fold
                this.select('page0UberSelector').appendTo(this.select('page3UberSelector'));
                this.select('page1UberSelector').appendTo(this.select('page4UberSelector'));
                this.select('page0ShadowSelector').appendTo(this.select('page3ShadowSelector'));
                this.select('page1ShadowSelector').appendTo(this.select('page4ShadowSelector'));
            }

            setTimeout(() => this.select('workspaceSelector').addClass('started'), 0);
        }
    };

    this.setOrder = function (event, data) {
        this.order = data;
        this.item = this.order.getItem(this.$node.data("item"));

        if (!this.loaded) {
            this.loaded = true;

            // cross fold
            if (this.item.query.simpleFold === 5) {
                this.changeFoldAngel('close');
            }
        }
        this.paint();
    };


    this.after('initialize', function () {
        this.animating = false;
        this.mode = null;
        //this.browserName =  getBrowserName();
        this.resolution = this.$node.data('resolution') || 'low';
        this.on(document, "setOrderData", this.setOrder);
        this.on(window, 'resize', this.afterResize);
        this.on('click', {
            openCloseBtnSelector: this.onFoldAngelChange,
            cubeSelector: this.onFoldAngelChange,
            animateBtnSelector: this.onAnimateChange
        });
        this.on(document, 'shown.bs.tab', this.afterResize);

        this.afterResize();
        //this.changeViewAngel(120);
        this.changeFoldAngel('middle');
    });
}
