import defineComponent from '../../../components/flight/lib/component';

export default defineComponent(ReprintTotal);

function ReprintTotal() {

    this.attributes({
        cartTotalSelector: '[data-type=cart-total]'
    });

    this.reprintPriceAvailable = function (event, data) {
        this.item = data.item;
        this.render();
    };

    this.afterDiscountRender = function (event, data) {
        this.discount = data.discountTotal;
        this.render();
    };

    this.afterReprintVatRender = function (event, data) {
        this.tax = data;
        this.render();
    };

    this.calcTotal = function () {
        let itemSubtotal = this.item.response.total;
        if (this.item.response.hardProofRequired || (Array.isArray(this.item.proofRequests) && this.item.proofRequests.length)) {
            itemSubtotal += this.item.response.hardProofTotal || 0;
        }
        return this.tax.sum - this.discount + itemSubtotal;
    };

    this.render = function () {
        this.total = this.item ? this.calcTotal() : 0;

        const prefix = this.item && this.item.response.currency ? this.item.response.currency.prefix || "" : "";
        const postfix = this.item && this.item.response.currency ? this.item.response.currency.postfix || "" : "";

        this.select('cartTotalSelector').text(`${prefix}${(this.total || 0).formatNumber(2)}${postfix}`);
        if (this.prevTotal && this.prevTotal !== this.total) {
            requestAnimationFrame(() => this.select('cartTotalSelector').hilightElement());
        }
        this.prevTotal = this.total;
    };

    this.after('initialize', function () {
        this.delivery = {sum: 0};
        this.tax = {sum: 0};
        this.discount = 0;
        this.prevTotal = undefined;

        this.on(document, 'reprintPriceAvailable', this.reprintPriceAvailable);
        this.on(document, 'afterDiscountRender', this.afterDiscountRender);
        this.on(document, 'afterReprintVatRender', this.afterReprintVatRender);
    });

}
