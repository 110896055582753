import defineComponent from '../../../components/flight/lib/component';

export default defineComponent(SantaStats);

function SantaStats() {


    this.attributes({
        santaMinuteCountSelector: '[data-field="minute"] .value',
        santaMinuteTimeSelector: '[data-field="minute"] .average',
        santaMHourCountSelector: '[data-field="hour"] .value',
        santaHourTimeSelector: '[data-field="hour"] .average',
        santaMDayCountSelector: '[data-field="day"] .value',
        santaDayTimeSelector: '[data-field="day"] .average',
    });

    this.setData = function (event, data) {
        this.trigger("log", {message: data, title: "SantaStats.setData"});
        this.select('santaMinuteCountSelector').text(Math.round(data.data.santaPerMinute));
        this.select('santaMHourCountSelector').text(Math.round(data.data.santaPerHour));
        this.select('santaMDayCountSelector').text(Math.round(data.data.santaPerDay));
        this.select('santaMinuteTimeSelector').text(`${Math.round(data.data.averageSantaPerMinute)}ms.`);
        this.select('santaHourTimeSelector').text(`${Math.round(data.data.averageSantaPerHour)}ms.`);
        this.select('santaDayTimeSelector').text(`${Math.round(data.data.averageSantaPerDay)}ms.`);
    };

    this.after('initialize', function () {
        this.on(document, "santaStatsReady", this.setData);
    });
}
