import ReactDom from  'react-dom';
import React from 'react';
const rd = ReactDom;

class AnimatedCheckRadioBoxList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: 0
        };
        //this.onChanged = (e) => this.onChanged(e);
        //this.randomKey = Math.floor(Math.random() * 2147483648).toString(36);
    }

    onChanged(e) {
        if (e.target.checked) {
            this.setState({
                value: +e.target.value
            });
        }
    }

    componentDidMount() {
        this.setState({
            value: this.props.data[0].value
        });
    }

    render() {
        const items = this.props.data.map((item) => {
            return (<AnimatedCheckRadioBoxListItem
                data={item}
                selected={this.state.value}
                key={item.value}
                />);
        });

        return <ul onChange={(e) => this.onChanged(e)}  onClick={(e) => this.onChanged(e)} className='animated-check-radio-box-list'>
            {items}
        </ul>;
    }
}

class AnimatedCheckRadioBoxListItem extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            checked: false
        };
    }

    render() {
        const item = this.props.data;
        const id = `ewsert${this.randomKey}${item.value}`;
        const selected = this.props.selected;
        const checked = item.value === selected;
        let targetOrder = null;

        if (item.value === 2) {
            targetOrder = (<input className="form-control target-order"  type="text" />);
        }

        if (item.value === 36) {
            targetOrder = (<input className="form-control target-order" placeholder="Tracking Number" type="text" />);
        }

        return (<li
            key={item.value}
            data-toggle="animated-checkbox"
            data-type="radio"
            data-mark="circle"
            className="animated-radiobox"
            >
            <input
                id={id}
                defaultChecked={checked}
                type="radio"
                value={item.value}
                name="dispatchOption"
                />
            <label
                htmlFor={id}
                >{item.description}</label>   {targetOrder}
            <svg
                viewBox="0 0 100 100"
                xmlns="http://www.w3.org/2000/svg">
                {checked && (<path
                    d="M34.745,7.183C25.078,12.703,13.516,26.359,8.797,37.13 c-13.652,31.134,9.219,54.785,34.77,55.99c15.826,0.742,31.804-2.607,42.207-17.52c6.641-9.52,12.918-27.789,7.396-39.713 C85.873,20.155,69.828-5.347,41.802,13.379"
                    style={{"strokeDasharray": '275.003, 275.003', "strokeDashoffset": 0, transition: 'strokeDashoffset 0.2s ease-in-out 0s'}}></path>)}
            </svg>
        </li>);
    }

}

export default AnimatedCheckRadioBoxList;
