import defineComponent from '../../components/flight/lib/component';

export default defineComponent(PageNavigate);

function PageNavigate() {


    this.attributes({
        topSelectop: '[data-action="go-top"]',
        bottomSelectop: '[data-action="go-bot"]',
    });

    this.getViewportHeight = function () {
        return $(window).height();
    };

    this.getDocumentHeight = function () {
        return $(document).height();
    };

    this.getBottomTargetHeight = function () {
        return this.getDocumentHeight() - 1.35 * this.getViewportHeight();
    };

    this.goto = function (event, offset) {
        event.preventDefault();
        $("html, body").animate({scrollTop: offset}, 500);
    };

    this.onScroll = function (event, data) {
        if (data.offset < this.getViewportHeight() / 2) {
            this.select('topSelectop').addClass('disabled');
        } else {
            this.select('topSelectop').removeClass('disabled');
        }

        if (data.offset > this.getBottomTargetHeight()) {
            this.select('bottomSelectop').addClass('disabled');
        } else {
            this.select('bottomSelectop').removeClass('disabled');
        }
    };

    this.after('initialize', function () {
        this.on('click', {
            topSelectop: e => this.goto(e, 0),
            bottomSelectop: e => this.goto(e, this.getBottomTargetHeight()),
        });
        this.on(document, "uiPageScroll", this.onScroll);
    });
}
