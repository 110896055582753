import defineComponent from '../../../../components/flight/lib/component';

export default defineComponent(AfterpayManager);

function AfterpayManager() {

    this.attributes({
        afterpayPlacementTagSelector: 'afterpay-placement',
        containerSelector: '[data-type="afterpay-container"]',
        buttonSelector: 'button',
        errorSelector: '[data-type="afterpay-error"]',
        delcinedSelector: '[data-type="afterpay-declined"]',
        validationErrorSelector: '[data-type="afterpay-validationError"]',
        leftToPaySelector: '[data-type="left-to-pay"]'
    });

    this.initAfterpay = function () {
        AfterPay.onComplete = (event) => {
            if (event.data.status === "SUCCESS") {
                $.post("/api/v3/afterpay/capture", {token: event.data.orderToken}, null, 'json')
                    .done(response => {
                        if(response.status === "OK") {
                            window.location.reload(true);
                        } else {
                            if(response.error) {
                                this.select('leftToPaySelector').text(response.orderAmount);
                                this.select('afterpayPlacementTagSelector').attr('data-amount', response.orderAmount);
                                this.select('validationErrorSelector').append(`<p>${response.error}</p>`).show();
                            } else {
                                this.select('delcinedSelector').show();
                            }
                            this.select('buttonSelector').removeAttr('data-loading', '*').attr('disabled', false);
                        }
                    })
                    .fail(response => {
                        this.select('buttonContainerSelector').hide();
                        this.select('errorSelector').show();
                        this.trigger("log", response);
                    });
            } else {
                this.select('buttonSelector').removeAttr('data-loading', '*').attr('disabled', false);
            }
        };
    };

    this.checkout = function () {
        AfterPay.initialize({countryCode: "US"});
        AfterPay.open();

        this.select('buttonSelector').attr('data-loading', '*').attr('disabled', true);
        $.post("/api/v3/afterpay/create-checkout", {orderId: this.orderId}, null, 'json')
            .done(response => {
                AfterPay.transfer({token: response.token});
            })
            .fail(response => {
                this.select('containerSelector').hide();
                this.select('errorSelector').show();
                AfterPay.close();
            });
    };

    this.setOrder = function(event, order) {
        this.select('afterpayPlacementTagSelector').attr('data-amount', order.leftToPay);
    };

    this.after('initialize', function () {
        const data = this.$node.data();
        this.orderId = data.orderId;

        this.on(document, "setOrderData", this.setOrder);

        this.select('errorSelector').hide();
        this.select('delcinedSelector').hide();

        this.on('click', {
            buttonSelector: this.checkout
        });

        setTimeout(() => {
            if (typeof window.AfterPay !== 'undefined') {
                this.initAfterpay();
            }
        }, 500);
    });
}
