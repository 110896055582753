import mixam from '../../../boot/mixam';
import Order from '../../constants/order';
import defineComponent from '../../../../components/flight/lib/component';
import DataTable from '../../table/data-table-net/data-table-net.tsx';
import WithOrdersItemsMembers from '../with-orders-items-members';
import WithOrdersItems from '../with-order-items';
import WithSignDataRequest from '../../with-sign-data-request';
import { Breakpoints } from '../../table/data-table-net/StyledDataTableNet.tsx';

export default defineComponent(AdminPublicationsManager, WithOrdersItemsMembers, WithOrdersItems, WithSignDataRequest);

function AdminPublicationsManager() {

    this.attributes({
        firstTimeUrl: `${mixam.reporterOrigin}/reporter/admin/api/shop/${mixam.shop.id}/orders/items/first`,
        url: `${mixam.reporterOrigin}/reporter/admin/api/shop/${mixam.shop.id}/orders/items`,
        dataTableSelector: '[data-type="data-table"]',
        status: Order.ORDER_STATUS_PRINT_ON_DEMAND,
        minStatus: 'CONFIRMED'
    });

    this.createSchema = function () {
        const cols = [];

        cols.push({
            title: "",
            type: "thumbnail",
            data:"front"
        });
        cols.push({
            title: "Publication Title",
            type: "text",
            data:"printOnDemandTitle",
            width: "20rem",
        });
        cols.push({
            title: "Order",
            type: "link",
            data:"href",
            width: "5rem",
        });
        cols.push({
            title: "Username",
            type: "user",
            data:"user",
            width: "10rem",
            hide: Breakpoints.SM,
        });
        cols.push({
            title: "Modified",
            type: "timebox",
            data:"lastModifiedDate",
            hide: Breakpoints.MD,
        });
        cols.push({
            title: "Source",
            type: "text",
            data: "printOnDemandItemSource",
            width: "150px",
            hide: Breakpoints.MD,
        });
        cols.push({
            title: "Referer",
            type: "text",
            data:  "printOnDemandItemReferer",
            width: "150px",
            hide: Breakpoints.MD,
        });
        cols.push({
            title: "Status",
            type: "badge",
            data: "statusBadge",
            width: "150px",
            hide: Breakpoints.MD,
        });
        cols.push({
            title: "Confirmed",
            type: "timebox",
            data:"confirmDate",
            hide: Breakpoints.MD,
        });
        cols.push({
            title: "Product",
            type: "text",
            data:"product",
            width: "10rem",
            hide: Breakpoints.LG,
        });
        cols.push({
            title: "Size",
            type: "text",
            data: "size",
            hide: Breakpoints.XXL,
        });
        cols.push({
            title: "Sides",
            type: "number",
            decimal: 0,
            data: "sides",
            hide: Breakpoints.XXL,
        });
        cols.push({
            title: "Pages",
            type: "number",
            data: "calcedPages",
            hide: Breakpoints.XXL,
        });
        cols.push({
            title: "Orientation",
            type: "icon",
            data: "orientation",
            hide: Breakpoints.XXL,
        });
        cols.push({
            title: "Bound",
            type: "icon",
            data: "bound",
            hide: Breakpoints.XXL
        });
        cols.push({
            title: "Bind",
            type: "icon",
            data: "bind",
            hide: Breakpoints.XXL,
        });
        cols.push({
            title: "Sewn",
            type: "boolean",
            data: "isSewn",
            hide: Breakpoints.XXL,
        });
        cols.push({
            title: "Foiled",
            type: "boolean",
            data: "isFoiled",
            hide: Breakpoints.XXL,
        });
        cols.push({
            title: "Dust Jacket",
            type: "boolean",
            data: "hasDustJacket",
            hide: Breakpoints.XXL,
        });
        cols.push({
            title: "Supplier",
            type: "text",
            data: "supplier",
            width: "10rem",
            hide: Breakpoints.XXL,
        });
        cols.push({
            title: "Sum",
            type: "number",
            data: "total",
            sum: true,
            currency: true,
            decimal: 2,
            prepend: mixam.shop.currency.prefix
        });
        cols.push({
            title: "Value",
            type: "number",
            data: "value",
            sum: true,
            currency: true,
            decimal: 2,
            prepend: mixam.shop.currency.prefix
        });

        return cols;
    };

    this.after('initialize', function () {
        DataTable.attachTo(this.select('dataTableSelector'));
        setTimeout(() => this.getData(), 10);
        try {
            this.subscribe(this.updateOrderLine.bind(this));
        } catch (e) {

        }
    });
}
