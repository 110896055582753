import defineComponent from '../../../../components/flight/lib/component';
import React from 'react';
import ReactDom from 'react-dom/client';
import FaqManager from '../React/FaqManager/FaqManager.tsx';

export default defineComponent(AdminFaqManager);

function AdminFaqManager() {

    this.initFaqManager = function (event) {

        const faqEditor = $(this.node);

        const root = ReactDom.createRoot(faqEditor.get(0));
        root.render(
            <FaqManager
                faqType={faqEditor.data('faq-type')}
                id={faqEditor.data('id')}
            ></FaqManager>
        );
    };

    this.after('initialize', function () {
        this.initFaqManager();
    });

}
