import defineComponent from '../../../../../components/flight/lib/component';
/*
 * TODO: replace! (old DataTable)
 *
 * NOTE: this code is only attached to a DOM element in year-view.vm, which is pulled into the new
 * Admin UI through an <iframe> on /admin/analytics/reports/year-view.
 */
import DataTable from '../../../table/data-table';
import YearViewBar from './year-view-bar';
import YearViewDerivative from './year-view-derivative';
import YearViewYearly from './year-view-yearly';

export default defineComponent(AnalyticsYearView);



function AnalyticsYearView() {

    this.attributes({
        url: "/admin/api/counters/monthly/list",
        dataTableSelector: '[data-type="data-table"]',
        barChartSelector: '[data-type="year-view-bar"]',
        derivativeChartSelector: '[data-type="year-view-derivative"]',
        yearlyChartSelector: '[data-type="year-view-yby"]',
    });

    this.createSchema = function () {
        const cols = [];

        cols.push({
            title: "Date",
            type: "date",
            width: "40%",
            data: "dateCreated"
        });

        cols.push({
            title: "Count",
            type: "number",
            width: "15%",
            data: "count"
        });
       cols.push({
            title: "Sum",
            type: "number",
            width: "15%",
            data: "sum"
        });

      cols.push({
            title: "Refund",
            type: "number",
            width: "15%",
            data: "refund"
        });

      cols.push({
            title: "Total",
            type: "number",
            width: "15%",
            data: "total"
        });

        return cols;
    };

    this.paint = function () {
        const data = {
            columns: this.createSchema(),
            fileName: this.attr.url.split("/").pop(),
            response: this.normalize(this.data),
            "class": 'table-striped table-order-list',
            "default-sort": {
                "dateCreated": -1
            }
        };

        if (this.dataReadyFired) {
            window.dispatchEvent(new CustomEvent('uiDataUpdate', { detail: data }));
        } else {
            this.dataReadyFired = true;
            this.trigger("uiDataReady", data);
        }
    };


    this.normalize = function (data) {
        return data.payments.map((x, i) => this.normalizeCounter(x, i));
    };

    this.normalizeCounter = function (counter_, id) {

        const counter = $.extend(true, {}, counter_);
        counter.id = `counter${id}`;
        counter.dateCreated = new Date(counter.year, counter.month + 1, 0);
        if (counter.dateCreated > Date.now()) {
            counter.dateCreated = Date.now();
        }
        const refund = this.getRefund(counter.year, counter.month);
        counter.refund = 0;
        if (refund) {
            counter.refund = refund.sum;
        }
        counter_.total = counter.total = Math.round(counter.sum - counter.refund);
        return counter;
    };

    this.getRefund = function (year, month) {
        return this.data.refunds.filter(r => r.year === year && r.month === month)[0];
    };

    this.getData = function () {
        const url = this.attr.url;

        $.getJSON(url)
            .done(data => {
                this.data = data;
                this.paint();
            })
            .fail(() => this.error("data-file-missing"));
    };

    this.error = function (err) {
        if (console) {
            console.error(err);
        }
    };


    this.after('initialize', function () {
        DataTable.attachTo(this.select('dataTableSelector'));
        YearViewBar.attachTo(this.select('barChartSelector'));
        YearViewDerivative.attachTo(this.select('derivativeChartSelector'));
        YearViewYearly.attachTo(this.select('yearlyChartSelector'));
        setTimeout(() => this.getData(), 10);
    });
}
