import defineComponent from '../../../../components/flight/lib/component';
import DataTable from './../../table/data-table-net/data-table-net.tsx';
import axios from "axios";

export default defineComponent(MachinesDataTable);

function MachinesDataTable() {

    this.attributes({
        url: "machines/data.json",
        dataTableSelector: '[data-type="data-table"]'
    });

    this.createSchema = function () {
        const cols = [];

        cols.push({
            title: "Name",
            type: "text",
            data: "name"
        });

        cols.push({
            title: "Description",
            type: "text",
            data: "description",
            width: '300px'
        });

        cols.push({
            title: "Print Type",
            type: "text",
            data: "printType"
        });

        cols.push({
            title: "Print Quality",
            type: "text",
            data: "printQuality"
        });

        cols.push({
            title: "Pricing Strategy",
            type: "text",
            data: "pricingStrategy"
        });

        cols.push({
            title: "Can Win",
            type: "boolean",
            data: "isCanWin"
        });

        cols.push({
            title: "Fulfilment Only",
            type: "boolean",
            data: "fulfilmentAllowed"
        });

        cols.push({
            title: "Width",
            type: "number",
            data: "width"
        });

        cols.push({
            title: "Height",
            type: "number",
            data: "height"
        });

        cols.push({
            title: "",
            type: "multiButton",
            data: "actions",
            width: '250px'
        });

        return cols;
    };


    this.paint = function () {
        const data = {
            columns: this.createSchema(),
            fileName: this.attr.url.split("/").pop(),
            response: this.normalize(this.data.list)
        };

        if (this.dataReadyFired) {
            window.dispatchEvent(new CustomEvent('uiDataUpdate', { detail: data }));
        } else {
            this.dataReadyFired = true;
            this.trigger("uiDataReady", data);
        }
    };

    this.normalize = function (list) {
        return list.map(x => this.normalizeMachine(x));
    };

    this.normalizeMachine = function (machine_) {
        const machine = $.extend(true, {}, machine_);

        machine.actions = [
            {
                href: `/admin/catalogue/machines/${machine_.id}/edit`,
                caption: "Edit",
                target: "_self",
                className: "btn btn-primary",
                iconClassname: "fa fa-pencil"
            },
            {
                href: `/admin/catalogue/machines/${machine_.id}/duplicate`,
                caption: "Duplicate",
                target: "_self",
                className: "btn btn-secondary",
                iconClassname: "fa fa-clipboard"
            }
        ];

        return machine;
    };

    this.getData = function () {
        axios.get(this.attr.url)
            .then(response => {
                this.data = response.data;
                this.paint();
                setTimeout(() => this.getData(), 1000 * 60 * 15);
            }).catch(error => this.trigger("log", {message: error}));
    };

    this.after('initialize', function () {
        DataTable.attachTo(this.select('dataTableSelector'));
        setTimeout(() => this.getData(), 10);
    });
}
