import defineComponent from '../../../../components/flight/lib/component';
import React from 'react';
import ReactDom from 'react-dom/client';
import CmsGroupManager from '../React/CmsGroupManager/CmsGroupManager.tsx';

export default defineComponent(AdminCmsGroupManager);

function AdminCmsGroupManager() {

    this.initCmsGroupManager = function (event) {

        const editor = $(this.node);

        const root = ReactDom.createRoot(editor.get(0));
        root.render(
            <CmsGroupManager
                id={editor.data('id')}
            ></CmsGroupManager>
        );
    };

    this.after('initialize', function () {
        this.initCmsGroupManager();
    });

}
