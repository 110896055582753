import mixam from '../../boot/mixam';
import defineComponent from '../../../components/flight/lib/component';
export default defineComponent(JobTitleEditor);

function JobTitleEditor() {


    this.attributes({
        allInputsSelector: ".job-title-display input[type='text']",
        editBtnSelector: "[data-type='edit-job-name']",
        headingTitleSelector: "h2[data-type='job-title']",
        headingTitleTextSelector: "h2[data-type='job-title'] .title-text",
        inputSelector: "input[type='text']",
        saveBtnSelector: "[data-type='save']",
        cancelSelector: "[data-type='cancel']",
        formSelector: "[data-type='job-name-form']"
    });

    this.edit = function (event) {
        event.preventDefault();
        this.select('headingTitleSelector').hide("nor");
        this.select('formSelector').show("nor").find("input").focus();
    };

    this.save = function (event) {
        var newTitle = this.select('inputSelector').val(),
            url = mixam.springUrl("/api/orders/{orderId}/{itemId}/jobname".supplant({
                orderId: this.$node.data("order"),
                itemId: this.$node.data("item")
            }));

        event.preventDefault();

        this.select('saveBtnSelector').attr('data-loading', '*').attr('disabled', true);
        this.on(document, "uiAfterAction", $.proxy(this.onAfterAction, this));
        this.trigger("uiRequestAction", {url: url, name: newTitle});

    };

    this.onAfterAction = function (event, data) {
        var newTitle = this.select('inputSelector').val();

        $(`[data-item="${data.itemId}"] ` + this.attr.headingTitleTextSelector).text(newTitle);
        $(`[data-item="${data.itemId}"] ` + this.attr.allInputsSelector).val(newTitle);

        this.select('saveBtnSelector').removeAttr('data-loading').removeAttr('disabled');
        this.close();
        this.off(document, "uiAfterAction");
    };

    this.cancel = function (event) {
        event.preventDefault();
        this.close();
    };

    this.close = function () {
        this.select('formSelector').hide("fast");
        this.select('headingTitleSelector').show("slow");
    };


    this.after('initialize', function () {


        this.on("click", {
            editBtnSelector: this.edit,
            saveBtnSelector: this.save,
            cancelSelector: this.cancel
        });

    });
}

