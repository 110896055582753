export default [
    "mx-saddle",
    "mx-saddle",
    "mx-perfect",
    "mx-perfect",
    "mx-pur",
    "mx-pur",
    "mx-casebind",
    "mx-casebind",
    'mx-wiro',
    'mx-wiro',
    'mx-loop',
    'mx-loop',
    'fa-calendar',
    'fa-calendar',
    'fa-calendar',
];