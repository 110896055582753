import defineComponent from '../../../components/flight/lib/component';
import Folding3dViewer from '../order/folding-3d-viewer';
import Mustache from '../../../components/mustache/mustache';
import templateFlipPages from 'text!../../../appes6/templates/preview/preview-flip-pages.mustache';
import templatescrollPages from 'text!../../../appes6/templates/preview/preview-scroll-pages.mustache';
import templateFolding from 'text!../../../appes6/templates/preview/folding-viewer.mustache';
import templateWebglModel from 'text!../../../appes6/templates/preview/webgl-viewer.mustache';
import Geometry from '../constants/geometry';
import WithFolderUrl from '../with-get-folder-url';
import Product from '../constants/products';

import PageFlipper from '../page-flip';
import WithNormalizeMap from '../with-normalize-map';

export default defineComponent(ShareView, WithNormalizeMap, WithFolderUrl);

function Order(response) {
    $.extend(this, response);
}

Order.prototype.getItem = function (id) {
    return this.items.filter(item => item.id === id)[0];
};

function ShareView() {

    this.attributes({
        mode: 'flip',
        pagesSelector: '.page',
        modeSelector: '[data-mode]',
        foldingViewerSelector: '[data-type="folding-3d-viewer"]',
        flipbookSelector: '.preview-container .contain'
    });

    this.render = function () {
        let tempate = this.attr.mode === 'flip' ? templateFlipPages : templatescrollPages;
        if (this.item.query.sides > 0) {
            tempate = templateFolding;
        }

        const newViewerSupportedFolds = [1, 2, 3, 5];
        if(this.item.query.productId === Product.POSTER ||
            this.item.query.productId === Product.POSTCARD ||
            this.item.query.productId === Product.LETTERHEAD ||
            this.item.query.productId === Product.LEAFLET ||
            this.item.query.productId === Product.BUSINESS_CARD ||
            (this.item.query.productId === Product.FOLDED && newViewerSupportedFolds.includes(this.item.query.simpleFold))) {

            tempate = templateWebglModel;
        }

        this.recalcItemDimesions();

        const map = this.normalizeMap(this.order, this.item, false);
        if(this.isPublicationPreview && this.item.preview && this.item.preview.previewType === 'LIMITED_PAGES') {
            const maxPages = this.item.preview.maxPages;
            map.pages = map.pages.filter((page, index) => {
                return index < maxPages;
            });
        }

        return Mustache.render(tempate, {
            map: map,
            id: this.item.id,
            orderId: this.order.id,
            canRemove: true,
            showMore: null,
            lowResolution: this.resolution === 'low' ? 'low' : null,
            mode: this.attr.mode,
            dimensions: this.calcDimensions(),
            aspect: this.item.dimensions.height / this.item.dimensions.width * 100,
            isRtl: this.item.isRtl ? 1 : null
        });
    };


    this.calcHeight = function (width) {
        if (this.item) {
            return width * this.item.dimensions.height / this.item.dimensions.width;
        }
        return 300;
    };

    this.recalcItemDimesions = function () {
        if (!this.item.dimensions) {
            if (this.item.geometry) {
                /** @namespace this.item.geometry.finalSize */
                const {width, height} = this.item.geometry.finalSize.trimBox;
                this.item.dimensions = {
                    width,
                    height,
                    left: 0,
                    top: 0,
                };
            } else {
                const {width, height} = Geometry.find(this.item);
                this.item.dimensions = {
                    width,
                    height,
                    left: 0,
                    top: 0,
                };
            }
        }
    };

    this.calcDimensions = function () {
        const $win = $(window),
            vpw = $win.width(),
            vph = (window.innerHeight || $win.height()) - 100,
            height = Math.min(vph, this.calcHeight(vpw)),
            aspect = this.item && this.item.dimensions && this.item.dimensions.width / this.item.dimensions.height || 0;

        return {
            width: Math.min(height * aspect, vpw),
            height: height
        };
    };

    this.resizeViewport = function () {
        setTimeout(() => {
            const {width, height} = this.calcDimensions();

            if (this.attr.mode !== 'flip') {
                this.select('pagesSelector').css('max-width', width).css('max-height', height);
            }
        }, 10);
    };
    /*

        this.resetPage = function ($page) {
            const divDiv = $page.find('div div');
            const width = $page.data('originalWidth') + 'px';
            $page.css("width", width);
            divDiv.css("width", width);
        };
    */

    this.display = function () {
        this.$node.html(this.render());
        let bookLength = this.item.map.pages.length;
        let pages = Math.floor(this.item.map.pages.length / 2) + 1;

        if(this.isPublicationPreview && this.item.preview && this.item.preview.previewType === 'LIMITED_PAGES' && this.item.preview.maxPages < this.item.map.pages.length) {
            bookLength = this.item.preview.maxPages;
            pages = Math.floor(this.item.preview.maxPages / 2) + 1;
        }

        PageFlipper.teardownAll();
        if (this.attr.mode === 'flip') {
            PageFlipper.attachTo(this.select('flipbookSelector'), {
                orientation: this.item.map.orientation,
                pages: pages,
                bookLength: bookLength,
                width: this.$node.width(),
                direction: this.item.isRtl ? "rtl" : "ltr",
                autoScale: true,
                page: this.$node.data('page'),
                pageWidth: this.item.dimensions.width - this.getGutterSize(false),
                pageHeight: this.item.dimensions.height,
                parentContainer: '[data-type="preview-panel"]'
            });
        }
        Folding3dViewer.attachTo(this.select('foldingViewerSelector'));
    };

    this.setData = function (event, data) {
        this.order = new Order(data);
        this.item = data.items[0];
        this.item.isNeedUvMap = () => this.item.query.productId === Product.ENVELOPE ||
            this.item.query.productId === Product.POSTER ||
            this.item.query.productId === Product.POSTCARD ||
            this.item.query.productId === Product.BUSINESS_CARD ||
            this.item.query.productId === Product.GREETING_CARD;
        this.item.isFlyer = () => this.item.query.productId === Product.LEAFLET;
        this.display();
        if (this.item.isFlyer()) {
            this.selectMode('single');
            $('[data-mode="flip"]').remove();
        }
        this.trigger('setOrderData', this.order);
    };

    this.onModeChange = function (event) {
        const $btn = $(event.target).closest('[data-mode]'),
            mode = $btn.data('mode');

        event.preventDefault();
        this.selectMode(mode);
    };

    this.selectMode = function (mode) {
        const $btn = $(`[data-mode="${mode}"]`);
        if (mode !== this.attr.mode) {
            this.attr.mode = mode;
            this.display();
            $(this.attr.modeSelector).each((i, item) => {
                $(item).parent().removeClass('active');
            });
            $btn.parent().addClass('active');
        }
    };

    this.after('initialize', function () {
        this.resolution = this.$node.data('resolution') || 'high';
        this.isPublicationPreview = $(this.node).data('publication-preview');

        this.on(document, 'dataShareReady', this.setData);
        //this.on(document, 'pageFlipTurning', this.turning);

        $(this.attr.modeSelector).on("click", this.onModeChange.bind(this));
        this.on(window, 'resize', $.proxy(this.resizeViewport, this));
        this.on(window, 'orientationchange', $.proxy(this.resizeViewport, this));

    });
}
