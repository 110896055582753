import defineComponent from '../../../components/flight/lib/component';
import WithStompClient from './with-stomp';

export default defineComponent(RecruitCandidate, WithStompClient);

function RecruitCandidate() {


    this.attributes({
        field: "sum"
    });

    this.updateAnswer = function(data) {
        if (data.answer && data.target) {
            const $target = $(data.target);
            const $all = $target.prevAll();
            const $dt = this.getFirstDt($all);// .eq($all.length - 1);
            $target.html(data.answer);
            $target.addClass('in');
            $dt.scrollToView(x => $target.hilightElement());
        }
    };

    this.getFirstDt = function (list) {
        for (let i = 0; i < list.length; i++) {
            let o = list[i];
            if (o.tagName === "DT") {
                return $(o);
            }
        }
    };


    this.after('initialize', function () {
        this.id = this.$node.data('id');
        this.topic = `/topic/recruit${this.id}`;
        try {
            this.subscribe(x => this.updateAnswer(x));
        } catch (e) {

        }
    });
}
