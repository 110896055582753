var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useState } from 'react';
import { Button, Form as BsForm, Table } from "react-bootstrap";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import { LabourCosts } from "./LabourCosts";
import { Capability } from '../types';
import ConfirmationModal from "./ConfirmationModal";
import { EditEquipment } from "./EditEquipment";
var defaultValues = {
    name: '',
    purchaseCost: 0,
    usefulLifeMonths: 0,
    monthlyRuntimeHours: 0,
    maintenanceRatio: 0.0,
    makeReadyTimeMinutes: 0,
    labourCosts: [],
    active: true,
    capabilities: [Capability.BINDING],
    sheetsPerHour: 0,
};
var LayflatBinderRow = function (_a) {
    var entry = _a.entry, configuration = _a.configuration, doGetConfiguration = _a.doGetConfiguration;
    var _b = useState(false), showModal = _b[0], setShowModal = _b[1];
    var _c = useState(false), showDeleteModal = _c[0], setShowDeleteModal = _c[1];
    var handleCloseModal = function () { return setShowModal(false); };
    var handleShowModal = function () { return setShowModal(true); };
    var doDelete = function () { return __awaiter(void 0, void 0, void 0, function () {
        var url;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    url = "/api/admin/tco/configurations/".concat(configuration.machineName, "/layflat-binders/").concat(entry.id);
                    return [4 /*yield*/, axios.delete(url)
                            .then(function () {
                            doGetConfiguration();
                            setShowDeleteModal(false);
                            window.dispatchEvent(new CustomEvent('showFixedMessage', { detail: { message: { type: 'success', body: 'Layflat Binder Removed', interval: 15000 } } }));
                        }).catch(function (error) {
                            window.dispatchEvent(new CustomEvent('showFixedMessage', { detail: { message: { type: 'error', body: "Failed to Remove Layflat Binder: ".concat(error.response.data.error), interval: 30000 } } }));
                        })];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    return (React.createElement(React.Fragment, null,
        React.createElement(ConfirmationModal, { show: showDeleteModal, handleClose: function () { return setShowDeleteModal(false); }, handleSubmit: doDelete, closeButtonText: "Close", content: "Are you sure you want to delete this layflat binder?", saveButtonText: "Delete", heading: "Delete Press" }),
        React.createElement("tr", null,
            React.createElement("td", { className: "align-middle" },
                React.createElement("div", { className: "d-flex" }, entry.name)),
            React.createElement("td", { className: "align-middle" },
                React.createElement("div", { className: "d-flex justify-content-end" }, entry.purchaseCost)),
            React.createElement("td", { className: "align-middle" },
                React.createElement("div", { className: "d-flex justify-content-end" }, entry.usefulLifeMonths)),
            React.createElement("td", { className: "align-middle" },
                React.createElement("div", { className: "d-flex justify-content-end" }, entry.monthlyRuntimeHours)),
            React.createElement("td", { className: "align-middle" },
                React.createElement("div", { className: "d-flex justify-content-end" }, entry.maintenanceRatio)),
            React.createElement("td", { className: "align-middle" },
                React.createElement("div", { className: "d-flex justify-content-end" }, entry.makeReadyTimeMinutes)),
            React.createElement("td", { className: "align-middle" },
                React.createElement("div", { className: "d-flex justify-content-end" }, entry.labourCosts.map(function (cost) { return cost.hourlyRate * (1 + cost.benefitsRatio); }).reduce(function (sum, num) { return sum + num; }, 0).toFixed(2))),
            React.createElement("td", { className: "align-middle" },
                React.createElement("div", { className: "d-flex justify-content-center" }, entry.capabilities.map(function (element) { return React.createElement(React.Fragment, { key: element },
                    element,
                    React.createElement("br", null)); }))),
            React.createElement("td", { className: "align-middle" },
                React.createElement("div", { className: "d-flex justify-content-center" }, entry === null || entry === void 0 ? void 0 : entry.requiredPrintType)),
            React.createElement("td", { className: "align-middle" },
                React.createElement("div", { className: "d-flex justify-content-end" }, entry.sheetsPerHour)),
            React.createElement("td", { className: "align-middle" },
                React.createElement("div", { className: "d-flex justify-content-center" }, entry.active ? 'YES' : 'NO')),
            React.createElement("td", { className: "text-nowrap" },
                React.createElement(Button, { variant: "danger", type: "button", onClick: function () { return setShowDeleteModal(true); } },
                    React.createElement("i", { className: "bi bi-trash" })),
                React.createElement(Button, { className: "ms-2", type: "button", onClick: handleShowModal },
                    React.createElement("i", { className: "bi bi-pencil-square" })))),
        showModal &&
            React.createElement(LayflatBinderModal, { layflatBinder: entry, showModal: showModal, handleClose: handleCloseModal, configuration: configuration, doGetConfiguration: doGetConfiguration })));
};
var LayflatBinderModal = function (_a) {
    var layflatBinder = _a.layflatBinder, showModal = _a.showModal, handleClose = _a.handleClose, configuration = _a.configuration, doGetConfiguration = _a.doGetConfiguration;
    var _b = useState(layflatBinder), entry = _b[0], setEntry = _b[1];
    var doCreate = function () { return __awaiter(void 0, void 0, void 0, function () {
        var url;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    url = "/api/admin/tco/configurations/".concat(configuration.machineName, "/layflat-binders");
                    return [4 /*yield*/, axios.post(url, entry)
                            .then(function () {
                            doGetConfiguration();
                            handleClose();
                            window.dispatchEvent(new CustomEvent('showFixedMessage', { detail: { message: { type: 'success', body: 'Layflat Binder Created', interval: 15000 } } }));
                        }).catch(function (error) {
                            handleClose();
                            window.dispatchEvent(new CustomEvent('showFixedMessage', { detail: { message: { type: 'error', body: "Failed to Create Layflat Binder: ".concat(error.response.data.error), interval: 30000 } } }));
                        })];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    var doUpdate = function () { return __awaiter(void 0, void 0, void 0, function () {
        var url;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    url = "/api/admin/tco/configurations/".concat(configuration.machineName, "/layflat-binders/").concat(entry.id);
                    return [4 /*yield*/, axios.put(url, entry)
                            .then(function () {
                            doGetConfiguration();
                            handleClose();
                            window.dispatchEvent(new CustomEvent('showFixedMessage', { detail: { message: { type: 'success', body: 'Layflat Binder Updated', interval: 15000 } } }));
                        }).catch(function (error) {
                            window.dispatchEvent(new CustomEvent('showFixedMessage', { detail: { message: { type: 'error', body: "Failed to Update Layflat Binder: ".concat(error.response.data.error), interval: 30000 } } }));
                        })];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    var handleSave = function () {
        !!entry.id ? doUpdate() : doCreate();
    };
    var handleOnChangeEntry = function (e) {
        var _a;
        var updatedEntry = __assign(__assign({}, entry), (_a = {}, _a[e.target.name] = e.target.value, _a));
        if (e.target.value === '') {
            // Special case: None
            delete updatedEntry[e.target.name];
        }
        setEntry(updatedEntry);
    };
    var handleOnChangeFolding = function (e) {
        var capabilities = e.target.checked
            ? [Capability.BINDING, Capability.FOLDING]
            : [Capability.BINDING];
        var updatedEntry = __assign(__assign({}, entry), { capabilities: capabilities });
        setEntry(updatedEntry);
    };
    return (React.createElement(Modal, { show: showModal, onHide: handleClose, size: "lg" },
        React.createElement(Modal.Header, { closeButton: true },
            React.createElement(Modal.Title, null, "Add Layflat Binder")),
        React.createElement(BsForm, { onSubmit: handleSave },
            React.createElement(Modal.Body, null,
                React.createElement(EditEquipment, { equipment: entry, setEquipment: function (equipment) { return setEntry(equipment); } }),
                React.createElement("div", { className: "card p-2 d-grid gap-2", style: { gridTemplateColumns: '1fr 1fr' } },
                    React.createElement("div", { className: "mb-4" },
                        React.createElement("label", { htmlFor: "sheetsPerHour", className: "form-label" }, "Sheets / Hour"),
                        React.createElement("input", { id: "sheetsPerHour", name: "sheetsPerHour", type: "number", className: "form-control", value: entry.sheetsPerHour, step: "1", onChange: handleOnChangeEntry })),
                    React.createElement("div", { className: "mb-4" },
                        React.createElement("label", { htmlFor: "requiredPrintType", className: "form-label" }, "Required Print Type"),
                        React.createElement("select", { id: "requiredPrintType", name: "requiredPrintType", className: "form-select", defaultValue: entry.requiredPrintType, onChange: handleOnChangeEntry },
                            React.createElement("option", { value: "" }, "None"),
                            React.createElement("option", { value: "LITHO" }, "LITHO"),
                            React.createElement("option", { value: "DIGITAL" }, "DIGITAL"))),
                    React.createElement("div", { className: "mb-4" },
                        React.createElement("label", { className: "form-label" }, "Capabilities"),
                        React.createElement("div", null,
                            React.createElement("input", { id: "capabilitiesBinding", name: "capabilitiesBinding", type: "checkbox", className: "form-check-input", value: "BINDING", checked: true, disabled: true }),
                            React.createElement("label", { htmlFor: "capabilitiesBinding", className: "form-label ms-2" }, "BINDING")),
                        React.createElement("div", null,
                            React.createElement("input", { id: "capabilitiesFolding", name: "capabilitiesFolding", type: "checkbox", className: "form-check-input", value: "FOLDING", defaultChecked: entry.capabilities.some(function (value) { return value === Capability.FOLDING; }), onChange: handleOnChangeFolding }),
                            React.createElement("label", { htmlFor: "capabilitiesFolding", className: "form-label ms-2" }, "FOLDING")))),
                React.createElement(LabourCosts, { equipment: entry, setEquipment: function (equipment) { return setEntry(equipment); } })),
            React.createElement(Modal.Footer, null,
                React.createElement(Button, { variant: "secondary", onClick: handleClose }, "Close"),
                React.createElement(Button, { variant: "primary", type: "submit" }, "Save")))));
};
export function LayflatBinders(_a) {
    var configuration = _a.configuration, doGetConfiguration = _a.doGetConfiguration;
    var _b = useState(false), showModal = _b[0], setShowModal = _b[1];
    var handleCloseModal = function () { return setShowModal(false); };
    var handleShowModal = function () { return setShowModal(true); };
    return (React.createElement("div", { className: "d-flex flex-column gap-4" },
        React.createElement("div", { className: "d-flex flex-column" },
            React.createElement("h4", null, "Layflat Binder"),
            React.createElement(Table, { striped: true, bordered: true, hover: true },
                React.createElement("thead", null,
                    React.createElement("tr", null,
                        React.createElement("th", null, "Name"),
                        React.createElement("th", null, "Cost"),
                        React.createElement("th", null, "Useful Life (mo)"),
                        React.createElement("th", null, "Hours / Month"),
                        React.createElement("th", null, "Maintenance Ratio"),
                        React.createElement("th", null, "Make Ready Time (min)"),
                        React.createElement("th", null, "Hourly Labour Costs"),
                        React.createElement("th", null, "Capabilities"),
                        React.createElement("th", null, "Required Print Type"),
                        React.createElement("th", null, "Sheets / Hour"),
                        React.createElement("th", null, "Active"),
                        React.createElement("th", null))),
                React.createElement("tbody", null, configuration && configuration.availableEquipment.layflatBinders.map(function (layflatBinder) { return (React.createElement(LayflatBinderRow, { key: layflatBinder.id, entry: layflatBinder, configuration: configuration, doGetConfiguration: doGetConfiguration })); }))),
            configuration && configuration.availableEquipment.layflatBinders.length === 0 && (React.createElement("p", null, "No data.")),
            React.createElement("div", { className: "d-flex flex-row gap-2 justify-content-end" },
                React.createElement("button", { type: "button", className: "align-self-end btn btn-primary", onClick: handleShowModal }, "+ Add Layflat Binder")),
            showModal &&
                React.createElement(LayflatBinderModal, { layflatBinder: JSON.parse(JSON.stringify(defaultValues)), showModal: showModal, handleClose: handleCloseModal, configuration: configuration, doGetConfiguration: doGetConfiguration }))));
}
