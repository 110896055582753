var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    width: 100%;\n"], ["\n    width: 100%;\n"])));
var VideoEvents = function (_a) {
    var data = _a.data;
    var box = null;
    if (data) {
        box = data.timeline.map(function (item, index) {
            var style = {
                left: "".concat(item.start, "%"),
                right: "".concat(100 - item.end, "%"),
            };
            var className = "view-type-".concat(item.type);
            return (React.createElement("div", { title: item.type, className: className, style: style, key: "".concat(data.start, "-").concat(data.end, "-").concat(data.duration, "-").concat(data.id, "-").concat(item.start, "-").concat(item.end, "-").concat(index) }));
        });
    }
    return (React.createElement(Wrapper, { className: "video-events" }, box));
};
export default VideoEvents;
var templateObject_1;
