import defineComponent from '../../../../../components/flight/lib/component';
import WithDashboardComponent from '../../../with-dashbord-component';
import Chartjs from '../../../../../components/Chart.js';

export default defineComponent(AnalyticsPayment, WithDashboardComponent);

function AnalyticsPayment() {
    const MARKER_COLORS = {
        card: "#6BD3CC",
        paypal: "#009AB6",
        "bank transfer": "#334B49",
        account: "#9E3A68",
        "amazon pay": "#6271B6",
        "volt pay": "#B1BC67",
        "pay by phone": "#8B5597",
        "afterpay" : "#6ED36B",
        "credit key": "#A6B77B",
        "proforma invoice": "#FF8181"
    };

    const MARKER_COLORS_HIGHLIGHT = {
        card: "#38A099",
        paypal: "#006783",
        "bank transfer": "#1A3230",
        account: "#6B0735",
        "amazon pay": "#2F3E83",
        "volt pay": "#471FBC",
        "pay by phone": "#582264",
        "afterpay" : "#3AB736",
        "credit key" : "#90A55A",
        "proforma invoice" : "#FF6060"
    };

    // TODO Do we need to add Stripe, Afterpay?
    this.attributes({
        counterIn: 'PAYMENT',
        counterCard: 'PAYMENTCARD',
        counterPaypal: 'PAYMENTPAYPAL',
        counterAmazonpay: 'PAYMENTAMAZONPAY',
        counterVoltpay: 'PAYMENTVOLTPAY',
        counterPayByPhone: 'PAYMENTBYPHONE',
        counterBank: 'PAYMENTBANK',
        counterAccount: 'PAYMENTACCOUNT',
        counterErrors: 'PAYMENTERROR',
        counterRefund: 'PAYMENTREFUND',
        counterAfterpay: 'PAYMENT_AFTERPAY',
        counterCreditKey: 'PAYMENTCREDITKEY',
        counterProforma: 'PAYMENTPROFORMA',
        errorsSlector: '[data-type="payments-errors"]',
        chartSelector: '.payment-chart',
        clipperSelector: '.donat-clipper'
    });

    this.reset = function () {
        this.counters[this.attr.counterIn] = this.createCounter(this.attr.counterIn);
        this.counters[this.attr.counterCard] = this.createCounter(this.attr.counterCard);
        this.counters[this.attr.counterAfterpay] = this.createCounter(this.attr.counterAfterpay);
        this.counters[this.attr.counterPaypal] = this.createCounter(this.attr.counterPaypal);
        this.counters[this.attr.counterAmazonpay] = this.createCounter(this.attr.counterAmazonpay);
        this.counters[this.attr.counterVoltpay] = this.createCounter(this.attr.counterVoltpay);
        this.counters[this.attr.counterPayByPhone] = this.createCounter(this.attr.counterPayByPhone);
        this.counters[this.attr.counterBank] = this.createCounter(this.attr.counterBank);
        this.counters[this.attr.counterAccount] = this.createCounter(this.attr.counterAccount);
        this.counters[this.attr.counterErrors] = this.createCounter(this.attr.counterErrors);
        this.counters[this.attr.counterRefund] = this.createCounter(this.attr.counterRefund);
        this.counters[this.attr.counterCreditKey] = this.createCounter(this.attr.counterCreditKey);
        this.counters[this.attr.counterProforma] = this.createCounter(this.attr.counterProforma);
        this.display(true);
    };

    this.isInteresting = function (counter) {
        return counter.cycle === "DAILY" && (this.attr.counterIn === counter.type ||
            this.attr.counterIn === counter.type ||
            this.attr.counterCard === counter.type ||
            this.attr.counterAfterpay === counter.type ||
            this.attr.counterPaypal === counter.type ||
            this.attr.counterAmazonpay === counter.type ||
            this.attr.counterVoltpay === counter.type ||
            this.attr.counterPayByPhone === counter.type ||
            this.attr.counterBank === counter.type ||
            this.attr.counterAccount === counter.type ||
            this.attr.counterRefund === counter.type ||
            this.attr.counterErrors === counter.type ||
            this.attr.counterCreditKey === counter.type ||
            this.attr.counterProforma === counter.type);
    };

    this.hasValue = function () {
        var i;

        for (i = 0; i < this.types.length; i++) {
            if (this.counters[this.types[i].counter].sum) {
                return true;
            }
        }
    };

    this.display = function (isSilent) {
        if (this.timer) {
            clearTimeout(this.timer);
        }

        this.timer = setTimeout(() => {
            this.timer = null;
            if (this.chart && this.hasValue()) {
                this.types.forEach(type => this.chart.segments[this.index[type.counter]].value = this.counters[type.counter].sum);
                this.select('clipperSelector').addClass("in");
                this.chart.update();
            } else {
                this.select('clipperSelector').removeClass("in");
            }

            if (this.counters[this.attr.counterErrors].count) {
                this.select('errorsSlector').show("normal");
            } else {
                this.select('errorsSlector').hide("normal");
            }

            Object.keys(this.counters).forEach(key => this.updateUiCounters(this.counters[key], isSilent));
        }, 200);
    };

    this.init = function () {
        var that = this,
            ctx = this.select('chartSelector').get(0).getContext("2d");

        this.index = {};
        /** @namespace Chartjs.Pie */
        this.chart = new Chartjs(ctx).Pie(this.types.map(function (type, index) {
            var typelc = type.name.toLowerCase();
            that.index[type.counter] = index;
            return {
                value: 1,
                color: MARKER_COLORS[typelc],
                highlight: MARKER_COLORS_HIGHLIGHT[typelc],
                label: type.name
            };
        }), {
            responsive: true,
            maintainAspectRatio: true
        });
    };


    this.after('initialize', function () {
        this.types = [
            {name: "Card", counter: this.attr.counterCard},
            {name: "Afterpay", counter: this.attr.counterAfterpay},
            {name: "Paypal", counter: this.attr.counterPaypal},
            {name: "Bank Transfer", counter: this.attr.counterBank},
            {name: "Account", counter: this.attr.counterAccount},
            {name: "Amazon Pay", counter: this.attr.counterAmazonpay},
            {name: "Volt Pay", counter: this.attr.counterVoltpay},
            {name: "Pay By Phone", counter: this.attr.counterPayByPhone},
            {name: "Credit Key", counter: this.attr.counterCreditKey},
            {name: "Proforma Invoice", counter: this.attr.counterProforma}
            ];

        this.counters = {};
        this.reset();

        this.init();
        this.on(document, "dashbordReset", this.reset);
        this.on(document, "dashbordCounterUpdate", this.counterUpdate);

    });

}