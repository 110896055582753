import defineComponent from '../../../../components/flight/lib/component';
import Product from '../../constants/products';
import Binding from '../../constants/bindings';

export default defineComponent(PaperTypeWeightEditor);

function PaperTypeWeightEditor() {

    this.attributes({
        saveWeightsButton: '[data-type="save-weights-btn"]',
        deleteWeightRowButton: '[data-type="delete-weight-row-btn"]',
        addWeightRowButton: '[data-type="add-weight-row-btn"]',
        weightTableBody: '[data-type="weights-table-body"]',

    });

    this.saveWeights = function(event) {

        const saveUrl = `/admin/catalogue/paper-types/${this.paperTypeId}/update-weights`;

        const editor = this;

        if(this.node.checkValidity()) {

            editor.initSaveBtn();

            const tableRows = this.select('weightTableBody').children('tr');

            const weights = [];
            tableRows.each(function(){
                const weightData = {
                    id: $(this).find('[data-field="id"]').val(),
                    gsm: $(this).find('[data-field="gsm"]').val(),
                    libs: $(this).find('[data-field="libs"]').val(),
                    caption: $(this).find('[data-field="caption"]').val(),
                    caliper: $(this).find('[data-field="caliper"]').val(),
                    microns: $(this).find('[data-field="microns"]').val(),
                    active: $(this).find('[data-field="active"]').val()
                };

                const typeValue = $(this).find('[data-field="type"]').val();
                if(typeValue) {
                    weightData.type = typeValue;
                }
                weights.push(weightData);
            });

           fetch(saveUrl, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(weights)
            }).then((data) => {
               editor.resetSaveBtn();
            }).catch((error) => {
               editor.resetSaveBtn();
               alert('Error Saving Data');
            });

            event.preventDefault();
        } else {
            $(this.node).addClass('was-validated');
        }
    };

    this.deleteWeightRow = function(event) {
        let tableRow = $(event.target).closest('tr');
        tableRow.remove();
        event.preventDefault();
    };

    this.addWeightRow = function(event) {

        const newWeightRow = `
            <tr>
                <td>
                    <input min="0" max="999999999" step="1" class="form-control" type="number" data-field="id" required value="" />
                </td>
                <td>
                    <input min="0" max="999999999" step="1" class="form-control" type="number" data-field="gsm" required value="" />
                </td>
                <td>
                    <input min="0" max="999999999" step="1" class="form-control" type="number" data-field="libs" value="" />
                </td>
                <td>
                    <select data-field="type" class="form-select" required>
                        <option value="0">TEXT</option>
                        <option value="1">COVER</option>
                    </select>
                </td>
                <td>
                    <input class="form-control" type="text" data-field="caption" required value="" />
                </td>
                <td>
                    <input min="0" max="999999999" step="0.01" class="form-control" type="number" data-field="caliper" required value="" />
                </td>
                <td>
                    <input min="0" max="999999999" step="0.01" class="form-control" type="number" data-field="microns" value="" />
                </td>
                <td>
                    <select data-field="active" class="form-select" required>
                        <option value="true">Yes</option>
                        <option value="false">No</option>
                    </select>
                </td>
                <td class="text-end">
                    <button class="btn btn-danger" data-type="delete-weight-row-btn">Delete</button>
                </td>
            </tr>
        `;
        
        this.select('weightTableBody').append(newWeightRow);

        event.preventDefault();
    };

    this.initSaveBtn = function(event) {
        let saveBtn = this.select('saveWeightsButton');
        setTimeout( function() {
            saveBtn.addClass('disabled');
            saveBtn.find('[data-type="spinner"]').removeClass('d-none');
            saveBtn.find('[data-type="btn-label"]').text("Saving");
        }, 1);
    };

    this.resetSaveBtn = function(event) {
        let saveBtn = this.select('saveWeightsButton');
        setTimeout( function() {
            saveBtn.find('[data-type="spinner"]').addClass('d-none');
            saveBtn.find('[data-type="btn-label"]').text("Save");
            saveBtn.removeClass('disabled');
        }, 2000);
    };

    this.after('initialize', function () {
        this.paperTypeId = $(this.node).data('paper-type-id');
        this.on('click', {
            saveWeightsButton: this.saveWeights,
            deleteWeightRowButton: this.deleteWeightRow,
            addWeightRowButton: this.addWeightRow
        });
    });
}
