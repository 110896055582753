var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useRef, useState } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { Card, Button, Form } from 'react-bootstrap';
import { Formik, Field } from 'formik';
import { CKEditor, CKEditorContext } from '@ckeditor/ckeditor5-react';
import Context from '@ckeditor/ckeditor5-core/src/context.js';
import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor.js';
import Bold from '@ckeditor/ckeditor5-basic-styles/src/bold.js';
import Italic from '@ckeditor/ckeditor5-basic-styles/src/italic.js';
import Essentials from '@ckeditor/ckeditor5-essentials/src/essentials.js';
import Paragraph from '@ckeditor/ckeditor5-paragraph/src/paragraph.js';
import Autoformat from "@ckeditor/ckeditor5-autoformat/src/autoformat.js";
import Heading from "@ckeditor/ckeditor5-heading/src/heading.js";
import Link from "@ckeditor/ckeditor5-link/src/link.js";
import List from "@ckeditor/ckeditor5-list/src/list.js";
import SourceEditing from "@ckeditor/ckeditor5-source-editing/src/sourceediting.js";
import PasteFromOffice from "@ckeditor/ckeditor5-paste-from-office/src/pastefromoffice.js";
import Clipboard from "@ckeditor/ckeditor5-clipboard/src/clipboard.js";
var style = {
    border: '1px dashed gray',
    cursor: 'move',
};
export var FaqItem = function (_a) {
    var id = _a.id, title = _a.title, content = _a.content, index = _a.index, newItem = _a.newItem, moveFaqItem = _a.moveFaqItem, updateFaqItem = _a.updateFaqItem, deleteFaqItem = _a.deleteFaqItem;
    var _b = useState(false), isEdit = _b[0], setEdit = _b[1];
    var _c = useState(newItem), isNewItem = _c[0], setNewItem = _c[1];
    var _d = useState(title), faqItemTitle = _d[0], setFaqItemTitle = _d[1];
    var _e = useState(content), faqItemContent = _e[0], setFaqItemContent = _e[1];
    var ref = useRef(null);
    var _f = useState(false), validated = _f[0], setValidated = _f[1];
    var handleSubmit = function (event) {
        var form = event.currentTarget;
        if (form.checkValidity()) {
            updateFaqItem(id, faqItemTitle, faqItemContent);
            setEdit(false);
            setNewItem(false);
        }
        event.preventDefault();
        event.stopPropagation();
        setValidated(true);
    };
    var _g = useDrop({
        accept: "FaqItem",
        collect: function (monitor) {
            return {
                handlerId: monitor.getHandlerId(),
            };
        },
        hover: function (item, monitor) {
            var _a;
            if (!ref.current) {
                return;
            }
            var dragIndex = item.index;
            var hoverIndex = index;
            // Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                return;
            }
            // Determine rectangle on screen
            var hoverBoundingRect = (_a = ref.current) === null || _a === void 0 ? void 0 : _a.getBoundingClientRect();
            // Get vertical middle
            var hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
            // Determine mouse position
            var clientOffset = monitor.getClientOffset();
            // Get pixels to the top
            var hoverClientY = clientOffset.y - hoverBoundingRect.top;
            // Only perform the move when the mouse has crossed half of the items height
            // When dragging downwards, only move when the cursor is below 50%
            // When dragging upwards, only move when the cursor is above 50%
            // Dragging downwards
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return;
            }
            // Dragging upwards
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return;
            }
            moveFaqItem(dragIndex, hoverIndex);
            item.index = hoverIndex;
        },
    }), handlerId = _g[0].handlerId, drop = _g[1];
    var _h = useDrag({
        type: 'FaqItem',
        item: function () {
            return { id: id, index: index };
        },
        collect: function (monitor) { return ({
            isDragging: monitor.isDragging(),
        }); },
    }), isDragging = _h[0].isDragging, drag = _h[1];
    var opacity = isDragging ? 0 : 1;
    drag(drop(ref));
    return (React.createElement(Card, { className: "mt-3", ref: ref, style: __assign(__assign({}, style), { opacity: opacity }) },
        React.createElement(Card.Body, null,
            !isEdit &&
                React.createElement("div", null,
                    React.createElement(Card.Title, null, faqItemTitle),
                    React.createElement(Card.Text, { dangerouslySetInnerHTML: { __html: faqItemContent } })),
            isEdit &&
                React.createElement(Formik, { initialValues: {
                        faqItemTitle: faqItemTitle,
                        faqItemContent: faqItemContent
                    }, onSubmit: function () { return __awaiter(void 0, void 0, void 0, function () {
                        return __generator(this, function (_a) {
                            updateFaqItem(id, faqItemTitle, faqItemTitle);
                            return [2 /*return*/];
                        });
                    }); } }, function (_a) {
                    var setFieldValue = _a.setFieldValue;
                    return (React.createElement(Form, { noValidate: true, validated: validated, onSubmit: handleSubmit },
                        React.createElement(Form.Group, { className: "mb-3", controlId: "faqSectionTitle" },
                            React.createElement(Form.Label, null, "Title"),
                            React.createElement(Field, { className: "form-control", type: "text", name: "faqItemTitle", onChange: function (e) {
                                    setFaqItemTitle(e.target.value);
                                    setFieldValue('faqItemTitle', e.target.value);
                                } })),
                        React.createElement(Form.Group, { className: "mb-3", controlId: "faqSectionContent" },
                            React.createElement(Form.Label, null, "Content"),
                            React.createElement(CKEditorContext, { context: Context },
                                React.createElement(CKEditor, { editor: ClassicEditor, config: {
                                        plugins: [Paragraph, Bold, Italic, Essentials, Autoformat, Heading, Link, List, SourceEditing, PasteFromOffice, Clipboard],
                                        toolbar: ['heading', 'bold', 'italic', 'bulletedList', 'numberedList', 'link', 'sourceEditing', 'undo', 'redo']
                                    }, onChange: function (e, editor) {
                                        setFaqItemContent(editor.getData());
                                        setFieldValue('faqItemContent', editor.getData());
                                    }, data: faqItemContent }))),
                        React.createElement(Button, { variant: "primary", type: "submit", className: "me-1" }, "Update")));
                })),
        !isEdit &&
            React.createElement(Card.Footer, null,
                React.createElement(Button, { variant: "primary", className: "me-1", onClick: function (e) {
                        setEdit(true);
                    } }, "Edit"),
                React.createElement(Button, { variant: "danger", className: "me-1", onClick: function (e) { deleteFaqItem(id); } }, "Delete"))));
};
