import defineComponent from '../../../components/flight/lib/component';
import WithSubmitAnimation from './../with-submit-zoom-animation';

export default defineComponent(UserObligo, WithSubmitAnimation);

function UserObligo() {


    this.attributes({
        saveBtnSelector: '[data-type="save-obligo"]',
        inputSelector: 'input[type="number"]',
        formSelector: "form"
    });

    this.handleObligoSubmit = function (event) {
        const url = this.$node.data('action');
        let val = (this.select("inputSelector").val() || "").trim();

        if (val) {
            let obligo = parseInt(val.replace(/,/g, ""), 10);
            val = isNaN(obligo) ? "" : obligo;
        }
        this.handleSubmit(event); // start animation
        event.preventDefault();

        $.post( url,  {obligo: val || 0} ).
            done(response => this.resetAnimation());
    };

    this.after('initialize', function () {
        this.on("submit", this.handleObligoSubmit);
    });
}
