import defineComponent from '../../components/flight/lib/component';

export default defineComponent(FormAnimSubmitter);

function FormAnimSubmitter() {


    this.attributes({
        animBtnSelector: ".btn-anime"
    });

    this.handleSubmit = function (event) {
        if (!event.isDefaultPrevented()) {
            this.select('animBtnSelector').attr('data-loading', '*').attr('disabled', true);
        }
    };

    this.resetAnimation = function () {
        this.select('animBtnSelector').removeAttr('data-loading').attr('disabled', false);
    };


    this.after('initialize', function () {
        this.on('submit', this.handleSubmit);
    });
}
