import defineComponent from '../../../components/flight/lib/component';
import React from 'react';
import ReactDom from 'react-dom/client';
import { FourthwallAttributesManager } from '../react/fourthwall/FourthwallAttributesManager.tsx';

export default defineComponent(OrderItemFourthwallAttributesManager);

function OrderItemFourthwallAttributesManager() {

    this.attributes({
        containerSelector: '[data-type="fourthwall-attributes-container"]',
    });

    this.initFourthwallAttributesManager = function () {
        const manager = this;
        const root = ReactDom.createRoot(this.select('containerSelector').get(0));
        root.render(
            <FourthwallAttributesManager
                orderId={manager.orderId}
                itemId ={manager.itemId}
                sku={manager.sku}
                asn={manager.asn}
                disabled={manager.disabled}
            />
        );
    };

    this.after('initialize', function () {
        this.orderId = this.$node.data('order');
        this.itemId = this.$node.data('item');
        this.sku = this.$node.data('sku');
        this.asn = this.$node.data('asn');
        this.disabled = this.$node.data('disabled');
        this.initFourthwallAttributesManager();
    });
}