import defineComponent from '../../../../../components/flight/lib/component';
import WithDashboardComponent from '../../../with-dashbord-component';
import Chartjs from '../../../../../components/Chart.js';
export default defineComponent(AnalyticsShare, WithDashboardComponent);


function AnalyticsShare() {
    this.attributes({
        field: "count",
        chartSelector: '.account-manager-chart',
        clipperSelector: '.donat-clipper',
        dataElementSelector: '.counter-data'
    });

    this.isInteresting = function (counter) {
        return counter.cycle === "DAILY" && counter.type.indexOf(this.type) === 0;
    };


    this.reset = function () {
        this.$elements.each((i, element) => {
            var $element = $(element);

            [
                $element.data("data-counter")
            ].forEach(counterName => this.counters[counterName] = this.createCounter(counterName));

        });
        this.display(true);
    };

    this.calcTotal = function ($element, field) {
        var a = this.counters[$element.data("counter")] && this.counters[$element.data("counter")][field];

        return Math.round(a || 0);
    };


    this.display = function (isSilent) {
        if (this.timer) {
            clearTimeout(this.timer);
        }

        this.timer = setTimeout(() => {
            this.timer = null;
            this.$elements.each((index, element) => this.chart.datasets[0].bars[index].value = this.calcTotal($(element), this.attr.field));
            this.chart.update();
            Object.keys(this.counters).forEach(key => this.updateUiCounters(this.counters[key], isSilent));
        }, 200);
    };


    this.init = function () {
        var data = {
                labels: this.$elements.map(function () {
                    return $(this).data("name");
                }).get(),
                datasets: [
                    {
                        label: "Times",
                        fillColor: this.rgba(this.attr.field, 0, 0.65),
                        strokeColor: this.rgba(this.attr.field, 0, 0.85),
                        highlightFill: this.rgba(this.attr.field, 1, 0.95),
                        highlightStroke: this.rgba(this.attr.field, 1, 1),
                        data: this.$elements.map(function () {
                            return 0;
                        }).get()
                    }
                ]
            },
            ctx = this.select('chartSelector').get(0).getContext("2d");

        /** @namespace Chartjs.Bar */
        this.chart = new Chartjs(ctx).Bar(data, this.getChartOptions());
    };


    this.after('initialize', function () {
        this.counters = {};
        this.$elements = this.select('dataElementSelector');
        this.type = this.$node.data('counterType');

        this.attr.field = this.$node.data("field");
        this.init();

        this.reset();
        this.on(document, "dashbordReset", this.reset);
        this.on(document, "dashbordCounterUpdate", this.counterUpdate);
    });
}
