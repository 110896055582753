import mixam from '../../../../boot/mixam';
import defineComponent from '../../../../../components/flight/lib/component';
import WithGetReferrerType from './with-get-referrer-type';
import AdminAnalyticsUsersList from './admin-analytics-users-list';
import regional from '../../../../boot/regional';
import moment from 'moment';
import {getProductMetadata} from "../../../home/getProductMetadata";

// NOTE: this import looks unused, but it's very much needed!
import stomp from '../../../../../components/stomp/stomp171';

export default defineComponent(AdminAnalyticsUsersManager, WithGetReferrerType);

function inflateQuery(queryText) {
    const result = {};
    const dict = regional().dictionary;

    queryText.split("&").forEach(function (pair) {
        var a = pair.split("=");

        result[a[0]] = decodeURIComponent(a[1]);
        if (dict[a[0]]) {
            result[a[0] + "_"] = dict[a[0]][decodeURIComponent(a[1])];
        }
    });
    result.productId = parseInt(result.productId, 10);
    result.coverType = parseInt(result.coverType, 10);

    return result;
}

function AdminAnalyticsUsersManager() {
    var GET_LIST_DELAY = 250;

    this.attributes({
        contentSelector: '.content',
        customerCountSelector: '[data-type="Customers"] h2 span.counter',
        shareCountSelector: '[data-type="Shares"] h2 span.counter',
        botsCountSelector: '[data-type="Bots"] h2 span.counter',
        mixamCountSelector: '[data-type="Mixam"] h2 span.counter',
        customerSelector: '[data-type="customers-users"]',
        sharesSelector: '[data-type="shares-users"]',
        botsSelector: '[data-type="bots-users"]',
        mixamSelector: '[data-type="mixam-users"]'
    });

    this.getVisits = function () {
        var url = mixam.springUrl("admin/api/visits"),
            promise;

        if (this.timer) {
            clearTimeout(this.timer);
        }

        this.timer = setTimeout(() => {
            this.timer = null;
            promise = $.getJSON(url);

            promise.done(response => {
                this.visits = this.normalize(response);

                setTimeout(() => this.getVisits(), 120000);
                this.trigger("visitsListRecalculated", {visits: this.visits}); // for the map
                this.trigger("visitsListChangeNormalized", {sessions: this.visits}); // for the lists
            });
        }, GET_LIST_DELAY);
    };

    this.addHistory = function (session) {
        var that = this,
            ref;

        if (session.referrerList && session.referrerList.list.length) {
            ref = session.referrerList.list[0];
            session.history = {
                domain: this.getHostName(ref.url),
                type: ref.type,
                date: (new Date(ref.date)).format("dd/mm/yyyy")
            };
            if (session.referrerList.list.length > 1) {
                session.history.previous = session.referrerList.list.length;
                session.history.list = session.referrerList.list.map(ref => {
                    return {
                        domain: that.getHostName(ref.url),
                        type: ref.type,
                        keyword: ref.keyword,
                        date: (new Date(ref.date)).format("dd/mm/yyyy")
                    };
                });
            }
        }
    };

    this.getHostName = function (url) {
        try {
            if (url) {
                return this.parseUrl(url).hostname;
            }
        } catch (e) {
        }
        return "";
    };

    this.normalize = function (list) {
        list.forEach((session) => {
            var ip, key, user, address,
                ipList = [],
                userList,
                start = new Date(session.start);

            session.startHours = mixam.padl(start.getHours(), "0", 2);  //.format("HH:mm"); //"dddd, MMMM Do YYYY, HH:mm:ss"
            session.startMinutes = mixam.padl(start.getMinutes(), "0", 2);
            session.startTime = start.getTime();

            session.lastModified = session.lastModified || new Date();

            this.addHistory(session);


            for (ip in session.ips) {

                if (session.ua) {
                    session.ua.icon = (session.ua.icon || "").replace(/\.png$/, "");
                    /** @namespace session.ua.operatingSystem */
                    session.ua.operatingSystem.icon = (session.ua.operatingSystem.icon || "").replace(/\.png$/, "");
                }

                //noinspection JSUnfilteredForInLoop
                /** @namespace session.ips */
                address = session.ips[ip];
                if (address.location) {
                    address.location.countryCode = address.location.countryCode.toLowerCase();
                    session.location = address.location;
                    session.location.ip = address.ip;
                    session.location.hostName = address.hostName;
                }
                ipList.push(address);
                userList = [];
                for (key in address.users) {
                    //noinspection JSUnfilteredForInLoop
                    /** @namespace address.users */
                    user = address.users[key];
                    session.user = this.normalizeUser(user);
                    session.user.type = this.getUserType(session.user.isMixam, session.ua, session.location);
                    userList.push(user);

                    if (!session.refType) {
                        session.refType = this.getReferrerType(user, session);
                        if (session.refType === "share" || session.refType === "embed") {
                            session.user.type = "share";
                        }
                    }

                    /** @namespace user.views */
                    user.views.forEach((view) => this.normalizeView(view));
                }
                address.userList = userList;
            }
            if (!session.refType) {
                session.refType = "direct";
            }
            session.ipList = ipList;
        });
        return list;
    };

    this.normalizeView = function (view) {
        view.dateText = moment(view.date).format("HH:mm");
        if (view.url.indexOf('/orders/') !== -1 || view.url.indexOf('/share/') !== -1) {
            view.needLink = true;
        }

        // New Price Calculator Offer Requests.
        // TODO: Replace The Mustache Template With One Rendered By Thymeleaf
        if(view.offerRequests && view.offerRequests.length > 0) {
            view.hasQueries = true;
            view.queryList = view.offerRequests.map((offerRequest) => {
                if(!offerRequest.link) {
                    return;
                }
                const query = inflateQuery(offerRequest.link);
                const primaryComponent = offerRequest.itemSpecification.components[0];
                query.productName = offerRequest.productName;
                query.link = offerRequest.link;
                query.colors = primaryComponent.colours === 'PROCESS' ? 5 : 1;
                query['colors-5'] = primaryComponent.colours === 'PROCESS';
                if(primaryComponent.backColours && primaryComponent.backColours !== 'NONE') {
                    query.colors2 = primaryComponent.backColours === 'PROCESS' ? 5 : 1;
                    query['colors2-5'] = primaryComponent.backColours === 'PROCESS';
                }
                query.copies = offerRequest.itemSpecification.copies;
                query.orientation = primaryComponent.orientation === 'PORTRAIT' ? 0 : 1;
                if(offerRequest.itemSpecification.bound) {
                    query.pages = primaryComponent.pages;
                    switch(primaryComponent.binding.type) {
                        case 'STAPLED':
                            query.bound = 0;
                            break;
                        case 'PUR':
                            query.bound = 4;
                            break;
                        case 'CASE':
                            query.bound = 6;
                            break;
                        case 'WIRO':
                            query.bound = 8;
                            break;
                        case 'LOOP':
                            query.bound = 10;
                            break;
                        case 'CALENDAR_WIRO':
                            query.bound = 12;
                            break;
                        case 'SEWN':
                            query.bound = 14;
                            break;
                    }
                }
                if(!mixam.shop.isManagementConsole) { // Do Not Get Metadata For MixamCloud
                    const coverComponent = offerRequest.itemSpecification.components.find(component => component.componentType === 'COVER');
                    if (coverComponent) {
                        const metaData = getProductMetadata(query.productId, offerRequest.subProductId);
                        const coverSubstrate = metaData.coverSubstrateTypes.find(substrateType => substrateType.id = coverComponent.substrate.typeId);
                        if (coverSubstrate) {
                            const coverWeight = coverSubstrate.substrateColours[coverComponent.substrate.colourId].weights.find(weight => weight.id === coverComponent.substrate.weightId);
                            if (coverWeight) {
                                query.cover = coverWeight.weight;
                            }
                        }
                        query.coverColors = coverComponent.colours === 'PROCESS' ? 5 : 1;
                        query['coverColors-5'] = coverComponent.colours === 'PROCESS';
                        query['ccolors-5'] = coverComponent.colours === 'PROCESS';
                        if (coverComponent.backColours && coverComponent.backColours !== 'NONE') {
                            query.coverColors2 = coverComponent.backColours === 'PROCESS' ? 5 : 1;
                            query['coverColors2-5'] = coverComponent.backColours === 'PROCESS';
                        }
                    }
                }
                return query;
            });
        }

        if (view.apis && view.apis.length) {
            view.hasApis = true;
        }
    };

    this.normalizeUser = function (user) {
        "isMixam,isMember,isSupplier".split(",").forEach(function (prop) {
            if (!user[prop]) {
                delete user[prop];
            }
        });
        return user;
    };

    this.getUserType = function (isMixam, ua, location) {
        if (isMixam) {
            return "mixam";
        }

        /** @namespace location.city */
        if (!location) {
            return "bot";
        }

        if (ua) {
            return ("Browser" === ua.type || "Mobile Browser" === ua.type) ? "customer" : "bot";
        }
        return "bot";
    };

    this.refreshList = function () {
        var now = (new Date()).getTime(),
            interval = 4 * 60 * 1000,
            i, session;

        if (this.visits && this.visits.length) {
            for (i = this.visits.length - 1; i >= 0; i--) {
                session = this.visits[i];
                if (!session.lastModified) {
                    session.lastModified = new Date(now);
                }
                if (now - session.lastModified.getTime() >= interval) {
                    this.updateVisits({
                        status: "SUCCESS",
                        reason: "delete",
                        itemId: session.id
                    });
                }
            }
        }
    };

    this.getSessionIndex = function (sessionId) {
        var i,
            len;

        this.visits = this.visits || [];
        len = this.visits.length;
        for (i = 0; i < len; i++) {
            if (this.visits[i].id === sessionId) {
                return i;
            }
        }
        return -1;
    };


    this.upSertSession = function (session) {
        var originalSessionIndex = this.getSessionIndex(session.id);

        session.lastModified = new Date();
        if (originalSessionIndex !== -1) {
            this.visits[originalSessionIndex] = session;
        } else {
            this.visits.unshift(session);
        }
    };

    this.deleteSession = function (sessionId) {
        var originalSessionIndex = this.getSessionIndex(sessionId);

        if (originalSessionIndex !== -1) {
            this.visits.splice(originalSessionIndex, 1);
        }
    };

    this.updateVisits = function (notification) {

        if (notification.status === "SUCCESS") {

            if (notification.reason === "change") {
                if (notification.data &&  (mixam.shop.isManagementConsole ||  notification.data.shopId === mixam.shop.id)) {
                    this.upSertSession(notification.data);
                    this.trigger("visitsListRecalculated", {visits: this.visits});
                    this.trigger("visitsListUpsert", {sessions: this.normalize([notification.data])});
                }
            } else {
                this.deleteSession(notification.itemId);
                this.trigger("visitsListRecalculated", {visits: this.visits});
                this.trigger("visitsListDelete", {sessions: [{id: notification.itemId, user: {type: -1}}]});
            }
        }
    };


    /**
     * subscribe to STOMP service that notify us about changes
     * in a specific order and a specific item
     */
    this.subscribe = function () {
        this.trigger("log", {message: ["Subscribe to stomp channel shopanalyticsmessages"], title: "AdminAnalytics.subscribe"});
        this.stomp = Stomp.client(mixam.stompServiceUrl);

        this.stomp.debug = (...a) => {
            if (a.join('').indexOf('Whoops! Lost connection to') !== -1) {
                setTimeout(() => this.subscribe(), 10);
            }
            this.trigger("log", {message: a, title: "AdminAnalytics.subscribe"});
        };

        this.stomp.connect(mixam.stompWebUser, mixam.stompWebPass, () => {
                this.stomp.subscribe("/topic/shoprealtimemessages", (d) => {
                    var p = JSON.parse(d.body);
                    this.updateVisits(p);
                });

            }, err => this.trigger("log", {message: err}),
            '/');
    };

    this.after('initialize', function () {

        AdminAnalyticsUsersList.attachTo(this.select('customerSelector'), {
            counterElement: $(this.attr.customerCountSelector),
            filter: "customer"
        });
        AdminAnalyticsUsersList.attachTo(this.select('sharesSelector'), {
            counterElement: $(this.attr.shareCountSelector),
            filter: "share"
        });
        AdminAnalyticsUsersList.attachTo(this.select('botsSelector'), {
            counterElement: $(this.attr.botsCountSelector),
            filter: "bot"
        });
        AdminAnalyticsUsersList.attachTo(this.select('mixamSelector'), {
            counterElement: $(this.attr.mixamCountSelector),
            filter: "mixam"
        });


        setTimeout($.proxy(this.getVisits, this), 100);
        try {
            this.subscribe();
        } catch (e) {

        }
        setInterval($.proxy(this.refreshList, this), 20000);
    });
}
