var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useState, useCallback, useRef, useEffect } from "react";
import { Query, Builder, Utils as QbUtils } from '@react-awesome-query-builder/bootstrap';
import "bootstrap/dist/css/bootstrap.min.css";
import '@react-awesome-query-builder/bootstrap/css/styles.css';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import ParticipationRuleTest from "./ParticipationRuleTest";
import axios from "axios";
import AwesomeQueryBuilderHelper from "../../AwesomeQueryBuilder/AwesomeQueryBuilderHelper";
import ParticipationRuleConfig from "../AwesomeQueryBuilder/ParticipationRuleConfig";
var queryValue = { id: QbUtils.uuid(), type: "group" };
var savePolicyUrl = '/admin/api/participation-policies';
export var ParticipationRuleEditor = function (props) {
    var _a, _b;
    var _c = useState(undefined), tree = _c[0], setTree = _c[1];
    var _d = useState(((_b = (_a = props.participationPolicy) === null || _a === void 0 ? void 0 : _a.expression) === null || _b === void 0 ? void 0 : _b.query) ? JSON.parse(props.participationPolicy.expression.query) : queryValue), initialQuery = _d[0], setInitialQuery = _d[1];
    var _e = useState(props.participationPolicy), participationPolicy = _e[0], setParticipationPolicy = _e[1];
    var _f = useState(false), validated = _f[0], setValidated = _f[1];
    var policyForm = useRef(null);
    var _g = useState(false), hasError = _g[0], setError = _g[1];
    var _h = useState(''), errorMessage = _h[0], setErrorMessage = _h[1];
    var _j = useState(undefined), config = _j[0], setConfig = _j[1];
    var _k = useState(undefined), configData = _k[0], setConfigData = _k[1];
    var onChange = useCallback(function (immutableTree, config) {
        var spelExpression = QbUtils.spelFormat(immutableTree, config);
        setTree(immutableTree);
        setParticipationPolicy(function (policy) { return (__assign(__assign({}, policy), { expression: {
                spel: spelExpression ? spelExpression : '',
                query: JSON.stringify(QbUtils.getTree(immutableTree))
            } })); });
    }, []);
    var handlePolicyTypeChange = function (policyType) {
        setParticipationPolicy(function (policy) { return (__assign(__assign({}, policy), { participationPolicyType: policyType, shopIds: policyType !== 'SHOP' ? [] : policy.shopIds, machineIds: policyType !== 'MACHINE' ? [] : policy.machineIds, supplierIds: policyType !== 'SUPPLIER' ? [] : policy.supplierIds })); });
    };
    var initAwesomeQueryBuilder = function () { return __awaiter(void 0, void 0, void 0, function () {
        var configData_1, config_1, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, AwesomeQueryBuilderHelper.getConfigData()];
                case 1:
                    configData_1 = _a.sent();
                    if (configData_1) {
                        config_1 = ParticipationRuleConfig.getConfig(configData_1);
                        setTree(QbUtils.checkTree(QbUtils.loadTree(initialQuery), config_1));
                        setConfigData(configData_1);
                        setConfig(config_1);
                    }
                    return [3 /*break*/, 3];
                case 2:
                    error_1 = _a.sent();
                    console.error("Error Initializing Awesome Query Builder: ".concat(error_1));
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    useEffect(function () {
        initAwesomeQueryBuilder();
    }, []);
    var handleSupplierChange = function (suppliersIds) { return setParticipationPolicy(function (policy) { return (__assign(__assign({}, policy), { supplierIds: suppliersIds })); }); };
    var handleMachineChange = function (machineIds) { return setParticipationPolicy(function (policy) { return (__assign(__assign({}, policy), { machineIds: machineIds })); }); };
    var handleShopChange = function (shopIds) { return setParticipationPolicy(function (policy) { return (__assign(__assign({}, policy), { shopIds: shopIds })); }); };
    var handleNameChange = function (name) { return setParticipationPolicy(function (policy) { return (__assign(__assign({}, policy), { name: name })); }); };
    var handleActiveChange = function (active) { return setParticipationPolicy(function (policy) { return (__assign(__assign({}, policy), { active: active })); }); };
    var handleDescriptionChange = function (description) { return setParticipationPolicy(function (policy) { return (__assign(__assign({}, policy), { description: description })); }); };
    var handleCantParticipateMessageChange = function (cantParticipateMessage) { return setParticipationPolicy(function (policy) { return (__assign(__assign({}, policy), { cantParticipateMessage: cantParticipateMessage })); }); };
    var saveParticipationPolicy = function (event) {
        var form = policyForm.current;
        if (form.checkValidity() === false || participationPolicy.expression === undefined) {
            event.preventDefault();
            event.stopPropagation();
            setValidated(true);
            setError(true);
        }
        else {
            setValidated(true);
            if (!participationPolicy.expression.spel) {
                setError(true);
                setErrorMessage('You Cannot Have An Empty Rule');
            }
            else {
                if (participationPolicy.id) {
                    axios.put("".concat(savePolicyUrl, "/").concat(participationPolicy.id), participationPolicy)
                        .then(function (response) {
                        setError(false);
                        setParticipationPolicy(response.data);
                    })
                        .catch(function (error) {
                        setError(true);
                        setErrorMessage("Failed To Save Participation Policy: ".concat(error));
                    });
                }
                else {
                    axios.post("".concat(savePolicyUrl), participationPolicy)
                        .then(function (response) {
                        setParticipationPolicy(response.data);
                        setError(false);
                    })
                        .catch(function (error) {
                        setError(true);
                        setErrorMessage("Failed To Save Participation Policy: ".concat(error));
                    });
                }
            }
        }
    };
    var handleMultiSelectChange = function (event, handlerFunction) {
        var options = event.target.options;
        var selectedValues = [];
        for (var i = 0; i < options.length; i++) {
            if (options[i].selected) {
                selectedValues.push(options[i].value);
            }
        }
        handlerFunction(selectedValues);
    };
    var renderPolicyTypeOptions = function () {
        var participationPolicies = ['GLOBAL', 'SUPPLIER', 'MACHINE', 'SHOP'];
        return (participationPolicies.map(function (policy) { return (React.createElement("option", { key: policy, value: policy }, policy)); }));
    };
    var renderShopOptions = function () {
        return ((configData === null || configData === void 0 ? void 0 : configData.shops) &&
            Object.entries(configData.shops).map(function (_a) {
                var key = _a[0], value = _a[1];
                return React.createElement("option", { value: key, key: "shop-option-".concat(key) }, value);
            }));
    };
    var renderOptGroupSelectOptions = function (options, labelKey, valueKey, itemKey) {
        var selectComponent = (options &&
            Object.entries(options).map(function (_a) {
                var label = _a[0], value = _a[1];
                return (React.createElement("optgroup", { label: label, key: "".concat(itemKey, "-optgroup-").concat(label) }, Object.entries(value).map(function (_a) {
                    var value = _a[0], label = _a[1];
                    return (React.createElement("option", { value: value, key: "".concat(itemKey, "-option-").concat(label, "-").concat(value) }, label));
                })));
            }));
        return selectComponent;
    };
    var renderParticipationRulesForm = function () { return (React.createElement(Form, { noValidate: true, validated: validated, ref: policyForm },
        React.createElement("p", null, "Specification's that match the configured rule will be thrown out of the pricing engine."),
        React.createElement(Row, { className: "mb-3" },
            React.createElement(Col, { className: "col col-md-12 col-lg-4" },
                React.createElement(Form.Group, { as: Col },
                    React.createElement(Form.Label, { className: "h6" }, "Name"),
                    React.createElement(Form.Control, { type: "text", id: "policyNameInput", required: true, "aria-describedby": "nameHelpBlock", onChange: function (e) { handleNameChange(e.target.value); }, defaultValue: participationPolicy.name }),
                    React.createElement(Form.Text, { id: "nameHelpBlock", muted: true }, "A brief name to easily identify the rule")))),
        React.createElement(Row, { className: "mb-3" },
            React.createElement(Col, { className: "col col-md-12 col-lg-4" },
                React.createElement(Form.Group, { as: Col },
                    React.createElement(Form.Label, { className: "h6" }, "Description"),
                    React.createElement(Form.Control, { as: "textarea", rows: 3, required: true, id: "policyDescriptionInput", "aria-describedby": "descriptionHelpBlock", onChange: function (e) { handleDescriptionChange(e.target.value); }, defaultValue: participationPolicy.description }),
                    React.createElement(Form.Text, { id: "descriptionHelpBlock", muted: true }, "A detailed description of what the rule actually does")))),
        React.createElement(Row, { className: "mb-3" },
            React.createElement(Col, { className: "col col-md-12 col-lg-4" },
                React.createElement(Form.Group, { as: Col },
                    React.createElement(Form.Label, { className: "h6" }, "Cant Participate Error Message"),
                    React.createElement(Form.Control, { as: "textarea", rows: 3, required: true, id: "cantParticipateMessageInput", "aria-describedby": "cantParticipateMessageHelpBlock", onChange: function (e) { handleCantParticipateMessageChange(e.target.value); }, defaultValue: participationPolicy.cantParticipateMessage }),
                    React.createElement(Form.Text, { id: "cantParticipateMessageHelpBlock", muted: true }, "The error message that appears in the declined offers")))),
        React.createElement(Row, { className: "mb-3" },
            React.createElement(Col, { className: "col col-xs-12 col-md-4" },
                React.createElement(Form.Group, { as: Col, controlId: "policyActive" },
                    React.createElement(Form.Label, { className: "h6" }, "Active"),
                    React.createElement(Form.Select, { onChange: function (e) { return handleActiveChange(e.target.value); }, required: true, defaultValue: String(participationPolicy.active) },
                        React.createElement("option", { value: "true" }, "Yes"),
                        React.createElement("option", { value: "false" }, "No")),
                    React.createElement(Form.Text, { id: "policyActiveHelpBlock", muted: true }, "Enable or disable the policy")))),
        React.createElement(Row, { className: "mb-3" },
            React.createElement(Col, { className: "col col-xs-12 col-md-4" },
                React.createElement(Form.Group, { as: Col, controlId: "policyType" },
                    React.createElement(Form.Label, { className: "h6" }, "Policy Type"),
                    React.createElement(Form.Select, { onChange: function (e) { return handlePolicyTypeChange(e.target.value); }, required: true, defaultValue: participationPolicy.participationPolicyType }, renderPolicyTypeOptions()),
                    React.createElement(Form.Text, { id: "policyTypeHelpBlock", muted: true }, "The type of participation rule")))),
        (participationPolicy === null || participationPolicy === void 0 ? void 0 : participationPolicy.participationPolicyType) === 'SUPPLIER' &&
            React.createElement(Row, { className: "mb-3" },
                React.createElement(Col, { className: "col col-xs-12 col-md-4" },
                    React.createElement(Form.Group, { as: Col, controlId: "policySupplierIds" },
                        React.createElement(Form.Label, { className: "h6" }, "Suppliers"),
                        React.createElement(Form.Select, { multiple: true, defaultValue: participationPolicy.supplierIds, htmlSize: 10, onChange: function (e) { return handleMultiSelectChange(e, handleSupplierChange); }, required: participationPolicy.participationPolicyType === 'SUPPLIER' }, renderOptGroupSelectOptions(configData === null || configData === void 0 ? void 0 : configData.allShopSuppliers, 'supplier', 'supplierId', 'supplier')),
                        React.createElement(Form.Text, { id: "supplierHelpBlock", muted: true }, "Please select the suppliers this rule is applicable for")))),
        (participationPolicy === null || participationPolicy === void 0 ? void 0 : participationPolicy.participationPolicyType) === 'MACHINE' &&
            React.createElement(Row, { className: "mb-3" },
                React.createElement(Col, { className: "col col-xs-12 col-md-4" },
                    React.createElement(Form.Group, { as: Col, controlId: "policyMachineIds" },
                        React.createElement(Form.Label, { className: "h6" }, "Machines"),
                        React.createElement(Form.Select, { multiple: true, defaultValue: participationPolicy.machineIds, htmlSize: 10, onChange: function (e) { return handleMultiSelectChange(e, handleMachineChange); }, required: participationPolicy.participationPolicyType === 'MACHINE' }, renderOptGroupSelectOptions(configData === null || configData === void 0 ? void 0 : configData.allShopMachines, 'machine', 'machineId', 'machine')),
                        React.createElement(Form.Text, { id: "machineHelpBlock", muted: true }, "Please select the machines this rule is applicable for")))),
        (participationPolicy === null || participationPolicy === void 0 ? void 0 : participationPolicy.participationPolicyType) === 'SHOP' &&
            React.createElement(Row, { className: "mb-3" },
                React.createElement(Col, { className: "col col-xs-12 col-md-4" },
                    React.createElement(Form.Group, { as: Col, controlId: "policyShopIds" },
                        React.createElement(Form.Label, { className: "h6" }, "Shops"),
                        React.createElement(Form.Select, { multiple: true, defaultValue: participationPolicy.shopIds, htmlSize: 10, onChange: function (e) { return handleMultiSelectChange(e, handleShopChange); }, required: participationPolicy.participationPolicyType === 'SHOP' }, renderShopOptions()),
                        React.createElement(Form.Text, { id: "shopHelpBlock", muted: true }, "Please select the shops this rule is applicable for")))))); };
    var renderBuilder = useCallback(function (props) { return (React.createElement("div", { className: "query-builder-container" },
        React.createElement("h5", null, "Rule"),
        React.createElement("div", { className: "query-builder" },
            React.createElement(Builder, __assign({}, props))))); }, []);
    var renderQueryBuilder = function () { return (React.createElement(Query, __assign({}, config, { value: tree, onChange: onChange, renderBuilder: renderBuilder }))); };
    var renderExpression = function () { return (React.createElement("div", { className: "query-builder-result" },
        React.createElement("div", { className: "pt-3" },
            React.createElement("h6", null, "SpEl Expression"),
            React.createElement("pre", { style: { whiteSpace: "pre-wrap", wordBreak: "keep-all" } }, JSON.stringify(QbUtils.spelFormat(tree, config)))))); };
    return (React.createElement(React.Fragment, null, config &&
        React.createElement(React.Fragment, null,
            React.createElement(Card, { className: "mb-3" },
                React.createElement(Card.Header, null, "Rule Builder"),
                React.createElement(Card.Body, null,
                    renderParticipationRulesForm(),
                    renderQueryBuilder(),
                    renderExpression(),
                    hasError &&
                        React.createElement(Alert, { variant: "danger" }, errorMessage),
                    React.createElement(Button, { variant: "primary", onClick: saveParticipationPolicy }, "Save"))),
            React.createElement(ParticipationRuleTest, { configData: configData, participationPolicy: participationPolicy }))));
};
export default ParticipationRuleEditor;
