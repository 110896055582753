export default WithConfirmCancelBar;

function WithConfirmCancelBar() {


    this.reset = function () {
        this.$elements.each( (i, element) => {
            var $element = $(element);

            [
                $element.data("counter-confirm"),
                $element.data("counter-unconfirm"),
                $element.data("counter-cancel")
            ].forEach(counterName => this.counters[counterName] = this.createCounter(counterName));

        });
        this.display(true);
    };

    this.calcTotal = function ($element, field) {
        var a = this.counters[$element.data("counter-confirm")][field],
            b = this.counters[$element.data("counter-unconfirm")][field],
            c = this.counters[$element.data("counter-cancel")][field];

        return Math.round(a - (b + c));
    };

    this.getChartOptions = function () {
        return {
            responsive: true,
            maintainAspectRatio: false
        };
    };

}
