const bindMap = {
    "BIND_SADDLE_STITCHED": 0,
    "BIND_PERFECT_BOUND": 2,
    "BIND_PERFECT_BOUND_PUR": 4,
    "BIND_CASE": 6,
    "BIND_WIRO": 8,
    "BIND_LOOP": 10,
    "BIND_CALENDAR_WIRO": 12,
    "BIND_COIL": 16,
};

export default {
    BIND: bindMap
};