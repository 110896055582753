import defineComponent from '../../../../components/flight/lib/component';
import mixam from '../../../boot/mixam';
import {selectCurrency} from "../../react/self-publishing/PublicationSettings/CurrencyUtils.tsx";

export default defineComponent(PaypalManager);

function PaypalManager() {

    this.attributes({
        buttonsDivSelector: '[data-type="paypal-buttons"]',
        errorsDivSelector: '[data-type="paypal-errors"]',
        errorSelector: '[data-type="paypal-error"]',
        declinedSelector: '[data-type="paypal-declined"]',
        validationErrorSelector: '[data-type="pp-amount"].paypal-validationError',
        paypalAmountSelector: '[data-type="pp-amount"]',
        paypalMessagesSelector: '[data-pp-amount]'
    });

    this.initPaypal = function () {
        /*
         * See https://developer.paypal.com/sdk/js/
         */
        paypal.Buttons({
            createOrder: (data, actions) => {
                return fetch("/api/paypal/orders", {
                    method: "post",
                    headers: {
                        'Accept': 'application/json'
                    },
                    body: this.orderId
                }).then(response =>
                    response.json()
                ).then(createOrderResponse =>
                    createOrderResponse.paypalOrderId
                ).catch(error => {
                    this.select('buttonsDivSelector').hide();
                    this.select('errorsDivSelector').show();
                });
            },
            onApprove: (data, actions) => {
                this.select('buttonsDivSelector').hide();
                fetch("/api/paypal/capture", {
                    method: "post",
                    headers: {
                        'Accept': 'application/json'
                    },
                    body: data.orderID
                }).then(response =>
                    response.json()
                ).then(captureResponse => {
                    if(captureResponse.status === "OK") {
                        window.location.reload(true);
                    } else {
                        if(captureResponse.error) {
                            this.updatePaypalAmount(captureResponse.orderAmount);
                            this.select('errorsDivSelector').show();
                            this.select('validationErrorSelector').append(`<p>${captureResponse.error}</p>`).show();
                        } else {
                            this.select('errorsDivSelector').show();
                            this.select('declinedSelector').show();
                        }
                        this.select('buttonsDivSelector').show();
                    }
                }).catch(error => {
                    this.select('errorsDivSelector').show();
                    this.select('errorSelector').show();
                });
            },
            onError: (err) => {
                this.select('buttonsDivSelector').hide();
                this.select('errorSelector').show();
            }
        }).render('#paypal-button-container');
    };

    this.updatePaypalAmount = function (amount) {
        const numberFormatLocale = mixam.shop.locale.replace('_','-');
        const formattedAmount = new Intl.NumberFormat(numberFormatLocale, {
            style: 'currency',
            currency: selectCurrency(numberFormatLocale).code
        }).format(amount);
        this.select('paypalAmountSelector').text(formattedAmount);
        this.select('paypalMessagesSelector').attr('data-pp-amount', amount);
    };

    this.setOrder = function (event, order) {
        const amount = Math.abs(order.leftToPay || 0).formatNumber(2);
        this.updatePaypalAmount(amount);
    };

    this.after('initialize', function () {
        this.orderId = this.$node.data().orderId;

        this.on(document, "setOrderData", this.setOrder);

        setTimeout(() => {
            if (typeof window.paypal !== 'undefined') {
                this.initPaypal();
            }
        }, 500);
    });
}
