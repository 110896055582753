import ReactDom from  'react-dom';
import React from 'react';
import proptypes from 'prop-types';
const rd = ReactDom;

const propTypes = {

    /**
     * CSS class or classes applied when the component is exited
     */
    className: proptypes.string,

};

function noop() {
}

const defaultProps = {
    in: false,
    onChange: noop
};

class AnimatedCheckRadioBoxList extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            value: props.value || 0
        };
    }

    componentDidMount() {
        this.props.onChange(this.state.value);
        /* const selected = React.Children.toArray(this.props.children).filter(child => child.props.value === this.state.value)[0];
         if (selected) {
           const cargo = selected.props.cargo;
           this.props.onChange(this.state.value, cargo);
         }*/
    }

    onChanged(e) {
        if (e.target.checked) {
            const value = parseInt(e.target.value, 10);
            this.setState({
                value: value
            });
            this.props.onChange(value);
        }
    }

    render() {
        const children = React.Children.map(this.props.children, (child, i) => {
            return React.cloneElement(child, {checked: child.props.value === this.state.value});
        });

        return <ul onChange={(event) => this.onChanged(event)} onClick={(event) => this.onChanged(event)}
                   className='animated-check-radio-box-list'>
            {children}
        </ul>;
    }
}

AnimatedCheckRadioBoxList.propTypes = propTypes;
AnimatedCheckRadioBoxList.defaultProps = defaultProps;

export default AnimatedCheckRadioBoxList;

