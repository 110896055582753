import mixam from '../../boot/mixam';
import defineComponent from '../../../components/flight/lib/component';

export default defineComponent(WorldShopPayments);

function WorldShopPayments() {

    this.attributes({
        url: "/admin/api/counters",
        paymentSumSelector: '[data-type="sum-payment"]',
        paymentCountSelector: '[data-type="count-payment"]',
        detailsSelector: '.details',
    });

    this.getData = function () {
        $.getJSON(`${this.attr.url}/${this.shopId}/totals/0`).done(response => {
            this.data = response;
            this.paint();
            setTimeout(() => this.getData(), 30 * 60000);
        }).fail((err) => this.trigger("log", {message: err}));
    };

    this.onCounter = function (data) {
        if (data.shopId !== this.shopId) {
            return;
        }
        if (data.cycle !== "DAILY") {
            return;
        }
        if (data.type === "PAYMENT") {
            if (this.updateCounter(data)) {
                this.paint();
            }
        }
    };

    this.paint = function () {
        this.select('paymentSumSelector').text(this.getCounterValue("PAYMENT", "sum").formatNumber(0));
        this.select('paymentCountSelector').text(this.getCounterValue("PAYMENT", "count").formatNumber(0));
        setTimeout(() => {
            this.select('paymentSumSelector').closest('div').hilightElement();
        }, 10);
    };

    //
    this.updateCounter = function (updatedCounter) {
        const counter = this.data.filter(c => c.type === updatedCounter.type && c.shopId === updatedCounter.shopId);
        //console.log("Loking for ", updatedCounter, " in ", this.data, " found ", counter.length);
        if (counter.length) {
            if (counter[0].sum !== updatedCounter.sum) {
                ["sum", "count", "cost", "delivery", "vat", "weight"].forEach(p => counter[0][p] = updatedCounter[p]);
                return true;
            }
        } else {
            this.data.push(updatedCounter);
            return true;
        }
        return false;
    };

    this.getCounterValue = function (counterName, property) {
        const counter = this.data.filter(c => c.type === counterName);
        if (counter.length) {
            return counter[0][property];
        }
        return 0;
    };

    /**
     * subscribe to STOMP service that notify us about changes
     * in a specific order and a specific item
     */
    this.subscribe = function (callback) {
        this.trigger("log", {message: ["Subscribe to stomp channel shopanalyticsmessages"], title: "WorldShopPayments.subscribe"});
        this.stomp = Stomp.client(mixam.stompServiceUrl);
        this.stomp.debug = (...a) => {
            if (a.join('').indexOf('Whoops! Lost connection to') !== -1) {
                setTimeout(() => this.subscribe(callback), 10);
            }
            this.trigger("log", {message: a, title: "santastats.subscribe"});
        };

        this.stomp.connect(mixam.stompWebUser, mixam.stompWebPass, () => {
            this.stomp.subscribe("/topic/shopanalyticsmessages", (d) => {
                const p = JSON.parse(d.body);

                if (p && p.data) {
                    callback(p.data);
                }
            });

        }, (err) => {
            this.trigger("log", {message: err});
        }, '/');
    };

    this.after('initialize', function () {
        this.shopId = this.$node.data('shop');
        this.getData();
        this.subscribe((x) => this.onCounter(x));
    });
}
