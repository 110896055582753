import defineComponent from '../../../../../components/flight/lib/component';
import WithDashboardComponent from '../../../with-dashbord-component';
import WithFulfilmentCancelBar from '../../../with-fulfilment-cancel-bar';
import Chartjs from '../../../../../components/Chart.js';

var COLORS = {
    cost: [[247, 70, 74, 1], [245, 21, 26, 1]],
    value: [[70, 191, 189, 1,], [54, 156, 154, 1]],
    count: [[70, 138, 191, 1], [54, 111, 156, 1]]
};

export default defineComponent(AnalyticsFulfilment, WithDashboardComponent, WithFulfilmentCancelBar);

function rgba(field, offset, opacity) {
    var a = COLORS[field][offset];

    a[3] = opacity;
    return `rgba(${a.join(",")})`;
}

function AnalyticsFulfilment() {

    this.attributes({
        field: "cost",
        chartSelector: '.fulfilment-chart',
        clipperSelector: '.donat-clipper',
        dataElementSelector: '.counter-data'
    });

    this.isInteresting = function (counter) {
        return counter.cycle === "DAILY" && counter.type.indexOf("FULFILMENT_") === 0;
    };


    this.display = function () {
        if (this.timer) {
            clearTimeout(this.timer);
        }

        this.timer = setTimeout(() => {
            this.timer = null;
            this.$elements.each((index, element) => this.chart.datasets[0].bars[index].value = this.calcTotal($(element), this.attr.field));
            this.chart.update();
        }, 200);
    };


    this.init = function () {
        var data = {
                labels: this.$elements.map(function () {
                    return $(this).data("name");
                }).get(),
                datasets: [
                    {
                        label: "Net Value",
                        fillColor: rgba(this.attr.field, 0, 0.65),
                        strokeColor: rgba(this.attr.field, 0, 0.85),
                        highlightFill: rgba(this.attr.field, 1, 0.95),
                        highlightStroke: rgba(this.attr.field, 1, 1),
                        data: this.$elements.map(function () {
                            return 0;
                        }).get()
                    }
                ]
            },
            ctx = this.select('chartSelector').get(0).getContext("2d");

        /** @namespace Chartjs.Bar */
        this.chart = new Chartjs(ctx).Bar(data, this.getChartOptions());
    };


    this.after('initialize', function () {
        this.counters = {};
        this.$elements = this.select('dataElementSelector');

        this.attr.field = this.$node.data("field");
        this.init();

        this.reset();
        this.on(document, "dashbordReset", this.reset);
        this.on(document, "dashbordCounterUpdate", this.counterUpdate);
    });
}
