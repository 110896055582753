var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    padding-right: 0.5rem;\n    width: 20rem;\n\n    @media (max-width: 700px) {\n        width: 100%;\n    }\n"], ["\n    padding-right: 0.5rem;\n    width: 20rem;\n\n    @media (max-width: 700px) {\n        width: 100%;\n    }\n"])));
var GlobalSearch = function (_a) {
    var data = _a.data, setFilteredData = _a.setFilteredData;
    var _b = useState(''), userInput = _b[0], setUserInput = _b[1];
    useEffect(function () {
        var filteredData = data.filter(function (obj) {
            return Object.values(obj)
                .filter(function (value) { return typeof value !== 'object' && value != null; })
                .map(function (value) { return value.toString().toLowerCase(); })
                .some(function (value) { return value.includes(userInput.toLowerCase()); });
        });
        setFilteredData(filteredData);
    }, [userInput, data]);
    return (React.createElement(Wrapper, { className: "input-group flex-nowrap align-items-start" },
        React.createElement("span", { className: "input-group-text" },
            React.createElement("i", { className: "bi bi-funnel" }),
            "\u00A0Filter"),
        React.createElement("input", { className: "form-control", type: "text", value: userInput, onChange: function (e) { return setUserInput(e.target.value); } })));
};
export default GlobalSearch;
var templateObject_1;
