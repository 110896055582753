import ReactDom from  'react-dom';
import React from 'react';

const rd = ReactDom;

class SamplesRequestModal extends React.Component {
    constructor(...args) {
        super(...args);
        this.state = {open: false, loading: false};
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        this.setState(
            {
                action: this.props.line.state
            }
        );
        this.requestId = this.props.line.id;
    }

    handleSubmit(event) {
        event.preventDefault();
        const url = `/admin/api/samplereq/${this.requestId}/status`;
        const modal = `sampleRequestModal-${this.requestId}`;
        const data = {
            status: this.state.action
        };
        console.log(url, modal, data);
        $.post(url, data, null, 'json').done((/*response*/) => {
            this.setState({loading: false, open: false});
            bootstrap.Modal.getInstance(this.refs[modal]).hide();
        });
    }

    handleChange(event) {
        this.setState({
            action: event.target.value
        });
    }

    render() {
        const line = this.props.line || [];
        let buttonCaption = 'Details';
        let btnType = 'btn-primary'
        if (line.state) {
            buttonCaption = line.state === 1 ? 'Sent' : (line.autoDec ? "Auto " : "") + 'Declined';
            btnType = line.state === 1 ? 'btn-danger' : 'btn-warning';
        }
        const modalContent = this.createSampleRequestContent(line);
        return (
            <div>
                <button type="button" className={`btn ${btnType} state-${line.state}`} data-bs-toggle="modal" data-bs-target={`#sampleRequest-${line.id}`}>{buttonCaption}</button>

                <div className="modal fade" id={`sampleRequest-${line.id}`} ref={`sampleRequestModal-${line.id}`} data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby={`sampleRequestLabel-${line.id}`} aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <form onSubmit={(event) => this.handleSubmit(event)}>
                                <div className="modal-header">
                                    <h5 className="modal-title" id={`sampleRequestLabel-${line.id}`}>Manage Sample Request</h5>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    {modalContent}
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                    <button type="submit" className="btn btn-primary" onClick={(e) => this.handleSubmit(e)}>Save</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

            </div>
        );
    }

    createSampleRequestContent(line) {

        return (

            <div className="sample-request-details">

                <div className="address">

                    <div className="border-bottom mb-3 pb-3">
                        <label className="form-label">Address</label>
                        <p className="mb-0">{line.name}</p>
                        {line.deliveryAddress.company && (
                            <p className="mb-0">{line.deliveryAddress.company}</p>
                        )}
                        <p className="mb-0">{line.deliveryAddress.line1}</p>
                        {line.deliveryAddress.line2 && (<p className="mb-0">{line.deliveryAddress.line2}</p>)}
                        <p className="mb-0">{line.deliveryAddress.town}</p>
                        {line.deliveryAddress.county && (<p className="mb-0">{line.deliveryAddress.county}</p>)}
                        <p className="mb-0">{line.deliveryAddress.postcode}</p>
                        <p className="mb-0">{line.deliveryAddress.country}</p>
                    </div>

                    <div className="border-bottom mb-3">
                        <label className="form-label">E-Mail Address</label>
                        <p>{line.email.caption}</p>
                    </div>

                    <div className="border-bottom mb-3">
                        <label className="form-label">Referer</label>
                        <p className="text-break">{line.referrer}</p>
                    </div>

                    {line.deliveryAddress.phone && (
                        <div className="border-bottom mb-3">
                            <label className="form-label">Phone</label>
                            <p>{line.deliveryAddress.phone}</p>
                        </div>
                    )}

                </div>

                <div>
                    <label htmlFor={`type-${line.id}`} className="form-label">Status</label>
                    <select className="form-select" required="" name="type"
                            id={`type-${line.id}`} value={this.state.action} onChange={this.handleChange}>
                        <option value="0">Waiting to be sent</option>
                        <option value="1">Sent</option>
                        <option value="2">Declined</option>
                    </select>
                </div>

            </div>
        );
    }

}


export default SamplesRequestModal;