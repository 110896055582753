import defineComponent from '../../../../components/flight/lib/component';
import DataTable from '../../table/data-table-net/data-table-net.tsx';
import {Breakpoints} from "../../table/data-table-net/StyledDataTableNet.tsx";
import mixam from "../../../boot/mixam";

export default defineComponent(AdminTaxExemptionCertificates);

function AdminTaxExemptionCertificates() {

    this.attributes({
        url: "/admin/api/tax-exemption/list",
        dataTableSelector: '[data-type="data-table"]'
    });

    this.createSchema = function () {
        let cols = [];

        cols.push({
            title: "Customer Name",
            type: "link",
            data: "name"
        });

        cols.push({
            title: "Request Date",
            type: "timebox",
            data: "requestDate",
            defaultSort: true,
            sortOrder: 'desc'
        });

        cols.push({
            title: "Certificate Number",
            type: "text",
            data: "certificateNumber"
        });

        cols.push({
            title: "Organization",
            type: "text",
            data: "organization",
            hide: Breakpoints.LG
        });

        cols.push({
            title: "States",
            type: "text",
            data: "states",
            hide: Breakpoints.LG
        });

        cols.push({
            title: "Issue Date",
            type: "date",
            data: "issueDate",
            hide: Breakpoints.XL
        });

        cols.push({
            title: "Expiry Date",
            type: "date",
            data: "expiryDate",
            hide: Breakpoints.XL
        });

        cols.push({
            title: "Status",
            type: "text",
            data: "status",
            hide: Breakpoints.XXL
        });

        cols.push({
            title: "Internal Notes",
            type: "showMoreText",
            data: "internalNotesText",
            width: '250px',
            hide: Breakpoints.XXL
        });

        cols.push({
            title: "Rejection Reason",
            type: "showMoreText",
            data: "rejectionReasonText",
            width: '250px',
        });

        cols.push({
            title: "Certificate",
            type: "link",
            data: "viewCertificate"
        });

        cols.push({
            title: "Action",
            type: "tax-exemption-action",
            width: "7rem",
            data: "taxExemptionCertificate"
        });

        return cols;
    };

    this.normalize = function (list) {
        return list.map(certificate => this.normalizeCertificate(certificate));
    };

    this.normalizeCertificate = function (certificate) {
        const data = $.extend(true, {}, certificate);
        data.viewCertificate =
        {
            href:  data.certificateFile,
            caption: 'View Certificate',
            target: '_blank',
            className: 'btn btn-sm btn-secondary',
        };
        data.name = {
            href: `/member/${data.memberId}`,
            caption: data.customerName,
            title:  data.customerName
        };
        data.taxExemptionCertificate = data;
        data.rejectionReasonText = {
            lines: 3,
            text: data.rejectionReason ? data.rejectionReason : ''
        };
        data.internalNotesText = {
            lines: 3,
            text: data.internalNotes ? data.internalNotes : ''
        };
        data.taxExemptionCertificate.states = data.taxExemptionCertificate.states?.join(",\n");
        return data;
    };

    this.getData = function () {
        $.getJSON(this.attr.url)
            .done(data => {
                this.data = data;
                this.paint();
                setTimeout(() => this.getData(), 1000 * 60 * 15);
           });
    };

    this.paint = function () {
        const data = {
            columns: this.createSchema(),
            fileName: this.attr.url.split("/").pop(),
            response: this.normalize(this.data)
        };
        if (this.dataReadyFired) {
            window.dispatchEvent(new CustomEvent('uiDataUpdate', { detail: data }));
        } else {
            this.dataReadyFired = true;
            this.trigger("uiDataReady", data);
        }
    };

    this.subscribe = function (onMessage) {
        this.stomp = Stomp.client(mixam.stompServiceUrl);
        this.stomp.debug = (...args) => {
            if (args.join('').indexOf('Lost connection to')  !== -1) {
                setTimeout(() => this.subscribe(onMessage), 10);
            }
        };

        this.stomp.connect(mixam.stompWebUser, mixam.stompWebPass, () => {
            this.stomp.subscribe("/topic/alltaxexemptionchannel", (d) => {
                const p = JSON.parse(d.body);
                onMessage(p);
            });
        }, (err) => this.trigger("log", err), '/');
    };

    this.updateMessageLine = function (data) {
        if (!this.data) {
            return;
        }

        let verb = data.type;
        const message = data.data;

        const index = this.data.map(x => x.id).indexOf(message.id);

        if (verb === "UPDATE" || verb === "INSERT") {
            if (index !== -1) {
                this.data[index] = message;
            } else {
                this.data.unshift(message);
            }
        }

        this.paint();
        requestAnimationFrame(() => $("#PK" + message.id).hilightTableLine());
    };

    this.after('initialize', function () {
        DataTable.attachTo(this.select('dataTableSelector'));
        setTimeout(() => this.getData(), 10);
        this.subscribe(d => this.updateMessageLine(d));
    });
}