var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Form, ListGroup, Row } from 'react-bootstrap';
import { CmsGroupType } from "./types";
import axios from "axios";
var CmsGroupManager = function (props) {
    var _a, _b, _c;
    var _d = useState(), documentOptions = _d[0], setDocumentOptions = _d[1];
    var _e = useState(), cmsGroup = _e[0], setCmsGroup = _e[1];
    var _f = useState(CmsGroupType.CMS_PAGE), cmsGroupType = _f[0], setCmsGroupType = _f[1];
    useEffect(function () {
        if (props.id !== undefined) {
            getCmsGroup(props.id);
        }
        else {
            setCmsGroup(function (prevCmsGroup) { return (__assign(__assign({}, prevCmsGroup), { cmsGroup: {
                    name: '',
                    cmsGroupType: cmsGroupType,
                    tag: ''
                }, cmsGroupItems: [] })); });
            handleGroupTypeChange(cmsGroupType);
        }
    }, []);
    var handleNameChange = function (event) {
        var newName = event.target.value;
        setCmsGroup(function (prevCmsGroup) { return (__assign(__assign({}, prevCmsGroup), { cmsGroup: __assign(__assign({}, prevCmsGroup.cmsGroup), { name: event.target.value }) })); });
    };
    var handleTagChange = function (event) {
        var newName = event.target.value;
        setCmsGroup(function (prevCmsGroup) { return (__assign(__assign({}, prevCmsGroup), { cmsGroup: __assign(__assign({}, prevCmsGroup.cmsGroup), { tag: event.target.value }) })); });
    };
    var handleDocumentChange = function (id, locale) {
        setCmsGroup(function (prevCmsGroup) {
            var filteredItems = prevCmsGroup.cmsGroupItems.filter(function (cmsGroupItem) { return cmsGroupItem.locale !== locale; });
            var newItem = { locale: locale, id: id };
            var updatedItems = id !== undefined ? __spreadArray(__spreadArray([], filteredItems, true), [newItem], false) : __spreadArray([], filteredItems, true);
            return __assign(__assign({}, prevCmsGroup), { cmsGroupItems: updatedItems });
        });
    };
    var handleSaveCmsGroup = function (event) { return __awaiter(void 0, void 0, void 0, function () {
        var response, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!(cmsGroup.cmsGroup.name !== undefined && cmsGroup.cmsGroup.name !== '')) return [3 /*break*/, 4];
                    event.preventDefault();
                    event.stopPropagation();
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, axios.post('/admin/cms/groups', cmsGroup)];
                case 2:
                    response = _a.sent();
                    window.location.href = '/admin/cms/groups/list';
                    return [3 /*break*/, 4];
                case 3:
                    error_1 = _a.sent();
                    console.error('Error saving cms group', error_1);
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var handleGroupTypeChange = function (cmsGroupType) { return __awaiter(void 0, void 0, void 0, function () {
        var response, error_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setCmsGroupType(cmsGroupType);
                    setCmsGroup(function (prevCmsGroup) { return (__assign(__assign({}, prevCmsGroup), { cmsGroup: __assign(__assign({}, prevCmsGroup.cmsGroup), { cmsGroupType: cmsGroupType }) })); });
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, axios.get("/admin/cms/groups/documents/".concat(cmsGroupType))];
                case 2:
                    response = _a.sent();
                    setDocumentOptions(response.data);
                    return [3 /*break*/, 4];
                case 3:
                    error_2 = _a.sent();
                    console.error('Error retrieving cms group documents', error_2);
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var getCmsGroup = function (cmsGroupId) { return __awaiter(void 0, void 0, void 0, function () {
        var response, data, error_3;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, axios.get("/admin/cms/groups/".concat(cmsGroupId))];
                case 1:
                    response = _a.sent();
                    data = response.data;
                    setCmsGroup(data);
                    setCmsGroupType(data.cmsGroup.cmsGroupType);
                    handleGroupTypeChange(data.cmsGroup.cmsGroupType);
                    return [3 /*break*/, 3];
                case 2:
                    error_3 = _a.sent();
                    console.error('Error retrieving cms group', error_3);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    return (React.createElement(Form, { className: "row g-3" },
        React.createElement(Row, { className: "mt-4" },
            React.createElement(Col, { className: "col-md-4" },
                React.createElement(Form.Group, { className: "mb-3", controlId: "groupForm.groupName" },
                    React.createElement(Form.Label, null, "Group Name"),
                    React.createElement(Form.Control, { name: "name", type: "text", value: ((_a = cmsGroup === null || cmsGroup === void 0 ? void 0 : cmsGroup.cmsGroup) === null || _a === void 0 ? void 0 : _a.name) || '', onChange: handleNameChange, required: true }))),
            React.createElement(Col, { className: "col-md-4" },
                React.createElement(Form.Group, { className: "mb-3", controlId: "groupForm.groupType" },
                    React.createElement(Form.Label, null, "Group Type"),
                    React.createElement(Form.Select, { name: "cmsGroupType", onChange: function (event) { handleGroupTypeChange(event.target.value); }, value: cmsGroupType }, Object.entries(CmsGroupType).map(function (_a) {
                        var key = _a[0], value = _a[1];
                        return (React.createElement("option", { key: key, value: value, selected: value === cmsGroupType }, value));
                    })))),
            React.createElement(Col, { className: "col-md-4" },
                React.createElement(Form.Group, { className: "mb-3", controlId: "groupForm.groupTag" },
                    React.createElement(Form.Label, null, "Tag"),
                    React.createElement(Form.Control, { name: "tag", type: "text", value: ((_b = cmsGroup === null || cmsGroup === void 0 ? void 0 : cmsGroup.cmsGroup) === null || _b === void 0 ? void 0 : _b.tag) || '', onChange: handleTagChange, required: true, readOnly: ((_c = cmsGroup === null || cmsGroup === void 0 ? void 0 : cmsGroup.cmsGroup) === null || _c === void 0 ? void 0 : _c.id) != null })))),
        documentOptions && (React.createElement(Row, null, documentOptions.cmsGroupDocumentShops.map(function (groupDocumentShop) { return (React.createElement(React.Fragment, { key: groupDocumentShop.shopName }, groupDocumentShop.locales.map(function (documentLocale) { return (React.createElement(Col, { className: "col-sm-6 col-md-4 col-lg-4 col-xl-2", key: "".concat(groupDocumentShop.shopName, "-").concat(documentLocale.language) },
            React.createElement(Card, { className: "mb-3" },
                React.createElement(Card.Header, null,
                    groupDocumentShop.shopName,
                    ": ",
                    documentLocale.language),
                React.createElement(ListGroup, { className: "list-group-flush" },
                    React.createElement(ListGroup.Item, null,
                        React.createElement(Form.Check, { type: "radio", id: "", name: "".concat(groupDocumentShop.shopId, "-").concat(documentLocale.locale), inline: true, label: "None", checked: !(cmsGroup === null || cmsGroup === void 0 ? void 0 : cmsGroup.cmsGroupItems.some(function (cmsGroupItem) { return cmsGroupItem.locale === documentLocale.locale; })), onChange: function (event) { handleDocumentChange(undefined, documentLocale.locale); } })),
                    documentLocale.documents.map(function (document) { return (React.createElement(ListGroup.Item, { key: document.id },
                        React.createElement(Form.Check, { type: "radio", id: document.id, name: "".concat(groupDocumentShop.shopId, "-").concat(documentLocale.locale), inline: true, label: document.title, checked: cmsGroup === null || cmsGroup === void 0 ? void 0 : cmsGroup.cmsGroupItems.some(function (cmsGroupItem) { return cmsGroupItem.id === document.id; }), onChange: function (event) { handleDocumentChange(document.id, documentLocale.locale); } }))); }))))); }))); }))),
        React.createElement(Row, { className: "justify-content-start" },
            React.createElement(Col, { xs: "auto" },
                React.createElement(Button, { type: "submit", variant: "primary", onClick: function (event) { handleSaveCmsGroup(event); } }, "Save")))));
};
export default CmsGroupManager;
