import defineComponent from '../../components/flight/lib/component';
import Mustache from '../../components/mustache/mustache';
import iframeTemplate from "text!../../appes6/templates/aleyant/edoc-builder-iframe.mustache";

export default defineComponent(EdocBuilder);

function EdocBuilder() {

    this.attributes({
        edocBuilderIframeSelector: '[data-type="edoc-builder-frame"]',
        loadingSelector: '.wait',
        docId: '',
        newSessionUrl: '/api/aleyant/start-session'
    });

    this.attatchLoaderListener = function() {
        this.select('edocBuilderIframeSelector').on('load', this.hideLoader());
    };

    this.hideLoader = function() {
        this.select('loadingSelector').hide();
    };

    this.startNewEdocBuilderSession = function () {

        const data = {
            docId: this.attr.docId
        };

        const promise = $.post(this.attr.newSessionUrl, data, null, "json");
        promise.done(response => {
            this.aleyantSession = response;
            $(this.renderIframe()).appendTo(this.$node);
            this.attatchLoaderListener();
        });
        promise.fail(response => {
            this.trigger("log", {message: `Send error: '${response.responseText}'`, title: "Edoc Builder New Session"});
        });
    };

    this.renderIframe = function () {
        return Mustache.render(iframeTemplate, this.normalizeIframe(this.aleyantSession));
    };

    this.normalizeIframe = function (sessionData) {
        const o = {};
        o.edocBuilderUrl = sessionData.iframeUrl;
        return o;
    };

    this.after('initialize', function () {
        this.attr.docId = this.$node.data('value');
        this.startNewEdocBuilderSession();
    });
}
