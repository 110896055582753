export default WithGoogleAnalytics;

function WithGoogleAnalytics() {

    this.fireAnalyticsConversionEvent = function (action, email, total) {
        var prefix = "";
        if (email.indexOf("@mixam") !== -1 || email.indexOf("effie.nadiv") !== -1) {
            prefix = "self";
        }
        dataLayer.push({
            'event': "quote-order",
            'eventAction': prefix + action,
            'email': email,
            'total': Math.round(total)
        });
    };

}
