export default WithScene;


function WithScene() {
    var compatibilityMode,
        xIndex = 0,
        yIndex = 0;

    this.attributes({
        //selectors
        scenesXSelector: '> section',
        scenesYSelector: '> section.present > section'
    });


    this.whichTransitionEvent = function () {
        var t,
            el = document.createElement('fakeelement'),
            transitions = {
                'transition': 'transitionend',
                'OTransition': 'oTransitionEnd',
                'MSTransition': 'msTransitionEnd',
                'MozTransition': 'transitionend',
                'WebkitTransition': 'webkitTransitionEnd'
            };

        for (t in transitions) {
            if (el.style[t] !== undefined) {
                return transitions[t];
            }
        }
    };


    this.goTo = function (x, y) {
        xIndex = x;
        yIndex = y;
        this.slide();
    };

    this.goLeft = function () {
        xIndex -= 1;
        yIndex = 0;
        xIndex = Math.max(xIndex, 0);
        this.slide();
    };

    this.goRight = function () {
        xIndex += 1;
        yIndex = 0;
        this.slide();
    };

    this.goUp = function () {
        yIndex -= 1;
        yIndex = Math.max(yIndex, 0);
        this.slide();
    };

    this.goDown = function () {
        yIndex += 1;
        this.slide();
    };

    this.slide = function () {
        this.$node.addClass('in-transition');
        xIndex = this.updateScenes('scenesXSelector', xIndex);
        yIndex = this.updateScenes('scenesYSelector', yIndex);
        if (compatibilityMode) {
            this.$node.removeClass('in-transition');
            /*if (options.callback) {
             options.callback.call(options.scope || this);
             }*/
        }
    };


    this.updateScenes = function (selector, index) {
        var // Select all slides and convert the NodeList result to an array
            slides = Array.prototype.slice.call(this.select(selector));

        if (slides.length) {
            // Enforce max and minimum index bounds
            index = Math.max(Math.min(index, slides.length - 1), 0);

            slides[index].setAttribute('class', 'present');

            // Any element previous to index is given the 'past' class
            slides.slice(0, index).map(function (element) {
                element.setAttribute('class', 'past');
            });

            // Any element subsequent to index is given the 'future' class
            slides.slice(index + 1).map(function (element) {
                element.setAttribute('class', 'future');
            });
        } else {
            // Since there are no slides we can't be anywhere beyond the
            // zeroth index
            index = 0;
        }

        return index;
    };


    this.after('initialize', function () {
        const te = this.whichTransitionEvent();


        if (te) {
            this.on(te, event => {
                /* jshint ignore:start */
                if ('SECTION' === event.target.tagName?.toUpperCase()) {
                    if (this.eventTimer) {
                        clearImmediate(this.eventTimer);
                    }

                    this.eventTimer = setImmediate(() => {
                        this.eventTimer = null;
                        this.$node.removeClass('in-transition');
                    });
                }
                /* jshint ignore:end */
            });
        } else {
            compatibilityMode = true;
        }

        this.on(document, 'sceneRequestBack', this.goLeft);
        this.on(document, 'sceneRequestNext', this.goRight);


    });
}