import defineComponent from '../../components/flight/lib/component';

export default defineComponent(HeroCarousel);

function HeroCarousel() {

    this.attributes({
        activeItemSelector: '.item.active',
        thumbnailItemsSelector: '.thumbnails li',
        youtubeVideoModalSelector: '[data-type="video-modal"]',
        youtubeVideoFrameSelector: '[data-type="video-frame"]'
    });

    this.initYoutubePlayer = function() {
        const youtubeIframe = $(this.select('youtubeVideoFrameSelector'));
        this.select('youtubeVideoModalSelector').on('hidden.bs.modal', function (event) {
            youtubeIframe.removeAttr('src');
        });

        this.select('youtubeVideoModalSelector').on('show.bs.modal', function (event) {
            youtubeIframe.attr('src', $(event.relatedTarget).data('src') + '?autoplay=1');
        });
    };

    this.updateThumbnails = function() {
        const $thumbnailItems = this.select('thumbnailItemsSelector');
        $thumbnailItems.removeClass('active');
        const $activeSlide = this.select('activeItemSelector');
        if($thumbnailItems.size() > 0) {
            $thumbnailItems[$activeSlide.index()].classList.add('active');
        }
    };

    this.after('initialize', function () {
        this.on('slid.bs.carousel', this.updateThumbnails);
        this.updateThumbnails();
        this.initYoutubePlayer();
    });

}