import render from './share-hero';
import regional from '../../../../boot/regional';
import defineComponent from '../../../../../components/flight/lib/component';

export default defineComponent(ShareHero);

function ShareHero() {


    this.attributes({
        contentSelector: ".content"
    });

    this.paint = function() {
        render({list: this.getList()}, this.node);
    };

    this.getList = function() {
        const o = [];
        const a = this.data.response.sort((a, b) => b.views - a.views);
        for (let i = 0; i < 8; i++) {
            const share = $.extend(true, {i: i, ribbon: i ? regional().ordinal[i + 1] : regional().admin.mostViewed}, a[i]);
            o.push(share);
        }
        return o;
    };

    this.setData = function(event, data) {
        this.data = data;
        this.paint();
    };

    this.after('initialize', function () {
        this.on(document, 'uiDataUpdate', this.setData);
        this.on(document, 'uiDataReady', this.setData);
    });
}
