import defineComponent from '../../components/flight/lib/component';

export default defineComponent(tabsControls);

/**
 *  This component is attached to Bootstrap's tab instance and adds the following functionality:
 *  1. Update the body element with the current product class name (product-1, product-2 etc.)
 *  2. broadcast santaProductChange event
 *  3. scroll the tab control elements into view
 *  4. push the state into history
 */
function tabsControls() {
    this.attributes({
        autoScroll: true,
        tabsSelector: 'a[data-toggle="tab"]'
    });

    this.notify = function (product, previous, description) {
        $('body').removeClass('product-' + (previous || 1)).addClass('product-' + product);

        // tell everyone that we have changed
        this.trigger('santaProductChange', {currentProduct: product, previousProduct: previous, description: description});
    };

    this.afterTabSelect = function (event) {
        //console.log('afterTabSelect', event)
        var $target = $(event.target),
            href = $target.attr('href').replace(/.*(?=#[^\s]*$)/, ''),
            santaTab = +$target.data('santa-tab'),
            product = +$target.data('product-id'),
            description = $target.data('product-description'),
            previous = +$(event.relatedTarget).data('product-id'),
            allowChangeUrl = $target.data('change-url');

        event.preventDefault();

        if (!isNaN(santaTab) && !isNaN(product)) {
            this.notify(product, previous, description);

            if (this.product !== -1 && this.attr.autoScroll && allowChangeUrl) {
                setImmediate(() => {
                    href = (href.charAt(0) !== '/' ? "/" : "") + href;
                    href = href.substr(href.lastIndexOf("/") + 1);
                    dataLayer.push({event: 'pageview'});
                });
            }
            this.product = product;
        }
    };

    this.beforeTabSelect = function (event) {
        if (this.attr.autoScroll) {
            $('#scene-container').scrollToView();
        }
    };

    this.selectTab = function (event, data) {
        this.silent = data.silent;
        $("#santaTab-" + data.santaTab).tab('show');
        setTimeout(() => {
            const $input = $('.active .editor .copies');
            $input.val($input.val());
        }, 30);
    };

    this.after('initialize', function () {
        this.prefix = this.$node.data('prefix') || '/';
        if (this.$node.data("embed")) {
            this.attr.autoScroll = false;
        }
        this.product = parseInt($('#default-product-id').val() || "");
        this.on('shown.bs.tab', {
            'tabsSelector': this.afterTabSelect
        });
        this.on('click', {
            'tabsSelector': this.beforeTabSelect
        });
        this.on(document, "santaProductChangeRequest", this.selectTab);
    });
}
