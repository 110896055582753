import regional_en from "./regionalEN_GB";
import regional_us from "./regionalEN_US";
import regional_ca from "./regionalEN_CA";
import regional_au from "./regionalEN_AU";
import regional_de from "./regionalDE_DE";
import regionalEN_DE from "./regionalEN_DE";

let currentLocale;

const regional = {
    en: regional_en,
    us: regional_us,
    ca: regional_ca,
    au: regional_au,
    de: regional_de,
    en_de: regionalEN_DE
};

export default function getRegional() {
     if (!currentLocale) {
        const shopLocale = document.getElementById('shopLocale').value;
        switch (shopLocale.toLocaleLowerCase()) {
            case "en_us":
                currentLocale = "us";
                break;
            case "en_ca":
                currentLocale = "ca";
                break;
            case "en_au":
                currentLocale = "au";
                break;
            case "de_de":
                currentLocale = "de";
                break;

            case "en_de":
                currentLocale = "en_de";
                break;
            default:
                currentLocale = shopLocale.substr(0, 2).toLocaleLowerCase();
                break;
        }
    }
    return regional[currentLocale] || regional.en;
}


