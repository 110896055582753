var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useCallback, useEffect, useState, forwardRef, useImperativeHandle } from "react";
import { Builder, Query, Utils as QbUtils } from '@react-awesome-query-builder/bootstrap';
import { DiscountCodeApplicationType } from "../types";
import AwesomeQueryBuilderHelper from "../../AwesomeQueryBuilder/AwesomeQueryBuilderHelper";
import DiscountRuleConfig from "../AwesomeQueryBuilder/DiscountRuleConfig";
var queryValue = { id: QbUtils.uuid(), type: "group" };
export var DiscountRuleEditor = forwardRef(function (props, ref) {
    var _a;
    var _b = useState(undefined), tree = _b[0], setTree = _b[1];
    var _c = useState(((_a = props.discountCode.expression) === null || _a === void 0 ? void 0 : _a.query) ? JSON.parse(props.discountCode.expression.query) : queryValue), initialQuery = _c[0], setInitialQuery = _c[1];
    var _d = useState(undefined), config = _d[0], setConfig = _d[1];
    var _e = useState(undefined), configData = _e[0], setConfigData = _e[1];
    var onChange = useCallback(function (immutableTree, config) {
        var spelExpression = QbUtils.spelFormat(immutableTree, config);
        setTree(immutableTree);
        props.discountCode.expression = {
            spel: spelExpression || '',
            query: JSON.stringify(QbUtils.getTree(immutableTree))
        };
    }, []);
    useEffect(function () {
        initAwesomeQueryBuilder();
    }, []);
    useImperativeHandle(ref, function () { return ({
        changeApplicationType: changeApplicationType
    }); });
    var initAwesomeQueryBuilder = function () { return __awaiter(void 0, void 0, void 0, function () {
        var configData_1, config_1, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, AwesomeQueryBuilderHelper.getConfigData()];
                case 1:
                    configData_1 = _a.sent();
                    if (configData_1) {
                        config_1 = props.discountCode.discountCodeApplicationType === DiscountCodeApplicationType.ORDER ? DiscountRuleConfig.getOrderConfig(configData_1) : DiscountRuleConfig.getOrderItemConfig(configData_1);
                        setConfigData(configData_1);
                        setTree(QbUtils.checkTree(QbUtils.loadTree(initialQuery), config_1));
                        setConfig(config_1);
                    }
                    return [3 /*break*/, 3];
                case 2:
                    error_1 = _a.sent();
                    console.error("Error Initializing Awesome Query Builder: ".concat(error_1));
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var changeApplicationType = function (discountCodeApplicationType) {
        var newConfig;
        switch (discountCodeApplicationType) {
            case DiscountCodeApplicationType.ORDER:
                newConfig = DiscountRuleConfig.getOrderConfig(configData);
                setConfig(newConfig);
                break;
            case DiscountCodeApplicationType.ITEM:
                newConfig = DiscountRuleConfig.getOrderItemConfig(configData);
                setConfig(newConfig);
                break;
        }
        setTree(QbUtils.checkTree(QbUtils.loadTree(queryValue), newConfig));
    };
    var renderBuilder = useCallback(function (props) { return (React.createElement("div", { className: "query-builder-container" },
        React.createElement("div", { className: "query-builder" },
            React.createElement(Builder, __assign({}, props))))); }, []);
    var renderQueryBuilder = function () { return (React.createElement(Query, __assign({}, config, { value: tree, onChange: onChange, renderBuilder: renderBuilder }))); };
    var renderExpression = function () { return (React.createElement("div", { className: "query-builder-result" },
        React.createElement("div", { className: "pt-3" },
            React.createElement("h6", null, "SpEl Expression"),
            React.createElement("pre", { style: { whiteSpace: "pre-wrap", wordBreak: "keep-all" } }, JSON.stringify(QbUtils.spelFormat(tree, config)))))); };
    return (React.createElement(React.Fragment, null, config &&
        React.createElement(React.Fragment, null,
            renderQueryBuilder(),
            renderExpression())));
});
export default DiscountRuleEditor;
