import React from 'react';
export var selectCurrency = function (locale) {
    switch (locale) {
        case 'en-US':
            return {
                code: 'USD',
                symbol: React.createElement("span", { dangerouslySetInnerHTML: { __html: '&dollar;' } })
            };
        case 'en-CA':
            return {
                code: 'CAD',
                symbol: React.createElement("span", { dangerouslySetInnerHTML: { __html: '&dollar;' } })
            };
        case 'en-AU':
            return {
                code: 'AUD',
                symbol: React.createElement("span", { dangerouslySetInnerHTML: { __html: '&dollar;' } })
            };
        case 'en-DE':
        case 'de-DE':
            return {
                code: 'EUR',
                symbol: React.createElement("span", { dangerouslySetInnerHTML: { __html: '&euro;' } })
            };
        default:
            return {
                code: 'GBP',
                symbol: React.createElement("span", { dangerouslySetInnerHTML: { __html: '&pound;' } })
            };
    }
};
export var formatCurrency = function (value, locale) {
    return value.toLocaleString(locale, {
        style: 'currency',
        currency: selectCurrency(locale).code
    });
};
