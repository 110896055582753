import mixam from '../../../../boot/mixam';
import defineComponent from '../../../../../components/flight/lib/component';
import DataTable from '../../../table/data-table-net/data-table-net.tsx';
import {Breakpoints} from "../../../table/data-table-net/StyledDataTableNet.tsx";

export default defineComponent(OdeonList);

const MIN_VIDEO_SECONDS = 90;

function OdeonList() {

    this.attributes({
        url: "/admin/api/odeon/list",
        dataTableSelector: '[data-type="data-table"]'
    });


    this.createSchema = function () {
        const cols = [];

        cols.push({
            title: "Video",
            type: "text",
            data: "videoId",
        });

        cols.push({
            title: "Name",
            type: "link",
            data: "memberRef",
            hide: Breakpoints.LG
        });

        cols.push({
            title: "Start",
            type: "datetime",
            data: "dateCreated",
            hide: Breakpoints.LG
        });

        cols.push({
            title: "End",
            type: "datetime",
            data: "end",
            hide: Breakpoints.LG
        });

        cols.push({
            title: "Duration",
            type: "number",
            data: "duration"
        });

        cols.push({
            title: "Events",
            type: "video-events",
            data: "videoEvents",
            width: "20%"
        });

        cols.push({
            title: "Browser",
            type: "icon",
            data: "browser",
            hide: Breakpoints.XL
        });

        cols.push({
            title: "Category",
            type: "icon",
            data: "category",
            hide: Breakpoints.XL
        });

        cols.push({
            title: "OS",
            type: "icon",
            data: "osicon",
            hide: Breakpoints.XL
        });

        cols.push({
            title: "Error",
            type: "boolean",
            data: "error",
            hide: Breakpoints.LG
        });

        return cols;
    };


    this.paint = function () {
        const data = {
            columns: this.createSchema(),
            fileName: this.attr.url.split("/").pop(),
            response: this.normalize(this.data.list),
            "class": 'table-striped table-order-list',
            "default-sort": {
                "dateCreated": -1
            }
        };

        if (this.dataReadyFired) {
            window.dispatchEvent(new CustomEvent('uiDataUpdate', { detail: data }));
        } else {
            this.dataReadyFired = true;
            this.trigger("uiDataReady", data);
        }
    };

    this.normalize = function (list) {
        return list.map(x => this.normalizeView(x));
    };

    this.normalizeView = function (view) {
        const result = $.extend(true, {}, view);

        result.override = result.override || {};
        result.override.td = result.override.td || {};
        result.error = this.hasError(view.events);
        result.dateCreated = view.start;
        result.duration = this.getDuration(view);
        result.os = view.operatingSystem;

        if (view.name) {
            result.memberRef = {
                href: `/member/${result.memberId}/admin`,
                caption: result.name
            };
        }

        result.videoEvents = {
            start: view.start,
            end: view.end,
            duration: Math.max(MIN_VIDEO_SECONDS, this.getDuration(view)),
            timeline: this.getTimeline(view),
            id: view.id
        };

        result.osicon = {
            title: `${view.operatingSystem} ${view.producer}`,
            icon: `fa-2x ${view.familly}`
        };

        result.category = {
            title: `${view.category}`,
            icon: `fa-2x ${view.category}`
        };

        result.browser = {
            title: `${view.browser}`,
            icon: `fa-2x ${view.browser}`
        };

        return result;
    };

    this.getTimeline = function (view) {
        const duration = Math.max(MIN_VIDEO_SECONDS, this.getDuration(view));

        view.events.sort((a, b) => a.date - b.date);
        const list = [];
        let current;
        view.events.forEach(e => {

            if (e.type === "play") {
                const start = Math.round((e.date - view.start) / 1000 / duration * 100);
                if (current) {
                    current.end = start;
                    list.push(current);
                }
                current = {start, type: e.type};
            }
            if (e.type === "error") {
                const start = Math.min(98, Math.round((e.date - view.start) / 1000 / duration * 100));
                const item = {start: start - 1, end: start + 1, type: 'error'};
                list.push(item);
            }
            if (e.type === "pause" || e.type === "end") {
                const end = Math.round((e.date - view.start) / 1000 / duration * 100);
                if (!current) {
                    current = {start: end, type: 'play'};
                }
                current.end = end;
                list.push(current);
                current = null;
            }
        });
        return list;
    };

    this.getDuration = function (view) {
        return view.end && view.start ? Math.round((view.end - view.start) / 1000) : 0;
    };

    this.hasError = function (events) {
        return events.some(e => e.type === 'error');
    };

    this.getData = function () {
        requestAnimationFrame(() => this.getReportData());
    };

    this.getReportData = function () {
        $.getJSON(this.attr.url)
            .done(data => {
                this.data = data;
                this.paint();
                setTimeout(() => this.getData(), 1000 * 60 * 15);
            })
            .fail((err) => this.trigger("log", {message: err}));
    };


    /**
     * subscribe to STOMP service that notify us about changes
     * in a specific invoice
     */
    this.subscribe = function (onMessage) {
        this.trigger("log", {message: ["Subscribe to stomp channel allodeonchannel"], title: "OdeonList.subscribe"});
        this.stomp = Stomp.client(mixam.stompServiceUrl);

        this.stomp.debug = (...args) => {
            if (args.join('').indexOf('Whoops! Lost connection to') !== -1) {
                setTimeout(() => this.subscribe(onMessage), 10);
            }
            this.trigger("log", {message: args, title: "OdeonList.subscribe"});
        };

        this.stomp.connect(mixam.stompWebUser, mixam.stompWebPass, () => {
            this.stomp.subscribe("/topic/allodeonchannel", (d) => {
                const p = JSON.parse(d.body);
                onMessage(p);
            });
        }, (err) => this.trigger("log", err), '/');
    };

    this.updateViewLine = function (data) {
        if (!this.data) {
            return;
        }

        let verb = data.type;
        const message = data.data;
        if (message.shopId !== mixam.shop.id) {
            return;
        }

        const index = this.data.list.map(x => x.id).indexOf(message.id);

        if (verb === "UPDATE" || verb === "INSERT") {
            if (index !== -1) {
                this.data.list[index] = message;
            } else {
                this.data.list.unshift(message);
            }

        } else if (verb === "DELETE" && index !== -1) {
            this.data.list.splice(index, 1);
        }

        this.paint();
        requestAnimationFrame(() => $("#PK" + message.id).hilightTableLine());
    };
    this.after('initialize', function () {
        DataTable.attachTo(this.select('dataTableSelector'));
        setTimeout(() => this.getData(), 10);
        this.subscribe(d => this.updateViewLine(d));
    });
}
