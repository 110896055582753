import defineComponent from '../../../../components/flight/lib/component';
import DataTable from './../../table/data-table-net/data-table-net.tsx';
import axios from "axios";

export default defineComponent(HomepageSettingsDataTable);

function HomepageSettingsDataTable() {

    this.attributes({
        url: "/admin/api/homepage-settings",
        dataTableSelector: '[data-type="data-table"]'
    });

    this.createSchema = function () {
        const cols = [];
        cols.push({
            title: "Meta Title",
            type: "text",
            data: "metaTitle"
        });
        cols.push({
            title: "Locale",
            type: "text",
            data: "locale"
        });
        cols.push({
            title: "",
            type: "multiButton",
            width: "180px",
            data: "actions"
        });
        return cols;
    };


    this.paint = function () {
        const data = {
            columns: this.createSchema(),
            response: this.normalize(this.data)
        };

        if (this.dataReadyFired) {
            window.dispatchEvent(new CustomEvent('uiDataUpdate', { detail: data }));
        } else {
            this.dataReadyFired = true;
            this.trigger("uiDataReady", data);
        }
    };

    this.normalize = function (list) {
        return list.map(x => this.normalizeSettings(x));
    };

    this.normalizeSettings = function (settings_) {
        const settings = $.extend(true, {}, settings_);

        settings.actions = [
            {
                href: `/admin/homepage/${settings.id}`,
                caption: "Edit",
                className: "btn btn-primary",
                iconClassname: "bi bi-pencil",
                target: "_self"
            }
        ];
        return settings;
    };

    this.getData = function () {
        axios.get(this.attr.url)
            .then(response => {
                this.data = response.data;
                this.paint();
                setTimeout(() => this.getData(), 1000 * 60 * 15);
            }).catch(error => this.trigger("log", {message: error}));
    };

    this.after('initialize', function () {
        DataTable.attachTo(this.select('dataTableSelector'));
        setTimeout(() => this.getData(), 10);
    });
}