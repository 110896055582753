import defineComponent from '../../../../components/flight/lib/component';
import supplierArticles from './supplier-articles';

export default defineComponent(AdminArticlesSummary);

function AdminArticlesSummary() {

    this.setData = function (event, data) {
        supplierArticles(event, data, this.$node[0]);
    };

    this.after('initialize', function () {
        this.on(document, 'articleDataReady', this.setData);
    });
}
