import React, { useEffect, useState, useRef } from 'react';
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { getTableCellValue } from "../util";
import mixam from '../../../../boot/mixam.js';
import * as googleWorksheets from '../../../admin/google-sheets/google-sheets.js';
var DataExporter = function (_a) {
    var data = _a.data, columns = _a.columns;
    var _b = useState(false), loadedGSuiteScripts = _b[0], setLoadedGSuiteScripts = _b[1];
    var modalToggleRef = useRef(null);
    useEffect(function () {
        googleWorksheets.loadScripts().then(function (success) {
            if (!success) {
                handleErrorMsg("Loading Scripts Failed & Returned False");
            }
            setLoadedGSuiteScripts(success);
        }).catch(function (error) {
            setLoadedGSuiteScripts(false);
            handleErrorMsg("Loading Scripts Failed, Reason: " + error.message);
        });
    }, []);
    function formatData(data, columns) {
        return data.map(function (row) {
            return columns.map(function (column) { return getTableCellValue(column, row); });
        });
    }
    function exportToExcel(data, columns) {
        var header = columns.map(function (column) { return column.title; });
        var formattedData = data.map(function (row) { return columns.map(function (column) { return getTableCellValue(column, row); }); });
        formattedData.unshift(header);
        var ws = XLSX.utils.aoa_to_sheet([]);
        formattedData.forEach(function (row, rowIndex) {
            row.forEach(function (cellValue, columnIndex) {
                var cellRef = XLSX.utils.encode_cell({ r: rowIndex, c: columnIndex });
                if (rowIndex === 0) {
                    // Header row: always treat as string
                    ws[cellRef] = { v: cellValue, t: 's' };
                }
                else {
                    // Data rows: format based on column type
                    var cell = void 0;
                    if (columns[columnIndex].type === 'number' && !isNaN(parseFloat(cellValue))) {
                        cell = { v: parseFloat(cellValue), t: 'n' };
                    }
                    else {
                        cell = { v: cellValue, t: 's' };
                    }
                    ws[cellRef] = cell;
                }
            });
        });
        ws['!ref'] = XLSX.utils.encode_range({ s: { r: 0, c: 0 }, e: { r: formattedData.length - 1, c: header.length - 1 } });
        var wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        var excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        var blob = new Blob([excelBuffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8" });
        FileSaver.saveAs(blob, "data.xlsx");
    }
    function exportToCSV(data, columns) {
        var separator = ',';
        var enclosingCharacter = '"';
        var formattedData = formatData(data, columns);
        // Add The Header
        var header = columns.map(function (column) { return column.title; });
        formattedData.unshift(header);
        formattedData = sanitiseData(formattedData);
        // Create The CSV
        var csvData = formattedData
            .map(function (row) { return row
            .map(function (element) { return (typeof element === 'undefined' || element === null) ? '' : element; })
            .map(function (column) { return "".concat(enclosingCharacter).concat(column).concat(enclosingCharacter); })
            .join(separator); }).join("\n");
        var blob = new Blob([csvData], { type: "text/csv" });
        FileSaver.saveAs(blob, "data.csv");
    }
    function exportToGoogleSheets(data, columns) {
        var header = columns.map(function (column) { return column.title; });
        var formattedData = formatData(data, columns);
        formattedData = sanitiseData(formattedData);
        formattedData.unshift(header);
        googleWorksheets.handleGSheetsClick(formattedData, {
            onLoad: function () { return toggleSpinner(true); },
            onSuccess: function (sheetUrl) {
                toggleSpinner(false);
                toggleModal(true, sheetUrl);
            },
            onError: function (errorMessage) { return handleErrorMsg(errorMessage); },
        });
    }
    var _c = useState(false), disabledFlag = _c[0], setDisabledFlag = _c[1];
    function toggleSpinner(onFlag) {
        if (onFlag) {
            setDisabledFlag(true);
            document.querySelector('[datatype="exportDataLinksText"]').classList.replace('d-inline', 'd-none');
            document.querySelector('[datatype="exportDataLinksSpinner"]').classList.replace('d-none', 'd-inline');
        }
        else {
            document.querySelector('[datatype="exportDataLinksText"]').classList.replace('d-none', 'd-inline');
            document.querySelector('[datatype="exportDataLinksSpinner"]').classList.replace('d-inline', 'd-none');
            setDisabledFlag(false);
        }
    }
    function toggleModal(onFlag, sheetUrl) {
        if (onFlag) {
            modalToggleRef.current.click();
            document.querySelector('[datatype="googleSheetsLink"]').setAttribute("href", sheetUrl);
        }
    }
    function handleErrorMsg(message) {
        window.dispatchEvent(new CustomEvent('showFixedMessage', { detail: { message: { type: 'info',
                    body: message,
                    interval: 30000 } } }));
    }
    function sanitiseData(tableData) {
        return tableData.map(function (row) { return row.map(function (cell) {
            if (typeof cell === 'undefined' || cell === null) {
                return '';
            }
            else if (typeof cell === 'number') {
                return "" + cell + "";
            }
            else if (typeof cell === 'object') {
                return "";
            }
            else {
                return cell;
            }
        }); });
    }
    return (
    // @ts-ignore
    !mixam.user.hasRole('ROLE_MIXAM') ? React.createElement("div", null) :
        React.createElement("div", { className: "flex-nowrap align-items-start" },
            React.createElement("div", { className: "btn-group" },
                React.createElement("button", { type: "button", className: "btn btn-secondary dropdown-toggle", datatype: "exportDataLinks", "data-bs-toggle": "dropdown", "data-bs-display": "static", "aria-expanded": "false", id: "exportDataLinks", disabled: disabledFlag },
                    React.createElement("div", { className: "d-inline", datatype: "exportDataLinksText" },
                        React.createElement("i", { className: "bi bi-download" }),
                        "\u00A0Export"),
                    React.createElement("div", { className: "d-none", datatype: "exportDataLinksSpinner" },
                        React.createElement("span", { className: "spinner-border spinner-border-sm", role: "status", "aria-hidden": "true" }),
                        "\u00A0Loading...")),
                React.createElement("ul", { className: "dropdown-menu dropdown-menu-end", "aria-labelledby": "exportDataLinks" },
                    React.createElement("li", null,
                        React.createElement("button", { className: "dropdown-item", type: "button", onClick: function () { return exportToCSV(data, columns); } },
                            React.createElement("i", { className: "bi bi-filetype-csv" }),
                            " CSV")),
                    React.createElement("li", null,
                        React.createElement("button", { className: "dropdown-item", type: "button", onClick: function () { return exportToExcel(data, columns); } },
                            React.createElement("i", { className: "bi bi-file-earmark-excel" }),
                            " Excel")),
                    loadedGSuiteScripts ? (React.createElement("li", null,
                        React.createElement("button", { className: "dropdown-item", type: "button", onClick: function () { return exportToGoogleSheets(data, columns); } },
                            React.createElement("i", { className: "bi bi-google" }),
                            " Google Sheets"))) : null)),
            React.createElement("button", { type: "button", className: "btn btn-primary d-none", datatype: "googleSheetsModalBtn", ref: modalToggleRef, "data-bs-toggle": "modal", "data-bs-target": "#gSheetsModal" }),
            React.createElement("div", { className: "modal fade", datatype: "googleSheetsModal", id: "gSheetsModal", tabIndex: -1, "aria-hidden": "true" },
                React.createElement("div", { className: "modal-dialog" },
                    React.createElement("div", { className: "modal-content" },
                        React.createElement("div", { className: "modal-header" },
                            React.createElement("h1", { className: "modal-title fs-5" }, "Google Sheets"),
                            React.createElement("button", { type: "button", className: "btn-close", "data-bs-dismiss": "modal", "aria-label": "Close" })),
                        React.createElement("div", { className: "modal-body" }, "Your Google Sheets Spreadsheet is ready!"),
                        React.createElement("div", { className: "modal-footer" },
                            React.createElement("button", { type: "button", className: "btn btn-secondary", "data-bs-dismiss": "modal" }, "Close"),
                            React.createElement("a", { type: "button", className: "btn btn-primary", datatype: "googleSheetsLink", href: "", target: "_blank" }, "Open Sheet")))))));
};
export default DataExporter;
