var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useState, useEffect } from "react";
import { Col, Form, Row, Card, Alert } from 'react-bootstrap';
import axios from 'axios';
import styled from "styled-components";
var Pre = styled.pre(templateObject_1 || (templateObject_1 = __makeTemplateObject(["whiteSpace: \"pre-wrap\", wordBreak: \"keep-all\""], ["whiteSpace: \"pre-wrap\", wordBreak: \"keep-all\""])));
export var ParticipationRuleTest = function (_a) {
    var configData = _a.configData, participationPolicy = _a.participationPolicy;
    var _b = useState({
        orderId: undefined,
        supplierId: undefined,
        machineId: undefined,
        shopId: undefined,
        participationPolicy: {
            participationPolicyType: 'GLOBAL'
        }
    }), expressionValidationRequest = _b[0], setExpressionValidationRequest = _b[1];
    var _c = useState(null), expressionValidationResponse = _c[0], setExpressionValidationResponse = _c[1];
    var _d = useState(false), isError = _d[0], setError = _d[1];
    var mapToOptionList = function (configOptions, key) {
        return Object.entries(configOptions[key]).map(function (_a) {
            var value = _a[0], title = _a[1];
            return ({
                value: value,
                title: title,
            });
        });
    };
    var getOptions = function (type) {
        var data = mapToOptionList(configData, type);
        var options = data.map(function (item) { return (React.createElement("option", { value: item.value, key: "".concat(type, "-").concat(item.value) }, item.title)); });
        return options;
    };
    var validateExpression = function () {
        var _a;
        if (((_a = expressionValidationRequest.participationPolicy) === null || _a === void 0 ? void 0 : _a.expression) && expressionValidationRequest.orderId) {
            axios.post('/admin/api/participation-policies/validation', expressionValidationRequest)
                .then(function (response) {
                if (response.data) {
                    setExpressionValidationResponse(response.data);
                    setError(false);
                }
            }).catch(function (error) {
                setExpressionValidationResponse(error.response.data);
                setError(true);
            });
        }
    };
    useEffect(function () {
        validateExpression();
    }, [expressionValidationRequest]);
    useEffect(function () {
        setExpressionValidationRequest(function (request) { return (__assign(__assign({}, request), { participationPolicy: participationPolicy })); });
    }, [participationPolicy]);
    useEffect(function () {
        if (configData) {
            setExpressionValidationRequest(function (request) { return (__assign(__assign({}, request), { supplierId: Object.entries(configData.suppliers)[0][0], machineId: Object.entries(configData.machines)[0][0], shopId: Object.entries(configData.shops)[0][0] })); });
        }
    }, [configData]);
    var handleInputChange = function (field, value) {
        setExpressionValidationRequest(function (request) {
            var _a;
            return (__assign(__assign({}, request), (_a = {}, _a[field] = value, _a)));
        });
    };
    var renderOptGroupSelectOptions = function (options, labelKey, valueKey, itemKey) {
        var selectComponent = (options &&
            Object.entries(options).map(function (_a) {
                var label = _a[0], value = _a[1];
                return (React.createElement("optgroup", { label: label, key: "".concat(itemKey, "-optgroup-").concat(label) }, Object.entries(value).map(function (_a) {
                    var value = _a[0], label = _a[1];
                    return (React.createElement("option", { value: value, key: "".concat(itemKey, "-option-").concat(label, "-").concat(value) }, label));
                })));
            }));
        return selectComponent;
    };
    return (React.createElement(Card, null,
        React.createElement(Card.Header, null, "Rule Test"),
        React.createElement(Card.Body, null,
            React.createElement("p", null, "Use this tool to test if the above rule against an item specification, supplier or machine combination is valid"),
            React.createElement(Form, null,
                React.createElement(Row, { className: "mb-3" },
                    React.createElement(Form.Group, { as: Col, controlId: "orderId" },
                        React.createElement(Form.Label, { className: "h6" }, "Order ID"),
                        React.createElement(Form.Control, { type: "text", placeholder: "Enter Order ID", onChange: function (e) { return handleInputChange('orderId', e.target.value); } })),
                    React.createElement(Form.Group, { as: Col, controlId: "supplierId" },
                        React.createElement(Form.Label, { className: "h6" }, "Supplier"),
                        React.createElement(Form.Select, { onChange: function (e) { return handleInputChange('supplierId', e.target.value); } }, renderOptGroupSelectOptions(configData === null || configData === void 0 ? void 0 : configData.allShopSuppliers, 'supplier', 'supplierId', 'supplier'))),
                    React.createElement(Form.Group, { as: Col, controlId: "machineId" },
                        React.createElement(Form.Label, { className: "h6" }, "Machine"),
                        React.createElement(Form.Select, { onChange: function (e) { return handleInputChange('machineId', e.target.value); } }, renderOptGroupSelectOptions(configData === null || configData === void 0 ? void 0 : configData.allShopMachines, 'machine', 'machineId', 'machine'))),
                    React.createElement(Form.Group, { as: Col, controlId: "shopId" },
                        React.createElement(Form.Label, { className: "h6" }, "Shop"),
                        React.createElement(Form.Select, { onChange: function (e) { return handleInputChange('shopId', e.target.value); } }, getOptions('shops'))))),
            expressionValidationResponse &&
                React.createElement(Row, { className: "mb-3" },
                    React.createElement(Col, null,
                        React.createElement("h6", null, "Test Result"),
                        !isError ?
                            React.createElement(React.Fragment, null,
                                expressionValidationResponse.expressionResult &&
                                    React.createElement(Alert, { variant: 'success' }, "This rule matches the against the test data. Items that match this rule will be excluded from the pricing engine"),
                                !expressionValidationResponse.expressionResult &&
                                    React.createElement(Alert, { variant: 'danger' }, "This rule does not match the test data and items for this specification will be included in the pricing engine"),
                                React.createElement("h6", null, "Canonical Dimensions"),
                                expressionValidationResponse.dimensions &&
                                    React.createElement("p", null,
                                        expressionValidationResponse.dimensions.width.toFixed(3),
                                        " x ",
                                        expressionValidationResponse.dimensions.height.toFixed(3)),
                                React.createElement("h6", null, "Item Specification"),
                                React.createElement(Pre, null, JSON.stringify(expressionValidationResponse.itemSpecification, null, 2)))
                            :
                                React.createElement(React.Fragment, null,
                                    React.createElement(Alert, { variant: 'danger' }, expressionValidationResponse.errorMessage),
                                    React.createElement("h6", null, "Stack Trace"),
                                    React.createElement(Pre, null, expressionValidationResponse.errorMessage)))))));
};
export default ParticipationRuleTest;
var templateObject_1;
