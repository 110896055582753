import ReactDom from  'react-dom';
import React from 'react';
import Fulfillment from  './Fulfillment';
import mixam from '../../boot/mixam';
import Collapse from '../../react-bootstrap/Collapse';
import MorphButton from '../../react-bootstrap/MorphButton';
import createArtworkReady from '../../react-bootstrap/artworkReady';

const rd = ReactDom;


class OrderDescription extends React.Component {
    constructor(...args) {
        super(...args);

        this.state = {};
    }

    handleEditEnter(elem) {
        this.setState({open: !this.state.open});
    }

    handleEditExit(elem) {
        this.setState({open: !this.state.open});
    }

    getItemInformation(item) {
        const query = item.santa;
        const copies = item.query.copies.formatNumber(0);

        let sizeContent = 'Custom size';
        let sizeCaption = "";

        if (!item.customSize) {
            if (query.hardcover) {
                sizeContent = query.formatName;
                sizeCaption = query.formatSize;
            } else {
                sizeContent = query.format;
                sizeCaption = (query.simpleFold ? 'flat ' : '') + 'size';
            }
        }

        const direction = item.isRtl ? (<div className="direction-info">
            <i className="fa fa-caret-left"></i> <i className="fa fa-paragraph"></i>

            <div>Right to Left</div>
        </div>) : null;

        const frontColor = this.getInfocolorContent(query);

        if (query.boundProduct) {
            return (
                <div className="item-summary-box bound">
                    <div>
                        <div className="info-box">
                            <div className="copies-info">
                                {copies}
                                <div>copies</div>
                            </div>
                            <div className="size-info">
                                {sizeContent}
                                <div>{sizeCaption}</div>
                            </div>
                            <div className="binding-info">
                                <i title={query.bind} className={query.bindIcon}></i>

                                <div>{query.bind}</div>
                            </div>
                            <div className="orientation-info">
                                <i title={query.orientation} className={query.orientationIcon}></i>

                                <div>{query.orientation}</div>
                            </div>

                            {direction}

                            <div className="weight-info">
                                {item.response.weight}
                                <div>weight, kg.</div>
                            </div>

                        </div>
                    </div>
                </div>
            );
        }
        return (
            <div className="item-summary-box bound">
                <div>
                    <div className="info-box">
                        <div className="copies-info">
                            {copies}
                            <div>copies</div>
                        </div>
                        <div className="size-info">
                            {sizeContent}
                            <div>{sizeCaption}</div>
                        </div>
                        <div className="paper-info">
                            {query.paper}
                            <div>gsm, paper - {query.paperFinish}</div>
                        </div>
                        <div className="printing-info">
                            {frontColor}
                            <div>
                                front
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    getInfocolorContent(query) {
        if (!query._colors) {
            return (<span></span>);
        }
        if (query._colors.NONE) {
            return (<span title={query.colors}><i className="fa fa-circle-thin"></i></span>);
        }
        if (query._colors.BLACK) {
            return (<span title={query.colors}><i className="fa color-kk fa-circle"></i></span>);
        }
        if (query._colors.COLOR) {
            return (<span title={query.colors}>
                    <i className="fa color-c fa-circle"></i>
                    <i className="fa color-m fa-circle"></i>
                    <i className="fa color-y fa-circle"></i>
                    <i className="fa color-k fa-circle"></i>
                 </span>);
        }
    }

    handleSubmit(event) {
        event.preventDefault();
        const taskId = this.props.line.id;

        const url = `/admin/api/task/${taskId}/journal/add`;
        const value = this.refs.remarkText.value;

        if (value) {
            this.setState({active: true});
            $.post(url, {body: value}, null, 'json').done(response => {
                this.refs.remarkText.value = "";
                this.setState({active: false, open: false});
            });
        }
    }

    render() {
        const order = this.props.data || {};

        const itemCount = (order.contact.name ? order.contact.name + ", " : "") +
            (order.items.length == 1 ? "One item" : `${order.items.length} items`);

        const classStatus = "status-label label-" + order.orderStatus;

        let currency;
        try {
            currency = order.items[0].response.currency;
        } catch (ex) {
            currency = mixam.shop.currency;
        }

        const orderDeliveriesMap = order && order.deliveries ? Object.keys(order.deliveries).reduce((p, c) => {
            const delivery = order.deliveries[c];
            const method = delivery.method;

            if (!p[method]) {
                p[method] = {count: 0, sum: 0, weight: 0};
            }
            p[method].count += (delivery.pallets || delivery.parcels);
            p[method].sum += delivery.sum;
            p[method].weight += delivery.weight;
            return p;
        }, {}) : null;


        const orderDeliveries = orderDeliveriesMap ? Object.keys(orderDeliveriesMap).map((key, i) => {
            const delivery = orderDeliveriesMap[key];
            const title = (key === "0" ? "DPD parcels" : 'pallets') + " " + currency.prefix + delivery.sum.formatNumber(2) + currency.postfix + " " + delivery.weight.formatNumber(2) + 'kg.';
            const caption = key === "0" ? "DPD" : 'pallets';
            const icon = key === "0" ? "mx-dpd" : 'mx-pallet';

            return (<div key={i} className="delivery-item-info" title={title}>
                <i className={icon}></i>
                <span className="badge badge-danger">{delivery.count}</span>

                <div>{caption}</div>
            </div>);
        }) : null;

        const items = order.items.map((item, i) => {
            const style = {
                backgroundImage: `url(${item.front.src})`
            };
            const thumbnailBox = item.front.src && (<a
                    className='thumbnail'
                    title={item.front.title}
                    target='_blank'
                    href={item.front.href}
                    style={style}
                >
                    <div className='aspect-keeper'></div>
                </a>);

            const title = item.alternativeTitle ? item.alternativeTitle : `${item.query.copies} ${item.query.productName}`;
            const body = item.alternativeDescription ? item.alternativeDescription : this.getItemInformation(item);
            const className = 'order-item orient-' + item.front.orientation;
            const fulfillment = item.fulfillment ? (<Fulfillment data={item.fulfillment}/>) : null;
            return (<div key={i} className={className}>
                <div className="title">{title}</div>
                <div className='content'>
                    {thumbnailBox}
                    <div className="info">
                        <div className="body">{body}</div>
                        {fulfillment}
                    </div>
                </div>
            </div>);
        });

        let orderJournal;

        if (order.journalMessages && order.journalMessages.length) {
            orderJournal = order.journalMessages.reverse().map((m, n) => {
                const _date = new Date(m.date).format("dd/mm/yy");
                const _time = new Date(m.date).format("HH:MM");
                const _body = m.body.split(/\r|\n/).map((p, i) => (<p key={i}>{p}</p>));

                const style = {
                    backgroundImage: `url(${m.photo})`
                };

                return (<div key={n}>
                    {m.photo && (<span className="member-icon" style={style}></span>)}
                    <strong>{m.from}</strong>, <span>{_date}</span> <span className="time">{_time}</span>

                    <div className="body">
                        {_body}
                    </div>
                </div>);
            })
        }

        const icons = createArtworkReady(this.props.line.artwork);
        const settledIcon = `fa fa-circle val-${!!this.props.line.settled}`;
        const expressIcon = `fa fa-circle val-${!!this.props.line.isHasExpress}`;

        return (<div className='order-description-box in-table-box'>
            <h3>

                <div>
                    <a className='link'
                       target='_blank'
                       href={order.href}>
                        Order {order.caseNumber}
                    </a>

                    <div className="secondary">
                        {itemCount}
                    </div>
                </div>

                <div className={classStatus}>
                    <span className="cell-content">{order.orderStatus}</span>
                </div>

                <div>
                    {orderDeliveries && (<span className="delivery-info info-box">{orderDeliveries}</span>)}
                </div>
            </h3>
            <div className="order-info-bar">
                <div className="col-artwork type-artwork">{icons}
                    <div className="caption">Artwork</div>
                </div>
                <div className="col-settled"><i className={settledIcon}/>
                    <div className="caption">Settled</div>
                </div>
                <div className="col-isHasExpress"><i className={expressIcon}/>
                    <div className="caption">Express</div>
                </div>
            </div>
            <div className="order-bar">
                <MorphButton in={this.state.open}
                             className="journalButton"
                             title1="Add journal message"
                             title2="Add journal message"
                             onEnter={(node) => this.handleEditEnter(node)}
                             onExit={(node) => this.handleEditExit(node)}
                             icon1ClassName="fa fa-2x fa-commenting"
                             icon2ClassName="fa fa-2x fa-times"
                />
                <Collapse in={this.state.open}>
                    <div>
                        <form onSubmit={ (event) => this.handleSubmit(event) } className="answer-dialog" action=""
                              method="post">
                            <div className="control-size-frame">
                                <textarea className="form-control" ref="remarkText" name="remark" rows="4"
                                          cols="40"></textarea>

                                <div className="control-save-line">
                                    {this.state.active ? (
                                        <button type="submit" className="btn btn-product-4 btn-anime btn-anime-zoomout"
                                                data-loading="*" data-style="zoom-out">
                                            <span className="btn-anime-label">Add</span>
                                            <span className="btn-anime-spinner"><i
                                                className="fa fa-spinner fa-lg fa-spin"></i></span>
                                        </button>) : (
                                        <button type="submit" className="btn btn-product-4 btn-anime btn-anime-zoomout"
                                                data-style="zoom-out">
                                            <span className="btn-anime-label">Add</span>
                                            <span className="btn-anime-spinner"><i
                                                className="fa fa-spinner fa-lg fa-spin"></i></span>
                                        </button>)}

                                </div>
                            </div>
                        </form>
                    </div>
                </Collapse>

            </div>
            {orderJournal && (<div className='order-journal'>{orderJournal}</div>)}

            {items}
        </div>);
    }
}

export default OrderDescription;


