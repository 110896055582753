import WithNormalizeMap from './../with-normalize-map';
import defineComponent from '../../../components/flight/lib/component';
import Mustache from '../../../components/mustache/mustache';
import templateSlidePages from 'text!../../../appes6/templates/preview/preview-slide-pages.mustache';
import WithFolderUrl from '../with-get-folder-url';

export default defineComponent(ShareSlides, WithNormalizeMap, WithFolderUrl);

function ShareSlides() {
    let Reveal;
    const COMPONENTS_URL = "https://d1e8vjamx1ssze.cloudfront.net/components";

    this.attributes({
        pageWidth: 0,
        pageHeight: 0,
        parentContainer: '[data-type="slides-panel"]'
    });

    this.calcHeight = function (width) {
        return width * this.attr.pageHeight / this.attr.pageWidth;
    };

    this.calcDimentions = function (width) {
        const $win = $(window),
            vpw = $win.width(),
            vph = (window.innerHeight || $win.height()) - 70,
            height = Math.min(vph, this.calcHeight(width)),
            aspect = this.attr.pageWidth / this.attr.pageHeight;

        return {
            width: Math.min(height * aspect, vpw),
            height: height
        };
    };

    this.render = function () {
        const {width, height} = this.calcDimentions(this.$node.closest(this.attr.parentContainer).width());

        return Mustache.render(templateSlidePages, {
            "map": this.normalizeMap(this.order, this.item, false),
            "canRemove": true,
            "showMore": null,
            "pwidth": width,
            "pheight": height,
            "isRtl": this.item.isRtl ? 1 : null
        });
    };

    this.setData = function (event, data) {
        this.order = data;
        this.item = data.items[0];
        this.attr.pageWidth = this.item.dimensions.width;
        this.attr.pageHeight = this.item.dimensions.height;
        this.initReveal();
    };

    this.initReveal = function () {
        if (this.order && Reveal) {
            this.$node.html(this.render());
            Reveal.initialize({
                controls: true,
                progress: true,
                history: false,
                center: true,
                scale: 1,
                minScale: 1,
                maxScale: 1,
                transition: 'slide' // none/fade/slide/convex/concave/zoom
            });
        }
    };

    this.after('initialize', function () {
        this.on(document, 'dataShareReady', this.setData);
        // must be an array
        // noinspection JSCheckFunctionSignatures
        require([`${COMPONENTS_URL}/reveal.js/js/reveal.min.js`], reveal => {
            Reveal = reveal;
            this.initReveal();
        });
    });
}
