import mixam from '../../boot/mixam';
import defineComponent from '../../../components/flight/lib/component';

export default defineComponent(DeliveryDateUpdater);

function DeliveryDateUpdater() {

    this.attributes({
        interval: 1000 * 60 * 15,
        deliveryDateDisplaySelector: '[data-type="delivery-date-disp"]'
    });

    this.load = function () {
        var url = mixam.springUrl('/api/working-days'),
            map = {},
            a;

        // unique list of working days
        this.select('deliveryDateDisplaySelector').each((i, x) => {
            const $x = $(x),
                wd = $x.data('days'),
                pid = $x.data('pid'),
                cut = $x.data('cut'),
                mid = $x.data('mid'),
                key = `${wd}|${mid}|${pid}|${cut}`;

            map[key] = map[key] || 0;
            map[key] += 1;
        });
        a = Object.keys(map);
        if (a.length) {
            $.post(url, {days: a.join(",")}, null, "json").
                done(response => this.render(map, response));
        }
    };

    this.render = function (map, times) {
        times.forEach(time => {
            this.select(`[data-type="delivery-date-disp"][data-days="${time.days}"][data-mid="${time.mid}"][data-pid="${time.pid}"]`).
                each((i, elem) => {
                    let $elem = $(elem),
                        date = $elem.data('date');

                    if (date !== time.date) {
                        $elem.data('date', time.date).text(mixam.dateToMediumDateString(time.date)).hilightElement();
                    }
                });
        });
    };

    this.setOrder = function(event, order) {
        this.order = order;
        if (order.orderStatusInt < 10) {
            this.update();
        } else {
            if (this.timer) {
                clearTimeout(this.timer);
            }
            this.timer = null;
        }
    };

    this.update = function () {
        if (this.timer) {
            clearTimeout(this.timer);
        }
        this.load();
        this.timer = setTimeout(() => this.update(), this.attr.interval);
    };

    this.after('initialize', function () {
        this.on(document, "setOrderData", this.setOrder);
       // this.on(document, "uiDeliveryDatesUpdateRequest", this.load);
    });
}
