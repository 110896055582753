import defineComponent from '../../../components/flight/lib/component';
import Mustache from '../../../components/mustache/mustache';
import calendarTemplate from 'text!../../../appes6/templates/admin/shop-calendar/calendar.mustache';
import monthTemplate from 'text!../../../appes6/templates/admin/shop-calendar/month.mustache';
import dayTemplate from 'text!../../../appes6/templates/admin/shop-calendar/day.mustache';
import regional from '../../boot/regional';

export default defineComponent(ShopCalendarPreview);

function ShopCalendarPreview() {

    this.attributes({
        url: '/admin/api/shop-calendar',
        contentSelector: '.content',
        yearSelector: '[data-action="year"]',
    });

    this.spinnerHtml = '<div class="wait"><i class="fa fa-spin fa-spinner"></i></div>';

    this.selectYear = function (event, data) {
        this.select('contentSelector').html(this.spinnerHtml);
        this.paint(data.year);
    };

    this.paint = function (year) {
        this.select('yearSelector').text(year);
        this.select('contentSelector').html(this.renderCalendar(year));
    };

    this.renderCalendar = function (year) {
        // noinspection JSUnusedGlobalSymbols,JSUnusedGlobalSymbols
        return Mustache.render(calendarTemplate, {months: this.buildYear(year)}, {
            monthTemplate,
            dayTemplate
        });
    };

    this.buildYear = function (year) {
        const months = [];
        for (let i = 0; i < 12; i++) {
            const days = [];
            const name =  regional().monthLongNames[i];
            // Obtain a date representing the first day of the month matching the specified number
            const firstDayOfMonth = new Date(year, i, 1);

            const dayOfWeek = firstDayOfMonth.getDay();
            for (let i = 0; i < dayOfWeek; i++) {
                days.push({blank: true});
            }

            const nextDay = new Date(firstDayOfMonth.getTime());
            const currentMonth = firstDayOfMonth.getMonth();

            while (nextDay.getMonth() === currentMonth) {
                const nextDayAsUtc = Date.UTC(nextDay.getFullYear(), nextDay.getMonth(), nextDay.getDate());
                const holiday = this.data[nextDayAsUtc];
                /* jshint ignore:start */
                days.push({
                    title: holiday?.name,
                    cssClasses: holiday ? 'calendar-day calendar-day-holiday' : 'calendar-day',
                    date: nextDay.getDate()
                });
                /* jshint ignore:end */
                nextDay.setDate(nextDay.getDate() + 1);
            }
            months.push({
                name,
                days
            });
        }
        return months;
    };

    this.normalize = function (list) {
        const map = {};
        list.forEach(holiday => {
            const h = this.normalizeHoliday(holiday);
            map[h.utc] = h;
        });
        return map;
    };

    this.normalizeHoliday = function (holiday_) {
        const holiday =  $.extend(true, {}, holiday_);
        // British time can vary between -60 minutes to 0, adding on hour guarantee being on the correct date
        const offset = 60 * 60  * 1000 +  new Date().getTimezoneOffset() * 60 * 1000;
        const d = new Date(holiday_.date + offset);
        holiday.year = d.getFullYear();
        holiday.utc = Date.UTC(d.getFullYear(), d.getMonth(), d.getDate());
        return holiday;
    };

    this.getData = function () {
        $.getJSON(this.attr.url)
            .done(data => {
                this.data = this.normalize(data);
                this.trigger('uiSetYearsList', {data: this.data});

                // paint the calendar component
                this.paint(new Date().getFullYear());
            })
            .fail((err) => this.trigger("log", {message: err}));
    };

    this.after('initialize', function () {
        this.on(document, 'uiSelectYear', this.selectYear);
        setTimeout(() => this.getData(), 10);
    });
}
