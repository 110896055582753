import defineComponent from '../../../../components/flight/lib/component';
import DataTable from './../../table/data-table-net/data-table-net.tsx';
import axios from "axios";
import mixam from "../../../boot/mixam";
import OrderStatus from "../../constants/orderStatus";
import Order from "../../constants/order";

export default defineComponent(PrintboxOrdersDataTable);

function PrintboxOrdersDataTable() {

    this.attributes({
        url: "/api/admin/integrations/printbox/orders",
        dataTableSelector: '[data-type="data-table"]',
        confirmDeleteModal: '[data-type="delete-article-confirmation"]'
    });

    this.createSchema = function () {
        const cols = [];
        cols.push({
            title: "",
            type: "thumbnail",
            data: "front"
        });
        cols.push({
            title: "Order",
            type: "link",
            data: "href"
        });
        cols.push({
            title: "Status",
            type: "badge",
            data: "statusBadge",
        });
        cols.push({
            title: "Artwork",
            type: "boolean",
            data: "artworkReady",
            "sortable": false,
        });
        cols.push({
            title: "Settled",
            type: "boolean",
            data: "settled",
        });
        cols.push({
            title: "# Items",
            type: "number",
            data: "itemCount",
        });
        cols.push({
            title: "Gross",
            type: "number",
            data: "total",
            sum: true,
            currency: true,
            decimal: 2,
            prepend: mixam.shop.currency.prefix
        });
        cols.push({
            title: "Net",
            type: "number",
            data: "nTotal",
            sum: true,
            currency: true,
            decimal: 2,
            prepend: mixam.shop.currency.prefix
        });
        cols.push({
            title: "Printbox User",
            type: "number",
            data: "customerId",
        });
        cols.push({
            title: "Customer Email",
            type: "text",
            data: "contactEmail",
        });
        cols.push({
            title: "Customer Name",
            type: "text",
            data: "contactName",
        });
        cols.push({
            title: "Created",
            type: "date",
            data: "time"
        });
        cols.push({
            title: "Modified",
            type: "date",
            data: "lastModifiedDate",
        });
        return cols;
    };


    this.paint = function () {
        const data = {
            columns: this.createSchema(),
            fileName: this.attr.url.split("/").pop(),
            response: this.normalize(this.data),
            "class": 'table-striped table-order-list',
            "default-sort": {
                "time": -1
            }
        };

        if (this.dataReadyFired) {
            window.dispatchEvent(new CustomEvent('uiDataUpdate', { detail: data }));
        } else {
            this.dataReadyFired = true;
            this.trigger("uiDataReady", data);
        }
    };

    this.normalize = function (list) {
        return list.map(x => this.normalizePrintboxOrder(x));
    };

    this.normalizePrintboxOrder = function (printboxOrder_) {
        const printboxOrder = $.extend(true, {}, printboxOrder_);
        printboxOrder.orderUrl = `/orders/${printboxOrder.orderId}/artwork`;

        printboxOrder.front = {
            src: printboxOrder.thumbnailUrl,
            href: printboxOrder.orderUrl,
            title: printboxOrder.orderId,
            target: "_blank"
        };

        printboxOrder.statusBadge = {
            label: printboxOrder.orderStatus,
            colour: OrderStatus.getBadgeColour(printboxOrder.orderStatusInt)
        };

        if ([Order.ORDER_STATUS_ORDER, Order.ORDER_STATUS_PRINT_ON_DEMAND, Order.ORDER_STATUS_PUBLICATION].includes(printboxOrder.status)) {
            printboxOrder.href = {
                href: printboxOrder.orderUrl,
                caption: printboxOrder.caseNumber,
                title: printboxOrder.orderId,
                target: '_blank'
            };
        } else {
            printboxOrder.href = {
                href: null,
                caption: printboxOrder.caseNumber,
                title: printboxOrder.orderId
            };
        }

        return printboxOrder;
    };

    this.getData = function () {
        axios.get(this.attr.url)
            .then(response => {
                this.data = response.data;
                this.paint();
                setTimeout(() => this.getData(), 1000 * 60 * 15);
            }).catch(error => this.trigger("log", {message: error}));
    };

    this.after('initialize', function () {
        DataTable.attachTo(this.select('dataTableSelector'));
        setTimeout(() => this.getData(), 10);
    });
}