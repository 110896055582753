import defineComponent from '../../components/flight/lib/component';

export default defineComponent(ScrollListener);

let supportsPassive = false;
try {
    const opts = Object.defineProperty({}, 'passive', {
        get: function() {
            supportsPassive = true;
        }
    });
    window.addEventListener("test", null, opts);
} catch (e) {}

function addEventListenerWithOptions(target, type, handler, options) {
    let optionsOrCapture = options;
    if (true || !supportsPassive) {
        optionsOrCapture = options.capture;
    }
    target.addEventListener(type, handler, optionsOrCapture);
}

function ScrollListener() {

    this.scroll = function () {
        if (this.scrollTimer) {
            clearTimeout(this.scrollTimer);
        }
        this.scrollTimer = setTimeout(() => {
            this.scrollTimer = null;
            let offset = document.documentElement.scrollTop || document.body.scrollTop;
            this.trigger("uiPageScroll", {offset});
        }, 0);
    };


    this.after('initialize', function () {
        addEventListenerWithOptions(window, "scroll", this.scroll.bind(this), {passive:true});
        //this.on(window, "scroll", this.scroll);
    });
}


