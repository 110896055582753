import defineComponent from '../../../components/flight/lib/component';

export default defineComponent(UploadInvoiceManager);

function UploadInvoiceManager() {


    this.attributes({
        uploadBtnSelector: '[data-type="upload-invoice-btn"]',
        fileSelector: 'input[type="file"]',
        formSelector: 'form'
    });

    this.uploadClick = function(event) {
        event.stopPropagation();
        event.preventDefault();
        this.select('fileSelector')[0].click();
    };

    this.doUpload = function() {
        this.select('uploadBtnSelector').attr('data-loading', '*').attr('disabled', true);
        const formData = new FormData(this.node);
        $.ajax({
            url: "/admin/api/fulfilment/invoice/add",
            type: "POST",
            data: formData,
            processData: false,
            contentType: false,
            success: (response) => {
                console.log(response);
                this.select('uploadBtnSelector').removeAttr('data-loading').attr('disabled', false);
                this.select('fileSelector').val(null);
            },
            error: (jqXHR, textStatus, errorMessage) => {
                console.error(errorMessage); // Optional
                this.select('uploadBtnSelector').removeAttr('data-loading').attr('disabled', false);
                this.select('fileSelector').val(null);
            }
        });

    };

    this.after('initialize', function () {
        this.on('click', {
            uploadBtnSelector: this.uploadClick
        });
       this.on('change', {
           fileSelector: this.doUpload
        });
    });
}
