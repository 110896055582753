export default {
    NONE: 0,
    INITIAL: 10,
    RESOLVING: 20,
    CLOSE: 60,
    CANCELED: 94,

    getBadgeColour: function (disputeStatus) {
        switch (disputeStatus) {
            case this.NONE:
            case this.INITIAL:
                return 'info';
            case this.RESOLVING:
                return 'danger';
            case this.CLOSE:
            case this.CANCELED:
                return 'secondary';
            default:
                return 'secondary';
        }
    },

    fromValue: function (statusValue) {
        let keyIndex = Object.values(this).indexOf(statusValue);
        return Object.keys(this)[keyIndex];
    }
};
