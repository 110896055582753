var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useState } from 'react';
import { Button, Table, Form as BsForm, Toast, Alert } from 'react-bootstrap';
import { FieldArray, Form, Formik } from 'formik';
import axios from 'axios';
import Modal from 'react-bootstrap/Modal';
import ConfirmationModal from './ConfirmationModal';
export function SheetsAndSizes(_a) {
    var _this = this;
    var configuration = _a.configuration, doGetConfiguration = _a.doGetConfiguration;
    var _b = useState(false), showModal = _b[0], setShowModal = _b[1];
    var _c = useState(0), height = _c[0], setHeight = _c[1];
    var _d = useState(0), width = _d[0], setWidth = _d[1];
    var _e = useState(false), showDeleteModal = _e[0], setShowDeleteModal = _e[1];
    var _f = useState(), pressSheet = _f[0], setPressSheet = _f[1];
    var _g = useState(false), showDuplicateIsDetectedToast = _g[0], setShowDuplicateIsDetectedToast = _g[1];
    var handleClose = function () {
        setShowModal(false);
    };
    var handleShow = function () { return setShowModal(true); };
    var handleSubmitHeightAndWidth = function () { return __awaiter(_this, void 0, void 0, function () {
        var res, e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, axios.post("/api/admin/tco/configurations/".concat(configuration.machineName, "/press-sheets"), { height: Number(height), width: Number(width) })];
                case 1:
                    res = _a.sent();
                    return [2 /*return*/, res.data];
                case 2:
                    e_1 = _a.sent();
                    console.log('error submitting');
                    throw e_1;
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var handleDeleteRowUpdate = function (pressSheet) { return __awaiter(_this, void 0, void 0, function () {
        var e_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, axios.delete("/api/admin/tco/configurations/".concat(configuration.machineName, "/press-sheets/").concat(pressSheet.id))];
                case 1:
                    _a.sent();
                    return [3 /*break*/, 3];
                case 2:
                    e_2 = _a.sent();
                    console.log('delete failed');
                    throw e_2;
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var handleDeleteRow = function (pressSheet) { return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, handleDeleteRowUpdate(pressSheet)];
                case 1:
                    _a.sent();
                    setShowDeleteModal(false);
                    return [4 /*yield*/, doGetConfiguration()];
                case 2:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    var handleSave = function () { return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, handleSubmitHeightAndWidth()];
                case 1:
                    _a.sent();
                    return [4 /*yield*/, doGetConfiguration()];
                case 2:
                    _a.sent();
                    setShowModal(false);
                    return [2 /*return*/];
            }
        });
    }); };
    var handleSubmit = function (event) {
        event.preventDefault();
        var form = event.currentTarget;
        if (form.checkValidity()) {
            var existingWidthsAndHeights = configuration.pressSheets.map(function (sheet) { return ({ height: sheet.height, width: sheet.width }); });
            var hasDuplicate = existingWidthsAndHeights
                .some(function (existingPair) { return height === existingPair.height && width === existingPair.width; });
            if (hasDuplicate) {
                setShowDuplicateIsDetectedToast(true);
                return;
            }
            // event.stopPropagation();
            else {
                handleSave();
            }
        }
    };
    var getInitialValuesSheetSizes = function () {
        return (configuration ? {
            pressSheets: configuration.pressSheets.map(function (sheet) {
                return ({ height: sheet.height, width: sheet.width, id: sheet.id });
            })
        }
            : { pressSheets: [] });
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(ConfirmationModal, { show: showDeleteModal, handleClose: function () { return setShowDeleteModal(false); }, handleSubmit: function () { return pressSheet && handleDeleteRow(pressSheet); }, closeButtonText: "Close", content: "Are you sure you want to delete this sheet size? All linked item sizes & substrates will also be deleted.", saveButtonText: "Delete", heading: "Delete Sheet" }),
        React.createElement("div", { className: "d-flex flex-column gap-4" },
            React.createElement("div", { className: "d-flex flex-column" },
                React.createElement("h4", null, "Sheet Sizes"),
                React.createElement(Formik, { enableReinitialize: true, initialValues: getInitialValuesSheetSizes(), onSubmit: function () { return console.error("UNUSED"); } }, function (_a) {
                    var values = _a.values;
                    return (React.createElement(Form, null,
                        React.createElement(FieldArray, { name: "pressSheets", render: function () { return (React.createElement("div", { className: "d-flex flex-column w-50" },
                                React.createElement(Table, { striped: true, bordered: true, hover: true },
                                    React.createElement("thead", null,
                                        React.createElement("tr", null,
                                            React.createElement("th", null, "Width"),
                                            React.createElement("th", null, "Height"),
                                            React.createElement("th", null))),
                                    React.createElement("tbody", null, values.pressSheets.map(function (pressSheet) { return (React.createElement("tr", { key: pressSheet.id },
                                        React.createElement("td", { style: { verticalAlign: 'middle' } },
                                            React.createElement("div", { className: "p-1" }, pressSheet.width)),
                                        React.createElement("td", { style: { verticalAlign: 'middle' } },
                                            React.createElement("div", { className: "p-1" }, pressSheet.height)),
                                        React.createElement("td", null,
                                            React.createElement("div", { className: "d-flex justify-content-end" },
                                                React.createElement(Button, { variant: "danger", type: "button", onClick: function () { pressSheet && setPressSheet(pressSheet); setShowDeleteModal(true); } },
                                                    React.createElement("i", { className: "bi bi-trash" })))))); }))),
                                React.createElement("div", { className: "d-flex flex-row gap-2 justify-content-end" },
                                    React.createElement(Button, { className: "align-self-end", type: "button", onClick: handleShow }, "+ Add Sheet Size")))); } })));
                }),
                showModal &&
                    React.createElement(Modal, { show: showModal, onHide: handleClose },
                        React.createElement(BsForm, { onSubmit: handleSubmit },
                            React.createElement(Modal.Header, { closeButton: true },
                                React.createElement(Modal.Title, null, "Add Sheet")),
                            React.createElement(Modal.Body, null,
                                React.createElement(Table, null,
                                    React.createElement("thead", null,
                                        React.createElement("tr", null,
                                            React.createElement("th", null, "Width"),
                                            React.createElement("th", null, "Height"))),
                                    React.createElement("tbody", null,
                                        React.createElement("tr", null,
                                            React.createElement("td", null,
                                                React.createElement(BsForm.Control, { type: "number", step: "0.01", onChange: function (e) { return setWidth(Number(e.target.value)); }, required: true })),
                                            React.createElement("td", null,
                                                React.createElement(BsForm.Control, { type: "number", step: "0.01", onChange: function (e) { return setHeight(Number(e.target.value)); }, required: true }))))),
                                React.createElement("div", { className: "d-flex w-100 justify-content-center" },
                                    React.createElement(Toast, { onClose: function () { return setShowDuplicateIsDetectedToast(false); }, show: showDuplicateIsDetectedToast, className: "w-100", autohide: true, delay: 3000 },
                                        React.createElement(Alert, { variant: "primary", className: "mb-0" },
                                            React.createElement("p", null, "This entry already exists, please create a unique entry and try again."))))),
                            React.createElement(Modal.Footer, null,
                                React.createElement(Button, { variant: "secondary", onClick: handleClose }, "Close"),
                                React.createElement(Button, { variant: "primary", type: "submit" }, "Save"))))))));
}
