import React from 'react';

const dateTemplate = "dd/mm/yy";
const timeTemplate = "h:MM TT";

class Fulfillment extends React.Component {
    constructor(props, context) {
        super(props, context);
    }

    render() {
        const {machine, by, date, isExpress, remark, supplierName} = this.props.data;
        const deliveryDate = date && (new Date(date)).format(dateTemplate);
        const remarkBody = remark && remark.split(/\n|\r/).map((t, i) => {
            return (<p key={i}>{t}</p>);
        });
        return (
            <div className="fulfillment-box">
                Send to production by {supplierName} - {machine} on {deliveryDate} by {by}.
                {isExpress && (<span className="lebel-express">priority</span>)}
                {remark && (<div className="remark">{remarkBody}</div>)}
            </div>
        );
    }
}


export default Fulfillment;