import React from 'react';

class VideoEvents extends React.Component {
    constructor(props, context) {
        super(props, context);
     }

    render() {
        const data = this.props.data || {};
        let box = null;

        if (data) {
            box = data.timeline.map(item => {
                const style = {
                    left: `${item.start}%`,
                    right: `${100 -item.end}%`,
                };
                const className = `view-type-${item.type}`;
                return (<div
                    title={item.type}
                    className={className}
                    style={style}
                    key={item.start}></div>);
            });
        }

        return (
            <div>
                <div className="video-events">
                    {box}
                </div>
            </div>
        );
    }
}

export default VideoEvents;
