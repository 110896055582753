import defineComponent from '../../../components/flight/lib/component';

export default defineComponent(PromotionManager);

function PromotionManager() {

    this.attributes({
        flipbookSelector     : '.flipbook'
    });

    this.gotoPage = function(page) {
        requestAnimationFrame(() => {
            try {
                this.select('flipbookSelector').turn('page', page);
            } catch(ex) {
                //ilb
            }
        });
    };

    this.setMessage = function(event, data) {
        var a,
            cmd = event.originalEvent.data;

        if (cmd && (typeof cmd === 'string') && cmd.indexOf("mpc") !== -1) {
            a = cmd.split(" ");
            if ("gotopage" === a[1]) {
                this.gotoPage(+a[2]);
            }
        }
    };

    this.after('initialize', function () {
        this.on(window, "message", this.setMessage);
    });
}
