import mixam from '../../../boot/mixam';

// NOTE: this import looks unused, but it's very much needed!
import Notify from '../../../../components/notify.js/notify';
import regional from "../../../boot/regional";

export default function WithPayments() {

    this.shops = {
        "56b3c7235e1cc70ad83d3e0e": {
            title: "Mixam",
            icon: "mx-mixam-logo-smart"
        },
        "5a45088e697df11c10709c21": {
            title: "Mixam.com",
            icon: "mx-mixam-logo-smart"
        },
        "572c7409b3f30a0752a5f9a6": {
            title: "Micropress",
            icon: "mx-micropress"
        },
        "5f98203cd46d75224b02f41c": {
            title: "PrintMX",
            icon: "mx-printmx-logo"
        },
    };

    this.normalize = function (list) {
        return list.map(x => this.normalizePayment(x));
    };

    this.normalizePayment = function (pay, index) {
        const payment = $.extend(true, {}, pay);
        payment.override = payment.override || {};
        payment.override.td = payment.override.td || {};
        payment.override.td.status = `type-${payment.transactionType} method-${payment.paymentMethod}`;
        payment.name = `${payment.billingFirstnames} ${payment.billingSurname}`;

        const shopId = payment.shopId || '56b3c7235e1cc70ad83d3e0e';
        payment.shop = this.shops[shopId] && {
            icon: this.shops[shopId].icon,
            caption: this.shops[shopId].title
        };

        let methodId = typeof payment.paymentMethod === "number" ? payment.paymentMethod : 1;
        payment.paymentMethod = regional().dictionary.paymentMethod[methodId];

        payment.amount = payment.amount || payment.capturedAmount;

        if (payment.orderId) {
            payment.order = {
                href: `/orders/${payment.orderId}/artwork`,
                caption: payment.orderCaseNumber || 'Order',
                title: payment.orderId
            };
        }

        if (payment.thumbnail) {
            payment.front = {
                src: payment.thumbnail,
                href: `/orders/${payment.orderId}/artwork`
            };
            payment.override = payment.override || {};
            payment.override.td = payment.override.td || {};
            payment.override.td.front = "orient-" + payment.thumbnailOrientation;
        }

        return payment;
    };

    this.getData = function () {
        $.getJSON(this.attr.url)
            .done(data => {
                this.data = data;
                this.paint();
                setTimeout(() => this.getData(), 1000 * 60 * 15);
            })
            .fail((err) => this.trigger("log", {message: err}));
    };

    this.paint = function () {
        const data = {
            columns: this.createSchema(),
            fileName: this.attr.url.split("/").pop(),
            response: this.normalize(this.data.list),
            "class": 'table-striped table-order-list',
            "default-sort": {
                "date": -1
            }
        };

        if (this.dataReadyFired) {
            window.dispatchEvent(new CustomEvent('uiDataUpdate', { detail: data }));
        } else {
            this.dataReadyFired = true;
            this.trigger("uiDataReady", data);
        }
    };

    /**
     * subscribe to STOMP service that notify us about changes
     * in a specific order and a specific item
     */
    this.subscribe = function (onMessage) {
        this.trigger("log", {message: ["Subscribe to stomp channel shoppaymentmessages"], title: "WithPayments.subscribe"});
        this.stomp = Stomp.client(mixam.stompServiceUrl);

        this.stomp.debug = (...args) => {
            if (args.join('').indexOf('Whoops! Lost connection to') !== -1) {
                setTimeout(() => this.subscribe(onMessage), 10);
            }
            this.trigger("log", {message: args, title: "WithPayments.subscribe"});
        };

        this.stomp.connect(mixam.stompWebUser, mixam.stompWebPass, () => {
            this.stomp.subscribe("/topic/shoppaymentmessages", d => {
                const p = JSON.parse(d.body);
                if (onMessage) {
                    onMessage(p);
                }
            });

        }, (err) => {
            //console.log('error', err);
        }, '/');

    };

}
