import defineComponent from '../../../components/flight/lib/component';
import React from 'react';
import ReactDom from 'react-dom/client';
import ImageUpload from '../admin/React/ImageUpload/ImageUpload.tsx';

export default defineComponent(AdminImageUpload);

function AdminImageUpload() {

    this.initImageUpload = function () {

        // Resize Method Can Be 'SCALE', 'FIT', 'COVER', 'THUMB'. The Resize Operation Will Be Performed By TinyPNG
        // https://tinypng.com/developers/reference/java

        const imageUploader = this;

        const imageUploadContainer = $(this.node);
        const uploadFolder = imageUploadContainer.data('upload-folder');
        const imageWidth = imageUploadContainer.data('image-width');
        const imageHeight = imageUploadContainer.data('image-height');
        const resizeMethod = imageUploadContainer.data('resize-method');
        const imagePreview = imageUploadContainer.data('image-preview');
        const produceWebP = imageUploadContainer.data('produce-webp');

        const root = ReactDom.createRoot(imageUploadContainer.get(0));
        root.render(
            <ImageUpload
                uploadFolder={uploadFolder}
                imageWidth={imageWidth}
                imageHeight={imageHeight}
                resizeMethod={resizeMethod}
                imagePreview={imagePreview}
                produceWebp={produceWebP}
                uploadSuccessCallback={(data) => {
                    imageUploader.trigger('imageUploadSuccess', data);
                }}
                deleteCallback={(data) => {
                    imageUploader.trigger('imageDeleteSuccess', data);
                }}
            ></ImageUpload>
        );
    };

    this.after('initialize', function () {
        this.initImageUpload();
    });

}
