import defineComponent from '../../../components/flight/lib/component';
import WithScene from './../with-scene';

export default defineComponent(Review, WithScene);

function Review() {


    this.attributes({
        delay: 0
    });

    this.next = function() {
        this.current += 1;
        if (this.current > 4) {
            this.current = 0;
        }
        setTimeout(() => this.goTo(0, this.current), this.attr.delay);
    };

    this.after('initialize', function () {
        this.current = 0;
        this.on(document, "reviewNext", this.next);
    });
}
