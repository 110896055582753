import React, { useState } from 'react';
import { Button, Card } from 'react-bootstrap';
import TooltipContent from './TooltipContent';
import axios from "axios";
var primaryOrAny = 'PRIMARY / ANY';
var componentTypes = [
    primaryOrAny,
    'COVER',
    'END_PAPERS',
    'DUST_JACKET',
    'ENVELOPE'
];
var TooltipEditor = function (_a) {
    var index = _a.index, tooltip = _a.tooltip, mode = _a.mode, allTooltipTypes = _a.allTooltipTypes, handleDeleteProduct = _a.handleDeleteProduct;
    var _b = useState(!tooltip || !tooltip.id), isEdit = _b[0], setIsEdit = _b[1];
    var _c = useState(tooltip.componentType), componentType = _c[0], setComponentType = _c[1];
    var _d = useState(tooltip.tooltipType), tooltipType = _d[0], setTooltipType = _d[1];
    var _e = useState(tooltip.content), tooltipContent = _e[0], setTooltipContent = _e[1];
    var handleSave = function () {
        if (tooltipContent.length < 1) {
            alert("Please enter text");
            return;
        }
        if (componentType === primaryOrAny) {
            delete tooltip['componentType'];
        }
        else {
            tooltip.componentType = componentType;
        }
        tooltip.tooltipType = tooltipType;
        tooltip.content = tooltipContent;
        if (tooltip.id) {
            axios.put("/admin/shop/tooltips", tooltip).then(function () {
                setIsEdit(false);
            }).catch(function (error) {
                alert("Error Saving Tooltip: ".concat(error.response.data.errorMessage));
            });
        }
        else {
            axios.post("/admin/shop/tooltips", tooltip).then(function (_a) {
                var tooltipResponse = _a.data;
                tooltip.id = tooltipResponse.tooltip.id;
                setIsEdit(false);
            }).catch(function (error) {
                alert("Error Saving Tooltip: ".concat(error.response.data.errorMessage));
            });
        }
    };
    var handleDeleteShop = function () {
        axios.delete("/admin/shop/tooltips/".concat(tooltip.id)).then(function (_a) {
            setTooltipContent('');
            setIsEdit(true);
        });
    };
    return (React.createElement(Card, { className: "mt-3" }, !isEdit ?
        React.createElement("div", null,
            React.createElement(Card.Body, null,
                React.createElement(Card.Title, null,
                    React.createElement("div", { className: "mb-2" },
                        "Component: ",
                        React.createElement("span", { className: "text-primary" }, tooltip.componentType ? tooltip.componentType : primaryOrAny)),
                    React.createElement("div", null,
                        "Type: ",
                        React.createElement("span", { className: "text-primary" }, tooltip.tooltipType))),
                React.createElement(Card.Text, { dangerouslySetInnerHTML: { __html: tooltipContent } })),
            React.createElement(Card.Footer, null,
                React.createElement(Button, { variant: "primary", className: "me-2", onClick: function () { return setIsEdit(true); } }, "Edit"),
                React.createElement(Button, { hidden: mode === 'product', variant: "danger", className: "me-2", onClick: handleDeleteShop }, "Delete"),
                React.createElement(Button, { hidden: mode === 'shop', variant: "danger", onClick: function () { return handleDeleteProduct(tooltip); } }, "Delete")))
        : React.createElement("div", null,
            React.createElement(Card.Body, null,
                React.createElement("div", { hidden: mode === 'product', className: "mb-3" },
                    React.createElement(Card.Title, null, tooltipType)),
                React.createElement("div", { hidden: mode === 'shop', className: "d-flex mb-3" },
                    React.createElement("div", { className: "flex-grow-1 me-2" },
                        React.createElement("label", { htmlFor: "componentType".concat(index), className: "form-label" }, "Component Type:"),
                        React.createElement("select", { id: "componentType".concat(index), onChange: function (e) {
                                setComponentType(e.target.value);
                            }, className: "form-control form-select", defaultValue: componentType }, componentTypes.map(function (option) { return React.createElement("option", { key: option, value: option }, option); }))),
                    React.createElement("div", { className: "flex-grow-1 ms-2" },
                        React.createElement("label", { htmlFor: "tooltipType-".concat(index), className: "form-label" }, "Option:"),
                        React.createElement("select", { id: "tooltipType-".concat(index), onChange: function (e) {
                                setTooltipType(e.target.value);
                            }, className: "form-control form-select", defaultValue: tooltipType }, allTooltipTypes.map(function (option) { return React.createElement("option", { key: option, value: option }, option); })))),
                React.createElement(TooltipContent, { index: index, data: tooltipContent, handleChange: setTooltipContent })),
            React.createElement(Card.Footer, null,
                React.createElement(Button, { onClick: handleSave, className: "me-2" }, "Save"),
                React.createElement(Button, { variant: "default", onClick: function () { return setIsEdit(false); } }, "Cancel")))));
};
export default TooltipEditor;
