var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import ImageUpload from "../../ImageUpload/ImageUpload";
import Table from 'react-bootstrap/Table';
import { Image, Button, Row, Col, Alert, Form } from "react-bootstrap";
import { Formik, FieldArray, Field, ErrorMessage } from 'formik';
import { FormControl } from "../../../../react/self-publishing/PublicationSettings/components/styled";
import * as Yup from 'yup';
import styled from "styled-components";
var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    .error {\n        color: red;\n    }\n    .success {\n        color: green;\n    }\n"], ["\n    .error {\n        color: red;\n    }\n    .success {\n        color: green;\n    }\n"])));
var isEqual = function (obj1, obj2) { return (obj1.title === obj2.title &&
    obj1.thumbnailUrl === obj2.thumbnailUrl &&
    obj1.position === obj2.position); };
var compareListsAndReturnIndexes = function (list1, list2) {
    var differentIndexes = [];
    for (var i = 0; i < list2.length; i++) {
        var obj1 = list1[i];
        var obj2 = list2[i];
        if (!obj1 || !obj2 || !isEqual(obj1, obj2)) {
            differentIndexes.push(i);
        }
    }
    return differentIndexes;
};
var PriceCalculatorManager = function (props) {
    var homepageSettingsUrl = "/admin/api/homepage-settings";
    var _a = useState(), homepageSettings = _a[0], setHomepageSettings = _a[1];
    var _b = useState(), availablePages = _b[0], setAvailablePages = _b[1];
    var _c = useState('idle'), savingProgress = _c[0], setSavingProgress = _c[1];
    var getHomepageSettings = function () {
        axios.get("".concat(homepageSettingsUrl, "/").concat(props.homepageSettingsId))
            .then(function (response) {
            setHomepageSettings(response.data);
        }).catch(function (error) {
            alert("Error Retrieving Homepage Settings Data: ".concat(error));
        });
    };
    var getAvailableProductPages = function () {
        axios.get("".concat(homepageSettingsUrl, "/").concat(props.homepageSettingsId, "/pages"))
            .then(function (response) {
            setAvailablePages(response.data);
        }).catch(function (error) {
            alert("Error Retrieving Homepage Settings Data: ".concat(error));
        });
    };
    useEffect(function () {
        getHomepageSettings();
        getAvailableProductPages();
    }, []);
    var uploadSuccessCallback = function (object, formikSetValues, formikValues) {
        var initialSantapageMetadata = availablePages[0];
        var newPriceCalculator = {
            thumbnailUrl: object['url'],
            title: initialSantapageMetadata.title,
            position: homepageSettings.priceCalculatorMetadata.length * 10,
            santaPageMetadataId: initialSantapageMetadata.id,
            unSaved: true,
            imageMetadata: initialSantapageMetadata.images[0],
            requestUri: initialSantapageMetadata.requestUri
        };
        formikSetValues({ priceCalculators: __spreadArray(__spreadArray([], formikValues.priceCalculators, true), [newPriceCalculator], false) });
        setHomepageSettings(function (prevSettings) {
            return __assign(__assign({}, prevSettings), { priceCalculatorMetadata: __spreadArray(__spreadArray([], prevSettings.priceCalculatorMetadata, true), [newPriceCalculator], false) });
        });
        return [];
    };
    return (React.createElement(Wrapper, null, (homepageSettings && availablePages) ?
        React.createElement(React.Fragment, null,
            React.createElement("h4", null, "Price Calculators"),
            React.createElement("div", null,
                React.createElement("small", null,
                    React.createElement("i", null, "Yellow rows contain unsaved changes"))),
            React.createElement(Formik, { initialValues: {
                    priceCalculators: homepageSettings.priceCalculatorMetadata.map(function (calculator) { return (__assign(__assign({}, calculator), { santaPageMetadataId: calculator.santaPageMetadataId })); })
                }, validationSchema: Yup.object().shape({
                    priceCalculators: Yup.array().of(Yup.object().shape({
                        title: Yup.string().required('Title is required'),
                        position: Yup.number().min(0, 'Position must be at least 0').required('Position is required'),
                        santaPageMetadataId: Yup.string().required('Product page is required')
                    })),
                }), onSubmit: function (values) {
                    setSavingProgress('saving');
                    var priceCalculatorsToSend = values.priceCalculators.map(function (priceCalculator, i) {
                        return __assign(__assign({}, priceCalculator), { thumbnailUrl: homepageSettings.priceCalculatorMetadata[i].thumbnailUrl, imageMetadata: homepageSettings.priceCalculatorMetadata[i].imageMetadata, requestUri: homepageSettings.priceCalculatorMetadata[i].requestUri });
                    });
                    axios.put("/admin/api/homepage-settings/".concat(homepageSettings.id, "/price-calculators"), priceCalculatorsToSend)
                        .then(function (res) {
                        setHomepageSettings(res.data);
                        setSavingProgress('success');
                    })
                        .catch(function () { return setSavingProgress('error'); })
                        .finally(function () {
                        setTimeout(function () {
                            setSavingProgress('idle');
                        }, 5000);
                    });
                } }, function (_a) {
                var handleSubmit = _a.handleSubmit, formikSetValues = _a.setValues, values = _a.values;
                var changedRows = compareListsAndReturnIndexes(homepageSettings.priceCalculatorMetadata, values.priceCalculators.map(function (priceCalculator, i) {
                    return __assign(__assign({}, priceCalculator), { thumbnailUrl: homepageSettings.priceCalculatorMetadata[i].thumbnailUrl, imageMetadata: homepageSettings.priceCalculatorMetadata[i].imageMetadata, requestUri: homepageSettings.priceCalculatorMetadata[i].requestUri });
                }));
                return (React.createElement(Form, { onSubmit: handleSubmit },
                    homepageSettings.priceCalculatorMetadata.length > 0 ? (React.createElement(Table, { striped: true, hover: true, responsive: true },
                        React.createElement("thead", null,
                            React.createElement("tr", null,
                                React.createElement("th", null, "Image"),
                                React.createElement("th", null, "Title"),
                                React.createElement("th", null, "Position"),
                                React.createElement("th", null, "Product Page"),
                                React.createElement("th", null))),
                        React.createElement("tbody", null,
                            React.createElement(FieldArray, { name: "priceCalculators" }, function (_a) {
                                var remove = _a.remove;
                                return (React.createElement(React.Fragment, null, homepageSettings.priceCalculatorMetadata.map(function (calculator, index) { return (React.createElement("tr", { key: "".concat(calculator.santaPageMetadataId, "_").concat(calculator.thumbnailUrl, "_").concat(calculator.title, "_").concat(calculator.position), className: calculator.unSaved || changedRows.includes(index) ? 'alert alert-warning' : '' },
                                    React.createElement("td", null,
                                        React.createElement(Image, { src: calculator.thumbnailUrl, thumbnail: true, width: "170px" })),
                                    React.createElement("td", null,
                                        React.createElement(Field, { type: "text", name: "priceCalculators.".concat(index, ".title"), as: FormControl }),
                                        React.createElement(ErrorMessage, { name: "priceCalculators.".concat(index, ".title"), component: "div", className: "error" })),
                                    React.createElement("td", null,
                                        React.createElement(Field, { type: "number", name: "priceCalculators.".concat(index, ".position"), as: FormControl }),
                                        React.createElement(ErrorMessage, { name: "priceCalculators.".concat(index, ".position"), component: "div", className: "error" })),
                                    React.createElement("td", null,
                                        React.createElement(Field, { type: "select", name: "priceCalculators.".concat(index, ".santaPageMetadataId"), as: Form.Select }, availablePages.map(function (page) { return (React.createElement("option", { key: "santapagemetadata-option-".concat(page.id), value: page.id }, page.title)); })),
                                        React.createElement(ErrorMessage, { name: "priceCalculators.".concat(index, ".santaPageMetadataId"), component: "div", className: "error" })),
                                    React.createElement("td", { className: "text-end" },
                                        React.createElement(Button, { variant: "danger", onClick: function () {
                                                remove(index);
                                                setHomepageSettings(function (homepageSettings) {
                                                    homepageSettings.priceCalculatorMetadata.splice(index, 1);
                                                    return homepageSettings;
                                                });
                                            } }, "Delete")))); })));
                            })))) : (React.createElement(Alert, { variant: "warning" }, "No Price Calculators Currently Configured")),
                    React.createElement(Row, { className: "mb-3" },
                        React.createElement(Col, { className: "col col-md-12" },
                            React.createElement(Button, { variant: "primary", type: "submit" }, savingProgress === 'saving' ?
                                React.createElement("div", { className: "spinner-border spinner-border-sm", role: "status" },
                                    React.createElement("span", { className: "visually-hidden" }, "Loading...")) : 'Save'),
                            savingProgress === 'success' && React.createElement("div", { className: "success" }, "Saved!"),
                            savingProgress === 'error' && React.createElement("div", { className: "error" }, "Encountered an error when saving, please try again"))),
                    React.createElement("h4", { className: "border-top pt-3 mt-3" }, "Add New Price Calculator"),
                    React.createElement("div", { className: "col-md-12 col-lg-6" },
                        React.createElement(ImageUpload, { uploadFolder: "homepage-price-calculator", imageWidth: 170, imageHeight: 110, resizeMethod: "SCALE-WIDTH", imagePreview: true, produceWebp: true, uploadSuccessCallback: function (record) { return uploadSuccessCallback(record, formikSetValues, values); }, deleteCallback: function (_a) {
                                var imageUpload = _a.imageUpload;
                                var newPriceCalculatorMetadata = homepageSettings.priceCalculatorMetadata.filter(function (metadata) { return metadata.thumbnailUrl !== imageUpload.url; });
                                setHomepageSettings(__assign(__assign({}, homepageSettings), { priceCalculatorMetadata: newPriceCalculatorMetadata }));
                                return imageUpload;
                            } }))));
            })) : ''));
};
export default PriceCalculatorManager;
var templateObject_1;
