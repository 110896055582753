import defineComponent from '../../../components/flight/lib/component';
import React from "react";
import ReactDom from "react-dom/client";
import {ArticleSummary} from "@mixam-platform/mixam-components";

export default defineComponent(SupplierFulfilments);

function SupplierFulfilments() {

    this.attributes({
        fulfilmentLimitSelector: 'div',
    });

    this.after('initialize', function () {
        fetch('/admin/api/article/listbydate', {
            method: 'GET',
            headers: {
                'Access-Control-Allow-Headers': '*',
                'Accept': 'application/json'
            }
        })
            .then(response => response.json())
            .then(({ supplierFulfilmentCounts, dateTimeCutOff }) => {
                const root = ReactDom.createRoot(this.select('fulfilmentLimitSelector')[0]);
                root.render(
                    <ArticleSummary
                        supplierFulfilmentCounts={supplierFulfilmentCounts}
                        dateTimeCutOff={dateTimeCutOff}
                    ></ArticleSummary>
                );
            });
    });

}
