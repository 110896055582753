import defineComponent from '../../../../../components/flight/lib/component';
import WithDashboardComponent from '../../../with-dashbord-component';
import Chartjs from '../../../../../components/Chart.js';

export default defineComponent(AnalyticsShareViews, WithDashboardComponent);

function AnalyticsShareViews() {

    this.attributes({
        field: "count",
        chartSelector: '.account-manager-chart',
        clipperSelector: '.donat-clipper',
        dataElementSelector: '.counter-data',
        captionCountSelector: '.widget-content h2 span.count'
    });

    this.isInteresting = function (counter) {
        return counter.cycle === "DAILY" && counter.type.indexOf(this.type) === 0;
    };

    this.reset = function () {

        this.display(true);
    };

    this.display = function (isSilent) {
        if (this.timer) {
            clearTimeout(this.timer);
        }

        this.timer = setTimeout(() => {
            this.timer = null;
            if (this.chart) {
                this.chart.destroy();
            }
            this.initChart();
            Object.keys(this.counters).forEach(key => this.updateUiCounters(this.counters[key], isSilent));
        }, 200);
    };

    this.initChart = function () {
        if (!Object.keys(this.counters).length) {
            return;
        }

        var series =  Object.keys(this.counters)
                .filter(key => this.type !== key)
                .map(key => this.counters[key].count),
            labels =  Object.keys(this.counters)
                .filter(key => this.type !== key)
                .map(key => this.counters[key].type.substr(11)),
            data = {
                labels: labels,
                datasets: [
                    {
                        label: "Times",
                        fillColor: this.rgba(this.attr.field, 0, 0.65),
                        strokeColor: this.rgba(this.attr.field, 0, 0.85),
                        highlightFill: this.rgba(this.attr.field, 1, 0.95),
                        highlightStroke: this.rgba(this.attr.field, 1, 1),
                        data: series
                    }
                ]
            },
            ctx = this.select('chartSelector').get(0).getContext("2d");

        /** @namespace Chartjs.Bar */
        this.chart = new Chartjs(ctx).Bar(data, this.getChartOptions());
    };


    this.after('initialize', function () {
        this.counters = {};
        this.type = this.$node.data('counterType');

            this.attr.field = this.$node.data("field");

        this.reset();
        this.on(document, "dashbordReset", this.reset);
        this.on(document, "dashbordCounterUpdate", this.counterUpdate);
    });
}
