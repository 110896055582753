/***
 *  Query Params parsing services
 *  Provides query parameters values as a mixin
 *
 *  Parse the url's query string on initialization
 *  and provide parameters values.
 *
 *  effie.n@taboola.com
 *  30 Jan 2015
 */


var parameters;

function parseQueryString() {
    var params = {};

    if (location.search) {
        location.search.substr(1).split("&").forEach(function (token) {
            var a = token.split("=");
            params[a[0]] = a[1];
        });
    }
    return params;
}


export default function WithQueryParams() {

    this.getParameter = function (param) {
        parameters = parameters || parseQueryString();
        return parameters[param];
    };
}

