var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useEffect, useState, useRef } from 'react';
import { Spinner, Alert, Button, Form, Card, Table, Modal } from 'react-bootstrap';
import { Formik, Form as FormikForm } from 'formik';
import axios from 'axios';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { UnitType } from '../types';
import { Trilean } from '@mixam-platform/mixam-types';
import ConfirmationModal from './ConfirmationModal';
var ItemType = 'ROW';
var DraggableRow = function (_a) {
    var _b, _c, _d, _e;
    var size = _a.size, index = _a.index, moveRow = _a.moveRow, handleDefaultChange = _a.handleDefaultChange, handleRemoveRow = _a.handleRemoveRow, handleUnitTypeChange = _a.handleUnitTypeChange, handleShowNameChange = _a.handleShowNameChange, handleFoldingOptionsClick = _a.handleFoldingOptionsClick, values = _a.values, dinSizes = _a.dinSizes, standardSizes = _a.standardSizes, disableActions = _a.disableActions, foldingAvailable = _a.foldingAvailable;
    var ref = useRef(null);
    var _f = useDrop({
        accept: ItemType,
        hover: function (item) {
            if (item.index === index)
                return;
            moveRow(item.index, index);
            item.index = index;
        },
    }), drop = _f[1];
    var _g = useDrag({
        type: ItemType,
        item: { index: index },
        collect: function (monitor) { return ({
            isDragging: monitor.isDragging(),
        }); },
    }), isDragging = _g[0].isDragging, drag = _g[1];
    drag(drop(ref));
    var sizeDataMm = size.standardSize === 'NONE'
        ? ((_b = dinSizes[size.dinSize]) === null || _b === void 0 ? void 0 : _b.find(function (s) { return s.unitType === UnitType.MILLIMETERS; })) || { width: 0, height: 0, label: '' }
        : ((_c = standardSizes[size.standardSize]) === null || _c === void 0 ? void 0 : _c.find(function (s) { return s.unitType === UnitType.MILLIMETERS; })) || { width: 0, height: 0, label: '' };
    var sizeDataInches = size.standardSize === 'NONE'
        ? ((_d = dinSizes[size.dinSize]) === null || _d === void 0 ? void 0 : _d.find(function (s) { return s.unitType === UnitType.INCHES; })) || { width: 0, height: 0, label: '' }
        : ((_e = standardSizes[size.standardSize]) === null || _e === void 0 ? void 0 : _e.find(function (s) { return s.unitType === UnitType.INCHES; })) || { width: 0, height: 0, label: '' };
    var sizeData = size.unitType === UnitType.MILLIMETERS ? sizeDataMm : sizeDataInches;
    return (React.createElement("tr", { ref: ref, style: { opacity: isDragging ? 0.5 : 1 } },
        React.createElement("td", null, sizeData.label),
        React.createElement("td", null, sizeDataMm.width),
        React.createElement("td", null, sizeDataMm.height),
        React.createElement("td", null, sizeDataInches.width),
        React.createElement("td", null, sizeDataInches.height),
        React.createElement("td", null,
            React.createElement(Form.Select, { value: size.unitType, onChange: function (e) { return handleUnitTypeChange(index, e.target.value); }, disabled: disableActions },
                React.createElement("option", { value: UnitType.MILLIMETERS }, "Millimeters"),
                React.createElement("option", { value: UnitType.INCHES }, "Inches"))),
        React.createElement("td", null,
            React.createElement(Form.Check, { type: "checkbox", checked: size.showName, onChange: function (e) { return handleShowNameChange(index, e.target.checked); }, disabled: disableActions })),
        React.createElement("td", null,
            React.createElement(Form.Check, { type: "radio", name: "santaDefault", checked: size.santaDefault, onChange: function () { return handleDefaultChange(index); }, disabled: disableActions })),
        React.createElement("td", { className: "text-end" },
            foldingAvailable && (React.createElement(Button, { variant: "secondary", onClick: function () { return handleFoldingOptionsClick(size, index); }, disabled: disableActions, className: "me-1", size: "sm" }, "Folding Options")),
            React.createElement(Button, { variant: "danger", onClick: function () { return handleRemoveRow(index); }, disabled: disableActions, size: "sm" }, "Remove"))));
};
var FoldingOptionsModal = function (_a) {
    var show = _a.show, onHide = _a.onHide, foldingOptions = _a.foldingOptions, onSave = _a.onSave, simpleFolds = _a.simpleFolds, setFieldValue = _a.setFieldValue, currentFoldingIndex = _a.currentFoldingIndex;
    var defaultFoldingOptions = {
        portraitOptions: [],
        landscapeOptions: []
    };
    var _b = useState(foldingOptions || defaultFoldingOptions), editedOptions = _b[0], setEditedOptions = _b[1];
    var _c = useState(null), selectedPortraitDefault = _c[0], setSelectedPortraitDefault = _c[1];
    var _d = useState(null), selectedLandscapeDefault = _d[0], setSelectedLandscapeDefault = _d[1];
    var _e = useState([]), rawAvailableSidesInput = _e[0], setRawAvailableSidesInput = _e[1];
    useEffect(function () {
        var portraitDefaultIndex = editedOptions.portraitOptions.findIndex(function (option) { return option.santaDefault; });
        var landscapeDefaultIndex = editedOptions.landscapeOptions.findIndex(function (option) { return option.santaDefault; });
        setSelectedPortraitDefault(portraitDefaultIndex);
        setSelectedLandscapeDefault(landscapeDefaultIndex);
    }, [editedOptions]);
    var handleOptionChange = function (type, index, field, value) {
        var _a;
        var updatedOptions = __spreadArray([], editedOptions[type], true);
        if (field === 'availableSides') {
            var updatedInput = __spreadArray([], rawAvailableSidesInput, true);
            updatedInput[index] = value;
            setRawAvailableSidesInput(updatedInput);
        }
        else {
            updatedOptions[index][field] = value;
        }
        setEditedOptions(__assign(__assign({}, editedOptions), (_a = {}, _a[type] = updatedOptions, _a)));
    };
    var handleAvailableSidesBlur = function (type, index) {
        var _a;
        var updatedOptions = __spreadArray([], editedOptions[type], true);
        var rawInput = rawAvailableSidesInput[index] || '';
        updatedOptions[index]['availableSides'] = rawInput
            .split(',')
            .map(Number)
            .filter(function (num) { return num !== 0; });
        setEditedOptions(__assign(__assign({}, editedOptions), (_a = {}, _a[type] = updatedOptions, _a)));
    };
    var handleAddOption = function (type) {
        var _a;
        var newOption = {
            simpleFold: 'NONE',
            availableSides: [],
            santaDefault: false,
            deliveredFlat: Trilean.UNAVAILABLE,
        };
        setEditedOptions(__assign(__assign({}, editedOptions), (_a = {}, _a[type] = __spreadArray(__spreadArray([], editedOptions[type], true), [newOption], false), _a)));
    };
    var handleRemoveOption = function (type, index) {
        var _a;
        var updatedOptions = __spreadArray([], editedOptions[type], true);
        updatedOptions.splice(index, 1);
        setEditedOptions(__assign(__assign({}, editedOptions), (_a = {}, _a[type] = updatedOptions, _a)));
    };
    var handleSave = function () {
        var updatedOptions = __assign(__assign({}, editedOptions), { portraitOptions: editedOptions.portraitOptions.map(function (option, index) { return (__assign(__assign({}, option), { santaDefault: index === selectedPortraitDefault })); }), landscapeOptions: editedOptions.landscapeOptions.map(function (option, index) { return (__assign(__assign({}, option), { santaDefault: index === selectedLandscapeDefault })); }) });
        setFieldValue("standardSizes[".concat(currentFoldingIndex, "].foldingOptions"), updatedOptions);
        onSave(updatedOptions);
        onHide();
    };
    return (React.createElement(Modal, { show: show, onHide: onHide, size: "lg" },
        React.createElement(Modal.Header, { closeButton: true },
            React.createElement(Modal.Title, null, "Edit Folding Options")),
        React.createElement(Modal.Body, null,
            React.createElement("h5", null, "Portrait Options"),
            React.createElement(Table, { striped: true, bordered: true, hover: true, variant: "light" },
                React.createElement("thead", null,
                    React.createElement("tr", null,
                        React.createElement("th", null, "Simple Fold"),
                        React.createElement("th", null, "Available Sides"),
                        React.createElement("th", null, "Santa Default"),
                        React.createElement("th", null, "Delivered Flat"),
                        React.createElement("th", null, "Actions"))),
                React.createElement("tbody", null, editedOptions.portraitOptions.map(function (option, index) { return (React.createElement("tr", { key: index },
                    React.createElement("td", null,
                        React.createElement(Form.Select, { value: option.simpleFold, onChange: function (e) { return handleOptionChange('portraitOptions', index, 'simpleFold', e.target.value); } }, simpleFolds.map(function (fold) { return (React.createElement("option", { key: fold, value: fold }, fold)); }))),
                    React.createElement("td", null,
                        React.createElement(Form.Control, { type: "text", value: rawAvailableSidesInput[index] || option.availableSides.join(', '), onChange: function (e) { return handleOptionChange('portraitOptions', index, 'availableSides', e.target.value); }, onBlur: function () { return handleAvailableSidesBlur('portraitOptions', index); }, disabled: option.simpleFold === 'NONE' })),
                    React.createElement("td", null,
                        React.createElement(Form.Check, { type: "radio", name: "portraitDefault", checked: selectedPortraitDefault === index, onChange: function () { return handleOptionChange('portraitOptions', index, 'santaDefault', true); } })),
                    React.createElement("td", null,
                        React.createElement(Form.Select, { value: option.deliveredFlat, onChange: function (e) { return handleOptionChange('portraitOptions', index, 'deliveredFlat', e.target.value); } }, Object.values(Trilean).map(function (trilean) { return (React.createElement("option", { key: trilean, value: trilean }, trilean)); }))),
                    React.createElement("td", null,
                        React.createElement(Button, { variant: "danger", onClick: function () { return handleRemoveOption('portraitOptions', index); } }, "Remove")))); }))),
            React.createElement(Button, { variant: "primary", onClick: function () { return handleAddOption('portraitOptions'); } }, "Add Portrait Option"),
            React.createElement("h5", { className: "mt-4" }, "Landscape Options"),
            React.createElement(Table, { striped: true, bordered: true, hover: true, variant: "light" },
                React.createElement("thead", null,
                    React.createElement("tr", null,
                        React.createElement("th", null, "Simple Fold"),
                        React.createElement("th", null, "Available Sides"),
                        React.createElement("th", null, "Santa Default"),
                        React.createElement("th", null, "Delivered Flat"),
                        React.createElement("th", null, "Actions"))),
                React.createElement("tbody", null, editedOptions.landscapeOptions.map(function (option, index) { return (React.createElement("tr", { key: index },
                    React.createElement("td", null,
                        React.createElement(Form.Select, { value: option.simpleFold, onChange: function (e) { return handleOptionChange('landscapeOptions', index, 'simpleFold', e.target.value); } }, simpleFolds.map(function (fold) { return (React.createElement("option", { key: fold, value: fold }, fold)); }))),
                    React.createElement("td", null,
                        React.createElement(Form.Control, { type: "text", value: option.simpleFold === 'NONE' ? '' : option.availableSides.join(', '), onChange: function (e) { return handleOptionChange('landscapeOptions', index, 'availableSides', e.target.value.split(',').map(Number)); }, disabled: option.simpleFold === 'NONE' })),
                    React.createElement("td", null,
                        React.createElement(Form.Check, { type: "radio", name: "landscapeDefault", checked: selectedLandscapeDefault === index, onChange: function () { return handleOptionChange('landscapeOptions', index, 'santaDefault', true); } })),
                    React.createElement("td", null,
                        React.createElement(Form.Select, { value: option.deliveredFlat, onChange: function (e) { return handleOptionChange('landscapeOptions', index, 'deliveredFlat', e.target.value); } }, Object.values(Trilean).map(function (trilean) { return (React.createElement("option", { key: trilean, value: trilean }, trilean)); }))),
                    React.createElement("td", null,
                        React.createElement(Button, { variant: "danger", onClick: function () { return handleRemoveOption('landscapeOptions', index); } }, "Remove")))); }))),
            React.createElement(Button, { variant: "primary", onClick: function () { return handleAddOption('landscapeOptions'); } }, "Add Landscape Option")),
        React.createElement(Modal.Footer, null,
            React.createElement(Button, { variant: "secondary", onClick: onHide }, "Cancel"),
            React.createElement(Button, { variant: "primary", onClick: handleSave }, "Save"))));
};
var StandardSizeMetadataEditor = function (_a) {
    var productId = _a.productId, subProductId = _a.subProductId, santaType = _a.santaType, dinSizes = _a.dinSizes, standardSizes = _a.standardSizes, simpleFolds = _a.simpleFolds, foldingAvailable = _a.foldingAvailable;
    var _b = useState(false), isLoading = _b[0], setLoading = _b[1];
    var _c = useState(null), error = _c[0], setError = _c[1];
    var _d = useState(null), successMessage = _d[0], setSuccessMessage = _d[1];
    var _e = useState(null), metadataDocumentWrapper = _e[0], setMetadataDocumentWrapper = _e[1];
    var _f = useState(false), useCustom = _f[0], setUseCustom = _f[1];
    var _g = useState(false), showAddModal = _g[0], setShowAddModal = _g[1];
    var _h = useState([]), selectedSizes = _h[0], setSelectedSizes = _h[1];
    var _j = useState(false), showFoldingOptionsModal = _j[0], setShowFoldingOptionsModal = _j[1];
    var _k = useState(null), currentFoldingOptions = _k[0], setCurrentFoldingOptions = _k[1];
    var _l = useState(null), currentFoldingIndex = _l[0], setCurrentFoldingIndex = _l[1];
    var _m = useState(false), showConfirmationModal = _m[0], setShowConfirmationModal = _m[1];
    useEffect(function () {
        setLoading(true);
        axios
            .get("/api/admin/metadata/product/standard-sizes/products/".concat(productId, "/sub-products/").concat(subProductId, "/santa-types/").concat(santaType))
            .then(function (response) {
            setMetadataDocumentWrapper(response.data);
            if (subProductId !== 0) {
                setUseCustom(response.data.productMetadataDocument !== null);
            }
            setLoading(false);
        })
            .catch(function () {
            setLoading(false);
            setError("Failed to load standard sizes metadata document.");
        });
    }, [productId, subProductId, santaType]);
    var saveStandardSizesMetadata = function (values, setSubmitting) {
        setLoading(true);
        setError(null);
        setSuccessMessage(null);
        var newDocument = activeDocument
            ? __assign({}, activeDocument) : {
            shopId: metadataDocumentWrapper.defaultProductMetadataDocument.shopId,
            productId: productId,
            subProductId: subProductId,
            santaType: santaType,
        };
        var saveRequest = {
            useDefault: !useCustom,
            standardSizesMetadataDocument: __assign(__assign({}, newDocument), { data: values.standardSizes }),
        };
        axios
            .post("/api/admin/metadata/product/standard-sizes/products/".concat(productId, "/sub-products/").concat(subProductId, "/santa-types/").concat(santaType), saveRequest)
            .then(function (response) {
            setMetadataDocumentWrapper(response.data);
            setLoading(false);
            setSubmitting(false);
            setSuccessMessage('Standard Sizes Metadata saved successfully!');
        })
            .catch(function (error) {
            var _a;
            setLoading(false);
            setSubmitting(false);
            setError("Failed to save standard sizes metadata: ".concat(((_a = error.response) === null || _a === void 0 ? void 0 : _a.data) || error.message));
        });
    };
    var handleSave = function (values, setSubmitting) {
        if (!useCustom && subProductId !== 0) {
            setShowConfirmationModal(true);
        }
        else {
            saveStandardSizesMetadata(values, setSubmitting);
        }
    };
    var handleCopyFromDefault = function () {
        if (metadataDocumentWrapper === null || metadataDocumentWrapper === void 0 ? void 0 : metadataDocumentWrapper.defaultProductMetadataDocument) {
            var defaultDocument = metadataDocumentWrapper.defaultProductMetadataDocument;
            var updatedCustomDocument = __assign(__assign({}, metadataDocumentWrapper.productMetadataDocument || {
                shopId: defaultDocument.shopId,
                productId: productId,
                subProductId: subProductId,
                santaType: santaType,
            }), { data: defaultDocument.data || [] });
            setMetadataDocumentWrapper(__assign(__assign({}, metadataDocumentWrapper), { productMetadataDocument: updatedCustomDocument }));
        }
    };
    if (isLoading) {
        return React.createElement(Spinner, { animation: "grow", variant: "primary" });
    }
    if (!metadataDocumentWrapper) {
        return React.createElement(Alert, { variant: "danger" }, "No Standard Sizes Metadata available");
    }
    var activeDocument = useCustom
        ? metadataDocumentWrapper === null || metadataDocumentWrapper === void 0 ? void 0 : metadataDocumentWrapper.productMetadataDocument
        : metadataDocumentWrapper === null || metadataDocumentWrapper === void 0 ? void 0 : metadataDocumentWrapper.defaultProductMetadataDocument;
    var isCustomizable = subProductId !== 0 ? useCustom : true;
    var handleFoldingOptionsClick = function (size, index) {
        var defaultFoldingOptions = {
            portraitOptions: [],
            landscapeOptions: []
        };
        setCurrentFoldingOptions(size.foldingOptions || defaultFoldingOptions);
        setCurrentFoldingIndex(index);
        setShowFoldingOptionsModal(true);
    };
    return (React.createElement(DndProvider, { backend: HTML5Backend },
        React.createElement(Formik, { initialValues: {
                standardSizes: (activeDocument === null || activeDocument === void 0 ? void 0 : activeDocument.data) || [],
            }, enableReinitialize: true, onSubmit: handleSave }, function (_a) {
            var values = _a.values, setFieldValue = _a.setFieldValue, isSubmitting = _a.isSubmitting, setSubmitting = _a.setSubmitting;
            var moveRow = function (fromIndex, toIndex) {
                var updatedSizes = __spreadArray([], values.standardSizes, true);
                var movedItem = updatedSizes.splice(fromIndex, 1)[0];
                updatedSizes.splice(toIndex, 0, movedItem);
                setFieldValue("standardSizes", updatedSizes);
            };
            var handleDefaultChange = function (index) {
                var updatedSizes = values.standardSizes.map(function (size, i) { return (__assign(__assign({}, size), { santaDefault: i === index })); });
                setFieldValue("standardSizes", updatedSizes);
            };
            var handleRemoveRow = function (index) {
                var updatedSizes = values.standardSizes.filter(function (_, i) { return i !== index; });
                setFieldValue("standardSizes", updatedSizes);
            };
            var handleUnitTypeChange = function (index, unitType) {
                var updatedSizes = values.standardSizes.map(function (size, i) {
                    return i === index ? __assign(__assign({}, size), { unitType: unitType }) : size;
                });
                setFieldValue("standardSizes", updatedSizes);
            };
            var handleShowNameChange = function (index, checked) {
                var updatedSizes = values.standardSizes.map(function (size, i) {
                    return i === index ? __assign(__assign({}, size), { showName: checked }) : size;
                });
                setFieldValue("standardSizes", updatedSizes);
            };
            var existingSizeKeys = new Set(values.standardSizes.map(function (size) { return size.standardSize || size.dinSize; }));
            var handleAddSelectedSizes = function () {
                var newSizes = selectedSizes
                    .filter(function (key) { return !existingSizeKeys.has(key); }) // Avoid adding sizes that are already in the list
                    .map(function (key) {
                    var _a, _b;
                    var size = ((_a = dinSizes[key]) === null || _a === void 0 ? void 0 : _a[0]) || ((_b = standardSizes[key]) === null || _b === void 0 ? void 0 : _b[0]);
                    if (size) {
                        // For DIN sizes, set standardSize as NONE
                        return dinSizes[key]
                            ? __assign({ dinSize: key, standardSize: "NONE" }, size) : __assign({ standardSize: key }, size);
                    }
                    return null;
                })
                    .filter(Boolean);
                setFieldValue("standardSizes", __spreadArray(__spreadArray([], values.standardSizes, true), newSizes, true));
                setSelectedSizes([]); // Reset selection
                setShowAddModal(false); // Close modal
            };
            var toggleSizeSelection = function (key) {
                setSelectedSizes(function (prevSelected) {
                    return prevSelected.includes(key)
                        ? prevSelected.filter(function (sizeKey) { return sizeKey !== key; })
                        : __spreadArray(__spreadArray([], prevSelected, true), [key], false);
                });
            };
            return (React.createElement(React.Fragment, null,
                React.createElement(Card, { className: "p-4 shadow-sm bg-light" },
                    React.createElement(FormikForm, null,
                        React.createElement("h5", { className: "mb-4" }, "Standard Sizes Metadata"),
                        successMessage && (React.createElement(Alert, { variant: "success", onClose: function () { return setSuccessMessage(null); }, dismissible: true }, successMessage)),
                        error && (React.createElement(Alert, { variant: "danger", onClose: function () { return setError(null); }, dismissible: true }, error)),
                        subProductId !== 0 && (React.createElement(Form.Group, { className: "mb-4" },
                            React.createElement(Form.Check, { type: "radio", label: "Use Default Metadata", checked: !useCustom, onChange: function () { return setUseCustom(false); } }),
                            React.createElement(Form.Check, { type: "radio", label: "Customize Metadata", checked: useCustom, onChange: function () { return setUseCustom(true); } }),
                            React.createElement("hr", null),
                            useCustom && (React.createElement(Button, { className: "mt-1 btn-sm", variant: "secondary", onClick: handleCopyFromDefault }, "Copy Default Values")))),
                        React.createElement(Table, { striped: true, bordered: true, hover: true, variant: "light" },
                            React.createElement("thead", null,
                                React.createElement("tr", null,
                                    React.createElement("th", null, "Label"),
                                    React.createElement("th", null, "Width (mm)"),
                                    React.createElement("th", null, "Height (mm)"),
                                    React.createElement("th", null, "Width (in)"),
                                    React.createElement("th", null, "Height (in)"),
                                    React.createElement("th", null, "Display Unit Type"),
                                    React.createElement("th", null, "Use Name"),
                                    React.createElement("th", null, "Default"),
                                    React.createElement("th", null))),
                            React.createElement("tbody", null, values.standardSizes.map(function (size, index) { return (React.createElement(DraggableRow, { key: index, size: size, index: index, moveRow: moveRow, handleDefaultChange: handleDefaultChange, handleRemoveRow: handleRemoveRow, handleUnitTypeChange: handleUnitTypeChange, handleShowNameChange: handleShowNameChange, handleFoldingOptionsClick: function () { return handleFoldingOptionsClick(size, index); }, values: values, dinSizes: dinSizes, standardSizes: standardSizes, disableActions: !isCustomizable, foldingAvailable: foldingAvailable })); }))),
                        React.createElement("div", { className: "d-flex justify-content-end mb-3" },
                            React.createElement(Button, { variant: "primary", onClick: function () { return setShowAddModal(true); }, disabled: !isCustomizable }, "Add New Size")),
                        React.createElement("div", { className: "d-flex justify-content-start" },
                            React.createElement(Button, { type: "submit", disabled: isSubmitting, variant: "primary" }, "Save Changes")))),
                React.createElement(Modal, { show: showAddModal, onHide: function () { return setShowAddModal(false); }, size: "xl" },
                    React.createElement(Modal.Header, { closeButton: true },
                        React.createElement(Modal.Title, null, "Select a New Size")),
                    React.createElement(Modal.Body, null,
                        React.createElement(Table, { striped: true, bordered: true, hover: true },
                            React.createElement("thead", null,
                                React.createElement("tr", null,
                                    React.createElement("th", null, "Key"),
                                    React.createElement("th", null, "Label MM"),
                                    React.createElement("th", null, "Label IN"),
                                    React.createElement("th", null, "Width (mm)"),
                                    React.createElement("th", null, "Height (mm)"),
                                    React.createElement("th", null, "Width (in)"),
                                    React.createElement("th", null, "Height (in)"),
                                    React.createElement("th", null, "Select"))),
                            React.createElement("tbody", null,
                                Object.keys(dinSizes)
                                    .sort(function (a, b) { return a.localeCompare(b); })
                                    .map(function (key) {
                                    var _a, _b;
                                    var mmSize = (_a = dinSizes[key]) === null || _a === void 0 ? void 0 : _a.find(function (s) { return s.unitType === UnitType.MILLIMETERS; });
                                    var inSize = (_b = dinSizes[key]) === null || _b === void 0 ? void 0 : _b.find(function (s) { return s.unitType === UnitType.INCHES; });
                                    var isDisabled = values.standardSizes.some(function (size) { var _a; return ((_a = size.dinSize) === null || _a === void 0 ? void 0 : _a.toString()) === key && size.standardSize === 'NONE'; });
                                    if (mmSize && inSize) {
                                        return (React.createElement("tr", { key: key, style: {
                                                backgroundColor: isDisabled
                                                    ? "#f8d7da"
                                                    : "inherit",
                                            } },
                                            React.createElement("td", null, key),
                                            React.createElement("td", null, mmSize.label),
                                            React.createElement("td", null, inSize.label),
                                            React.createElement("td", null, mmSize.width),
                                            React.createElement("td", null, mmSize.height),
                                            React.createElement("td", null, inSize.width),
                                            React.createElement("td", null, inSize.height),
                                            React.createElement("td", null,
                                                React.createElement(Form.Check, { type: "checkbox", disabled: isDisabled, checked: selectedSizes.includes(key), onChange: function () { return toggleSizeSelection(key); } }))));
                                    }
                                    return null;
                                }),
                                Object.keys(standardSizes)
                                    .sort(function (a, b) { return a.localeCompare(b); })
                                    .map(function (key) {
                                    var _a, _b;
                                    var mmSize = (_a = standardSizes[key]) === null || _a === void 0 ? void 0 : _a.find(function (s) { return s.unitType === UnitType.MILLIMETERS; });
                                    var inSize = (_b = standardSizes[key]) === null || _b === void 0 ? void 0 : _b.find(function (s) { return s.unitType === UnitType.INCHES; });
                                    var isDisabled = values.standardSizes.some(function (size) {
                                        return size.standardSize === key && size.standardSize !== 'NONE';
                                    });
                                    if (mmSize && inSize) {
                                        return (React.createElement("tr", { key: key, style: {
                                                backgroundColor: isDisabled
                                                    ? "#f8d7da"
                                                    : "inherit",
                                            } },
                                            React.createElement("td", null, key),
                                            React.createElement("td", null, mmSize.label),
                                            React.createElement("td", null, inSize.label),
                                            React.createElement("td", null, mmSize.width),
                                            React.createElement("td", null, mmSize.height),
                                            React.createElement("td", null, inSize.width),
                                            React.createElement("td", null, inSize.height),
                                            React.createElement("td", null,
                                                React.createElement(Form.Check, { type: "checkbox", disabled: isDisabled, checked: selectedSizes.includes(key), onChange: function () { return toggleSizeSelection(key); } }))));
                                    }
                                    return null;
                                })))),
                    React.createElement(Modal.Footer, null,
                        React.createElement(Button, { variant: "secondary", onClick: function () { return setShowAddModal(false); } }, "Cancel"),
                        React.createElement(Button, { variant: "primary", onClick: handleAddSelectedSizes }, "Add Selected Sizes"))),
                currentFoldingOptions && (React.createElement(FoldingOptionsModal, { show: showFoldingOptionsModal, onHide: function () { return setShowFoldingOptionsModal(false); }, foldingOptions: currentFoldingOptions, simpleFolds: simpleFolds, onSave: function () { }, setFieldValue: setFieldValue, currentFoldingIndex: currentFoldingIndex })),
                React.createElement(ConfirmationModal, { show: showConfirmationModal, onHide: function () { return setShowConfirmationModal(false); }, onConfirm: function () {
                        setShowConfirmationModal(false);
                        saveStandardSizesMetadata(values, setSubmitting);
                    } })));
        })));
};
export default StandardSizeMetadataEditor;
