export default {
    BOOKLET: 1,
    LEAFLET: 2,
    FOLDED: 3,
    POSTER: 4,
    LETTERHEAD: 5,
    PHOTOBOOK: 6,
    BOOK: 7,
    BUSINESS_CARD: 8,
    POSTCARD: 9,
    GREETING_CARD: 10,
    NOTE_BOOK: 11,
    COMPLIMENT_SLIP: 12,
    ENVELOPE: 13,
    FOLDER: 14,
    LAYFLAT: 15,
    WALL_CALENDAR: 16,
    DESK_CALENDAR: 17,
    WALL_VR_CALENDAR: 18,
    DESK_VR_CALENDAR: 19,
    TRADITIONAL_BOOK: 20,
    CANVAS: 21,
    FA_STICKERS_INDOORS: 22,
    FA_STICKERS_OUTDOORS: 23,
    FA_STICKERS_NEON: 24,
    FA_STICKERS_GEL: 25,
    FA_STICKERS_EXTREMELY_ADHESIVE: 26,
    FA_STICKERS_HEAT_RESISTANT: 27,
    FA_STICKERS_LUMINOUS: 28,
    FA_STICKERS_REFLECTIVE: 29,
    FA_STICKERS_REMOVABLE: 30,
    FA_STICKERS_FOOD: 31,
    FA_STICKERS_VEGAN_INDOOR: 32,
    FA_STICKERS_VEGAN_OUTDOOR: 33,
    FA_STICKER_ROLL: 34,
    FA_STICKER_SHEET: 35,
    DUST_JACKET: 36,

    isSticker: function (productId) {
        switch(productId) {
            case this.FA_STICKERS_INDOORS:
            case this.FA_STICKERS_OUTDOORS:
            case this.FA_STICKERS_NEON:
            case this.FA_STICKERS_GEL:
            case this.FA_STICKERS_EXTREMELY_ADHESIVE:
            case this.FA_STICKERS_HEAT_RESISTANT:
            case this.FA_STICKERS_LUMINOUS:
            case this.FA_STICKERS_REFLECTIVE:
            case this.FA_STICKERS_REMOVABLE:
            case this.FA_STICKERS_FOOD:
            case this.FA_STICKERS_VEGAN_INDOOR:
            case this.FA_STICKERS_VEGAN_OUTDOOR:
            case this.FA_STICKER_SHEET:
            case this.FA_STICKER_ROLL:
                return true;
            default:
                return false;
        }
    }
};