import defineComponent from '../../../components/flight/lib/component';

export default defineComponent(PromotionLoader);

function PromotionLoader() {

    this.init = function() {
        this.$node.html($(`<iframe name="share_embed_frame" title="Share embed frame" class="embed-share"
            src="/share/promotion/frame" frameborder="0" marginwidth="0" marginheight="0" allowtransparency="true" scrolling="no">
            </iframe>`));
    };

    this.after('initialize', function () {
        $(setTimeout(() => this.init(), 250));
    });
}
