import React from 'react';
import { Modal, Button } from 'react-bootstrap';
var ConfirmationModal = function (_a) {
    var show = _a.show, onHide = _a.onHide, onConfirm = _a.onConfirm;
    return (React.createElement(Modal, { show: show, onHide: onHide },
        React.createElement(Modal.Header, { closeButton: true },
            React.createElement(Modal.Title, null, "Switch To Default")),
        React.createElement(Modal.Body, null,
            React.createElement("p", null, "You are switching to the default metadata. This will overwrite all existing custom metadata and it will not be recoverable. Are you sure you want to proceed?")),
        React.createElement(Modal.Footer, null,
            React.createElement(Button, { variant: "secondary", onClick: onHide }, "Cancel"),
            React.createElement(Button, { variant: "danger", onClick: onConfirm }, "Confirm"))));
};
export default ConfirmationModal;
