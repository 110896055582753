var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useEffect, useState } from 'react';
import axios from "axios";
import Table from 'react-bootstrap/Table';
import Card from 'react-bootstrap/Card';
import { Locale } from "@mixam-platform/mixam-types";
import { ResponsiveLine } from "@nivo/line";
import { Button } from './components/styled';
var getSalesHistory = function (memberId) { return __awaiter(void 0, void 0, void 0, function () {
    var response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, axios.get("/api/orders/".concat(memberId, "/history"))];
            case 1:
                response = _a.sent();
                if (response.status === 200) {
                    return [2 /*return*/, response.data];
                }
                return [2 /*return*/, undefined];
        }
    });
}); };
var getFormattedDate = function (month, year) {
    var date = new Date(year, month - 1);
    var options = { year: 'numeric', month: 'long' };
    return date.toLocaleDateString(undefined, options);
};
var formatCurrency = function (amount, locale) {
    return new Intl.NumberFormat(locale, {
        style: 'currency',
        currency: getCurrencyCodeFromLocale(locale)
    }).format(amount);
};
var getCurrencyCodeFromLocale = function (locale) {
    switch (locale) {
        case Locale.EN_GB:
            return "GBP";
        case Locale.EN_US:
            return "USD";
        case Locale.EN_CA:
            return "CAD";
        case Locale.EN_AU:
            return "AUD";
        case Locale.DE_DE:
        case Locale.EN_DE:
            return "EUR";
        default:
            return undefined;
    }
};
export var SalesHistory = function (props) {
    var _a = useState([]), salesHistoryData = _a[0], setSalesHistoryData = _a[1];
    var _b = useState([]), commissionChartData = _b[0], setCommissionChartData = _b[1];
    var _c = useState({}), showDetails = _c[0], setShowDetails = _c[1];
    var toggleDetails = function (recordId) {
        setShowDetails(function (prevState) {
            var _a;
            return (__assign(__assign({}, prevState), (_a = {}, _a[recordId] = !prevState[recordId], _a)));
        });
    };
    useEffect(function () {
        getSalesHistory(props.memberId)
            .then(function (data) {
            setSalesHistoryData(data);
            var chartData = data.map(function (item) { return ({
                x: new Date(item.year, item.month - 1),
                y: item.commission
            }); });
            setCommissionChartData(chartData);
        });
    }, []);
    return (React.createElement(Card, { className: "p-3 mt-3" }, salesHistoryData.length > 0 ? (React.createElement(React.Fragment, null,
        React.createElement("div", { style: { width: "100%", height: "500px" } },
            React.createElement(ResponsiveLine, { data: [
                    {
                        id: "Commission",
                        data: commissionChartData,
                    },
                ], margin: { top: 50, right: 10, bottom: 50, left: 100 }, xScale: {
                    type: "time",
                    format: "%Y-%m-%d",
                    precision: "day",
                }, xFormat: "time:%Y-%m-%d", yScale: { type: "linear", min: "auto", max: "auto" }, axisBottom: {
                    format: "%b %Y",
                    tickValues: "every 1 month",
                    legend: "Date",
                    legendOffset: 36,
                    legendPosition: "middle",
                }, axisLeft: {
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: "Commission",
                    legendOffset: -80,
                    legendPosition: "middle",
                    format: function (value) { return "".concat(formatCurrency(value, props.locale)); },
                }, enablePoints: true, pointSize: 10, colors: ["#44c7be"], pointColor: { theme: "background" }, pointBorderWidth: 2, pointBorderColor: { from: "serieColor" }, pointLabel: "Commission", useMesh: false })),
        React.createElement("div", { className: "table-responsive" },
            React.createElement(Table, null,
                React.createElement("thead", null,
                    React.createElement("tr", null,
                        React.createElement("th", null),
                        React.createElement("th", null, "Order Count"),
                        React.createElement("th", null, "Total Copies"),
                        React.createElement("th", null, "Commission"),
                        React.createElement("th", null, "Settlement Amount"),
                        React.createElement("th", null, "Settlement Date"),
                        React.createElement("th", null))),
                React.createElement("tbody", null, salesHistoryData.map(function (record, outerIndex) { return (React.createElement(React.Fragment, { key: record.id },
                    React.createElement("tr", null,
                        React.createElement("td", null, getFormattedDate(record.month, record.year)),
                        React.createElement("td", null, record.items.length),
                        React.createElement("td", null, record.copiesTotal),
                        React.createElement("td", null, formatCurrency(record.commission, props.locale)),
                        React.createElement("td", null, formatCurrency(record.settlementAmount, props.locale)),
                        React.createElement("td", null, record.settlementDate ? new Date(record.settlementDate).toLocaleDateString() : ''),
                        React.createElement("td", { align: "right" },
                            React.createElement(Button, { variant: "primary", onClick: function () { return toggleDetails(record.id); } }, showDetails[record.id] ? 'Hide Details' : 'Show Details'))),
                    React.createElement("tr", { className: showDetails[record.id] ? '' : 'd-none' },
                        React.createElement("td", { colSpan: 7 },
                            React.createElement(Table, { striped: true, variant: "light" },
                                React.createElement("thead", null,
                                    React.createElement("tr", null,
                                        React.createElement("th", null, "Publication"),
                                        React.createElement("th", null),
                                        React.createElement("th", null, "Date"),
                                        React.createElement("th", null, "Customer"),
                                        React.createElement("th", null, "Copies"),
                                        React.createElement("th", null, "Price"),
                                        React.createElement("th", null, "Commission"))),
                                React.createElement("tbody", null, record.items.map(function (itemRecord, innerIndex) { return (React.createElement("tr", { key: innerIndex },
                                    React.createElement("td", null,
                                        React.createElement("img", { src: itemRecord.thumbnailUrl, width: 60 })),
                                    React.createElement("td", null, itemRecord.title),
                                    React.createElement("td", null, new Date(itemRecord.fulfilmentDate).toLocaleDateString()),
                                    React.createElement("td", null, itemRecord.customerName),
                                    React.createElement("td", null, itemRecord.copies),
                                    React.createElement("td", null, formatCurrency(itemRecord.total, props.locale)),
                                    React.createElement("td", null, formatCurrency(itemRecord.commission, props.locale)))); }))))))); })))))) : (React.createElement("div", null, "No Sales History"))));
};
