import mixam from '../../boot/mixam';
import defineComponent from '../../../components/flight/lib/component';
import WithNormalizeShoppingCartItems from '../with-normalize-shopping-items';
import WithNormalizeDispatch from '../with-normalize-dispatch';
import Mustache from '../../../components/mustache/mustache';
import WithNormalizeMap from '../with-normalize-map';
import WithSubmitAnimation from '../with-submit-zoom-animation';
import AnimatedCheckbox from '../animated/animated-checkbox';
import WithFolderUrl from '../with-get-folder-url';
import providerTemplate from 'text!../../../appes6/templates/dispatch/provider-dispatch.mustache';
import itemTemplate from 'text!../../../appes6/templates/dispatch/dispatch-item.mustache';
import itemSummaryTemplate from 'text!../../../appes6/templates/dispatch/dispatch-item-summary.mustache';
import WithCatalogPaperTypes from '../home/with-catalog-paper-types';

export default defineComponent(ItemDispatcher, WithNormalizeMap, WithNormalizeShoppingCartItems, WithNormalizeDispatch, WithSubmitAnimation,
    WithFolderUrl, WithCatalogPaperTypes);

function ItemDispatcher() {

    this.attributes({
        displaySelector: '.items-search-content',
        centersSelector: '[data-type="centers"]',
        centerIdSelector: '[data-type="center-id"]',
        collectionDateSelector: '[data-type="collection-date"]',
        animatedCheckboxSelector: '[data-toggle="animated-checkbox"]',
        animatedCheckboxInputSelector: '[data-toggle="animated-checkbox"] input[type=checkbox]',
        saveBtnSelector: '.control-save-line button[type = "submit"]'
    });

    this.render = function () {
        this.model.centerId = this.centerId;

        return Mustache.render(providerTemplate, this.model, {
            singleItemTemplate: itemTemplate,
            itemSummaryTemplate: itemSummaryTemplate
        });
    };

    this.loadDispatch = function (orderId, deliveryId, itemId) {
        const url = `/api/spedition/${orderId}/${deliveryId}/${itemId}`;

        this.$node.addClass("loading");
        $.getJSON(url).
            done(response => this.setData(response)).
            fail(response =>  this.setError(response));
    };

    this.setError = function (error) {
        this.$node.removeClass("loading");
        this.trigger("log", {message: error});
        //this.trigger('showFixedMessage', {message: regional().messages[1036]});
        //this.select('displaySelector').html("");
    };

    this.setData = function (data) {
        this.model = this.normalizeDispatch(data);
        this.model.currency = mixam.shop.currency;
        this.model.ref = this.ref;
        this.model.IS_PALLET = this.model.newShipment.deliveryMethod === 'PALLETS';
        this.paint();
        this.$node.removeClass("loading");
    };

    this.afterShowItem = function(event) {
        const $target = $(event.target),
            $value = $target.find('[data-type="value"]'),
            $copies = $target.find('.shipment-copies');

        $value.data('value', 1);
        $value.val($value.data('item'));
        $copies.prop('disabled', false);

        const activeItems = this.$node.find('input:checked');
        if (activeItems.length < 2) {
            activeItems.prop('disabled', true);
        } else {
            activeItems.prop('disabled', false);
        }
        //console.log("Show", $target, itemId, $button);
    };

    this.afterHideItem = function(event) {
        const $target = $(event.target),
            $value = $target.find('[data-type="value"]'),
            $copies = $target.find('.shipment-copies'),
            $supplierElement = $target.closest('.supplier-list-item');

        let activeItems = $supplierElement.find('[data-type="value"][data-value="1"]'),
            isLast = activeItems.length < 2;

        if (isLast) {
            event.stopPropagation();
            event.preventDefault();
        } else {
            $value.data('value', 0);
            $value.val("");
            $copies.prop('disabled', true);
        }

        activeItems = $supplierElement.find('input:checked');
        if (activeItems.length < 2) {
            activeItems.prop('disabled', true);
        } else {
            activeItems.prop('disabled', false);
        }
    };

    this.paint = function() {
        if (this.centerId && this.model.suppliers[0].centers) {
            this.model.suppliers[0].centers.map(c => {
                c.selected = null;
                return c;
            }).
                filter(c => c.centerId === this.centerId).
                forEach(c => c.selected = true);
        }

        this.select('displaySelector').html(this.render());
        /* jshint ignore:start */
        if (this.model.suppliers[0]?.services) {
            this.select('saveBtnSelector').removeAttr('data-loading').attr('disabled', false);
        }
        /* jshint ignore:end */
        AnimatedCheckbox.attachTo(this.attr.animatedCheckboxSelector);
    };

    this.centerChange = function (/*event*/) {
        this.centerId = this.select('centersSelector').val();
        this.select('centerIdSelector').val(this.centerId);
        this.getServices(this.model.order, this.model.delivery, this.model.suppliers[0]);
     };

    this.collectionDateChange = function () {
        this.model.newShipment.request.consignment[0].collectionDate = this.select('collectionDateSelector').val();
        delete this.model.suppliers[0].services;
        this.paint();
        this.getServices(this.model.order, this.model.delivery, this.model.suppliers[0]);
    };

    this.after('initialize', function () {
        this.orderId = this.$node.data('order');
        this.deliveryId = this.$node.data('delivery');
        this.itemId = this.$node.data('item');
        this.ref = this.$node.data('ref');
        this.deliveryMethod = this.$node.data('method');
        this.on('submit', this.handleSubmit);
        this.on("show.bs.collapse", this.afterShowItem);
        this.on("hide.bs.collapse", this.afterHideItem);
        this.on("change", {
            centersSelector: this.centerChange,
            collectionDateSelector: this.collectionDateChange
        });

        this.loadDispatch(this.orderId, this.deliveryId, this.itemId);
     });
}
