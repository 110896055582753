import Ripple from './../ripple';
import AnimatedCheckbox from '../animated/animated-checkbox';
import defineComponent from '../../../components/flight/lib/component';

export default defineComponent(DisputeRequestAction);

function DisputeRequestAction() {

    this.attributes({
        animatedCheckboxSelector: '[data-toggle="animated-checkbox"]',
        radioboxSelector: 'input[type="radio"]',
        valueSelector: '[data-type="value"]',
        rippleSelector: '.ripple',
    });

    this.after('initialize', function () {
        AnimatedCheckbox.attachTo(this.select('animatedCheckboxSelector'));
        Ripple.attachTo(this.select('rippleSelector'));
    });
}
