import React from 'react';
import ReactDom from  'react-dom';
import proptypes from 'prop-types';

const propTypes = {
  /**
   * Show the component; triggers the expand or collapse animation
   */
  in: proptypes.bool,

  /**
   * CSS class or classes applied when the component is exited
   */
  className: proptypes.string,

  /**
   * data-type associated with the button
   */
  datatype: proptypes.string,

  /**
   * Callback fired before the component expands
   */
  onEnter: proptypes.func,

  /**
   * Callback fired before the component collapses
   */
  onExit: proptypes.func,

  /**
   * icon associated with the button when not active
   */
  icon1ClassName: proptypes.string,

  /**
   * icon associated with the button when  active
   */
  icon2ClassName: proptypes.string,

  /**
   * title associated with the button when not active
   */
  title1: proptypes.string,

  /**
   * title associated with the button when active
   */
  title2: proptypes.string

};

function noop() {
}

const defaultProps = {
  in: false,
  onEnter: noop,
  onExit: noop
};


class MorphButton extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {open: props.in};
  }

  onclick() {
    const open = !this.state.open;
    this.setState({ open });
    this.componentDidInteractivelyUpdate(open);
  }

  componentDidInteractivelyUpdate(open) {
    const node = ReactDom.findDOMNode(this);
    if (open) {
      this.props.onEnter(node);
    } else {
      this.props.onExit(node);
    }
  }

  componentDidUpdate() {
    this.state = {open: this.props.in};
  }

  render() {
    const { className, datatype, icon1ClassName, icon2ClassName, title1, title2 } = this.props;
    const morphClassName = this.state.open ? 'morphed' : '';
    const buttonClassName = `btn  btn-outline btn-morph ${morphClassName} ${className}`;

    return (
      <div className="icon-morph-button">
        <button className={buttonClassName} onClick={() => this.onclick()} data-type={datatype}>
          <span className="morph-from" title={title1}><i className={icon1ClassName}/></span>
          <span className="morph-to" title={title2}><i className={icon2ClassName}/></span>
        </button>
      </div>
    );
  }
}

MorphButton.propTypes = propTypes;
MorphButton.defaultProps = defaultProps;

export default MorphButton;
