import babelHelpers from  '../util/babelHelpers';
import _utilCamelizeStyle from  '../util/camelizeStyle';

const _utilCamelizeStyle2 = babelHelpers.interopRequireDefault(_utilCamelizeStyle);

const rposition = /^(top|right|bottom|left)$/;
const rnumnonpx = /^([+-]?(?:\d*\.|)\d+(?:[eE][+-]?\d+|))(?!px)[a-z%]+$/i;

export default  function _getComputedStyle(node) {
  if (!node) {
    throw new TypeError('No Element passed to `getComputedStyle()`');
  }
  var doc = node.ownerDocument;

  return 'defaultView' in doc ?
    doc.defaultView.opener ?
    node.ownerDocument.defaultView.getComputedStyle(node, null) : window.getComputedStyle(node, null) : {
    //ie 8 "magic" from: https://github.com/jquery/jquery/blob/1.11-stable/src/css/curCSS.js#L72
    getPropertyValue: function getPropertyValue(prop) {
      const style = node.style;

      prop =  _utilCamelizeStyle2['default'](prop);

      if (prop === 'float') {
        prop = 'styleFloat';
      }

      var current = node.currentStyle[prop] || null;

      if (current == null && style && style[prop]) {
        current = style[prop];
      }

      if (rnumnonpx.test(current) && !rposition.test(prop)) {
        // Remember the original values
        const left = style.left;
        const runStyle = node.runtimeStyle;
        const rsLeft = runStyle && runStyle.left;

        // Put in the new values to get a computed value out
        if (rsLeft) {
          runStyle.left = node.currentStyle.left;
        }

        style.left = prop === 'fontSize' ? '1em' : current;
        current = style.pixelLeft + 'px';

        // Revert the changed values
        style.left = left;
        if (rsLeft) {
          runStyle.left = rsLeft;
        }
      }

      return current;
    }
  };
}