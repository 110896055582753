import mixam from '../../boot/mixam';
import defineComponent from '../../../components/flight/lib/component';
import Mustache from '../../../components/mustache/mustache';
import carttotal from './reprint-total';
import cartVat from './reprint-vat';
import WithNormalizeShoppingCartItems from '../with-normalize-shopping-items';
import WithSubmitAnimation from '../with-submit-zoom-animation';
import DiscountManager from "../shopping-cart/discount-manager";
import WithCatalogPaperTypes from '../home/with-catalog-paper-types';
import regional from '../../boot/regional';

import shopCatItemTemplate from 'text!../../../appes6/templates/share/shop-catItem.mustache';
import reorderPriceError from 'text!../../../appes6/templates/share/price-error.mustache';

export default defineComponent(ReprintManager, WithNormalizeShoppingCartItems, WithSubmitAnimation, WithCatalogPaperTypes);

function ReprintManager() {
    var santaDelay = 800;

    this.attributes({
        waitSelector: '.wait',
        containerSelector: '.sc-container',
        cartTotalSelector: '.cart-total',
        cartVatSelector: '[data-type="tax"]',
        copiesSelector: '[data-prop="copies"]',
        add2CartSelector: '[data-type="add2cart"]',
        cartTotalsSelector: '[data-type="cart-totals"]',
        cartDiscountSelector: '[data-type="shopping-cart-discount"]'
    });

    this.renderItems = function (items) {
        const list = {
            items: this.normalizeItems(items),
            dict: regional().santa.shoppingCart,
            formatNumber: function () {
                return mixam.mustachFormatNumber;
            }
        };
        return Mustache.render(shopCatItemTemplate, list);
    };

    this.open = function (event) {
        if (event.target.id === `reprintContainer-${this.orderId}-${this.itemId}` || this.item.id === $(event.target).data('item')) {
            this.select('copiesSelector').focus();
            this.onChange();
        }
    };

    this.fetchPriceFromMaria = function () {
        if (this.delayTimerId) {
            clearTimeout(this.delayTimerId);
        }
        this.delayTimerId = setTimeout(() => {
            this.delayTimerId = null;
            if (this.shareId) {
                this.select('waitSelector').addClass('active');

                $.ajax({
                    url: '/api/offer/share',
                    contentType: 'application/json',
                    type: 'POST',
                    data: JSON.stringify({shareId: this.shareId, copies: this.item.query.copies})
                }).done(
                    response => this.priceAvailable(response)
                ).fail(
                    response => this.priceError(response)
                );
            }

        }, santaDelay);
    };

    this.onChange = function () {
        var val = this.select('copiesSelector').val() ? +this.select('copiesSelector').val() : this.item.query.copies;

        if (this.item && val && !isNaN(val) && val !== this.copiesLastVal) {
            this.copiesLastVal = val;
            this.item.query.copies = this.copiesLastVal;
            this.fetchPriceFromMaria();
        }
    };

    /**
     * Sets the data for the reprint-manager when used on the share screen
     */
    this.setData = function (event, shareable) {
        this.item = shareable.items[0];
        this.shareId = shareable.id;
        // for rendering this will be set during normalisation, but for afterShoppingcartRender we need to set it here
        this.item.santa = this.item.query;
    };

    /**
     * Sets the data for the reprint-manager when used on the my-orders screen
     * dataShareItemReady = { id: item.id, order: order, item: item }
     */
    this.setItem = function (event, dataShareItemReady) {
        if (dataShareItemReady.order.id === this.orderId && dataShareItemReady.id === this.itemId) {
            this.shareId = dataShareItemReady.item.share.id;
            this.orderId = dataShareItemReady.order.id;
            this.item = dataShareItemReady.item;
            // for rendering this will be set during normalisation, but for afterShoppingcartRender we need to set it here
            this.item.santa = this.item.query;
        }
    };

    this.priceAvailable = function (data) {
        if(data.offerTable.length === 0) {
            this.select('waitSelector').removeClass('active');
            this.select('cartTotalsSelector').hide();
            this.select('containerSelector').html(Mustache.render(reorderPriceError));
        } else {

            /*
             * Always choose the first offer in the offer table.
             * This logic is flawed for 2 reasons:
             * 1. Destination is based on the original order, but once added to the cart it will be based on the new order.
             *      - therefore a closer suppliers offer may have won.
             *      - TODO receive updates from uiSetSantaPostCode and request the offer based on the instant-destination
             *         this will require the deliverManager to show delivery inclusive prices.
             *      - TODO alternatively we could blank out the destination and get the offer using the default destination
             *         this will allow the deliveryManager (and dellivery line) to be removed form reorder.
             * 2. It assumes the user wants the first offer in the table (i.e. cheapest).
             */

            var offer = data.offerTable[0];
            this.item.response = data;
            this.item.response.ntotal = offer.nprice;
            this.item.response.total = offer.price;
            this.item.response.pid = offer.pid;
            this.item.response.mid = offer.mid;
            this.item.response.isExpress = offer.isExpress;
            this.item.response.type = offer.type;
            this.item.response.days = offer.days;
            this.item.response.fulfilerName = offer.name;
            this.item.response.deliveryDate = offer.date;
            this.item.response.deliveryDateText = mixam.dateToLongString(offer.date);

            this.select('waitSelector').removeClass('active');
            this.select('containerSelector').html(this.renderItems([this.item]));
            this.select("copiesSelector").focus();

            this.trigger("reprintPriceAvailable", {item: this.item});
        }
    };

    this.priceError = function (data) {
        this.select('waitSelector').removeClass('active');
    };

    /**
     * Copies the values from the inner item form into the outer dupe form
     * then allows the dupe form to be full-page submitted.
     */
    this.handleOrderSubmit = function (event) {
       event.preventDefault();

       const that = this;

       const reOrderItem = {
            orderId: this.orderId,
            itemId: this.item.id,
            copies: this.item.query.copies,
            offerItemPid: this.item.response.pid,
            offerItemMid: this.item.response.mid,
            offerItemDays: this.item.response.days
        };

       // We Should Use The Window Event Really To Do This Re-Order Business But The JS (cart-indicator) File Is Not
        // Included On this Page
        fetch("/api/cart/items/reorder", {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify(reOrderItem)
        }).then(response =>
            response.json()
        ).then(cartResponse => {
                const event = new CustomEvent('reactCartUpdated', {
                    detail: { cartResponse }
                });
                window.dispatchEvent(event);
                that.trigger("dataStoreCartRefresh", {reason: "reorderItemAdded", cartResponse: cartResponse});
                that.trigger('showFixedMessage', {
                    type: 'warning',
                    body: regional().santa.shoppingCart.reprintAddedToCart,
                    interval: 10000
                });
            }
        ).catch(function(err) {
            that.trigger('showFixedMessage', { type: 'error', body: err, interval: 10000 });
        });

    };

    this.after('initialize', function () {
        this.shareId = this.$node.data("share");
        this.orderId = this.$node.data("order");
        this.itemId = this.$node.data("item");

        this.item = null;
        this.copiesLastVal = null;

        carttotal.attachTo(this.select('cartTotalSelector'));
        cartVat.attachTo(this.select('cartVatSelector'));
        DiscountManager.attachTo(this.select('cartDiscountSelector'));

        this.on(document, 'shown.bs.collapse', this.open);
        this.on(document, 'dataShareReady', this.setData);
        this.on(document, 'dataShareItemReady', this.setItem);

        this.on("click", {
            add2CartSelector: this.handleOrderSubmit
        });
        this.on("change", {
            copiesSelector: this.onChange
        });

        this.on("input", {
            copiesSelector: this.onChange
        });

    });
}
