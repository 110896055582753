import defineComponent from '../../../../components/flight/lib/component';
import AdminImageUpload from "../admin-image-upload";

export default defineComponent(ProductEditor);

function ProductEditor() {

    this.attributes({
        viewProductPageButton: '[data-type="view-product-page-btn"]',
        viewProductPageRequestUriSelector: '[data-type="product-page-request-uri"]',
        categoryImageUploadContainerSelector: '[data-type="admin-image-upload"]',
        categoryImageSelector: '[data-field="category-image-url"]',
        categoryImageInputSelector: '[data-field="category-image-url-input"]'
    });

    this.viewProductPage = function(event) {
        const requestPageUri = this.select('viewProductPageRequestUriSelector').val();
        window.open(`${requestPageUri}`, '_blank');
    };

    this.categoryImageUploadCallback = function(event, data) {
        this.select('categoryImageSelector').attr('src', data.url);
        this.select('categoryImageSelector').removeClass('d-none');
        this.select('categoryImageInputSelector').val(data.url);
    };

    this.after('initialize', function () {
        if(this.select('categoryImageUploadContainerSelector')) {
            AdminImageUpload.attachTo(this.select('categoryImageUploadContainerSelector'));
            this.on('imageUploadSuccess', this.categoryImageUploadCallback);
        }
        this.on('click', {
            viewProductPageButton: this.viewProductPage
        });
    });
}
