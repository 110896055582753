var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useState, useMemo, useEffect, useCallback } from 'react';
import * as S from './StyledSearchBuilder';
import { FILTER_OPTIONS, FILTER_METHODS, FILTERABLE_DATA_TYPES, FILTER_METHODS_NO_INPUT, FILTER_METHODS_TWO_INPUTS, FILTER_METHODS_DATE, FILTER_METHODS_TWO_DATES } from '../../DataTableConstants';
import { checkObjContainsValue, round } from '../../util';
var Dropdown = function (_a) {
    var options = _a.options, onChange = _a.onChange, isDefaultSelected = _a.isDefaultSelected, disabled = _a.disabled, defaultValue = _a.defaultValue;
    return (React.createElement("select", { className: "form-select", onChange: onChange, defaultValue: defaultValue, style: { fontStyle: isDefaultSelected ? 'italic' : 'normal' }, disabled: disabled }, options.map(function (_a) {
        var data = _a.data, text = _a.text, type = _a.type;
        var isDefault = data === 'default';
        return (React.createElement("option", { "data-type": type, value: data, key: text, disabled: isDefault, hidden: isDefault }, text));
    })));
};
var doFilterNumber = function (data, filterMethod, columnToFilter, userInput, columnData, userInput2) {
    var filteredItems;
    var decimal = columnData.find(function (col) { return col.data === columnToFilter; }).decimal;
    switch (filterMethod) {
        case FILTER_METHODS.EQUALS:
            filteredItems = data.filter(function (item) {
                return (round(item[columnToFilter], decimal) ==
                    round(userInput, decimal));
            });
            break;
        case FILTER_METHODS.NOT:
            filteredItems = data.filter(function (item) {
                return (round(item[columnToFilter], decimal) !=
                    round(userInput, decimal));
            });
            break;
        case FILTER_METHODS.LESS_THAN:
            filteredItems = data.filter(function (item) { return item[columnToFilter] && item[columnToFilter] < userInput; });
            break;
        case FILTER_METHODS.LESS_THAN_EQUAL_TO:
            filteredItems = data.filter(function (item) { return item[columnToFilter] && item[columnToFilter] <= userInput; });
            break;
        case FILTER_METHODS.GREATER_THAN:
            filteredItems = data.filter(function (item) { return item[columnToFilter] && item[columnToFilter] > userInput; });
            break;
        case FILTER_METHODS.GREATER_THAN_EQUAL_TO:
            filteredItems = data.filter(function (item) { return item[columnToFilter] && item[columnToFilter] >= userInput; });
            break;
        case FILTER_METHODS.BETWEEN:
            filteredItems = data.filter(function (item) {
                return item[columnToFilter] && (item[columnToFilter] > userInput &&
                    item[columnToFilter] < userInput2);
            });
            break;
        case FILTER_METHODS.NOT_BETWEEN:
            filteredItems = data.filter(function (item) {
                return item[columnToFilter] && (item[columnToFilter] < userInput ||
                    item[columnToFilter] > userInput2);
            });
            break;
        case FILTER_METHODS.EMPTY:
            filteredItems = data.filter(function (item) { return !item[columnToFilter]; });
            break;
        case FILTER_METHODS.NOT_EMPTY:
            filteredItems = data.filter(function (item) { return item[columnToFilter]; });
            break;
        default:
            filteredItems = data;
    }
    return filteredItems;
};
var doFilterString = function (data, filterMethod, columnToFilter, columnToFilterDataKey, userInput) {
    var filteredItems;
    switch (filterMethod) {
        case FILTER_METHODS.EQUALS:
            filteredItems = data.filter(function (item) {
                return item[columnToFilter] && typeof item[columnToFilter] === 'object' && item[columnToFilter].hasOwnProperty(columnToFilterDataKey) && item[columnToFilter][columnToFilterDataKey] ?
                    item[columnToFilter][columnToFilterDataKey].toString().toLowerCase() === userInput.toLowerCase() :
                    item[columnToFilter] && item[columnToFilter].toString().toLowerCase() === userInput.toLowerCase();
            });
            break;
        case FILTER_METHODS.NOT:
            filteredItems = data.filter(function (item) {
                return item[columnToFilter] && typeof item[columnToFilter] === 'object' && item[columnToFilter].hasOwnProperty(columnToFilterDataKey) && item[columnToFilter][columnToFilterDataKey] ?
                    item[columnToFilter] && item[columnToFilter][columnToFilterDataKey].toString().toLowerCase() !== userInput.toLowerCase() :
                    item[columnToFilter] && item[columnToFilter].toString().toLowerCase() !== userInput.toLowerCase();
            });
            break;
        case FILTER_METHODS.CONTAINS:
            filteredItems = data.filter(function (item) {
                return item[columnToFilter] && typeof item[columnToFilter] === 'object' && item[columnToFilter].hasOwnProperty(columnToFilterDataKey) && item[columnToFilter][columnToFilterDataKey] ?
                    item[columnToFilter] && item[columnToFilter][columnToFilterDataKey].toString().toLowerCase().includes(userInput.toLowerCase()) :
                    item[columnToFilter] && item[columnToFilter].toString().toLowerCase().includes(userInput.toLowerCase());
            });
            break;
        case FILTER_METHODS.DOES_NOT_CONTAIN:
            filteredItems = data.filter(function (item) {
                return item[columnToFilter] && typeof item[columnToFilter] === 'object' && item[columnToFilter].hasOwnProperty(columnToFilterDataKey) && item[columnToFilter][columnToFilterDataKey] ?
                    item[columnToFilter] && !item[columnToFilter][columnToFilterDataKey].toString().toLowerCase().includes(userInput.toLowerCase()) :
                    item[columnToFilter] && !item[columnToFilter].toString().toLowerCase().includes(userInput.toLowerCase());
            });
            break;
        case FILTER_METHODS.STARTS_WTIH:
            filteredItems = data.filter(function (item) {
                return item[columnToFilter] && typeof item[columnToFilter] === 'object' && item[columnToFilter].hasOwnProperty(columnToFilterDataKey) && item[columnToFilter][columnToFilterDataKey] ?
                    item[columnToFilter] && item[columnToFilter][columnToFilterDataKey].toString().toLowerCase().startsWith(userInput.toLowerCase()) :
                    item[columnToFilter] && item[columnToFilter].toString().toLowerCase().startsWith(userInput.toLowerCase());
            });
            break;
        case FILTER_METHODS.DOES_NOT_START_WITH:
            filteredItems = data.filter(function (item) {
                return item[columnToFilter] && typeof item[columnToFilter] === 'object' && item[columnToFilter].hasOwnProperty(columnToFilterDataKey) && item[columnToFilter][columnToFilterDataKey] ?
                    item[columnToFilter] && !item[columnToFilter][columnToFilterDataKey].toString().toLowerCase().startsWith(userInput.toLowerCase()) :
                    item[columnToFilter] && !item[columnToFilter].toString().toLowerCase().startsWith(userInput.toLowerCase());
            });
            break;
        case FILTER_METHODS.ENDS_WITH:
            filteredItems = data.filter(function (item) {
                return item[columnToFilter] && typeof item[columnToFilter] === 'object' && item[columnToFilter].hasOwnProperty(columnToFilterDataKey) && item[columnToFilter][columnToFilterDataKey] ?
                    item[columnToFilter] && item[columnToFilter][columnToFilterDataKey].toString().toLowerCase().endsWith(userInput.toLowerCase()) :
                    item[columnToFilter] && item[columnToFilter].toString().toLowerCase().endsWith(userInput.toLowerCase());
            });
            break;
        case FILTER_METHODS.DOES_NOT_END_WTIH:
            filteredItems = data.filter(function (item) {
                return item[columnToFilter] && typeof item[columnToFilter] === 'object' && item[columnToFilter].hasOwnProperty(columnToFilterDataKey) && item[columnToFilter][columnToFilterDataKey] ?
                    item[columnToFilter] && !item[columnToFilter][columnToFilterDataKey].toString().toLowerCase().endsWith(userInput.toLowerCase()) :
                    item[columnToFilter] && !item[columnToFilter].toString().toLowerCase().endsWith(userInput.toLowerCase());
            });
            break;
        case FILTER_METHODS.EMPTY:
            filteredItems = data.filter(function (item) {
                return item[columnToFilter] && typeof item[columnToFilter] === 'object' && item[columnToFilter].hasOwnProperty(columnToFilterDataKey) && item[columnToFilter][columnToFilterDataKey] ?
                    !item[columnToFilter][columnToFilterDataKey] :
                    !item[columnToFilter];
            });
            break;
        case FILTER_METHODS.NOT_EMPTY:
            filteredItems = data.filter(function (item) {
                return item[columnToFilter] && typeof item[columnToFilter] === 'object' && item[columnToFilter].hasOwnProperty(columnToFilterDataKey) && item[columnToFilter][columnToFilterDataKey] ?
                    item[columnToFilter][columnToFilterDataKey] :
                    item[columnToFilter];
            });
            break;
        default:
            filteredItems = data;
    }
    return filteredItems;
};
var doFilterDate = function (data, filterMethod, columnToFilter, userInput, columnData, userInput2) {
    var filteredItems;
    switch (filterMethod) {
        case FILTER_METHODS.DATE_EQUALS:
            filteredItems = data.filter(function (item) {
                if (item[columnToFilter] && userInput) {
                    var userInputDate = new Date(userInput);
                    var dataDate = new Date(item[columnToFilter]);
                    return userInputDate.getFullYear() === dataDate.getFullYear() &&
                        userInputDate.getMonth() === dataDate.getMonth() &&
                        userInputDate.getDate() === dataDate.getDate();
                }
                return false;
            });
            break;
        case FILTER_METHODS.DATE_LESS_THAN:
            filteredItems = data.filter(function (item) {
                if (item[columnToFilter] && userInput) {
                    var userInputDate = new Date(userInput);
                    var dataDate = new Date(item[columnToFilter]);
                    return dataDate < userInputDate;
                }
                return false;
            });
            break;
        case FILTER_METHODS.DATE_LESS_THAN_EQUAL_TO:
            filteredItems = data.filter(function (item) {
                if (item[columnToFilter] && userInput) {
                    var userInputDate = new Date(userInput);
                    var dataDate = new Date(item[columnToFilter]);
                    return dataDate < userInputDate ||
                        (userInputDate.getFullYear() === dataDate.getFullYear() &&
                            userInputDate.getMonth() === dataDate.getMonth() &&
                            userInputDate.getDate() === dataDate.getDate());
                }
                return false;
            });
            break;
        case FILTER_METHODS.DATE_GREATER_THAN:
            filteredItems = data.filter(function (item) {
                if (item[columnToFilter] && userInput) {
                    var userInputDate = new Date(userInput);
                    var dataDate = new Date(item[columnToFilter]);
                    return dataDate > userInputDate;
                }
                return false;
            });
            break;
        case FILTER_METHODS.DATE_GREATER_THAN_EQUAL_TO:
            filteredItems = data.filter(function (item) {
                if (item[columnToFilter] && userInput) {
                    var userInputDate = new Date(userInput);
                    var dataDate = new Date(item[columnToFilter]);
                    return dataDate > userInputDate ||
                        (userInputDate.getFullYear() === dataDate.getFullYear() &&
                            userInputDate.getMonth() === dataDate.getMonth() &&
                            userInputDate.getDate() === dataDate.getDate());
                }
                return false;
            });
            break;
        case FILTER_METHODS.DATE_BETWEEN:
            filteredItems = data.filter(function (item) {
                if (item[columnToFilter] && userInput && userInput2) {
                    var userInputDateFrom = new Date(userInput);
                    var userInputDateToo = new Date(userInput2);
                    var dataDate = new Date(item[columnToFilter]);
                    return dataDate > userInputDateFrom && dataDate < userInputDateToo;
                }
                return false;
            });
            break;
        case FILTER_METHODS.EMPTY:
            filteredItems = data.filter(function (item) { return !item[columnToFilter]; });
            break;
        case FILTER_METHODS.NOT_EMPTY:
            filteredItems = data.filter(function (item) { return item[columnToFilter]; });
            break;
        default:
            filteredItems = data;
    }
    return filteredItems;
};
var doFilterBoolean = function (data, filterMethod, columnToFilter) {
    var filteredItems;
    switch (filterMethod) {
        case FILTER_METHODS.TRUE:
            filteredItems = data.filter(function (item) { return item[columnToFilter]; });
            break;
        case FILTER_METHODS.FALSE:
            filteredItems = data.filter(function (item) { return !item[columnToFilter]; });
            break;
        default:
            filteredItems = data;
    }
    return filteredItems;
};
var SearchBuilderRow = function (_a) {
    var data = _a.data, columns = _a.columns, setFilteredData = _a.setFilteredData, eventFilterText = _a.eventFilterText, eventFilterColumn = _a.eventFilterColumn, eventFilterType = _a.eventFilterType, eventFilterMethod = _a.eventFilterMethod;
    var _b = useState(''), columnToFilter = _b[0], setColumnToFilter = _b[1];
    var _c = useState(FILTER_OPTIONS.stringTypeOptions), filterMethodOptions = _c[0], setFilterMethodOptions = _c[1];
    var _d = useState(''), filterMethod = _d[0], setFilterMethod = _d[1];
    var _e = useState(''), userInput = _e[0], setUserInput = _e[1];
    var _f = useState(''), userInput2 = _f[0], setUserInput2 = _f[1];
    var _g = useState(''), colDataType = _g[0], setColDataType = _g[1];
    var cols = useMemo(function () {
        // format column data ready to be used in Dropdown component
        var cols = columns.map(function (col) {
            // column type must be in FILTERABLE_DATA_TYPES to be presented as a filter option to user
            if (!checkObjContainsValue(FILTERABLE_DATA_TYPES, col.type)) {
                return;
            }
            return { text: col.title, data: col.data, type: col.type };
        });
        cols = cols.filter(function (col) { return col; });
        cols.unshift({
            text: 'Select Filter Column',
            data: 'default',
        });
        return cols;
    }, [columns]);
    useEffect(function () {
        setColDataType(eventFilterType);
        setUserInput(eventFilterText);
        setColumnToFilter(eventFilterColumn);
        setFilterMethod(eventFilterMethod);
    }, [eventFilterText, eventFilterColumn, eventFilterType, eventFilterMethod]);
    useEffect(function () {
        if (!filterMethod) {
            return;
        }
        if (!userInput && !FILTER_METHODS_NO_INPUT.includes(filterMethod)) {
            // if user has not entered anything then all data is shown, if the query requires no input (e.g. IS_EMPTY) then they skip this if
            setFilteredData(data);
            return;
        }
        if (FILTER_METHODS_TWO_INPUTS.includes(filterMethod) && !userInput2) {
            return;
        }
        if (FILTER_METHODS_TWO_DATES.includes(filterMethod) && !userInput2) {
            return;
        }
        var filteredData;
        if (colDataType === FILTERABLE_DATA_TYPES.TEXT) {
            var columnToFilterDataKey = null;
            filteredData = doFilterString(data, filterMethod, columnToFilter, columnToFilterDataKey, userInput);
        }
        if (colDataType === FILTERABLE_DATA_TYPES.NUMBER) {
            filteredData = doFilterNumber(data, filterMethod, columnToFilter, userInput, columns, userInput2);
        }
        if (colDataType === FILTERABLE_DATA_TYPES.BOOLEAN) {
            filteredData = doFilterBoolean(data, filterMethod, columnToFilter);
        }
        if (colDataType === FILTERABLE_DATA_TYPES.USER) {
            var columnToFilterDataKey = 'name';
            filteredData = doFilterString(data, filterMethod, columnToFilter, columnToFilterDataKey, userInput);
        }
        if (colDataType === FILTERABLE_DATA_TYPES.BADGE) {
            var columnToFilterDataKey = 'label';
            filteredData = doFilterString(data, filterMethod, columnToFilter, columnToFilterDataKey, userInput);
        }
        if (colDataType === FILTERABLE_DATA_TYPES.SHOW_MORE_TEXT) {
            var columnToFilterDataKey = 'text';
            filteredData = doFilterString(data, filterMethod, columnToFilter, columnToFilterDataKey, userInput);
        }
        if (colDataType === FILTERABLE_DATA_TYPES.LINK) {
            var columnToFilterDataKey = 'caption';
            filteredData = doFilterString(data, filterMethod, columnToFilter, columnToFilterDataKey, userInput);
        }
        if (colDataType === FILTERABLE_DATA_TYPES.DATE) {
            filteredData = doFilterDate(data, filterMethod, columnToFilter, userInput, columns, userInput2);
        }
        if (colDataType === FILTERABLE_DATA_TYPES.DATETIME) {
            filteredData = doFilterDate(data, filterMethod, columnToFilter, userInput, columns, userInput2);
        }
        if (colDataType === FILTERABLE_DATA_TYPES.TIMEBOX) {
            filteredData = doFilterDate(data, filterMethod, columnToFilter, userInput, columns, userInput2);
        }
        setFilteredData(filteredData);
    }, [columnToFilter, filterMethod, userInput, userInput2, data]);
    useEffect(function () {
        if (eventFilterText)
            return;
        setUserInput('');
        setUserInput2('');
        // set filter method options (condition) depending on the column data type
        switch (colDataType) {
            case FILTERABLE_DATA_TYPES.TEXT:
            case FILTERABLE_DATA_TYPES.USER:
            case FILTERABLE_DATA_TYPES.BADGE:
            case FILTERABLE_DATA_TYPES.SHOW_MORE_TEXT:
            case FILTERABLE_DATA_TYPES.LINK:
                setFilterMethodOptions(FILTER_OPTIONS.stringTypeOptions);
                break;
            case FILTERABLE_DATA_TYPES.NUMBER:
                setFilterMethodOptions(FILTER_OPTIONS.numberTypeOptions);
                break;
            case FILTERABLE_DATA_TYPES.BOOLEAN:
                setFilterMethodOptions(FILTER_OPTIONS.booleanTypeOptions);
                break;
            case FILTERABLE_DATA_TYPES.DATE:
            case FILTERABLE_DATA_TYPES.DATETIME:
            case FILTERABLE_DATA_TYPES.TIMEBOX:
                setFilterMethodOptions(FILTER_OPTIONS.dateTypeOptions);
                break;
        }
    }, [colDataType]);
    var generateUserInput = useCallback(function () {
        var UserInput;
        if (FILTER_METHODS_NO_INPUT.includes(filterMethod) || colDataType === FILTERABLE_DATA_TYPES.BOOLEAN) {
            UserInput = React.createElement(React.Fragment, null);
        }
        else if (FILTER_METHODS_TWO_INPUTS.includes(filterMethod)) {
            UserInput = (React.createElement("div", { className: "input-group" },
                React.createElement("input", { type: "text", value: userInput, onChange: function (e) { return setUserInput(e.target.value); }, disabled: filterMethod === '', className: "form-control" }),
                React.createElement("div", { className: "mx-2" }, "and"),
                React.createElement("input", { type: "text", value: userInput2, onChange: function (e) { return setUserInput2(e.target.value); }, disabled: filterMethod === '', className: "form-control" })));
        }
        else if (FILTER_METHODS_DATE.includes(filterMethod)) {
            UserInput = (React.createElement("div", { className: "input-group" },
                React.createElement("input", { className: "form-control ", type: "date", value: userInput, onChange: function (e) { return setUserInput(e.target.value); }, disabled: filterMethod === '', placeholder: "Value" })));
        }
        else if (FILTER_METHODS_TWO_DATES.includes(filterMethod)) {
            UserInput = (React.createElement("div", { className: "input-group" },
                React.createElement("input", { className: "form-control ", type: "date", value: userInput, onChange: function (e) { return setUserInput(e.target.value); }, disabled: filterMethod === '', placeholder: "Value" }),
                React.createElement("div", { className: "mx-2" }, "and"),
                React.createElement("input", { className: "form-control ", type: "date", value: userInput2, onChange: function (e) { return setUserInput2(e.target.value); }, disabled: filterMethod === '', placeholder: "Value" })));
        }
        else {
            UserInput = (React.createElement("div", { className: "input-group" },
                React.createElement("input", { className: "form-control ", type: "text", value: userInput, onChange: function (e) { return setUserInput(e.target.value); }, disabled: filterMethod === '', placeholder: "Value" })));
        }
        return UserInput;
    }, [colDataType, filterMethod, userInput, userInput2]);
    return (React.createElement(React.Fragment, null,
        React.createElement(Dropdown, { options: cols, onChange: function (e) {
                setColDataType(e.target[e.target.selectedIndex].getAttribute('data-type'));
                setColumnToFilter(e.target.value);
            }, isDefaultSelected: columnToFilter === '', defaultValue: eventFilterText ? eventFilterColumn : 'default' }),
        React.createElement(Dropdown, { options: filterMethodOptions, onChange: function (e) { return setFilterMethod(e.target.value); }, isDefaultSelected: filterMethod === '', disabled: columnToFilter === '', defaultValue: eventFilterText ? FILTER_METHODS.EQUALS : 'default' }),
        generateUserInput()));
};
var SearchBuilder = function (_a) {
    var data = _a.data, columns = _a.columns, setFilteredData = _a.setFilteredData;
    var _b = useState([]), conditionsOpen = _b[0], setConditionsOpen = _b[1];
    var _c = useState([]), allQuerysData = _c[0], setAllQuerysData = _c[1];
    var _d = useState(''), eventFilterText = _d[0], setEventFilterText = _d[1];
    var _e = useState(''), eventFilterColumn = _e[0], setEventFilterColumn = _e[1];
    var _f = useState(''), eventFilterType = _f[0], setEventFilterType = _f[1];
    var _g = useState(''), eventFilterMethod = _g[0], setEventFilterMethod = _g[1];
    var numConditionsClosed = conditionsOpen.filter(function (item) { return !item; }).length;
    useEffect(function () {
        window.addEventListener('dataTableCustomFilterEvent', function (event) { return handleFilterEvent(event.detail.filterColumn, event.detail.filterText, event.detail.filterType, event.detail.filterMethod); });
    }, []);
    useEffect(function () {
        if (conditionsOpen.length === 0) {
            setFilteredData(data);
        }
    }, [data]);
    var handleFilterEvent = function (filterColumn, filterText, filterType, filterMethod) {
        setConditionsOpen([true]);
        setEventFilterText(filterText);
        setEventFilterColumn(filterColumn);
        setEventFilterType(filterType);
        setEventFilterMethod(filterMethod);
    };
    useEffect(function () {
        if (allQuerysData.length === 0) {
            setFilteredData(data);
            return;
        }
        var filteredData = allQuerysData.reduce(function (array1, array2) {
            return array1.filter(function (_a) {
                var id = _a.id;
                return array2.some(function (_a) {
                    var id2 = _a.id;
                    return id === id2;
                });
            });
        });
        setFilteredData(filteredData);
    }, [allQuerysData]);
    var combineFilteredData = function (data, queryIndex) {
        if (!data) {
            return;
        }
        var tempAllQuerysData = __spreadArray([], allQuerysData, true);
        tempAllQuerysData[queryIndex - numConditionsClosed] = data;
        tempAllQuerysData = tempAllQuerysData.filter(function (item) { return item; });
        setAllQuerysData(tempAllQuerysData);
    };
    var removeCondition = function (queryIndex, totalConditionsIndex) {
        if (eventFilterText)
            setEventFilterText('');
        var tempAllQuerysData = __spreadArray([], allQuerysData, true);
        tempAllQuerysData.splice(queryIndex, 1);
        setAllQuerysData(tempAllQuerysData);
        var temp = __spreadArray([], conditionsOpen, true);
        temp[totalConditionsIndex] = false;
        setConditionsOpen(temp);
    };
    var handleAddCondition = function () {
        var temp = __spreadArray([], conditionsOpen, true);
        temp.push(true);
        setConditionsOpen(temp);
    };
    var openConditionsIndex = -1;
    return (React.createElement(S.Wrapper, { className: "flex-grow-1 pe-3 d-flex flex-column", style: { gap: "0.5rem" } },
        React.createElement("h4", null, "Advanced Search"),
        conditionsOpen.map(function (isOpen, i) {
            if (isOpen)
                openConditionsIndex++;
            return !isOpen ? React.createElement(React.Fragment, null) : (React.createElement("div", { key: i, className: !isOpen ? 'closed' : '' },
                React.createElement(S.FilterRow, null,
                    React.createElement(SearchBuilderRow, { eventFilterColumn: eventFilterColumn, eventFilterText: eventFilterText, eventFilterType: eventFilterType, eventFilterMethod: eventFilterMethod, data: data, columns: columns, setFilteredData: function (data) {
                            return combineFilteredData(data, i);
                        } }),
                    React.createElement("button", { onClick: function () { return removeCondition(openConditionsIndex, i); }, className: "btn btn-danger", type: "button" }, "X"))));
        }),
        !eventFilterText && React.createElement("div", null,
            React.createElement("button", { onClick: handleAddCondition, className: "btn btn-primary", type: "button" },
                React.createElement("i", { className: "bi bi-search" }),
                "\u00A0Add Condition"))));
};
export default SearchBuilder;
