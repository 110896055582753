import defineComponent from '../../../components/flight/lib/component';
import Review from './review';

export default defineComponent(TrustPilot);

function TrustPilot() {

    this.attributes({
        interval: 10 * 1000,
        reviewsSelector: ".reviews",
        trustpilotSelector: ".trustpilot"
    });

    this.clock = function () {
        clearTimeout(this.timer);
        if (!this.hasMouse) {
            this.trigger("reviewNext");
        }
        this.timer = setTimeout(() => this.clock(), this.attr.interval);
    };

    this.mouseEnter = function (event) {
        this.hasMouse = true;
    };

    this.mouseLeave = function (event) {
        this.hasMouse = false;
    };

    this.onScroll = function(event, data) {
        if (!this.done) {
            if (this.top <= data.offset) {
                this.done = true;
                this.select('reviewsSelector').addClass("active");
                this.timer = setTimeout(() => this.clock(), this.attr.interval);
            }
        }
    };
    this.onResize = function () {
        this.top = this.node.offsetTop - window.innerHeight + this.node.offsetHeight / 2;
        this.onScroll(null, {offset: document.documentElement.scrollTop || document.body.scrollTop});
    };

    this.after('initialize', function () {
        var i = 4;

        this.on("mouseenter", this.mouseEnter);
        this.on("mouseleave", this.mouseLeave);
        Review.attachTo(this.select('reviewsSelector'), {
            delay: 1 * 250
        });
        $(window).resize($.proxy(this.onResize, this));
        this.on(document, "uiPageScroll", this.onScroll);
        this.onResize();
    });
}
