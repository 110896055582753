import Mustache from '../../../components/mustache/mustache';
import discountTemplate from 'text!../../../appes6/templates/shop/shop-cat-discount.mustache';
import defineComponent from '../../../components/flight/lib/component';
import WithOffersDisplayList from './../with-offers-display-list';
import WithCustomerDiscount from "./../with-customer-discount";

import regional from '../../boot/regional';

export default defineComponent(DiscountManager, WithOffersDisplayList, WithCustomerDiscount);


function DiscountManager() {

    this.attributes({
        contentSelector: '[data-type="data-type="content"]'
    });

    this.render = function (data) {
        return Mustache.render(discountTemplate, data);
    };

    this.normalizeItems = function (items) {
        let hasDiscount = false;
        let discountTotal = 0;
        const items_ = items.map((item, i) => {
            const item_ = $.extend(true, {}, item);
            item_.price = item.response.total;
            item_.newPrice = this.applyDiscount(item_.price, item.query || item.santa);
            if (item_.price !== item_.newPrice) {
                const discountSum = item_.price - item_.newPrice;
                hasDiscount = true;
                item_.discountSum = (discountSum).formatNumber(2);
                item_.discountPercent = (discountSum / item_.price * 100).formatNumber(2);
                discountTotal += discountSum;
            }
            item_.index = i + 1;
            return item_;
        });

        return {
            items: items_,
            hasDiscount: hasDiscount,
            discountTotal: discountTotal, //do not format
            dict: {
                item: regional().santa.shoppingCart.item,
                discount: regional().santa.shoppingCart.discountOn
            }
        };
    };

    this.paint = function () {
        if (this.items && this.items.length) {
            const data = this.normalizeItems(this.items);
            this.$node.html(this.render(data));
            if (data.hasDiscount) {
                this.$node.removeClass('hidden');
            } else {
                this.$node.addClass('hidden');
            }
            this.trigger("afterDiscountRender", data);
        }
    };

    this.afterRender = function (event, data) {
        this.items = data.items;
        this.paint();
    };

    this.reprintPriceAvailable = function (event, data) {
        this.items = [data.item];
        this.paint();
    };

    this.after('initialize', function () {
        this.on(document, 'afterShoppingcartRender', this.afterRender);
        this.on(document, 'reprintPriceAvailable', this.reprintPriceAvailable);
    });
}
