export default {
    DESTINATION_BASED: 0,
    ORIGIN_BASED: 1,

    getNexusCollectionTypeLabel: function (nexusCollectionType) {
        switch (parseInt(nexusCollectionType)) {
            case 0: return 'Destination Based';
            case 1: return 'Origin Based';
        }
    }
};
