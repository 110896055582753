import mixam from '../../../../boot/mixam';
import defineComponent from '../../../../../components/flight/lib/component';
import DataTable from '../../../table/data-table-net/data-table-net.tsx';


export default defineComponent( AdminSharesManager);


function AdminSharesManager() {

    this.attributes({
        url: "/admin/api/shares/list",
        dataTableSelector: '[data-type="data-table"]'
    });

    this.createSchema = function () {
        const cols = [];

        cols.push({
            title: '',
            type: "thumbnail",
            data: "front"
        });
        cols.push({
            title: "Order",
            type: "link",
            data: "order"
        });

       cols.push({
            title: "Title",
            type: "text",
            width: "15%",
            data: "title"
        });

       cols.push({
            title: "Customer",
            type: "text",
            data: "memberId"
        });

        cols.push({
            title: "Created",
            type: "datetime",
            data: "dateCreated"
        });

        cols.push({
            title: "Last Viewed",
            type: "datetime",
            data: "dateModified"
        });

        cols.push({
            title: "Views",
            type: "number",
            data: "views",
            sum: true
        });

        return cols;
    };

    this.normalize = function (list) {
        return list.map(x => this.normalizeShare(x));
    };

    this.normalizeShare = function (share_) {
        const share = $.extend(true, {}, share_);
        share.override = share.override || {};
        share.override.td = share.override.td || {};

        if (share.orderId) {
            share.order = {
                href: `/orders/${share.orderId}/artwork`,
                caption: share.orderCaseNumber || 'Order',
                title: share.orderId
            };
        }

        if (share.image) {
            share.front = {
                src: share.image,
                href: `/orders/${share.orderId}/artwork`
            };
        }

        return share;
    };

    this.getData = function () {
        $.getJSON(this.attr.url)
            .done(data => {
                this.data = data;
                this.paint();
                setTimeout(() => this.getData(), 1000 * 60 * 15);
            })
            .fail((err) => this.trigger("log", {message: err}));
    };

    this.paint = function () {
        const data = {
            columns: this.createSchema(),
            fileName: this.attr.url.split("/").pop(),
            response: this.normalize(this.data.list),
            "class": 'table-striped table-order-list',
            "default-sort": {
                "views": -1
            }
        };

        if (this.dataReadyFired) {
            window.dispatchEvent(new CustomEvent('uiDataUpdate', { detail: data }));
        } else {
            this.dataReadyFired = true;
            this.trigger("uiDataReady", data);
        }
    };

    /**
     * subscribe to STOMP service that notify us about changes
     * in a specific invoice
     */
    this.subscribe = function (onMessage) {
        this.trigger("log", {message: ["Subscribe to stomp channel allshareschanel"], title: "AdminSharesManager.subscribe"});
        this.stomp = Stomp.client(mixam.stompServiceUrl);

        this.stomp.debug = (...args) => {
            if (args.join('').indexOf('Whoops! Lost connection to') !== -1) {
                setTimeout(() => this.subscribe(onMessage), 10);
            }
            this.trigger("log", {message: args, title: "AdminSharesManager.subscribe"});
        };
        this.stomp.connect(mixam.stompWebUser, mixam.stompWebPass, () => {
            this.stomp.subscribe("/topic/allshareschanel", (d) => {
                const p = JSON.parse(d.body);
                onMessage(p);
            });
        }, (err) => this.trigger("log", err), '/');
    };

    this.updateShare = function (data) {
        if (!this.data) {
            return;
        }

        let verb = data.type;
        const share = data.data;
        if (share.shopId !== mixam.shop.id) {
            return;
        }

        const index = this.data.list.map(x => x.id).indexOf(share.id);

        if (verb === "UPDATE" || verb === "INSERT") {
            if (index !== -1) {
                this.data.list[index] = share;
            } else {
                this.data.list.unshift(share);
            }

        } else if (verb === "DELETE" && index !== -1) {
            this.data.list.splice(index, 1);
        }

        this.paint();
        requestAnimationFrame(() => $("#PK" + share.id).hilightTableLine());
    };

    this.after('initialize', function () {
        DataTable.attachTo(this.select('dataTableSelector'));
        setTimeout(() => this.getData(), 10);


        try {
            this.subscribe((o) => this.updateShare(o));
        } catch (e) {

        }

    });
}