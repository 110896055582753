export default {
    secondaryFormat: {
        "1": {width: 203, height: 127},
        "2": {width: 197, height: 132},
        "3": {width: 216, height: 138},
        "4": {width: 229, height: 152},
        "5": {width: 234, height: 156},
        "6": {width: 210, height: 210},
        "7": {width: 280, height: 210},
        "8": {width: 100, height: 210},
        "9": {width: 105, height: 210},
        "10": {width: 105, height: 297},
        "11": {width: 148, height: 148},
        "12": {width: 105, height: 105},
        "13": {width: 120, height: 120},
        "14": {width: 420, height: 1188},
        "15": {width: 297, height: 840},
        "16": {width: 99,  height: 210},
        "17": {width: 707, height: 1000},
        "18": {width: 500, height: 707},
        "19": {width: 183, height: 273},
        "20": {width: 150, height: 265},
        "21": {width: 100, height: 100},
        "22": {width: 55,  height: 85},
        "23": {width: 220, height: 110},
        "24": {width: 229, height: 162},
        "25": {width: 229, height: 324},
        "26": {width: 162, height: 114},
        "27": {width: 155, height: 155},
        "28": {width: 170, height: 170},
        "29": {width: 229, height: 114},
        "30": {width: 50, height: 90},
        "31": {width: 54, height: 86},
        "32": {width: 55, height: 55},
        "33": {width: 175, height: 245},
        "34": {width: 248, height: 346},
        "35": {width: 98, height: 98},
        "36": {width: 74, height: 210},
        "37": {width: 52, height: 149},
        "38": {width: 100, height: 141},
        "39": {width: 170, height: 590},
        "40": {width: 1185, height: 1750},
        "41": {width: 74, height: 98},
        "42": {width: 170, height: 240},
        "43": {width: 508, height:  762},
        "44": {width: 762, height: 1016},
        "45": {width: 1016, height: 1524},
        "46": { width: 140, height: 216},
        "47": {width: 216, height: 279},
        "48": {width: 216, height: 559},
        "49": {width: 108, height: 140},
        "50": {width: 102, height: 152},
        "51": {width: 127, height: 178},
        "52": {width: 152, height: 229},
        "53": {width: 203, height: 254},
        "54": {width: 152, height: 279},
        "55": {width: 279, height: 432},
        "56": {width: 152, height: 152},
        "57": {width: 305, height: 305},
        "58": {width: 121, height: 121},
        "59": {width: 305, height: 457},
        "60": {width: 330, height: 483},
        "61": {width: 457, height: 610},
        "62": {width: 483, height: 686},
        "63": {width: 610, height: 914},
        "64": {width: 660, height: 991},
        "65": {width: 216, height: 356},
        "66": {width: 157, height: 240},
        "67": {width: 170, height: 260},
        "68": {width: 127, height: 191},
        "69": {width: 127, height: 203},
        "70": {width: 129, height: 198},
        "71": {width: 133, height: 203},
        "72": {width: 156, height: 234},
        "73": {width: 170, height: 244},
        "74": {width: 178, height: 254},
        "75": {width: 189, height: 246},
        "76": {width: 191, height: 235},
        "77": {width: 152, height: 210},
        "78": {width: 210, height: 210},
        "79": {width: 216, height: 216},
        "80": {width: 178, height: 178},
        "81": {width: 140, height: 140},
        "82": {width: 125, height: 176},
        "83": {width: 152, height: 229},
        "84": {width: 51, height: 89},
        "85": {width: 89, height: 140},
        "86": {width: 108, height: 152},
        "87": {width: 203, height: 279},
        "88": {width: 279, height: 356},
        "89": {width: 203, height: 203},
        "90": {width: 129, height: 198},
        "91": {width: 171, height: 246},
        "92": {width: 189, height: 246},
        "93": {width: 280, height: 280},
        "94": {width: 184, height: 241},
        "95": {width: 108, height: 174},
        "96": {width: 191, height: 191},
        "97": {width: 241, height: 305},
        "98": {width: 178, height: 229},
        "99": {width: 200, height: 200},
        "100": {width: 200, height: 250},
        "101": {width: 200, height: 300},
        "102": {width: 200, height: 600},
        "103": {width: 300, height: 300},
        "104": {width: 300, height: 400},
        "105": {width: 300, height: 600},
        "106": {width: 300, height: 1000},
        "107": {width: 400, height: 400},
        "108": {width: 400, height: 500},
        "109": {width: 400, height: 600},
        "110": {width: 500, height: 500},
        "111": {width: 500, height: 600},
        "112": {width: 500, height: 700},
        "113": {width: 500, height: 750},
        "114": {width: 500, height: 1000},
        "115": {width: 600, height: 600},
        "116": {width: 600, height: 750},
        "117": {width: 600, height: 800},
        "118": {width: 750, height: 100},
        "119": {width: 254, height: 254},
        "120": {width: 300, height: 300},
        "121": {width: 89, height: 216},
        "122": {width: 108, height: 280},
        "123": {width: 178, height: 216},
        "124": {width: 191, height: 216},
        "125": {width: 229, height: 305},
        "126": {width: 229, height: 406},
        "127": {width: 216, height: 432},
        "128": {width: 292, height: 445},
        "129": {width: 432, height: 559},
        "130": {width: 279, height: 648},
        "131": {width: 203, height: 305},
        "132": {width: 254, height: 381},
        "133": {width: 140, height: 216},
        "134": {width: 89, height: 216},
        "135": {width: 102, height: 229},
        "136": {width: 51, height: 203},
        "137": {width: 93, height: 108},
        "138": {width: 76, height: 102},
        "139": {width: 102, height: 102},
        "140": {width: 147, height: 235},
        "141": {width: 213, height: 276},
        "142": {width: 137, height: 213},
        "143": {width: 133, height: 213}
    },

    format: {
        '0': {width: 841, height: 1189},
        '1': {width: 594, height: 841},
        '2': {width: 420, height: 594},
        '3': {width: 297, height: 420},
        '4': {width: 210, height: 297},
        '5': {width: 148, height: 210},
        '6': {width: 105, height: 148},
        '7': {width: 74, height: 105},
        '8': {width: 52, height: 74}
    },

    find: function (item) {
        let o = this.format[item.query.format];

        if (item.query.secondaryFormat) {
          o = this.secondaryFormat[item.query.secondaryFormat];
        }
        
        if (item.query.orientation) {
            return {width: o.height, height: o.width};
        }
        return {width: o.width, height: o.height};
    }
};
