import Command from '@ckeditor/ckeditor5-core/src/command';

export default class WidgetCommand extends Command {
    execute(value) {

        this.value = value;
        const defaultConfigValues = value.value.configurationProperties.reduce((accumulator, configurationProperty) => {
            accumulator[configurationProperty.name] = configurationProperty.defaultValue || '';
            return accumulator;
        }, {});

        this.editor.model.change( writer => {
            const placeholder = writer.createElement('widget', {
                name: value.value.name,
                label: value.value.title,
                'data-config': defaultConfigValues
            });

            this.editor.model.insertObject( placeholder, null, null, {
                setSelection: 'on'
            } );

        } );
    }

    refresh() {
        const model = this.editor.model;
        const selection = model.document.selection;
        const allowedIn = model.schema.findAllowedParent( selection.getFirstPosition(), 'widget' );
        this.isEnabled = allowedIn !== null;
    }
}