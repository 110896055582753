import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import ButtonView from '@ckeditor/ckeditor5-ui/src/button/buttonview';
import GridIcon from '@ckeditor/ckeditor5-table/theme/icons/table-row.svg';
import GridEditing from './grid-editing';

export default class GridPlugin extends Plugin {
    static get requires() {
        return [GridEditing];
    }
    init() {
        const editor = this.editor;
        const t = editor.t;

        editor.ui.componentFactory.add( 'grid', locale => {

            const command = editor.commands.get( 'insertTwoColumnGrid' );
            const buttonView = new ButtonView( locale );

            buttonView.set( {
                label: t( 'Two Column Grid' ),
                icon: GridIcon,
                tooltip: true
            } );

            buttonView.bind( 'isOn', 'isEnabled' ).to( command, 'value', 'isEnabled' );
            this.listenTo( buttonView, 'execute', () => editor.execute( 'insertTwoColumnGrid' ) );
            return buttonView;
        } );
    }
}