import defineComponent from '../../../../components/flight/lib/component';
import React from 'react';
import ReactDom from 'react-dom/client';
import PriceCalculatorManager from '../React/Homepage/PriceCalculatorManager/PriceCalculatorManager.tsx';

export default defineComponent(AdminHomepagePriceCalcultorManager);

function AdminHomepagePriceCalcultorManager() {

    this.initManager = function () {
        const manager = $(this.node);
        const root = ReactDom.createRoot(manager.get(0));
        root.render(
            <PriceCalculatorManager homepageSettingsId={manager.data('homepage-id')}></PriceCalculatorManager>
        );
    };

    this.after('initialize', function () {
        this.initManager();
    });

}
