import defineComponent from '../../../../../components/flight/lib/component';
import WithDashboardComponent from '../../../with-dashbord-component';
export default defineComponent(AnalyticsZSummary, WithDashboardComponent);

function AnalyticsZSummary() {

    this.attributes({
        counterIn: 'CONFIRM',
        counterCancel: 'CANCEL_CONFIRMED',
        counterDowngrade: 'UN_CONFIRM',
        counterField: 'value',
        currentUsersSelector: '#currentUsers'
    });

    this.reset = function () {
        this.counters[this.attr.counterIn] = this.createCounter(this.attr.counterIn);
        this.counters[this.attr.counterCancel] = this.createCounter(this.attr.counterCancel);
        this.counters[this.attr.counterDowngrade] = this.createCounter(this.attr.counterDowngrade);
        this.display(true);
    };

    this.isInteresting = function (counter) {
        return counter.cycle === "DAILY" && (counter.type === this.attr.counterIn ||
            counter.type === this.attr.counterCancel ||
            counter.type === this.attr.counterDowngrade);
    };

    this.calcTotal = function (field) {
        var a = this.counters[this.attr.counterIn][field],
            b = this.counters[this.attr.counterCancel][field],
            c = this.counters[this.attr.counterDowngrade][field];

        //console.log("calcTotal", a, b, c, a - (b + c));
        return a - (b + c);
    };

    this.display = function (isSilent) {
        if (this.timer) {
            clearTimeout(this.timer);
        }
        this.timer = setTimeout(() => {
            if (Object.keys(this.counters).length >= 3) {

                this.counters.TOTAL = this.createCounter("TOTAL",
                    this.calcTotal("count"),
                    this.calcTotal("sum"),
                    this.calcTotal("cost"),
                    this.calcTotal("vat"),
                    this.calcTotal("delivery"),
                    this.calcTotal("weight"));

                Object.keys(this.counters).forEach(key => this.updateUiCounters(this.counters[key], isSilent));
            }
        }, 200);
    };


    this.after('initialize', function () {
        var that = this,
            data = this.$node.data(),
            key;


        // read attributes via data api
        for (key in data) {
            //noinspection JSUnfilteredForInLoop
            if (data[key]) {
                //noinspection JSUnfilteredForInLoop
                that.attr[key] = data[key];
            }
        }
        this.counters = {};
        this.reset();
        this.on(document, "dashbordReset", this.reset);
        this.on(document, "dashbordCounterUpdate", this.counterUpdate);

    });
}