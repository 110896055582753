import defineComponent from '../../../components/flight/lib/component';

export default defineComponent(OdeonPlayer);

function OdeonPlayer() {


    this.attributes({
        url: '/api/odeon',
        videoPlayerSelector: "video"
    });

    this.onPlay = function (event) {
        this.send('play');
    };

    this.onPlaying = function (event) {
        //this.send('playing');
    };

    this.onPause = function (event) {
        this.send('pause');
    };

    this.onEnded = function (event) {
        this.send('end');
    };

    this.onError = function (event) {
        this.send('error');
    };

    this.genRandomIdentifier = function () {
        return Math.floor(Math.random() * 2147483648).toString(36);
    };

    this.send = function (type) {
        $.post(`${this.attr.url}/${type}`, { id: this.sessionId, videoId:  this.media}, null, 'json')
            .done(data => {
                // nothing to do
               //  console.log(data);
            })
            .fail((err) => this.trigger("log", { message: err }));
    };

    this.after('initialize', function () {
        this.sessionId = `VID${this.genRandomIdentifier()}SS${this.genRandomIdentifier()}`;
        this.media = this.$node.data('media');

        this.$node.find(this.attr.videoPlayerSelector).
            on("play", ev => this.onPlay(ev)).
            on("pause", ev => this.onPause(ev)).
            on("ended", ev => this.onEnded(ev)).
            on("error", ev => this.onError(ev)).
            on("playing", ev => this.onPlaying(ev));
    });
}
