import React from 'react';
import padLeft from './utils/padLeft';

function createTimebox(time) {
  const now = Date.now(),
    dateDiff = Math.round((now - time) / 1000 / 60),
    dateTemplate = "dd/mm/yy",
    timeTemplate = "h:MM TT";

  let box;

  if (dateDiff < 240) {
    box = (React.createElement('div', {
        className: 'timebox'
      },
      React.createElement('span', {
          className: 'value'
        },
        React.createElement("span", {
          className: 'hours'
        }, Math.floor(dateDiff / 60)),
        React.createElement("span", {
          className: 'seperator'
        }, ':'),
        React.createElement("span", {
          className: 'minutes'
        }, padLeft(dateDiff % 60, "0", 2))
      ),
      React.createElement('span', {
        className: 'caption'
      }, 'hours ago')
    ));
  } else if (time > getStartOfDay()) {
    box = (React.createElement('div', {
        className: 'timebox'
      },
      React.createElement('span', {
          className: 'value'
        },
        React.createElement("span", {
          className: 'hours'
        }, Math.floor(dateDiff / 60))
      ),
      React.createElement('span', {
        className: 'caption'
      }, 'hours ago')
    ));
  } else if (time > getStartOfDay(-1)) {
    box = (React.createElement('div', {
        className: 'timebox'
      },
      React.createElement('div', {
          className: 'date-part'
        },
        "Yesterday"
      ),
      React.createElement('div', {
        className: 'time-part'
      }, (new Date(time)).format(timeTemplate))
    ));
  } else if (dateDiff < 60 * 24 * 21) {
    box = (React.createElement('div', {
        className: 'timebox'
      },
      React.createElement('span', {
          className: 'value'
        },
        React.createElement("span", {
          className: 'days'
        }, Math.ceil(dateDiff / 60 / 24))
      ),
      React.createElement('span', {
        className: 'caption'
      }, 'days ago')
    ));
  } else {
    box = React.createElement('span', null, time && (new Date(time)).format(dateTemplate));
  }

  return box;

}

function getStartOfDay(days) {
  const now = new Date();
  return new Date(now.getFullYear(), now.getMonth(), now.getDate() + (days || 0), 0, 0, 0, 0).getTime();
}

export default createTimebox;