/***
 *  State management
 *  We read the state ONLY once (when we initialize)
 *  We write the state after a page-number-change, filter-change and sort-change
 *
 *  effie.n@taboola.com
 *  30 Jan 2015
 */
import defineComponent from '../../components/flight/lib/component';

export default defineComponent(State);

function State() {

    this.attributes({
        field: "sum"
    });
    this.write = function () {
        const value = JSON.stringify(this.state);
        document.location.hash = value.replace(/\./g, "_~_");
    };

    this.read = function () {
        var hash = document.location.hash && document.location.hash.substr(1).replace(/_~_/g, ".");

        if (hash) {
            try {
                return JSON.parse(decodeURIComponent(hash));
            } catch (ex) {
            }
        }
        return {};
    };

    this.afterSortChange = function (event, data) {
        this.state.sort = data;
        this.write();
    };

    this.afterCompareChange = function (event, data) {
        this.state.compare = data;
        this.write();
    };

    this.afterPageChange = function (event, data) {
        this.state.page = data.page;
        this.write();
    };

    this.afterTestModeChange = function (event, data) {
        this.state.mode = data.mode;
        this.write();
    };
    this.afterTestModeChange = function (event, data) {
        this.state.mode = data.mode;
        this.write();
    };

    this.afterFilterChange = function (event, data) {
        this.state.filter = data;
        this.write();
    };

    this.afterFilterClear = function (/*event, data*/) {
        delete this.state.filter;
        this.write();
    };

    this.afterRangeChange = function (event, data) {
        this.state.range = data;
        this.write();
    };

    this.getState = function () {
        this.trigger("uiSetState", this.read());
    };

    this.after('initialize', function () {
        this.state = this.read();

        this.on("uiSetCompare", this.afterCompareChange);
        this.on("uiAfterSort", this.afterSortChange);
        this.on("uiSetPageNumber", this.afterPageChange);
        this.on("uiSetTestMode", this.afterTestModeChange);
        this.on("uiSetFilter", this.afterFilterChange);
        this.on("uiClearFilter", this.afterFilterClear);
        this.on("uiChangeRange", this.afterRangeChange);
        this.on("requestState", this.getState);
    });
}
