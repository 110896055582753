import defineComponent from '../../../components/flight/lib/component';

export default defineComponent(AddressStreetView);

function AddressStreetView() {


    this.attributes({
        field: "sum"
    });

    this.check = function (url) {
        $.getJSON(url).done(response => {
            if ('OK' === response.status) {
                this.$node.removeClass('invisible');
            }
        });
    };

    this.after('initialize', function () {
        this.metaUrl = this.$node.data('meta');
        this.check(this.metaUrl);
    });
}
