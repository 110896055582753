import regional from './regional';

const supports3DTransforms =
    'WebkitPerspective' in document.body.style ||
    'MozPerspective' in document.body.style ||
    'msPerspective' in document.body.style ||
    'OPerspective' in document.body.style ||
    'perspective' in document.body.style; // Detect support for CSS 3D transforms

const supports2DTransforms =
    'WebkitTransform' in document.body.style ||
    'MozTransform' in document.body.style ||
    'msTransform' in document.body.style ||
    'OTransform' in document.body.style ||
    'transform' in document.body.style; // Detect support for CSS 2D transforms

const animationEndEvent =
    'animationend oAnimationEnd msAnimationEnd webkitAnimationEnd';

const whichAnimationEvent = function () {
    const el = document.createElement('fakeelement');
    const transitions = {
        transition: ['animationstart', 'animationend', 'transitionend'],
        OTransition: ['oAnimationStart', 'oAnimationEnd', 'oTransitionEnd'],
        MSTransition: ['msAnimationStart', 'msAnimationEnd', 'msTransitionEnd'],
        MozTransition: ['animationstart', 'animationend', 'transitionend'],
        WebkitTransition: [
            'webkitAnimationStart',
            'webkitAnimationEnd',
            'webkitTransitionEnd',
        ],
    };

    for (const t in transitions) {
        // noinspection JSUnfilteredForInLoop
        if (el.style[t] !== undefined) {
            // noinspection JSUnfilteredForInLoop
            return transitions[t];
        }
    }
    return null;
};

const monitorAnimation = function ($elem) {
    const events = whichAnimationEvent();
    const $container = $elem.find('.an-container');
    const transitionEnd = function (event) {
        if (
            event.target === $container[0] &&
            event.originalEvent.propertyName === 'opacity'
        ) {
            $container.addClass('an-open');
        }
    };
    const animationStart = function (/* event*/) {
        $container.addClass('an-active');
    };
    const animationEnd = function (/* event*/) {
        $container.removeClass('an-open');
        $container.addClass('an-opened');
    };

    if ($container.length) {
        if (supports3DTransforms) {
            $container.one(events[2], transitionEnd);
            $container.on(events[0], animationStart);
            $container.on(events[1], animationEnd);
        } else {
            $container.addClass('an-opened an-degraded');
        }
    }
};

const getUrlParameters = function () {
    const pairs = window.location.search.substring(1).split('&');
    const parameters = {};

    $.each(pairs, function (i, value) {
        const a = value.split('=');

        parameters[a[0]] = a[1];
    });
    return parameters;
};

const displayMessage = function (message) {
    const a = message.split('-');

    if (verifyTtl(a[1])) {
        $(document).trigger('showFixedMessage', getRegionalMessage(a[0]));
    }
};

const getRegionalMessage = function (n) {
    return regional().messages[n];
};

const verifyTtl = function (stamp) {
    return stamp > $('#serverTime').val();
};

// old (MOST!) browsers don't have setImmediate
if (!window.setImmediate) {
    window.setImmediate = function (func, args) {
        // noinspection JSCheckFunctionSignatures
        return window.setTimeout(func, 0, args);
    };
    window.clearImmediate = window.clearTimeout;
}

Number.prototype.formatNumber = function (
    decimalPrecision,
    decimalSymbol,
    digitGroupingSymbol
) {
    let n = this;
    // if decimal is zero we must take it, it means user does not want to
    // show any decimal
    const c = isNaN(decimalPrecision) ? 2 : Math.abs(decimalPrecision);
    // if no decimal separetor is passed we use the comma as default decimal
    // separator (we MUST use a decimal separator)
    const d = decimalSymbol || regional().decimalSymbol;
    // if you don't want ot use a thousands separator you can pass
    // empty string as digitGroupingSymbol value
    const t = digitGroupingSymbol || regional().digitGroupingSymbol;

    const sign = n < 0 ? '-' : '';

    // extracting the absolute value of the integer part of the number
    // and converting to string
    const i = parseInt((n = Math.abs(n).toFixed(c))) + '';
    let j = i.length;

    j = j > 3 ? j % 3 : 0;

    return (
        sign +
        (j ? i.substr(0, j) + t : '') +
        i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + t) +
        (c ? d +
            Math.abs(n - i)
                .toFixed(c)
                .slice(2)
            : '')
    );
};

function formatNumberWithLocale(val) {
    const locale = $('#shopLocale').val().replace('_', '-');
    const number = parseFloat(val);
    const formattedNumber = new Intl.NumberFormat(locale, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    }).format(number || 0);

    return formattedNumber;
}

/**
 * mustachFormatNumber
 * @param {Object} val - value to format
 * @param  {function} render - mustache render context
 * @return {*} formatted number
 */
function mustachFormatNumber(val, render) {
    const [text] = render(val).split('~');
    return formatNumberWithLocale(text);
}

String.prototype.supplant = function (o) {
    return this.replace(/{([^{}]*)}/g, function (a, b) {
        const r = o[b];
        return typeof r !== 'undefined' ? r : a;
    });
};

String.prototype.capitalize = function () {
    return this.charAt(0).toUpperCase() + this.substr(1); // .toLowerCase();
};

let supportPositionSticky = false;
(function () {
    const $div = $('.sticky-tester');
    if ($div.length) {
        supportPositionSticky = $div.css('position').indexOf('sticky') !== -1;
        $div.remove();
    }
})();


if (!Array.prototype.includes) {
    Object.defineProperty(Array.prototype, 'includes', {
        value: function (searchElement, fromIndex) {
            if (this == null) {
                throw new TypeError('"this" is null or not defined');
            }

            const o = Object(this);
            const len = o.length >>> 0;
            if (len === 0) {
                return false;
            }

            const n = fromIndex | 0;
            let k = Math.max(n >= 0 ? n : len - Math.abs(n), 0);

            function sameValueZero(x, y) {
                return x === y || (typeof x === 'number' && typeof y === 'number' && isNaN(x) && isNaN(y));
            }

            while (k < len) {
                if (sameValueZero(o[k], searchElement)) {
                    return true;
                }
                k++;
            }
            return false;
        }
    });
}

// https://tc39.github.io/ecma262/#sec-array.prototype.find
if (!Array.prototype.find) {
    Object.defineProperty(Array.prototype, 'find', {
        value: function(predicate) {
            // 1. Let O be ? ToObject(this value).
            if (this == null) {
                throw TypeError('"this" is null or not defined');
            }

            var o = Object(this);

            // 2. Let len be ? ToLength(? Get(O, "length")).
            var len = o.length >>> 0;

            // 3. If IsCallable(predicate) is false, throw a TypeError exception.
            if (typeof predicate !== 'function') {
                throw TypeError('predicate must be a function');
            }

            // 4. If thisArg was supplied, let T be thisArg; else let T be undefined.
            var thisArg = arguments[1];

            // 5. Let k be 0.
            var k = 0;

            // 6. Repeat, while k < len
            while (k < len) {
                // a. Let Pk be ! ToString(k).
                // b. Let kValue be ? Get(O, Pk).
                // c. Let testResult be ToBoolean(? Call(predicate, T, « kValue, k, O »)).
                // d. If testResult is true, return kValue.
                var kValue = o[k];
                if (predicate.call(thisArg, kValue, k, o)) {
                    return kValue;
                }
                // e. Increase k by 1.
                k++;
            }

            // 7. Return undefined.
            return undefined;
        },
        configurable: true,
        writable: true
    });
}

/**
 * scroll an element into the view port
 * @param {function} callback - call function upon completion
 *  @return {*} self reference
 */
$.fn.scrollToView = function (callback) {
    const offset = this.offset();

    if (offset) {
        // offset.left -= 20;
        offset.top -= 100;

        $('html, body').animate(
            {
                scrollTop: offset.top, /* ,
             scrollLeft: offset.left */
            },
            {duration: 800, easing: 'easeOutCubic', complete: callback}
        );
    }
    return this;
};

/**
 * returns if the elemenent is inside the view port
 * @return {boolean}
 */
$.fn.isVisible = function () {
    let i;
    let n;
    let r;
    let isInViewport;
    const element = this[0];

    if (element.offsetWidth === 0 || element.offsetHeight === 0) {
        return false;
    }

    const clientHeight = document.documentElement.clientHeight;
    const clientWidth = document.documentElement.clientWidth;
    // noinspection JSUnresolvedFunction
    const rects = element.getClientRects();

    for (i = 0, n = rects.length; i < n; i++) {
        r = rects[i];
        isInViewport =
            r.top > 0 ? r.top <= clientHeight
                : r.bottom > 0 && r.bottom <= clientHeight;

        if (isInViewport) {
            return r.left > 0  ? r.left <= clientWidth
                : r.right > 0 && r.right <= clientWidth;
        }
    }
    return false;
};

/**
 * Create jQuery closure
 * @param {string} className
 * @return {Function}
 */
function animateClass(className) {
    return function (callback) {
        const that = this;
        this.removeClass(className);
        setTimeout(function () {
            that.addClass(className);
            that.one(animationEndEvent, function () {
                that.removeClass(className);
                if (callback) {
                    callback();
                }
            });
        }, 0);
    };
}

/**
 * hilight an element for few seonds in yellow
 * to indicate a change
 */
$.fn.hilight = animateClass('animation-hilight');
$.fn.hilightElement = animateClass('hilight-element');

$.fn.hilightTableLine = animateClass('animation-table-line');

const log = function (params) {
    const url = $('#applicationDomain').val() + 'log?';

    params = params || [];
    params.push('time=' + new Date().getTime());
    params.push('url=' + encodeURIComponent(location.href));
    params.push('ua=' + encodeURIComponent(navigator.userAgent));
    $.get(url + params.join('&'));
};

const unAmp = function (text) {
    return (text || '').replace(/&amp;/g, '&');
};

const springUrl = function (url) {
    return $('#applicationDomain').val() + url.replace(/^\//, '');
};

/**
 * current user json format
 * @return {{firstName: (string), roles: (string),
 * name: (string), id: (string), username: (string)}}
 */
function getCurrentUser() {
    const user = {
        username: $('#mixamUserEmail').val(),
        id: $('#mixamUserId').val(),
        roles: $('#mixamUserRoles').val(),
        name: $('#mixamUserName').val(),
        firstName: $('#mixamUserFirstName').val(),
        impression: 'true' === $('#isImpression').val(),
    };

    if ($('#userImpression').length) {
        user.impression = true;
    }
    user.roleList = user.roles.split(" ");

    user.hasRole = function (role) {
        let found = true;

        if (Array.isArray(role)) {
            while (found && role[0]) {
                found = user.hasRole(role.shift());
            }
            return found;
        }
        return this.roleList.indexOf(role) !== -1;
    };

    return user;
}

const cookie = {
    /**
     * Reads the content of HTTP cookie into the object.
     * @param {String} name The name of the HTTP cookie.
     * @param {String} defaultValue
     * @return {String} The Value of the cookie or the value of
     * defaultValue or null.
     */
    read: function (name, defaultValue) {
        let i;
        let c;
        const nameEQ = name + '=';
        const ca = document.cookie.split(';');

        for (i = 0; i < ca.length; i++) {
            c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1, c.length);
            }
            if (c && c.indexOf(nameEQ) === 0) {
                // jshint ignore:line
                return c.substring(nameEQ.length, c.length).replace(/^null$/, '');
            }
        }
        return defaultValue;
    },

    /**
     * Writes the content of value into HTTP cookie named
     * name, expires after 'days' days.
     * @param {String} name The name of the HTTP cookie.
     * @param {String} value The value of the HTTP cookie.
     * @param {Number} seconds Expires after n seconds.
     * @param {String} path of the cookie.
     */
    write: function (name, value, seconds, path) {
        let expires = '';
        if (seconds) {
            const date = new Date();
            date.setTime(date.getTime() + seconds * 1000);
            expires = '; expires=' + date.toUTCString();
        }
        document.cookie = name + '=' + value + expires + '; path=' + (path || '/');
    },

    delete: function (name, path) {
        cookie.write(name, '-10', 0.001, path);
    },
};

const timeStampToDate = function (timestamp) {
    timestamp = +timestamp;
    if (timestamp < 9999999999) {
        timestamp *= 1000;
    }
    return new Date(timestamp);
};

const getBrowserName = function () {
    let name = "Unknown";
    if(navigator.userAgent.indexOf("MSIE") !== -1){
        name = "msie";
    }
    else if(navigator.userAgent.indexOf("Firefox") !== -1){
        name = "firefox";
    }
    else if(navigator.userAgent.indexOf("Opera") !== -1){
        name = "opera";
    }
    else if(navigator.userAgent.indexOf("Chrome") !== -1){
        name = "chrome";
    }
    else if(navigator.userAgent.indexOf("Safari") !== -1){
        name = "safari";
    }
    return name;
};

$.ajaxSetup({cache: false});

const shopId = $('#shopId').val();
const shopName = $('#shopName').val();
const shopSizeFormat = $('#shopSizeFormat').val();
const hasInk = parseInt($('#shopHasInk').val(), 10);

export default {
    initialize: function () {
        const $body = $(document.body);
        const $quatro = $('.quatro');
        const message = getUrlParameters().msg;

        let bodyClass = `browser-${getBrowserName()}`;
        if (!supports2DTransforms && !supports3DTransforms) {
            bodyClass += ' loaded no-transforms';
        }
        $body.addClass(bodyClass);

        $.tools.validator.localize('en', regional().validationErrors);

        if (message) {
            displayMessage(message);
        }

        $('[data-type="navigate"]').on('change', function (event) {
            location.href = '/' + event.target.value;
        });

        if ($quatro.length) {
            monitorAnimation($quatro);
        }
    },

    supportPositionSticky: supportPositionSticky,

    replicate: function (c, len) {
        return new Array(len + 1).join(c);
    },

    padl: function (n, c, len) {
        return ('' + this.replicate(c, len) + n).slice(-1 * len);
    },

    dateToDateTimeString: function (timestamp) {
        if (timestamp) {
            const d = timeStampToDate(timestamp);
            return (
                this.padl(d.getDate(), '0', 2) +
                regional().dateDelimiter +
                this.padl(d.getMonth() + 1, '0', 2) +
                regional().dateDelimiter +
                d.getFullYear() +
                ' ' +
                ' ' +
                this.padl(d.getHours(), '0', 2) +
                ':' +
                this.padl(d.getMinutes(), '0', 2)
            ); /* + ":" + this.padl(d.getSeconds(), "0", 2)*/
        }
    },

    dateToTimeString: function (timestamp) {
        if (timestamp) {
            const d = timeStampToDate(timestamp);

            return (
                this.padl(d.getHours(), '0', 2) +
                ':' +
                this.padl(d.getMinutes(), '0', 2)
            );
        }
    },

    dateToDateString: function (timestamp) {
        if (timestamp) {
            const d = timeStampToDate(timestamp);

            return (
                this.padl(d.getDate(), '0', 2) +
                regional().dateDelimiter +
                this.padl(d.getMonth() + 1, '0', 2) +
                regional().dateDelimiter +
                (d.getFullYear() - 2000)
            );
        }
    },

    dateToMediumDateString: function (timestamp) {
        if (timestamp) {
            const d = timeStampToDate(timestamp);

            return `${regional().dayLongNames[d.getDay()]}, ${
                regional().monthLongNames[d.getMonth()]
                } ${d.getDate()}, ${d.getFullYear()}`;
        }
    },
    dateToLongString: function (timestamp) {
        if (timestamp) {
            const d = timeStampToDate(timestamp);
            return `${regional().dayNames[d.getDay()]}, ${d.getDate()}  ${
                regional().monthNames[d.getMonth()]
                }`;
        }
    },

    cookie: cookie,
    log: log,
    unAmp: unAmp,
    placeHolderUrl: `${$('#cdnUrl').val()}/coloratura/images/placeholders`,
    parameters: getUrlParameters(),
    mustachFormatNumber: mustachFormatNumber,
    formatNumberWithLocale: formatNumberWithLocale,
    animationEventNames: whichAnimationEvent(),
    applicationDomain: $('#applicationDomain').val(),
    secureDomain: $('#secureDomain').val(),
    loqateKey: $('#loqateKey').val(),
    getUserDiscountRules: () => window.getUserDiscountRules && window.getUserDiscountRules(),
    stompServiceUrl: $('#stompWebServiceUrl').val(),
    stompWebUser: $('#stompWebUser').val(),
    stompWebPass: $('#stompWebPass').val(),
    useReactArtworkManager: $('#useReactArtworkManager').val() === 'true',
    assetsDomain: $('#assetsDomain').val(),
    springUrl: springUrl,
    cdn: $('#cdnUrl').val(),
    reporterOrigin: $('#reporterOrigin').val(),
    static: $('#staticUrl').val(),
    version: $('#appversion').val(),
    bucket: $('#s3Bucket').val(),
    shopId: shopId,
    shop: {
        loqateApiKey: $('#loqateKey').val(),
        id: shopId,
        name: shopName,
        title: $('#shopTitle').val(),
        locale: $('#shopLocale').val(),
        supportedLanguages: $('#supportedLanguages').val(),
        timeZone: $('#shopTimeZone').val(),
        url: $('#shopUrl').val(),
        themeJson: $('#shopThemeJson').val(),
        hasInk: hasInk,
        isMixamShop: "true" === $('#isMixamShop').val(),
        weightFormat: $('#shopWeightFormat').val(),
        sizeFormat: shopSizeFormat,
        countryRedirectService: $('#countryRedirectService').val() === 'true',
        showDeliveryOptionsInSanta:
            $('#showDeliveryOptionsInSanta').val() === 'true',
        showUkDeliveryOptionsInCart:
            $('#showUkDeliveryOptionsInCart').val() === 'true',
        currency: {
            id: $('#defaultCurrencyId').val(),
            prefix: $('#defaultCurrencyPrefix').val(),
            postfix: $('#defaultCurrencyPostfix').val(),
        },
        isManagementConsole: '5c9674c6697df162615449b1' === shopId,
        isUsaShop: () => shopSizeFormat === 'IMPERIAL',
        shipToCountries: $('[data-type="shipToCountries"]').map(function(){return $(this).val();}).get(),
        showWarningOnLoss: $('#showWarningOnLoss').val() === 'true',
        useReactPriceCalculator: $('#useReactPriceCalculator').val() === 'true',
        redirectToCart: $('#redirectToCart').val() === 'true',
        enableSplitDeliveryManager: $('#enableSplitDeliveryManager').val() === 'true',
        isMixamAccountEnabled: $('#isMixamAccountEnabled').val() === 'true',
    },
    orderId: $('#orderId').val(),
    queryResponseTopicId: $('#queryResponseTopicId').val(),
    shopName: shopName,
    shopHasInk: hasInk,
    user: getCurrentUser(),
    gCaptchaV3key: $('#gCaptchaV3key').val(),
    googleSheetsClientId: $('#googleSheetsClientId').val(),
    googleSheetsApiKey: $('#googleSheetsApiKey').val(),
    googleSheetsDiscoveryDocUri: $('#googleSheetsDiscoveryDocUri').val(),
    googleSheetsScope: $('#googleSheetsScope').val(),
    printboxTestingFlag: $('#printboxTestingFlag').val(),
    disputeRequestCategoryValues: $.parseJSON($('#disputeRequestCategoryValues').val()),
    csrf: {
        token: $('#csrfToken').length > 0 ? $('#csrfToken').val() : '',
        param: $('#csrfParameter').length > 0 ? $('#csrfParameter').val() : '',
    },
    cookiePopupEnabled: $('#cookiePopupEnabled').val() === 'true',
};
