import defineComponent from '../../../components/flight/lib/component';
import AnimatedCheckbox from '../animated/animated-checkbox';

export default defineComponent(MemberGroupsManager);

function MemberGroupsManager() {

    this.attributes({
        animatedCheckboxSelector: '[data-toggle="animated-checkbox"]',
        saveBtnSelector: 'button[type="submit"]'
    });

    this.handleSubmit = function(event) {
        const $target = $(event.target);
        $target.find(this.attr.saveBtnSelector).attr('data-loading', '*').attr('disabled', true);
    };

    this.after('initialize', function () {
        AnimatedCheckbox.attachTo(this.select('animatedCheckboxSelector'));
        this.on('submit', this.handleSubmit);
    });
}
