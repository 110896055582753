import defineComponent from '../../../components/flight/lib/component';

export default defineComponent(OrderReadiness);

function OrderReadiness() {

    this.attributes({
        artworkReadySelector: '[data-type="artwork-readiness"] .status-ready',
        artworkNotReadySelector: '[data-type="artwork-readiness"] .status-not-ready',
        publicationReadySelector: '[data-type="publication-readiness"] .status-ready',
        publicationNotReadySelector: '[data-type="publication-readiness"] .status-not-ready'
    });

    this.setOrder = function (event, data) {
        this.order = data;
        if (this.order.artworkReady) {
            this.select('artworkReadySelector').removeClass("hidden");
            this.select('artworkNotReadySelector').addClass("hidden");
        } else {
            this.select('artworkReadySelector').addClass("hidden");
            this.select('artworkNotReadySelector').removeClass("hidden");
        }
        if (this.order.publicationConfigReady) {
            this.select('publicationReadySelector').removeClass("hidden");
            this.select('publicationNotReadySelector').addClass("hidden");
        } else {
            this.select('publicationReadySelector').addClass("hidden");
            this.select('publicationNotReadySelector').removeClass("hidden");
        }
    };

    this.after('initialize', function () {
        this.on(document, "setOrderData", this.setOrder);
    });

}
