import defineComponent from '../../../../components/flight/lib/component';
import DataTable from "../../table/data-table-net/data-table-net.tsx";
import axios from 'axios';
import mixam from "../../../boot/mixam";

export default defineComponent(InvoiceExporter);

function InvoiceExporter() {

    this.attributes({
        url: "/admin/api/invoices/export/jobs",
        dataTableSelector: '[data-type="data-table"]',
        requestFormSelector: '[data-type="request-form"]',
        fromDateSelector: '[data-type="from-date"]',
        toDateSelector: '[data-type="to-date"]',
        reportTypeSelector: '[data-type="report-type"]'
    });

    this.createSchema = function () {
        const cols = [];

        cols.push({
            title: "Created",
            type: "datetime",
            data: "createdDate",
            defaultSort: true,
            sortOrder: 'desc'
        });

        cols.push({
            title: "Type",
            type: "text",
            data: "type",
        });

        cols.push({
            title: "From Date",
            type: "date",
            data: "fromDate"
        });

        cols.push({
            title: "To Date",
            type: "date",
            data: "toDate"
        });

        cols.push({
            title: "Author",
            type: "text",
            data: "author",
        });

        cols.push({
            title: "Status",
            type: "text",
            data: "status",
        });

        cols.push({
            title: "Records Exported",
            type: "number",
            data: "recordsExported",
        });

        cols.push({
            title: "Records Not Exported",
            type: "number",
            data: "recordsWithError",
        });

        cols.push({
            title: "Error",
            type: "text",
            data: "errorMessage",
        });

        cols.push({
            title: "",
            type: "button",
            data: "action"
        });

        return cols;
    };


    this.paint = function () {
        const data = {
            columns: this.createSchema(),
            fileName: this.attr.url.split("/").pop(),
            response: this.normalize(this.data)
        };

        if (this.dataReadyFired) {
            window.dispatchEvent(new CustomEvent('uiDataUpdate', { detail: data }));
        } else {
            this.dataReadyFired = true;
            this.trigger("uiDataReady", data);
        }
    };

    this.normalize = function (list) {
        return list.map(x => this.normalizeJob(x));
    };

    this.normalizeJob = function (job_) {
        const job = $.extend(true, {}, job_);

        job.action = {
            href: job.fileUrl,
            caption: 'Download',
            target: "_blank",
            className: `btn btn-primary position-absolute end-0 ${job.status !== 'COMPLETE' ? 'disabled' : ''}`,
            iconClassname: "bi bi-download"
        };
        return job;
    };

    this.getData = function () {
        axios.get(this.attr.url)
            .then((response) => {
                this.data = response.data;
                this.paint();
            }).catch((error) => {
                this.trigger("log", {message: error});
            });
    };

    this.createNewJob = function (event) {
        const invoiceExporter = this;
        axios.post(this.attr.url, {
                fromDate: invoiceExporter.select('fromDateSelector').val(),
                toDate: invoiceExporter.select('toDateSelector').val(),
                type: invoiceExporter.select('reportTypeSelector').val()
            })
            .catch((error) => {
                this.trigger("log", {message: error});
            });

        event.preventDefault();
        event.stopPropagation();
    };

    this.subscribe = function (onMessage) {
        this.stomp = Stomp.client(mixam.stompServiceUrl);

        this.stomp.connect(mixam.stompWebUser, mixam.stompWebPass, () => {
            this.stomp.subscribe("/topic/invoiceexportchannel", (d) => {
                const p = JSON.parse(d.body);
                onMessage(p);
            });
        }, (err) => this.trigger("log", err), '/');
    };

    this.updateMessageLine = function (data) {
        if (!this.data) {
            return;
        }

        let verb = data.type;
        const message = data.data;

        const index = this.data.map(x => x.id).indexOf(message.id);

        if (verb === "UPDATE" || verb === "INSERT") {
            if (index !== -1) {
                this.data[index] = message;
            } else {
                this.data.unshift(message);
            }
        } else if (verb === "DELETE" && index !== -1) {
            this.data.splice(index, 1);
        }
        this.paint();
    };

    this.after('initialize', function () {
        DataTable.attachTo(this.select('dataTableSelector'));
        this.on('submit', {
            requestFormSelector: this.createNewJob
        });
        this.subscribe(d => this.updateMessageLine(d));
        setTimeout(() => this.getData(), 10);
    });
}
