// Initialised when the page loads; shared across all use cases
const productMetadataCache = {};

const generateCacheKey = (productId, subProductId) => {
    return `metadata-${productId}-${subProductId}`;
};

export const getProductMetadata = (productId, subProductId) => {

    subProductId = subProductId || 0;

    const cacheKey = generateCacheKey(productId, subProductId);
    let productMetadata = productMetadataCache[cacheKey];

    if (!productMetadata) {
        const options = {
            url: `/api/products/${productId}/${subProductId}`,
            dataType: 'json',
            async: false,
            success: (data) => {
                productMetadata = data;
                productMetadataCache[cacheKey] = productMetadata;
            },
            error: (jqXHR, textStatus, errorThrown) => this.trigger("log", {message: errorThrown})
        };
        $.ajax(options);
    }

    return productMetadata;
};

let allProductMetadataCache = {};
export const getProductMetadataByAll = (productId, subProductId) => {
    let allProductMetadata = allProductMetadataCache;

    subProductId = subProductId || 0;

    if (Object.keys(allProductMetadata).length === 0) {
        const options = {
            url: '/api/products/',
            dataType: 'json',
            async: false,
            success: (data) => {
                allProductMetadata = data;
                allProductMetadataCache = data;
            },
            error: (jqXHR, textStatus, errorThrown) => this.trigger("log", {message: errorThrown})
        };
        $.ajax(options);
    }

    let productMetadata = allProductMetadata.find(productMetadata => productMetadata.productId === productId && productMetadata.subProductId === subProductId);
    if (!productMetadata) {
        productMetadata = allProductMetadata.find(productMetadata => productMetadata.productId === productId);
    }

    return productMetadata;
};