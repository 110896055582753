import React from 'react';
var DeliveryDropdown = function (_a) {
    var data = _a.data;
    if (data) {
        if (Array.isArray(data)) {
            if (data.length > 1) {
                var deliveryContents = data.map(function (delivery, index) {
                    return (React.createElement("li", { key: "deliver-".concat(index, "-").concat(delivery.href) },
                        React.createElement("a", { className: "dropdown-item", href: delivery.href, role: "button" }, delivery.caption)));
                });
                return (React.createElement("div", { className: "btn-group" },
                    React.createElement("button", { type: "button", className: "btn btn-sm btn-secondary dropdown-toggle w-100", "data-bs-toggle": "dropdown", "data-bs-display": "static", "aria-expanded": "false" },
                        React.createElement("i", { className: "bi bi-truck" }),
                        " Deliveries"),
                    React.createElement("ul", { className: "dropdown-menu dropdown-menu-lg-start" }, deliveryContents)));
            }
            else {
                return (React.createElement("a", { className: "btn btn-secondary btn-sm", href: data[0].href, role: "button" },
                    React.createElement("i", { className: "bi bi-truck" }),
                    " Delivery"));
            }
        }
    }
    return React.createElement("div", null);
};
export default DeliveryDropdown;
