import defineComponent from '../../../../components/flight/lib/component';
import axios from "axios";

export default defineComponent(SupplierPageRangeEditor);

function SupplierPageRangeEditor() {

    this.attributes({
        savePageRangesButton: '[data-type="save-page-ranges-btn"]',
        deletePageRangeRowButton: '[data-type="delete-page-range-row-btn"]',
        addPageRangeRowButton: '[data-type="add-page-range-row-btn"]',
        pageRangeTableBody: '[data-type="page-range-table-body"]'
    });

    this.roundTo4 = function (x) {
        const y = 4;
        return x - (x % y);
    };

    this.savePageRanges = function() {

        if (this.node.checkValidity()) {
            const editor = this;
            editor.disableSaveBtn();

            const substrateWeightPageRanges = [];
            this.select('pageRangeTableBody').children('tr').each((index, element) => {
                const $element = $(element);
                const minPagesVal = $element.find('[data-field="min-pages"]').val();
                const maxPagesVal = $element.find('[data-field="max-pages"]').val();

                if (minPagesVal % 4 !== 0) {
                    $element.find('[data-field="min-pages"]').val(this.roundTo4(minPagesVal));
                }
                if (maxPagesVal % 4 !== 0) {
                    $element.find('[data-field="max-pages"]').val(this.roundTo4(maxPagesVal));
                }
                substrateWeightPageRanges.push({
                    maxGsm: $element.find('[data-field="max-gsm"]').val(),
                    minPages: $element.find('[data-field="min-pages"]').val(),
                    maxPages: $element.find('[data-field="max-pages"]').val()
                });
            });

            axios.put(
                `/admin/catalogue/suppliers/${this.supplierId}/page-ranges/${this.fingerprint}/weights`,
                substrateWeightPageRanges
            ).then(
                () => {
                    // Re-sort the rows...
                    window.location.reload();
                }
            ).catch(
                error => {
                    editor.resetSaveBtn();
                    alert('Error Saving Data: ' + error.response.data.message);
                }
            );
        } else {
            $(this.node).addClass('was-validated');
        }
    };

    this.deletePageRangeRow = function(event) {
        $(event.target).closest('tr').remove();
    };

    this.addPageRangeRow = function() {
        /*
         * It's extremely likely that the following will fall out-o-step with the rows rendered
         * server-side; this functionality is a good candidate for moving to React.
         */
        const newPageRangeRow = `
            <tr>
                <td>
                    <input min="0" max="2000" step="1" class="form-control" type="number" data-field="max-gsm" required />
                </td>
                <td>
                    <input min="0" max="2000" step="4" class="form-control" type="number" data-field="min-pages" required />
                </td>
                <td>
                    <input min="0" max="2000" step="4" class="form-control" type="number" data-field="max-pages" required />
                </td>
                <td class="text-end">
                    <button class="btn btn-danger" data-type="delete-page-range-row-btn">Delete</button>
                </td>
            </tr>
        `;

        this.select('pageRangeTableBody').append(newPageRangeRow);
    };

    this.disableSaveBtn = function() {
        let saveBtn = this.select('savePageRangesButton');
        setTimeout( function() {
            saveBtn.addClass('disabled');
            saveBtn.find('[data-type="spinner"]').removeClass('d-none');
            saveBtn.find('[data-type="btn-label"]').text("Saving");
        }, 1);
    };

    this.resetSaveBtn = function() {
        let saveBtn = this.select('savePageRangesButton');
        setTimeout( function() {
            saveBtn.find('[data-type="spinner"]').addClass('d-none');
            saveBtn.find('[data-type="btn-label"]').text("Save");
            saveBtn.removeClass('disabled');
        }, 2000);
    };

    this.after('initialize', function () {
        this.supplierId = $(this.node).data('supplier-id');
        this.fingerprint = $(this.node).data('fingerprint');
        this.on('click', {
            savePageRangesButton: this.savePageRanges,
            deletePageRangeRowButton: this.deletePageRangeRow,
            addPageRangeRowButton: this.addPageRangeRow
        });
    });

}
