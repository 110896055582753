import defineComponent from '../../../../components/flight/lib/component';
import DataTable from './../../table/data-table-net/data-table-net.tsx';
import axios from "axios";

export default defineComponent(PaperTypesDataTable);

function PaperTypesDataTable() {

    this.attributes({
        url: "paper-types/list",
        dataTableSelector: '[data-type="data-table"]'
    });

    this.createSchema = function () {
        const cols = [];
        cols.push({
            title: "Name",
            type: "text",
            data: "name"
        });
        cols.push({
            title: "Description",
            type: "text",
            data: "description"
        });
        cols.push({
            title: "External ID",
            type: "number",
            data: "externalId",
            defaultSort: true
        });
        cols.push({
            title: "",
            type: "multiButton",
            data: "actions",
            width: '175px'
        });
        return cols;
    };


    this.paint = function () {
        const data = {
            columns: this.createSchema(),
            fileName: this.attr.url.split("/").pop(),
            response: this.normalize(this.data)
        };

        if (this.dataReadyFired) {
            this.trigger("uiDataUpdate", data);
        } else {
            this.dataReadyFired = true;
            this.trigger("uiDataReady", data);
        }
    };

    this.normalize = function (list) {
        return list.map(x => this.normalizePaperType(x));
    };

    this.normalizePaperType = function (paperType_) {
        const paperType = $.extend(true, {}, paperType_);
        paperType.actions = [
            {
                href: `paper-types/${paperType.id}/edit`,
                caption: "Edit",
                target: "_self",
                className: "btn btn-primary"
            }
        ];
        return paperType;
    };

    this.getData = function () {
        axios.get(this.attr.url)
            .then(response => {
                this.data = response.data;
                this.paint();
                setTimeout(() => this.getData(), 1000 * 60 * 15);
            }).catch(error => this.trigger("log", {message: error}));
    };

    this.after('initialize', function () {
        DataTable.attachTo(this.select('dataTableSelector'));
        setTimeout(() => this.getData(), 10);
    });
}
