import defineComponent from '../../../components/flight/lib/component';
import WithScene from './../with-scene';

export default defineComponent(ConfirmManager, WithScene);

function ConfirmManager() {

    this.attributes({
        delay: 0,
        checkboxHolderSelector: ".checkbox-holder",
        signHereSelector: '[data-type="sign-here"]',
        heightLessSelector: ".inline-confirmation, section.present",
        cancelBtnSelector: ".btn-invert-cancel",
        confCheckSelector: 'input[type="checkbox"]'
    });

    this.onResize = function () {
        const minHeight = Math.max(60, this.select('checkboxHolderSelector').height());

        this.select('heightLessSelector').css("min-height", minHeight + "px");
    };


    this.handleConfCheckClick = function (/*event*/) {
        setTimeout(() => this.goTo(0, 1), 200);
    };

    this.onCancel = function (/*event*/) {
        const $input = this.select('confCheckSelector');

        $input.prop('checked', !$input.prop('checked'));
        $input.trigger("change");
        setTimeout(() => this.goTo(0, 0), this.attr.delay);
    };

    this.onSubmit = function (/*event*/) {
        this.select('signHereSelector').attr('class', 'fa fa-spinner fa-spin');
    };

    this.after('initialize', function () {
        this.on(window, 'resize', $.proxy(this.onResize, this));
        setTimeout(() => this.onResize(), 100);

        this.on('submit', this.onSubmit);
        this.on('click', {
            confCheckSelector: this.handleConfCheckClick,
            cancelBtnSelector: this.onCancel
        });

    });
}
