import defineComponent from '../../../components/flight/lib/component';

export default  defineComponent(CatalogManager);

function CatalogManager() {


    this.attributes({
        importSelector: '.import-form select'
    });


    this.importFile = function (event) {
        event.target.form.submit();
    };

    this.after('initialize', function () {
        this.on("change", {
            importSelector: this.importFile
        });

    });
}
