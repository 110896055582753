import FilterUnit from  './filter-unit';
import FilterTypeahead from  './filter-typeahead';
import defineComponent from '../../../components/flight/lib/component';
import Mustache from '../../../components/mustache/mustache';
import filterTemplate from 'text!../../../appes6/templates/table/filter-box.mustache';
import DateRangePicker from './filter-date-range';
import moment from 'moment/moment';

var animationEndEvent = 'webkitTransitionEnd oTransitionEnd transitionend',
    operatorDict = {
        eq: "Equals",
        neq: "Not Equals",
        cn: "Contains",
        ncn: "Not Contains",
        gt: "Grater than",
        lt: "Less than",
        gte: "Grater Equals",
        lte: "Less Equals",
        regexp: "Regexp"
    };

export default defineComponent(Filter);

function Filter() {


    function timeStringTillMillies(text) {
        var a = text.split(":"),
            result = 0;

        // hours
        if (a[0] && !isNaN(+a[0])) {
            result += +a[0] * 60 * 60 * 1000;
        }
        // minutes
        if (a[1] && !isNaN(+a[1])) {
            result += +a[1] * 60 * 1000;
        }

        return result;
    }

    function milliesTillTimeString(miliSeconds) {
        var minutes = miliSeconds % (60 * 60 * 1000)  / 1000 / 60,
            hours = Math.floor(miliSeconds / (60 * 60 * 1000));

        return `${hours}:${minutes}`;
    }

    this.attributes({
        labelAll: "All",
        labelAllDates: "All dates",
        buttonCommand: "clear-filter",
        morphedClassName: 'morphed',
        thSelector: 'thead tr:first-child th',
        clearFilterSelector: '[data-type="clear-filter"]',
        containerSelector: '.box-container',
        tableSelector: '.filter-table',
        typeaheadSelector: '.typeahead',
        filterInputSelector: '[data-type="filter-input"]',
        filterCheckboxSelector: '[data-type="filter-checkbox"]',
        filterUnitSelector: '[data-type="filter-unit"]',
        filterUnitAndOrSelector: '[data-type="and-or-or"]',
        filterOperatorSelector: '[data-type="operator"]',
        operatorCaptionSelector: '.operator .dropdown-toggle .caption',
        dateRangePickerSelector: '[data-type="filter-range-picker"]',
        startSelector: '[data-type="start"]',
        endSelector: '[data-type="end"]',
        labelSelector: '[data-type="label"]',
        label2Selector: '[data-type="label2"]',
        timeSelector: '[data-input="time"]',
        filterComponentSelector: '[data-type="filter-input"]:not(.tt-hint), [data-type="filter-checkbox"]',
        dateRangeCaption1Selector: '[data-type="filter-range-picker"] .caption-text',
        dateRangeCaption2Selector: '[data-type="filter-range-picker"] .text'
    });

    this.syncToTable = function (event, data) {
        var cells,
            hasState = this.state && this.state.filter,
            value = this.getFilterValue();


        if (!this.ths) {

            this.$node.html(this.render());

            // Construct typeahead on text inputs
            FilterTypeahead.attachTo(this.select('typeaheadSelector'), {
                data: this.data
            });

            FilterUnit.attachTo(this.select('filterUnitSelector'));
            DateRangePicker.attachTo(this.attr.dateRangePickerSelector);

            this.ths = this.select('thSelector');
            this.select('containerSelector').on(animationEndEvent, event => {
                this.$node.removeClass("closed opened").addClass(this.mode);
                if (this.mode === "closed") {
                    this.select('containerSelector').addClass("hidden");
                }
            });

            if (value && this.mode === "opened") {
                this.state = this.state || {};
                this.state.filter = value;
                this.mode = "closed";
            }
        }

        cells = $(data.table).find(this.attr.thSelector);
        this.ths.each(function (i, th) {
            $(th).width(cells.eq(i).width());
        });

        if (this.state && this.state.filter) {
            setTimeout($.proxy(this.readState, this, hasState), 1);
        }
    };

    this.addSchema = function (event, data) {
        if (this.schema) {
            return;
        }
        this.schema = this.normalizeSchema(data.columns);
        this.preDefienedFilters = data.filters || [];
        this.ths = null;

        if (this.preDefienedFilters.length) {
            let filters = this.preDefienedFilters.map(o => {
                return {
                    name: o.name,
                    filter: JSON.stringify(o.filter)
                };
            });
            setTimeout(() => this.trigger('uiRequestButtonMenu', {target: this.attr.buttonCommand, items: filters}), 16);
        }
        this.tryToCalcIcons();
    };

    this.normalizeSchema = function (schema) {
        var map = schema.map(function (col) {
            var colTypeUpper = col.type.toUpperCase();

            if (colTypeUpper === "THUMBNAIL" || colTypeUpper === "LIGHTBOX") {
                col.preventFilter = true;
            }
            return col;
        });

        // add byId map to the array
        map.forEach(col => map[col.data] = col);

        return map;
    };

    this.render = function () {
        return Mustache.render(filterTemplate, {
            columns: this.schema,
            hasFilters: this.preDefienedFilters && this.preDefienedFilters.length ? true : '',
            filters: this.preDefienedFilters.map(o => {
                return {
                    name: o.name,
                    filter: JSON.stringify(o.filter)
                };
            })
        });
    };

    /**
     * Reads the state from the various UI elements into a known json format
     * {
     *      and: {fieldId: {operator: value}, [fieldId...n: {operator: value},] },
     *      or: {fieldId: {operator: value}, [fieldId...n: {operator: value},]}
     * }
     * @returns - the filter json or null
     */
    this.getFilterValue = function () {
        var activeFilterUnitsCount = 0,
            filter = {and: {}, or: {}},
            isClear = true;

        this.select('filterUnitSelector').each((index, unit) => {
            var o = {},
                $unit = $(unit),
                method = $unit.find(this.attr.filterUnitAndOrSelector + ":checked").val(),
                $input = $unit.find(this.attr.filterComponentSelector),
                operator = $unit.find(this.attr.filterOperatorSelector).val(),
                type = $input.attr('type'),
                start = $unit.find(this.attr.startSelector).val(),
                end = $unit.find(this.attr.endSelector).val(),
                label = $unit.find(this.attr.labelSelector).val(),
                label2 = $unit.find(this.attr.label2Selector).val(),
                isTime =  $unit.find(this.attr.timeSelector).length;

            if (start && end && !isNaN(start) && !isNaN(end) ) {
                operator = 'btw';
                o[operator] = {start: start, end: end, label: label, label2: label2};
                filter[method][$unit.data("field")] = o;
                isClear = false;
                activeFilterUnitsCount += 1;
            } else if ($input.hasClass("tristate")) {
                if (+$input.val()) {
                    o[operator] = +$input.val() !== -1;
                    filter[method][$input.data("key")] = o;
                    isClear = false;
                    activeFilterUnitsCount += 1;
                }
            } else if ($input.val()) {
                if (isTime) {
                    o[operator] = timeStringTillMillies($input.val());
                } else {
                o[operator] = $input.val();
                }

                filter[method][$input.data("key")] = o;
                isClear = false;
                activeFilterUnitsCount += 1;
            }
        });

        /* side effect - consider change the location of the following code: */
        this.select('tableSelector')[activeFilterUnitsCount > 1 ? 'removeClass' : 'addClass']("virgin");

        return isClear ? null : filter;
    };

    this.clearFilter = function (event, data) {
        if (data.command === this.attr.buttonCommand) {
            if (data.isMorphed) {
                // reset 'active' state
                this.select('filterUnitSelector').removeClass("active");
                // reset yhe and-or to and
                this.$node.find(this.attr.filterUnitAndOrSelector + "[value='and']").prop("checked", true);
                // reset the value input or checkbox
                this.select('filterInputSelector').val("");
                this.select('startSelector').val("");
                this.select('endSelector').val("");
                this.select('labelSelector').val("All Dates");
                this.select('dateRangeCaption1Selector').text("All");
                this.select('dateRangeCaption2Selector').text("All Dates");

                this.select('filterCheckboxSelector').prop("readonly", false).prop("checked", false).prop("indeterminate", false).val(0);

                // reset the operator to default value and caption
                this.select('filterOperatorSelector').each((i, input) => {
                    var $input = $(input);

                    $input.val($input.data('default-value')).closest(this.attr.filterUnitSelector)
                        .find(this.attr.operatorCaptionSelector).text($input.data('default-caption'));
                });

                this.trigger("uiFilterChange", this.getFilterValue());
                this.select('filterInputSelector').eq(0).trigger("input");
            } else {
                if (this.mode === "opened") {
                    if (data.isFixed && !this.$node.isVisible()) {
                        this.$node.scrollToView();
                    } else {
                        this.hide();
                    }
                } else {
                    this.show(data.isFixed);
                }
            }
        }
    };

    this.show = function (isScroll) {
        if (this.mode !== "opened") {
            this.select('containerSelector').removeClass("hidden");
            this.mode = "opened";
            this.trigger('uiRequestButtonActivate', {target: this.attr.buttonCommand});

            if (isScroll) {
                this.$node.scrollToView(() =>  {
                    setTimeout(() => {
                        this.select('containerSelector').removeClass("hidden").width();
                        this.select('containerSelector').removeClass("shrink");
                    }, 10);
                });
            } else {
                setTimeout(() => {
                    this.select('containerSelector').removeClass("hidden").width();
                    this.select('containerSelector').removeClass("shrink");
                }, 10);
            }
        }
    };

    this.onRequestPredefinedFilter = function(event, data) {
        if (data.command === this.attr.buttonCommand) {
            this.clearFilter(null, data);
            this.state.filter = this.ensureNewFilterFormat(data.filter);
            this.readState(true);
        }
    };

    this.getSchemaColumn = function (id) {
        var i, col;

        for (i = 0; col = this.schema[i]; i++) { // jshint ignore:line
            if (col.data === id) {
                return col;
            }
        }
    };

    this.ensureNewFilterFormat = function (filter) {
        var newFilter;

        if (!filter.and && !filter.or) {
            newFilter = {and: {}, or: {}};
            Object.keys(filter).forEach(key => {
                var o = {},
                    type = this.schema[key].type.toUpperCase(),
                    operator = type === "NUMBER" || type === "BOOLEAN" || type === "DATE" || type === "DATETIME" || type === "TIME" ? "eq" : "cn";

                o[operator] = filter[key];
                newFilter.and[key] = o;
            });
            return newFilter;
        }
        return filter;
    };


    this.hide = function () {
        this.mode = "closed";
        this.select('containerSelector').addClass("shrink");
        this.trigger('uiRequestButtonDeactivate', {target: this.attr.buttonCommand});
    };

    this.changeHandler = function (/*event*/) {
        var filter = this.getFilterValue();

        if (filter) {
            this.trigger('uiRequestButtonMorph', {
                target: this.attr.buttonCommand,
                className: this.attr.morphedClassName,
                isMorphed: true
            });
        } else {
            this.trigger('uiRequestButtonMorph', {
                target: this.attr.buttonCommand,
                className: this.attr.morphedClassName,
                isMorphed: false
            });
        }

        if (this.timer) {
            clearTimeout(this.timer);
        }
        this.timer = setTimeout(() => this.trigger("uiFilterChange", filter), 100);
    };

    this.checkboxChangeHandler = function(event) {
        var target = event.target;

        target.value = 1;
        if (target.readOnly) {
            target.checked = target.readOnly = false;
            target.value = 0;
        }
        else if (!target.checked) {
            target.readOnly = target.indeterminate = true;
            target.value = -1;
        }
        this.changeHandler();
    };

    this.readState = function (hasState) {
        var stateFilter = this.state.filter;

        if (stateFilter) {
            this.show();
            Object.keys(stateFilter).forEach(method => {
                Object.keys(stateFilter[method]).forEach(key => {
                    var filterItem = stateFilter[method][key],
                        $unit = this.$node.find('[data-field="' + key + '"]'),
                        isTime =  $unit.find(this.attr.timeSelector).length,
                        $input;

                    // set the and-or to and
                    $unit.addClass("active").find(this.attr.filterUnitAndOrSelector + "[value='" + method + "']").prop("checked", true);
                    Object.keys(filterItem).forEach(operator => {
                        if (operator === 'btw') {
                            if (!filterItem[operator].start) {
                                // reset the value input or checkbox
                                $unit.find(this.attr.startSelector).val("");
                                $unit.find(this.attr.endSelector).val("");
                                $unit.find(this.attr.labelSelector).val("All");
                                $unit.find(this.attr.label2Selector).val("All Dates");
                                $unit.find(this.attr.dateRangeCaption1Selector).text("All");
                                $unit.find(this.attr.dateRangeCaption2Selector).text("All Dates");
                            } else {
                                this.reEvaluateFilter(filterItem[operator]);
                                $unit.find(this.attr.startSelector).val(filterItem[operator].start);
                                $unit.find(this.attr.endSelector).val(filterItem[operator].end);
                                $unit.find(this.attr.labelSelector).val(filterItem[operator].label);
                                $unit.find(this.attr.label2Selector).val(filterItem[operator].label2);
                                $unit.find(this.attr.dateRangeCaption1Selector).text(filterItem[operator].label);
                                $unit.find(this.attr.dateRangeCaption2Selector).text(filterItem[operator].label2);
                            }
                        } else {
                        $unit.find(this.attr.filterOperatorSelector).val(operator);
                        $unit.find(this.attr.operatorCaptionSelector).text(operatorDict[operator]);

                        $input = $unit.find("[data-key='" + key + "']");
                        if (typeof filterItem[operator] === "boolean") {
                            if (filterItem[operator]) {
                                $input.prop('checked', true).val(1);
                            } else {
                                $input.prop('readonly', true).prop("indeterminate", true).val(-1);
                            }
                        } else {
                                let newValue = filterItem[operator];
                                if (isTime) {
                                    newValue = milliesTillTimeString(newValue);
                                }
                                $input.val(newValue);
                            }
                        }
                    });
                });
            });
            this.trigger('uiRequestButtonMorph', {
                target: this.attr.buttonCommand,
                className: this.attr.morphedClassName,
                isMorphed: true
            });
            if (hasState) {
                this.changeHandler();
            }
        }
        this.state = {};
    };

    this.reEvaluateFilter = function (filterItem) {
        switch (filterItem.label) {
            case "All" :
                filterItem.start = moment().subtract(50, 'year').toDate().getTime();
                filterItem.end = moment().endOf('day').toDate().getTime();
                break;
            case "Today" :
                filterItem.start = moment().startOf('day').toDate().getTime();
                filterItem.end = moment().endOf('day').toDate().getTime();
                break;
            case "Yesterday" :
                filterItem.start = moment().subtract(1, 'days').startOf('day').toDate().getTime();
                filterItem.end = moment().subtract(1, 'days').endOf('day').toDate().getTime();
                break;
            case "This Week" :
                filterItem.start = moment().startOf('week').toDate().getTime();
                filterItem.end = moment().endOf('week').toDate().getTime();
                break;
            case "Last 7 Days" :
                filterItem.start = moment().subtract(6, 'days').startOf('day').toDate().getTime();
                filterItem.end = moment().endOf('day').toDate().getTime();
                break;
            case "Last 30 Days" :
                filterItem.start = moment().subtract(29, 'days').startOf('day').toDate().getTime();
                filterItem.end = moment().endOf('day').toDate().getTime();
                break;
            case "This Month" :
                filterItem.start = moment().startOf('month').toDate().getTime();
                filterItem.end = moment().endOf('month').toDate().getTime();
                break;
            case "Last Month" :
                filterItem.start = moment().subtract(1, 'month').startOf('month').toDate().getTime();
                filterItem.end = moment().subtract(1, 'month').endOf('month').toDate().getTime();
                break;
        }

        filterItem.label2 = this.attr.labelAll === filterItem.label ?
            this.attr.labelAllDates :
            moment(filterItem.start).format('MMM D') + ' - ' + moment(filterItem.end).format('MMM D');
    };

    this.setState = function (event, state) {
        if (this.state && this.state.filter) {
            return;
        }
        this.state = state || {};
    };

    this.setData = function (event, data) {
        this.data = data.list;
        this.tryToCalcIcons();
        setTimeout(() => this.trigger('uiRequestButtonEnable', {target: this.attr.buttonCommand}), 300);
    };

    this.tryToCalcIcons = function () {
        if (this.data && this.schema) {
            this.reduceIconList();
        }
    };

    this.reduceIconList = function () {
        this.schema.forEach(col => {
            if (col.type.toUpperCase() === "ICON") {
                col.options = this.getDistinctIconsList(col.data);
                col.defaultIcon =  col.options[0];
            }
        });
    };

    this.getDistinctIconsList = function (colName) {
        var result = {};

        this.data.forEach(line => line[colName].icon ? result[line[colName].icon] = true : null);
        return Object.keys(result);
    };

    this.typeaheadSelected = function (/*event, suggestion, name*/) {
        this.changeHandler();
    };

    this.typeaheadAutocompleted = function (/*event, suggestion, name*/) {
        this.changeHandler();
    };

    this.after('initialize', function () {
        this.suggestions = {};
        this.handler = this.changeHandler;
        this.state = {};

        this.on(document, "uiSetState", this.setState);
        this.trigger('requestState');

        this.on(document, "uiBeforeTableRender", this.addSchema);
        this.on(document, "uiAfterTableRender", this.syncToTable);
        this.on(document, "uiSetData", this.setData);
        this.on("uiFilterOperatorChange", this.changeHandler);
        this.on(document, "toolbarRequestFilter", this.onRequestPredefinedFilter);

        this.on("typeahead:selected", this.typeaheadSelected);
        this.on("typeahead:autocompleted", this.typeaheadAutocompleted);

        this.on(document, "toolbarAction", this.clearFilter);

        this.on("input", {
            filterInputSelector: this.changeHandler
        });

        this.on("uiDateRangeChange",  this.changeHandler);

        this.on("change", {
            filterCheckboxSelector: this.checkboxChangeHandler,
            filterUnitSelector: this.changeHandler
        });
     });
}
