var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import mixam from '../../../../boot/mixam.js';
import React, { useEffect, useState } from 'react';
import { Formik, Form, Field } from 'formik';
import axios from "axios";
var ImageUpload = function (props) {
    var _a = useState((Array)), filesToUpload = _a[0], setFilesToUpload = _a[1];
    var _b = useState((Array)), fileUploadProgress = _b[0], setUploadProgress = _b[1];
    var _c = useState((Array)), uploadedFiles = _c[0], setUploadedFiles = _c[1];
    var _d = useState([]), uploadedFileResponses = _d[0], setUploadedFileResponses = _d[1];
    var _e = useState(props.imageWidth), imageWidth = _e[0], setImageWidth = _e[1];
    var _f = useState(props.imageHeight), imageHeight = _f[0], setImageHeight = _f[1];
    var _g = useState(props.resizeMethod), resizeMethod = _g[0], setResizeMethod = _g[1];
    var _h = useState(true), enableWidth = _h[0], setEnableWidth = _h[1];
    var _j = useState(true), enableHeight = _j[0], setEnableHeight = _j[1];
    var _k = useState(0), tinyPngCreditsUsed = _k[0], setTinyPngCreditsUsed = _k[1];
    var _l = useState(false), fileUploadInProgress = _l[0], setFileUploadInProgress = _l[1];
    var uploadUrl = "/admin/api/images/save";
    var tinyPngCreditsUsedUrl = "/admin/api/images/credits-used";
    var setResizeMode = function (mode) {
        switch (mode) {
            case 'SCALE-WIDTH':
                setResizeMethod('SCALE');
                setEnableWidth(true);
                setEnableHeight(false);
                break;
            case 'SCALE-HEIGHT':
                setResizeMethod('SCALE');
                setEnableWidth(false);
                setEnableHeight(true);
                break;
            default:
                setResizeMethod(mode);
                setEnableWidth(true);
                setEnableHeight(true);
                break;
        }
    };
    var uploadFiles = function () { return __awaiter(void 0, void 0, void 0, function () {
        var _loop_1, i;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setFileUploadInProgress(true);
                    setUploadedFiles(__spreadArray(__spreadArray([], uploadedFiles, true), Array.from(filesToUpload), true));
                    setUploadProgress(__spreadArray(__spreadArray([], fileUploadProgress, true), Array.from(filesToUpload).map(function () { return 0; }), true));
                    setUploadedFileResponses(__spreadArray(__spreadArray([], uploadedFileResponses, true), Array.from(filesToUpload).map(function () { return null; }), true));
                    _loop_1 = function (i) {
                        var data, response;
                        return __generator(this, function (_b) {
                            switch (_b.label) {
                                case 0:
                                    data = new FormData();
                                    data.append('image', filesToUpload[i]);
                                    data.append('uploadFolder', props.uploadFolder);
                                    data.append('imageWidth', enableWidth ? imageWidth.toString() : "0");
                                    data.append('imageHeight', enableHeight ? imageHeight.toString() : "0");
                                    data.append('imageResizeMethod', resizeMethod.toString());
                                    data.append('produceWebP', String(props.produceWebp));
                                    return [4 /*yield*/, axios.post(uploadUrl, data, {
                                            headers: {
                                                "Content-Type": "multipart/form-data",
                                            },
                                            onUploadProgress: function (progressEvent) {
                                                var progress = Math.ceil((progressEvent.loaded / progressEvent.total) * 100);
                                                setUploadProgress(fileUploadProgress.map(function (uploadProgress, index) {
                                                    return index === (i) ? progress : uploadProgress;
                                                }));
                                            },
                                        })];
                                case 1:
                                    response = _b.sent();
                                    if (response.status === 200) {
                                        uploadedFileResponses.push(response.data);
                                        setUploadedFileResponses(__spreadArray([], uploadedFileResponses, true));
                                        props.uploadSuccessCallback(response.data);
                                    }
                                    return [2 /*return*/];
                            }
                        });
                    };
                    i = 0;
                    _a.label = 1;
                case 1:
                    if (!(i < filesToUpload.length)) return [3 /*break*/, 4];
                    return [5 /*yield**/, _loop_1(i)];
                case 2:
                    _a.sent();
                    _a.label = 3;
                case 3:
                    i++;
                    return [3 /*break*/, 1];
                case 4:
                    setFileUploadInProgress(false);
                    getTinyPngCreditsUsed();
                    return [2 /*return*/];
            }
        });
    }); };
    var removeFile = function (imageUpload, index) { return __awaiter(void 0, void 0, void 0, function () {
        var deleteUrl, response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    deleteUrl = "/admin/api/images/".concat(imageUpload.id);
                    return [4 /*yield*/, axios.delete(deleteUrl)];
                case 1:
                    response = _a.sent();
                    if (response.status === 200) {
                        uploadedFileResponses.splice(index, 1);
                        fileUploadProgress.splice(index, 1);
                        uploadedFiles.splice(index, 1);
                        setUploadedFileResponses(__spreadArray([], uploadedFileResponses, true));
                        setUploadProgress(__spreadArray([], fileUploadProgress, true));
                        setUploadedFiles(__spreadArray([], uploadedFiles, true));
                        props.deleteCallback({
                            index: index,
                            imageUpload: imageUpload
                        });
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    var getTinyPngCreditsUsed = function () {
        axios.get(tinyPngCreditsUsedUrl)
            .then(function (response) { return setTinyPngCreditsUsed(response.data); });
    };
    useEffect(function () { getTinyPngCreditsUsed(); }, []);
    return (React.createElement(Formik, { initialValues: {
            imageWidth: props.imageWidth,
            imageHeight: props.imageHeight,
            resizeMethod: props.resizeMethod
        }, onSubmit: function () { return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/];
            });
        }); } }, function (_a) {
        var setFieldValue = _a.setFieldValue;
        return (React.createElement(Form, null,
            React.createElement("div", { className: "row g-3 mt-2" },
                React.createElement("div", { className: "col" },
                    React.createElement("h6", null, "TinyPNG Image Compression & Resize Options"),
                    React.createElement("div", { className: "form-text h6" },
                        "Used ",
                        tinyPngCreditsUsed,
                        "/500 Monthly Credits"),
                    React.createElement("div", { className: "alert alert-warning mt-4", role: "alert" },
                        "Images for this type of asset should ideally be resized to ",
                        props.imageWidth,
                        " x ",
                        props.imageHeight,
                        "px"))),
            React.createElement("div", { className: "row g-3 mt-0" },
                React.createElement("div", { className: "col" },
                    React.createElement("label", { htmlFor: "file", className: "form-label" }, "Width (px)"),
                    React.createElement(Field, { className: "form-control", type: "number", name: "imageWidth", min: "1", max: "4000", step: "1", onChange: function (e) {
                            setFieldValue('imageWidth', e.target.value);
                            setImageWidth(e.target.value);
                        }, disabled: !enableWidth })),
                React.createElement("div", { className: "col" },
                    React.createElement("label", { htmlFor: "file", className: "form-label" }, "Height (px)"),
                    React.createElement(Field, { className: "form-control", type: "number", name: "imageHeight", min: "1", max: "4000", step: "1", onChange: function (e) {
                            setFieldValue('imageHeight', e.target.value);
                            setImageHeight(e.target.value);
                        }, disabled: !enableHeight })),
                React.createElement("div", { className: "col" },
                    React.createElement("label", { htmlFor: "file", className: "form-label" }, "Resize Method"),
                    React.createElement(Field, { className: "form-select", as: "select", name: "resizeMethod", onChange: function (e) {
                            setFieldValue('resizeMethod', e.target.value);
                            setResizeMode(e.target.value);
                        } },
                        React.createElement("option", { value: "FIT" }, "Fit"),
                        React.createElement("option", { value: "SCALE-WIDTH" }, "Scale (Width)"),
                        React.createElement("option", { value: "SCALE-HEIGHT" }, "Scale (Height)"),
                        React.createElement("option", { value: "COVER" }, "Cover"),
                        React.createElement("option", { value: "THUMB" }, "Thumb"))),
                resizeMethod.startsWith('SCALE') &&
                    React.createElement("div", null,
                        React.createElement("img", { width: "200px", src: "".concat(mixam.cdn, "/coloratura/images/tiny-png/api-scale-2x.png"), className: "float-start" }),
                        React.createElement("small", { className: "text-s text-muted" }, "Scales the image down proportionally. You must provide either a target width or a target height, but not both. The scaled image will have exactly the provided width or height.")),
                resizeMethod === 'FIT' &&
                    React.createElement("div", null,
                        React.createElement("img", { width: "200px", src: "".concat(mixam.cdn, "/coloratura/images/tiny-png/api-fit-2x.png"), className: "float-start" }),
                        React.createElement("small", { className: "text-s text-muted" }, "Scales the image down proportionally so that it fits within the given dimensions. You must provide both a width and a height. The scaled image will not exceed either of these dimensions.")),
                resizeMethod === 'COVER' &&
                    React.createElement("div", null,
                        React.createElement("img", { width: "200px", src: "".concat(mixam.cdn, "/coloratura/images/tiny-png/api-cover-2x.png"), className: "float-start" }),
                        React.createElement("small", { className: "text-s text-muted" }, "Scales the image proportionally and crops it if necessary so that the result has exactly the given dimensions. You must provide both a width and a height. Which parts of the image are cropped away is determined automatically. An intelligent algorithm determines the most important areas of your image.")),
                resizeMethod === 'THUMB' &&
                    React.createElement("div", null,
                        React.createElement("img", { width: "200px", src: "".concat(mixam.cdn, "/coloratura/images/tiny-png/api-thumb-2x.png"), className: "float-start" }),
                        React.createElement("small", { className: "text-s text-muted" }, "A more advanced implementation of cover that also detects cut out images with plain backgrounds. The image is scaled down to the width and height you provide. If an image is detected with a free standing object it will add more background space where necessary or crop the unimportant parts."))),
            React.createElement("div", { className: "row mt-3" },
                React.createElement("div", { className: "col" },
                    React.createElement("label", { htmlFor: "file", className: "form-label" }, "Files"),
                    React.createElement("div", { className: "input-group mb-3" },
                        React.createElement(Field, { className: "form-control", type: "file", name: "file", multiple: "multiple", accept: ".jpg,.jpeg,.png", onChange: function (e) {
                                e.preventDefault();
                                setFilesToUpload(e.currentTarget.files);
                            } }),
                        React.createElement("button", { className: "btn btn-primary", type: "button", disabled: fileUploadInProgress, onClick: function (e) {
                                e.preventDefault();
                                uploadFiles();
                            } }, fileUploadInProgress ?
                            React.createElement("span", { className: "spinner-grow text-white", role: "status" })
                            :
                                React.createElement("span", null, "Upload & Scale Images"))))),
            props.imagePreview &&
                React.createElement("div", { className: "row mt-3" },
                    React.createElement("div", { className: "col" },
                        React.createElement("div", { className: "grid mt-3" }, uploadedFiles.map(function (file, index) { return (React.createElement("div", { key: "file-upload-progress-".concat(index), className: "g-col-6 g-col-md-3" },
                            uploadedFileResponses[index] === null &&
                                React.createElement("div", { className: "progress flex-grow-1" },
                                    React.createElement("div", { className: "progress-bar progress-bar-striped progress-bar-animated", role: "progressbar", "aria-valuenow": 75, "aria-valuemin": 0, "aria-valuemax": 100, style: { 'width': "".concat(fileUploadProgress[index], "%") } })),
                            uploadedFileResponses[index] &&
                                React.createElement("div", null,
                                    React.createElement("img", { src: "".concat(uploadedFileResponses[index].url), className: "img-fluid img-thumbnail" }),
                                    React.createElement("small", { className: "text-s text-muted" },
                                        uploadedFileResponses[index].width,
                                        " x ",
                                        uploadedFileResponses[index].height)),
                            React.createElement("div", { className: "d-flex mt-2" },
                                React.createElement("small", { className: "text-s flex-grow-1 text-muted" }, file.name),
                                React.createElement("span", null,
                                    React.createElement("a", { className: "btn btn-sm btn-danger ms-3", onClick: function (e) {
                                            e.preventDefault();
                                            removeFile(uploadedFileResponses[index], index);
                                        } },
                                        React.createElement("i", { className: "bi bi-x-lg" })))))); }))))));
    }));
};
export default ImageUpload;
