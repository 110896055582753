export default {
    DEFAULT: 0,
    HARDCOVER_BOOKS: 100001,
    PAPERBACK_BOOKS: 100002,
    PERFECT_BOUND_BOOKLETS: 100003,
    WIRO_BOUND_BOOKLET: 100004,
    LOOP_BOUND_BOOKLET: 100005,
    STAPLED_BOOKLET: 100006,
    MAGAZINE: 100007,
    CATALOGUE: 100008,
    BOOKLETS: 100009,
    ZINE: 100010,
    COMICS: 100011,
    ART_PRINTS: 100012,
    MANGA: 100013,
    WEDDING_BOOK: 100014,
    YEARBOOK: 100015,
    COOKBOOK: 100016,
    COLORING_BOOK: 100017,
    ART_BOOK: 100018,
    GRAPHIC_NOVELS: 100019,
    LOOKBOOK: 100020,
    MENUS: 100021,
    CLASSIC_BOOKS: 100022,
    DIARIES: 100023,
    JOURNALS: 100024,
    STICKER_SQUARE_RECTANGLE: 100025,
    STICKER_CIRCLE: 100026,
    STICKER_OVAL: 100027,
    STICKER_ROUNDED_RECTANGLE: 100028,
    PAPERBACK_READING_BOOK: 100029,
    FAMILY_HISTORY_BOOKS: 100030,
    ACTIVITY_BOOKS: 100031,
    MEMOIRS_BOOKS: 100032,
    CHILDREN_BOOKS: 100033,
    WORKBOOKS: 100034,
    PHOTO_BOOKS: 100035,
    PHOTOGRAPHY_BOOKS: 100036,
    COFFEE_TABLE_BOOKS: 100037,
    MANUALS_BOOKS: 100038,
    BABY_BOOKS: 100039,
    BIOGRAPHY: 100040,
    NOVELS: 100041,
    POETRY_BOOKS: 100042,
    SPIRAL_BOUND_BOOKLET: 100043,
    GICLEE_ART_PRINTS_SMALL_FORMAT: 100044,
    GICLEE_ART_PRINTS_LARGE_FORMAT: 100045,
    STAPLED_CALENDARS: 100046,
    NOTEBOOKS: 100047,
    BOOKMARKS: 100048,
    WIRO_CALENDARS: 100049,
    THESES: 100050,
    DISSERTATIONS: 100051,
    TEXTBOOKS: 100052,
    HANDBOOKS: 100053,

    getBadgeColour: function (subProductId) {
        switch (subProductId) {
            case this.DEFAULT:
                return 'primary';
            default:
                return 'secondary';
        }
    }
};