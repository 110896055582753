import mixam from '../../boot/mixam';
import WithSubmitAnimation from '../with-submit-zoom-animation';
import WithNormalizeMemberOrders from '../with-normalize-member-orders';
import Mustache from '../../../components/mustache/mustache';
import uploadItemTemplate from 'text!../../../appes6/templates/member/uploadItem.mustache';
import defineComponent from '../../../components/flight/lib/component';
import utils from '../../../components/flight/lib/utils';
import DisputeRequestAction from './dispute-requst-action';
import renderDisputesTemplate from 'text!../../../appes6/templates/member/dispute.mustache';

export default defineComponent(WithNormalizeMemberOrders, ProblemEditor, WithSubmitAnimation);

function ProblemEditor() {

    this.attributes({
        dragging: $(),
        saveBtnSelector: '[type="submit"]',
        formSelector: "form",
        uploadSelector: '[data-type="upload-input"]',
        uploadManagerSelector: '[data-type="upload-manager"]',
        progressSelector: '[data-type="progress-bar"]',
        progressBarSelector: '[data-type="progress-bar"] .bar',
        fileListSelector: '[data-type="upload-file-list"]',
        removeResourceSelector: '[data-type="remove-resource"]',
        problemCategorySelector: '[name="problemCategory"]',
        requestCategorySelector: '[name="requestCategory"]',
        attachmentsSelector: '[name="attachments"]',
        attachmentNamesSelector: '[name="attachmentNames"]',
        disputeRequstActionSelector: '[data-type="dispute-requst-action"]',
    });

    this.uploadStart = function (event) {
        this.select('uploadSelector').simpleUpload(this.uploadUrl, {
            expect: 'json',
            start: file => this.add(file),
            progress: progress => this.progress(progress),
            success: response => this.uploadSuccess(response),
            error: error => this.trigger("log", error)
        });
    };

    this.progress = function (percent) {
        const progress = Math.round(percent);

        if (progress > 0) {
            this.select('progressSelector').removeClass('hidden');
        } else {
            this.select('progressSelector').addClass('hidden');
        }

        this.select('progressBarSelector').css(
            'width',
            progress + '%'
        );
    };

    this.uploadSuccess = function (response) {
        if ("success" === response.status && response.id) {
            this.progress(0);
            this.attatchments.push({
                id: response.id,
                name: response.name,
                size: response.size,
                url: response.url,
                thumbnail: response.thumbnail,
            });
            this.paintAttachments();
        }
    };

    this.removeResource = function (event) {
        event.preventDefault();
        const id = $(event.target).closest("a").data('resource');
        this.attatchments = this.attatchments.filter(a => a.id !== id);
        this.paintAttachments();
    };

    this.renderDispute = function (dispute) {
        this.$node.html(Mustache.render(renderDisputesTemplate, {disputes: [this.normalizeDispute(dispute)]}));
    };

    this.paintAttachments = function () {
        this.select('fileListSelector').html(Mustache.render(uploadItemTemplate, this.attatchments));
    };

    this.handleFormSubmit = function (event) {
        if (!event.isDefaultPrevented()) {
            const value = JSON.stringify(this.attatchments);
            this.select('attachmentsSelector').val(value);
            this.handleSubmit(event);
            event.preventDefault();
            const $form = this.select('formSelector');
            $.post($form.attr('action'), this.select('formSelector').serialize(), null, "json")
                .done(data => {
                    console.log("handleFormSubmit->", data);
                    this.renderDispute(data);
                })
                .fail((err) => this.trigger("log", {message: err}));
        }
    };

    this.dragEnter = function (event) {
        event.preventDefault();
        if (this.hasFiles(event)) {
            if (this.attr.dragging.size() === 0) {
                this.trigger('dndHoverStart');
            }
            this.attr.dragging = this.attr.dragging.add(event.target);
        }
    };

    this.dragLeave = function (event) {
        event.preventDefault();
        setTimeout(() => {
            this.attr.dragging = this.attr.dragging.not(event.target);
            if (this.attr.dragging.size() === 0) {
                this.trigger('dndHoverEnd');
            }
        }, 1);
    };

    this.hasFiles = function (event) {
        try {
            if (event.originalEvent.dataTransfer) {
                for (let dataTransferItem of event.originalEvent.dataTransfer.items) {
                    if (dataTransferItem.kind === "file") {
                        return true;
                    }
                }
            }
            return false;
        } catch (ex) {
            this.trigger("log", ex);
        }
        return true;
    };

    this.dragHoverStarts = function (/*event*/) {
        this.$node.addClass('drag-over');
    };

    this.dragHoverEnds = function (event) {
        event.preventDefault();
        event.stopPropagation();
        this.$node.removeClass('drag-over');
        this.attr.dragging = $();
        /* jshint ignore:start */
        const files = event.originalEvent?.dataTransfer?.files;
        /* jshint ignore:end */
        if (files) {
            Array.from(files).forEach(file => this.dropUploadStart(file));
        }
    };

    this.dropUploadStart = function (file) {
        $.fn.simpleUpload(this.uploadUrl, {
            files: [file],
            start: file => this.add(file),
            progress: progress => this.progress(progress),
            success: response => this.uploadSuccess(response),
            error: error => this.trigger("log", error)
        });
    };

    this.add = function (file) {
        const o = file;
        o.fileSize = file.size / 1000;
        o.date = mixam.dateToLongString(o.lastModified);
        o.canRemove = 1;
        console.log("file->", file);
        this.progress(0);
    };

    this.categoryChanged = function (event) {
        this.select('problemCategorySelector').find("option").eq(0).remove();
    };

    this.actionChanged = function (event) {
        this.select('requestCategorySelector').find("option").eq(0).remove();
    };

    this.after('initialize', function () {
        this.attatchments = [];
        this.uploadUrl = this.select('uploadManagerSelector').data('uploadUrl');
        this.on('submit', {
            formSelector: this.handleFormSubmit
        });

        this.on("change", {
            problemCategorySelector: utils.once((event) => this.categoryChanged(event)),
            requestCategorySelector: utils.once((event) => this.actionChanged(event)),
            uploadSelector: this.uploadStart
        });

        this.on("click", {
            removeResourceSelector: this.removeResource
        });

        this.on('dndHoverStart', this.dragHoverStarts);
        this.on('dndHoverEnd', this.dragHoverEnds);

        this.$node.on({
            dragover: (event) => event.preventDefault(),
            dragenter: (event) => this.dragEnter(event),
            dragleave: (event) => this.dragLeave(event),
            drop: (event) => this.dragHoverEnds(event)
        });

        DisputeRequestAction.attachTo(this.select(`disputeRequstActionSelector`));

    });
}
