import defineComponent from '../../../../../components/flight/lib/component';
import DataTable from '../../../table/data-table-net/data-table-net.tsx';
import axios from "axios";
import mixam from "../../../../boot/mixam";
import WithSignDataRequest from "../../../with-sign-data-request";

export default defineComponent(AdminFulfillmentReport, WithSignDataRequest);

function AdminFulfillmentReport() {

    this.attributes({
        fulfillmentReportUrl: `${mixam.reporterOrigin}/reporter/admin/api/shops/${mixam.shop.id}/fulfillment`,
        dataTableSelector: '[data-type="data-table"]',
        fromDateSelector: '[data-type="date-from"]',
        toDateSelector: '[data-type="date-to"]',
        dataLoaderSelector: '[data-type="data-loader"]'
    });

    this.createSchema = function () {
        const cols = [];

        cols.push({
            title: "Name",
            type: "text",
            data: "name"
        });

        cols.push({
            title: "Accepted",
            type: "number",
            data: "acceptedCount"
        });

        cols.push({
            title: "Locked",
            type: "number",
            data: "lockedCount"
        });

        cols.push({
            title: "On Hold",
            type: "number",
            data: "onHoldCount"
        });

        cols.push({
            title: "Confirmed",
            type: "number",
            data: "confirmedCount"
        });

        cols.push({
            title: "In Production",
            type: "number",
            data: "inProductionCount"
        });

        cols.push({
            title: "Dispatched",
            type: "number",
            data: "dispatchedCount"
        });

        cols.push({
            title: "Canceled",
            type: "number",
            data: "canceledCount"
        });

        cols.push({
            title: "Total Items",
            type: "number",
            data: "total",
            defaultSort: true,
            sortOrder: 'desc'
        });

        cols.push({
            title: "Total Cost (Supplier)",
            type: "number",
            data: "totalCost",
            decimal: 2,
            sum: true,
            currency: true,
        });

        cols.push({
            title: "Total Cost (Customer)",
            type: "number",
            data: "totalValue",
            decimal: 2,
            sum: true,
            currency: true,
        });

        cols.push({
            title: "Total Discount (Customer)",
            type: "number",
            data: "totalDiscountValue",
            decimal: 2,
            sum: true,
            currency: true,
        });

        cols.push({
            title: "Commission (Publisher)",
            type: "number",
            data: "totalCommissionValue",
            decimal: 2,
            sum: true,
            currency: true,
        });

        cols.push({
            title: "Total After Discount (Customer)",
            type: "number",
            data: "totalAfterDiscountValue",
            decimal: 2,
            sum: true,
            currency: true,
        });

        cols.push({
            title: "Total After Discount & Commission",
            type: "number",
            data: "totalAfterDiscountAndCommission",
            decimal: 2,
            sum: true,
            currency: true,
        });

        cols.push({
            title: "Margin",
            type: "number",
            data: "margin",
            decimal: 2,
            sum: true,
            currency: true,
        });

        cols.push({
            title: "Margin %",
            type: "number",
            data: "marginPercentage",
            decimal: 2,
            sum: true
        });

        return cols;
    };

    this.paint = function () {
        const data = {
            columns: this.createSchema(),
            fileName: "Search",
            response: this.normalize(this.data)
        };

        if (this.dataReadyFired) {
            window.dispatchEvent(new CustomEvent('uiDataUpdate', { detail: data }));
        } else {
            this.dataReadyFired = true;
            this.trigger("uiDataReady", data);
        }
    };

    this.getData = function () {
        this.disableDateSelectors();
        this.signDataRequest(token => this.getFulfillmentData(token));
    };

    this.getFulfillmentData = function (token) {

        const fromDate = this.select('fromDateSelector').val();
        const toDate = this.select('toDateSelector').val();
        const fulfilmentReport = this;

        axios.get(this.attr.fulfillmentReportUrl,
            {
                params: {fromDate: fromDate, toDate: toDate},
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            .then((response) => {
                this.data = response.data;
                this.paint();
                fulfilmentReport.resetDateSelectors();
            }).catch(error => {
                alert(`Failed To Retrieve Data: ${error}`);
                fulfilmentReport.resetDateSelectors();
            });
    };

    this.disableDateSelectors = function () {
        this.select('fromDateSelector').addClass('disabled');
        this.select('toDateSelector').addClass('disabled');
        this.select('fromDateSelector').prop( "disabled", true);
        this.select('toDateSelector').prop( "disabled", true);
        this.select('dataLoaderSelector').removeClass('d-none');
    };

    this.resetDateSelectors = function () {
        this.select('fromDateSelector').removeClass('disabled');
        this.select('toDateSelector').removeClass('disabled');
        this.select('fromDateSelector').prop( "disabled", false);
        this.select('toDateSelector').prop( "disabled", false);
        this.select('dataLoaderSelector').addClass('d-none');
    };

    this.normalize = function (list) {
        return list.map(item => this.normalizeItem(item));
    };

    this.normalizeItem = function (item) {
        const result = $.extend(true, {}, item);
        result.totalAfterDiscountAndCommission = result.totalAfterDiscountValue - result.totalCommissionValue;
        result.margin = result.totalAfterDiscountAndCommission - item.totalCost;
        result.marginPercentage = 0;

        if (result.margin >= 0) {
            result.marginPercentage = (result.margin / result.totalAfterDiscountAndCommission) * 100;
        } else {
            result.marginPercentage = (result.margin / item.totalCost) * 100;
        }
        return result;
    };

    this.after('initialize', function () {

        DataTable.attachTo(this.select('dataTableSelector'));

        setTimeout(() => this.getData(), 10);

        this.on('change', {
            fromDateSelector: this.getData,
            toDateSelector: this.getData
        });
    });
}
