var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
// @ts-ignore
import defineComponent from '../../../../components/flight/lib/component.js';
import React, { useState, useEffect, useMemo } from 'react';
import ReactDom from 'react-dom/client.js';
import DataTable from 'react-data-table-component';
import DataTableToolbar from './DataTableToolbar';
import { getTableCell, ExpandedComponent, round, formatAsCurrency, checkObjContainsValue } from './util';
import { Wrapper, DataTableWrapper, } from './StyledDataTableNet';
import { CELL_TYPES } from './DataTableConstants';
var ReactDataTable = function (_a) {
    var columns = _a.columns, originalData = _a.originalData, largestBreakpoint = _a.largestBreakpoint;
    var _b = useState(originalData), unfilteredData = _b[0], setUnfilteredData = _b[1];
    var _c = useState(originalData), searchBuilderData = _c[0], setSearchBuilderData = _c[1];
    var _d = useState(originalData), globalSearchData = _d[0], setGlobalSearchData = _d[1];
    var _e = useState([]), highlightedRowIds = _e[0], setHighlightedRowIds = _e[1];
    useEffect(function () {
        var handleUiDataUpdate = function (event) { return setUnfilteredData(event.detail.response); };
        var handleHighlightDataTableRow = function (event) {
            var rowToHighlightId = event.detail.rowToHighlightId;
            if (!highlightedRowIds.includes(rowToHighlightId)) {
                var newRowsToHighlight = __spreadArray([], highlightedRowIds, true);
                newRowsToHighlight.push(rowToHighlightId);
                setHighlightedRowIds(__spreadArray([], newRowsToHighlight, true));
                setTimeout(function () {
                    setHighlightedRowIds(highlightedRowIds.filter(function (rowId) { return rowId !== rowToHighlightId; }));
                }, 10000);
            }
        };
        window.addEventListener('uiDataUpdate', handleUiDataUpdate);
        window.addEventListener('highlightDataTableRow', handleHighlightDataTableRow);
        return function () {
            window.removeEventListener("uiDataUpdate", handleUiDataUpdate);
            window.removeEventListener("highlightDataTableRow", handleHighlightDataTableRow);
        };
    }, []);
    var formatColumnData = function () {
        columns.forEach(function (column) {
            var columnData = column.data;
            column.name = column.title;
            column.selector = function (row) { return row[columnData]; };
            if (checkObjContainsValue(CELL_TYPES, column.type)) {
                column.cell = function (row) {
                    return getTableCell(column.type, row, columnData);
                };
            }
            if (column.decimal) {
                column.format = function (row) { return round(row[columnData], column.decimal); };
            }
            if (column.currency) {
                column.format = function (row) { return formatAsCurrency(column, row); };
            }
            // custom column settings
            column.sortable = true;
            column.reorder = true;
            column.wrap = true;
            column.minWidth = '1px';
            column.compact = true;
            column.allowOverflow = true;
            column.style = {
                padding: '10px 10px',
            };
        });
    };
    formatColumnData();
    // See If A Column Is The Default Sort Field
    var defaultSortFieldId = columns.findIndex(function (column) { return 'defaultSort' in column && column.defaultSort === true; });
    var defaultSortOrderAsc = true;
    if (defaultSortFieldId >= 0) {
        if ('sortOrder' in columns[defaultSortFieldId] && columns[defaultSortFieldId].sortOrder === 'desc') {
            defaultSortOrderAsc = false;
        }
    }
    // Sort Column Is Based Upon Index Starting At 1
    defaultSortFieldId = defaultSortFieldId === -1 ? 1 : defaultSortFieldId + 1;
    var tableName = useMemo(function () { return window.location.pathname.match(/\/([^/]+)$/)[1]; }, [window.location.pathname]);
    var conditionalRowStyles = [
        {
            // The "items" table has a different structure from other tables so we need to take that into account when highlighting rows
            when: function (row) { return highlightedRowIds.includes(tableName === 'items' ? row.order.id : row.id); },
            style: {
                backgroundColor: '#f7f76f'
            }
        }
    ];
    return (React.createElement(Wrapper, null,
        React.createElement(DataTableToolbar, { columns: columns, unfilteredData: unfilteredData, setSearchBuilderData: setSearchBuilderData, searchBuilderData: searchBuilderData, setGlobalSearchData: setGlobalSearchData }),
        React.createElement(DataTableWrapper, { largestBreakpoint: largestBreakpoint },
            React.createElement(DataTable, { data: globalSearchData, columns: columns, pagination: true, paginationPerPage: 50, paginationRowsPerPageOptions: [25, 50, 75, 100], highlightOnHover: true, persistTableHead: true, striped: true, 
                // selectableRows
                expandableRows: true, defaultSortFieldId: defaultSortFieldId, defaultSortAsc: defaultSortOrderAsc, expandableRowsComponent: function (data) {
                    return ExpandedComponent(data, columns);
                }, subHeader: true, conditionalRowStyles: conditionalRowStyles, sortIcon: React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: "16", height: "16", fill: "currentColor", className: "bi bi-caret-down-fill", viewBox: "0 0 16 16" },
                    React.createElement("path", { d: "M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" })) }))));
};
function DataTableNet() {
    this.attributes({
        containerSelector: 'section.data-table',
    });
    var getLargestBreakpoint = function (columns) {
        var largestBreakpoint = 0;
        columns.forEach(function (_a) {
            var hide = _a.hide;
            if (hide > largestBreakpoint)
                largestBreakpoint = hide;
        });
        return largestBreakpoint;
    };
    this.renderDataTable = function (event, _a) {
        var response = _a.response, columns = _a.columns;
        var root = ReactDom.createRoot(this.select('containerSelector')[0]);
        root.render(React.createElement(ReactDataTable, { columns: columns, originalData: response, largestBreakpoint: getLargestBreakpoint(columns) }));
    };
    this.after('initialize', function () {
        var _this = this;
        this.on(document, 'uiDataReady', function (event, data) {
            _this.renderDataTable(event, data);
        });
    });
}
export default defineComponent(DataTableNet);
