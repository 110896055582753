var _a;
import { ChargeItemType } from "@mixam-platform/mixam-types";
// Will Need Moving To i18n When We Put It In
export var ChargeItemTypeMap = (_a = {},
    _a[ChargeItemType.BESPOKE_QUOTE] = 'Bespoke Quote',
    _a[ChargeItemType.CREDIT] = 'Credit',
    _a[ChargeItemType.DELIVERY_ADJUSTMENT] = 'Delivery Adjustment',
    _a[ChargeItemType.REFUND] = 'Refund',
    _a[ChargeItemType.OTHER] = 'Other',
    _a[ChargeItemType.DIRECT_MAIL] = 'Direct Mail',
    _a[ChargeItemType.PAYMENT_TRANSFER] = 'Payment Transfer',
    _a[ChargeItemType.PRICE_ADJUSTMENT] = 'Price Adjustment',
    _a[ChargeItemType.REPRINT] = 'Reprint',
    _a[ChargeItemType.SPLIT_DELIVERY] = 'Split Delivery',
    _a[ChargeItemType.TAX_ADJUSTMENT] = 'Tax Adjustment',
    _a);
