import defineComponent from '../../../components/flight/lib/component';

export default defineComponent(YearSelect);

function YearSelect() {

    this.attributes({
        selectSelector: 'select'
    });

    this.populateYears = function(event, data) {
        const years = this.populateAvailYears(data);
        const yearSelectField = this.select('selectSelector')[0];
        const now = new Date(); // NB: we are assuming the calendar is always initially populated for the current year
        years.forEach(year => {
            yearSelectField.options.add(new Option(year, year, false,
                now.getFullYear() === parseInt(year, 10)));
        });
    };

    this.populateAvailYears = function (list) {
        const map = {};
        Object.keys(list.data).forEach( k => map["" + list.data[k].year] = 1);
        return Object.keys(map).sort((a,b) => b - a);
    };

    this.onSelectChange = function (/*event*/) {
       this.trigger('uiSelectYear', { year: this.select('selectSelector').val() });
    };


    this.after('initialize', function() {
        this.on('change', {
            selectSelector: this.onSelectChange
        });
        this.on(document, 'uiSetYearsList', this.populateYears);
    });
}
