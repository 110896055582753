var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useEffect, useState } from 'react';
import { Button, Form, FormCheck, FormControl } from './components/styled';
import Col from 'react-bootstrap/Col';
import InputGroup from 'react-bootstrap/InputGroup';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Alert from 'react-bootstrap/Alert';
import Spinner from 'react-bootstrap/Spinner';
import * as yup from 'yup';
import { FieldArray, Formik } from 'formik';
import { MarkupType, PreviewType } from "./types";
import axios from "axios";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import regional from "../../../../boot/regional.js";
import i18nShop from "../../../../../assets/i18n/i18n";
import { I18nextProvider } from 'react-i18next';
import { selectCurrency } from "./CurrencyUtils";
import { PriceBreakdown } from "./PriceBreakdown";
var schema = yup.object().shape({
    title: yup.string().required('Publication title is required'),
    description: yup.string().required('Publication description is required'),
    active: yup.boolean().required(),
    authors: yup.array().of(yup.object().shape({
        name: yup.string().required('Author name is required'),
        bio: yup.string().max(2048, 'Author bio must be 2048 characters or fewer')
    })).required().min(1, 'Publications must have at least 1 author'),
    markupType: yup.string().required(),
    fixedMarkup: yup.number().when('markupType', {
        is: MarkupType.FIXED_MARKUP,
        then: function (schema) { return schema.required('Fixed markup is required')
            .min(0.01, 'Fixed markup must be greater than 0')
            .max(99999, 'Fixed markup maximum is 99999'); }
    }),
    percentageMarkup: yup.number().when('markupType', {
        is: MarkupType.PERCENTAGE,
        then: function (schema) { return schema.required('Markup percentage is required')
            .min(0.01, 'Percentage markup must be greater than 0')
            .max(99999, 'Percentage markup maximum is 99999'); }
    }),
    productionCost: yup.number().required('Production cost is required')
        .min(0, 'Production cost cannot be negative'),
    fixedPrice: yup.number().when('markupType', {
        is: MarkupType.FIXED_RETAIL,
        then: function (schema) { return schema.required('Fixed retail is required')
            .min(0.01, 'Fixed retail must be greater than 0')
            .max(99999, 'Fixed retail maximum is 99999')
            .test('is-greater', 'Fixed retail must be greater than production cost', function (value) {
            var productionCost = this.parent.productionCost;
            if (value < productionCost) {
                return this.createError({
                    message: "Fixed retail must be greater than our production cost of ".concat(productionCost.toFixed(2)),
                    path: 'fixedPrice',
                });
            }
            return true;
        }); }
    })
});
var getPublicationConfig = function (orderId, itemId) { return __awaiter(void 0, void 0, void 0, function () {
    var response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, axios.get("/api/orders/".concat(orderId, "/items/").concat(itemId, "/publication"))];
            case 1:
                response = _a.sent();
                if (response.status === 200) {
                    return [2 /*return*/, response.data];
                }
                return [2 /*return*/, undefined];
        }
    });
}); };
var getProductionCost = function (subProductId, productName, itemSpecification, podPolicyId) { return __awaiter(void 0, void 0, void 0, function () {
    var offerRequest, response, offer;
    var _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                offerRequest = {
                    subProductId: subProductId,
                    productName: productName,
                    freeTest: 0,
                    playground: 0,
                    publisher: '',
                    postCode: '',
                    santaType: 'PUBLICATION',
                    itemSpecification: itemSpecification,
                    requestId: Math.random(),
                    policyId: podPolicyId
                };
                return [4 /*yield*/, axios.post('/api/offer', offerRequest)];
            case 1:
                response = _b.sent();
                if (response.status === 200) {
                    if (((_a = response.data.offerTable) === null || _a === void 0 ? void 0 : _a.length) > 0) {
                        offer = response.data.offerTable[0];
                        return [2 /*return*/, offer.price];
                    }
                }
                return [2 /*return*/, 0];
        }
    });
}); };
export var PublicationSettings = function (props) {
    var _a;
    var _b = useState(undefined), publicationConfig = _b[0], setPublicationConfig = _b[1];
    var _c = useState(0), productionCost = _c[0], setProductionCost = _c[1];
    var _d = useState(true), requestingProductionCost = _d[0], setRequestingProductionCost = _d[1];
    var _e = useState(false), copyLink = _e[0], setCopyLink = _e[1];
    var _f = useState(false), hasOffer = _f[0], setOffer = _f[1];
    useEffect(function () {
        getPublicationConfig(props.orderId, props.itemId)
            .then(function (config) { return __awaiter(void 0, void 0, void 0, function () {
            var cost;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        setPublicationConfig(config);
                        return [4 /*yield*/, getProductionCost(props.subProductId, props.productName, props.itemSpecification, props.podPolicyId)];
                    case 1:
                        cost = _a.sent();
                        setProductionCost(cost);
                        if (cost > 0) {
                            setOffer(true);
                        }
                        setRequestingProductionCost(false);
                        return [2 /*return*/];
                }
            });
        }); });
    }, []);
    return (React.createElement(I18nextProvider, { i18n: i18nShop },
        React.createElement(React.Fragment, null, publicationConfig && productionCost > 0 &&
            React.createElement(Formik, { validationSchema: schema, onSubmit: function (values) { return __awaiter(void 0, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                publicationConfig.title = values.title;
                                publicationConfig.description = values.description;
                                publicationConfig.active = values.active;
                                publicationConfig.authors = values.authors;
                                publicationConfig.markup.type = values.markupType;
                                publicationConfig.preview.previewType = values.previewType;
                                publicationConfig.preview.maxPages = values.previewMaxPages;
                                // publicationConfig.policyId = values.policyId;
                                switch (values.markupType) {
                                    case MarkupType.NONE:
                                    case MarkupType.DISTRIBUTION:
                                        publicationConfig.markup.value = 0;
                                        break;
                                    case MarkupType.FIXED_MARKUP:
                                        publicationConfig.markup.value = Number(values.fixedMarkup);
                                        break;
                                    case MarkupType.PERCENTAGE:
                                        publicationConfig.markup.value = Number(values.percentageMarkup);
                                        break;
                                    case MarkupType.FIXED_RETAIL:
                                        publicationConfig.markup.value = Number(values.fixedPrice);
                                        break;
                                }
                                return [4 /*yield*/, axios.put("/api/orders/".concat(props.orderId, "/items/").concat(props.itemId, "/publication"), publicationConfig)
                                        .then(function () {
                                        window.dispatchEvent(new CustomEvent('showFixedMessage', { detail: { message: regional().messages[1059] } }));
                                    })];
                            case 1:
                                _a.sent();
                                return [2 /*return*/];
                        }
                    });
                }); }, initialValues: {
                    title: publicationConfig.title || '',
                    authors: publicationConfig.authors.length > 0 ? publicationConfig.authors : [{ name: '', bio: '' }],
                    description: publicationConfig.description || '',
                    active: publicationConfig.active,
                    markupType: publicationConfig.markup.type,
                    fixedMarkup: publicationConfig.markup.type === MarkupType.FIXED_MARKUP ? publicationConfig.markup.value : '',
                    percentageMarkup: publicationConfig.markup.type === MarkupType.PERCENTAGE ? (_a = publicationConfig.markup) === null || _a === void 0 ? void 0 : _a.value : '',
                    fixedPrice: publicationConfig.markup.type === MarkupType.FIXED_RETAIL ? publicationConfig.markup.value : '',
                    productionCost: productionCost,
                    itemSpecification: props.itemSpecification,
                    subProductId: props.subProductId,
                    productName: props.productName,
                    locale: props.locale,
                    orderId: props.orderId,
                    itemId: props.itemId,
                    shareId: props.shareId,
                    previewType: publicationConfig.preview.previewType,
                    previewMaxPages: publicationConfig.preview.maxPages
                } }, function (_a) {
                var handleSubmit = _a.handleSubmit, handleChange = _a.handleChange, handleBlur = _a.handleBlur, values = _a.values, touched = _a.touched, errors = _a.errors, isSubmitting = _a.isSubmitting;
                return (React.createElement(Form, { noValidate: true, onSubmit: handleSubmit },
                    React.createElement(Card, { bg: "primary" },
                        React.createElement(Card.Header, null, i18nShop.t('publicationSettings.cardHeaderTitle')),
                        React.createElement(Card.Body, null,
                            React.createElement(Row, { className: "mb-3" },
                                React.createElement(Form.Group, { as: Col, md: "12", controlId: "publicationTitle" },
                                    React.createElement(Form.Label, null, i18nShop.t('publicationSettings.detailsTitle')),
                                    React.createElement(FormControl, { type: "text", placeholder: i18nShop.t('publicationSettings.detailsTitlePlaceholder'), name: "title", value: values.title, onChange: handleChange, onBlur: handleBlur, isInvalid: touched.title && !!errors.title }),
                                    React.createElement(Form.Control.Feedback, { type: "invalid" }, errors.title))),
                            React.createElement(Row, { className: "mb-3" },
                                React.createElement(Form.Group, { as: Col, md: "12", controlId: "publicationDescription" },
                                    React.createElement(Form.Label, null, i18nShop.t('publicationSettings.descriptionTitle')),
                                    React.createElement(Form.Control, { as: "textarea", placeholder: i18nShop.t('publicationSettings.descriptionTitlePlaceholder'), name: "description", value: values.description, onChange: handleChange, onBlur: handleBlur, isInvalid: touched.description && !!errors.description }),
                                    React.createElement(Form.Control.Feedback, { type: "invalid" }, errors.description))),
                            React.createElement(Row, { className: "mb-3" },
                                React.createElement(Form.Group, { as: Col, md: "6", controlId: "publicationAuthors" },
                                    React.createElement(Form.Label, null, i18nShop.t('publicationSettings.authorsTitle')),
                                    React.createElement(FieldArray, { name: "authors", render: function (arrayHelpers) { return (React.createElement("div", null,
                                            values.authors.map(function (author, index) { return (React.createElement("div", { className: "card p-2 mb-3", key: "container-".concat(index) },
                                                React.createElement(InputGroup, { className: "mb-3", key: "author-name-".concat(index) },
                                                    React.createElement(FormControl, { type: "text", placeholder: i18nShop.t('publicationSettings.authorsNamePlaceholder'), name: "authors.".concat(index, ".name"), value: values.authors[index].name, onChange: handleChange, onBlur: handleBlur, isInvalid: Array.isArray(touched.authors) && touched.authors[index] !== undefined && Array.isArray(errors.authors) && errors.authors[index] !== undefined && Object.keys(errors.authors[index]).indexOf('name') !== -1 }),
                                                    React.createElement(Button, { variant: "primary", onClick: function () { return arrayHelpers.remove(index); }, disabled: values.authors.length === 1 }, "Remove"),
                                                    React.createElement(Form.Control.Feedback, { type: "invalid" }, Array.isArray(errors.authors) && errors.authors[index] !== undefined && Object.keys(errors.authors[index]).indexOf('name') !== -1 &&
                                                        React.createElement("span", null, Object(errors.authors[index]).name))),
                                                React.createElement(InputGroup, { key: "author-bio-".concat(index) },
                                                    React.createElement(Form.Control, { as: "textarea", placeholder: i18nShop.t('publicationSettings.authorsBioPlaceholder'), name: "authors.".concat(index, ".bio"), value: values.authors[index].bio, onChange: handleChange, onBlur: handleBlur, isInvalid: Array.isArray(touched.authors) && touched.authors[index] !== undefined && Array.isArray(errors.authors) && errors.authors[index] !== undefined && Object.keys(errors.authors[index]).indexOf('bio') !== -1 }),
                                                    React.createElement(Form.Control.Feedback, { type: "invalid" }, Array.isArray(errors.authors) && errors.authors[index] !== undefined && Object.keys(errors.authors[index]).indexOf('bio') !== -1 &&
                                                        React.createElement("span", null, Object(errors.authors[index]).bio))))); }),
                                            React.createElement(Button, { variant: "primary", onClick: function () { return arrayHelpers.push(''); } }, i18nShop.t('publicationSettings.authorsAddAuthor')))); } }))),
                            React.createElement(Row, { className: "mb-3" },
                                React.createElement(Form.Group, { as: Col, md: "6", controlId: "publicationpreviewType" },
                                    React.createElement(Form.Label, null, i18nShop.t('publicationSettings.previewTypeTitle')),
                                    React.createElement(Form.Select, { label: "Preview Type", name: "previewType", onChange: handleChange, onBlur: handleBlur, value: values.previewType, isInvalid: touched.previewType && !!errors.previewType },
                                        React.createElement("option", { value: PreviewType.NONE }, i18nShop.t('publicationSettings.previewTypeShowNone')),
                                        React.createElement("option", { value: PreviewType.ALL_PAGES }, i18nShop.t('publicationSettings.previewTypeShowAllPages')),
                                        React.createElement("option", { value: PreviewType.LIMITED_PAGES }, i18nShop.t('publicationSettings.previewTypeShowLimitedPages'))),
                                    React.createElement(Form.Control.Feedback, { type: "invalid" }, errors.previewType))),
                            values.previewType === PreviewType.LIMITED_PAGES &&
                                React.createElement(Row, { className: "mb-3" },
                                    React.createElement(Form.Group, { as: Col, md: "6", controlId: "publicationPreviewMaxPages" },
                                        React.createElement(Form.Label, null, i18nShop.t('publicationSettings.previewMaxPagesTitle')),
                                        React.createElement(FormControl, { type: "number", name: "previewMaxPages", value: values.previewMaxPages, onChange: handleChange, onBlur: handleBlur, isInvalid: touched.previewMaxPages && !!errors.previewMaxPages }),
                                        React.createElement(Form.Control.Feedback, { type: "invalid" }, errors.active))))),
                    React.createElement(Card, { className: "mt-3 bg-dark text-white" },
                        React.createElement(Card.Header, null, i18nShop.t('publicationSettings.pricingTitle')),
                        React.createElement(Card.Body, null,
                            React.createElement(Row, { xs: 1, md: 2, className: "g-4" },
                                React.createElement(Col, { md: 8 },
                                    React.createElement(Card, null,
                                        React.createElement(Card.Body, null,
                                            React.createElement("h5", { className: "mb-3 border-bottom pb-2" }, i18nShop.t('publicationSettings.pricingStrategy')),
                                            React.createElement(Row, null,
                                                React.createElement(Form.Group, { as: Col, controlId: "markupTypeOptions" },
                                                    React.createElement(FormCheck, { id: MarkupType.NONE, type: "radio", name: "markupType", label: i18nShop.t('publicationSettings.pricingMarkupTypeNone'), value: MarkupType.NONE, onChange: handleChange, onBlur: handleBlur, checked: values.markupType === MarkupType.NONE, isInvalid: touched.markupType && !!errors.markupType }),
                                                    values.markupType === MarkupType.DISTRIBUTION &&
                                                        React.createElement(Row, null,
                                                            React.createElement("div", { className: "ps-5 pe-5 mt-3" },
                                                                React.createElement("p", null, i18nShop.t('publicationSettings.pricingMarkupTypeDistribution')))),
                                                    React.createElement(FormCheck, { id: MarkupType.FIXED_MARKUP, type: "radio", name: "markupType", label: i18nShop.t('publicationSettings.pricingMarkupTypeFixedMarkup'), value: MarkupType.FIXED_MARKUP, onChange: handleChange, onBlur: handleBlur, checked: values.markupType === MarkupType.FIXED_MARKUP, isInvalid: touched.markupType && !!errors.markupType }),
                                                    values.markupType === MarkupType.FIXED_MARKUP &&
                                                        React.createElement(Row, null,
                                                            React.createElement("div", { className: "ps-5 pe-5 mt-3" },
                                                                React.createElement(Form.Group, { controlId: "markupFixedPrice" },
                                                                    React.createElement(InputGroup, { className: "mb-3 w-50" },
                                                                        React.createElement(InputGroup.Text, null, selectCurrency(props.locale).symbol),
                                                                        React.createElement(FormControl, { type: "number", name: "fixedMarkup", value: values.fixedMarkup, onChange: handleChange, onBlur: handleBlur, isInvalid: touched.fixedMarkup && !!errors.fixedMarkup }),
                                                                        React.createElement(Form.Control.Feedback, { type: "invalid" }, errors.fixedMarkup))),
                                                                React.createElement("p", null, i18nShop.t('publicationSettings.pricingMarkupTypeFixedMarkupDescription')))),
                                                    React.createElement(FormCheck, { id: MarkupType.PERCENTAGE, type: "radio", name: "markupType", label: i18nShop.t('publicationSettings.pricingMarkupTypePercentageMarkup'), value: MarkupType.PERCENTAGE, onChange: handleChange, onBlur: handleBlur, checked: values.markupType === MarkupType.PERCENTAGE, isInvalid: touched.markupType && !!errors.markupType }),
                                                    values.markupType === MarkupType.PERCENTAGE &&
                                                        React.createElement(Row, null,
                                                            React.createElement("div", { className: "ps-5 pe-5 mt-3" },
                                                                React.createElement(Form.Group, { controlId: "markupPercentagePrice" },
                                                                    React.createElement(InputGroup, { className: "mb-3 w-50" },
                                                                        React.createElement(FormControl, { type: "number", name: "percentageMarkup", value: values.percentageMarkup, onChange: handleChange, onBlur: handleBlur, isInvalid: touched.percentageMarkup && !!errors.percentageMarkup }),
                                                                        React.createElement(InputGroup.Text, null, "%"),
                                                                        React.createElement(Form.Control.Feedback, { type: "invalid" }, errors.percentageMarkup))),
                                                                React.createElement("p", null, i18nShop.t('publicationSettings.pricingMarkupTypePercentageMarkupDescription')))),
                                                    React.createElement(FormCheck, { id: MarkupType.FIXED_RETAIL, type: "radio", name: "markupType", label: i18nShop.t('publicationSettings.pricingMarkupTypeFixedRetail'), value: MarkupType.FIXED_RETAIL, onChange: handleChange, onBlur: handleBlur, checked: values.markupType === MarkupType.FIXED_RETAIL, isInvalid: touched.markupType && !!errors.markupType }),
                                                    values.markupType === MarkupType.FIXED_RETAIL &&
                                                        React.createElement(Row, null,
                                                            React.createElement("div", { className: "ps-5 pe-5 mt-3" },
                                                                React.createElement(Form.Group, { controlId: "fixedRetailPrice" },
                                                                    React.createElement(InputGroup, { className: "mb-3 w-50" },
                                                                        React.createElement(InputGroup.Text, null, selectCurrency(props.locale).symbol),
                                                                        React.createElement(FormControl, { type: "number", name: "fixedPrice", value: values.fixedPrice, onChange: handleChange, onBlur: handleBlur, isInvalid: touched.fixedPrice && !!errors.fixedPrice }),
                                                                        React.createElement(Form.Control.Feedback, { type: "invalid" }, errors.fixedPrice))),
                                                                React.createElement("p", null, i18nShop.t('publicationSettings.pricingMarkupTypeFixedRetailDescription')))),
                                                    React.createElement(Form.Control.Feedback, { type: "invalid" }, errors.markupType))),
                                            [MarkupType.FIXED_MARKUP, MarkupType.PERCENTAGE, MarkupType.NONE].includes(values.markupType) &&
                                                React.createElement(Alert, { variant: "warning", className: "mt-3" }, i18nShop.t('publicationSettings.pricingMarkupTypeFluctuationAlert')),
                                            values.markupType === MarkupType.FIXED_RETAIL &&
                                                React.createElement(Alert, { variant: "warning", className: "mt-3" }, i18nShop.t('publicationSettings.pricingFixedRetailAlert'))))),
                                React.createElement(Col, { md: 4 },
                                    React.createElement(Card, null,
                                        React.createElement(Card.Body, null,
                                            React.createElement("h5", { className: "mb-3 border-bottom pb-2" }, i18nShop.t('publicationSettings.priceBreakdownTitle')),
                                            React.createElement(PriceBreakdown, { name: "priceBreakdown", productionCost: productionCost, requestingProductionCost: requestingProductionCost }))))))),
                    React.createElement(Card, { className: "mt-3 bg-dark text-white" },
                        React.createElement(Card.Header, null, i18nShop.t('publicationSettings.shareEmbedLinksTitle')),
                        React.createElement(Card.Body, null,
                            React.createElement(Row, { className: "mb-3" },
                                React.createElement(Form.Group, { as: Col, md: "6", controlId: "publicationActive" },
                                    React.createElement(Form.Label, null, i18nShop.t('publicationSettings.shareEmbedLinksDisableOrEnable')),
                                    React.createElement(FormCheck, { type: "switch", label: i18nShop.t('publicationSettings.shareEmbedLinksLabelEnable'), name: "active", checked: hasOffer && values.active, onChange: handleChange, onBlur: handleBlur, isInvalid: touched.active && !!errors.active, disabled: !hasOffer }),
                                    React.createElement(Form.Control.Feedback, { type: "invalid" }, errors.active))),
                            props.pending &&
                                React.createElement(Row, { className: "mb-3" },
                                    React.createElement(Col, null,
                                        React.createElement(Alert, { variant: "warning", className: "mt-3" }, i18nShop.t('publicationSettings.notAvailableUntilReviewedByExpert')))),
                            values.active &&
                                React.createElement(Row, { className: "mb-3" },
                                    React.createElement(Form.Group, { as: Col, controlId: "publicationLink" },
                                        React.createElement(Form.Label, null, i18nShop.t('publicationSettings.linkTitle')),
                                        React.createElement("div", { className: 'position-relative', style: { height: '4rem' } },
                                            React.createElement("pre", { className: "p-3 w-100 position-absolute", style: {
                                                    top: '0',
                                                    border: "1px dashed #dee2e6",
                                                    whiteSpace: 'pre-wrap'
                                                } },
                                                React.createElement("code", null,
                                                    React.createElement("a", { className: "link-secondary", style: { textDecoration: "none" }, href: "".concat(props.secureDomain, "/print-on-demand/").concat(values.shareId), target: "_blank" },
                                                        props.secureDomain,
                                                        "/print-on-demand/",
                                                        values.shareId))),
                                            React.createElement(CopyToClipboard, { text: "".concat(props.secureDomain, "/print-on-demand/").concat(values.shareId) },
                                                React.createElement(Button, { style: { top: '0', right: '0' }, onClick: function () { return setCopyLink(true); }, className: "position-absolute", variant: "outline-secondary" }, !copyLink ?
                                                    React.createElement("i", { className: "icon-copy" }) :
                                                    React.createElement("i", { className: "icon-check" })))))))),
                    React.createElement(Row, { className: "mt-3" },
                        React.createElement(Col, null,
                            isSubmitting &&
                                React.createElement(Button, { type: "submit", variant: "primary", disabled: true },
                                    React.createElement(Spinner, { as: "span", animation: "grow", size: "sm", role: "status", "aria-hidden": "true" }),
                                    i18nShop.t('publicationSettings.saving')),
                            !isSubmitting &&
                                React.createElement(Button, { type: "submit", variant: "primary", disabled: errors > 0 }, i18nShop.t('publicationSettings.save'))))));
            }))));
};
