import defineComponent from '../../../components/flight/lib/component';
import ShareView from './share-view';
import ShareSlides from './share-slides';
import ReprintManager from './reprint-manager';

export default defineComponent(ShareManager);

function ShareManager() {

    this.attributes({
        idInputSelector: '#shareId',
        viewSelector: '[data-type="preview-panel"]',
        buyBtnSelector: '[data-type="buy"]',
        slidesSelector: '[data-type="slides-panel"]',
        reprintSelector: '[data-type="reprint-santa-editor"]'
    });

    this.load = function() {
        $.getJSON(this.url)
        .done(response => {
            this.trigger("dataShareReady", response);
        })
        .fail(response =>  console && console.log('failure', response));
    };

    this.allowReprintChange = function (event, data) {
        if (data.allow) {
            this.select('buyBtnSelector').removeClass('hidden');
        } else {
            this.select('buyBtnSelector').addClass('hidden');
        }
    };

    this.after('initialize', function () {
        this.id = $(this.attr.idInputSelector).val();
        this.url = '/api/share/' + this.id + '/shareable';

        ShareView.attachTo(this.select('viewSelector'));
        ShareSlides.attachTo(this.select('slidesSelector'));
        ReprintManager.attachTo(this.select('reprintSelector'));

        this.on('uiAllowReprint', this.allowReprintChange);
        this.load();
    });
}
