var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import mixam from '../../../../../boot/mixam.js';
import React, { useState } from 'react';
import { Formik, Field } from 'formik';
import FormikTableModal from '../FormikTableModal';
import { submit } from '../api';
import axios from "axios";
var ProofUploadForm = function (_a) {
    var line = _a.line;
    var _b = useState(true), isFileUpload = _b[0], setFileUpload = _b[1];
    var _c = useState(false), isUploadSuccess = _c[0], setUploadSuccess = _c[1];
    var _d = useState(0), uploadPercent = _d[0], setUploadPercent = _d[1];
    var _e = useState(''), uploadFileUrl = _e[0], setUploadFileUrl = _e[1];
    var uploadUrl = "".concat(mixam.assetsDomain, "/proof/").concat(line.proofProxy.orderId, "/").concat(line.proofProxy.itemId, "/upload");
    var importUrl = "".concat(mixam.assetsDomain, "/proof/").concat(line.proofProxy.orderId, "/").concat(line.proofProxy.itemId, "/import");
    var uploadFile = function (files) { return __awaiter(void 0, void 0, void 0, function () {
        var i, data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setUploadSuccess(false);
                    i = 0;
                    _a.label = 1;
                case 1:
                    if (!(i < files.length)) return [3 /*break*/, 4];
                    setUploadPercent(0);
                    data = new FormData();
                    data.append('file', files[i]);
                    return [4 /*yield*/, axios.post(uploadUrl, data, {
                            headers: {
                                "Content-Type": "multipart/form-data",
                            },
                            onUploadProgress: function (progressEvent) {
                                var progress = Math.ceil((progressEvent.loaded / progressEvent.total) * 100);
                                setUploadPercent(progress);
                            }
                        })];
                case 2:
                    _a.sent();
                    _a.label = 3;
                case 3:
                    i++;
                    return [3 /*break*/, 1];
                case 4:
                    setUploadSuccess(true);
                    return [2 /*return*/];
            }
        });
    }); };
    var importFile = function (formData) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setUploadSuccess(false);
                    return [4 /*yield*/, axios.post(importUrl, formData)];
                case 1:
                    _a.sent();
                    setUploadSuccess(true);
                    return [2 /*return*/];
            }
        });
    }); };
    return (React.createElement(Formik, { initialValues: {
            uploadMode: 'file',
            remarks: '',
            url: '',
            file: ''
        }, onSubmit: function (values) { return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, submit("/api/proof/".concat(line.orderId, "/").concat(line.itemId, "/finalize"), values)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); } }, function (_a) {
        var setFieldValue = _a.setFieldValue, submitForm = _a.submitForm;
        var modalFooterButtons = [
            {
                label: 'Close',
                enabled: true,
                className: 'btn-secondary',
                closeAfterCallback: true,
                validateForm: false,
                onClickEvent: function () { return ({}); }
            },
            {
                label: 'Finalize Proof',
                enabled: isUploadSuccess,
                className: 'btn-primary',
                closeAfterCallback: true,
                validateForm: true,
                onClickEvent: function () { return __awaiter(void 0, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        submitForm();
                        return [2 /*return*/];
                    });
                }); }
            }
        ];
        return (React.createElement(FormikTableModal, { modalSize: "md", headerText: "Upload Ripped Proof", openBtnLabel: "Upload Proof", openBtnClassName: "btn-secondary", scrollable: true, footerButtons: modalFooterButtons },
            React.createElement("div", null,
                !isUploadSuccess &&
                    React.createElement("div", { className: "row mt-3" },
                        React.createElement("div", { className: "col" },
                            React.createElement("label", { htmlFor: "uploadMode", className: "form-label h6" }, "Mode"),
                            React.createElement(Field, { className: "form-select", as: "select", name: "uploadMode", onChange: function (e) {
                                    setFieldValue('uploadMode', e.target.value);
                                    e.target.value === 'file' ? setFileUpload(true) : setFileUpload(false);
                                } },
                                React.createElement("option", { value: "file" }, "File Upload"),
                                React.createElement("option", { value: "url" }, "URL")))),
                isFileUpload && !isUploadSuccess &&
                    React.createElement("div", { className: "row mt-3" },
                        React.createElement("div", { className: "col" },
                            React.createElement("label", { htmlFor: "file", className: "form-label h6" }, "Files"),
                            React.createElement(Field, { className: "form-control", type: "file", name: "file", accept: "application/pdf", multiple: "multiple", onChange: function (e) {
                                    setFieldValue('file', e.target.value);
                                    e.preventDefault();
                                    uploadFile(e.currentTarget.files);
                                } }),
                            React.createElement("div", { className: "progress mt-3" },
                                React.createElement("div", { className: "progress-bar progress-bar-striped progress-bar-animated", role: "progressbar", "aria-valuenow": 75, "aria-valuemin": 0, "aria-valuemax": 100, style: { 'width': "".concat(uploadPercent, "%") } })))),
                !isFileUpload && !isUploadSuccess &&
                    React.createElement("div", { className: "row mt-3" },
                        React.createElement("div", { className: "col" },
                            React.createElement("label", { htmlFor: "url", className: "form-label h6" }, "URL"),
                            React.createElement("div", { className: "input-group mb-3" },
                                React.createElement(Field, { className: "form-control", type: "url", name: "url", placeholder: "Any url, Dropbox link", onChange: function (e) {
                                        setFieldValue('url', e.target.value);
                                        setUploadFileUrl(e.target.value);
                                    } }),
                                React.createElement("button", { className: "btn btn-primary", type: "button", onClick: function (e) {
                                        e.preventDefault();
                                        var data = new FormData();
                                        data.append('url', uploadFileUrl);
                                        importFile(data);
                                    } }, "Submit")))),
                isUploadSuccess &&
                    React.createElement("div", { className: "alert alert-success", role: "alert" }, "File(s) successfully uploaded. Please add any comments and finalize the proof by pressing the finalize button"),
                React.createElement("div", { className: "row mt-3" },
                    React.createElement("div", { className: "col" },
                        React.createElement("div", { className: "form-group mb-3" },
                            React.createElement("label", { htmlFor: "internalNotes" }, "Remarks"),
                            React.createElement(Field, { className: "form-control", as: "textarea", name: "remarks" })))))));
    }));
};
export default ProofUploadForm;
