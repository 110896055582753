import defineComponent from '../../../../components/flight/lib/component';

export default defineComponent(MachinePriceListEditor);

function MachinePriceListEditor() {

    const metricTonToHundredweight = 22.04623;

    this.attributes({
        savePriceListButton: '[data-type="save-price-list-btn"]',
        priceListTableBody: '[data-type="price-list-table-body"]',
        priceListTypeSelector: '[data-type="price-list-type"]',
        priceListModeSelector: '[data-type="price-list-mode"]',
        priceListPerSelector: '[data-type="price-list-per"]',
        paperPriceListUnitSelector: '[data-type="paper-price-list-unit"]',
        incrementDecrementPriceListBtnSelector: '[data-type="increment-decrement-price-list-btn"]',
        incrementDecrementPriceListAmountSelector: '[data-type="increment-decrement-price-list-amount"]',
        hasSteppedPricingBtn: '[data-type="has-stepped-pricing-btn"]',
        deleteSteppedPriceRowBtn: '[data-type="delete-step-btn"]',
        addSteppedPriceRowBtn: '[data-type="add-step-btn"]'
    });

    this.savePriceList = function(event) {

        const saveUrl = `/admin/catalogue/machines/${this.machineId}/update-price-list`;
        let priceListEditor = this;
        const priceListId = this.priceListId;
        const priceListField = this.priceListField;
        let paperPriceListUnit = 'MT';

        if(this.node.checkValidity()) {

            priceListEditor.initSaveBtn();

            let tableRows = this.select('priceListTableBody').children('tr[data-row-type="price-row"]');

            let priceListRows = [];
            tableRows.each(function(){

                let id = $(this).data('row-key');
                let price = $(this).find('[data-field="price-list-row-price"]').val();
                let minimumPrice = $(this).find('[data-field="price-list-row-minimum-price"]').val();
                let setup = $(this).find('[data-field="price-list-row-setup"]').val();
                let enabled = $(this).find('[data-field="price-list-row-enabled"]').val();
                let hasStepPricing = $(this).find('[data-field="price-list-row-has-steps"]').val();

                if(priceListField === 'pricelistPapers') {
                    paperPriceListUnit = priceListEditor.select('paperPriceListUnitSelector').val();
                    if (paperPriceListUnit === 'CWT' && price > 0) {
                        price = Number((price * metricTonToHundredweight).toFixed(3));
                    }
                }

                if(price > 0 || minimumPrice > 0 || setup > 0 || enabled === 'true') {

                    // Look For Stepped Pricing & Add To Object
                    let priceListRowSteppedPricing = [];
                    if(hasStepPricing) {
                        const steppedPriceListTable = priceListEditor.select('priceListTableBody').find(`tr[data-row-type="stepped-price-row"][data-step-price-key="${id}"] table tbody`);
                        const steppedPriceDataRows = steppedPriceListTable.children('tr[data-type="stepped-price-data-row"][data-duplication-row="false"]');
                        steppedPriceDataRows.each(function(){
                            const stepFrom = $(this).find('[data-field="step-from"]').val();
                            const stepTo = $(this).find('[data-field="step-to"]').val();
                            const stepSetup = $(this).find('[data-field="step-setup"]').val();
                            const stepPrice = $(this).find('[data-field="step-price"]').val();
                            const stepMinimumPrice = $(this).find('[data-field="step-minimum-price"]').val();
                            const stepEnabled = $(this).find('[data-field="step-enabled"]').val();

                            priceListRowSteppedPricing.push(
                                {
                                    from: stepFrom,
                                    to: stepTo,
                                    setup: stepSetup,
                                    price: stepPrice,
                                    minimumPrice: stepMinimumPrice,
                                    enabled: stepEnabled
                                }
                            );
                        });
                    }

                    priceListRows.push(
                        {
                            id: id,
                            price: price,
                            minimumPrice: minimumPrice,
                            setup: setup,
                            enabled: enabled,
                            steps: priceListRowSteppedPricing
                        }
                    );
                }
            });

            const priceListData = {
                mode: this.select('priceListModeSelector').val(),
                type: this.select('priceListTypeSelector').val(),
                per: this.select('priceListPerSelector').val(),
                size: $(this.node).data('price-list-size'),
                items: priceListRows
            };

            $.ajax({
                url: saveUrl,
                type: 'POST',
                data: {
                    priceListData: JSON.stringify(priceListData),
                    priceListField: priceListField,
                    priceListId: priceListId
                },
                success: function(priceListId) {
                    priceListEditor.updatePricelistId(priceListId);
                    priceListEditor.resetSaveBtn();
                },
                error: function(error) {
                    priceListEditor.resetSaveBtn();
                    alert('Error Saving Data');
                }
            });

            event.preventDefault();
        } else {
            $(this.node).addClass('was-validated');
        }

    };

    this.switchPaperPriceUnit = function(event) {
        let paperPriceListUnit = this.select('paperPriceListUnitSelector').val();

        let tableRows = this.select('priceListTableBody').children('tr');

        tableRows.each(function(){
            let price = $(this).find('[data-field="price-list-row-price"]').val();
            let originalMetricTonValue = $(this).find('[data-field="price-list-row-price"]').data('original-value');
            if(price > 0) {
                if (paperPriceListUnit === 'CWT') {
                    let cwtPrice = Number((price / metricTonToHundredweight).toFixed(3));
                    $(this).find('[data-field="price-list-row-price"]').val(cwtPrice);
                } else {
                    $(this).find('[data-field="price-list-row-price"]').val(originalMetricTonValue);
                }
            }
        });
    };

    this.initSaveBtn = function(event) {
        let saveBtn = this.select('savePriceListButton');
        setTimeout( function() {
            saveBtn.addClass('disabled');
            saveBtn.find('[data-type="spinner"]').removeClass('d-none');
            saveBtn.find('[data-type="btn-label"]').text("Saving");
        }, 1);
    };

    this.resetSaveBtn = function(event) {
        let saveBtn = this.select('savePriceListButton');
        setTimeout( function() {
            saveBtn.find('[data-type="spinner"]').addClass('d-none');
            saveBtn.find('[data-type="btn-label"]').text("Save");
            saveBtn.removeClass('disabled');
        }, 2000);
    };

    this.updatePricelistId = function(priceListId) {
        this.priceListId = priceListId;
    };

    this.toggleSteppedPricing = function(event) {
        const selectToggle = $(event.target);
        const targetRowId = selectToggle.data('target-row');
        const stepPriceTable = this.select('priceListTableBody').find(`tr[data-row-type="stepped-price-row"][data-step-price-key="${targetRowId}"]`);
        if(selectToggle.val() === 'true') {
            stepPriceTable.removeClass('d-none');
        } else {
            stepPriceTable.addClass('d-none');
        }
    };

    this.deleteStepDataRow = function(event) {
        const deleteStepBtn = $(event.target);
        const steppedPriceDataRow = deleteStepBtn.closest('tr');
        steppedPriceDataRow.remove();
    };

    this.addStepDataRow = function(event) {
        const addStepPriceBtn = $(event.target);
        const targetSteppedPricingId = addStepPriceBtn.data('step-row');
        const steppedPriceList = this.select('priceListTableBody').find(`tr[data-row-type="stepped-price-row"][data-step-price-key="${targetSteppedPricingId}"]`);
        const duplicationRow = steppedPriceList.find(`tr[data-type="stepped-price-data-row"][data-duplication-row="true"]`);
        const steppedPriceListTableBody = steppedPriceList.find('tbody');

        const duplicatedRow = duplicationRow.clone();
        duplicatedRow.attr('data-duplication-row', 'false');
        duplicatedRow.removeClass('d-none');
        duplicatedRow.appendTo(steppedPriceListTableBody);

        const addStepPriceBtnRow = addStepPriceBtn.closest('tr');
        addStepPriceBtnRow.clone().appendTo(steppedPriceListTableBody);
        addStepPriceBtnRow.remove();
    };

    this.incrementDecrementPriceList = function() {
        const percentageChange = this.select('incrementDecrementPriceListAmountSelector').val();
        let tableRows = this.select('priceListTableBody').children('tr');

        tableRows.each(function(){
            let price = $(this).find('[data-field="price-list-row-price"]').val();
            if(price > 0 && percentageChange !== 0) {
                let newPrice = Number((price * (1.0 + (percentageChange / 100.0))).toFixed(3));
                $(this).find('[data-field="price-list-row-price"]').val(newPrice);
            }
        });
    };

    this.after('initialize', function () {
        this.machineId = $(this.node).data('machine-id');
        this.priceListId = $(this.node).data('price-list-id');
        this.catalogId = $(this.node).data('catalog-id');
        this.priceListField = $(this.node).data('price-list-field');
        this.on('click', {
            savePriceListButton: this.savePriceList,
            incrementDecrementPriceListBtnSelector: this.incrementDecrementPriceList,
            deleteSteppedPriceRowBtn: this.deleteStepDataRow,
            addSteppedPriceRowBtn: this.addStepDataRow
        });
        this.on('change', {
            paperPriceListUnitSelector: this.switchPaperPriceUnit,
            hasSteppedPricingBtn: this.toggleSteppedPricing
        });
    });
}
