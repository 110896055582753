import React, { useEffect, useState } from 'react';
import Row from 'react-bootstrap/Row';
import Alert from 'react-bootstrap/Alert';
import Spinner from 'react-bootstrap/Spinner';
import { useFormikContext } from 'formik';
import { MarkupType } from "./types";
import i18nShop from "../../../../../assets/i18n/i18n";
import { I18nextProvider } from 'react-i18next';
import { formatCurrency } from "./CurrencyUtils";
export var PriceBreakdown = function (props) {
    var _a = useFormikContext(), _b = _a.values, markupType = _b.markupType, fixedPrice = _b.fixedPrice, percentageMarkup = _b.percentageMarkup, fixedMarkup = _b.fixedMarkup, locale = _b.locale, touched = _a.touched, setFieldValue = _a.setFieldValue;
    var _c = useState(0), buyerPrice = _c[0], setBuyerPrice = _c[1];
    var _d = useState(0), publisherRevenue = _d[0], setPublisherRevenue = _d[1];
    var _e = useState(false), showPriceBreakdown = _e[0], setShowPriceBreakdown = _e[1];
    var productionCost = props.productionCost;
    useEffect(function () {
        setShowPriceBreakdown(false);
        if (productionCost > 0 &&
            (markupType === MarkupType.FIXED_MARKUP && fixedMarkup > 0) ||
            (markupType === MarkupType.PERCENTAGE && percentageMarkup > 0) ||
            (markupType === MarkupType.FIXED_RETAIL && fixedPrice >= productionCost) ||
            (markupType === MarkupType.NONE) ||
            (markupType === MarkupType.DISTRIBUTION)) {
            switch (markupType) {
                case MarkupType.PERCENTAGE:
                    var finalPrice = productionCost + (productionCost * percentageMarkup / 100);
                    setBuyerPrice(finalPrice);
                    setPublisherRevenue(finalPrice - productionCost);
                    break;
                case MarkupType.FIXED_MARKUP:
                    setBuyerPrice(productionCost + fixedMarkup);
                    setPublisherRevenue(fixedMarkup);
                    break;
                case MarkupType.FIXED_RETAIL:
                    setBuyerPrice(fixedPrice);
                    setPublisherRevenue(fixedPrice - productionCost);
                    break;
                case MarkupType.NONE:
                    setBuyerPrice(productionCost);
                    setPublisherRevenue(0);
                    break;
                case MarkupType.DISTRIBUTION:
                    setBuyerPrice(0);
                    setPublisherRevenue(0);
                    break;
                default:
                    setBuyerPrice(productionCost);
                    setPublisherRevenue(0);
                    break;
            }
            setShowPriceBreakdown(productionCost > 0);
        }
    }, [markupType, props.productionCost, props.requestingProductionCost, fixedPrice, touched.fixedPrice, fixedMarkup, touched.fixedMarkup, percentageMarkup, touched.percentageMarkup, setFieldValue, props.name]);
    return (React.createElement(I18nextProvider, { i18n: i18nShop },
        React.createElement(React.Fragment, null,
            props.requestingProductionCost &&
                React.createElement("div", { className: "text-center" },
                    React.createElement("p", null, i18nShop.t('publicationSettings.queryingPublicationPrice')),
                    React.createElement(Spinner, { animation: "border", variant: "primary" })),
            !props.requestingProductionCost && showPriceBreakdown &&
                React.createElement(React.Fragment, null,
                    React.createElement(Row, { className: "mb-3" },
                        React.createElement("div", null,
                            React.createElement("span", { className: "fw-bold" }, i18nShop.t('publicationSettings.mixamCost')),
                            " ",
                            formatCurrency(productionCost, locale))),
                    publisherRevenue >= 0 &&
                        React.createElement(React.Fragment, null,
                            React.createElement(Row, { className: "mb-3" },
                                React.createElement("div", null,
                                    React.createElement("span", { className: "fw-bold" }, i18nShop.t('publicationSettings.buyerPrice')),
                                    " ",
                                    formatCurrency(buyerPrice, locale))),
                            React.createElement(Row, { className: "mb-3" },
                                React.createElement("div", null,
                                    React.createElement("span", { className: "fw-bold" }, i18nShop.t('publicationSettings.yourRevenue')),
                                    " ",
                                    formatCurrency(publisherRevenue, locale))))),
            (!props.requestingProductionCost && !showPriceBreakdown && productionCost > 0) &&
                React.createElement(Row, { className: "mb-3" },
                    React.createElement("div", null,
                        React.createElement("span", { className: "fw-bold" }, i18nShop.t('publicationSettings.mixamCost')),
                        " ",
                        formatCurrency(productionCost, locale))),
            (!props.requestingProductionCost && !showPriceBreakdown && productionCost === 0) &&
                React.createElement(Alert, { variant: "warning" }, i18nShop.t('publicationSettings.unableToSupplyItem')))));
};
