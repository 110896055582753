import ReactDom from  'react-dom';
import React from 'react';
const rd = ReactDom;

class ThumbLink extends React.Component {
    render() {
        const data = this.props.data || {};
        const style = data.src && {
            backgroundImage: `url(${data.src})`
        };

        let thumbnailBox;
        if (data.src) {
            thumbnailBox = (<a
                className='thumbnail'
                title={data.title}
                target='_blank'
                href={data.href}
                style={style}
                ><div className='aspect-keeper'></div></a>);
        }

        const linkBox = (<a
            className='link'
            target='_blank'
            href={data.href}
            >{data.title}</a>);

        const className = 'thumb-link-box orient-' +  data.orientation;

        return (<div className={className}>
            {thumbnailBox}
            {linkBox}
        </div>);
    }
}

export default ThumbLink;


