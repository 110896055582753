/* exported gapiLoaded */
/* exported gisLoaded */
/* exported handleAuthClick */
/* exported handleSignoutClick */

import mixam from "../../../boot/mixam";

/**
 * Link to Google Sheets API Documentation:
 * https://developers.google.com/sheets/api/quickstart/js
 */

// Client ID from Google Cloud Services Project Credentials tab
const CLIENT_ID = mixam.googleSheetsClientId;

// API Key from Google Cloud Services Project Credentials tab
const API_KEY = mixam.googleSheetsApiKey;

// Discovery doc URL for APIs used by the quickstart
const DISCOVERY_DOC = mixam.googleSheetsDiscoveryDocUri;

// Authorization scopes required by the API
const SCOPES = mixam.googleSheetsScope;

// Determines how input data should be interpreted by Google Sheets
const USER_INPUT_VALUE = 'RAW';

// Specifies Cell Range for Google Sheets writer API, not that a range of A1 automatically extends cells based on passed data size
const CELL_RANGE = 'A1';

// Default Name of Google Sheet
const SHEET_DEFAULT_NAME = "Mixam Admin Sheet";

let tokenClient;

// Load Google API
function gapiLoaded() {
    gapi.load(
        'client',
        gapi.client.init({
            apiKey: API_KEY,
            discoveryDocs: [DISCOVERY_DOC]
        })
    );
}

// Load Google Identity Services
function gisLoaded() {
    tokenClient = window.google.accounts.oauth2.initTokenClient({
        client_id: CLIENT_ID,
        scope: SCOPES,
        callback: ''
    });
}

// Authenticate user upon button click and create their Google sheet
export function handleGSheetsClick(formattedData, callbackFunc) {
    tokenClient.callback = async (resp) => {
        if (resp.error !== undefined) {
            callbackFunc.onError("Login and Authentication Error: " + resp.error);
            throw (resp);
        }
        // Google Authentication Successful
        try {
            callbackFunc.onLoad();
            let sheetId = await create(SHEET_DEFAULT_NAME);
            sheetId = await updateValues(sheetId, CELL_RANGE, USER_INPUT_VALUE, formattedData);
            const spreadsheetUrl = await getExistingSpreadsheetByResponse(sheetId);
            callbackFunc.onSuccess(spreadsheetUrl);
        } catch (error) {
            callbackFunc.onError("Error Code: " + error.result.error.code + ", Status: " + error.result.error.status + ", Message: " + error.result.error.message);
        }
    };

    if (gapi.client.getToken() === null) {
        // Prompt the user to select a Google Account and ask for consent to share their data
        // when establishing a new session.
        tokenClient.requestAccessToken({prompt: 'consent'});
    } else {
        // Skip display of account chooser and consent dialog for an existing session.
        tokenClient.requestAccessToken({prompt: ''});
    }
}

// Create Google Sheet
async function create(title) {
    const response = await gapi.client.sheets.spreadsheets.create({
        properties: {
            title: title
        }});
    return response.result.spreadsheetId;
}

// Write Data to Google Sheet
async function updateValues(spreadsheetId, range, valueInputOption, values) {
    const response = await gapi.client.sheets.spreadsheets.values.update({
        spreadsheetId: spreadsheetId,
        range: range,
        valueInputOption: valueInputOption,
        resource: {
            values: values
        }
    });
    return response.result.spreadsheetId;
}

// Get Google Sheet URL by Sheet ID
async function getExistingSpreadsheetByResponse(passedSheetId) {
    const response = await gapi.client.sheets.spreadsheets.get({
        spreadsheetId: passedSheetId
    });
    return response.result.spreadsheetUrl;
}

// Add Script into DOM for Google Sheets API
function addScript(src) {
    return new Promise((resolve, reject) => {
        const createdElement = document.createElement('script');
        createdElement.setAttribute('src', src);
        createdElement.addEventListener('load', resolve);
        createdElement.addEventListener('error', reject);
        document.body.appendChild(createdElement);
    });
}

// Load Google API Scripts into the page from vanilla JS
export async function loadScripts() {
    return await Promise.allSettled([
        addScript('https://apis.google.com/js/api.js'),
        addScript('https://accounts.google.com/gsi/client')
    ]).then(() => {
        gapiLoaded();
        gisLoaded();
        return true;
    }).catch(() => {
        return false;
    });
}