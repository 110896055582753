import mixam from '../../boot/mixam';
import disputeStatus from '../constants/disputeStatus';
import disputeCategories from '../constants/disputeCategory';
import disputeCulprits from '../constants/disputeCulprit';
import disputeResolutions from '../constants/disputeResolution';
import ReactDom from  'react-dom';
import React from 'react';

const rd = ReactDom;

class DisputeAction extends React.Component {
    constructor(...args) {
        super(...args);
        this.state = {open: false, loading: false};
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    /*
        handleAcceptClick(e) {
            this.setState({status: disputeStatusValues.RESOLVING});
        }
    */

    handleResolveClick(e) {
        this.toggleOpenState();
    }

    handleCloseClick(e) {
        this.toggleOpenState();
    }

    toggleOpenState() {
        this.setState({open: !this.state.open});
    }

    onTransitionEnd(event) {
        if (event.propertyName === "top") {
            this.setState({active: this.state.open});
        }
    }

    componentDidMount() {
        this.setState({
            problemMixamRefundAmount: this.props.line.mixamRefundAmount,
            problemMixamReprintCost: this.props.line.mixamReprintCost,
            problemSupplierRefundAmount: this.props.line.supplierRefundAmount,
            problemSupplierReprintCost: this.props.line.supplierReprintCost,
            problemLogisticsCost: this.props.line.logisticsCost,
            problemReprintCaseNumber: this.props.line.reprintCaseNumber,
            problemRemark: this.props.line.remarks,
            problemCategory: this.props.line.disputeCloseCategory,
            problemCulprit: this.props.line.culprit,
            problemResolution: this.props.line.resolvedActionType
        });
    }

    handleAcceptSubmit(event) {
        event.preventDefault();
        const line = this.props.line;
        const url = `/api/admin/dispute/${line.id}/accept`;

        this.setState({loading: true});
        $.post(url, null, null, 'json').done(response => {
            this.setState({loading: false, open: false});
        });
    }

    handleResolveSubmit(event) {
        event.preventDefault();
        const line = this.props.line;
        const url = `/api/admin/dispute/${line.id}/resolve`;
        const form = $(event.target);
        this.setState({loading: true});
        $.post(url, form.serialize(), null, 'json').done(response => {
            this.setState({loading: false, open: false});
        });
    }

    handleUpdateClick(event) {
        event.preventDefault();
        const line = this.props.line;
        const url = `/api/admin/dispute/${line.id}/update`;
        const form = $(event.target).closest('form');
        this.setState({loading: true});
        $.post(url, form.serialize(), null, 'json').done(response => {
            this.setState({loading: false, open: false});
        });
    }

    render() {
        const line = this.props.line;
        //console.log("render->", this.state.status, line.status)
        switch (disputeStatus.fromValue(line.status)) {
            case disputeStatus.INITIAL:
                return this.renderAcceptDialog();
            case disputeStatus.RESOLVING:
                return this.renderResolvingDialog();
            case disputeStatus.CLOSE:
                return this.renderDetailsDialog();
            default:
                return this.renderCloseDialog();
        }
    }

    renderAcceptDialog() {
        const line = this.props.line;
        const className = `${this.state.open ? "open " : ""} ${this.state.active ? "active " : ""}morph-dialog-button morph-dialog-button-overlay morph-dialog-button-fixed accepting`;
        const dialog = this.createAcceptDialog(line);

        const button = React.createElement("button", {
                button: 'button',
                onClick: (e) => this.toggleOpenState(e)
            },
            React.createElement("i", {
                    className: 'fa fa-play-circle'
                }
            ),
            ' ',
            'Handle',
        );


        return (React.createElement("span", {
                    className: 'cell-content'
                },
                React.createElement("div", {
                        className: className
                    },

                    button,

                    React.createElement("div", {
                            className: 'content-style-mask',
                            onClick: (e) => this.handleCloseClick(e)
                        }
                    ),

                    React.createElement("div", {
                            ref: 'content',
                            onTransitionEnd: (ev) => this.onTransitionEnd(ev),
                            className: 'morph-dialog-content'
                        },
                        React.createElement("div", null,
                            React.createElement("div", {
                                    className: 'content-style-overlay'
                                },

                                React.createElement("button", {
                                        className: 'close-btn state-close btn-shape-circle',
                                        title: "Close",
                                        onClick: (e) => this.handleCloseClick(e)
                                    },
                                    React.createElement("i", {
                                            className: 'fa fa-times'
                                        }
                                    )
                                ),

                                dialog
                            )
                        )
                    )
                ))
        );
    }

    renderResolvingDialog() {
        const data = this.props.data;
        const line = this.props.line;
        const className = `${this.state.open ? "open " : ""} ${this.state.active ? "active " : ""}morph-dialog-button morph-dialog-button-overlay morph-dialog-button-fixed resolving`;
        const dialog = this.createrResolvingDialog(line);
        const dataLoading1 = this.state.loading ? "*" : null;

        const button = React.createElement("button", {
                button: 'button',
                onClick: (e) => this.handleResolveClick(e),
            },
            React.createElement("i", {
                    className: 'fa fa-balance-scale'
                }
            ),
            ' ',
            'Resolve'
        );

        return (React.createElement("span", {
                    className: 'cell-content'
                },
                React.createElement("div", {
                        className: className
                    },

                    button,

                    React.createElement("div", {
                            className: 'content-style-mask',
                            onClick: (e) => this.handleClose(e)
                        }
                    ),

                    React.createElement("div", {
                            ref: 'content',
                            onTransitionEnd: (ev) => this.onTransitionEnd(ev),
                            className: 'morph-dialog-content'
                        },
                        React.createElement("div", null,
                            React.createElement("div", {
                                    className: 'content-style-overlay'
                                },

                                React.createElement("button", {
                                        className: 'close-btn state-close btn-shape-circle',
                                        title: "Close",
                                        onClick: (e) => this.handleCloseClick(e)
                                    },
                                    React.createElement("i", {
                                            className: 'fa fa-times'
                                        }
                                    )
                                ),

                                dialog
                            )
                        )
                    )
                ))
        );
    }

    renderCloseDialog() {
        return (<div>
            Close
        </div>);
    }

    renderDetailsDialog() {
        const line = this.props.line;
        const className = `${this.state.open ? "open " : ""} ${this.state.active ? "active " : ""}morph-dialog-button morph-dialog-button-overlay morph-dialog-button-fixed details`;
        const dialog = this.createDetailsDialog(line);

        const button = React.createElement("button", {
                button: 'button',
                onClick: (e) => this.toggleOpenState(e)
            },
            React.createElement("i", {
                    className: 'fa fa-info'
                }
            ),
            ' ',
            'Details',
        );


        return (React.createElement("span", {
                    className: 'cell-content'
                },
                React.createElement("div", {
                        className: className
                    },

                    button,

                    React.createElement("div", {
                            className: 'content-style-mask',
                            onClick: (e) => this.handleCloseClick(e)
                        }
                    ),

                    React.createElement("div", {
                            ref: 'content',
                            onTransitionEnd: (ev) => this.onTransitionEnd(ev),
                            className: 'morph-dialog-content'
                        },
                        React.createElement("div", null,
                            React.createElement("div", {
                                    className: 'content-style-overlay'
                                },

                                React.createElement("button", {
                                        className: 'close-btn state-close btn-shape-circle',
                                        title: "Close",
                                        onClick: (e) => this.handleCloseClick(e)
                                    },
                                    React.createElement("i", {
                                            className: 'fa fa-times'
                                        }
                                    )
                                ),

                                dialog
                            )
                        )
                    )
                ))
        );
    }

    createAcceptDialog(line) {
        const dataLoading1 = this.state.loading ? "*" : null;
        return (<div className='dialog'>
            <form onSubmit={(event) => this.handleAcceptSubmit(event)} className='form-horizontal long-labels product-1'>
                <h2 className='caption'>Start Handling Dispute</h2>
                <div className='form-group'>
                    {mixam.user.firstName}, You are about to mark this dispute as 'RESOLVING'. Continue?
                </div>
                <div className="form-group last">
                    <div className="control-save-line">
                        <button type="submit" data-type="save-import" data-style="zoom-out" className="btn btn-outline-white btn-anime btn-anime-zoomout"
                                data-loading={dataLoading1}>
                            <span className="btn-anime-label">OK</span>
                            <span className="btn-anime-spinner"><i className="fa fa-spinner fa-lg fa-spin"></i></span>
                        </button>
                        <button type="button" onClick={(e) => this.handleCloseClick(e)} className="btn btn-outline-white">Cancel</button>
                    </div>
                </div>
            </form>
        </div>);

    }

    createrResolvingDialog(line) {
        const dataLoading1 = this.state.loading ? "*" : null;

        const problemOptions = disputeCategories.map(group => {
            const options = group.items.map(o => {
                return (<option value={o.id}>{o.name}</option> );
            })
            return (<optgroup label={group.name}>
                {options}
            </optgroup> );
        });
        const attachments = line.attachments && line.attachments.length ?
            line.attachments.map(a => (
                <div className='attachment-item'>
                    <a className='attachment' href={a.url} target='_blank'>
                        <img src={a.thumbnail}/>
                    </a>
                    <p>
                        <a href={a.url} target='_blank'>{a.name}</a>
                    </p>
                </div>)) : null;
        const attachmentContainer = line.attachments && line.attachments.length ?
            (<div className="form-group">
                <label className="control-label">Attachments</label>
                <div className="control-size-frame">
                <div className='attachments-items'>
                {attachments}
            </div></div></div>) : null;
        const culpritOptions = disputeCulprits.map(culprit => {
            return (<option value={culprit.id}>{culprit.value}</option>)
        });
        const resolutionOptions = disputeResolutions.map(resolution => {
            return (<option value={resolution.id}>{resolution.value}</option>)
        });

        return (<div className='dialog'>
            <form onSubmit={(event) => this.handleResolveSubmit(event)} className='form-horizontal long-labels product-1'>
                <h2 className='caption'>Resolve Dispute</h2>

                <div class="resolve-dispute-form-wrapper">

                    <div className="resolve-dispute-form-left">

                        <div className="form-group">
                            <label className="control-label">Member name</label>

                            <div className="control-size-frame">
                                <p className="form-control-static">{line.memberName}</p>
                            </div>
                        </div>

                        <div className="form-group">
                            <label className="control-label">Opening date</label>

                            <div className="control-size-frame">
                                <p className="form-control-static">{line.dateCreated.text}</p>
                            </div>
                        </div>

                        <div className="form-group">
                            <label className="control-label">Category</label>

                            <div className="control-size-frame">
                                <p className="form-control-static">{line.problemCategoryText}</p>
                            </div>
                        </div>

                        <div className="form-group">
                            <label className="control-label">Copies Affected</label>

                            <div className="control-size-frame">
                                <p className="form-control-static">{line.copiesAffected}</p>
                            </div>
                        </div>

                        <div className="form-group">
                            <label className="control-label">Supplier Item Cost</label>

                            <div className="control-size-frame">
                                <p className="form-control-static">{Number.parseFloat(line.itemSupplierCost).toFixed(2) || ''}</p>
                            </div>
                        </div>

                        <div className="form-group">
                            <label className="control-label">Item Value</label>

                            <div className="control-size-frame">
                                <p className="form-control-static">{Number.parseFloat(line.itemValue).toFixed(2) || ''}</p>
                            </div>
                        </div>

                        <div className="form-group">
                            <label className="control-label">Details</label>

                            <div className="control-size-frame">
                                <p className="form-control-static">{line.problemDetails}</p>
                            </div>
                        </div>

                        <div className="form-group">
                            <label className="control-label">Asking for</label>

                            <div className="control-size-frame">
                                <p className="form-control-static">{line.disputeRequestCategory}</p>
                            </div>
                        </div>

                        {attachmentContainer}

                    </div>

                    <div className="resolve-dispute-form-right">

                        <div className="form-group">
                            <label className="control-label" htmlFor={`problemCategory-${line.id}`}>Problem</label>

                            <div className="control-size-frame">
                                <div className="clearfix subject">
                                    <span className="select-bkg">
                                        <select className="form-control" required="" name="problemCategory"
                                                id={`problemCategory-${line.id}`} value={this.state.problemCategory} onChange={this.handleChange}>
                                            <option className="a-prompt" value="">Choose a category</option>
                                            {problemOptions}
                                        </select>
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div className="form-group">
                            <label className="control-label" htmlFor={`problemCulprit-${line.id}`}>Fault</label>

                            <div className="control-size-frame">
                                <div className="clearfix subject">
                                    <span className="select-bkg">
                                        <select className="form-control" required="" name="problemCulprit"
                                                id={`problemCulprit-${line.id}`} value={this.state.problemCulprit} onChange={this.handleChange}>
                                            <option className="a-prompt" value="">Choose a culprit</option>
                                            {culpritOptions}
                                        </select>
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div className="form-group">
                            <label className="control-label" htmlFor={`problemResolution-${line.id}`}>Resolution</label>

                            <div className="control-size-frame">
                                <div className="clearfix subject">
                                    <span className="select-bkg">
                                        <select className="form-control" required="" name="problemResolution"
                                                id={`problemResolution-${line.id}`} value={this.state.problemResolution} onChange={this.handleChange}>
                                            <option className="a-prompt" value="">Choose a resolved action</option>
                                            {resolutionOptions}
                                        </select>
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div className="form-group">
                            <label className="control-label" htmlFor={`problemRemarks-${line.id}`}>Remarks</label>
                            <div className="control-size-frame">
                                 <textarea className="form-control" ref="remarkText" name="problemRemark" id={`problemRemark-${line.id}`} rows="8" cols="40" value={this.state.problemRemark} onChange={this.handleChange}></textarea>
                            </div>
                        </div>

                        <div className="form-group">
                            <label className="control-label" htmlFor={`problemReprintCaseNumber-${line.id}`}>Reprint Case Number</label>
                            <div className="control-size-frame">
                                <input type="number" step="1" className="form-control no-step" ref="problemReprintCaseNumber" name="problemReprintCaseNumber" id={`problemReprintCaseNumber-${line.id}`} value={this.state.problemReprintCaseNumber} onChange={this.handleChange}></input>
                            </div>
                        </div>

                        <div className="form-group">
                            <label className="control-label" htmlFor={`problemMixamRefundAmount-${line.id}`}>Mixam Refund Amount</label>
                            <div className="control-size-frame">
                                <input type="number" step="0.01" className="form-control dispute-amount no-step" ref="problemMixamRefundAmount" name="problemMixamRefundAmount" id={`problemMixamRefundAmount-${line.id}`} value={this.state.problemMixamRefundAmount} onChange={this.handleChange}></input>
                            </div>
                        </div>

                        <div className="form-group">
                            <label className="control-label" htmlFor={`problemMixamReprintCost-${line.id}`}>Mixam Reprint Cost</label>
                            <div className="control-size-frame">
                                <input type="number" step="0.01" className="form-control dispute-amount no-step" ref="problemMixamReprintCost" name="problemMixamReprintCost" id={`problemMixamReprintCost-${line.id}`} value={this.state.problemMixamReprintCost} onChange={this.handleChange}></input>
                            </div>
                        </div>

                        <div className="form-group">
                            <label className="control-label" htmlFor={`problemSupplierRefundAmount-${line.id}`}>Supplier Refund Amount</label>
                            <div className="control-size-frame">
                                <input type="number" step="0.01" className="form-control dispute-amount no-step" ref="problemSupplierRefundAmount" name="problemSupplierRefundAmount" id={`problemSupplierRefundAmount-${line.id}`} value={this.state.problemSupplierRefundAmount} onChange={this.handleChange}></input>
                            </div>
                        </div>

                        <div className="form-group">
                            <label className="control-label" htmlFor={`problemSupplierReprintCost-${line.id}`}>Supplier Reprint Cost</label>
                            <div className="control-size-frame">
                                <input type="number" step="0.01" className="form-control dispute-amount no-step" ref="problemSupplierReprintCost" name="problemSupplierReprintCost" id={`problemSupplierReprintCost-${line.id}`} value={this.state.problemSupplierReprintCost} onChange={this.handleChange}></input>
                            </div>
                        </div>

                        <div className="form-group">
                            <label className="control-label" htmlFor={`problemLogisticsCost-${line.id}`}>Logistics Cost</label>
                            <div className="control-size-frame">
                                <input type="number" step="0.01" className="form-control dispute-amount no-step" ref="problemLogisticsCost" name="problemLogisticsCost" id={`problemLogisticsCost-${line.id}`} value={this.state.problemLogisticsCost} onChange={this.handleChange}></input>
                            </div>
                        </div>

                    </div>
                </div>

                <div className="form-group last">
                    <div className="control-save-line">
                        <button type="button" onClick={(e) => this.handleUpdateClick(e)} data-type="save-import" data-style="zoom-out" className="btn btn-outline-white btn-anime btn-anime-zoomout"
                                data-loading={dataLoading1}>
                            <span className="btn-anime-label">Save</span>
                            <span className="btn-anime-spinner"><i className="fa fa-spinner fa-lg fa-spin"></i></span>
                        </button>
                        <button type="submit" data-type="save-import" data-style="zoom-out" className="btn btn-outline-white btn-anime btn-anime-zoomout"
                                data-loading={dataLoading1}>
                            <span className="btn-anime-label">Save &amp; Resolve</span>
                            <span className="btn-anime-spinner"><i className="fa fa-spinner fa-lg fa-spin"></i></span>
                        </button>
                        <button type="button" onClick={(e) => this.handleCloseClick(e)} className="btn btn-outline-white">Cancel</button>
                    </div>
                </div>
            </form>
        </div>);
    }

    createDetailsDialog(line) {
        const dataLoading1 = this.state.loading ? "*" : null;

        const problemOptions = disputeCategories.map(group => {
            const options = group.items.map(o => {
                return (<option value={o.id}>{o.name}</option> );
            })
            return (<optgroup label={group.name}>
                {options}
            </optgroup> );
        });
        const attachments = line.attachments && line.attachments.length ?
            line.attachments.map(a => (
                <div className='attachment-item'>
                    <a className='attachment' href={a.url} target='_blank'>
                        <img src={a.thumbnail}/>
                    </a>
                    <p>
                        <a href={a.url} target='_blank'>{a.name}</a>
                    </p>
                </div>)) : null;
        const attachmentContainer = line.attachments && line.attachments.length ?
            (<div className="form-group">
                <label className="control-label">Attachments</label>
                <div className="control-size-frame">
                    <div className='attachments-items'>
                        {attachments}
                    </div></div></div>) : null;
        const culpritOptions = disputeCulprits.map(culprit => {
            return (<option value={culprit.id}>{culprit.value}</option>)
        });
        const resolutionOptions = disputeResolutions.map(resolution => {
            return (<option value={resolution.id}>{resolution.value}</option>)
        });

        return (<div className='dialog'>
            <form onSubmit={(event) => this.handleResolveSubmit(event)} className='form-horizontal long-labels product-1'>
                <h2 className='caption'>Dispute Details</h2>

                <div class="resolve-dispute-form-wrapper">

                    <div className="resolve-dispute-form-left">

                        <div className="form-group">
                            <label className="control-label">Member name</label>

                            <div className="control-size-frame">
                                <p className="form-control-static">{line.memberName}</p>
                            </div>
                        </div>

                        <div className="form-group">
                            <label className="control-label">Opening date</label>

                            <div className="control-size-frame">
                                <p className="form-control-static">{line.dateCreated.text}</p>
                            </div>
                        </div>

                        <div className="form-group">
                            <label className="control-label">Category</label>

                            <div className="control-size-frame">
                                <p className="form-control-static">{line.problemCategoryText}</p>
                            </div>
                        </div>

                        <div className="form-group">
                            <label className="control-label">Details</label>

                            <div className="control-size-frame">
                                <p className="form-control-static">{line.problemDetails}</p>
                            </div>
                        </div>

                        <div className="form-group">
                            <label className="control-label">Asking for</label>

                            <div className="control-size-frame">
                                <p className="form-control-static">{line.disputeRequestCategory}</p>
                            </div>
                        </div>

                        <div className="form-group">
                            <label className="control-label">Supplier Item Cost</label>

                            <div className="control-size-frame">
                                <p className="form-control-static">{Number.parseFloat(line.itemSupplierCost).toFixed(2) || ''}</p>
                            </div>
                        </div>

                        <div className="form-group">
                            <label className="control-label">Item Value</label>

                            <div className="control-size-frame">
                                <p className="form-control-static">{Number.parseFloat(line.itemValue).toFixed(2) || ''}</p>
                            </div>
                        </div>

                        {attachmentContainer}

                    </div>

                    <div className="resolve-dispute-form-right">

                        <div className="form-group">
                            <label className="control-label">Problem</label>

                            <div className="control-size-frame">
                                <p className="form-control-static">{line.disputeCloseCategoryText}</p>
                            </div>
                        </div>

                        <div className="form-group">
                            <label className="control-label">Culprit</label>

                            <div className="control-size-frame">
                                <p className="form-control-static">{line.disputeCulpritText}</p>
                            </div>
                        </div>

                        <div className="form-group">
                            <label className="control-label">Resolution</label>

                            <div className="control-size-frame">
                                <p className="form-control-static">{line.disputeResolutionsText}</p>
                            </div>
                        </div>

                        <div className="form-group">
                            <label className="control-label">Remarks</label>

                            <div className="control-size-frame">
                                <p className="form-control-static">{line.remarks}</p>
                            </div>
                        </div>

                        <div className="form-group">
                            <label className="control-label">Closed By</label>

                            <div className="control-size-frame">
                                <p className="form-control-static">{line.closedByName}</p>
                            </div>
                        </div>

                        <div className="form-group">
                            <label className="control-label">Closed Date</label>

                            <div className="control-size-frame">
                                <p className="form-control-static">{line.dateClosedText}</p>
                            </div>
                        </div>

                        <div className="form-group">
                            <label className="control-label">Reprint Order Number</label>

                            <div className="control-size-frame">
                                <p className="form-control-static">
                                    {line.reprintCaseNumber != "" &&
                                        <a href={`${line.reprintOrderLink.href}`} target={`${line.reprintOrderLink.target}`}>{line.reprintCaseNumber}</a>
                                    }
                                </p>
                            </div>
                        </div>

                        <div className="form-group">
                            <label className="control-label">Mixam Refund Amount</label>

                            <div className="control-size-frame">
                                <p className="form-control-static">{line.mixamRefundAmount}</p>
                            </div>
                        </div>

                        <div className="form-group">
                            <label className="control-label">Mixam Reprint Cost</label>

                            <div className="control-size-frame">
                                <p className="form-control-static">{line.mixamReprintCost}</p>
                            </div>
                        </div>

                        <div className="form-group">
                            <label className="control-label">Supplier Refund Amount</label>

                            <div className="control-size-frame">
                                <p className="form-control-static">{line.supplierRefundAmount}</p>
                            </div>
                        </div>

                        <div className="form-group">
                            <label className="control-label">Supplier Reprint Cost</label>

                            <div className="control-size-frame">
                                <p className="form-control-static">{line.supplierReprintCost}</p>
                            </div>
                        </div>

                        <div className="form-group">
                            <label className="control-label">Logistics Cost</label>

                            <div className="control-size-frame">
                                <p className="form-control-static">{line.logisticsCost}</p>
                            </div>
                        </div>

                    </div>
                </div>

                <div className="form-group last">
                    <div className="control-save-line">
                        <button type="button" onClick={(e) => this.handleCloseClick(e)} className="btn btn-outline-white">Close</button>
                    </div>
                </div>
            </form>
        </div>);

    }

}

export default DisputeAction;
