import mixam from '../../../boot/mixam';
import defineComponent from '../../../../components/flight/lib/component';
import DataTableNet from '../../table/data-table-net/data-table-net.tsx';
import WithPayments from './with-payments';
import { Breakpoints } from '../../table/data-table-net/StyledDataTableNet.tsx';

export default defineComponent(AdminPaymentsManager, WithPayments);

function AdminPaymentsManager() {
    this.attributes({
        url: '/admin/api/payment/list',
        dataTableSelector: '[data-type="data-table"]',
        dataTableNetSelector: '[data-type="data-table-net"]',
    });

    this.createSchema = function () {
        var cols = [];

        cols.push({
            title: '',
            type: 'thumbnail',
            hide: Breakpoints.MD,
            data: 'front',
        });
        cols.push({
            title: 'Order',
            type: 'link',
            hide: Breakpoints.SM,
            data: 'order',
        });

        cols.push({
            title: 'Type',
            type: 'text',
            hide: Breakpoints.SM,
            data: 'transactionType',
        });

        cols.push({
            title: 'Date',
            type: 'timebox',
            hide: Breakpoints.LG,
            data: 'date',
        });

        cols.push({
            title: 'Name',
            type: 'text',
            data: 'name',
        });

        cols.push({
            title: 'Email',
            type: 'text',
            data: 'customerEmail',
            width: '300px'
        });

        cols.push({
            title: 'Status',
            type: 'text',
            hide: Breakpoints.LG,
            data: 'status',
        });

        cols.push({
            title: 'Method',
            type: 'text',
            hide: Breakpoints.LG,
            data: 'paymentMethod',
        });

        cols.push({
            title: 'Net',
            type: 'number',
            decimal: 2,
            data: 'net',
            sum: true,
            currency: true,
        });

        cols.push({
            title: 'Vat',
            type: 'number',
            decimal: 2,
            data: 'vat',
            hide: Breakpoints.MD,
            sum: true,
            currency: true,
        });

        cols.push({
            title: 'Amount',
            type: 'number',
            decimal: 2,
            data: 'amount',
            sum: true,
            currency: true,
        });

        return cols;
    };

    this.updatePaymentLine = function (data) {
        if (!this.data) {
            return;
        }
        let verb = data.type;
        const payment = data.data;
        if (payment.shopId !== mixam.shop.id) {
            return;
        }
        const index = this.data.list.map((x) => x.id).indexOf(payment.id);

        if (verb === 'UPDATE') {
            if (index !== -1) {
                this.data.list[index] = payment;
            } else {
                this.data.list.unshift(payment);
            }
        }

        // console.log('updateArticleLine', data);
        this.paint();
        requestAnimationFrame(() => $('#PK' + payment.id).hilightTableLine());
    };

    this.after('initialize', function () {
        // DataTable.attachTo(this.select('dataTableSelector'));
        DataTableNet.attachTo(this.select('dataTableSelector'));
        setTimeout(() => this.getData(), 10);
        try {
            this.subscribe(this.updatePaymentLine.bind(this));
        } catch (e) {}
    });
}
