import mixam from '../../boot/mixam';
import defineComponent from '../../../components/flight/lib/component';

import Mustache from '../../../components/mustache/mustache';
import templateLiveUpload from "text!../../../appes6/templates/admin/live-uploads.mustache";

export default defineComponent(LiveUploads);

function LiveUploads() {


    this.attributes({
        thumbnailSelector: '[data-type="thumbnail"]'
    });

    this.render = function (data) {
        return Mustache.render(templateLiveUpload, data);
    };
    /**
     * subscribe to STOMP service that notify us about changes
     * in a specific order and a specific item
     */
    this.subscribe = function (onMessage) {
        this.trigger("log", {message: ["Subscribe to stomp channel ups3allmessages"], title: "LiveUploads.subscribe"});
        this.stomp = Stomp.client(mixam.stompServiceUrl);

        this.stomp.debug = (...args) => {
            if (args.join('').indexOf('Whoops! Lost connection to') !== -1) {
                setTimeout(() => this.subscribe(onMessage), 10);
            }
            this.trigger("log", {message: args, title: "LiveUploads.subscribe"});
        };

        this.stomp.connect(mixam.stompWebUser, mixam.stompWebPass, () => {
            this.stomp.subscribe("/topic/ups3allmessages", (d) => {
                const p = JSON.parse(d.body);
                onMessage(p);
            });
        }, (err) => this.trigger("log", err), '/');
    };

    this.onMessage = function (message) {
        if (message.data.indexOf('_thumb_') === -1) {
            const image = new Image();
            image.src = message.data;
            image.onload = () => {
                this.uploads.push(message);
                this.paint();
            };
        }
    };

    this.paint = function () {
        if (this.timer) {
            clearTimeout(this.timer);
        }

        this.timer = null;
        if (this.uploads.length) {
            const message = this.uploads.shift();
            const thumbnails = this.select('thumbnailSelector');
            const $mover = $(thumbnails[this.numOfItems - 1]);
            $mover.remove().addClass("new");
            $mover.css('backgroundImage', `url(${message.data})`);
            $mover.insertBefore($(thumbnails[0]));
            setTimeout(() => $mover.removeClass("new"), 20);
            if (this.uploads.length) {
                this.timer = setTimeout(() => this.paint(), 200);
            }
        }
    };

    this.after('initialize', function () {
        this.numOfItems = this.select('thumbnailSelector').size();
        this.uploads = [];
        this.subscribe(m => this.onMessage(m));
    });
}
