import defineComponent from '../../../../components/flight/lib/component';

export default defineComponent(StripeManager);

function StripeManager() {


    this.attributes({
        intentUrl: '/api/v3/stripe/paymentintent/create',
        resultUrl: 'orders/{orderId}/payment/stripe',
        secondaryFormSelector: '[data-type="secondary-form"]',
        stripeFormSelector: '[data-type="stripe-cart-form"]',
        intentSelector: '[data-type="intent"]',
        saveBtnSelector: "button",
        cardSelector: '[data-type="card"]',
        cardHolderSelector: '[data-type="cardHolder"]',
        errorSelector: '[data-type="errors"]',
        tokenSelector: '[data-type="stripe-token"]',
        frameSelector: '[data-type="3dsecure-frame"]',
    });

    this.stripeInit = function () {

        const elements = this.stripe.elements();

        // Custom styling can be passed to options when creating an Element.
        // (Note that this demo uses a wider set of styles than the guide below.)
        const style = {
            base: {
                color: '#32325d',
                lineHeight: '18px',
                fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
                fontSmoothing: 'antialiased',
                fontSize: '16px',
                '::placeholder': {
                    color: '#aab7c4'
                }
            },
            invalid: {
                color: '#fa755a',
                iconColor: '#fa755a'
            }
        };

        // Create an instance of the card Element.
        this.card = elements.create('card', {style: style});

        // Add an instance of the card Element into the `card-element` <div>.
        this.card.mount('#card-element');

        this.card.addEventListener('change', (event) =>  this.select('errorSelector').text(event.error ? event.error.message : ""));
    };

    this.handleSubmit = function (event) {
        event.preventDefault();
        this.select('saveBtnSelector').attr('data-loading', '*').attr('disabled', true);
        const cardHolderName = this.select('cardHolderSelector').val();

        $.post(this.attr.intentUrl, {orderId: this.order.id}, null, 'json').
        done(response => {
            const intentId = response.orderId;
            const intentSecret = response.itemId;
            this.stripe.handleCardPayment(
                intentSecret, this.card , {
                    payment_method_data: {
                        billing_details: {name: cardHolderName}
                    }
                }
            ).then(result => {
                //console.log(result);
                this.timer = setTimeout(() => {
                    this.submitOrderPayment();
                }, 10000); // allow webhook to finish before submitting
            });
        }).
        fail(response => {
            this.trigger("log", response);
        });
    };

    this.submitOrderPayment = function () {
        if (this.timer) {
            clearTimeout(this.timer);
            this.timer = null;
        }
        this.select('secondaryFormSelector').submit();
    };

    this.resetAnimation = function () {
        this.select('saveBtnSelector').removeAttr('data-loading').attr('disabled', false);
    };

    this.setOrder = function(event, order) {
        this.order = order;
    };

    this.after('initialize', function () {
        this.on(document, "setOrderData", this.setOrder);
        this.on(document, "uiRequestPaymentSubmit", this.submitOrderPayment);
        this.key = this.$node.data('key');

        this.amount = this.$node.data('amount');
        this.currency = this.$node.data('currency');
        let attemptedCount = 0;
        let intervalId = setInterval(() => {
                attemptedCount++;
                if (typeof window.Stripe !== 'undefined') {
                    clearInterval(intervalId);
                    this.stripe = Stripe(this.key);
                    this.stripeInit();
                    this.on("submit", {
                        stripeFormSelector: this.handleSubmit
                    });
                } else if(attemptedCount > 4) {
                    clearInterval(intervalId);
                    throw new Error("ES Module Stripe.js SDK not found");
                }
            },
            500
        );
    });
}
