import defineComponent from '../../../../components/flight/lib/component';

export default defineComponent(CmsPageEditor);

function CmsPageEditor() {

    this.attributes({
        viewCmsPagePageButton: '[data-type="view-cms-page-btn"]',
        viewCmsPageRequestUriSelector: '[data-type="cms-page-request-uri"]'
    });

    this.viewCmsPage = function() {
        const requestPageUri = this.select('viewCmsPageRequestUriSelector').val();
        window.open(requestPageUri,'_blank');
    };

    this.after('initialize', function () {
        this.on('click', {
            viewCmsPagePageButton: this.viewCmsPage
        });
    });
}
