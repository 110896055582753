import defineComponent from '../../../../components/flight/lib/component';
import AdminImageUpload from '../admin-image-upload';

export default defineComponent(ProductPageMediaEditor);

function ProductPageMediaEditor() {

    this.attributes({
        imageUploadContainerSelector: '[data-type="admin-image-upload"]',
        imageTableBodySelector: '[data-type="product-images-table-body"]',
        saveMediaButtonSelector: '[data-type="save-product-media-btn"]',
        mediaFormSelector: '[data-type="product-page-media-form"]',
        deleteMediaButtonSelector: '[data-type="delete-product-image-btn"]'
    });

    this.imageUploadCallback = function(event, data) {
        this.addProductImageTableRow(data);
    };

    this.imageDeleteCallback = function(event, data) {
        let tableRows = this.select('imageTableBodySelector').children('tr');
        tableRows.each(function(){
            let imageUrl = $(this).find('[data-field="image-url"]').attr('src');
            if(imageUrl === data.imageUpload.url) {
                $(this).remove();
            }
        });
    };

    this.addProductImageTableRow = function(imageData) {

        let tableRow = `
        <tr>
            <td style="width: 150px;">
                <img class="img-thumbnail rounded img-fluid" data-field="image-url" data-webp-url="${imageData.webpUrl}" src="${imageData.url}"/>
            </td>
            <td>
                <input class="form-control" type="text" data-field="image-title" required />
            </td>
            <td>
                <input class="form-control" type="text" data-field="image-alt-text" required />
            </td>
            <td>
                <input class="form-control" type="text" data-field="image-credit-text" />
            </td>
            <td>
                <input class="form-control" type="url" data-field="image-credit-url" />
            </td>
            <td class="text-end">
                <button class="btn btn-danger" data-type="delete-product-image-btn">Delete</button>
            </td>
        </tr>`;

        this.select('imageTableBodySelector').append(tableRow);
    };

    this.saveImages = function(event) {

        const saveUrl = `/admin/product/page/${this.pageId}/media`;

        let editor = this;

        if(editor.select('mediaFormSelector').get(0).checkValidity()) {

            editor.initSaveBtn();

            let tableRows = this.select('imageTableBodySelector').children('tr');

            let images = [];
            tableRows.each(function(){
                let imageElement = $(this).find('[data-field="image-url"]');
                images.push({
                    imageUrl: imageElement.attr('src'),
                    webpUrl: imageElement.data('webp-url'),
                    title: $(this).find('[data-field="image-title"]').val(),
                    altText: $(this).find('[data-field="image-alt-text"]').val(),
                    creditText: $(this).find('[data-field="image-credit-text"]').val(),
                    creditUrl: $(this).find('[data-field="image-credit-url"]').val()
                });
            });

            $.ajax({
                url: saveUrl,
                type: 'PUT',
                data: JSON.stringify(images),
                success: function(data) {
                    editor.resetSaveBtn();
                },
                error: function(error) {
                    editor.resetSaveBtn();
                    alert('Error Saving Data');
                }
            });

            event.preventDefault();
        } else {
            editor.select('mediaFormSelector').addClass('was-validated');
        }
    };

    this.deleteImageRow = function(event) {
        let tableRow = $(event.target).closest('tr');
        tableRow.remove();
        event.preventDefault();
    };

    this.initSaveBtn = function(event) {
        let saveBtn = this.select('saveMediaButtonSelector');
        setTimeout( function() {
            saveBtn.addClass('disabled');
            saveBtn.find('[data-type="spinner"]').removeClass('d-none');
            saveBtn.find('[data-type="btn-label"]').text("Saving");
        }, 1);
    };

    this.resetSaveBtn = function(event) {
        let saveBtn = this.select('saveMediaButtonSelector');
        setTimeout( function() {
            saveBtn.find('[data-type="spinner"]').addClass('d-none');
            saveBtn.find('[data-type="btn-label"]').text("Save");
            saveBtn.removeClass('disabled');
        }, 2000);
    };

    this.after('initialize', function () {
        this.pageId = $(this.node).data('page-id');
        AdminImageUpload.attachTo(this.select('imageUploadContainerSelector'));
        this.on('imageUploadSuccess', this.imageUploadCallback);
        this.on('imageDeleteSuccess', this.imageDeleteCallback);
        this.on('click', {
            saveMediaButtonSelector: this.saveImages,
            deleteMediaButtonSelector: this.deleteImageRow,
        });
    });
}