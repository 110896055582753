import defineComponent from '../../../components/flight/lib/component';
import Mustache from '../../../components/mustache/mustache';
import journalTemplate from 'text!../../../appes6/templates/admin/journal.mustache';

export default defineComponent(AdminJournalManager);

function AdminJournalManager() {

  this.attributes({
    buttonSelector: "button",
    textareaSelector: "textarea",
    contentSelector: ".journal-messages"
  });

  this.render = function (list) {
    return Mustache.render(journalTemplate, { list: list });
  };

  this.paint = function () {
    this.select('contentSelector').html(this.render(this.normalize(this.data)));
  };

  this.onClick = function (event) {
    event.stopPropagation();
    event.preventDefault();
    this.getData();
  };

  this.normalize = function (data) {
    return data.reverse().map(m => {
      const _date = new Date(m.date).format("dd/mm/yy");
      const _time = new Date(m.date).format("HH:MM");
      const _body = m.body.split(/\r|\n/);
      return {_date, _time, _body, from: m.from, photo: m.photo};
    });
  };

  this.getData = function () {
    const url = `/admin/api/order/${this.orderId}/journal/add`;
    const value = this.select('textareaSelector').val();
    this.select('textareaSelector').val("");
    if (value) {
      $.post(url, { body: value }, null, 'json')
        .done(data => {
          this.data = data;
          this.select('buttonSelector').removeAttr('data-loading').attr('disabled', false);
          this.paint();
        })
        .fail((err) => this.trigger("log", { message: err }));

      this.select('buttonSelector').attr('data-loading', '*').attr('disabled', true);
    }
  };

  this.after('initialize', function () {
    this.orderId = this.$node.data('order');
    this.on('click', {
      buttonSelector: this.onClick
    });
  });
}
