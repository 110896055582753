export default WithSignDataRequest;

function WithSignDataRequest() {

    this.attributes({
        signUrl: '/sign-data-request'
    });

    this.signDataRequest = function (callback) {
        if (this.token) {
            // We already have a token in memory...
            return callback(this.token);
        }
        $.get(this.attr.signUrl, null, null, "json")
            .done(response => {
                this.token = response.signature;
                // Clear the new token from memory before it expires
                setTimeout(() => delete this.token, 45 * 60 * 1000);
                callback(this.token);
            })
            .fail(err => this.trigger("log", {message: err}));
    };
}
