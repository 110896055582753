var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useState } from 'react';
import { Button, Form, FormCheck, PublicationsGrid } from './components/styled';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Spinner from 'react-bootstrap/Spinner';
import { Formik } from 'formik';
import axios from "axios";
import { formatCurrency } from "./CurrencyUtils";
import i18nShop from "../../../../../assets/i18n/i18n";
var MAX_DISPLAYED_AVAILABLE_SPECIFICATIONS = 5;
export var PublicationSpawner = function (props) {
    var _a, _b;
    var _c = useState((_a = props.availableItemSpecifications[0]) === null || _a === void 0 ? void 0 : _a.policyId), policyId = _c[0], setPolicyId = _c[1];
    var _d = useState((_b = props.availableItemSpecifications[0]) === null || _b === void 0 ? void 0 : _b.itemSpecification), itemSpecification = _d[0], setItemSpecification = _d[1];
    var _e = useState(false), isSpawning = _e[0], setSpawning = _e[1];
    var _f = useState(undefined), spawnedOrder = _f[0], setSpawnedOrder = _f[1];
    var _g = useState(props.previousPublications), generatedPublications = _g[0], setGeneratedPublications = _g[1];
    var _h = useState(false), showMore = _h[0], setShowMore = _h[1];
    var handleShowMore = function () {
        setShowMore(!showMore);
    };
    var visibleSpecifications = showMore
        ? props.availableItemSpecifications
        : props.availableItemSpecifications.slice(0, MAX_DISPLAYED_AVAILABLE_SPECIFICATIONS);
    var remainingSpecificationsCount = props.availableItemSpecifications.length - MAX_DISPLAYED_AVAILABLE_SPECIFICATIONS;
    var spawnPublication = function (publicationRequest) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            axios.post("/api/orders/".concat(props.orderId, "/items/").concat(props.itemId, "/publication"), publicationRequest)
                .then(function (response) {
                var publications = __spreadArray([], generatedPublications, true);
                publications.push(response.data);
                setGeneratedPublications(publications);
                setSpawnedOrder(response.data);
                setSpawning(false);
            });
            return [2 /*return*/];
        });
    }); };
    var formatSpecificationDescription = function (specification) {
        var originalParts = props.originalSpecification.split('\n');
        var currentParts = specification.split('\n');
        return currentParts.map(function (part, index) {
            if (index === 0) {
                // Ignore First Line (Either same as original spec or the copies and product name)
                return React.createElement(React.Fragment, null,
                    React.createElement("span", { key: index }, part),
                    React.createElement("br", null));
            }
            var originalWords = originalParts[index] ? originalParts[index].split(' ') : [];
            var currentWords = part.split(' ');
            var formattedWords = currentWords.map(function (word, wordIndex) {
                var _a;
                if (word.trim() !== ((_a = originalWords[wordIndex]) === null || _a === void 0 ? void 0 : _a.trim())) {
                    // Highlight Any Differences Between Specs
                    return React.createElement("span", { key: wordIndex, style: { fontWeight: 900 } },
                        word,
                        " ");
                }
                return React.createElement("span", { key: wordIndex },
                    word,
                    " ");
            });
            return React.createElement(React.Fragment, null,
                React.createElement("span", { key: index }, formattedWords),
                React.createElement("br", null));
        });
    };
    return (React.createElement(Formik, { onSubmit: function () {
            setSpawning(true);
            var publicationRequest = {
                orderId: props.orderId,
                itemId: props.itemId,
                policyId: policyId,
                itemSpecification: itemSpecification
            };
            spawnPublication(publicationRequest);
        }, initialValues: {
            policyId: props.availableItemSpecifications.length > 0 ? props.availableItemSpecifications[0].policyId : undefined,
        } }, function (_a) {
        var handleSubmit = _a.handleSubmit, handleChange = _a.handleChange, handleBlur = _a.handleBlur, values = _a.values, touched = _a.touched, errors = _a.errors;
        return (React.createElement(Form, { noValidate: true, onSubmit: handleSubmit },
            generatedPublications.length != 0 &&
                React.createElement("div", { className: 'd-flex flex-column mt-3', style: { gap: '1rem' } },
                    React.createElement("h3", null, i18nShop.t('publicationSpawner.previousPublicationsTitle')),
                    React.createElement(PublicationsGrid, null, generatedPublications.map(function (publication) {
                        var options = { year: 'numeric', month: 'long', day: 'numeric' };
                        return (React.createElement(React.Fragment, null,
                            React.createElement("a", { className: 'btn btn-primary me-2', key: "button-".concat(publication.orderCaseNumber), href: "/orders/".concat(publication.orderId), target: "_blank" },
                                i18nShop.t('publicationSpawner.printLink'),
                                " #",
                                publication.orderCaseNumber),
                            React.createElement("span", { key: "date-".concat(publication.orderCaseNumber) },
                                i18nShop.t('publicationSpawner.created'),
                                ": ",
                                new Date(publication.dateCreated).toLocaleDateString(undefined, options))));
                    }))),
            isSpawning &&
                React.createElement("div", { className: 'd-flex flex-column align-items-center mt-5', style: { gap: '1rem', color: 'var(--bs-primary)' } },
                    React.createElement("h2", null,
                        React.createElement(Spinner, { as: "span", animation: "border", role: "status", "aria-hidden": "true" })),
                    React.createElement("h3", null, i18nShop.t('publicationSpawner.createPublication'))),
            !isSpawning &&
                React.createElement(React.Fragment, null,
                    React.createElement("h3", { className: "mt-5" }, i18nShop.t('publicationSpawner.createPublicationTitle')),
                    React.createElement(Card, { className: "mt-3" },
                        React.createElement(Card.Header, null, i18nShop.t('publicationSpawner.availableSpecificationsTitle')),
                        React.createElement(Card.Body, null,
                            props.availableItemSpecifications.length > 0 ? (React.createElement(Row, null,
                                React.createElement("p", null, i18nShop.t('publicationSpawner.availableSpecificationsBody')))) : (React.createElement(Row, null,
                                React.createElement("p", null, i18nShop.t('publicationSpawner.noAvailableSpecifications')))),
                            React.createElement(Row, null,
                                React.createElement(Form.Group, { as: Col, controlId: "policyIdOptions" },
                                    visibleSpecifications.map(function (transformedItemSpecification) {
                                        return (React.createElement(Card, { className: "mb-3 shadow-sm option-card", key: transformedItemSpecification.policyId, onClick: function () {
                                                setItemSpecification(transformedItemSpecification.itemSpecification);
                                                setPolicyId(transformedItemSpecification.policyId);
                                            }, style: { cursor: 'pointer' } },
                                            React.createElement(Card.Body, { className: "d-flex justify-content-between align-items-center" },
                                                React.createElement("div", { className: "d-flex align-items-center" },
                                                    React.createElement(FormCheck, { id: transformedItemSpecification.policyId, type: "radio", name: "policyId", value: transformedItemSpecification.policyId, checked: policyId === transformedItemSpecification.policyId, onChange: function (e) {
                                                            handleChange(e);
                                                            setItemSpecification(transformedItemSpecification.itemSpecification);
                                                            setPolicyId(transformedItemSpecification.policyId);
                                                        }, onBlur: handleBlur, isInvalid: touched.policyId && !!errors.policyId, className: "me-3" }),
                                                    React.createElement("div", null, formatSpecificationDescription(transformedItemSpecification.itemDescription))),
                                                React.createElement("div", { className: "ms-5" },
                                                    React.createElement("span", { className: "font-weight-bold" }, formatCurrency(transformedItemSpecification.price, props.locale))))));
                                    }),
                                    remainingSpecificationsCount > 0 && !showMore && (React.createElement(Row, null,
                                        React.createElement(Col, { className: "text-center" },
                                            React.createElement(Button, { className: "btn mt-3", variant: "secondary", onClick: handleShowMore }, i18nShop.t('publicationSpawner.showMoreBtnLabel', { count: remainingSpecificationsCount }))))),
                                    showMore && (React.createElement(Row, null,
                                        React.createElement(Col, { className: "text-center" },
                                            React.createElement(Button, { className: "btn mt-3", variant: "secondary", onClick: handleShowMore }, i18nShop.t('publicationSpawner.showLessBtnLabel'))))))))),
                    !spawnedOrder && props.availableItemSpecifications.length > 0 &&
                        React.createElement(Row, { className: "mt-3" },
                            React.createElement(Col, null,
                                React.createElement(Button, { type: "submit", variant: "primary" }, i18nShop.t('publicationSpawner.createPublicationBtnLabel')))),
                    spawnedOrder &&
                        React.createElement(Row, { className: "mt-3" },
                            React.createElement(Col, null,
                                React.createElement("p", null, i18nShop.t('publicationSpawner.publicationReady')),
                                React.createElement(Button, { type: "button", variant: "success", onClick: function () { return window.open("/orders/".concat(spawnedOrder.orderId)); } }, i18nShop.t('publicationSpawner.viewPublicationBtnLabel')))))));
    }));
};
