import defineComponent from '../../../components/flight/lib/component';

// NOTE: this import looks unused, but it's very much needed!
import utils from '../../../components/flight/lib/utils';

export default  defineComponent(FixedHeader);

function FixedHeader() {

    this.attributes({
        thSelector: "th",
        containerSelector: ".fh-table-container",
        fixedPanelSelector: ".fixed-panel",
        sortHeaderSelector: "th.sortable",
        tableContainerSelector: ".table-container",
    });

    this.onScroll = function (event, data) {

        if (data && data.offset) {
            this.currentOffsetTop = data.offset;
        } else {
            this.currentOffsetTop = document.documentElement.scrollTop || document.body.scrollTop;
        }

        this.top = this.$node.offset().top;
        this.bottom = this.top + this.$node.height();

        if (this.top && (this.top <= this.currentOffsetTop) && this.bottom && (this.currentOffsetTop <= this.bottom)) {
            if (!this.fixedHeader) {
                this.createCloneHeader();
            }
            if (this.fixedHeader && this.fixedHeader.hasClass("hidden")) {
                this.fixedHeader.removeClass("hidden");
            }
        } else {
            this.reset();
        }

    };

    this.onResize = function (/*event*/) {
        var mainThElements = this.select('thSelector'),
            newTop = this.$node.offset().top;

        this.top =  newTop || this.top;
        if (this.fixedHeader) {
            this.fixedHeader.find("th").each((index, th) => $(th).css("width", mainThElements.eq(index).outerWidth() + "px"));
            this.fixedHeader.find("table").css("width", $(this.attr.tableContainerSelector).find("table").width());
        }
    };

    this.reset = function(event) {
        if (this.fixedHeader) {
            this.fixedHeader.remove();
            this.fixedHeader = null;
        }
    };

    this.onRender = function(event) {
        this.reset();
        this.onScroll(event, {offset: this.currentOffsetTop});
    };

    this.createCloneHeader = function () {
        var offset = this.$node.offset();
        if (!$(this.attr.containerSelector).length) {
            this.$containerHolder =  $("<div/>", {"class": "container-fluid"});
            this.$container =  $("<div/>", {"class": "fh-table-container table-container"}).appendTo(this.$containerHolder);
            this.fixedHeader = $("<div/>", {"class": "fixed-panel"}).append(this.$containerHolder).appendTo($(this.attr.tableContainerSelector));
        } else {
            this.$container = $(this.attr.containerSelector);
            this.fixedHeader = $(this.attr.fixedPanelSelector);
        }

        this.$container.empty().append(this.$node.clone());
        this.fixedHeader.find("colgroup, tbody, tfoot, thead:not(:first)").remove().end().addClass("fixed-header" + (offset.top > 0 ? " hidden" : ""));
        this.fixedHeader.on("click", this.attr.sortHeaderSelector, $.proxy(this.sortClick, this));
        this.onResize();
        this.onScroll();
    };

    this.sortClick = function (event) {
        this.trigger('requestSortClick', {th: $(event.target).closest("th")});
    };

    this.after('initialize', function () {
        this.currentOffsetTop = 0;
        $(window).resize($.proxy(this.onResize, this));
        this.on(document, "uiPageScroll", this.onScroll);
        this.on(document, "uiAfterTableRender", this.onRender);
        //this.createCloneHeader();
    });
}