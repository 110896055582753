import defineComponent from '../../../../components/flight/lib/component';
import DataTable from '../../table/data-table-net/data-table-net.tsx';
import WithOrdersItemsMembers from '../with-orders-items-members';
import ResellerGroup from "../../constants/reseller-groups";
import {Breakpoints} from "../../table/data-table-net/StyledDataTableNet.tsx";
import mixam from "../../../boot/mixam";

export default defineComponent(AdminResellerActivity, WithOrdersItemsMembers);

const UNKNOWN_AVATAR = 'https://s3-eu-west-1.amazonaws.com/static3.mixam.com/members/unnamed.png';

function AdminResellerActivity() {

    this.attributes({
        url: "/admin/api/resellers/activity",
        dataTableSelector: '[data-type="data-table"]'
    });

    this.error = function (err) {
        if (console) {
            console.log(err);
        }
    };

    this.createSchema = function () {
        var cols = [];

        cols.push({
            title: "",
            type: "image",
            data: "photo",
            sortable: false,
        });
        cols.push({
            title: "Name",
            type: "link",
            data: "name"
        });
        cols.push({
            title: "Email",
            type: "link",
            data: "email"
        });
        cols.push({
            title: "Company",
            type: "text",
            data: "company"
        });
       cols.push({
            title: "Reseller",
            type: "text",
            data: "reseller",
           hide: Breakpoints.SM
        });

        cols.push({
            title: "Opening",
            type: "number",
            data: "resellerOpenBalance",
            sum: true,
            decimal: 2,
            prepend: mixam.shop.currency.prefix,
            currency: true,
            hide: Breakpoints.MD
        });
        cols.push({
            title: "Quarterly #",
            type: "number",
            data: "quarterlyAccumulatorCount",
            sum: true,
            hide: Breakpoints.MD
        });

        cols.push({
            title: `Quarterly ${mixam.shop.currency.prefix}`,
            type: "number",
            data: "quarterlyAccumulatorTotal",
            sum: true,
            decimal: 2,
            prepend: mixam.shop.currency.prefix,
            currency: true,
            hide: Breakpoints.MD
        });

       cols.push({
            title: "Semiannual #",
            type: "number",
            data: "semiannualAccumulatorCount",
            sum: true,
           hide: Breakpoints.LG
        });

        cols.push({
            title: `Semiannual ${mixam.shop.currency.prefix}`,
            type: "number",
            data: "semiannualAccumulatorTotal",
            sum: true,
            decimal: 2,
            prepend: mixam.shop.currency.prefix,
            currency: true,
            hide: Breakpoints.LG
        });

       cols.push({
            title: "Annual",
            type: "number",
            data: "annualAccumulatorCount",
            sum: true,
           hide: Breakpoints.LG
        });

        cols.push({
            title: `Annual ${mixam.shop.currency.prefix}`,
            type: "number",
            data: "annualAccumulatorTotal",
            sum: true,
            decimal: 2,
            prepend: mixam.shop.currency.prefix,
            currency: true,
            hide: Breakpoints.LG
        });

       cols.push({
            title: "Accumulator",
            type: "number",
            data: "eternalAccumulatorCount",
            sum: true,
           hide: Breakpoints.XL
        });

        cols.push({
            title: `Accumulator ${mixam.shop.currency.prefix}`,
            type: "number",
            data: "eternalAccumulatorTotal",
            sum: true,
            decimal: 2,
            prepend: mixam.shop.currency.prefix,
            currency: true,
            hide: Breakpoints.XL,
            defaultSort: true,
            sortOrder: 'desc'
        });
        return cols;
    };

    this.normalize = function (list) {
        return list.map(member => this.normalizeMember(member));
    };

    this.normalizeMember = function (member) {
        //const result = $.extend(true, {}, item);
        if (!member.photo && member.fullcontactInfo && member.fullcontactInfo.photos.length) {
            member.photo = (member.fullcontactInfo.photos.filter(x => x.isPrimary)[0] || {}).s3url;
        }
        const result = {
            id: member.id,
            photo: {
                href: `/member/${member.id}`,
                src: member.photo || UNKNOWN_AVATAR,
                caption:  (`${member.firstName} ${member.lastName}`).trim(),
                title:  `${member.firstName} ${member.lastName}`
            },
            name: {
                href: `/member/${member.id}`,
                caption:   (`${member.firstName} ${member.lastName}`).trim(),
                title:  `${member.firstName} ${member.lastName}`
            },
            email: {
                href: `/member/${member.id}`,
                caption:   (member.email || "").trim(),
                title:  `${member.firstName} ${member.lastName}`
            },
            company: member.billingAddress && member.billingAddress.company,
            roles:  member.role.split(" ").map(role => {
                return {role: role.toLowerCase().substr(5).capitalize()};
            }),
            shop: this.shops[member.shopId] && this.shops[member.shopId].icon,
            createdDate: member.dateCreated,
            modifiedDate: member.lastModifiedDate,
            seenDate: member.lastSigninDate,
            purchasesCount: member.purchasesCount || 0,
            confirmedOrderCount: member.confirmedOrderCount || 0,
            semiannualAccumulatorTotal: member.semiannualAccumulator.total,
            annualAccumulatorTotal: member.annualAccumulator.total,
            eternalAccumulatorTotal: member.eternalAccumulator.total,
            quarterlyAccumulatorTotal: member.quarterlyAccumulator.total,
            quarterlyAccumulatorCount: member.quarterlyAccumulator.count,
            semiannualAccumulatorCount: member.semiannualAccumulator.count,
            annualAccumulatorCount: member.annualAccumulator.count,
            eternalAccumulatorCount: member.eternalAccumulator.count,
            resellerOpenBalance: member.resellerOpenBalance || 0,
            reseller: ResellerGroup[member.reseller]
        };
        return result;
    };

    this.getData = function () {
        $.getJSON(this.attr.url)
            .done(data => {
                this.data = data;
                this.paint();
           })
            .fail(() => this.error("data-file-missing"));
    };

    this.paint = function () {
        const data = {
            columns: this.createSchema(),
            fileName: this.attr.url.split("/").pop(),
            response: this.normalize(this.data),
            "class": 'table-striped table-order-list',
            "default-sort": {
                "eternalAccumulatorTotal": -1
            }
        };
        if (this.dataReadyFired) {
            window.dispatchEvent(new CustomEvent('uiDataUpdate', { detail: data }));
        } else {
            this.dataReadyFired = true;
            this.trigger("uiDataReady", data);
            this.clock();
        }
    };

    this.after('initialize', function () {
        DataTable.attachTo(this.select('dataTableSelector'));
        setTimeout(() => this.getData(), 10);

        try {
            this.subscribe();
        } catch (e) {

        }
    });
}