import defineComponent from '../../../components/flight/lib/component';
import WithSubmitAnimation from './../with-submit-zoom-animation';

export default defineComponent(ManualDeliveryManager, WithSubmitAnimation);

function ManualDeliveryManager() {
    this.attributes({
        openBtnSelector: ".btn-manual",
        closeBtnSelector: '[data-type="cancel"]',
        formSelector: '[data-type="manual-delivery-form"]',
        descriptionSelector: "[data-type='delivery-description']",
        daysSelector: "[data-type='days']",
        costSelector: "[data-type='cost']",
        saveBtnSelector: '[data-type="save"]',
    });

    this.open = function (event) {
        this.select('openBtnSelector').hide('slow');
        this.select('formSelector').show('slow');
    };

    this.close = function (event) {
        this.select('openBtnSelector').show('slow');
        this.select('formSelector').hide('slow');
    };

    this.submit = function (event) {
        this.handleSubmit(event);
        event.preventDefault();

        const url = this.select('formSelector').prop('action');
        const data = {
            description: this.select('descriptionSelector').val(),
            days: this.select('daysSelector').val(),
            price: this.select('costSelector').val()
        };

        $.post(url, data, null, 'json').done(response => {
            this.resetAnimation();
            this.close();
        });
    };

    this.after('initialize', function () {
        this.on('submit', this.submit);
        this.on('click', {
            openBtnSelector: this.open,
            closeBtnSelector: this.close
        });
    });
}
