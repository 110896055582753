import defineComponent from '../../../../components/flight/lib/component';
import React from 'react';
import ReactDom from 'react-dom/client';
import DiscountCodeEditor from '../React/DiscountCodeEditor/DiscountCodeEditor.tsx';

export default defineComponent(AdminDiscountCodeEditor);

function AdminDiscountCodeEditor() {

    this.initDiscountCodeEditor = function () {
        const editor = $(this.node);
        const root = ReactDom.createRoot(editor.get(0));
        root.render(
            <DiscountCodeEditor
                discountCodeId={editor.data('discount-code-id')}
                shopId={editor.data('shop-id')}
            ></DiscountCodeEditor>
        );
    };

    this.after('initialize', function () {
        this.initDiscountCodeEditor();
    });

}
