import mixam from '../../../boot/mixam';
import defineComponent from '../../../../components/flight/lib/component';
import DataTable from '../../table/data-table-net/data-table-net.tsx';
import {Breakpoints} from "../../table/data-table-net/StyledDataTableNet.tsx";
import ResellerGroup from "../../constants/reseller-groups";

export default defineComponent(ResellerRequestList);

function ResellerRequestList() {

    this.attributes({
        url: "/admin/api/resellers/list",
        dataTableSelector: '[data-type="data-table"]'
    });

    this.createSchema = function () {
        const cols = [];

        cols.push({
            title: "Name",
            type: "text",
            data:"name"
        });
        cols.push({
            title: "Email",
            type: "text",
            data:"email",
            width: '300px'
        });
        cols.push({
            title: "Phone",
            type: "text",
            data:"phone"
        });
        cols.push({
            title: "Company",
            type: "text",
            data:"company"
        });
        cols.push({
            title: "Web Site",
            type: "text",
            data:"web",
            width: '300px',
            hide: Breakpoints.LG
        });
        cols.push({
            title: "Company Phone",
            type: "text",
            data:"companyNumber",
            hide: Breakpoints.XL
        });
        cols.push({
            title: "Date Created",
            type: "timebox",
            data:"dateCreated",
            hide: Breakpoints.XL,
            defaultSort: true,
            sortOrder: 'desc'
        });
        cols.push({
            title: "Status",
            type: "text",
            data:"status",
        });
        cols.push({
           title: "Decline Reason",
           type: "text",
           data: "rejectionReason"
        });
        cols.push({
            title: "Confirm",
            type: "resellercommand",
            width: "100px",
            data:"confirm"
        });

        return cols;
    };

    this.paint = function () {
        const data = {
            columns: this.createSchema(),
            fileName: this.attr.url.split("/").pop(),
            response: this.normalize(this.data)
        };

        if (this.dataReadyFired) {
            window.dispatchEvent(new CustomEvent('uiDataUpdate', { detail: data }));
        } else {
            this.dataReadyFired = true;
            this.trigger("uiDataReady", data);
        }
    };

    this.normalize = function (list) {
        return list.map(x => this.normalizeResellerRequest(x));
    };

    this.normalizeResellerRequest = function (reseller) {
        const so = $.extend(true, {}, reseller);
        so.name = reseller.firstName + " " + reseller.lastName;
        so.confirm = -1;
        so.status = ResellerGroup[so.reseller];
        if(!so.status) {
            so.status = 'Pending';
        }
        return so;
    };

    this.getData = function () {
        $.getJSON(this.attr.url)
            .done(data => {
                this.data = data;
                this.paint();
                setTimeout(() => this.getData(), 1000 * 60 * 15);
            })
            .fail((err) => this.trigger("log", {message: err}));
    };

    /**
     * subscribe to STOMP service that notify us about changes
     * in a specific order and a specific item
     */
    this.subscribe = function (onMessage) {
        this.trigger("log", {message: ["Subscribe to stomp channel allresellerrequest"], title: "ResellerRequestList.subscribe"});
        this.stomp = Stomp.client(mixam.stompServiceUrl);

        this.stomp.debug = (...args) => {
            if (args.join('').indexOf('Whoops! Lost connection to') !== -1) {
                setTimeout(() => this.subscribe(onMessage), 10);
            }
            this.trigger("log", {message: args, title: "ResellerRequestList.subscribe"});
        };

        this.stomp.connect(mixam.stompWebUser, mixam.stompWebPass, () => {
            this.stomp.subscribe("/topic/allresellerrequest", (d) => {
                const p = JSON.parse(d.body);
                onMessage(p);
            });
        }, (err) => this.trigger("log", err), '/');
    };

    this.updateResellerRequestLine = function (data) {
        if (!this.data) {
            return;
        }
        let verb = data.type;
        const mergeJob = data.data;
        if (mergeJob.shopId !== mixam.shop.id) {
            return;
        }
        if (mergeJob) {
            const index = this.data.list.map(x => x.id).indexOf(mergeJob.id);

            if (verb === "UPDATE" || verb === "INSERT") {
                if (index !== -1) {
                    this.data.list[index] = mergeJob;
                } else {
                    this.data.list.unshift(mergeJob);
                }

            } else if (verb === "DELETE" && index !== -1) {
                this.data.list.splice(index, 1);
            }

            this.paint();
            requestAnimationFrame(() => $("#PK" + mergeJob.id).hilightTableLine());
        }
    };

    this.statusChange = function (event, data) {
        console.log('statusChange', data);
        $.post(`/admin/api/resellers/${data.requestId}/create`, data).done((response) => true);
    };

    this.after('initialize', function () {
        DataTable.attachTo(this.select('dataTableSelector'));
        this.on(document, "uiRequestResellerStatusChange", this.statusChange);

        setTimeout(() => this.getData(), 10);
        this.subscribe(this.updateResellerRequestLine.bind(this));

    });
}
