import React from 'react';

function createArtworkReady(data) {
  const icons = data && data.map((x, i) => {
    const className = x.MAP_READY ? 'fa fa-circle text-success' : (
      x.MAP_HALF_READY ? "fa fa-dot-circle-o text-warning" : "fa fa-circle-o text-danger"
    );

    return React.createElement("i", {
      key: 'icon' + i,
      className: className
    });

  });

  return (React.createElement("span", {
      className: 'cell-content'
    },
    icons
  ));

}

export default createArtworkReady;