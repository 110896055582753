import mixam from '../../../../boot/mixam';
import defineComponent from '../../../../../components/flight/lib/component';
import AnalyticsZSummary from './analytics-z-summary';
import AnalyticsPayment from './analytics-payment';
import AnalyticsProducts from './analytics-products';
import AnalyticsAccountManagers from './analytics-account-managers';
import AnalyticsMachines from './analytics-machines';
import AnalyticsFulfilment from './analytics-fulfilment';
import AnalyticsMethods from './analytics-methods';
import AnalyticsUploads from './analytics-uploads';
import AnalyticsShare from './analytics-share';
import AnalyticsShareViews from './analytics-share-views';
import Chartjs from '../../../../../components/Chart.js';
import moment from 'moment/moment';

// NOTE: these imports looks unused, but are very much needed!
import Daterangepicker from 'bootstrap-daterangepicker/daterangepicker';
import stomp from '../../../../../components/stomp/stomp171';

export default defineComponent(AdminAnalytics);

function AdminAnalytics() {
    var GET_LIST_DELAY = 250,
        counterTypes = ("INIT,DETAILS,NO_DETAILS,CONFIRM,PAYMENT,PAYMENTCARD,PAYMENTBANK,PAYMENTACCOUNT,PAYMENTAMAZONPAY, PAYMENTBYPHONE, PAYMENTERROR, PAYMENTPAYPAL, PAYMENTVOLTPAY,INIT_CONTACT,INIT_QUOTE,INIT_ORDER,PRICE_CONFLICT," +
            "CANCEL_CONFIRMED,CANCEL_PENDING,UN_CONFIRM,DIRECT_VISITS,PAID_VISITS,ORGANIC_VISITS,SOCIAL_VISITS,REFERRAL_VISITS,MIXAM_VISITS,VISITS,PRODUCT_1,PRODUCT_2,PRODUCT_3,PRODUCT_4").split(","),
        barColors = ["151,187,205", "166,175,212", "189,166,212", "212,166,185", "212,187,166", "212,211,166", "166,212,169", "220,220,220"];

    this.attributes({
        zSummarySelector: '[data-gizmo="z-summary"]',
        paymentSelector: '[data-gizmo="payment"]',
        productsSelector: '[data-gizmo="products"]',
        accountManagersSelector: '[data-gizmo="account-managers"]',
        machineSelector: '[data-gizmo="machines"]',
        fulfilmentSelector: '[data-gizmo="fulfilment"]',
        methodsSelector: '[data-gizmo="methods"]',
        uploadsSelector: '[data-gizmo="uploads"]',
        shareSelector: '[data-gizmo="share-clicks"]',
        shareViewsSelector: '[data-gizmo="share-view"]',
        tableContainerSelector: '#membersTableContainer',
        dateRangeSelector: '#reportrange',
        dateRangeSpanSelector: '#reportrange span',
        dashboardSelector: '.dashboard',
        last30CanvasSelector: '#dailyChart',
        legendSelector: '.widget.legend',
        currentUsersSelector: '#currentUsers',
        changeCounterSelector: '[data-type="change-counter"]',
        legendCheckboxSelector: '.widget.legend input[data-legend]'
    });

    this.createLabels = function (sDate, end) {
        var result = [],
            start = sDate.clone();

        while (start.toDate().getTime() < end.toDate().getTime()) {
            result.push(start.format("D ddd"));
            start = start.add('days', 1);
        }
        return result;
    };

    this.createChartTypeMetrics = function () {
        return [
            {name: "Net Value", field: "value", format: 2, symbol: "&#163;", active: true},
            {name: "Sales", field: "sum", symbol: "&#163;", format: 2, active: true},
            {name: "Orders count", field: "count", format: 0},
            {name: "Delivery Charges", field: "delivery", symbol: "&#163;", format: 2},
            {name: "VAT Charges", field: "vat", symbol: "&#163;", format: 2},
            {name: "Weight", field: "weight", postSymbol: "kg.", format: 0}
        ];
    };

    this.onLegendClick = function (event) {
        var metrics = this.last30DaysChart[this.last30DaysCounterType],
            $target = $(event.target),
            series = metrics[$target.data("legend")];

        if (series) {
            series.active = $target.prop("checked");
        }
        setTimeout($.proxy(this.displayLast30DaysChart, this), 10);
    };

    this.onCounterSelectorChange = function (event) {
        var $target = $(event.target),
            checkboxes = this.select('legendCheckboxSelector'),
            metrics;

        this.last30DaysCounterType = $target.val();
        metrics = this.last30DaysChart[this.last30DaysCounterType];

        // sync metrics active prop with the state ot the UI
        metrics.forEach(function (metrica, i) {
            metrica.active = checkboxes.eq(i).prop("checked");
        });

        setTimeout($.proxy(this.displayLast30DaysChart, this), 10);
    };

    this.initLast30DaysChart = function (data) {
        var that = this,
            start = moment().subtract('days', 29).startOf("day"),
            end = moment().endOf("day"),
            labels = this.createLabels(start, end);


        this.last30DaysCounterType = "PAYMENT";
        this.chartData = {labels: labels};
        this.last30DaysChart = {};
        this.last30DaysChartType = "line";

        counterTypes.forEach(function (type) {
            that.last30DaysChart[type] = that.createChartTypeMetrics();
            that.last30DaysChart[type].forEach(function (metrica) {
                metrica.data = labels.map(function () {
                    return 0;
                });
            });
        });

        data.forEach(function (counter) {
            if (that.last30DaysChart[counter.type]) {
                that.last30DaysChart[counter.type].forEach(function (metrica) {
                    var d = moment(counter.created).diff(start, "days");
                    metrica.data[d] = Math.round(counter[metrica.field] * 100) / 100;
                });
            }
        });

        counterTypes.forEach(function (type) {
            that.last30DaysChart[type].forEach(function (metrica) {
                metrica.max = Math.max.apply(Math, metrica.data);
            });
        });

        setTimeout($.proxy(this.displayLast30DaysChart, this), 10);
    };

    this.displayLast30DaysChart = function () {
        var that = this,
            ctx = this.select('last30CanvasSelector').get(0).getContext("2d"),
            chartData = this.chartData,
            metrics = this.last30DaysChart[this.last30DaysCounterType],
            max = 0,
            i = 0;

        this.chartData.datasets = [];
        metrics.forEach(function (metrica) {
            if (metrica.active) {

                if (that.last30DaysChartType === "line") {
                    chartData.datasets.push({
                        label: metrica.name,
                        fillColor: "rgba(" + barColors[i] + ",0.2)",
                        strokeColor: "rgba(" + barColors[i] + ",0.1)",
                        pointColor: "rgba(" + barColors[i] + ",1)",
                        pointStrokeColor: "#fff",
                        pointHighlightFill: "#fff",
                        pointHighlightStroke: "rgba(" + barColors[i] + ",1)",
                        data: metrica.data
                    });
                } else {
                    chartData.datasets.push({
                        label: metrica.name,
                        fillColor: "rgba(" + barColors[i] + ",0.5)",
                        strokeColor: "rgba(" + barColors[i] + ",0.8)",
                        highlightFill: "rgba(" + barColors[i] + ",0.75)",
                        highlightStroke: "rgba(" + barColors[i] + ",1)",
                        data: metrica.data
                    });
                }
                max = Math.max(max, metrica.max);
                i += 1;
            }
        });

        if (this.last30Days) {
            this.last30Days.destroy();
        }

        var steps = 20;

        if (chartData.datasets.length) {
            if (this.last30DaysChartType === "line") {
                this.last30Days = new Chartjs(ctx).Line(chartData, {
                    responsive: true,
                    maintainAspectRatio: false,
                    scaleOverride: true,
                    scaleSteps: steps,
                    scaleStepWidth: Math.ceil(max / steps),
                    scaleStartValue: 0
                });
            } else {
                this.last30Days = new Chartjs(ctx).Bar(chartData, {
                    responsive: true,
                    maintainAspectRatio: false
                });
            }
        }
    };

    this.updateCounter = function (counters, isHighlight) {
        var that = this;

        counters.forEach(function (counter) {
            var nodes = that.$node.find(".old-dashboard [data-counter-type='" + counter.type + "']");

            nodes.each(function (index, node) {
                var $node = $(node),
                    field = $node.data("field"),
                    type = $node.data("type"),
                    value = counter[field];

                if (type === "decimal") {
                    value = parseFloat(value).formatNumber(2);
                } else if (type === "int") {
                    value = parseFloat(value).formatNumber(0);
                }
                $node.text(value);
                if (isHighlight) {
                    $node.hilightElement();
                }
            });
        });
    };

    this.getDailyCounters = function () {
        var that = this,
            url = mixam.springUrl("admin/api/counters/daily/{start}/{end}".supplant({
                start: moment().utc().subtract('days', 29).startOf("day").toISOString(),
                end: moment().utc().endOf("day").toISOString(),
                period: "daily"
            })),
            promise;

        if (this.dailiesTimer) {
            clearTimeout(this.dailiesTimer);
        }
        this.dailiesTimer = setTimeout(function () {
            that.dailiesTimer = null;
            promise = $.getJSON(url);
            promise.done(function (response) {
                setTimeout($.proxy(that.initLast30DaysChart, that, that.normalize(response)), 10);
            });
        }, GET_LIST_DELAY);

    };
    this.getCounters = function () {
        var that = this,
            url = mixam.springUrl(`admin/api/counters/totals/${this.labelToTerm(this.label)}`),
            promise;

        /*
                start:  this.marshalDate(this.startDate.toDate()), //  toISOString()
                    end: this.marshalDate(this.label === "Custom" ?  this.endDate.add('days', 1).toDate() : this.endDate.toDate()),
        */

        if (this.totalsTimer) {
            clearTimeout(this.totalsTimer);
        }
        this.totalsTimer = setTimeout(function () {
            that.totalsTimer = null;
            promise = $.getJSON(url);
            that.select('dashboardSelector').addClass("loading");
            promise.done(function (response) {
                that.select('dashboardSelector').removeClass("loading");
                that.reset();
                that.trigger("dashbordReset");
                that.trigger("dashbordCounterUpdate", {list: response});
                setTimeout($.proxy(that.updateCounter, that, that.normalize(response)), 10);
            });
        }, GET_LIST_DELAY);
    };

    this.labelToTerm = function (label) {
        switch (label) {
            case 'Today': return "0";
            case 'Yesterday': return "1";
            case 'Last 7 Days': return "7";
            case 'Last 30 Days': return "30";
            case 'This Month': return "100";
            case 'Last Month': return "110";
            default: return `${this.marshalDate(this.startDate.toDate())}!${this.marshalDate(this.endDate.toDate())}`;
        }
    };

    this.marshalDate = function (d) {
        return `${d.getDate()}@${d.getMonth()}@${d.getFullYear()}`;
    };

    this.normalize = function (data) {
        var that = this,
            visits = 0,
            initCounter,
            detailsCounter,
            sum, cost, vat, delivery;

        if (data) {
            data.forEach(function (counter) {
                counter.value = that.calcNetValue(counter);
                if (counter.type === "DIRECT_VISITS" || counter.type === "PAID_VISITS" || counter.type === "ORGANIC_VISITS" || counter.type === "REFERRAL_VISITS") {
                    visits += counter.count;
                }
                if (counter.type === "INIT_ORDER") {
                    initCounter = counter;
                } else if (counter.type === "DETAILS") {
                    detailsCounter = counter;
                }
            });

            if (initCounter && detailsCounter) {
                cost = (initCounter.cost || 0) - (detailsCounter.cost || 0);
                sum = (initCounter.sum || 0) - (detailsCounter.sum || 0);
                vat = (initCounter.vat || 0) - (detailsCounter.vat || 0);
                delivery = (initCounter.delivery || 0) - (detailsCounter.delivery || 0);
                data.push({
                    cost: cost,
                    count: initCounter.count - detailsCounter.count,
                    delivery: delivery,
                    sum: sum,
                    type: "NO_DETAILS",
                    vat: vat,
                    weight: initCounter.weight - detailsCounter.weight,
                    value: sum - cost - vat - delivery
                });
            }

            data.push({
                cost: 0,
                count: visits,
                delivery: 0,
                sum: 0,
                type: "VISITS",
                vat: 0,
                weight: 0,
                value: 0
            });
        }

        return data || [];
    };

    this.updatePickerLabel = function () {
        this.select('dateRangeSpanSelector').html("<span class='dtp-label'><i class='fa fa-calendar-o'></i> {label}</span> <span class='dates'><span class='start'>{start}</span> - <span class='end'>{end}</span></span>".supplant({
            label: this.label,
            start: this.startDate.format('MMMM D, YYYY'),
            end: this.endDate.format('MMMM D, YYYY')
        }));
    };

    this.reset = function (types) {
        var resetCounterMap = types.map(function (type) {
            return {
                cost: 0,
                count: 0,
                delivery: 0,
                sum: 0,
                type: type,
                vat: 0,
                weight: 0,
                value: 0
            };
        });

        return function () {
            this.updateCounter(resetCounterMap);
        };
    }(counterTypes);

    this.calcNetValue = function (counter) {
        return (counter.sum || 0) - (counter.cost || 0) - (counter.delivery || 0) - (counter.vat || 0);
    };

    this.updateSingleCounter = function (data) {
        if (data.shopId !== mixam.shop.id) {
            return;
        }

        if (this.label === "Today") {
            this.trigger("dashbordCounterUpdate", data);
            if (data.cycle === "DAILY") {
                if (data.type === "PAYMENT") {

                    try {
                        this.snd.play();
                    } catch (e) {

                    }
                }
                this.updateCounter([
                    {
                        cost: data.cost || 0,
                        count: data.count || 0,
                        delivery: data.delivery || 0,
                        sum: data.sum || 0,
                        type: data.type,
                        vat: data.vat || 0,
                        weight: data.weight || 0,
                        value: this.calcNetValue(data)
                    }
                ], true);
            }
        }
    };

    /**
     * subscribe to STOMP service that notify us about changes
     * in a specific order and a specific item
     */
    this.subscribe = function () {
        this.trigger("log", {message: ["Subscribe to stomp channel santastats"], title: "AdminAnalytics.subscribe"});
        this.stomp = Stomp.client(mixam.stompServiceUrl);
        this.stomp.debug = (...a) => {
            if (a.join('').indexOf('Whoops! Lost connection to') !== -1) {
                setTimeout(() => this.subscribe(), 10);
            }
            this.trigger("log", {message: a, title: "santastats.subscribe"});
        };

        this.stomp.connect(mixam.stompWebUser, mixam.stompWebPass, () => {
            this.stomp.subscribe("/topic/shopanalyticsmessages",  (d) => {
                var p = JSON.parse(d.body);

                if (p && p.data) {
                    this.updateSingleCounter(p.data);
                }
                this.trigger("log", {message: p});
            });

            this.stomp.subscribe("/topic/santastats",  (d) => {
                var p = JSON.parse(d.body);
                this.trigger("santaStatsReady", p);
            });


        }, (err) => {
            this.trigger("log", {message: err});
        }, '/');

    };

    this.initDateRangePicker = function () {
        var that = this,
            cb = function (start, end, label) {
                that.startDate = start;
                that.endDate = end;
                that.label = label;
                that.getCounters();
                that.updatePickerLabel();
            },

            options = {
                startDate: this.startDate,
                endDate: this.endDate,
                minDate: '01/01/2014',
                maxDate: moment().utc(),
                dateLimit: {days: 365},
                showDropdowns: false,
                showWeekNumbers: false,
                timePicker: false,
                timePickerIncrement: 1,
                timePicker12Hour: true,
                ranges: {
                    'Today': [moment(), moment()],
                    'Yesterday': [moment().subtract('days', 1), moment().subtract('days', 1)],
                    'Last 7 Days': [moment().subtract('days', 6), moment()],
                    'Last 30 Days': [moment().subtract('days', 29), moment()],
                    'This Month': [moment().startOf('month'), moment().endOf('month')],
                    'Last Month': [moment().subtract('month', 1).startOf('month'), moment().subtract('month', 1).endOf('month')]
                },
                opens: 'left',
                buttonClasses: ['btn btn-default'],
                applyClass: 'btn-small btn-product-2',
                cancelClass: 'btn-small',
                format: 'DD/MM/YYYY',
                separator: ' to ',
                locale: {
                    applyLabel: 'Submit',
                    cancelLabel: 'Clear',
                    fromLabel: 'From',
                    toLabel: 'To',
                    customRangeLabel: 'Custom',
                    daysOfWeek: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
                    monthNames: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
                    firstDay: 1
                }
            };

        this.updatePickerLabel();
        this.select('dateRangeSelector').daterangepicker(options, cb);
    };

    this.after('initialize', function () {
        AnalyticsZSummary.attachTo(this.select('zSummarySelector'));
        AnalyticsPayment.attachTo(this.select('paymentSelector'));
        AnalyticsProducts.attachTo(this.select('productsSelector'));
        AnalyticsAccountManagers.attachTo(this.select('accountManagersSelector'));
        AnalyticsMachines.attachTo(this.select('machineSelector'));
        AnalyticsFulfilment.attachTo(this.select('fulfilmentSelector'));
        AnalyticsMethods.attachTo(this.select('methodsSelector'));
        AnalyticsUploads.attachTo(this.select('uploadsSelector'));
        AnalyticsShare.attachTo(this.select('shareSelector'));
        AnalyticsShareViews.attachTo(this.select('shareViewsSelector'));


        try {
            this.snd = new Audio("https://s3-eu-west-1.amazonaws.com/static3.mixam.com/shop/sound/coin-drop-4.wav");
        } catch (e) {

        }

        this.startDate = moment().startOf("day");
        this.endDate = moment().endOf("day");
        this.label = "Today";
        this.initDateRangePicker();
        setTimeout($.proxy(this.getDailyCounters, this), 10);
        setTimeout($.proxy(this.getCounters, this), 10);
        this.on("click", {
            legendSelector: this.onLegendClick
        });
        this.on("change", {
            changeCounterSelector: this.onCounterSelectorChange
        });
        try {
            this.subscribe();
        } catch (e) {

        }

    });

}