import defineComponent from '../../../components/flight/lib/component';

export default defineComponent(AdminReload);

function AdminReload() {

    this.attributes({
        saveBtnSelector: '[type="submit"]'
    });

    this.handleSubmit = function (event) {
        if (!event.isDefaultPrevented()) {
            $(event.target).find(this.attr.saveBtnSelector).attr('data-loading', '*').attr('disabled', true);
        }
    };

    this.after('initialize', function () {
        this.on("submit", this.handleSubmit);
    });
}
