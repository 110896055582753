export default {
    1: "Booklets",
    2: "Leaflets",
    3: "Folded",
    4: "Posters",
    5: "Letterheads",
    6: "Photobook",
    7: "Book",
    8: "Business card",
    9: "Postcard",
    10: "Greeting card",
    11: "Notebook",
    12: "Compliment Slip",
    13: "Envelopes",
    14: "Folder",
    15: "Layflat",
    16: "WallCalendar",
    17: "DeskCalendar",
    18: "WallCalendar",
    19: "DeskCalendar",
    20: "Traditional Books",
    21: "Canvases"
};
