export default [
    {
        id: 0,
        value: "Customer"
    },
    {
        id: 1,
        value: "Supplier"
    },
    {
        id: 2,
        value: "Carrier"
    },
    {
        id: 3,
        value: "Mixam"
    }
];