import Chartjs from '../../../../../components/Chart.js';
import regional from '../../../../boot/regional';
import WithChartColors from "./with-chart-colors";
import WithChartData from "./with-chart-data";

import defineComponent from '../../../../../components/flight/lib/component';

export default defineComponent(YearViewBar, WithChartColors, WithChartData);

function YearViewBar() {

    this.attributes({
        chartSelector: '.chart',
        legendSelector: '.legend',
    });

    this.drawChart = function () {
        const barChartData = {
            labels: regional().monthLongNames,
            datasets: this.data2ChartData(this.data)
        };
        const ctx = this.select('chartSelector').get(0).getContext("2d");

        this.select('legendSelector').html(this.createLegend(barChartData));
        // noinspection JSValidateTypes
        this.chart = new Chartjs(ctx).Bar(barChartData, {
            responsive: true,
            title: {
                display: true,
                text: 'Year View'
            }
        });
    };

    this.createLegend = function (barChartData) {
        const a = barChartData.datasets.map(d => `<li style="background: ${d.fillColor};border: 1px solid ${d.strokeColor}">${d.label}</li>`);
        return `<ul>${a.join('')}</ul>`;
    };

    this.setData = function (event, data) {
        this.data = data.response;
        this.drawChart();
    };

    this.after('initialize', function () {
        this.on(document, "uiDataReady", this.setData);
    });
}
