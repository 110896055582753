/**
 Attach a countdown gizmo until cutOff hour
 data-type="date-count-down"
 */
import defineComponent from '../../../components/flight/lib/component';
import regional from '../../boot/regional';

export default defineComponent(DateCountDown);

function DateCountDown() {


    this.attributes({
        interval: 1000 * 60 * 2,
        countDownHourSelector: '[data-type="count-down-hour"]',
        countDownLeftSelector: '[data-type="count-down-left"]'
    });

    this.paint = function () {
        if (this.timer) {
            clearTimeout(this.timer);
            this.timer = null;
        }

        if (this.visible) {
            const cutoffDate = new Date(this.cutOff);
            const cutoffHour = cutoffDate.getHours();
            const diffInMinutes = Math.round((cutoffDate.getTime() - Date.now()) / 1000 / 60);
            if (diffInMinutes > 0 && diffInMinutes <= 240) {
                const left = this.getLeft(diffInMinutes);
                this.select('countDownHourSelector').text(cutoffHour);
                this.select('countDownLeftSelector').text(left);
                if (this.$node.hasClass('hidden')) {
                    this.$node.removeClass('hidden');
                }
            } else {
                this.$node.addClass('hidden');
            }
            this.timer = setTimeout(() => this.paint(), this.attr.interval);
        } else {
            this.$node.addClass('hidden');
        }
    };

    this.getLeft = function (diffInMinutes) {
        if (diffInMinutes < 60) {
            return regional().santa.order.countDownMinutesLeft.supplant({minutes: diffInMinutes});
        }
        return regional().santa.order.countDownHoursLeft.supplant({hours: Math.round(diffInMinutes / 60)});
    };

    this.setOrder = function (event, order) {
        this.visible = order.orderStatusInt < 10;
        this.paint();
    };

    this.after('initialize', function () {
        this.cutOff = this.$node.data('cutoff');
        this.on(document, "setOrderData", this.setOrder);
        this.paint();
    });
}
