import defineComponent from '../../../components/flight/lib/component';
import WithSubmitAnimation from './../with-submit-zoom-animation';

export default defineComponent(FileRename, WithSubmitAnimation);

function FileRename() {

    this.attributes({
        renameBtnSelector: ".btn-rename",
        stateCloseSelector: ".state-close",
        renameFormSelector: '[data-type="rename-form"]',
        cancelImportBtnSelector: '[data-type="cancel-rename"]',
        saveBtnSelector: '[data-type="save-rename"]',
        inputSelector: '[data-type="file-name"]'

    });

    this.open = function() {
        this.isOpen = true;
        this.select("stateCloseSelector").addClass("hidden");
        setTimeout(() => this.select("inputSelector").focus(), 100);
    };

    this.cancelRename = function(event) {
        this.close();
    };

    this.close = function() {
        this.isOpen = false;
        this.select("stateCloseSelector").removeClass("hidden");
        this.select("renameFormSelector").collapse('hide');
    };

    this.submitRename = function(event) {
        var $input,
            originalName,
            newName,
            fileId;

        if (!event.isDefaultPrevented()) {
                $input = this.select('inputSelector');
                originalName = $input.data('original');
                newName = $input.val();

            if (newName !== originalName) {
                this.handleSubmit(event);
                fileId = this.$node.closest('[data-file-id]').data('fileId');
                this.trigger("uiFileRenamed", {
                    fileId: fileId,
                    originalName: originalName,
                    newName: newName
                });
            }
        }
        event.preventDefault();
        event.stopPropagation();
    };


    this.after('initialize', function () {

        this.on("submit", {
            renameFormSelector: this.submitRename
        });

        this.on("click", {
            cancelImportBtnSelector: this.cancelRename,
            renameBtnSelector: this.open
        });
    });
}
