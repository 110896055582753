import Chartjs from '../../../../../components/Chart.js';
import regional from '../../../../boot/regional';
import WithChartColors from "./with-chart-colors";
import WithChartData from "./with-chart-data";

import defineComponent from '../../../../../components/flight/lib/component';

export default defineComponent(YearViewBar, WithChartColors, WithChartData);

// noinspection DuplicatedCode
function YearViewBar() {

    this.attributes({
        chartSelector: '.chart',
        legendSelector: '.legend',
    });

    this.drawChart = function () {
        const barChartData = {
            labels: regional().monthLongNames,
            datasets: this.getChartData()
        };
        const ctx = this.select('chartSelector').get(0).getContext("2d");

        this.select('legendSelector').html(this.createLegend(barChartData));
        // noinspection JSValidateTypes
        this.chart = new Chartjs(ctx).Bar(barChartData, {
            responsive: true,
            title: {
                display: true,
                text: 'Year View'
            }
        });
    };

    this.getChartData = function () {
        const datum = this.data2ChartData(this.data);
        const newData = datum.map(y => $.extend(true, {}, y));
        const currentMonth = (new Date()).getMonth();
        for (let year = 1; year < datum.length; year++) {
            for (let month = 0; month < 12; month++) {
                const prevValue = datum[year - 1].data[month];
                const currentValue = datum[year].data[month];
                let growth = 0;
                if (prevValue && currentValue) {
                    growth = Math.round((currentValue - prevValue) / prevValue * 100);

                    if (year === datum.length - 1 && month === currentMonth) {
                       growth =  Math.max(0, growth);
                    }

                }
                newData[year].data[month] = growth;
            }
        }
        newData.shift();
        if (newData.length > 1) {
            newData.shift();
        }
        //console.log(newData);
        return newData;
    };

    this.createLegend = function (barChartData) {
        const a = barChartData.datasets.map(d => `<li style="background: ${d.fillColor};border: 1px solid ${d.strokeColor}">${d.label}</li>`);
        return `<ul>${a.join('')}</ul>`;
    };

    this.setData = function (event, data) {
        this.data = data.response;
        this.drawChart();
    };


    this.after('initialize', function () {
        this.on(document, "uiDataReady", this.setData);
    });
}
