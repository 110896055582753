var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { BootstrapConfig } from "@react-awesome-query-builder/bootstrap";
import AwesomeQueryBuilderHelper from "../../AwesomeQueryBuilder/AwesomeQueryBuilderHelper";
var InitialConfig = BootstrapConfig;
var DiscountRuleConfig = {
    getOrderConfig: function (configData) {
        var config = __assign(__assign({}, InitialConfig), { fields: {
                order: {
                    label: "Order",
                    type: "!struct",
                    subfields: {
                        status: {
                            label: 'Order Status',
                            type: 'select',
                            valueSources: ["value"],
                            fieldSettings: {
                                listValues: [
                                    { value: 'order', title: 'Order' },
                                    { value: 'quote', title: 'Quote' },
                                    { value: 'publication', title: 'Publication' },
                                    { value: 'print-on-demand', title: 'Print On Demand' },
                                ],
                            },
                        },
                        total: {
                            label: 'Order Total',
                            type: 'number'
                        },
                        items: {
                            label: "Items",
                            type: "!group",
                            mode: "array",
                            subfields: {
                                total: {
                                    label: "Item Total",
                                    type: 'number'
                                },
                                query: {
                                    label: "Query",
                                    type: "!struct",
                                    subfields: {
                                        productId: {
                                            label: "Product ID",
                                            type: 'number',
                                        },
                                        subProductId: {
                                            label: "Sub Product ID",
                                            type: 'number',
                                        },
                                        designOption: {
                                            label: "Design Option",
                                            type: "select",
                                            valueSources: ["value"],
                                            fieldSettings: {
                                                listValues: AwesomeQueryBuilderHelper.getConfigEnumOption(configData, 'designOptions')
                                            },
                                            widgets: {
                                                select: {
                                                    widgetProps: {
                                                        spelFormatValue: function (val) {
                                                            return AwesomeQueryBuilderHelper.getEnumSpelFormat('com.mixam.shop.models.DesignOption', val);
                                                        }
                                                    }
                                                },
                                                multiselect: {
                                                    widgetProps: {
                                                        spelFormatValue: function (val) {
                                                            return AwesomeQueryBuilderHelper.getMultiSelectEnumSpelFormat('com.mixam.shop.models.DesignOption', val);
                                                        }
                                                    }
                                                }
                                            }
                                        },
                                        itemSpecification: AwesomeQueryBuilderHelper.getItemSpecificationConfig(configData)
                                    }
                                }
                            },
                        }
                    },
                },
            } });
        return config;
    },
    getOrderItemConfig: function (configData) {
        var config = __assign(__assign({}, InitialConfig), { fields: {
                item: {
                    label: "Item",
                    type: "!struct",
                    subfields: {
                        total: {
                            label: "Item Total",
                            type: 'number'
                        },
                        query: {
                            label: "Query",
                            type: "!struct",
                            subfields: {
                                productId: {
                                    label: "Product ID",
                                    type: 'number',
                                },
                                subProductId: {
                                    label: "Sub Product ID",
                                    type: 'number',
                                },
                                designOption: {
                                    label: "Design Option",
                                    type: "select",
                                    valueSources: ["value"],
                                    fieldSettings: {
                                        listValues: AwesomeQueryBuilderHelper.getConfigEnumOption(configData, 'designOptions')
                                    },
                                    widgets: {
                                        select: {
                                            widgetProps: {
                                                spelFormatValue: function (val) {
                                                    return AwesomeQueryBuilderHelper.getEnumSpelFormat('com.mixam.shop.models.DesignOption', val);
                                                }
                                            }
                                        },
                                        multiselect: {
                                            widgetProps: {
                                                spelFormatValue: function (val) {
                                                    return AwesomeQueryBuilderHelper.getMultiSelectEnumSpelFormat('com.mixam.shop.models.DesignOption', val);
                                                }
                                            }
                                        }
                                    }
                                },
                                itemSpecification: AwesomeQueryBuilderHelper.getItemSpecificationConfig(configData)
                            }
                        }
                    },
                }
            } });
        return config;
    }
};
export default DiscountRuleConfig;
