import mixam from '../../boot/mixam';
import defineComponent from '../../../components/flight/lib/component';
import regional from '../../boot/regional';

export default defineComponent(GoogleSheetApi);

function GoogleSheetApi() {
    const CLIENT_ID2 = "739109048270-rut068k7bdvli08jjp3aa3caho6pjr94.apps.googleusercontent.com";
    const CLIENT_ID = "666944801885-liuld9ktmdbt8tusge87e4vb7m64albq.apps.googleusercontent.com";
    const SCOPES = ["https://www.googleapis.com/auth/spreadsheets"];

    this.attributes({
        reportName: "Unknown report",
        shopUrl: "Unknown url",
        authorizeDivSelector: "#authorize-div",
        authorizeBtnSelector: '[data-type="authorize-button"]',
        exportBtnSelector: '[data-type="export-button"]'
    });


    this.loadSheetsApi = function () {
        var discoveryUrl =
            'https://sheets.googleapis.com/$discovery/rest?version=v4';
        gapi.client.load(discoveryUrl).then(() => this.enableExportData());
    };

    this.enableExportData = function () {
        this.select('exportBtnSelector').prop('disabled', false);
    };

    this.exportData = function () {
        this.select('exportBtnSelector').attr('data-loading', '*').attr('disabled', true);
        const now = new Date();
        const data = {'properties': {'title': `${this.attr.reportName} (${mixam.user.name}) ${now.format('d/m/yy h:MM TT')}`}};
        gapi.client.sheets.spreadsheets.create(data).
            then((a, b, c) => {
                this.sheet = a.result;
                //console.log(this.sheet);
                this.update();
            });
    };

    this.update = function () {
        const fields = this.schema.map((col) => ({stringValue: col.title}));
        const data = this.data.map(o => this.schema.map(col => {
            const val = o[col.data];
            try {
                switch (col.type) {
                    case "number":
                        return {numberValue: (col.decimal ? Math.round(val * 100) / 100 : val)};
                    case "text":
                        return val === 'undefined' || val === null ? {stringValue: ''} : {stringValue: val};
                    case "command":
                        return val && {stringValue: val};
                    case "creditcommand":
                        return val && {stringValue: val};
                    case "dispatch":
                        return val && {stringValue: val.text};
                    case "user":
                        return val && {stringValue: val.name};
                    case "remarks":
                        return val && {numberValue: val.count};
                    case "icon":
                        return val && {stringValue: (val.caption || val.icon)};
                    case "boolean":
                        return typeof val === 'undefined' ? null : {boolValue: val};
                    case "roles":
                        return val && {stringValue: val.toString};
                    case "link":
                        return val && {formulaValue: `=HYPERLINK("${this.attr.shopUrl}/${val.href}","${val.caption}")`};
                    case "timebox":
                    case "datetime":
                        return val && {stringValue: val.text};
                    case "date":
                        return val && {stringValue: val.text};
                    case "time":
                        return val && {stringValue: val.text};
                }
            } catch (ex) {
               // console.log(ex, col.data, o[col.data]);
            }
            return null;
        }));
        data.unshift(fields);
        const PAGE_SIZE = 1000;
        const updates = Math.ceil(data.length / PAGE_SIZE);

        const upateChunk = (count, spreadsheetId) => {
            const chunk = [];
            const len = (count + 1) * PAGE_SIZE;
            for (let i = count * PAGE_SIZE; i < len; i++) {
                if (!data[i]) {
                    break;
                }
                const row = {};
                row.values = data[i].map(cell => ({
                    userEnteredValue: cell
                }));
                chunk.push(row);
            }

            //console.log("chunk", chunk);
            const f = gapi.client.sheets.spreadsheets.batchUpdate({
                    spreadsheetId: spreadsheetId
                },
                {
                    requests: [
                        {
                            appendCells: {
                                sheetId: this.sheet.sheets[0].properties.sheetId,
                                rows: chunk,
                                fields: "*"
                            }
                        }
                    ]
                }).
                then((a, b, c) => {
                   // console.log(a, b, c);
                    if (count < updates - 1) {
                        upateChunk(count + 1, spreadsheetId);
                    } else {
                        gapi.client.sheets.spreadsheets.batchUpdate({
                                spreadsheetId: spreadsheetId
                            },
                            {
                                requests: [
                                    {
                                        "autoResizeDimensions": {
                                            "dimensions": {
                                                sheetId: this.sheet.sheets[0].properties.sheetId,
                                                "dimension": "COLUMNS",
                                                "startIndex": 0,
                                                "endIndex": this.schema.length
                                            }
                                        }
                                    },
                                    {
                                        "repeatCell": {
                                            "range": {
                                                "sheetId": this.sheet.sheets[0].properties.sheetId,
                                                "startRowIndex": 0,
                                                "endRowIndex": 1
                                            },
                                            "cell": {
                                                "userEnteredFormat": {
                                                    "backgroundColor": {
                                                        "red": 122/255,
                                                        "green": 131/255,
                                                        "blue": 154/255
                                                    },
                                                    "horizontalAlignment" : "CENTER",
                                                    "textFormat": {
                                                        "foregroundColor": {
                                                            "red": 1.0,
                                                            "green": 1.0,
                                                            "blue": 1.0
                                                        },
                                                        "fontSize": 12,
                                                        "bold": true
                                                    }
                                                }
                                            },
                                            "fields": "userEnteredFormat(backgroundColor,textFormat,horizontalAlignment)"
                                        }
                                    },
                                    {
                                        "updateSheetProperties": {
                                            "properties": {
                                                "sheetId": this.sheet.sheets[0].properties.sheetId,
                                                "gridProperties": {
                                                    "frozenRowCount": 1
                                                }
                                            },
                                            "fields": "gridProperties.frozenRowCount"
                                        }
                                    }
                                ]
                            }).then((a, b, c) => {
                                this.trigger('showFixedMessage', {message: regional().messages[1039]});
                                this.select('exportBtnSelector').removeAttr('data-loading').attr('disabled', false);
                            });
                    }
                });
        };

        upateChunk(0, this.sheet.spreadsheetId);
    };

    this.handleAuthResult = function (authResult) {
        if (authResult && !authResult.error) {
            this.select('authorizeBtnSelector').hide();
            this.select('exportBtnSelector').show();
            this.loadSheetsApi();
        } else {
            this.select('authorizeBtnSelector').show();
            this.select('exportBtnSelector').hide();
        }
    };

    this.handleAuthClick = function () {
        gapi.auth.authorize(
            {client_id: CLIENT_ID, scope: SCOPES, immediate: false},
            (authResult) => this.handleAuthResult(authResult));
        return false;
    };

    this.checkAuth = function () {
        gapi.auth.authorize({
            'client_id': CLIENT_ID,
            'scope': SCOPES.join(' '),
            'immediate': true
        }, (authResult) => this.handleAuthResult(authResult));
    };

    this.addSchema = function (event, data) {
        if (this.schema) {
            return;
        }
        this.schema = data.columns.filter((col) => col.type !== "thumbnail" && col.type !== "command" && col.type !== "artwork" && col.type !== "lightbox");
    };

    this.setData = function (event, data) {
        this.data = data.list;
        //console.log('schema:', this.schema);
        //console.log('data:', this.data);
    };

    this.after('initialize', function () {
        const reportName = this.$node.data('sheetName');
        const shopUrl = this.$node.data('shopUrl');
        this.attr.shopUrl = shopUrl || this.attr.shopUrl;
        this.attr.reportName = reportName || this.attr.reportName;
        this.on(document, "uiBeforeTableRender", this.addSchema);
        this.on(document, "uiSetData", this.setData);
        //require(["https://apis.google.com/js/client.js"], () => this.checkAuth);
        this.on('click', {
            authorizeBtnSelector: this.handleAuthClick,
            exportBtnSelector: this.exportData
        });
        setTimeout(() => this.checkAuth(), 500);
    });
}
