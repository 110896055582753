import mixam from '../boot/mixam';
import defineComponent from '../../components/flight/lib/component';
import Mustache from '../../components/mustache/mustache';
import messageTemplate from 'text!../../appes6/templates/fixed-message/message.mustache';

export default defineComponent(FixedMessage);

function FixedMessage() {
    var displayTimer;

    this.attributes({
        bodySelector: '[data-type=message]',
        containerSelector: '.container',
        closeBtnSelector: '.close-btn'
    });

    this.renderMessage = function (message) {
        return Mustache.render(messageTemplate, message);
    };


    // normal,info, error, failure, success, warning
    this.show = function (event, message, callback) {
        var that = this;

        if (event) {
            event.preventDefault();
        }

        // Limit Messages To The Last 2
        let existingMessages = this.$node.children();
        if(existingMessages.length > 1) {
            existingMessages.splice(existingMessages.length - 1, 1);
            existingMessages.remove();
        }

        // Unpack the event if it didn't come natively from jQuery...
        if (!message && event.originalEvent) {
            message = event.originalEvent.detail;
        }

        if (message.message) {
            message.type = message.message.type;
            message.body = message.message.body;
            message.interval = message.message.interval;
            delete message.message;
        }

        //normal warning error success
        message.type = message.type || "normal";
        message[message.type] = true;

        $(this.renderMessage(message)).appendTo(this.$node);
        this.$node.addClass("active");

        if (displayTimer) {
            clearTimeout(displayTimer);
            displayTimer = null;
        }
        if (message.interval) {
            displayTimer = setTimeout(function () {
                that.hide();
                if(callback) {
                    callback();
                }
            }, message.interval);
        }
    };

    this.hide = function (event) {
        if (event) {
            event.preventDefault();
        }
        this.$node.removeClass('active');
    };

    this.afterHide = function (event) {
        if (!$(event.target).hasClass("active")) {
            this.$node.children().remove();
        }
    };

    this.ShowQueuedMessages = function () {
        window.textMessageQue = window.textMessageQue || [];

        while (textMessageQue[0]) {
            this.show(null, textMessageQue.shift());
        }
    };

    this.after('initialize', function () {
        this.on(document, 'showFixedMessage', this.show);
        window.addEventListener("showFixedMessage", (e) => this.show(e, e.detail.message));
        this.on(document, 'hideFixedMessage', this.hide);
        if (mixam.animationEventNames && mixam.animationEventNames.length) {
            this.on(mixam.animationEventNames[2], this.afterHide);
        }
        this.on('click', {
            'closeBtnSelector': this.hide
        });

        this.ShowQueuedMessages();
    });

}