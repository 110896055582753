import defineComponent from '../../../components/flight/lib/component';
import DataTable from './../table/data-table-net/data-table-net.tsx';
import {Breakpoints} from "../table/data-table-net/StyledDataTableNet.tsx";
import WithOrdersOrders from './with-normalized-order';
import WithOrdersItemsMembers from './with-orders-items-members';
import mixam from "../../boot/mixam";

export default defineComponent(OrderLocator, WithOrdersItemsMembers, WithOrdersOrders );

function OrderLocator() {


    this.attributes({
        url: '/admin/api/orders/locate',
        caseNumberInputSelector: '[data-type="case-number-search-input"]',
        nameInputSelector: '[data-type="name-search-input"]',
        emailAddressInputSelector: '[data-type="email-address-search-input"]',
        popoverSelector: '[data-toggle="popover"]',
        dataTableSelector: '[data-type="data-table"]',
        searchBtnSelector: '[data-type="search-btn"]'
    });

    this.createSchema = function () {
        const cols = [];

        cols.push({
            title: "",
            type: "thumbnail",
            data: "front"
        });
        cols.push({
            title: "Order",
            type: "link",
            data: "href"
        });
        cols.push({
            title: "Status",
            type: "badge",
            data: "statusBadge",
        });
        cols.push({
            title: "Username",
            type: "user",
            data: "user"
        });
        cols.push({
            title: "Created",
            type: "timebox",
            data: "time",

        });
        cols.push({
            title: "Modified",
            type: "timebox",
            data: "lastModifiedDate",
            hide: Breakpoints.XL
        });
        cols.push({
            title: "Confirmed",
            type: "timebox",
            data: "confirmDate",
            hide: Breakpoints.XL
        });

        cols.push({
            title: "Artwork",
            type: "boolean",
            data: "artwork",
            "sortable": false
        });
        cols.push({
            title: "Settled",
            type: "boolean",
            data: "settled"
        });
        cols.push({
            title: "Remarks",
            type: "remarks",
            data: "remarkData"
        });
        cols.push({
            title: "Sum",
            type: "number",
            data: "total",
            sum: true,
            currency: true,
            decimal: 2,
            prepend: mixam.shop.currency.prefix,
        });

        return cols;
    };

    this.clock = function () {
        return true;
    };

    this.paint = function () {
        const data = {
            columns: this.createSchema(),
            fileName: "Search",
            response: this.normalize(this.data),
            class: 'table-striped table-order-list'
        };

        this.select('dataTableSelector').show();

        if (this.dataReadyFired) {
            window.dispatchEvent(new CustomEvent('uiDataUpdate', { detail: data }));
        } else {
            this.dataReadyFired = true;
            this.trigger("uiDataReady", data);
        }
    };

    this.doSearch = function (event) {
        const caseNumber = (this.select('caseNumberInputSelector').val() || "").trim();
        const name = (this.select('nameInputSelector').val() || "").trim();
        const emailAddress = (this.select('emailAddressInputSelector').val() || "").trim();
        if (caseNumber || name || emailAddress) {
            this.select('searchBtnSelector').attr('data-loading', '*').attr('disabled', true);
            $.post(this.attr.url, {caseNumber, name, emailAddress}, null, 'json').done((response) => {
                this.select('searchBtnSelector').removeAttr('data-loading').attr('disabled', false);
                this.handleResponse(response);
            }).fail(response => {
                this.select('searchBtnSelector').removeAttr('data-loading').attr('disabled', false);
                this.trigger("log", response);
            });
        }
    };

    this.handleResponse = function (list) {
        this.data = list;
        if (list.length) {
            this.paint();
        }
    };

    this.doKeyUp = function (event) {
        if (event.keyCode === 13) {
            this.doSearch(event);
        }
    };

    this.after('initialize', function () {
        DataTable.attachTo(this.select('dataTableSelector'));

        this.on('click', {
            searchBtnSelector: this.doSearch
        });
        this.on('keyup', this.doKeyUp);
    });
}
