/**
 * This is form validation effect which is used by the mixam onlinr.
 * It is responsible for "shaking" the invalid inputs on validation errors.
 * Requires jquerytools's validator plugin.
 * @author Effie Nadiv
 * June 11, 2012
 */

(function($) {
    /**
     * Check if the date is in the required format and whether it is a real date (e.g. not 06/45/2012)
     */
    var
        /**
         * Get the name of a given field from its corresponding label's inner text (from which we remove unwanted characters such as ":" and "*").
         * If corresponding label is not found, it returns the value from the field's "name" attribute.
         */
        getFieldLabel = function(group) {
            var result =  group.find('label');
            return result.length ? result.text() : '';
        };


    (function() {

        var errCount = $('#serverErrorCount'),

            reRemoveComma = new RegExp(',', 'g'),
            hasNonDigit = new RegExp('[^0-9\.,-]'),

            toNumberValue = function(text) {
                return text && !hasNonDigit.test(text) ? parseFloat(text.replace(reRemoveComma, '')) : undefined;
            },

            reset = function(inputs) {
                inputs.each(function() {
                    var $this = $(this),
                        msg = $this.data("msg.el"),
                        controls = $this.closest(".form-group");


                    if (msg) {
                        msg.remove();
                        $this.data("msg.el", null);
                    }
                    controls.removeClass("has-error");
                });
            };

        // When we have evidence of a server error ... display a message
        if (errCount.length && errCount.val()) {
            // $().fixedMessage(PCONSOLE.regional.validation.errors, 'error');
        }

        $.tools.validator.addEffect("mixam", function(errors, event) {

            // get form
            var form = $(this.getForm()),
                conf = this.getConf();

            //reset(this.getInputs());

            //form.find(".validation-error:not(.server-error)").remove();
            //form.find(".control-group").removeClass("error");

            // add errors
            $.each(errors, function(index, error) {
                var input = error.input,
                    errElem = input.data("msg.el"),
                    controls = input.closest(".form-group"),
                    controlsGroup = controls, //controls.closest(".form-group"),
                    label = getFieldLabel(controlsGroup).replace(/(:|\* )/g, '');

                // $().fixedMessage(PCONSOLE.regional.validation.errors, 'error');

                //add the error class
                controlsGroup.addClass("has-error");

                if (index === 0 && !input.isVisible()) {
                    input.scrollToView();
                    if (!input.hasClass('date-picker')) {
                        input.eq(0).focus();
                    }
                }

                var msg = "<div class='" + conf.errorClass + "'>";
                //add error messages
                $.each(error.messages, function(errIndex, errMessage) {
                    msg +=  ("<p>" + errMessage + "</p>").supplant({
                        "field-name": input.attr("name"),
                        "field-value": input.val(),
                        "field-label": label
                    });
                });
                msg += "</div>";

                if (errElem) {
                    errElem.remove();
                }
                errElem = $(msg);
                input.closest(".control-size-frame").append(errElem);
                input.data("msg.el", errElem);
            });

        }, function(inputs)  {

            var conf = this.getConf();

            reset(inputs);

            inputs.removeClass(conf.errorClass).each(function() {
                var $this = $(this),
                    $msg = $this.data("msg.el"),
                    controls = $this.closest(".form-group");


                if ($msg) {
                    $msg.remove();
                    $this.data("msg.el", null);
                }
                controls.removeClass("has-error");

            });
        });
    }());


    //validator for input fields - ensures that the value length is not larger than specified
    $.tools.validator.fn("[data-min-length]", function(input, value) {
        var minLength = input.data("min-length");

        if(input.attr("disabled") || input.attr("readonly") || !value || isNaN(minLength)) {
            return true;
        }

        return value.length >= minLength ? true : [minLength];
    });

    $.tools.validator.fn("[data-must-match]", function(input, value) {
        var secondValue = $(input.data("must-match")).val();

        if(input.attr("disabled") || input.attr("readonly") || !value || !secondValue) {
            return true;
        }

        return value === secondValue;
    });

})(jQuery);

