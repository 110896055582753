var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React, { useEffect, useState } from "react";
import { Modal, Button, Image } from 'react-bootstrap';
import styled from 'styled-components';
import i18nShop from "../../../../assets/i18n/i18n";
import { I18nextProvider } from 'react-i18next';
import mixam from '../../../boot/mixam.js';
import axios from 'axios';
var StyledModal = styled(Modal)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    opacity: 1; // overwriting some conflicting modal styles\n    .modal-content {\n        margin-top: 10rem;\n    }\n    label {\n        font-weight: bold;\n    }\n"], ["\n    opacity: 1; // overwriting some conflicting modal styles\n    .modal-content {\n        margin-top: 10rem;\n    }\n    label {\n        font-weight: bold;\n    }\n"])));
export var OptionWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  margin-top: 1rem;\n  display: flex;\n"], ["\n  margin-top: 1rem;\n  display: flex;\n"])));
export var ImageButtonGroup = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  flex-wrap: wrap;\n"], ["\n  display: flex;\n  flex-wrap: wrap;\n"])));
export var ImageOption = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  width: 140px;\n  box-shadow: none !important;\n  border-radius: 0.5rem !important;\n  color: var(--bs-secondary);\n  cursor: pointer;\n  &.active {\n    border-color: #6BD3CC;\n    border-width: 3px;\n    border-style: solid;\n  }\n"], ["\n  width: 140px;\n  box-shadow: none !important;\n  border-radius: 0.5rem !important;\n  color: var(--bs-secondary);\n  cursor: pointer;\n  &.active {\n    border-color: #6BD3CC;\n    border-width: 3px;\n    border-style: solid;\n  }\n"])));
export var ImageButtonContainer = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  margin: 4px;\n  display: flex;\n  flex-direction: column;\n  width: fit-content;\n  align-items: center;\n  width: 150px;\n  word-break: break-word;\n"], ["\n  margin: 4px;\n  display: flex;\n  flex-direction: column;\n  width: fit-content;\n  align-items: center;\n  width: 150px;\n  word-break: break-word;\n"])));
export var StyledImage = styled(Image)(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  border-radius: 0.3rem !important;\n"], ["\n  border-radius: 0.3rem !important;\n"])));
export var PRINTESS_TEMPLATE_TYPE;
(function (PRINTESS_TEMPLATE_TYPE) {
    PRINTESS_TEMPLATE_TYPE["ENTIRE_DOCUMENT"] = "Entire Document";
    PRINTESS_TEMPLATE_TYPE["COVER"] = "Covers Only";
    PRINTESS_TEMPLATE_TYPE["BODY"] = "Inside Pages Only";
    PRINTESS_TEMPLATE_TYPE["SPINE"] = "Spine";
})(PRINTESS_TEMPLATE_TYPE || (PRINTESS_TEMPLATE_TYPE = {}));
export var PrintessTemplateOptionsModal = function (_a) {
    var orderId = _a.orderId, itemId = _a.itemId, onCancelBtnClick = _a.onCancelBtnClick, onSelectTemplate = _a.onSelectTemplate;
    var _b = useState(true), show = _b[0], setShow = _b[1];
    var _c = useState([]), allowedTemplateTypes = _c[0], setAllowedTemplateTypes = _c[1];
    var _d = useState(false), isInitialized = _d[0], setInitialized = _d[1];
    var _e = useState(PRINTESS_TEMPLATE_TYPE.ENTIRE_DOCUMENT), templateType = _e[0], setTemplateType = _e[1];
    var handleSelectTemplate = function (templateType) {
        setTemplateType(templateType);
    };
    useEffect(function () {
        axios.get("/api/printess/orders/".concat(orderId, "/items/").concat(itemId, "/template-types"))
            .then(function (response) {
            var mappedTypes = response.data.map(function (type) { return PRINTESS_TEMPLATE_TYPE[type]; }).filter(Boolean);
            setAllowedTemplateTypes(mappedTypes);
            if (mappedTypes.length > 0) {
                setTemplateType(mappedTypes[0]);
            }
            if (mappedTypes.length == 1) {
                setTemplateType(mappedTypes[0]);
                onSelectTemplate(mappedTypes[0]);
                setShow(false);
            }
            else {
                setInitialized(true);
            }
        });
    }, []);
    return (React.createElement(React.Fragment, null, isInitialized && (React.createElement(I18nextProvider, { i18n: i18nShop },
        React.createElement(StyledModal, { show: show, size: 'lg', scrollable: true, centered: true },
            React.createElement(Modal.Header, null,
                React.createElement(Modal.Title, null, i18nShop.t('printessTemplateOptions.modal.title'))),
            React.createElement(Modal.Body, null,
                React.createElement(React.Fragment, null,
                    i18nShop.t('printessTemplateOptions.modal.body'),
                    React.createElement(OptionWrapper, null,
                        React.createElement(ImageButtonGroup, null, Object.entries(PRINTESS_TEMPLATE_TYPE).map(function (_a) {
                            var key = _a[0], value = _a[1];
                            if (!allowedTemplateTypes.includes(value)) {
                                return null;
                            }
                            return (React.createElement(ImageButtonContainer, { key: key },
                                React.createElement(ImageOption, { className: templateType === value ? 'active' : '' },
                                    React.createElement(StyledImage, { src: "".concat(mixam.cdn, "/coloratura/images/printess-design-templates/").concat(key, ".png"), onClick: function () { return handleSelectTemplate(value); }, width: '100%' })),
                                i18nShop.t("printessTemplateOptions.modal.options.".concat(key))));
                        }))))),
            React.createElement(Modal.Footer, null,
                React.createElement(Button, { onClick: function () {
                        setShow(false);
                        onCancelBtnClick();
                    } }, i18nShop.t('printessTemplateOptions.modal.cancelBtnLabel')),
                React.createElement(Button, { variant: 'primary', onClick: function () {
                        onSelectTemplate(templateType);
                        setShow(false);
                    } }, i18nShop.t('printessTemplateOptions.modal.okBtnLabel'))))))));
};
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
