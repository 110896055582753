import camelize from  '../util/camelizeStyle';
import hyphenate from  '../util/hyphenateStyle';
import _getComputedStyle from  './getComputedStyle';
import removeStyle from  './removeStyle';

const has = Object.prototype.hasOwnProperty;

export default function style(node, property, value) {
  var css = '',
      props = property;

  if (typeof property === 'string') {

    if (value === undefined) {
      return node.style[camelize(property)] || _getComputedStyle(node).getPropertyValue(hyphenate(property));
    } else {
      (props = {})[property] = value;
    }
  }

  for (var key in props){
    if (has.call(props, key)) {
      !props[key] && props[key] !== 0 ? removeStyle(node, hyphenate(key)) : css += hyphenate(key) + ':' + props[key] + ';';  // jshint ignore:line
    }
  }

  node.style.cssText += ';' + css;
}