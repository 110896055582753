import defineComponent from '../../../components/flight/lib/component';
import moment from 'moment/moment';

// NOTE: this import looks unused, but it's very much needed!
import Daterangepicker from 'bootstrap-daterangepicker/daterangepicker';

export default defineComponent(FilterDateRangePicker);

function FilterDateRangePicker() {

    this.attributes({
        id: "unknown",
        labelAll: "All",
        labelAllDates: "All dates",
        daysBack: 7,
        captionTextSelector: "span.caption-text",
        textSelector: "span.text",
        startSelector: '[data-type="start"]',
        endSelector: '[data-type="end"]',
        labelSelector: '[data-type="label"]',
        label2Selector: '[data-type="label2"]'
    });

    this.render = function (start, end, label) {
        var caption = this.attr.labelAll === label ? this.attr.labelAllDates : start.format('MMM D') + ' - ' + end.format('MMM D');

        this.select("captionTextSelector").text(label);
        this.select("textSelector").html(caption);

        if (this.attr.labelAll === label) {
            this.select('startSelector').val(null);
            this.select('endSelector').val(null);

        } else {
            this.select('startSelector').val(start.toDate().getTime());
            this.select('endSelector').val(end.toDate().getTime());
        }
        this.select('labelSelector').val(label);
        this.select('label2Selector').val(caption);

        this.trigger("uiDateRangeChange", {
            id: this.attr.id,
            range: {
                start: this.attr.labelAll === label ? null : start.toDate().getTime(),
                end: this.attr.labelAll === label ? null : end.toDate().getTime(),
                label: label
            }
        });
    };

    this.init = function () {

        var idag = moment().utc().endOf("day"),
            ranges = {
                'All': [moment().subtract(120, 'month').startOf('month'), moment()],
                'Today': [moment(), moment()],
                'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                'This Week': [moment().startOf('week'), moment().endOf('week')],
                'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                'This Month': [moment().startOf('month'), moment().endOf('month')],
                'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
            };

        [0, 1, 2, 3, 4].map(months => {
            var start = moment(idag).subtract(months, "month").startOf("month"),
                end = moment(idag).subtract(months, "month").endOf("month");

            return [start, end];
        }).reduce(function (prev, current) {
            var date = current[0].format("MMMM YYYY");

            prev[date] = current;
            return prev;
        }, ranges);

        this.$node.daterangepicker({
            startDate: moment().subtract(this.attr.daysBack, 'days'),
            endDate: moment(),
            minDate: '01/01/2015',
            maxDate: moment().add(6, 'month'),
            dateLimit: {days: 365},
            ranges: ranges
        }, this.render.bind(this));
    };

    this.setState = function (event, state) {
        if (state.range2222) {
            this.range.start = moment(state.range.start);
            this.range.end = moment(state.range.end);
            this.range.label = state.range.label;
        }
    };

    this.after('initialize', function () {
        this.attr.id = this.$node.data('key');
        this.on(document, "uiSetState", this.setState);
        this.init();

        // default range
        this.range = {
            start: moment().subtract(50, 'year'),
            end: moment(),
            label: 'All'
        };

        this.trigger("requestState");
        this.render(this.range.start, this.range.end, this.range.label);
        this.$node.removeClass("invisible");
    });
}
