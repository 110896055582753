import defineComponent from '../../../components/flight/lib/component';

export default defineComponent(WebGlViewManager);

function WebGlViewManager() {

    this.attributes({
        frameSelector: ".embed-wgl3d"
    });

    this.propagateReloadEvent = function(event, order) {
        const item = order.getItem(this.itemId);
        this.select('frameSelector')[0].contentWindow.postMessage({
            m: "reload",
            u: item.uvMap,
            item: item
        }, "*");
    };

    this.after('initialize', function () {
        this.orderId = this.$node.data('order');
        this.itemId = this.$node.data('item');
        this.on(document, "setOrderData", this.propagateReloadEvent);
    });
}
