var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Table from 'react-bootstrap/Table';
import { Image, Button, Row, Col, Alert, Form } from "react-bootstrap";
import { Formik, FieldArray, Field, ErrorMessage } from 'formik';
import { FormControl } from "../../../../react/self-publishing/PublicationSettings/components/styled";
import * as Yup from 'yup';
var FeaturedProductManager = function (props) {
    var homepageSettingsUrl = "/admin/api/homepage-settings";
    var _a = useState(), homepageSettings = _a[0], setHomepageSettings = _a[1];
    var _b = useState(), availablePages = _b[0], setAvailablePages = _b[1];
    var _c = useState('idle'), savingProgress = _c[0], setSavingProgress = _c[1];
    var getHomepageSettings = function () {
        axios.get("".concat(homepageSettingsUrl, "/").concat(props.homepageSettingsId))
            .then(function (response) {
            setHomepageSettings(response.data);
        }).catch(function (error) {
            alert("Error Retrieving Homepage Settings Data: ".concat(error));
        });
    };
    var getAvailableProductPages = function () {
        axios.get("".concat(homepageSettingsUrl, "/").concat(props.homepageSettingsId, "/pages"))
            .then(function (response) {
            setAvailablePages(response.data);
        }).catch(function (error) {
            alert("Error Retrieving Homepage Settings Data: ".concat(error));
        });
    };
    useEffect(function () {
        getHomepageSettings();
        getAvailableProductPages();
    }, []);
    var getSantapageMetadata = function (id) {
        var existingOption = availablePages.find(function (page) { return page.id === id; });
        if (existingOption) {
            return existingOption;
        }
        return undefined;
    };
    var addFeaturedProduct = function (formikSetValues, formikValues) {
        var initialSantapageMetadata = availablePages[0];
        var thumbnailUrlImage = initialSantapageMetadata.images[0].imageUrl ? initialSantapageMetadata.images[0].imageUrl : null;
        var newFeaturedProduct = {
            title: initialSantapageMetadata.title,
            position: homepageSettings.featuredProductsMetadata.length * 10,
            santaPageMetadataId: initialSantapageMetadata.id,
            thumbnailUrl: thumbnailUrlImage,
            unSaved: true,
            imageMetadata: initialSantapageMetadata.images[0],
            requestUri: initialSantapageMetadata.requestUri
        };
        formikSetValues({ featuredProducts: __spreadArray(__spreadArray([], formikValues.featuredProducts, true), [newFeaturedProduct], false) });
        setHomepageSettings(function (prevSettings) {
            return __assign(__assign({}, prevSettings), { featuredProductsMetadata: __spreadArray(__spreadArray([], prevSettings.featuredProductsMetadata, true), [newFeaturedProduct], false) });
        });
        return [];
    };
    return (React.createElement(React.Fragment, null, (homepageSettings && availablePages) ?
        React.createElement(React.Fragment, null,
            React.createElement("h4", null, "Featured Products"),
            React.createElement(Formik, { initialValues: {
                    featuredProducts: homepageSettings.featuredProductsMetadata.map(function (featuredProduct) { return (__assign(__assign({}, featuredProduct), { santaPageMetadataId: featuredProduct.santaPageMetadataId })); })
                }, validationSchema: Yup.object().shape({
                    featuredProducts: Yup.array().of(Yup.object().shape({
                        title: Yup.string().required('Title is required'),
                        position: Yup.number().min(0, 'Position must be at least 0').required('Position is required'),
                        santaPageMetadataId: Yup.string().required('Product page is required')
                    })),
                }), onSubmit: function (values) {
                    setSavingProgress('saving');
                    var featuredProductsToSend = values.featuredProducts.map(function (featuredProduct, i) {
                        return __assign(__assign({}, featuredProduct), { thumbnailUrl: homepageSettings.featuredProductsMetadata[i].thumbnailUrl, imageMetadata: homepageSettings.featuredProductsMetadata[i].imageMetadata, requestUri: homepageSettings.featuredProductsMetadata[i].requestUri });
                    });
                    axios.put("/admin/api/homepage-settings/".concat(homepageSettings.id, "/featured-products"), featuredProductsToSend)
                        .then(function (res) {
                        setHomepageSettings(res.data);
                        setSavingProgress('success');
                    })
                        .catch(function () { return setSavingProgress('error'); })
                        .finally(function () {
                        setTimeout(function () {
                            setSavingProgress('idle');
                        }, 5000);
                    });
                } }, function (_a) {
                var handleSubmit = _a.handleSubmit, formikSetValues = _a.setValues, values = _a.values, setFieldValue = _a.setFieldValue;
                return (React.createElement(Form, { onSubmit: handleSubmit },
                    homepageSettings.featuredProductsMetadata.length > 0 ? (React.createElement(Table, { striped: true, hover: true, responsive: true },
                        React.createElement("thead", null,
                            React.createElement("tr", null,
                                React.createElement("th", null, "Image"),
                                React.createElement("th", null, "Title"),
                                React.createElement("th", null, "Position"),
                                React.createElement("th", null, "Product Page"),
                                React.createElement("th", null))),
                        React.createElement("tbody", null,
                            React.createElement(FieldArray, { name: "priceCalculators" }, function (_a) {
                                var remove = _a.remove;
                                return (React.createElement(React.Fragment, null, homepageSettings.featuredProductsMetadata.map(function (featuredProduct, index) { return (React.createElement("tr", { key: "".concat(featuredProduct.santaPageMetadataId, "_").concat(featuredProduct.thumbnailUrl, "_").concat(featuredProduct.title, "_").concat(featuredProduct.position) },
                                    React.createElement("td", null,
                                        React.createElement(Image, { src: featuredProduct.imageMetadata ? featuredProduct.imageMetadata.imageUrl : "", thumbnail: true, width: "200px" })),
                                    React.createElement("td", null,
                                        React.createElement(Field, { type: "text", name: "featuredProducts.".concat(index, ".title"), as: FormControl }),
                                        React.createElement(ErrorMessage, { name: "featuredProducts.".concat(index, ".title"), component: "div", className: "error" })),
                                    React.createElement("td", null,
                                        React.createElement(Field, { type: "number", name: "featuredProducts.".concat(index, ".position"), as: FormControl }),
                                        React.createElement(ErrorMessage, { name: "featuredProducts.".concat(index, ".position"), component: "div", className: "error" })),
                                    React.createElement("td", null,
                                        React.createElement(Field, { type: "select", name: "featuredProducts.".concat(index, ".santaPageMetadataId"), as: Form.Select, onChange: function (event) {
                                                var selectedSantapageMetadata = getSantapageMetadata(event.target.value);
                                                setFieldValue("featuredProducts.".concat(index, ".santaPageMetadataId"), selectedSantapageMetadata.id);
                                                setFieldValue("featuredProducts.".concat(index, ".title"), selectedSantapageMetadata.title);
                                                setHomepageSettings(function (prevHomepageSettings) {
                                                    prevHomepageSettings.featuredProductsMetadata[index].thumbnailUrl = selectedSantapageMetadata.images[0].imageUrl ? selectedSantapageMetadata.images[0].imageUrl : null;
                                                    prevHomepageSettings.featuredProductsMetadata[index].title = selectedSantapageMetadata.title;
                                                    prevHomepageSettings.featuredProductsMetadata[index].imageMetadata = selectedSantapageMetadata.images[0];
                                                    prevHomepageSettings.featuredProductsMetadata[index].requestUri = selectedSantapageMetadata.requestUri;
                                                    return homepageSettings;
                                                });
                                            } }, availablePages.map(function (page) { return (React.createElement("option", { key: "santapagemetadata-option}-".concat(page.id), value: page.id }, page.title)); })),
                                        React.createElement(ErrorMessage, { name: "featuredProducts.".concat(index, ".santaPageMetadataId"), component: "div", className: "error" })),
                                    React.createElement("td", { className: "text-end" },
                                        React.createElement(Button, { variant: "danger", onClick: function () {
                                                remove(index);
                                                setHomepageSettings(function (homepageSettings) {
                                                    homepageSettings.featuredProductsMetadata.splice(index, 1);
                                                    return homepageSettings;
                                                });
                                            } }, "Delete")))); })));
                            })))) : (React.createElement(Alert, { variant: "warning" }, "No Featured Products Currently Configured")),
                    React.createElement(Row, { className: "mb-3" },
                        React.createElement(Col, { className: "col col-md-12" },
                            React.createElement(Button, { variant: "secondary", onClick: function () { return addFeaturedProduct(formikSetValues, values); }, className: "me-1" }, "Add New Featured Product"),
                            React.createElement(Button, { variant: "primary", type: "submit" }, savingProgress === 'saving' ?
                                React.createElement("div", { className: "spinner-border spinner-border-sm", role: "status" },
                                    React.createElement("span", { className: "visually-hidden" }, "Loading...")) : 'Save'),
                            savingProgress === 'success' && React.createElement("div", { className: "success" }, "Saved!"),
                            savingProgress === 'error' && React.createElement("div", { className: "error" }, "Encountered an error when saving, please try again")))));
            })) : ''));
};
export default FeaturedProductManager;
