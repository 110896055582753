import React from 'react';

class AnimatedCheckBox extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            checked: props.checked
        };
        this.id = "SW" + Math.floor(Math.random() * 2147483648).toString(36);
    }

    toggle() {
        const checked = !this.state.checked;
        this.setState({ checked });
        if (this.props.toggleCallback) {
            this.props.toggleCallback(checked);
        }
    }

    render() {
        const checked = this.state.checked;
        const id = "hgf4756kjhlj" + this.id;

        // noinspection CheckTagEmptyBody,HtmlUnknownAttribute
        return (<div className="animated-check-radio-box-list">
                    <div className="animated-checkbox" data-toggle="animated-checkbox" data-type="checkbox" data-mark="checkmark">
                        <div className="ripple ripple-blue">

                              <input
                                id={id}
                                defaultChecked={checked}
                                type="checkbox"
                                value="1"
                                name={this.props.name}
                                onChange={this.toggle.bind(this)}
                                />
                            <label
                                htmlFor={id}
                                >{this.props.label}</label>
                            <svg
                                viewBox="0 0 100 100"
                                xmlns="http://www.w3.org/2000/svg">
                                {checked && ( <path d="M16.667,62.167c3.109,5.55,7.217,10.591,10.926,15.75 c2.614,3.636,5.149,7.519,8.161,10.853c-0.046-0.051,1.959,2.414,2.692,2.343c0.895-0.088,6.958-8.511,6.014-7.3 c5.997-7.695,11.68-15.463,16.931-23.696c6.393-10.025,12.235-20.373,18.104-30.707C82.004,24.988,84.802,20.601,87,16"
                                style={{"strokeDasharray": '126.37, 126.37', "strokeDashoffset": 0, transition: 'strokeDashoffset 0.2s ease-in-out 2s'}}></path>)}
                            </svg>

                        </div>
                    </div>
                </div>);
    }
}

export default AnimatedCheckBox;
