var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useEffect, useState } from "react";
import { Spinner } from 'react-bootstrap';
import axios from "axios";
import TooltipEditor from "./TooltipEditor";
import { Button } from 'react-bootstrap';
var getAllTooltipTypes = function (successCallback) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        axios.get("/admin/shop/tooltips/types")
            .then(function (_a) {
            var data = _a.data;
            successCallback(data);
        })
            .catch(function (err) {
            console.error(err);
        });
        return [2 /*return*/];
    });
}); };
var getTooltipsData = function (mode, productId, subProductId, successCallback) { return __awaiter(void 0, void 0, void 0, function () {
    var tooltipsUrl;
    return __generator(this, function (_a) {
        tooltipsUrl = mode === 'shop' ? "/admin/shop/tooltips" : "/admin/shop/tooltips/products/".concat(productId, "/sub-products/").concat(subProductId);
        axios.get(tooltipsUrl)
            .then(function (_a) {
            var data = _a.data;
            successCallback(data);
        });
        return [2 /*return*/];
    });
}); };
var TooltipsList = function (_a) {
    var shopId = _a.shopId, productId = _a.productId, subProductId = _a.subProductId, mode = _a.mode;
    var _b = useState([]), allTooltipTypes = _b[0], setAllTooltipTypes = _b[1];
    var _c = useState([]), tooltips = _c[0], setTooltips = _c[1];
    var _d = useState(true), loading = _d[0], setLoading = _d[1];
    useEffect(function () {
        getAllTooltipTypes(setAllTooltipTypes);
        getTooltipsData(mode, productId, subProductId, function (tooltips) {
            setTooltips(tooltips);
            setLoading(false);
        });
    }, []);
    var addTooltipItem = function () {
        var newTooltipWithData = {
            content: '',
            id: null,
            shopId: shopId,
            tooltipType: allTooltipTypes[0].toString(),
            productId: productId,
            subProductId: subProductId
        };
        setTooltips(__spreadArray(__spreadArray([], tooltips, true), [newTooltipWithData], false));
    };
    var handleDeleteProduct = function (tooltip) {
        if (mode === 'product') {
            setTooltips(tooltips.filter(function (t) { return t !== tooltip; }));
            if (tooltip.id) {
                axios.delete("/admin/shop/tooltips/".concat(tooltip.id)).then(function (_a) { });
            }
        }
    };
    if (loading) {
        return React.createElement(Spinner, null);
    }
    if (mode === 'shop') {
        return React.createElement(React.Fragment, null, allTooltipTypes.map(function (tooltipType, index) {
            var foundTooltip = tooltips.find(function (tooltip) { return tooltip.tooltipType === tooltipType; });
            if (!foundTooltip) {
                foundTooltip = {
                    content: '',
                    id: null,
                    shopId: shopId,
                    tooltipType: tooltipType,
                    productId: productId,
                    subProductId: subProductId
                };
            }
            return React.createElement(TooltipEditor, { index: index, key: tooltipType, tooltip: foundTooltip, mode: mode, allTooltipTypes: allTooltipTypes, handleDeleteProduct: handleDeleteProduct });
        }));
    }
    return (React.createElement("div", null,
        tooltips.map(function (tooltip, index) {
            return React.createElement(TooltipEditor, { index: index, key: "tooltip-".concat(index, "-").concat(tooltip.id, "-").concat(tooltip.componentType, "-").concat(tooltip.tooltipType), tooltip: tooltip, mode: mode, allTooltipTypes: allTooltipTypes, handleDeleteProduct: handleDeleteProduct });
        }),
        React.createElement(Button, { variant: "warning", className: "mt-3", onClick: function () {
                addTooltipItem();
            } }, "Add Tooltip")));
};
export default TooltipsList;
