import defineComponent from '../../../../components/flight/lib/component';

export default defineComponent(SupplierMachineEditor);

function SupplierMachineEditor() {

    this.attributes({
        deleteSupplierMachineBtn: '[data-type="delete-supplier-machine-btn"]',
        saveSupplierMachineBtn: '[data-type="save-supplier-machine-btn"]',
        deleteSupplierMachinePriceBtn: '[data-type="delete-machine-price-btn"]',
        addSupplierMachinePriceBtn: '[data-type="add-machine-price-btn"]',
        machinePricesTableBody: '[data-type="machine-prices"]',
        canPrintCustomSize: '[data-type="can-print-custom-size"]',
        customSizeAddition: '[data-type="custom-size-addition"]',
        addMachineBtn: '[data-type="add-machine-btn"]',
        machineNameSelect: '[data-type="machine-name"]',
    });

    this.deleteSupplierMachine = function(event) {
        const removeSupplierMachineUrl = '/admin/catalogue/suppliers/machine/remove';
        const data = {
            supplierId: this.supplierId,
            machineName: this.machineName
        };
        const btn = $(event.target);
        $.post(removeSupplierMachineUrl, data).done((response) => {
            $(this.node).fadeOut("normal", function() {
                $(this.node).remove();
            });
        });
        event.preventDefault();
    };

    this.saveSupplierMachine = function(event) {

        const saveUrl = `/admin/catalogue/suppliers/${this.supplierId}/machine/save`;

        let priceListTableRows = this.select('machinePricesTableBody').children('tr');

        let priceListRows = [];
        priceListTableRows.each(function(){
            priceListRows.push(
                {
                    delay: $(this).find('[data-field="price-delay"]').val(),
                    priceIncrement: $(this).find('[data-field="price-increment"]').val(),
                    isActive: $(this).find('[data-field="price-is-active"]').val()
                }
            );
        });

        const machineData = {
            name: this.machineName,
            customSizeAddition: this.select('customSizeAddition').val(),
            canPrintCustomSize: this.select('canPrintCustomSize').val(),
            prices: priceListRows
        };

        let machineEditor = $(this.node);

        $.ajax({
            url: saveUrl,
            type: 'POST',
            data: {
                machineData: JSON.stringify(machineData),
                machineName: this.machineName
            },
            success: function(response) {
                machineEditor.animate({opacity: '0.4'}, "fast");
                machineEditor.html(response);
                machineEditor.animate({opacity: '1.0'}, "slow");
            },
            error: function(error) {
                alert('Error Saving Data');
            }
        });

        event.preventDefault();
    };

    this.deleteSupplierMachinePrice = function(event) {
        $(event.target).closest('tr').remove();
        const priceCount = this.select('machinePricesTableBody').children().length;
        if(priceCount === 1) {
            let rowDeleteButton = this.select('machinePricesTableBody').find('[data-type="delete-machine-price-btn"]');
            rowDeleteButton.prop('disabled', true);
            rowDeleteButton.addClass('disabled');
        }
        event.preventDefault();
    };

    this.addSupplierMachinePrice = function(event) {

        let newPriceRow = `
            <tr>
                <td>
                    <input type="number" id="priceDelay" name="customSizeAddition" class="form-control form-control-sm" data-field="price-delay" min="0" step="1" value="0" />
                </td>
                <td>
                    <input type="number" id="priceIncrement" name="customSizeAddition" class="form-control form-control-sm" data-field="price-increment" min="0" step="0.01" value="0.0" />
                </td>
                <td>
                    <select id="isActive" name="isActive" class="form-select form-select-sm" data-field="price-is-active" value="false" required>
                        <option value="true">Yes</option>
                        <option value="false" selected>No</option>
                    </select>
                </td>
                <td class="text-end">
                    <button type="button" class="btn btn-sm btn-danger" data-type="delete-machine-price-btn" data-row="-1">Delete</button>
                </td>
            </tr>`;

        this.select('machinePricesTableBody').append(newPriceRow);

        const priceCount = this.select('machinePricesTableBody').children().length;
        if(priceCount > 1) {
            let rowDeleteButtons = this.select('machinePricesTableBody').find('[data-type="delete-machine-price-btn"]');
            rowDeleteButtons.each(function( index, deleteBtn ) {
                $(deleteBtn).prop('disabled', false);
                $(deleteBtn).removeClass('disabled');
            });
        }

        event.preventDefault();
    };

    this.addMachine = function(event) {
        const saveUrl = `/admin/catalogue/suppliers/${this.supplierId}/machine/add`;

        let priceListTableRows = this.select('machinePricesTableBody').children('tr');

        let priceListRows = [];
        priceListTableRows.each(function(){
            priceListRows.push(
                {
                    delay: $(this).find('[data-field="price-delay"]').val(),
                    priceIncrement: $(this).find('[data-field="price-increment"]').val(),
                    isActive: $(this).find('[data-field="price-is-active"]').val()
                }
            );
        });

        const machineData = {
            name: this.select('machineNameSelect').val(),
            customSizeAddition: this.select('customSizeAddition').val(),
            canPrintCustomSize: this.select('canPrintCustomSize').val(),
            prices: priceListRows
        };

        let machineEditorList = document.querySelector('[data-type="machine-editor-list"]');

        $.ajax({
            url: saveUrl,
            type: 'POST',
            data: {
                machineData: JSON.stringify(machineData),
                machineName: this.select('machineNameSelect').val()
            },
            success: function(response) {
                $(machineEditorList).append(response);
                let newMachine = document.querySelector('[data-type="machine-editor-list"] [data-type="admin-supplier-machine-editor"]:last-child');
                let newSupplierMachineEditor = defineComponent(SupplierMachineEditor);
                newSupplierMachineEditor.attachTo(newMachine);
            },
            error: function(error) {
                alert(`Error Saving Data: ${error.responseText}`);
            }
        });

        event.preventDefault();
    };

    this.after('initialize', function () {
        this.machineName = $(this.node).data('machine-name');
        this.supplierId = $(this.node).data('supplier-id');
        this.on('click', {
            deleteSupplierMachineBtn: this.deleteSupplierMachine,
            saveSupplierMachineBtn: this.saveSupplierMachine,
            deleteSupplierMachinePriceBtn: this.deleteSupplierMachinePrice,
            addSupplierMachinePriceBtn: this.addSupplierMachinePrice,
            addMachineBtn: this.addMachine
        });
    });
}
