import defineComponent from '../../../components/flight/lib/component';
import '../../../components/typeahead.js/dist/typeahead.bundle';

export default defineComponent(FilterTypeahead);

function FilterTypeahead() {

    this.attributes({
        data: [],
        limit: 30
    });

    this.makeDistinctArray = function (data, id) {
        const all = {};

        data.forEach(item => {
            if (item[id]) {
                let key = item[id].caption || item[id].name || item[id];
                all[key] = (all[key] || 0) + 1;
            }
        });

        return Object.keys(all).map(item => {
            return {v: item, p: all[item]};
        });
    };

    this.getSuggestions = function () {
        if (!this.suggestions) {
            this.suggestions = new Bloodhound({
                name: 'suggestions-' + this.id,
                local: this.makeDistinctArray(this.attr.data, this.id),
                limit: this.attr.limit,
                datumTokenizer: Bloodhound.tokenizers.obj.nonword("v"),
                queryTokenizer: Bloodhound.tokenizers.nonword,
                sorter: (a, b) => b.p - a.p,  // sort by popularity
                dupDetector: (a, b) => a.v === b.v
            });
        }
        this.suggestions.initialize();
        return this.suggestions;
    };

    this.init = function () {
        this.$node.typeahead({
                hint: true,
                highlight: true,
                minLength: 1
            },
            {
                displayKey: "v",
                templates: {suggestion: o => (o.p > 1 ? `<span class='count'>${o.p}</span>` : "") + `<span class='content'>${o.v}</span>`},
                source: this.getSuggestions().ttAdapter()
            });
    };
    this.after('initialize', function () {
        this.id = this.$node.data('key');

        setTimeout(() => this.init(), 700);
    });
}

