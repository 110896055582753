import defineComponent from '../../../../components/flight/lib/component';
import React from 'react';
import ReactDom from 'react-dom/client';
import ParticipationRuleBuilder from '../React/ParticipationRuleBuilder/ParticipationRuleBuilder.tsx';

export default defineComponent(AdminParticipationRuleBuilder);

function AdminParticipationRuleBuilder() {

    this.initAdminRuleBuilder = function () {
        const ruleBuilder = $(this.node);
        const root = ReactDom.createRoot(ruleBuilder.get(0));
        root.render(
            <ParticipationRuleBuilder
                participationPolicyId={ruleBuilder.data('participation-policy-id')}
            ></ParticipationRuleBuilder>
        );
    };

    this.after('initialize', function () {
        this.mode = $(this.node).data('mode');
        this.initAdminRuleBuilder();
    });

}
