import mixam from '../boot/mixam';
import defineComponent from '../../components/flight/lib/component';
import WithQueryParams from './with-query-params';

export default defineComponent(Logger, WithQueryParams);

let logCount = 0;

window.onerror = function (errorMsg, url, lineNumber, column, errorObj) {
    const args = [];

    if (++logCount < 20) {
        args.push("type=onerror");
        args.push("msg=" + encodeURIComponent(errorMsg || "n/a"));
        args.push("count=" + logCount);
        args.push("file=" + encodeURIComponent(url));
        args.push("col=" + encodeURIComponent(column || "0"));
        args.push("errorObj=" + encodeURIComponent(JSON.stringify(errorObj || {})));
        args.push("linenumber=" + encodeURIComponent(lineNumber || "0"));
        args.push("csuser=" + encodeURIComponent(mixam.user.username || "n/a"));
        args.push("shop=" + encodeURIComponent(mixam.shop.name || "n/a"));
        mixam.log(args);
    }
    return false;
};

function Logger() {


    this.attributes({
        level: 0
    });

    this.sendLogEvent = function (params) {
        const url = $("#applicationDomain").val() + "log?";

        params = params || [];
        params.push("time=" + (new Date()).getTime());
        params.push("url=" + encodeURIComponent(location.href));
        params.push("ua=" + encodeURIComponent(navigator.userAgent));
        $.get(url + params.join("&"));
    };

    this.log = function (event, data) {
        let aData;

        if (data.params) {
            this.sendLogEvent(data.params);
        } else {
            const level = data.level || 4;
            if (level <= this.attr.level) {
                if (window.console && console.log && console.log.apply) {
                    if (Array.isArray(data)) {
                        aData = data;
                    } else if (Array.isArray(data.message)) {
                        aData = data.message;
                    } else if (data.message) {
                        aData = [data.message];
                    } else {
                        aData = [data];
                    }
                    if (data.title) {
                        aData.unshift(data.title);
                    }
                    console.log.apply(console, aData);
                }
            }
        }
    };

    this.echo = function (...text) {
        if (window.console && console.log && console.log.apply) {
            console.log.apply(console, text);
        }
    };

    this.after('initialize', function () {
        const debugLevel = parseInt(this.getParameter('debug'), 10);

        if (!isNaN(debugLevel)) {
            this.attr.level = debugLevel;
        }
        this.on(document, "log", this.log);

        if (this.attr.level) {
            this.echo(`mixam.co.uk is loaded with software version '${mixam.version}' in debug mode: ${this.attr.level}`);
        }
    });
}
