var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useState } from 'react';
import { Importer, ImporterField } from 'react-csv-importer';
import 'react-csv-importer/dist/index.css';
import axios from 'axios';
var CsvDataImporter = function (_a) {
    var fields = _a.fields, uploadDataUrl = _a.uploadDataUrl, chunkSize = _a.chunkSize;
    var _b = useState([]), allRecords = _b[0], setAllRecords = _b[1];
    var _c = useState(false), importComplete = _c[0], setImportComplete = _c[1];
    var _d = useState(false), isImporting = _d[0], setIsImporting = _d[1];
    var _e = useState(false), showTable = _e[0], setShowTable = _e[1];
    var _f = useState(null), importResults = _f[0], setImportResults = _f[1];
    var validateData = function (data, isRevalidation) {
        if (isRevalidation === void 0) { isRevalidation = false; }
        return __awaiter(void 0, void 0, void 0, function () {
            var validateUrl, dataToValidate, response, _a, failed, processed, skipped, processedWithKey, failedWithKey, skippedWithKey, newRecords_1, error_1;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        setIsImporting(true);
                        if (isRevalidation) {
                            setAllRecords([]);
                        }
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 3, 4, 5]);
                        validateUrl = "".concat(uploadDataUrl, "/import/validate");
                        dataToValidate = isRevalidation ? data : data.map(function (row) { return (__assign({}, row)); });
                        return [4 /*yield*/, axios.post(validateUrl, dataToValidate, {
                                headers: {
                                    'Content-Type': 'application/json'
                                }
                            })];
                    case 2:
                        response = _b.sent();
                        _a = response.data, failed = _a.failedRecords, processed = _a.processedRecords, skipped = _a.skippedRecords;
                        processedWithKey = processed.map(function (record, index) { return (__assign(__assign({}, record), { key: "processed-".concat(index), imported: true })); });
                        failedWithKey = failed.map(function (record, index) { return (__assign(__assign({}, record), { key: "failed-".concat(index) })); });
                        skippedWithKey = skipped.map(function (record, index) { return (__assign(__assign({}, record), { key: "skipped-".concat(index) })); });
                        newRecords_1 = __spreadArray(__spreadArray(__spreadArray([], processedWithKey, true), failedWithKey, true), skippedWithKey, true);
                        setAllRecords(function (prev) { return __spreadArray(__spreadArray([], prev, true), newRecords_1, true); });
                        setShowTable(true);
                        return [3 /*break*/, 5];
                    case 3:
                        error_1 = _b.sent();
                        console.error('Error validating data:', error_1.message);
                        window.dispatchEvent(new CustomEvent('showFixedMessage', {
                            detail: { message: { type: 'error', body: "Error validating data: ".concat(error_1.message), interval: 15000 } }
                        }));
                        return [3 /*break*/, 5];
                    case 4:
                        setIsImporting(false);
                        return [7 /*endfinally*/];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    var dataHandler = function (rows, _a) {
        var startIndex = _a.startIndex;
        return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, validateData(rows)];
                    case 1:
                        _b.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    var handleReValidate = function () {
        validateData(allRecords, true);
    };
    var handleImportComplete = function () {
        window.dispatchEvent(new CustomEvent('showFixedMessage', {
            detail: { message: { type: 'success', body: 'Data Import Complete', interval: 15000 } }
        }));
        document.dispatchEvent(new CustomEvent('uiDataImportComplete'));
        setImportComplete(true);
        setShowTable(false);
    };
    var handleFieldChange = function (index, fieldName, value) {
        var _a;
        var updatedRecords = __spreadArray([], allRecords, true);
        updatedRecords[index] = __assign(__assign({}, updatedRecords[index]), (_a = {}, _a[fieldName] = fieldName === 'overwrite' ? Boolean(value) : value, _a));
        setAllRecords(updatedRecords);
    };
    var handleFinalImport = function () { return __awaiter(void 0, void 0, void 0, function () {
        var applyUrl, recordsToImport, response, _a, newFailed_1, newProcessed_1, newSkipped_1, updatedRecords, detailedResults, error_2;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    setIsImporting(true);
                    _b.label = 1;
                case 1:
                    _b.trys.push([1, 3, 4, 5]);
                    applyUrl = "".concat(uploadDataUrl, "/import/apply");
                    recordsToImport = allRecords;
                    if (recordsToImport.length === 0) {
                        window.dispatchEvent(new CustomEvent('showFixedMessage', {
                            detail: { message: { type: 'info', body: 'No records to import.', interval: 5000 } }
                        }));
                        setIsImporting(false);
                        return [2 /*return*/];
                    }
                    return [4 /*yield*/, axios.post(applyUrl, recordsToImport, {
                            headers: {
                                'Content-Type': 'application/json'
                            }
                        })];
                case 2:
                    response = _b.sent();
                    _a = response.data, newFailed_1 = _a.failedRecords, newProcessed_1 = _a.processedRecords, newSkipped_1 = _a.skippedRecords;
                    updatedRecords = allRecords.map(function (record) {
                        var processed = newProcessed_1.find(function (p) {
                            return p.orderNumber === record.orderNumber &&
                                p.actualCost === record.actualCost &&
                                p.referenceNumber === record.referenceNumber;
                        });
                        var failed = newFailed_1.find(function (f) {
                            return f.orderNumber === record.orderNumber &&
                                f.actualCost === record.actualCost &&
                                f.referenceNumber === record.referenceNumber;
                        });
                        var skipped = newSkipped_1.find(function (s) {
                            return s.orderNumber === record.orderNumber &&
                                s.actualCost === record.actualCost &&
                                s.referenceNumber === record.referenceNumber;
                        });
                        if (processed) {
                            return __assign(__assign({}, record), { error: undefined, imported: true });
                        }
                        if (failed) {
                            return __assign(__assign({}, record), { error: failed.error });
                        }
                        if (skipped) {
                            return __assign(__assign({}, record), { warning: skipped.warning });
                        }
                        return record;
                    });
                    setAllRecords(updatedRecords);
                    detailedResults = updatedRecords.map(function (record, index) {
                        var status = 'Processed';
                        var message = 'No errors';
                        if (record.error) {
                            status = 'Failed';
                            message = record.error;
                        }
                        else if (record.warning && !record.overwrite) {
                            status = 'Skipped';
                            message = record.warning;
                        }
                        else if (record.warning && record.overwrite) {
                            status = 'Processed';
                            message = 'Overwritten';
                        }
                        return "Row ".concat(index + 1, " (Order: ").concat(record.orderNumber, "): ").concat(status, " - ").concat(message);
                    }).join('\n');
                    setImportResults(detailedResults);
                    if (newFailed_1.length === 0) {
                        handleImportComplete();
                    }
                    else {
                        window.dispatchEvent(new CustomEvent('showFixedMessage', {
                            detail: { message: { type: 'warning', body: 'Some records failed to import. Please review and try again.', interval: 15000 } }
                        }));
                    }
                    return [3 /*break*/, 5];
                case 3:
                    error_2 = _b.sent();
                    console.error('Error applying import:', error_2.message);
                    window.dispatchEvent(new CustomEvent('showFixedMessage', {
                        detail: { message: { type: 'error', body: "Error importing data: ".concat(error_2.message), interval: 15000 } }
                    }));
                    return [3 /*break*/, 5];
                case 4:
                    setIsImporting(false);
                    return [7 /*endfinally*/];
                case 5: return [2 /*return*/];
            }
        });
    }); };
    var renderTable = function () {
        return (React.createElement("div", { className: "mt-4" },
            React.createElement("h2", null, "Data Review"),
            React.createElement("table", { className: "table table-bordered" },
                React.createElement("thead", { className: "thead-dark" },
                    React.createElement("tr", null,
                        fields.map(function (field, index) { return (React.createElement("th", { key: index }, field.label)); }),
                        React.createElement("th", null, "Status"))),
                React.createElement("tbody", null, allRecords.map(function (record, index) { return (React.createElement("tr", { key: record.key || index, className: record.error ? 'table-danger' : (record.warning ? 'table-warning' : 'table-success') },
                    fields.map(function (field, fIndex) {
                        var _a, _b;
                        return (React.createElement("td", { key: "".concat(index, "_").concat(fIndex) }, (record.error || record.warning) ? (field.name === 'overwrite' ? (React.createElement("select", { className: "form-control", value: String((_a = record[field.name]) !== null && _a !== void 0 ? _a : 'false'), onChange: function (e) { return handleFieldChange(index, field.name, e.target.value === 'true'); } },
                            React.createElement("option", { value: "true" }, "Yes"),
                            React.createElement("option", { value: "false" }, "No"))) : (React.createElement("input", { type: "text", className: "form-control", value: ((_b = record[field.name]) !== null && _b !== void 0 ? _b : ''), onChange: function (e) { return handleFieldChange(index, field.name, e.target.value); } }))) : (record[field.name])));
                    }),
                    React.createElement("td", null, record.error ?
                        React.createElement("span", { className: "text-danger" }, record.error) :
                        (record.warning ?
                            React.createElement("span", { className: "text-warning" },
                                record.warning,
                                " ",
                                record.overwrite ? 'Will be overwritten.' : 'Set overwrite to Yes to import.') :
                            'No Errors - OK to import')))); }))),
            allRecords.length > 0 && (React.createElement(React.Fragment, null,
                React.createElement("button", { className: "btn btn-primary", onClick: handleFinalImport, disabled: isImporting || allRecords.some(function (record) { return record.error; }) }, isImporting ? 'Importing...' : 'Import Data'),
                React.createElement("button", { className: "btn btn-secondary ms-2", onClick: handleReValidate, disabled: isImporting }, "Re-Validate")))));
    };
    var renderImportResults = function () {
        return (React.createElement("div", { className: "mt-4" },
            React.createElement("h3", null, "Import Results"),
            React.createElement("pre", { className: "border p-3", style: { maxHeight: '300px', overflowY: 'auto' } }, importResults)));
    };
    return (React.createElement(React.Fragment, null,
        !importComplete && !showTable && (React.createElement(Importer, { restartable: true, dataHandler: dataHandler, onComplete: function () { } }, fields.map(function (field, index) { return (React.createElement(ImporterField, { key: "".concat(field.name, "_").concat(index), name: field.name, label: field.label, optional: field.optional })); }))),
        showTable && renderTable(),
        importResults && renderImportResults()));
};
export default CsvDataImporter;
