import defineComponent from '../../../../../components/flight/lib/component';
import WithDashboardComponent from '../../../with-dashbord-component';
import WithConfirmCancelBar from '../../../with-confirm-cancel-bar';
import Chartjs from '../../../../../components/Chart.js';

export default defineComponent(AnalyticsProducts, WithDashboardComponent, WithConfirmCancelBar);

function AnalyticsProducts() {
    var MARKER_COLORS = {Booklets: "#7a839a", "Leaflets": "#8f9472", Folded: "#9a7a7a", Posters: "#728694", Letterheads: "#a597b0", books: "#aa9e90", BusinessCard: "#8f9472", Postcard: "#8f9472", GreetingCards: "#8f9472"},
        MARKER_COLORS_HIGHLIGHT = {Booklets: "#979eb0", Leaflets: "#a6aa8f", Folded: "#b09797", Posters: "#8f9faa", Letterheads: "#a597b0", books: "#aa9e90", BusinessCard: "#a6aa8f", Postcard: "#a6aa8f", GreetingCards: "#a6aa8f"};

    this.attributes({
        field: "sum",
        chartSelector: '.products-chart',
        clipperSelector: '.donat-clipper',
        dataElementSelector: '.counter-data'
    });

    this.reset = function () {
        this.counters[this.attr.counter1] = this.createCounter(this.attr.counter1);
        this.counters[this.attr.counter2] = this.createCounter(this.attr.counter2);
        this.counters[this.attr.counter3] = this.createCounter(this.attr.counter3);
        this.counters[this.attr.counter4] = this.createCounter(this.attr.counter4);
        this.display(true);
    };

    this.isInteresting = function (counter) {
        return counter.cycle === "DAILY" && counter.type.indexOf("PRODUCT_") === 0;
    };

    this.hasValue = function (totals) {
        return !!totals.total.count;
    };

    this.countTotals = function () {
        var that = this,
            totals = {total: this.createCounter("TOTAL")};

        this.$elements.each(function (i, element) {
            var $element = $(element);

            (function (props, key) {
                key = key.toUpperCase();
                totals[key] = that.createCounter(key);

                props.forEach(function (prop) {
                    var value = that.calcTotal($element, prop);

                    totals[key][prop] += value;
                    totals.total[prop] += value;
                });
            }(
                ["count", "sum", "value", "cost", "vat", "delivery", "weight"],
                $element.data("counter-confirm")
            ));
        });
        return totals;
    };

    this.display = function (isSilent) {
        if (this.timer) {
            clearTimeout(this.timer);
        }
        this.timer = setTimeout(() => {
            this.timer = null;
            const totals = this.countTotals();
            Object.keys(totals).forEach(key => this.updateUiCounters(totals[key], isSilent));

            if (this.chart && this.hasValue(totals)) {
                this.types.forEach( (type, index) => this.chart.segments[index].value = totals["PRODUCT_" + (index + 1)][this.attr.field]);
                this.select('clipperSelector').addClass("in");
                this.chart.update();
            } else {
                this.select('clipperSelector').removeClass("in");
            }
        }, 200);
    };

    this.init = function () {
        var ctx = this.select('chartSelector').get(0).getContext("2d");

        /** @namespace Chartjs.Pie */
        this.chart = new Chartjs(ctx).Pie(this.types.map(function (type, index) {
            return {
                value: 1,
                color: MARKER_COLORS[type],
                highlight: MARKER_COLORS_HIGHLIGHT[type],
                label: type
            };
        }));
    };

    this.after('initialize', function () {
        this.counters = {};
        this.$elements = this.select('dataElementSelector');
        this.types = this.$elements.map(function () {
            return $(this).data("name");
        }).get();

        this.init();
        this.reset();
        this.on(document, "dashbordReset", this.reset);
        this.on(document, "dashbordCounterUpdate", this.counterUpdate);
    });
}

