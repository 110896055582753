import mixam from '../../../../boot/mixam';
import defineComponent from '../../../../../components/flight/lib/component';
import AdminAnalyticsUsersMonitor from './admin-analytics-users-monitor';
import AdminReferrerChart from './admin-referrer-chart';
import WithGetReferrerType from './with-get-referrer-type';
import DayNightOverlay from '../../../day-night-overlay/daynightoverlay';

const markerColors = {
    paid: "#F7464A",
    direct: "#46BFBD",
    referral: "#c971c8",
    organic: "#468ABF",
    mixam: "#FDB45C",
    social: "#949FB1",
    brand: "#2f43f7",
    share: '#6A29BF',
    embed: 'rgba(106, 41, 191, 0.66)'
};

export default defineComponent(AdminAnalyticsRealtime, WithGetReferrerType);

function getCircle(magnitude, type/*, isMixam*/) {
    const color = markerColors[type || "direct"];

    // noinspection JSUnresolvedVariable
    return {
        path: google.maps.SymbolPath.CIRCLE,
        fillColor: color,
        fillOpacity: 0.5,
        scale: Math.pow(2, magnitude) / Math.PI,
        strokeColor: 'white',
        strokeWeight: 0.8
    };
}

function AdminAnalyticsRealtime() {
    const MIN_ZOOM = 8;

    this.attributes({
        shopLocationUrl: '/api/shops/location',
        canvasSelector: '.map-canvas',
        referrerSelector: '.realtime-referrer',
        currentUsersSelector: '#currentUsers',
        dataMapDefaultsSelector: '[data-map-defaults]'
    });

    this.onVisitsListChange = function (event, data) {
        setTimeout(() => this.addCities(data.visits), 10);
    };

    this.initMap = function () {
        let cords = this.select('dataMapDefaultsSelector').data('mapDefaults') || [51.436493, -0.11909485];

        // noinspection JSUnresolvedVariable  JSUnresolvedFunction
        const mapOptions = {
                center: mixam.shop.isManagementConsole  ? new google.maps.LatLng(0, 0) : new google.maps.LatLng(cords[0], cords[1]),
                panControl: false,
                zoomControl: false,
                scaleControl: false,
                heading: 0,
                "mapTypeControl": false,
                "maxZoom": MIN_ZOOM,
                "minZoom": mixam.shop.isManagementConsole ? 4 : null,
                "overviewMapControl": false,
                "rotateControl": false,
                "scrollwheel": false,
                "streetViewControl": false,
                "tilt": 0,
                zoom: mixam.shop.isManagementConsole ? 4: MIN_ZOOM
            },

            styles = [
                {
                    stylers: [
                        {hue: "C1FAF4"},
                        {saturation: -50}
                    ]
                },
                {
                    featureType: "road",
                    elementType: "geometry",
                    stylers: [
                        {lightness: 80},
                        {visibility: "simplified"}
                    ]
                },
                {
                    featureType: "road",
                    elementType: "labels",
                    stylers: [
                        {visibility: "off"}
                    ]
                },
                {
                    featureType: "water",
                    stylers: [
                        {
                            visibility: "on"
                        },
                        {
                            saturation: -20
                        }
                    ]
                },
                {
                    featureType: "landscape",
                    stylers: [
                        {
                            visibility: "on"
                        },
                        {
                            color: "#e5e3df"
                        }
                    ]
                }
            ];



        // noinspection JSUnresolvedVariable
        this.map = new google.maps.Map($(".map-canvas")[0], mapOptions);
        this.map.setOptions({styles: styles});
        this.shops = [];

        if (mixam.shop.isManagementConsole) {
            $.getJSON(this.attr.shopLocationUrl).done(data => {
                this.shops = data;
                this.shops.forEach(shop => {
                    /* let image;
                     if (shop.logo) {
                         image = new google.maps.MarkerImage(shop.logo,
                             new google.maps.Size(100, 100),
                             new google.maps.Point(0, 0),
                             new google.maps.Point(0, 32));
                     }
                     var shape = {
                         coord: [1, 1, 1, 20, 18, 20, 18 , 1],
                         type: 'poly'
                     };*/
                    // noinspection JSUnresolvedVariable,JSUnresolvedFunction,JSUnresolvedFunction
                    new google.maps.Marker({
                        animation: google.maps.Animation.DROP,
                        //clickable: false,
                        //icon: image,
                        //shape: shape,
                        label: shop.title,
                        position: new google.maps.LatLng(shop.latitude, shop.longitude),
                        map: this.map
                    });
                });
            }).fail((err) => this.trigger("log", {message: err}));

            this.showWholeWorld();

            this.overlay = new DayNightOverlay({
                map: this.map,
                fillColor: 'rgba(0,0,0,0.15)',
                date: new Date(Date.now())
            });
            setTimeout(() => this.setTime(), 1000);
        }
    };

    this.setTime = function () {
        if (this.overlay) {
            this.overlay.setDate(new Date(Date.now()));
            setTimeout(() => this.setTime(), 1000);
        }
    };

    this.setSessionsType = function (list) {
        list.forEach((session) => {
            for (let key in session.ips) {
                if (session.ips.hasOwnProperty(key)) {
                    const ip = session.ips[key];
                    for (let userKey in ip.users) {
                        if (ip.users.hasOwnProperty(userKey)) {
                            const user = ip.users[userKey];
                            ip.isMixam = user.isMixam;
                            if (user.views && user.views.length) {
                                session.refType = this.getReferrerType(user, session);
                                return;
                            }
                        }
                    }
                }
            }
            if (!session.refType) {
                session.refType = "direct";
            }
        });
    };

    this.addCities = function (list) {

        // noinspection JSUnresolvedFunction,JSUnresolvedVariable
        const bounds = new google.maps.LatLngBounds();
        let marker;

        if (!this.map) {
            setTimeout(() => this.addCities(list), 50);
            return;
        }

        this.setSessionsType(list);
        for (let ipKey in this.markersMap) {
            if (this.markersMap.hasOwnProperty(ipKey)) {
                marker = this.markersMap[ipKey];
                marker.used = false;
            }
        }

        list.filter(s => s.ua && s.ua.type !== "ROBOT").forEach(session => {
            for (let ip in session.ips) {
                if (session.ips.hasOwnProperty(ip)) {
                    const location = session.ips[ip].location;
                    if (location) {
                        let marker = this.markersMap["ip" + ip];
                        if (!marker) {
                            // noinspection JSUnresolvedVariable
                            // noinspection JSUnresolvedFunction,JSUnresolvedVariable
                            marker = new google.maps.Marker({
                                position: new google.maps.LatLng(location.latitude, location.longitude),
                                map: this.map,
                                icon: getCircle(6, session.refType, session.ips[ip].isMixam)
                            });
                        }
                        marker.used = true;
                        this.markersMap["ip" + ip] = marker;
                        // noinspection JSUnresolvedFunction
                        bounds.extend(marker.position);
                    }
                }
            }
        });

        for (let ipKey in this.markersMap) {
            if (this.markersMap.hasOwnProperty(ipKey)) {
                marker = this.markersMap[ipKey];
                if (!marker.used) {
                    // noinspection JSUnresolvedFunction
                    marker.setMap(null);
                }
            }
        }
        if (!mixam.shop.isManagementConsole) {
            // noinspection JSUnresolvedFunction,JSUnresolvedVariable
            this.shops.forEach(shop => bounds.extend(new google.maps.LatLng(shop.latitude, shop.longitude)));

            const keys = Object.keys(this.markersMap);
            if (keys.length > 0) {
                // noinspection JSUnresolvedFunction
                this.map.setCenter(bounds.getCenter());
                // noinspection JSUnresolvedFunction
                this.map.fitBounds(bounds);
            }
        }
    };

    this.getZoomFactor = function () {
        const width = this.select('canvasSelector').width();
        //console.log("canvas width", width);
        if (width <= 768) {
            return 2;
        }
        if (width <= 1366) {
            return 3;
        }
        return 4;
    };

    this.showWholeWorld = function () {
        const zoom = this.getZoomFactor();
        //console.log("zoom", zoom);

        this.map.maxZoom = null;
        this.map.minZoom = zoom;
        this.map.zoom = zoom;
        const allowedBounds = new google.maps.LatLngBounds(
            new google.maps.LatLng(85, -180),	// top left corner of map
            new google.maps.LatLng(-85, 180)	// bottom right corner
        );

        const k = 5.0;
        const n = allowedBounds.getNorthEast().lat() - k;
        const e = allowedBounds.getNorthEast().lng() - k;
        const s = allowedBounds.getSouthWest().lat() + k;
        const w = allowedBounds.getSouthWest().lng() + k;
        const neNew = new google.maps.LatLng( n, e );
        const swNew = new google.maps.LatLng( s, w );
        const boundsNew = new google.maps.LatLngBounds( swNew, neNew );
        this.map.fitBounds(boundsNew);
        const center = new google.maps.LatLng(36, -12);
        this.map.panTo(center);
    };

    this.subscribe = function () {
        this.trigger("log", {
            message: ["Subscribe to stomp channel santastats"],
            title: "AdminAnalyticsRealtime.subscribe"
        });
        this.stomp = Stomp.client(mixam.stompServiceUrl);
        this.stomp.debug = (...a) => {
            if (a.join('').indexOf('Whoops! Lost connection to') !== -1) {
                setTimeout(() => this.subscribe(), 10);
            }
            this.trigger("log", {message: a, title: "AdminAnalyticsRealtime.subscribe"});
        };

        this.stomp.connect(mixam.stompWebUser, mixam.stompWebPass, () => {
                this.stomp.subscribe("/topic/santastats", (d) => {
                    const p = JSON.parse(d.body);
                    this.trigger("santaStatsReady", p);
                });

            }, err => this.trigger("log", {message: err}),
            '/');
    };

    this.after('initialize', function () {
        this.map = null;
        this.markers = [];
        this.markersMap = {};
        this.on(document, "visitsListRecalculated", this.onVisitsListChange);

        $(() => this.initMap());
        setTimeout(() => {
            AdminAnalyticsUsersMonitor.attachTo(this.select('currentUsersSelector'));
        }, 10);
        AdminReferrerChart.attachTo(this.select('referrerSelector'));
        this.subscribe();
        if (mixam.shop.isManagementConsole) {
            this.on(window, 'resize', this.showWholeWorld);
        }
    });
}
