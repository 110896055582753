import defineComponent from '../../components/flight/lib/component';

// NOTE: these import looks unused, but are very much needed!
import turn from 'turn';
import jqui from 'jqueryui';

export default  defineComponent(PageFlipper);

function getViewNumber(book, page) {
    return parseInt((page || book.turn('page')) / 2 + 1, 10);
}

function PageFlipper() {
    var previewWidth = 115,
        previewHeight = 73;

    this.attributes({
        parentContainer: "",
        orientation: 0,
        width: 0,
        pageWidth: 0,
        pageHeight: 0,
        bookLength: 0,
        page: 0,
        pages: 0,
        direction: "ltr",
        autoScale: false,
        pnumberSelector: '.pnumber span',
        pnumberLeftSelector: '.pnumber.left span',
        pnumberRightSelector: '.pnumber.right span',
        flipbookSelector: '.flipbook',
        holderSelector: '.holder',
        sliderSelector: '[data-type="slider"]',
        togglePreviewSelector: '[data-view]',
        navBtnSelector: '.nav-btn',
        backSelector: '[data-nav="first"], [data-nav="back"]',
        nextSelector: '[data-nav="next"], [data-nav="last"]'
    });

    this.calcHeight = function (width) {
        return width * this.attr.pageHeight / this.attr.pageWidth / 2;
    };

    this.calcDimensions = function (width) {
        var $win = $(window),
            vpw = $win.width(),
            sliderVSpace = vpw >= 768 ? 180 : 100,
            vph = (window.innerHeight || $win.height()) - sliderVSpace,
            height = Math.min(vph, this.calcHeight(width)),
            aspect = this.attr.pageWidth * 2 / this.attr.pageHeight;

        if (this.attr.autoScale) {
            return {
                width: Math.min(height * aspect, vpw),
                height: height
            };
        } else {
            return {
                width: Math.min(vpw, width),
                height: this.calcHeight(width)
            };
        }

    };

    this.resizeViewport = function (event) {
        if (this.timer) {
            clearTimeout(this.timer);
        }
        this.timer = setTimeout(() => {
            var {width, height} = this.calcDimensions(this.$node.closest(this.attr.parentContainer).width()),
                flipDiv = this.select('flipbookSelector');

            try {
                this.select('holderSelector').css("width", width);
                flipDiv.removeClass('animated');
                if (flipDiv.length) {
                    flipDiv.turn('size', width, height);
                    if (flipDiv.turn('page') === 1) {
                        flipDiv.turn('peel', 'br');
                    }
                }
                flipDiv.addClass('animated');
            } catch (ex) {
                this.trigger("log", ex);
            }
        }, 250);
    };

    this.addPreviewElements = function (preview, view, numPages) {
        var pages = [view === 1 ? 1 : (view - 1) * 2];

        if (numPages !== 1) {
            pages.push(pages[0] + 1);
        }

        pages.forEach(page => {
            var source = this.tree.children()[page - 1],
                clone = source && source.cloneNode();

            if (clone) {
                $(clone).css("width", previewWidth / 2 + "px")
                    .css("height", previewHeight + "px")
                    .css("float", page % 2 ? "right" : "left")
                    .css("transform", "none")
                    .css("-webkit-transform", "none")
                    .css("position", "static")
                    .css("margin", "0")
                    .css("padding", "0")
                    .appendTo(preview);


            }
        });
    };

    this.setPreview = function (view) {
        var preview = $(_thumbPreview.children(':first')),
            numPages = (view === 1 || view === +this.select('sliderSelector').slider('option', 'max')) ? 1 : 2,
            width = (numPages === 1) ? previewWidth / 2 : previewWidth;

        _thumbPreview.
            addClass('no-transition').
            css({
                width: width + 15,
                height: previewHeight + 15,
                top: -previewHeight - 30,
                left: ($(this.select('sliderSelector').children(':first')).width() - width - 15) / 2
            });

        preview.css({
            width: width,
            height: previewHeight,
            overflow: "hidden"
        });

        preview.children().remove();
        this.addPreviewElements(preview, view, numPages);

        setTimeout(function () {
            _thumbPreview.removeClass('no-transition');
        }, 0);
    };

    this.moveBar = function (yes) {
        this.select('sliderSelector').find('.ui-slider-handle').css({zIndex: yes ? -1 : 10000});
    };


    this.init = function () {
        var that = this,
            max = this.attr.pages,
            {width, height} = this.calcDimensions(this.attr.width);


        this.select('sliderSelector').slider({
            min: 1,
            max: max,

            start: function (event, ui) {
                if (!window._thumbPreview) {
                    window._thumbPreview = $('<div />', {'class': 'thumbnail'}).html('<div></div>');
                    that.setPreview(ui.value);
                    _thumbPreview.appendTo($(ui.handle));
                } else {
                    that.setPreview(ui.value);
                }
                that.moveBar(false);
            },

            slide: function (event, ui) {
                that.setPreview(ui.value);
            },

            stop: function () {
                if (window._thumbPreview) {
                    _thumbPreview.removeClass('show');
                }
                try {
                    that.select('flipbookSelector').turn('page', Math.min(max * 2 - 2, Math.max(1, $(this).slider('value') * 2 - 2)));
                } catch (ex) {
                    // ilb
                }
            }
        });

        this.select('holderSelector').css("width", width);
        // save the tree for later use by the slider thumbnails
        this.tree = $(this.select('flipbookSelector')[0].cloneNode(true));

        this.select('flipbookSelector').turn({     //displayMode: "single",
            width: width,
            height: height,
            duration: 1000,
            acceleration: true,
            elevation: 50,
            pages: this.attr.pages,
            gradients: true,
            autoCenter: true,
            direction: this.attr.direction,
            when: {
                turning: (event, page, view) => {
                    this.setCurrentPage(page);
                    this.setCurrentView(view);
                    this.trigger("pageFlipTurning", {page, view});
                },
                turned: function (e, page, view) {
                    var book = $(this);

                    that.setCurrentPage(page);
                    that.setCurrentView(view);
                    that.select('sliderSelector').slider('value', getViewNumber(book, page));
                },

                start: function (e, pageObj) {
                    that.moveBar(true);
                },

                end: function (e, pageObj) {
                    var book = $(this);
                    setTimeout(function () {
                        that.select('sliderSelector').slider('value', getViewNumber(book));
                    }, 1);

                    that.moveBar(false);
                }
            }
        });

        if (this.attr.page) {
            requestAnimationFrame(() => this.select('flipbookSelector').turn('page', this.attr.page));
        }
        this.on(window, 'resize', $.proxy(this.resizeViewport, this));
        this.on(window, 'orientationchange', $.proxy(this.resizeViewport, this));
    };

    this.setCurrentView = function (view) {
        this.select('pnumberLeftSelector').text(view[0] || "")[view[0] ? "removeClass" : "addClass"]("hidden");
        this.select('pnumberRightSelector').text(view[1] || "")[view[1] ? "removeClass" : "addClass"]("hidden");
    };


    this.setCurrentPage = function (page) {
        if (page > 1) {
            this.select("backSelector").prop("disabled", false);
        } else {
            this.select("backSelector").prop("disabled", true);
        }
        if (page < this.attr.bookLength) {
            this.select("nextSelector").prop("disabled", false);
        } else {
            this.select("nextSelector").prop("disabled", true);
        }
    };

    this.doClick = function (event) {
        var $target = $(event.target).closest("button"),
            p = this.select('flipbookSelector').turn('page'),
            dir = $target.data("nav"),
            targetPage = 1,
            max = this.attr.pages;

        switch (dir) {
            case "back" :
                targetPage = p - (p % 2) - 1;
                break;
            case "next" :
                targetPage = p + (p % 2 ? 0 : 1) + 1;
                break;
            case "last" :
                targetPage = max * 2 - 2;
                break;
        }

        targetPage = Math.min(Math.max(targetPage, 1), max * 2 - 2);
        this.select('flipbookSelector').turn('page', targetPage);
    };

    this.after('initialize', function () {
        setTimeout($.proxy(this.init, this), 16);
        this.on("click", {
            navBtnSelector: this.doClick
        });

    });

}
