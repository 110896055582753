import defineComponent from '../../../../../components/flight/lib/component';
import counterDisplay from './counter-display';
import Chartjs from '../../../../../components/Chart.js';

export default defineComponent(AdminReferrerChart);

function AdminReferrerChart() {
    const COUNTER_NAME = "page-visits",
        MARKER_COLORS = {
            paid: "#F7464A",
            direct: "#46BFBD",
            referral: "#c971c8",
            organic: "#468ABF",
            mixam: "#FDB45C",
            social: "#949FB1",
            brand: "#2f43f7",
            shahe: '#6A29BF',
            embed: 'rgba(106, 41, 191, 0.66)'
        },
        MARKER_COLORS_HIGHLIGHT = {
            paid: "#FF5A5E",
            direct: "#5AD3D1",
            referral: "#c971c8",
            organic: "#5D9ED1",
            mixam: "#FDB45C",
            social: "#A8B3C5",
            brand: "#0015f7",
            share: '#491A88',
            embed: '#6A29BF'
        };


    this.attributes({
        chartSelector: '.referrer-chart',
        clipperSelector: '.donat-clipper',
        counterSelector: '.realtime-counter .count',
        currentUsersSelector: '#currentUsers',
        legendSelector: 'li',
        legenddirectSelector: 'li.direct',
        legendorganicSelector: 'li.organic',
        legendreferralSelector: 'li.referral',
        legendpaidSelector: 'li.paid',
        legendmixamSelector: 'li.mixam',
        legendbrandSelector: 'li.brand',
        legendsocialSelector: 'li.social'
    });

    this.countVisits = function (visits) {
        let countVisits = 0;
        const counts = {};

        this.types.forEach(series => counts[series.toLowerCase()] = 0);

        visits.forEach(session => {
            if (session.user && (session.user.type === "customer" || session.user.type === "share")) {
                let key = session.refType;
                if (session.refType === "share" || session.refType === "embed") {
                    key = "referral";
                }
                counts[key || "direct"] += 1;
                countVisits += 1;
            }
        });

        this.trigger("updateCounter", {
            counterId: COUNTER_NAME,
            value: countVisits
        });
        document.title = countVisits + " users | Realtime mixam | Mixam Print";
        return countVisits ? counts : null;
    };

    this.addVisits = function (visits) {
        const totals = this.countVisits(visits);

        if (totals) {

            for (let series in totals) {
                //noinspection JSUnfilteredForInLoop
                /** @namespace this.chart.segments */
                this.chart.segments[this.index[series]].value = totals[series];
            }
            this.select('clipperSelector').addClass("in");
            this.chart.update();
        } else {
            this.select('clipperSelector').removeClass("in");
        }
        this.updateLegend(totals);
    };

    this.updateLegend = function (totals) {
        this.select('legendSelector').addClass("hidden");
        if (totals) {
            for (let series in totals) {
                //noinspection JSUnfilteredForInLoop
                const count = totals[series];
                if (count) {
                    this.select('legend' + series + 'Selector').removeClass("hidden")
                        .find(".type-count").text(count);
                }
            }
        }
    };

    this.init = function () {
        const ctx = this.select('chartSelector').get(0).getContext("2d");

        this.index = {};
        /** @namespace Chartjs.Doughnut */
        this.chart = new Chartjs(ctx).Doughnut(this.types.map((type, index) => {
            const typelc = type.toLowerCase();
            this.index[typelc] = index;
            return {
                value: 1,
                color: MARKER_COLORS[typelc],
                highlight: MARKER_COLORS_HIGHLIGHT[typelc],
                label: type
            };
        }));
    };

    this.onVisitsListChange = function (event, data) {
        //console.log('onVisitsListChange', data);
        setTimeout(() => this.addVisits(data.visits), 10);
    };

    this.after('initialize', function () {
        this.types = "Direct,Organic,Referral,Paid,Social,Mixam,Brand".split(",");
        this.data = [];
        this.on(document, "visitsListRecalculated", this.onVisitsListChange);

        counterDisplay.attachTo(this.attr.counterSelector, {counterId: COUNTER_NAME});
        this.init();
    });
}
