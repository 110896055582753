import mixam from '../../boot/mixam';
import defineComponent from '../../../components/flight/lib/component';

// NOTE: this import looks unused, but it's very much needed!
import utils from '../../../components/flight/lib/utils';

export default defineComponent(FixedDisplay);
const ALIGN_TOP_CLASS_NAME = 'align-top';
const ALIGN_BOTTOM_CLASS_NAME = 'align-bottom';
function FixedDisplay() {

    this.attributes({
        mainSantaFrameSelector: '#santa-main-frame',
        displaySelector: ".display-panel",
        fixedDisplaySelector: ".display-panel > div > div"
    });


    this.afterScroll_ = function (evend, data) {
        if ( this.suspend) {
            return;
        }
        const top = data.offset;
        this.height = this.$node.height();

        let state = 0;
        let display = {};
        if (this.needFixed(top)) {
            display = this.getDisplayDimensions();
            const santaHeight = this.$santaFrame.height();
            if (display.height < santaHeight) {
                const intersection = this.offset.top + this.height - top;
                if (intersection < display.height) {
                    state = 2;
                    this.offsetY =  intersection - display.height;
                } else {
                    state = 1;
                    this.offsetY = 0;
                }
            }
        }
        //console.log(state, top, this.height, this.offsetY, display.height);
        this.addClassName(state, display);
    };


    this.addClassName_ = function (state, display) {
        const $display = this.select('displaySelector');
        const $fixedDisplay = this.select('fixedDisplaySelector');

        if (state === 0) {
            if ($display.hasClass(ALIGN_BOTTOM_CLASS_NAME)) {
                $display.removeClass(ALIGN_BOTTOM_CLASS_NAME).css('width', '');
            }
            if ($fixedDisplay.hasClass(ALIGN_TOP_CLASS_NAME)) {
                $fixedDisplay.removeClass(ALIGN_TOP_CLASS_NAME).css('width', '').css("-webkit-transform", '');
            }
        } else if (state === 1) {
            if ($display.hasClass(ALIGN_BOTTOM_CLASS_NAME)) {
                $display.removeClass(ALIGN_BOTTOM_CLASS_NAME).css('width', '');
            }
            if (!$fixedDisplay.hasClass(ALIGN_TOP_CLASS_NAME)) {
                $fixedDisplay.addClass(ALIGN_TOP_CLASS_NAME).css('width', display.width + 'px').css("-webkit-transform", '');
            }
        } else if (state === 2) {
            if ($fixedDisplay.hasClass(ALIGN_TOP_CLASS_NAME)) {
                $fixedDisplay.removeClass(ALIGN_TOP_CLASS_NAME).css('width', '');
            }
            if (!$display.hasClass(ALIGN_BOTTOM_CLASS_NAME)) {
                $display.addClass(ALIGN_BOTTOM_CLASS_NAME).css('width', '');
            }
        }
    };


    this.getDisplayDimensions = function () {
        const $display = this.select('fixedDisplaySelector');
        return {
            height: $display.height(),
            width: $display.width() + 2
        };
    };

    this.needFixed = function (top) {
        return top > this.offset.top && (this.offset.top + this.height) > top;
    };

    this.afterResize = function (evend, data) {
        if (window.matchMedia("(max-width: 767px)").matches) {
            this.suspend = true;
            this.addClassName(0);
            return;
        }
        this.suspend = false;
        this.offset = this.$node.offset();
        this.height = this.$node.height();
        this.screenHeight = $(window).height();
    };

    this.doResize = function (evend, data) {
        setTimeout(() => this.afterResize(), 0);
    };

    this.after('initialize', function () {
        if (!mixam.supportPositionSticky) {
            this.addClassName = this.addClassName_;//utils.throttle(this.addClassName_, 220);
            this.afterScroll = this.afterScroll_;// utils.throttle(this.afterScroll_, 120);
            this.$santaFrame = $(this.attr.mainSantaFrameSelector);
            //this.on(document, 'uiPageScroll', utils.debounce(this.afterScroll, 20));
            this.on(document, 'uiPageScroll', this.afterScroll);
            this.on(window, 'resize', this.afterResize);
            this.on(document, 'shown.bs.collapse', this.afterResize);
            this.on(document, 'hidden.bs.collapse', this.afterResize);
            this.on(document, 'sceneAfterRoute', this.afterResize);
            this.doResize();
        }
    });
}


