import React from 'react';
import SearchBuilder from './components/search/SearchBuilder';
import GlobalSearch from './components/search/GlobalSearch';
import DataExporter from './components/DataExporter';
var DataTableToolbar = function (_a) {
    var columns = _a.columns, unfilteredData = _a.unfilteredData, setSearchBuilderData = _a.setSearchBuilderData, searchBuilderData = _a.searchBuilderData, setGlobalSearchData = _a.setGlobalSearchData;
    return (React.createElement("div", { className: "d-flex", style: { gap: '0.5rem' } },
        React.createElement(SearchBuilder, { data: unfilteredData, columns: columns, setFilteredData: setSearchBuilderData }),
        React.createElement(GlobalSearch, { data: searchBuilderData, setFilteredData: setGlobalSearchData }),
        React.createElement(DataExporter, { data: searchBuilderData, columns: columns })));
};
export default DataTableToolbar;
