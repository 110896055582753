import WithStompClient from './with-stomp';

import defineComponent from '../../../components/flight/lib/component';

export default defineComponent(RecruitTester, WithStompClient);

function RecruitTester() {


    this.attributes({
        showAnswerSelector: '.show-answer'
    });

    this.send = function (data) {
        this.stomp.send(this.topic, {}, JSON.stringify(data, null, 4));
    };



    this.updateAnswer = function(data) {
        console.log(data);


    };


    this.showAnswer = function (event) {
        const $button = $(event.target).closest('button');
        const $dd = $button.closest('dd');
        const $answer = $dd.next('dd');
        const target = $button.data('target');
        //console.log(target, $answer.html());
        this.send({
            target: target,
            answer: $answer.html()
        });
    };

    this.after('initialize', function () {
        this.id = this.$node.data('id');
        this.topic = `/topic/recruit${this.id}`;

        this.on('click', {
            showAnswerSelector: this.showAnswer
        });

        try {
            this.subscribe(x => this.updateAnswer(x));
        } catch (e) {

        }
    });
}
