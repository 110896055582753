import defineComponent from '../../../components/flight/lib/component';

export default defineComponent(DigitalWalletManager);

function DigitalWalletManager() {

    this.attributes({
        intentUrl: '/api/v3/stripe/paymentintent/create',
        resultUrl: 'orders/{orderId}/payment/stripe',
        secondaryFormSelector: '[data-type="pay-digital-wallet-secondary-form"]',
        paymentButtonContainer: "#digital-wallet-payment-request-button",
        errorMessageSelector: '.digital-wallet-error',
        loadingSelector: ".wait",
        paymentOptionSelector: '[data-type="digital-wallet-pay-option"]',
        paymentOptionBoxSelector: '[data-type="digital-wallet-box"]',
    });

    this.initPayWithDigitalWallet = function () {

        const elements = this.stripe.elements();

        const paymentRequest = this.stripe.paymentRequest({
            country: this.country,
            currency: this.currency,
            total: {
                label: 'Mixam ' + this.country,
                amount: Math.round(this.amount * 100),
            },
            requestPayerName: true,
            requestPayerEmail: true
        });

        this.paymentButton = elements.create('paymentRequestButton', {
            paymentRequest,
        });

        paymentRequest.canMakePayment().then(result => {
            if (result) {
                $(this.attr.paymentOptionSelector).removeClass('hidden');
                this.paymentButton.mount('#digital-wallet-payment-request-button');
            }
        });

        this.paymentButton.addEventListener('click', (event) => this.fireIntent());
        paymentRequest.addEventListener('paymentmethod', (event) => this.handlePayment(event));

    };

    this.fireIntent = function (event) {
        $.post(this.attr.intentUrl, {orderId: this.order.id}, null, 'json').
        done(response => {
            this.intentSecret = response.itemId;
        }).
        fail(response => {
            this.trigger("log", response);
        });
    };

    this.handlePayment = function(event) {
        this.stripe.confirmCardPayment(
            this.intentSecret,
            {payment_method: event.paymentMethod.id},
            {handleActions: false}
        ).then(confirmResult => {
            if (confirmResult.error) {
                event.complete('fail');
            } else {
                event.complete('success');
                console.log(confirmResult);
                this.select('loadingSelector').show();
                this.select('paymentButtonContainer').hide();
                this.timer = setTimeout(() => {
                    this.submitOrderPayment();
                }, 10000); // allow webhook to finish before submitting
            }
        });
    };

    this.submitOrderPayment = function () {
        if (this.timer) {
            clearTimeout(this.timer);
            this.timer = null;
        }
        this.select('secondaryFormSelector').submit();
    };

    this.setOrder = function(event, order) {
        this.order = order;
    };

    this.after('initialize', function () {
        this.on(document, "setOrderData", this.setOrder);
        this.on(document, "uiRequestPaymentSubmit", this.submitOrderPayment);
        this.key = this.$node.data('key');
        this.amount = this.$node.data('amount');
        this.currency = this.$node.data('currency');
        this.country = this.$node.data('country');
        setTimeout(() => {
            if (typeof window.Stripe !== 'undefined') {
                this.stripe = Stripe(this.key);
                this.initPayWithDigitalWallet();
            }
        }, 500);
    });
}
