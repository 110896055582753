import mixam from '../../boot/mixam';
import WithSubmitAnimation from './../with-submit-zoom-animation';
import defineComponent from '../../../components/flight/lib/component';

export default defineComponent(PhotoUploadManager, WithSubmitAnimation);

function PhotoUploadManager() {

    this.attributes({
        uploadSelector: '[data-type="upload-input"]',
        uploadManagerSelector: '[data-type="photo-upload-manager"]',
        progressSelector: '[data-type="progress-bar"]',
        progressBarSelector: '[data-type="progress-bar"] .bar',
        resetSelector: '[data-type="reset-image"]',
        resetBtnSelector: '[data-action="btn-image-reset"]',
        collapseUploadSelector: '#collapseUploadImage',
        collapseResetSelector: '#collapseResetImage',
    });

    this.add = function (file) {
        const o = file;
        o.fileSize = file.size / 1000;
        o.date = mixam.dateToLongString(o.lastModified);
        o.canRemove = 1;
        this.progress(0);
    };

    this.uploadStart = function (event) {
        this.select('uploadSelector').simpleUpload(this.uploadUrl, {
            expect: 'json',
            start: file => this.add(file),
            progress: progress => this.progress(progress),
            success: response => this.uploadSuccess(response),
            error: error => this.trigger("log", error)
        });
    };

    this.progress = function (percent) {
        const progress = Math.round(percent);

        if (progress > 0) {
            this.select('progressSelector').removeClass('hidden');
        } else {
            this.select('progressSelector').addClass('hidden');
        }

        this.select('progressBarSelector').css(
            'width',
            progress + '%'
        );
    };

    this.uploadSuccess = function (response) {
        if ("success" === response.status) {
            this.progress(0);
            document.querySelector('[data-type="user-photo"]').style.backgroundImage = `url(${response.reason})`;
            this.select('collapseUploadSelector').collapse('hide');
            $('[data-action="btn-image-reset"]').removeClass('hidden');
        }
    };

    this.resetIcon = function (event) {
        this.handleSubmit(event);
        $.post(this.resetUrl, null, null, 'json')
            .done(response => {
                document.querySelector('[data-type="user-photo"]').style.backgroundImage = `url(${response.reason})`;
                this.resetAnimation();
                $('[data-action="btn-image-reset"]').addClass('hidden');
                this.select('collapseResetSelector').collapse('hide');
            })
            .fail((err) => {
                this.resetAnimation();
                this.trigger("log", {message: err});
            });
    };

    this.after('initialize', function () {
        this.uploadUrl = this.$node.data('uploadUrl');
        this.resetUrl = this.$node.data('resetUrl');
        this.on("change", {
            uploadSelector: this.uploadStart
        });
        this.on("click", {
            resetSelector: this.resetIcon
        });
    });
}
