/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { SalesHistory } from './SalesHistory';
import ReactDom from 'react-dom/client.js';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import reactToWebComponent from 'react-to-webcomponent';
import { StyleSheetManager } from 'styled-components';
var styles = require('./bs-global-styles.css').toString();
var bootstrapLink = '<link ' +
    'rel="stylesheet" ' +
    'href="https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/css/bootstrap.min.css" ' +
    'integrity="sha384-rbsA2VBKQhggwzxH7pPCaAqO46MgnOM80zW1RWuH61DGLwZJEdK2Kadq2F9CUG65" ' +
    'crossorigin="anonymous" ' +
    '/>';
;
var SalesHistoryStyleWrapper = function (_a) {
    var memberid = _a.memberid, locale = _a.locale;
    // Do not remove this state.
    // For some strange reason, styled-component's StyleSheetManager needs react state to be set within the same component for it to render the styles.
    var _b = useState(false), _ = _b[0], setPointlessState = _b[1];
    useEffect(function () {
        setPointlessState(true);
    }, []);
    var refContainer = useRef(null);
    var hostStyles = useMemo(function () { return styles.replace(':root', ':host'); }, [styles]);
    return (React.createElement(StyleSheetManager, { target: refContainer.current },
        React.createElement(React.Fragment, null,
            React.createElement("style", null, hostStyles),
            React.createElement("div", { dangerouslySetInnerHTML: { __html: bootstrapLink } }),
            React.createElement("div", { ref: refContainer }),
            React.createElement(SalesHistory, { memberId: memberid, locale: locale }))));
};
SalesHistoryStyleWrapper.propTypes = {
    memberid: PropTypes.string,
    locale: PropTypes.string,
};
export var initialiseSalesHistory = function () {
    window.customElements.define('sales-history', reactToWebComponent(SalesHistoryStyleWrapper, React, ReactDom, {
        shadow: 'open',
    }));
};
