import defineComponent from '../../../components/flight/lib/component';

export default defineComponent(KlaviyoWebTracking);
const JS_URL = "https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=";

function KlaviyoWebTracking() {

    this.initWebTracking = function() {
        const scriptUrl = `${JS_URL}${this.companyId}`;
        $.getScript(scriptUrl);
    };

    this.after('initialize', function () {
        this.companyId = $(this.node).data('company-id');
        this.initWebTracking();
    });
}
