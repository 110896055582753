import WithSubmitAnimation from '../../with-submit-zoom-animation';

import defineComponent from '../../../../components/flight/lib/component';

export default defineComponent(AmazonPayWidget, WithSubmitAnimation);

//const TEST_SCRIPT_URL = "https://static-eu.payments-amazon.com/OffAmazonPayments/uk/sandbox/lpa/js/Widgets.js";
const SCRIPT_URL = "https://static-eu.payments-amazon.com/OffAmazonPayments/gbp/lpa/js/Widgets.js";

function AmazonPayWidget() {

    this.attributes({
        amazonBtnSelector: '.amazon-pay-button',
        amazonWidgetSelector: '.amazon-pay-widget',
        accessTokenSelector: '[data-type="access-token"]',
        orderReferenceIdSelector: '[data-type="order-reference-id"]',
        widgetFormSelector: 'form.amazon-express-checkout',
        saveBtnSelector: 'button[type="submit"]',
    });

    this.AmazonLoginReady = function (/*event*/) {
        // noinspection JSUnresolved Function,JSUnresolvedVariable
        amazon.Login.setClientId(this.clientId);
    };

    this.AmazonPaymentsReady = function (/*event*/) {
        this.showPayButton();
    };

    this.showPayButton = function () {
        // noinspection JSUnresolvedFunction
        OffAmazonPayments.Button("AmazonPayWidgetButton", this.merchantId, {
            type: "PwA",
            color: "Gold",
            size: "medium",
            language: this.language,

            authorization: () => {
                const loginOptions = { scope: "profile payments:widget payments:shipping_address payments:billing_address", popup: true };
                // noinspection JSUnresolvedFunction
               amazon.Login.authorize(loginOptions,  (t) => {
                    this.accessToken = t.access_token;
                    this.showWidget();
                });
            },
            onError: (error) => {
                const errorText = `The following error occurred: ${error.getErrorCode()} - ${error.getErrorMessage()}`;
                this.trigger('showFixedMessage', {type: 'error', body: errorText, interval: 20000});
            }
        });
    };

    this.showWidget = function () {
        this.select('amazonWidgetSelector').removeClass('hidden');
        this.select('amazonBtnSelector').addClass('hidden');

        // noinspection JSUnresolvedFunction
        new OffAmazonPayments.Widgets.Wallet({
            sellerId:  this.merchantId,

            onReady: (orderReference) => {
                this.orderReferenceId = orderReference.getAmazonOrderReferenceId();
            },
            design: {
                designMode: 'responsive'
            },
            onError: function(error) {
                // Error handling code
                // We also recommend that you implement an onError handler in your code.
                // @see https://payments.amazon.com/documentation/lpwa/201954960
                // noinspection JSUnresolvedFunction
                const errorText = `The following error occurred: ${error.getErrorCode()} - ${error.getErrorMessage()}`;
                this.trigger('showFixedMessage', {type: 'error', body: errorText, interval: 20000});
            }
        }).bind("amazonPayWidgetDiv");
    };

    this.doSubmit = function (event) {
        this.handleSubmit(event); // start animation
        this.select('accessTokenSelector').val(this.accessToken);
        this.select('orderReferenceIdSelector').val(this.orderReferenceId);
    };

    this.after('initialize', function () {
        this.clientId = $("#amazonClientId").val();
        this.language = $("#amazonLanguage").val();
        this.merchantId = $("#amazonMerchantId").val();
        this.orderId = $("#orderId").val();

        if (!window.onAmazonLoginReady) {
            window.onAmazonLoginReady = (event) => this.AmazonLoginReady(event);
            window.onAmazonPaymentsReady = (event) => this.AmazonPaymentsReady(event);
            this.on('submit', {
                widgetFormSelector: this.doSubmit
            });
           // require([SCRIPT_URL]);
        }
    });
}
