import defineComponent from '../../../components/flight/lib/component';

export default defineComponent(AdminHeader);

let navOpen = false;
function AdminHeader() {
    this.after('initialize', function () {
        window.addEventListener('navbar-toggler-event', () => {
            navOpen = !navOpen;
            if (navOpen) {
                document.getElementById("admin-navbar").style.position = "relative";
            } else {
                document.getElementById("admin-navbar").style.position = "fixed";
            }
        });
    });
}