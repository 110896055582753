import mixam from '../../boot/mixam';
import defineComponent from '../../../components/flight/lib/component';
import DataTable from '../table/data-table-net/data-table-net.tsx';
import WithOrdersItemsMembers from './with-orders-items-members';
import WithOrdersOrders from './with-normalized-order';
import AnimatedCheckbox from '../animated/animated-checkbox';
import WithSignDataRequest from '../with-sign-data-request';
import { Breakpoints } from "../table/data-table-net/StyledDataTableNet.tsx";

export default defineComponent(WithOrdersItemsMembers, WithOrdersOrders, AdminOrdersManager, WithSignDataRequest);

function AdminOrdersManager() {

    this.attributes({
        firstTimeUrl: `${mixam.reporterOrigin}/reporter/admin/api/shop/${mixam.shop.id}/orders/list/first`,
        url: `${mixam.reporterOrigin}/reporter/admin/api/shop/${mixam.shop.id}/orders/list`,
        filterToggleSelector: '[data-type="filter-toggle"]',
        popoverSelector: '[data-toggle="popover"]',
        dataTableSelector: '[data-type="data-table"]',
        animatedCheckboxSelector: '[data-toggle="animated-checkbox"]'
    });

    this.error = function (err) {
        if (console) {
            console.log(err);
        }
    };

    this.createSchema = function () {
        var cols = [];

        cols.push({
            title: "",
            type: "thumbnail",
            data: "front"
        });
        cols.push({
            title: "Order",
            type: "link",
            data: "href"
        });
        cols.push({
            title: "Created",
            type: "timebox",
            data: "time"
        });
        cols.push({
            title: "Modified",
            type: "timebox",
            data: "lastModifiedDate"
        });
        cols.push({
            title: "Username",
            type: "user",
            data: "user",
            hide: Breakpoints.LG
        });
        cols.push({
            title: "Groups",
            type: "list",
            data: "customerGroupsList",
            hide: Breakpoints.LG,
            width: '150px'
        });
        cols.push({
            title: "Status",
            type: "badge",
            data: "statusBadge",
            hide: Breakpoints.LG,
            width: '150px'
        });
        cols.push({
            title: "Confirmed",
            type: "timebox",
            data: "confirmDate",
            hide: Breakpoints.LG
        });
        cols.push({
            title: "Split Delivery",
            type: "boolean",
            data: "splitDelivery",
            hide: Breakpoints.LG
        });
        cols.push({
            title: "Reprint",
            type: "boolean",
            width: "5%",
            data: "reprint"
        });
        cols.push({
            title: "Sample Test",
            type: "boolean",
            data: "freeTest",
            hide: Breakpoints.XL
        });
        cols.push({
            title: "Express",
            type: "boolean",
            data: "isHasExpress",
            hide: Breakpoints.XL
        });
        cols.push({
            title: "Proof",
            type: "boolean",
            data: "proofProxy",
            "trueIcon": "fa fa-file-image-o",
            hide: Breakpoints.XL
        });
        cols.push({
            title: "Type",
            type: "order-type",
            data: "orderType",
            hide: Breakpoints.XL
        });
        cols.push({
            title: "Artwork",
            type: "artwork",
            data: "artwork",
            "sortable": false,
            hide: Breakpoints.XL
        });
        cols.push({
            title: "Settled",
            type: "boolean",
            data: "settled",
            hide: Breakpoints.XXL
        });
        cols.push({
            title: "# Items",
            type: "number",
            data: "itemCount",
            hide: Breakpoints.XXL
        });
        cols.push({
            title: "Handled",
            type: "text",
            data: "handleBy",
            hide: Breakpoints.XXL
        });
        cols.push({
            title: "Remarks",
            type: "remarks",
            data: "remarkData",
            width: "150px",
            hide: Breakpoints.XXL,
        });
        cols.push({
            title: "Promised",
            type: "date",
            data: "expectedDate",
            hide: Breakpoints.XXL
        });
        cols.push({
            title: "Sum",
            type: "number",
            data: "total",
            sum: true,
            currency: true,
            decimal: 2,
            prepend: mixam.shop.currency.prefix
        });
        cols.push({
            title: "Value",
            type: "number",
            data: "value",
            sum: true,
            currency: true,
            decimal: 2,
            prepend: mixam.shop.currency.prefix
        });
        return cols;
    };

    this.getData = function () {
        requestAnimationFrame(() => this.signDataRequest(token => this.getReportData(token)));
    };

    this.getReportData = function (token) {
        const url = (this.hasConnection ? this.attr.url : this.attr.firstTimeUrl);
        $.ajax({url: url, dataType: 'json', headers: {'Authorization': `Bearer ${token}`}})
            .done(data => {
                this.data = data;
                this.paint();
                if (this.hasConnection) {
                    setTimeout(() => this.getData(), 1000 * 60 * 15);
                } else {
                    this.hasConnection = true;
                    setTimeout(() => this.getData(), 1000 * 5);
                }
            })
            .fail(err => this.trigger("log", {message: err}));
    };

    this.paint = function () {
        const data = {
            columns: this.createSchema(),
            fileName: this.attr.url.split("/").pop(),
            response: this.normalize(this.data),
            "class": 'table-striped table-order-list',
            "default-sort": {
                "lastModifiedDate": -1
            }
        };

        if (this.dataReadyFired) {
            window.dispatchEvent(new CustomEvent('uiDataUpdate', { detail: data }));
        } else {
            this.dataReadyFired = true;
            this.trigger("uiDataReady", data);
            this.clock();
        }
    };

    this.updateOrderLine = function (data) {
        if (!this.data) {
            return;
        }
        if (this.isRelevantMessage(data)) {
            const order = this.normalizeLine(data.order);
            const index = this.data.map(x => x.id).indexOf(order.id);

            if (index !== -1) {
                this.data[index] = order;
                // console.log("update index", index, this.data);
            } else {
                this.data.unshift(order);
                //console.log("update first", this.data);
            }
            this.paint();

            window.dispatchEvent(new CustomEvent('highlightDataTableRow', {detail: {rowToHighlightId: order.id}}));
        }

    };

    this.after('initialize', function () {

        DataTable.attachTo(this.select('dataTableSelector'));

        AnimatedCheckbox.attachTo(this.select('animatedCheckboxSelector'));

        this.on(document, "click.ask.for.notify.permissions", this.askForNotifyPermissions);

        setTimeout(() => this.getData(), 10);
        try {
            this.subscribe(data => this.updateOrderLine(data));
        } catch (e) {

        }
    });
}
