import defineComponent from '../../../components/flight/lib/component';

export default defineComponent(FrontAppLiveChat);

function FrontAppLiveChat() {

    this.attributes({

    });

    this.initializeChat = function() {

        const chatInitObject = {
            chatId: `${this.chatId}`,
            useDefaultLauncher: true
        };

        if(this.userHash) {
            chatInitObject.email = this.email;
            chatInitObject.userHash = this.userHash;
            chatInitObject.name = this.name;
        }

        window.FrontChat('init', chatInitObject);
    };

    this.initializeTalkToAHumanClick = function() {
        window.addEventListener('message', (event) => {
            if(event.data.action === 'talk_to_human') {
                $('.chat-bubble').hide();
                window.FrontChat('show');
            }
        });
    };

    this.after('initialize', function () {
        this.chatId = $(this.node).data('chat-id');
        this.chatGptWidgetEnabled = $(this.node).data('chat-gpt-widget-enabled');
        this.userHash = $(this.node).data('user-hash');
        this.name = $(this.node).data('name');
        this.email = $(this.node).data('email');
        this.initializeChat();
        if (this.chatGptWidgetEnabled) {
            this.initializeTalkToAHumanClick();
        }
    });
}
