import defineComponent from '../../../../components/flight/lib/component';
import DataTable from './../../table/data-table-net/data-table-net.tsx';
import {Breakpoints} from "../../table/data-table-net/StyledDataTableNet.tsx";

export default defineComponent(CustomerManager);

function CustomerManager() {

    this.attributes({
        dataTableSelector: '[data-type="data-table"]'
    });

    this.createSchema = function () {
        var cols = [];

        cols.push({
            title: "Name",
            type: "link",
            data: "name",
            width: '200px'
        });
        cols.push({
            title: "Email",
            type: "link",
            data: "email",
            width: '300px'
        });
        cols.push({
            title: "Company",
            type: "text",
            data: "company"
        });
        cols.push({
            title: "Created",
            type: "timebox",
            data: "createdDate",
            defaultSort: true,
            sortOrder: 'desc',
            hide: Breakpoints.MD
        });
        return cols;
    };

    this.normalize = function (list) {
        return list.map(member => this.normalizeMember(member));
    };

    this.normalizeMember = function (member) {
        const result = {
            id: member.id,
            name: {
                href: member.memberId ? `/member/${member.id}` : null,
                caption: (`${member.name || ""}`).trim(),
                title: `${member.name || ""}`
            },
            email: {
                href:  member.memberId ? `/member/${member.id}` : null,
                caption: (member.email || "").trim(),
                title: `${member.name || ""}`
            },
            company: member.company,
            createdDate: member.dateCreated
        };
        return result;
    };

    this.getData = function () {
        $.getJSON(`/api/admin/customer-groups/${this.groupId}/customers`)
            .done(data => {
                this.data = data;
                this.paint();
            });
    };

    this.paint = function () {
        const data = {
            columns: this.createSchema(),
            response: this.normalize(this.data)
        };
        if (this.dataReadyFired) {
            window.dispatchEvent(new CustomEvent('uiDataUpdate', { detail: data }));
        } else {
            this.dataReadyFired = true;
            this.trigger("uiDataReady", data);
        }
    };

    this.after('initialize', function () {
        this.groupId = this.$node.data('groupId');
        DataTable.attachTo(this.select('dataTableSelector'));
        setTimeout(() => this.getData(), 100);
    });
}