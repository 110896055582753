import defineComponent from '../../../../components/flight/lib/component';
import DataTable from '../../table/data-table-net/data-table-net.tsx';
import axios from "axios";

export default defineComponent(AdminPublisherItemSales);

function AdminPublisherItemSales() {

    this.attributes({
        publisherSalesAggregateUrl: '/admin/print-on-demand/publisher/sales/list',
        dataTableSelector: '[data-type="data-table"]',
        aggregateYearSelect: '[data-type="aggregate-year"]',
        aggregateMonthSelect: '[data-type="aggregate-month"]'
    });

    this.createSchema = function () {
        const cols = [];

        cols.push({
            title: '',
            type: 'thumbnail',
            width: '4%',
            data: 'thumbnail'
        });

        cols.push({
            title: 'Publisher',
            type: 'link',
            data: 'publisherLink'
        });

        cols.push({
            title: 'Publisher Email',
            type: 'text',
            data: 'email'
        });

        cols.push({
            title: "Groups",
            type: "list",
            data: "customerGroupsList",
            width: '150px'
        });

        cols.push({
            title: 'Stripe Connect Account',
            type: 'text',
            data: 'stripeConnectAccount'
        });

        cols.push({
            title: 'Year',
            type: 'number',
            data: 'year',
        });

        cols.push({
            title: 'Month',
            type: 'number',
            data: 'month'
        });

        cols.push({
            title: 'Order',
            type: 'link',
            data: 'orderLink'
        });

        cols.push({
            title: 'Customer Name',
            type: 'text',
            data: 'customerName'
        });

        cols.push({
            title: 'Publication Title',
            type: 'text',
            data: 'title'
        });

        cols.push({
            title: 'Copies',
            type: 'number',
            data: 'copies'
        });

        cols.push({
            title: 'Total',
            type: 'number',
            data: 'total',
            decimal: 2,
            sum: true,
            currency: true
        });

        cols.push({
            title: 'Cost',
            type: 'number',
            data: 'cost',
            decimal: 2,
            sum: true,
            currency: true
        });

        cols.push({
            title: 'Commission',
            type: 'number',
            data: 'commission',
            decimal: 2,
            sum: true,
            currency: true
        });

        cols.push({
            title: 'Margin',
            type: 'number',
            data: 'value',
            decimal: 2,
            sum: true,
            currency: true
        });

        cols.push({
            title: 'Fuflilment Date',
            type: 'timebox',
            data: 'fulfilmentDate',
            defaultSort: true,
            sortOrder: 'desc'
        });

        cols.push({
            title: 'Supplier',
            type: 'text',
            data: 'supplierName'
        });

        cols.push({
            title: 'Machine',
            type: 'text',
            data: 'machineName'
        });

        cols.push({
            title: 'Status',
            type: 'text',
            data: 'orderStatus'
        });

        return cols;
    };

    this.paint = function () {
        const data = {
            columns: this.createSchema(),
            response: this.normalize(this.data)
        };

        if (this.dataReadyFired) {
            window.dispatchEvent(new CustomEvent('uiDataUpdate', { detail: data }));
        } else {
            this.dataReadyFired = true;
            this.trigger("uiDataReady", data);
        }
    };

    this.getAggregateData = function (event) {

        const year = Number(this.select('aggregateYearSelect').val());
        const month = Number(this.select('aggregateMonthSelect').val());

        axios.post(this.attr.publisherSalesAggregateUrl, {year: year, month: month})
            .then((response) => {
                this.data = response.data;
                this.paint();
            }).catch(error => {
                alert(`Failed To Aggregate Data: ${error}`);
            });
    };

    this.normalize = function (list) {
        return list.flatMap((salesAggregate) => {
            return salesAggregate.items.map(item => this.normalizeItem(salesAggregate, item));
        });
    };

    this.normalizeItem = function (salesAggregate, item) {
        const result = $.extend(true, {}, salesAggregate, item);

        result.id = item.itemId;
        result.publisherLink = {
            href: `/member/${salesAggregate.publisher.id}`,
            title: salesAggregate.publisher.name,
            caption: salesAggregate.publisher.name,
            target: '_blank'
        };

        result.orderLink = {
            href: `/orders/${item.orderId}`,
            title: item.caseNumber,
            caption: item.caseNumber,
            target: '_blank'
        };

        result.thumbnail = {
            src: item.thumbnailUrl,
            href: `/orders/${item.orderId}`,
            title: item.title,
            target: '_blank'
        };

        result.customerGroupsList = salesAggregate.publisher.customerGroupReferences ?
            salesAggregate.publisher.customerGroupReferences.map(customerGroup => customerGroup.name) :
            [];

        result.stripeConnectAccount = salesAggregate.publisher.stripeConnectAccountId ? salesAggregate.publisher.stripeConnectAccountId : '';
        result.email = salesAggregate.publisher.email ? salesAggregate.publisher.email : '';

        return result;
    };

    this.after('initialize', function () {

        DataTable.attachTo(this.select('dataTableSelector'));

        setTimeout(() => this.getAggregateData(), 10);

        this.on('change', {
            aggregateYearSelect: this.getAggregateData,
            aggregateMonthSelect: this.getAggregateData
        });
    });
}
