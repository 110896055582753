import mixam from '../../../boot/mixam';
import defineComponent from '../../../../components/flight/lib/component';
import DataTable from '../../table/data-table-net/data-table-net.tsx';

import postStatus from "../../admin/postjob-status";
import postTypes from "../../admin/postjob-types";

export default defineComponent(TaylorsJobManager);

function TaylorsJobManager() {

    this.attributes({
        url: "/api/admin/supplier/jobs/taylors",
        dataTableSelector: '[data-type="data-table"]'
    });


    this.createSchema = function () {
        const cols = [];

        cols.push({
            title: "",
            type: "thumbnail",
            data: "front"
        });
        cols.push({
            title: "Order",
            type: "link",
            data: "href"
        });

        cols.push({
            title: "Created",
            type: "datetime",
            data: "dateCreated"
        });

        cols.push({
            title: "Status",
            type: "text",
            data: "status"
        });

        cols.push({
            title: "Error",
            type: "text",
            width: "30%",
            data: "error"
        });

        return cols;
    };


    this.paint = function () {
        const data = {
            columns: this.createSchema(),
            fileName: this.attr.url.split("/").pop(),
            response: this.normalize(this.data)
        };

        if (this.dataReadyFired) {
            window.dispatchEvent(new CustomEvent('uiDataUpdate', { detail: data }));
        } else {
            this.dataReadyFired = true;
            this.trigger("uiDataReady", data);
        }
    };

    this.normalize = function (list) {
        return list.map(x => this.normalizePost(x));
    };

    this.normalizePost = function (apiPost) {
        const result = $.extend(true, {}, apiPost);

        result.override = result.override || {};
        result.override.td = result.override.td || {};
        result.front = {
            src: result.thumbnailUrl,
            href: `/orders/${result.orderId}/artwork`,
            title: result.caseNumber,
            target: "_blank"
        };
        result.override.td.front = "orient-" + result.thumbnailOrientaion;
        result.href = {
            href: `/orders/${result.orderId}/artwork`,
            caption: result.caseNumber
        };
        result.status = postStatus[result.status];
        result.type = postTypes[result.type];
        return result;
    };


    this.getData = function () {
        $.getJSON(this.attr.url)
            .done(data => {
                this.data = data;
                this.paint();
            })
            .fail((err) => this.trigger("log", {message: err}));
    };

    this.subscribe = function (onMessage) {
        this.trigger("log", {message: ["Subscribe to stomp channel alltaylorsapipostchanel"], title: "Taylors.subscribe"});
        this.stomp = Stomp.client(mixam.stompServiceUrl);

        this.stomp.debug = (...args) => {
            if (args.join('').indexOf('Whoops! Lost connection to') !== -1) {
                setTimeout(() => this.subscribe(onMessage), 10);
            }
            this.trigger("log", {message: args, title: "Taylors.subscribe"});
        };

        this.stomp.connect(mixam.stompWebUser, mixam.stompWebPass, () => {
            this.stomp.subscribe("/topic/alltaylorsapipostchanel", (d) => {
                const p = JSON.parse(d.body);
                onMessage(p);
            });
        }, (err) => this.trigger("log", err), '/');
    };

    this.updateMessageLine = function (data) {
        if (!this.data) {
            return;
        }

        let verb = data.type;
        const message = data.data;
        if (message.shopId !== mixam.shop.id) {
            return;
        }

        const index = this.data.map(x => x.id).indexOf(message.id);

        if (verb === "UPDATE" || verb === "INSERT") {
            if (index !== -1) {
                this.data[index] = message;
            } else {
                this.data.unshift(message);
            }

        } else if (verb === "DELETE" && index !== -1) {
            this.data.splice(index, 1);
        }

        this.paint();
    };
    this.after('initialize', function () {
        DataTable.attachTo(this.select('dataTableSelector'));
        setTimeout(() => this.getData(), 10);
        this.subscribe(d => this.updateMessageLine(d));
    });
}
