import defineComponent from '../../../components/flight/lib/component';
import Mustache from '../../../components/mustache/mustache';
import menuTemplate from 'text!../../../appes6/templates/floating-bar.mustache';

export default defineComponent(FloatingToolbarButton);

function FloatingToolbarButton() {

    this.attributes({
        top: 0,
        command: "unknown",
        buttonSelector: 'button[data-type="clear-filter"]',
        preFiltersSelector: '.pre-filters',
        fromIconSelector: '.morph-from > i'
    });

    this.onClick = function (event) {
        this.trigger("toolbarAction", {
            command: this.attr.command,
            isMorphed: this.select('buttonSelector').hasClass(this.morphedClassName),
            isFixed: this.isFixed
        });
    };

    this.onMorph = function (event, data) {
        if (data.target === this.attr.command) {
            this.morphedClassName = data.className || this.morphedClassName;

            if (data.isMorphed) {
                this.select('buttonSelector').addClass(this.morphedClassName);
            } else {
                this.select('buttonSelector').removeClass(this.morphedClassName);
            }
        }
    };

    this.onEnable = function (event, data) {
        if (data.target === this.attr.command) {
            this.select('buttonSelector').removeAttr("disabled");
        }
    };

    this.onDisable = function (event, data) {
        if (data.target === this.attr.command) {
            this.select('buttonSelector').attr("disabled", true);
        }
    };

    this.onActivate = function (event, data) {
        if (data.target === this.attr.command) {
            this.select('buttonSelector').addClass("active");
            this.select('preFiltersSelector').addClass('open');
            setTimeout(() =>  this.on(document, "click.floating.toolbar", this.onDocumentClick), 16);
        }
    };

    this.onDeactivate = function (event, data) {
        if (data.target === this.attr.command) {
            this.select('buttonSelector').removeClass("active");
        }
    };

    this.onScroll = function (event, data) {

        if (data && typeof data.offset === "number") {
            this.isFixed = data.offset > this.attr.top;
        }
    };

    this.onAddMenu = function (event, data) {
        if (data.target === this.attr.command) {
            var filters = data.items;

            if (filters && filters.length) {
                $('<i class="fa-mini fa fa-caret-down"></i>').appendTo(this.select('fromIconSelector'));
                $(this.render(filters)).appendTo(this.$node);
            }
        }
    };

    this.render = function (items) {
        return Mustache.render(menuTemplate, {filters: items});
    };


    this.onDocumentClick = function (event) {
        var $target = $(event.target),
            filter = $target.data('filter');

        // click on pre-defined filter
        if (filter) {
            event.preventDefault();
            this.select('buttonSelector').addClass(this.morphedClassName);
            this.trigger("toolbarRequestFilter", {
                command: this.attr.command,
                isMorphed: this.select('buttonSelector').hasClass(this.morphedClassName),
                isFixed: this.isFixed,
                filter: filter
            });
         }
        this.closePredefinedFilterBox();
    };

    this.closePredefinedFilterBox = function () {
        this.select('preFiltersSelector').removeClass("open");
        this.off(document, "click.floating.toolbar", this.onDocumentClick);
    };


    this.after('initialize', function () {
        this.attr.command = this.select('buttonSelector').data('type');
        this.morphedClassName = "morphed";
        this.isFixed = false;
        this.on('click', {buttonSelector: this.onClick});
        this.on(document, "uiRequestButtonMorph", this.onMorph);
        this.on(document, "uiRequestButtonEnable", this.onEnable);
        this.on(document, "uiRequestButtonDisable", this.onDisable);
        this.on(document, "uiRequestButtonActivate", this.onActivate);
        this.on(document, "uiRequestButtonDeactivate", this.onDeactivate);
        this.on(document, "uiRequestButtonMenu", this.onAddMenu);
        this.on(document, "uiPageScroll", this.onScroll);

    });
}
