import mixam from '../../../boot/mixam';
import Order from '../../constants/order';
import defineComponent from '../../../../components/flight/lib/component';
import DataTable from '../../table/data-table-net/data-table-net.tsx';
import WithOrdersItemsMembers from '../with-orders-items-members';
import WithOrdersItems from '../with-order-items';
import WithSignDataRequest from '../../with-sign-data-request';
import { Breakpoints } from '../../table/data-table-net/StyledDataTableNet.tsx';
import axios from "axios";

export default defineComponent(AdminPodFulfilmentQueue, WithOrdersItemsMembers, WithOrdersItems, WithSignDataRequest);

function AdminPodFulfilmentQueue() {

    this.attributes({
        firstTimeUrl: `${mixam.reporterOrigin}/reporter/admin/api/shop/${mixam.shop.id}/orders/items/first`,
        url: `${mixam.reporterOrigin}/reporter/admin/api/shop/${mixam.shop.id}/orders/items`,
        status: Order.ORDER_STATUS_PRINT_ON_DEMAND,
        minStatus: 'RIPE',
        maxStatus: 'CONFIRMED',
        dataTableSelector: '[data-type="data-table"]',
        fulfillmentBtnSelector: '[data-type="fulfil-selected-items-btn"]',
        fulfilmentCheckboxSelector: 'input[name="fulfil-item-checkbox"]',
        fulfilmentApiUrl: '/api/admin/print-on-demand/fulfilments'
    });

    this.createSchema = function () {
        const cols = [];

        cols.push({
            title: "",
            type: "thumbnail",
            data:"front"
        });
        cols.push({
            title: 'Fulfil Item',
            type: 'checkbox',
            data: 'podFulfilmentCheckbox'
        });
        cols.push({
            title: "Order",
            type: "link",
            data:"href",
            width: "5rem",
        });
        cols.push({
            title: "Username",
            type: "user",
            data:"user",
            width: "10rem",
            hide: Breakpoints.SM,
        });
        cols.push({
            title: "Modified",
            type: "timebox",
            data:"lastModifiedDate",
            hide: Breakpoints.MD,
        });
        cols.push({
            title: "Status",
            type: "badge",
            data:"statusBadge",
            width: "150px",
            hide: Breakpoints.MD,
        });
        cols.push({
            title: "Product",
            type: "text",
            data:"product",
            width: "10rem",
            hide: Breakpoints.LG,
        });
        cols.push({
            title: "Size",
            type: "text",
            data: "size",
            hide: Breakpoints.XXL,
        });
        cols.push({
            title: "Pages",
            type: "number",
            data: "calcedPages",
            hide: Breakpoints.XXL,
        });
        cols.push({
            title: "Orientation",
            type: "icon",
            data: "orientation",
            hide: Breakpoints.XXL,
        });
        cols.push({
            title: "Bound",
            type: "icon",
            data: "bound",
            hide: Breakpoints.XXL
        });
        cols.push({
            title: "Bind",
            type: "icon",
            data: "bind",
            hide: Breakpoints.XXL,
        });
        cols.push({
            title: "Sewn",
            type: "boolean",
            data: "isSewn",
            hide: Breakpoints.XXL,
        });

        return cols;
    };

    this.fulfilSelectedItems = function() {
        const itemsToFulfil = [];
        let tableRows = this.select('fulfilmentCheckboxSelector');
        tableRows.each(function(){
            const checkbox = $(this);
            if(checkbox.prop('checked')) {
                let orderId = $(this).data('order');
                let itemId = $(this).data('item');
                itemsToFulfil.push({
                    orderId: orderId,
                    itemId: itemId
                });
            }
        });

        if(itemsToFulfil.length > 0) {
            this.initFulfilmentBtn();
            axios.post(this.attr.fulfilmentApiUrl, itemsToFulfil)
                .then(() => {
                    this.resetFulfilmentBtn();
                    this.getData();
                }).catch(error => {
                    alert(`Failed To Send Items To Fulfilment: ${error}`);
                    this.resetFulfilmentBtn();
                });
        }
    };

    this.initFulfilmentBtn = function(event) {
        let fulfilmentBtn = this.select('fulfillmentBtnSelector');
        setTimeout( function() {
            fulfilmentBtn.addClass('disabled');
            fulfilmentBtn.find('[data-type="spinner"]').removeClass('d-none');
            fulfilmentBtn.find('[data-type="btn-label"]').text("Fulfilling Items");
        }, 1);
    };

    this.resetFulfilmentBtn = function(event) {
        let fulfilmentBtn = this.select('fulfillmentBtnSelector');
        setTimeout( function() {
            fulfilmentBtn.find('[data-type="spinner"]').addClass('d-none');
            fulfilmentBtn.find('[data-type="btn-label"]').text("Fulfil Selected Items");
            fulfilmentBtn.removeClass('disabled');
        }, 2000);
    };

    this.after('initialize', function () {
        this.attr.podQueueId = $(this.node).data('queue');
        DataTable.attachTo(this.select('dataTableSelector'));
        this.on('click', {
            fulfillmentBtnSelector: this.fulfilSelectedItems
        });
        setTimeout(() => this.getData(), 10);
        try {
            this.subscribe(this.updateOrderLine.bind(this));
        } catch (e) {

        }
    });
}
