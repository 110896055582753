export default WithDashboardComponent;

var COLORS = {
        cost: [[247, 70, 74, 1], [245, 21, 26, 1]],
        value: [[70, 191, 189, 1,], [54, 156, 154, 1]],
        count: [[70, 138, 191, 1], [54, 111, 156, 1]]
    };

function WithDashboardComponent() {

    this.rgba = function (field, offset, opacity) {
        var a = COLORS[field][offset];

        a[3] = opacity;
        return `rgba(${a.join(",")})`;
    };

    this.getChartOptions = function () {
        return {
            responsive: true,
            maintainAspectRatio: false
        };
    };

    this.counterUpdate = function (event, data, isSilent) {
        var that = this;

        if (data.list) {
            data.list.forEach(function (counter) {
                that.counterUpdate(event, counter, true);
            });
            return;
        }
        if (this.isInteresting(data)) {
            this.calcNetValue(data);
            this.counters[data.type] = data;
            this.display(isSilent);
        }
    };

    this.updateUiCounters = function (counter, isSilent) {
        var nodes = this.$node.find("[data-counter-type='" + counter.type + "']");

        nodes.each(function (index, node) {
            var $node = $(node),
                field = $node.data("field"),
                type = $node.data("type"),
                value = counter[field];

            if (type === "decimal" || type === "negative-decimal") {
                value = parseFloat(value).formatNumber(0);
            } else if (type === "int") {
                value = parseFloat(value).formatNumber(0);
            }
            $node.text(value);
            if (type === "negative-decimal") {
                if (parseFloat(value)) {
                    $node.addClass("negative");
                } else {
                    $node.removeClass("negative");
                }
            }

            if (!isSilent) {
                $node.hilightElement();
            }

        });
    };

    this.calcNetValue = function (counter) {
        if (this.attr.counterIn && this.attr.counterIn.indexOf("FULFILMENT_") === 0) {
            counter.value = Math.round(((counter.weight || 0) - (counter.sum || 0)) * 100) / 100;
        } else {
            counter.value = Math.round(((counter.sum || 0) - (counter.cost || 0) - (counter.delivery || 0) - (counter.vat || 0)) * 100) / 100;
        }
    };

    this.createCounter = function (type, count, sum, cost, vat, delivery, weight) {
        var o = {
            cost: cost || 0,
            count: count || 0,
            delivery: delivery || 0,
            sum: sum || 0,
            type: type,
            vat: vat || 0,
            weight: weight || 0

        };

        this.calcNetValue(o);

        return o;
    };
}
