
export default {
    clear: clear,
    add: add,
    get: get
};

var store = {};

function clear() {
    store = {};
}

function add(value) {
    var key = Math.floor(Math.random() * 2147483648).toString(36);

    store[key] = value;
    return key;
}

function get(key) {
    return store[key];
}

