import React from 'react';
import styled from 'styled-components';
import ReactDom from  'react-dom/client';
import mixam from '../../../boot/mixam';
import Chartjs from '../../../../components/Chart.js/Chart';
import {FILTER_METHODS, FILTERABLE_DATA_TYPES} from "../../table/data-table-net/DataTableConstants.ts";

const Wrapper = styled.div`

`;

const Supplier = ({supplier}) => {

    const jobsBehind = supplier.jobsBehind ? supplier.jobsBehind : 0;
    const behindPercent = Math.round(jobsBehind * 100 / supplier.list.length);
    let behindBadgeClass;
    if(behindPercent < 5) {
        behindBadgeClass = 'primary';
    } else if(behindPercent < 25) {
        behindBadgeClass = 'warning';
    } else {
        behindBadgeClass = 'danger';
    }

    function filterSupplier(supplierId) {
        const event = new CustomEvent('dataTableCustomFilterEvent', {
            detail: {
                filterColumn: 'providerId',
                filterText: supplierId,
                filterType: FILTERABLE_DATA_TYPES.TEXT,
                filterMethod: FILTER_METHODS.EQUALS
            }
        });
        window.dispatchEvent(event);
    }

    return (
        <div className="col-12 col-md-2">
            <div className="card h-100">
                <div className="card-header bg-primary">
                    <h6 className="text-center text-white">{supplier.name}</h6>
                </div>
                <div className="card-body">
                    <div className="row">
                        <div className="col">
                            <h6 className="card-subtitle mb-2 text-muted">Jobs</h6>
                            <p>{supplier.list.length} ({supplier.countPercent}%)</p>
                        </div>
                        <div className="col">
                            <canvas className="float-end" data-role="article-pie-chart" data-supplier-id={supplier.id} data-percent={supplier.countPercent} width="50" height="50"></canvas>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <h6 className="card-subtitle mb-2 text-muted">Value</h6>
                            <p>{mixam.shop.currency.prefix}{supplier.cost.formatNumber(0)}{mixam.shop.currency.postfix} ({supplier.costPercent}%)</p>
                        </div>
                        <div className="col">
                            <canvas className="float-end" data-role="article-pie-chart" data-supplier-id={supplier.id} data-percent={supplier.costPercent} width="50" height="50"></canvas>
                        </div>
                    </div>
                    {supplier.jobsBehind !== undefined && (
                        <>
                            <div className="mb-2">
                                <span className={`bg-${behindBadgeClass} badge w-100`}>{jobsBehind} Jobs Behind ({behindPercent}%)</span>
                            </div>
                            <button type="button" className="btn btn-secondary btn-sm w-100" onClick={() => filterSupplier(supplier.id)}>View Jobs</button>
                        </>
                    )}
                </div>

            </div>
        </div>
    )

};

const ReactSupplierArticles = (data) => {

    const supplierData = data.data;

    const suppliers = Object.keys(supplierData).
        filter(s => (typeof supplierData[s] === 'object')).
        sort((a, b) => supplierData[a].name.localeCompare(supplierData[b].name)).
        map((supplier) =>
            <Supplier
                supplier={supplierData[supplier]}
                key={supplierData[supplier].id}
            ></Supplier>
       );
    return (
        <Wrapper>
            <h4 className="mt-3">Supplier Summary</h4>
            <div className="align-items-start mb-4 py-4 border-bottom">
                <div className="row g-3">
                    {suppliers}
                </div>
                <hr/>
            </div>
        </Wrapper>
    );
};

function supplierArticles(event, data, target) {

    const root = ReactDom.createRoot(target);
    root.render(
        <ReactSupplierArticles data={data}/>
    );

    setTimeout(() => {
        /*
         * NOTE: this solution should be reworked.
         *
         * We really shouldn't perform direct DOM manipulation upon something React has rendered.
         * Someone should really re-do the implementation of Chartjs in a React-friendly way. For
         * the moment, it's working (but requires the setTimeout).
         */
        const pieCharts = document.querySelectorAll('[data-role="article-pie-chart"]');
        pieCharts.forEach(pieChart => {
            var chartData = [{
                value: pieChart.dataset.percent,
                color: '#DE3618',
                highlight: '#BD2E14',
                label: pieChart.dataset.percent + '%'
            }, {
                value: 100-pieChart.dataset.percent,
                color: '#CCC',
                highlight: '#CCC',
                label: ''
            }];
            new Chartjs(pieChart.getContext("2d")).Doughnut(chartData, {
                showTooltips: false,
                maintainAspectRatio: true,
                animation: false
            });
            pieChart.style.height = '50px';
            pieChart.style.width = '50px';
        });
    }, 500);

}

export default supplierArticles;
