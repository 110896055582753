import defineComponent from '../../../../components/flight/lib/component';
import DataTable from './../../table/data-table-net/data-table-net.tsx';
import {Breakpoints} from "../../table/data-table-net/StyledDataTableNet.tsx";
import {Modal} from "bootstrap";
import axios from "axios";

export default defineComponent(MemberNewsletter);

function MemberNewsletter() {

    this.attributes({
        url: "/admin/api/members/newsletter",
        klaviyoSyncSubscribersUrl: '/admin/api/klaviyo/subscribers/sync',
        dataTableSelector: '[data-type="data-table"]',
        klaviyoSyncButtonSelector: '[data-type="klavio-sync-button"]',
        confirmKlavioSyncModalSelector: '[data-type="klavio-sync-confirmation"]',
        confirmKlavioSyncButtonSelector: '[data-type="confirm-button"]'
    });

    this.createSchema = function () {
        var cols = [];

        cols.push({
            title: "Name",
            type: "link",
            data: "name",
            width: '200px'
        });
        cols.push({
            title: "Email",
            type: "link",
            data: "email",
            width: '300px'
        });
        cols.push({
            title: "Company",
            type: "text",
            data: "company"
        });
        cols.push({
            title: "Registered",
            type: "boolean",
            data: "registered"
        });
        cols.push({
            title: "Consent",
            type: "boolean",
            data: "marketingEmailConsent",
            hide: Breakpoints.XL
        });
        cols.push({
            title: "Created",
            type: "timebox",
            data: "createdDate",
            defaultSort: true,
            sortOrder: 'desc',
            hide: Breakpoints.MD
        });
        cols.push({
            title: "First Order",
            type: "timebox",
            data: "dateFirstOrder",
            hide: Breakpoints.LG
        });
        cols.push({
            title: "Latest Order",
            type: "timebox",
            data: "dateLatestOrder",
            hide: Breakpoints.LG
        });
        cols.push({
            title: "Town",
            type: "text",
            data: "town",
            hide: Breakpoints.XL
        });
        cols.push({
            title: "County",
            type: "text",
            data: "county",
            hide: Breakpoints.XL
        });
        cols.push({
            title: "Country",
            type: "text",
            data: "country",
            hide: Breakpoints.XL
        });

        return cols;
    };

    this.normalize = function (list) {
        return list.map(member => this.normalizeMember(member));
    };

    this.normalizeMember = function (member) {
        const result = {
            id: member.id,
            name: {
                href: member.memberId ? `/member/${member.id}` : null,
                caption: (`${member.name || ""}`).trim(),
                title: `${member.name || ""}`
            },
            email: {
                href:  member.memberId ? `/member/${member.id}` : null,
                caption: (member.email || "").trim(),
                title: `${member.name || ""}`
            },
            company: member.company,
            marketingEmailConsent: member.consent,
            dateFirstOrder: member.firstOrder,
            dateLatestOrder: member.recentOrder,
            registered: member.registered,
            createdDate: member.dateCreated,
            modifiedDate: member.lastModifiedDate,
            seenDate: member.lastSigninDate,
            town: member.town || '',
            county: member.county || '',
            country: member.country || '',
        };
        return result;
    };

    this.getData = function () {
        $.getJSON(this.attr.url)
            .done(data => {
                this.data = data;
                this.paint();
            });
    };

    this.paint = function () {
        const data = {
            columns: this.createSchema(),
            fileName: this.attr.url.split("/").pop(),
            response: this.normalize(this.data)
        };
        if (this.dataReadyFired) {
            window.dispatchEvent(new CustomEvent('uiDataUpdate', { detail: data }));
        } else {
            this.dataReadyFired = true;
            this.trigger("uiDataReady", data);
        }
    };

    this.syncSubscribersWithKlaviyo = function () {

        const newsletterManager = this;
        const confirmationDialogue = this.select('confirmKlavioSyncModalSelector');
        let modal = Modal.getOrCreateInstance(confirmationDialogue);
        modal.show();

        this.select('confirmKlavioSyncButtonSelector').click(function() {
            modal.hide();

            newsletterManager.initKlaviyoSyncButton();
            axios.post(newsletterManager.attr.klaviyoSyncSubscribersUrl)
                .then(response => {
                    newsletterManager.resetKlaviyoSyncButton();
                    newsletterManager.trigger('showFixedMessage', {body: `Subscribers Synced With Klaviyo`, interval: 15000});
                }).catch(error => {
                    newsletterManager.trigger('showFixedMessage', {type: 'error', body: `Failed To Sync Data With Klaviyo: ${error}`, interval: 15000});
                    newsletterManager.resetKlaviyoSyncButton();
                });

        });

    };

    this.initKlaviyoSyncButton = function(event) {
        let klaviyoSyncButton = this.select('klaviyoSyncButtonSelector');
        setTimeout( function() {
            klaviyoSyncButton.addClass('disabled');
            klaviyoSyncButton.find('[data-type="spinner"]').removeClass('d-none');
            klaviyoSyncButton.find('[data-type="btn-label"]').text("Syncing Data");
        }, 1);
    };

    this.resetKlaviyoSyncButton = function(event) {
        let klaviyoSyncButton = this.select('klaviyoSyncButtonSelector');
        setTimeout( function() {
            klaviyoSyncButton.find('[data-type="spinner"]').addClass('d-none');
            klaviyoSyncButton.find('[data-type="btn-label"]').text("Sync With Klaviyo");
            klaviyoSyncButton.removeClass('disabled');
        }, 2000);
    };

    this.after('initialize', function () {
        DataTable.attachTo(this.select('dataTableSelector'));
        this.on('click', {
            klaviyoSyncButtonSelector: this.syncSubscribersWithKlaviyo
        });
        setTimeout(() => this.getData(), 10);
    });
}