import defineComponent from '../../../../components/flight/lib/component';
import DataTable from './../../table/data-table-net/data-table-net.tsx';
import {Modal} from "bootstrap";
import axios from "axios";

export default defineComponent(KnowledgeBaseArticleDataTable);

function KnowledgeBaseArticleDataTable() {

    this.attributes({
        url: "article-data",
        dataTableSelector: '[data-type="data-table"]',
        confirmDeleteModal: '[data-type="delete-article-confirmation"]'
    });

    this.createSchema = function () {
        const cols = [];
        cols.push({
            title: "Title",
            type: "text",
            data: "title"
        });
        cols.push({
            title: "Language",
            type: "text",
            data: "language"
        });
        cols.push({
            title: "Category",
            type: "text",
            data: "categoryName"
        });
        cols.push({
            title: "Active",
            type: "boolean",
            data: "active"
        });
        cols.push({
            title: "Created Date",
            type: "date",
            data: "createdDate"
        });
        cols.push({
            title: "Last Modified Date",
            type: "date",
            data: "lastModifiedDate",
        });
        cols.push({
            title: "",
            type: "multiButton",
            data: "actions"
        });
        return cols;
    };


    this.paint = function () {
        const data = {
            columns: this.createSchema(),
            fileName: this.attr.url.split("/").pop(),
            response: this.normalize(this.data)
        };

        if (this.dataReadyFired) {
            window.dispatchEvent(new CustomEvent('uiDataUpdate', { detail: data }));
        } else {
            this.dataReadyFired = true;
            this.trigger("uiDataReady", data);
        }
    };

    this.normalize = function (list) {
        return list.map(x => this.normalizeArticle(x));
    };

    this.normalizeArticle = function (article_) {
        const article = $.extend(true, {}, article_);
        article.categoryName = article.category.name;
        article.createdDate = article.createdDateTimestamp;
        article.lastModifiedDate = article.lastModifiedDateTimestamp;
        article.actions = [
            {
                href: `articles/${article.id}/edit`,
                caption: "Edit",
                className: "btn btn-primary",
                iconClassname: "bi bi-pencil",
                key: "edit",
                target: "_self"
            },
            {
                href: `articles/${article.id}`,
                caption: "Delete",
                className: "btn btn-danger",
                iconClassname: "bi bi-trash",
                key: "delete",
                role: "ROLE_DEV",
                target: "_self",
                callback: (event) => {
                    event.stopPropagation();
                    event.preventDefault();

                    let buttonTarget = $(event.target);

                    const confirmationDialogue = document.querySelector('[data-type="delete-article-confirmation"]');
                    let modal = Modal.getOrCreateInstance(confirmationDialogue);
                    modal.show();

                    $(document.querySelector('[data-type="confirm-button"]')).click(function() {
                        fetch(
                            buttonTarget.attr('href'),
                            {
                                method: 'DELETE',
                            }
                        ).then((response) => {
                            if(response.status === 200) {
                                buttonTarget.closest('.rdt_TableRow').remove();
                            }
                        });
                        modal.hide();
                    });
                }
            }
        ];
        return article;
    };

    this.getData = function () {
        axios.get(this.attr.url)
            .then(response => {
                this.data = response.data;
                this.paint();
                setTimeout(() => this.getData(), 1000 * 60 * 15);
            }).catch(error => this.trigger("log", {message: error}));
    };

    this.after('initialize', function () {
        DataTable.attachTo(this.select('dataTableSelector'));
        setTimeout(() => this.getData(), 10);
    });
}