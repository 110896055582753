export default  WithSubmitAnimation;

function WithSubmitAnimation() {

    this.handleSubmit = function (event) {
        if (!event.isDefaultPrevented()) {
            this.select('saveBtnSelector').attr('data-loading', '*').attr('disabled', true);
        }
    };

    this.resetAnimation = function () {
        this.select('saveBtnSelector').removeAttr('data-loading').attr('disabled', false);
    };

}