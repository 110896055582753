var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React, { useEffect, useState } from "react";
import { Modal, Button, Image } from 'react-bootstrap';
import styled from 'styled-components';
import i18nShop from "../../../../assets/i18n/i18n";
import { I18nextProvider } from 'react-i18next';
import axios from 'axios';
var StyledModal = styled(Modal)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    opacity: 1; // overwriting some conflicting modal styles\n    .modal-content {\n        margin-top: 7rem;\n    }\n    label {\n        font-weight: bold;\n    }\n"], ["\n    opacity: 1; // overwriting some conflicting modal styles\n    .modal-content {\n        margin-top: 7rem;\n    }\n    label {\n        font-weight: bold;\n    }\n"])));
export var OptionWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  margin-top: 1rem;\n  display: flex;\n"], ["\n  margin-top: 1rem;\n  display: flex;\n"])));
export var ImageButtonGroup = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  flex-wrap: wrap;\n"], ["\n  display: flex;\n  flex-wrap: wrap;\n"])));
export var ImageOption = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  width: 140px;\n  box-shadow: none !important;\n  border-radius: 0.5rem !important;\n  color: var(--bs-secondary);\n  cursor: pointer;\n  &.active {\n    border-color: #6BD3CC;\n    border-width: 3px;\n    border-style: solid;\n  }\n"], ["\n  width: 140px;\n  box-shadow: none !important;\n  border-radius: 0.5rem !important;\n  color: var(--bs-secondary);\n  cursor: pointer;\n  &.active {\n    border-color: #6BD3CC;\n    border-width: 3px;\n    border-style: solid;\n  }\n"])));
export var ImageButtonContainer = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  margin: 4px;\n  display: flex;\n  flex-direction: column;\n  width: fit-content;\n  align-items: center;\n  width: 150px;\n  word-break: break-word;\n"], ["\n  margin: 4px;\n  display: flex;\n  flex-direction: column;\n  width: fit-content;\n  align-items: center;\n  width: 150px;\n  word-break: break-word;\n"])));
export var StyledImage = styled(Image)(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  border-radius: 0.3rem !important;\n"], ["\n  border-radius: 0.3rem !important;\n"])));
export var PrintessDesignOptionsModal = function (_a) {
    var orderId = _a.orderId, itemId = _a.itemId, onCancelBtnClick = _a.onCancelBtnClick, onSelectDesign = _a.onSelectDesign;
    var _b = useState(true), show = _b[0], setShow = _b[1];
    var _c = useState([]), availableDesigns = _c[0], setAvailableDesigns = _c[1];
    var _d = useState(false), isInitialized = _d[0], setInitialized = _d[1];
    var _e = useState(), design = _e[0], setDesign = _e[1];
    var handleSelectDesign = function (design) {
        setDesign(design);
    };
    useEffect(function () {
        axios.get("/api/printess/orders/".concat(orderId, "/items/").concat(itemId, "/designs"))
            .then(function (response) {
            setAvailableDesigns(response.data);
            if (response.data.length > 0) {
                setDesign(response.data[0]);
            }
            setInitialized(true);
        });
    }, []);
    return (React.createElement(React.Fragment, null, isInitialized && (React.createElement(I18nextProvider, { i18n: i18nShop },
        React.createElement(StyledModal, { show: show, size: 'lg', scrollable: true, centered: true },
            React.createElement(Modal.Header, null,
                React.createElement(Modal.Title, null, i18nShop.t('printessTemplateOptions.modal.title'))),
            React.createElement(Modal.Body, null,
                React.createElement(React.Fragment, null,
                    i18nShop.t('printessTemplateOptions.modal.body'),
                    React.createElement(OptionWrapper, null,
                        React.createElement(ImageButtonGroup, null, availableDesigns.map(function (value) {
                            return (React.createElement(ImageButtonContainer, { key: "".concat(value.template, "-").concat(value.imageUrl) },
                                React.createElement(ImageOption, { className: (design === null || design === void 0 ? void 0 : design.imageUrl) === value.imageUrl ? 'active' : '' },
                                    React.createElement(StyledImage, { src: "".concat(value.imageUrl), onClick: function () { return handleSelectDesign(value); }, width: '100%' })), "".concat(value.label)));
                        }))))),
            React.createElement(Modal.Footer, null,
                React.createElement(Button, { onClick: function () {
                        setShow(false);
                        onCancelBtnClick();
                    } }, i18nShop.t('printessTemplateOptions.modal.cancelBtnLabel')),
                React.createElement(Button, { variant: 'primary', onClick: function () {
                        onSelectDesign(design);
                        setShow(false);
                    } }, i18nShop.t('printessTemplateOptions.modal.okBtnLabel'))))))));
};
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
