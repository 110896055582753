var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useEffect, useState } from 'react';
import { usePlaidLink } from 'react-plaid-link';
import axios from "axios";
import { TransferNetwork } from './types';
import { PlaidAccountItem, PlaidPaymentForm } from './components/styled';
export var PlaidPaymentManager = function (_a) {
    var orderId = _a.orderId, amount = _a.amount;
    var _b = useState(null), linkToken = _b[0], setLinkToken = _b[1];
    var _c = useState([]), linkAccounts = _c[0], setLinkAccounts = _c[1];
    var _d = useState(true), isLoadingAccounts = _d[0], setLoadingAccounts = _d[1];
    var _e = useState(true), isLoadingToken = _e[0], setLoadingToken = _e[1];
    var generateToken = function () { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, axios.post("/api/plaid/link-token")];
                case 1:
                    response = _a.sent();
                    setLinkToken(response.data.linkToken);
                    setLoadingToken(false);
                    return [2 /*return*/];
            }
        });
    }); };
    var getLinkAccounts = function () { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, axios.get("/api/plaid/link-accounts")];
                case 1:
                    response = _a.sent();
                    setLinkAccounts(response.data);
                    setLoadingAccounts(false);
                    return [2 /*return*/];
            }
        });
    }); };
    var updateLinkAccounts = function (accounts) {
        setLinkAccounts(accounts);
    };
    useEffect(function () {
        getLinkAccounts();
        generateToken();
    }, []);
    return (React.createElement(React.Fragment, null, isLoadingAccounts || isLoadingToken ? (React.createElement("p", null, "Loading. Please Wait...")) : (React.createElement(React.Fragment, null,
        React.createElement(LinkAccounts, { accounts: linkAccounts, orderId: orderId, updateLinkAccounts: updateLinkAccounts, amountToPay: amount }),
        React.createElement(Link, { linkToken: linkToken, updateLinkAccounts: updateLinkAccounts })))));
};
var Link = function (props) {
    var onSuccess = React.useCallback(function (public_token, metadata) {
        axios.post('/api/plaid/link-token/exchange', {
            public_token: public_token
        }, {
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then(function (response) {
            props.updateLinkAccounts(response.data);
        });
    }, []);
    var config = {
        token: props.linkToken,
        onSuccess: onSuccess,
    };
    var _a = usePlaidLink(config), open = _a.open, ready = _a.ready;
    return (React.createElement("button", { className: "btn btn-product-1", onClick: function () { return open(); }, disabled: !ready }, "Add New Account"));
};
var LinkAccounts = function (props) {
    var _a;
    var _b = useState(false), processingPayment = _b[0], setProcessingPayment = _b[1];
    var _c = useState(null), paymentResponse = _c[0], setPaymentResponse = _c[1];
    var _d = useState(TransferNetwork.SAME_DAY_ACH), transferNetwork = _d[0], setTransferNetwork = _d[1];
    var payWithAccount = function (accountId) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setProcessingPayment(true);
                    setPaymentResponse(null);
                    return [4 /*yield*/, axios.post("/api/plaid/payment", {
                            transferNetwork: transferNetwork,
                            orderId: props.orderId,
                            accountId: accountId
                        }, {
                            headers: {
                                'Content-Type': 'application/json',
                            }
                        })
                            .then(function (response) {
                            setPaymentResponse(response.data);
                            if (response.data.approved) {
                                window.location.reload();
                            }
                        }).finally(function () {
                            setProcessingPayment(false);
                        })];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    var removeAccount = function (accountId) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, axios.post("/api/plaid/link/account/".concat(accountId, "/remove"), {}, {
                        headers: {
                            'Content-Type': 'application/json',
                        }
                    })
                        .then(function (response) {
                        props.updateLinkAccounts(response.data);
                    })];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    var transferNetworkMap = (_a = {},
        _a[TransferNetwork.SAME_DAY_ACH.toString()] = TransferNetwork.SAME_DAY_ACH,
        _a[TransferNetwork.ACH.toString()] = TransferNetwork.ACH,
        _a);
    var debitDate = new Date().toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
    });
    return (React.createElement("div", { className: "panel panel-default" },
        React.createElement("div", { className: "panel-heading" },
            React.createElement("h3", { className: "panel-title", style: { marginTop: '0px' } }, "Linked Accounts")),
        React.createElement("div", { className: "panel-body" }, processingPayment ? (React.createElement("p", null, "Processing Payment. Please Wait...")) : (React.createElement(React.Fragment, null,
            (paymentResponse === null || paymentResponse === void 0 ? void 0 : paymentResponse.declined) &&
                React.createElement("div", { className: "alert alert-danger" }, "Payment was declined. Please try again or contact us if the problem persists"),
            (paymentResponse === null || paymentResponse === void 0 ? void 0 : paymentResponse.approved) ? (React.createElement("div", { className: "alert alert-success" }, "Payment was accepted. Once the transfer is complete we will start producing your order")) : (React.createElement(React.Fragment, null, props.accounts.length > 0 ? (React.createElement(React.Fragment, null,
                React.createElement(PlaidPaymentForm, null,
                    React.createElement("div", { className: "form-group" },
                        React.createElement("label", { htmlFor: "amountToPay", className: "control-label" }, "Amount To Pay:"),
                        React.createElement("div", { className: "control-size-frame" },
                            React.createElement("p", null,
                                "$",
                                props.amountToPay))),
                    React.createElement("div", { className: "form-group" },
                        React.createElement("label", { htmlFor: "transactionDate", className: "control-label" }, "Date of Debit:"),
                        React.createElement("div", { className: "control-size-frame" },
                            React.createElement("p", null, debitDate))),
                    React.createElement("div", { className: "form-group" },
                        React.createElement("label", { htmlFor: "selectOption", className: "control-label" }, "Payment Type:"),
                        React.createElement("div", { className: "control-size-frame" },
                            React.createElement("span", { className: "select-bkg" },
                                React.createElement("select", { className: "form form-control", onChange: function (event) { return setTransferNetwork(transferNetworkMap[event.target.value]); }, value: transferNetwork },
                                    React.createElement("option", { value: TransferNetwork.SAME_DAY_ACH }, "ACH Same Day"),
                                    React.createElement("option", { value: TransferNetwork.ACH }, "ACH"))))),
                    React.createElement("div", { className: "clearfix" }),
                    (transferNetwork === TransferNetwork.SAME_DAY_ACH || transferNetwork === TransferNetwork.ACH) &&
                        React.createElement("div", { className: "alert alert-warning" }, "ACH payments might delay your order until payment is received by Mixam. Please note Same Day ACH payments are usually received on the same day, as long as the transfer is made before 11am Pacific time or 2pm Eastern time."),
                    React.createElement("div", { className: "alert alert-info" }, "By clicking \"Pay with this account\" I authorize Mixam Inc to electronically debit my account for a single (one-time) entry at the depository financial institution selected below. I agree that ACH transactions I authorize comply with all applicable laws. I understand that this authorization will remain in full force and effect until I notify Mixam Inc by clicking to \u201Cremove\u201D the account, that I wish to revoke this authorisation. I understand that Mixam Inc requires at least 30 days prior notice to cancel this authorization.")),
                React.createElement("ul", { className: "list-unstyled" }, props.accounts.map(function (linkAccount, index) { return (linkAccount.plaidAccounts.map(function (account, accountIndex) {
                    var _a, _b;
                    return (React.createElement(PlaidAccountItem, { key: account.accountId },
                        linkAccount.institution.logo && (React.createElement("img", { src: "data:image/png;base64,".concat(linkAccount.institution.logo), alt: (_a = linkAccount.institution) === null || _a === void 0 ? void 0 : _a.name })),
                        React.createElement("h5", null, (_b = linkAccount.institution) === null || _b === void 0 ? void 0 : _b.name),
                        React.createElement("h5", null, account.name),
                        React.createElement("p", null,
                            "**** **** **** ",
                            account.mask),
                        React.createElement("button", { className: "btn btn-product-1", onClick: function () { return payWithAccount(account.accountId); } }, "Pay With This Account"),
                        React.createElement("button", { className: "btn btn-primary", onClick: function () { return removeAccount(account.accountId); } }, "Remove")));
                })); })))) : (React.createElement("p", null, "You have not linked any accounts yet. Please click the 'Add New Account' button to get started")))))))));
};
