var _a;
import i18n from 'i18next';
//translation files
import translationENUS from './en-us.json';
import translationENGB from './en-gb.json';
import translationDEDE from './de-de.json';
import translationENDE from './en-de.json';
//... en_de, de_de ..
import { Locale } from "@mixam-platform/mixam-types";
import mixam from "../../appes6/boot/mixam.js";
var resources = (_a = {},
    _a[Locale.EN_US] = {
        translation: translationENUS,
    },
    _a[Locale.EN_GB] = {
        translation: translationENGB,
    },
    _a[Locale.DE_DE] = {
        translation: translationDEDE,
    },
    _a[Locale.EN_DE] = {
        translation: translationENDE,
    },
    _a);
/**
 * i18N new instance initialization
 * We cannot use i18n.use(initReactI18next).init as this is global
 * in Shop it breaks other translations in PC and Checkout...
 * New instance is needed (imo we'd need to create instances for each project)
 */
var i18nShop = i18n.createInstance();
i18nShop.init({
    resources: resources,
    lng: mixam.shop.locale.replace('_', '-'),
    fallbackLng: 'en-US',
    interpolation: {
        escapeValue: false,
    },
    debug: false
});
/**
 * Same functionality as using normal `t` function from `i18next`, it is just
 * the return type that is `string`, a necessary evil for some components
 * which expect parameter to be a string not a `TFunction` or `DefaultTFuncReturn`
 * Also removes the need of using `I18nextProvider` in some cases and passing down
 * the i18n prop.
 *
 * Bonus: It is using local i18n object
 *
 * @param key - a locale key
 * @param args - additional parameter to be passed to translation (e.g. if we have a variable in a translation)
 * @returns a translated string
 */
export var t = function (key) {
    var args = [];
    for (var _i = 1; _i < arguments.length; _i++) {
        args[_i - 1] = arguments[_i];
    }
    return i18nShop.t(key, { args: args });
};
export default i18nShop;
