import React from 'react';
import Collapse from '../../react-bootstrap/Collapse';
import MorphButton from '../../react-bootstrap/MorphButton';

//const rd = ReactDom;
const dateTemplate = "dd/mm/yy h:MM TT";


class TaskMessages extends React.Component {
    constructor(...args) {
        super(...args);

        this.state = {action: 0};
    }

    componentDidMount() {
        this._mounted = true;
    }

    componentWillUnmount() {
        this._mounted = false;
    }

    onclick() {
        const open_ = !this.state.open;
        this.setState({open: open_});
        if (!open_) {
            setTimeout(() => {
                const $container = $(this.refs.messageContainer);
                if (!$container.isVisible()) {
                    $container.scrollToView();
                }
            }, 50);
        }
    }

    handleEditEnter(/*elem*/) {
        this.setState({edit: !this.state.edit});
    }

    handleEditExit(/*elem*/) {
        this.setState({edit: !this.state.edit});
    }

    handleSubmit(event) {
        event.preventDefault();
        const $form = $(event.target);
        const url = $form.prop('action');

        let data = $form.serialize();
        data += "&action=" + (this.state.action || 0);

        $.post(url, data, null, 'json').done((/*response*/) => {
            if (this._mounted) {
                if (this.refs.remarkText) {
                    this.refs.remarkText.value = "";
                }
                this.setState({action: 0, edit: false});
            }
        });
    }

    handleSubmitClick(action) {
        this.setState({action});
    }

    isAttentionOnly() {
        const taskType = this.props.line.type;
        return taskType === "SUPPLIER_STILL" ||
            taskType === "ATTENTION_REQUEST" ||
            taskType === "FULFILMENT_REQUIRED" ||
            taskType === "RESELLER_REQUEST" ||
            taskType === "DISPATCH_DECLINE" ||
            taskType === "REDISTIL_NOTIFICATION" ||
            taskType === "ARTWORK_ERROR" ||
            taskType === "PAYMENT_ERROR" ||
            taskType === "INVOICE_ERROR" ||
            taskType === "PAID_IDLE" ||
            taskType === "HELPSCOUT_EVENT"||
            taskType === "DELIVERY_EVENT";
    }

    createDialog() {
        const taskType = this.props.line.type;
        const dataLoading1 = this.state.action === 1 ? "*" : null;
        const dataLoading2 = this.state.action === 2 ? "*" : null;
        const dataLoading3 = this.state.action === 3 ? "*" : null;
        const dataLoading4 = this.state.action === 4 ? "*" : null;
        const dataLoading5 = this.state.action === 5 ? "*" : null;

        const taskId = this.props.line.id;
        const actionUrl = `/admin/api/tasks/${taskId}`;

        if (this.isAttentionOnly()) {
            return (
                <div>
                    <form onSubmit={(event) => this.handleSubmit(event)} className="answer-dialog" action={actionUrl} method="post">
                        <div className="form-group last">
                            <label className="control-label"></label>

                            <div className="control-save-line">
                                <button key="3" type="submit" onClick={() => this.handleSubmitClick(3)} name="reply_close" value="3"
                                        className="btn btn-product-4 btn-done btn-anime btn-anime-zoomout" data-loading={dataLoading4} data-style="zoom-out">
                                    <span className="btn-anime-label">Done</span>
                                    <span className="btn-anime-spinner"><i className="fa fa-spinner fa-lg fa-spin"></i></span>
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            );
        } else {

            const btn1 = (<button key="1" type="submit" onClick={() => this.handleSubmitClick(1)} name="answer_close" value="1"
                                  className="btn btn-product-4 btn-anime btn-anime-zoomout" data-loading={dataLoading1} data-style="zoom-out">
                <span className="btn-anime-label">Answer &amp; Close</span>
                <span className="btn-anime-spinner"><i className="fa fa-spinner fa-lg fa-spin"></i></span>
            </button>);

            const btn2 = (<button key="2" type="submit" onClick={() => this.handleSubmitClick(2)} name="answer" value="2"
                                  className="btn btn-product-4 btn-anime btn-anime-zoomout" data-loading={dataLoading2} data-style="zoom-out">
                <span className="btn-anime-label">Answer</span>
                <span className="btn-anime-spinner"><i className="fa fa-spinner fa-lg fa-spin"></i></span>
            </button>);

            const btn3 = (<button key="3" type="submit" onClick={() => this.handleSubmitClick(3)} name="close" value="3"
                                  className="btn close-submit-btn btn-product-4  btn-anime btn-anime-zoomout" data-loading={dataLoading3} data-style="zoom-out">
                <span className="btn-anime-label">Close</span>
                <span className="btn-anime-spinner"><i className="fa fa-spinner fa-lg fa-spin"></i></span>
            </button>);

            const btn4 = (<button key="4" type="submit" onClick={() => this.handleSubmitClick(4)} name="reply_close" value="4"
                                  className="btn btn-product-4 btn-anime btn-anime-zoomout" data-loading={dataLoading4} data-style="zoom-out">
                <span className="btn-anime-label">Reply &amp; Close</span>
                <span className="btn-anime-spinner"><i className="fa fa-spinner fa-lg fa-spin"></i></span>
            </button>);

            const btn5 = (<button key="5" type="submit" onClick={() => this.handleSubmitClick(5)} name="reply_close" value="5"
                                  className="btn btn-product-4 btn-anime btn-anime-zoomout" data-loading={dataLoading5} data-style="zoom-out">
                <span className="btn-anime-label">Notify User &amp; Close</span>
                <span className="btn-anime-spinner"><i className="fa fa-spinner fa-lg fa-spin"></i></span>
            </button>);

            let toolbar = [btn1, btn2, btn3];
            if (taskType === 'CONTACT_ANSWER_REQUEST' || taskType === 'QUERY_REQUEST_ANSWER') {
                toolbar = [btn3, btn4];
            } else if (taskType === "DISPATCH_DELAY") {
                toolbar = [btn3, btn5];
            }

            return (<Collapse in={this.state.edit}>
                <div>
                    <form onSubmit={(event) => this.handleSubmit(event)} className="answer-dialog" action={actionUrl} method="post">
                        <textarea name="remark" ref="remarkText" className="form-control"></textarea>

                        <div className="form-group last">
                            <label className="control-label"></label>

                            <div className="control-save-line">
                                {toolbar}
                            </div>
                        </div>
                    </form>
                </div>
            </Collapse>);
        }
    }

    render() {
        const messages = this.props.data || [];
        const taskType = this.props.line.type;
        const remarks = this.props.line.remarks || [];
        const threadButtonText = this.state.open ? "See one" : "See all";
        const openClass = `messages-container thread-${this.state.open ? 'open' : 'close'}`;
        const isClosed = !this.state.open;

        const thread = remarks.map((rem, i) => {
            const className = rem.nostro ? "nostro" : "none-nostro";
            const body = rem.body.split(/[\n\r]/).map((t, i) => {
                return (<p key={i}>{t}</p>);
            });
            const date = (new Date(rem.date)).format(dateTemplate);
            return (<li className={className} key={i}>{body} {date}</li>);
        });

        thread.pop();  // remove last message

        const items = messages.map((m, i) => {
            const body = taskType !== "HELPSCOUT_EVENT" ? m.body.split(/[\n\r]/).map((t, i) => {
                return (<p key={i}>{t}</p>);
            }) : m.body;
            const className = m.nostro ? "nostro" : "none-nostro";

            if (taskType !== "HELPSCOUT_EVENT") {
                return (<li key={i} className={className}>{body}</li>);
            }
            return React.createElement("li", {
                dangerouslySetInnerHTML: {__html: body},
                key: i,
                className: className
            });
        });


        return (<div className='messages-type'>
            <div ref="messageContainer"></div>
            <div className="message">
                <Collapse in={this.state.open}>
                    <div>
                        <ul className="messages-container">
                            {thread}
                        </ul>
                    </div>
                </Collapse>
                <ul className={openClass}>
                    {items}
                </ul>
                {this.createDialog()}
            </div>

            <div className="message-footer">
                {remarks.length > 1 && (
                    <a onClick={(e) => this.onclick(e)} className='btn btn-product-1'>
                        {isClosed && (<span className='thread-count'>{remarks.length}</span>)}
                        {threadButtonText}
                    </a>
                )} <span className="type">{this.props.line.type}</span>

                {!this.isAttentionOnly() && (<MorphButton in={this.state.edit}
                                                          className="answerButton"
                                                          onEnter={(node) => this.handleEditEnter(node)}
                                                          onExit={(node) => this.handleEditExit(node)}
                                                          icon1ClassName="fa fa-2x fa-pencil"
                                                          icon2ClassName="fa fa-2x fa-times"
                />)}


            </div>
        </div>);
    }
}


export default TaskMessages;
