import defineComponent from '../../../components/flight/lib/component';

export default defineComponent(InframeCommunicator);

function InframeCommunicator() {

    this.update = function () {
        const height = document.querySelector("#main").scrollHeight;
        parent.postMessage(height, "*");
    };

    this.after('initialize', function () {
        window.addEventListener('pcItemSpecificationUpdated', this.update);
        window.addEventListener('pcQueryResponseUpdated', this.update);
        this.on(window, 'resize', this.update);
        setTimeout(() => this.update(), 100);
    });

}
