export default  WithChartColors;

const chartColors = [
    '75, 192, 192',
    '255, 159, 64',
    '255, 99, 132',
    '54, 162, 235',
    '153, 102, 255',
    '201, 203, 207',
    '255, 205, 86',
    '163, 185, 217',
];

function WithChartColors() {
    this.getColor = function(index, opacity) {
        return `rgba(${chartColors[index]}, ${opacity})`;
    };
}