import React from 'react';
import AcceptDispute from './AcceptDispute';
import ResolveDispute from './ResolveDispute';
import ViewDispute from './ViewDispute';
export var Status;
(function (Status) {
    Status[Status["INITIAL"] = 10] = "INITIAL";
    Status[Status["RESOLVING"] = 20] = "RESOLVING";
    Status[Status["CLOSE"] = 60] = "CLOSE";
})(Status || (Status = {}));
var DisputeAction = function (_a) {
    var line = _a.line;
    var getForm = function () {
        switch (line.status) {
            case Status.INITIAL:
                return React.createElement(AcceptDispute, { line: line });
            case Status.RESOLVING:
                return React.createElement(ResolveDispute, { line: line });
            case Status.CLOSE:
                return React.createElement(ViewDispute, { line: line });
            default:
                return React.createElement(ViewDispute, { line: line });
        }
    };
    return getForm();
};
export default DisputeAction;
