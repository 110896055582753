import defineComponent from '../../../components/flight/lib/component';

export default defineComponent(SupportPromotion);

function SupportPromotion() {

    this.handleClick = function () {
        const $search = $('#searchTextBox');
        $search.scrollToView(() => {
            $search.hilightElement();
            $search.focus();
        });
    };

    this.after('initialize', function () {
        this.on('click', this.handleClick);
    });
}
