import mixam from '../../../boot/mixam';
import defineComponent from '../../../../components/flight/lib/component';
import DataTable from '../../table/data-table-net/data-table-net.tsx';

export default defineComponent(ShopifyWebhookManager);

function ShopifyWebhookManager() {

    this.attributes({
        url: "/api/admin/shopify/webhooks",
        dataTableSelector: '[data-type="data-table"]'
    });

    this.createSchema = function () {
        const cols = [];

        cols.push({
            title: "Topic",
            type: "text",
            data: "topic"
        });
        cols.push({
            title: "Payload",
            type: "string",
            data: "payload"
        });
        return cols;
    };


    this.paint = function () {
        const data = {
            columns: this.createSchema(),
            fileName: this.attr.url.split("/").pop(),
            response: this.data
        };

        if (this.dataReadyFired) {
            window.dispatchEvent(new CustomEvent('uiDataUpdate', { detail: data }));
        } else {
            this.dataReadyFired = true;
            this.trigger("uiDataReady", data);
        }
    };


    this.getData = function () {
        $.getJSON(this.attr.url)
            .done(data => {
                this.data = data;
                this.paint();
            })
            .fail((err) => this.trigger("log", {message: err}));
    };

    this.after('initialize', function () {
        DataTable.attachTo(this.select('dataTableSelector'));
        setTimeout(() => this.getData(), 10);
    });
}
