import defineComponent from '../../../../../components/flight/lib/component';
import WithDashboardComponent from '../../../with-dashbord-component';
import WithConfirmCancelBar from '../../../with-confirm-cancel-bar';
import Chartjs from '../../../../../components/Chart.js';

export default defineComponent(AnalyticsMethods, WithDashboardComponent, WithConfirmCancelBar);

function AnalyticsMethods() {
    var MARKER_COLORS = {Digital: "#F7464A", "Litho": "#46BFBD"},
        MARKER_COLORS_HIGHLIGHT = {Digital: "#FF5A5E", "Litho": "#5AD3D1"};

    this.attributes({
        field: "sum",
        chartSelector: '.methods-chart',
        clipperSelector: '.donat-clipper',
        dataElementSelector: '.counter-data'
    });


    this.isInteresting = function (counter) {
        return counter.cycle === "DAILY" && counter.type.indexOf("machine_") === 0;
    };


    this.hasValue = function (totals) {
        return !!totals.total.count;
    };

    this.isDigital = function (name) {
        return /B[23]D/.test(name);
    };

    this.countTotals = function () {
        const totals = {Digital: this.createCounter("DIGITAL"), Litho: this.createCounter("LITHO"), total: this.createCounter("TOTAL")};

        this.$elements.each((i, element) => {
            const $element = $(element);
            const key = this.isDigital($element.data("name")) ? "Digital" : "Litho";

            ["count", "sum", "value", "cost", "vat", "delivery", "weight"].forEach(prop => {
                const value = this.calcTotal($element, prop);

                totals[key][prop] += value;
                totals.total[prop] += value;
            });
        });
        return totals;
    };


    this.display = function (isSilent) {
        if (this.timer) {
            clearTimeout(this.timer);
        }

        this.timer = setTimeout(() => {
            this.timer = null;
            const totals = this.countTotals();

            if (this.chart && this.hasValue(totals)) {
                this.chart.segments[0].value = totals.Digital[this.attr.field];
                this.chart.segments[1].value = totals.Litho[this.attr.field];
                this.select('clipperSelector').addClass("in");
                this.chart.update();
            } else {
                this.select('clipperSelector').removeClass("in");
            }

            Object.keys(totals).forEach(key => this.updateUiCounters(totals[key], isSilent));
        }, 200);
    };

    this.init = function () {
        var ctx = this.select('chartSelector').get(0).getContext("2d");

        /** @namespace Chartjs.Pie */
        this.chart = new Chartjs(ctx).Pie(["Digital", "Litho"].map(function (type) {
            return {
                value: 1,
                color: MARKER_COLORS[type],
                highlight: MARKER_COLORS_HIGHLIGHT[type],
                label: type
            };
        }));
    };


    this.after('initialize', function () {
        this.counters = {};
        this.$elements = this.select('dataElementSelector');

        this.init();
        this.attr.field = this.$node.data("field");
        this.reset();
        this.on(document, "dashbordReset", this.reset);
        this.on(document, "dashbordCounterUpdate", this.counterUpdate);
    });
}