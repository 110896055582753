import React from 'react';
import axios from "axios";
var InvoiceRequeueAction = function (_a) {
    var data = _a.data;
    var handleClick = function () {
        axios.post("/admin/api/invoices/".concat(data.jobId, "/requeue"))
            .catch(function (error) { return alert('Error re-queuing job'); });
    };
    return (data.status == "FAILED" && React.createElement("button", { className: "btn btn-primary", onClick: handleClick }, "Requeue"));
};
export default InvoiceRequeueAction;
