import defineComponent from '../../../components/flight/lib/component';
import regional from '../../boot/regional';

export default defineComponent(ProofManager);

function ProofManager() {

    this.attributes({
        url: ""
    });

    this.getUrl = function (orderId, itemId) {
        return `/api/orders/${orderId}/${itemId}/requestproof`;
    };

    this.onClick = function () {
        this.$node.attr('data-loading', '*').attr('disabled', true);
        const orderId = this.$node.data('order');
        const itemId = this.$node.data('item');
        const index = this.$node.data('index');
        $.post(this.getUrl(orderId, itemId), {index: index}).
            done(() => this.trigger('showFixedMessage',  {message: regional().messages[1038]}));
    };

    this.after('initialize', function () {
        this.on('click', this.onClick);
    });
}
