import defineComponent from '../../../../components/flight/lib/component';

export default defineComponent(MachinePrintRunEditor);

function MachinePrintRunEditor() {

    this.attributes({
        savePrintRunButton: '[data-type="save-print-run-btn"]',
        deletePrintRunRowButton: '[data-type="delete-print-run-row-btn"]',
        addPrintRunRowButton: '[data-type="add-print-run-row-btn"]',
        printRunTableBody: '[data-type="print-run-table-body"]',
        printRunRowMin: '[data-field="print-run-min"]',
        printRunRowMax: '[data-field="print-run-max"]',
        printRunRowSetup: '[data-field="print-run-setup"]',
        printRunRowPrice: '[data-field="print-run-price"]',
        printRunRowMinimumPrice: '[data-field="print-run-minimum-price"]',
        incrementDecrementPrintRunPriceBtnSelector: '[data-type="increment-decrement-price-list-btn"]',
        incrementDecrementPrintRunAmountSelector: '[data-type="increment-decrement-price-list-amount"]'
    });

    this.savePrintRun = function(event) {

        const saveUrl = `/admin/catalogue/machines/${this.machineId}/update-print-run-prices`;
        let printRunEditor = this;

        if(this.node.checkValidity()) {

            printRunEditor.initSaveBtn();

            let tableRows = this.select('printRunTableBody').children('tr');

            let printRuns = [];
            tableRows.each(function(){
                printRuns.push({
                    min: $(this).find('[data-field="print-run-min"]').val(),
                    max: $(this).find('[data-field="print-run-max"]').val(),
                    setup: $(this).find('[data-field="print-run-setup"]').val(),
                    price: $(this).find('[data-field="print-run-price"]').val(),
                    minimum: $(this).find('[data-field="print-run-minimum-price"]').val()
                });
            });

            $.ajax({
                url: saveUrl,
                type: 'POST',
                data: {
                    printRunType: this.printRunType,
                    printRunData: JSON.stringify(printRuns)
                },
                success: function(data) {
                    printRunEditor.resetSaveBtn();
                },
                fail: function(error) {
                    printRunEditor.resetSaveBtn();
                    alert('Error Saving Data');
                }
            });

            event.preventDefault();
        } else {
            $(this.node).addClass('was-validated');
        }
    };

    this.deletePrintRunRow = function(event) {
        let tableRow = $(event.target).closest('tr');
        tableRow.remove();
        event.preventDefault();
    };

    this.initSaveBtn = function(event) {
        let saveBtn = this.select('savePrintRunButton');
        setTimeout( function() {
            saveBtn.addClass('disabled');
            saveBtn.find('[data-type="spinner"]').removeClass('d-none');
            saveBtn.find('[data-type="btn-label"]').text("Saving");
        }, 1);
    };

    this.resetSaveBtn = function(event) {
        let saveBtn = this.select('savePrintRunButton');
        setTimeout( function() {
            saveBtn.find('[data-type="spinner"]').addClass('d-none');
            saveBtn.find('[data-type="btn-label"]').text("Save");
            saveBtn.removeClass('disabled');
        }, 2000);
    };

    this.addPrintRunRow = function(event) {
        let newPrintRunRow = `
            <tr>
                <td>
                    <input min="0" max="999999999" step="1" class="form-control" type="number" data-field="print-run-min" required />
                </td>
                <td>
                    <input min="0" max="999999999" step="1" class="form-control" type="number" data-field="print-run-max" required />
                </td>
                <td>
                    <input min="0" max="999999999" step="0.001" class="form-control" type="number" data-field="print-run-setup" required />
                </td>
                <td>
                    <input min="0" max="999999999" step="0.001" class="form-control" type="number" data-field="print-run-price" required />
                </td>
                <td>
                    <input min="0" max="999999999" step="0.001" class="form-control" type="number" data-field="print-run-minimum-price" required />
                </td>
                <td class="text-end">
                    <button class="btn btn-danger" data-type="delete-print-run-row-btn">Delete</button>
                </td>
            </tr>
        `;

        this.select('printRunTableBody').append(newPrintRunRow);

        event.preventDefault();
    };

    this.incrementDecrementPrintRunPrice = function() {
        const percentageChange = this.select('incrementDecrementPrintRunAmountSelector').val();
        let tableRows = this.select('printRunTableBody').children('tr');

        tableRows.each(function(){
            let price = $(this).find('[data-field="print-run-price"]').val();
            if(price > 0 && percentageChange !== 0) {
                let newPrice = Number((price * (1.0 + (percentageChange / 100.0))).toFixed(3));
                $(this).find('[data-field="print-run-price"]').val(newPrice);
            }
        });
    };

    this.after('initialize', function () {
        this.printRunType = $(this.node).data('print-run-type');
        this.machineId = $(this.node).data('machine-id');
        this.catalogId = $(this.node).data('catalog-id');
        this.on('click', {
            savePrintRunButton: this.savePrintRun,
            deletePrintRunRowButton: this.deletePrintRunRow,
            addPrintRunRowButton: this.addPrintRunRow,
            incrementDecrementPrintRunPriceBtnSelector: this.incrementDecrementPrintRunPrice
        });
    });
}
