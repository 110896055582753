import mixam from '../../boot/mixam';
import defineComponent from '../../../components/flight/lib/component';
import Mustache from '../../../components/mustache/mustache';
import templateTimeline from 'text!../../../appes6/templates/order/timeline.mustache';
import MomentZone from "moment-timezone";

export default defineComponent(OrderTimeliner);

const dateToDateString = (timestamp) => {
    if (timestamp) {
        // OMG dates in JavaScript...
        const utcDate = MomentZone.utc(timestamp);
        const dateInShopTimezone = utcDate.tz(mixam.shop.timeZone);
        return dateInShopTimezone.format('YYYY-MM-DD');
    }
};

const dateToTimeString = (timestamp) => {
    if (timestamp) {
        // OMG dates in JavaScript...
        const utcDate = MomentZone.utc(timestamp);
        const dateInShopTimezone = utcDate.tz(mixam.shop.timeZone);
        return dateInShopTimezone.format('HH:mm');
    }
};

function OrderTimeliner() {
    const iconMap = {
       "INIT": "fa fa-bookmark-o",
        "ADDRESS" : "fa fa-user",
        "UPLOAD": "fa fa-cloud-upload",
        "PUSHED": "fa fa-save",
        "FAILURE": "fa fa-frown-o",
        "MODIFIED": "fa fa-pencil",
        "PAID": "fa fa-credit-card",
        "REMARK": "fa fa-comment",
        "CONFIRMED": "fa fa-check-square-o",
        "STATUS": "fa fa-dashboard",
        "ITEM_STATUS": "fa fa-dashboard",
        "REMOVED": "fa fa-trash",
        "MERGED": "fa fa-puzzle-piece",
        "ITEM_DELAY": "fa fa-hourglass",
        "BLEED": "fa fa-compress",
        "EMAILSENT": "fa-envelope-o",
        "POSTBACK": "fa-backward",
        "RENAME": "fa fa-pencil",
        "VAT": "fa mx-govuk",
        "CREDIT": "fa fa-credit-card",
        "DELIVERY_METHOD": "fa fa-truck",
        "QUERY_UPDATE": "fa fa-refresh",
        "API_ACCEPTED": "fa fa-check",
        "API_FAILED": "fa fa-thumbs-o-down",
        "LABELED": "fa fa-tag",
        "DISPATCHED": "fa fa-rocket",
        "REROUTE": "fa fa-arrows",
        "ROUTED": "fa fa-arrows",
        "DELIVERY_FALLBACK": "fa fa-truck",
        "FULFILMENT_DELIVERY_FALLBACK": "fa fa-truck",
        "DISPUTE_OPEN": "fa fa-gavel",
        "DISPUTE_UPDATE": "fa fa-gavel",
        "DISPUTE_CLOSE": "fa fa-gavel"
    };

    this.attributes({
        field: "sum"
    });

    this.render = function () {
        return Mustache.render(templateTimeline, this.normalize(this.order));
    };

    this.normalize = function (order) {
        var line = order.timeline.map(item => {
                return {
                    createdDateText: dateToDateString(item.dateCreated),
                    createdTimeText: dateToTimeString(item.dateCreated),
                    timelineIcon:  iconMap[item.type],
                    body: item.body,
                    header: item.header
                };
            }).reverse();
        return {
            timeline: line
        };
    };

    this.setOrder = function(event, order) {
        this.order = order;
        this.$node.html(this.render());
    };

    this.after('initialize', function () {
        this.on(document, "setOrderData", this.setOrder);
    });
}
