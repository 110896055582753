var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import ReactTimeAgo from 'react-time-ago';
var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    width: 100%;\n"], ["\n    width: 100%;\n"])));
var TimeBox = function (_a) {
    var data = _a.data;
    var box = null;
    if (data) {
        return (React.createElement(ReactTimeAgo, { date: data, timeStyle: "twitter-minute-now", locale: "en-US" }));
    }
    return (React.createElement(Wrapper, { className: "time-box" }, box));
};
export default TimeBox;
var templateObject_1;
