import defineComponent from '../../../../components/flight/lib/component';
import axios from "axios";

export default defineComponent(MonduManager);

function MonduManager() {

    this.attributes({
        paymentSelector: '[data-type="mondu-payment-types"]',
        radioSelector: '[data-type="mondu-payment-type-radio"]',
        submitButtonSelector: '[data-type="mondu-submit-btn"]'
    });

    this.checkout = function () {
        this.select('submitButtonSelector').attr('data-loading', '*').attr('disabled', true);
        let paymentType = null;

        const radios = this.select('radioSelector');

        radios.each(function () {
            if (this.checked) {
                paymentType = this.value;
            }
        });
        let orderId = this.orderId;

        let form = {
            orderId: orderId,
            monduPaymentType: paymentType
        };

        axios.post(`/api/mondu/orders`, form)
            .then(response => {
                let newWindowProxy = window.location.replace(response.data.url);

                if(newWindowProxy) {
                    newWindowProxy.focus();
                }

                this.select('submitButtonSelector').removeAttr('data-loading').attr('disabled', false);
            })
            .catch(error => {
                if(error.response && error.response.status === 400) {
                    console.error(error);
                }
            });
    };

    this.checkRadioSelection = function () {
        const radios = this.select('radioSelector');
        const submitButton = this.select('submitButtonSelector');

        let isSelected = false;
        radios.each(function () {
            if (this.checked) {
                isSelected = true;
            }
        });

        submitButton.prop('disabled', !isSelected);
    };

    this.after('initialize', function () {
        const data = this.$node.data();
        this.orderId = data.orderId;

        this.select('errorSelector').hide();

        this.on('click', {
            submitButtonSelector: this.checkout
        });

        this.on('change', {
            radioSelector: this.checkRadioSelection
        });

    });
}
