import defineComponent from '../../../../../components/flight/lib/component';
import WithDashboardComponent from '../../../with-dashbord-component';
import WithConfirmCancelBar from '../../../with-confirm-cancel-bar';
import Chartjs from '../../../../../components/Chart.js';

export default defineComponent(AnalyticsAccountManagers, WithDashboardComponent, WithConfirmCancelBar);

function AnalyticsAccountManagers() {

    this.attributes({
        chartSelector: '.account-manager-chart',
        clipperSelector: '.donat-clipper',
        dataElementSelector: '.counter-data'
    });

    this.isInteresting = function (counter) {
        return counter.cycle === "DAILY" && counter.type.indexOf("HandleBy_") === 0;
    };


    this.display = function () {
        if (this.timer) {
            clearTimeout(this.timer);
        }

        this.timer = setTimeout(() => {
            this.timer = null;
            this.$elements.each( (index, element) => {
                var $element = $(element);
                this.chart.datasets[0].bars[index].value = this.calcTotal($element, "value");
                this.chart.datasets[1].bars[index].value = this.calcTotal($element, "sum");
            });

            this.chart.update();
        }, 200);
    };


    this.init = function () {
        var data = {
                labels: this.$elements.map(function () {
                    return $(this).data("name");
                }).get(),
                datasets: [
                    {
                        label: "Net Value",
                        fillColor: "rgba(70, 191, 189, 0.65)",
                        strokeColor: "rgba(70, 191, 189, 0.85)",
                        highlightFill: "rgba(70, 191, 189, 0.95)",
                        highlightStroke: "rgba(70, 191, 189, 1)",
                        data: this.$elements.map(function () {
                            return 0;
                        }).get()
                    },
                    {
                        label: "Sum",
                        fillColor: "rgba(253, 180, 92,0.65)",
                        strokeColor: "rgba(253, 180, 92,0.85)",
                        highlightFill: "rgba(253, 180, 92,0.95)",
                        highlightStroke: "rgba(253, 180, 92,1)",
                        data: this.$elements.map(function () {
                            return 0;
                        }).get()
                    }
                ]
            },
            ctx = this.select('chartSelector').get(0).getContext("2d");

        /** @namespace Chartjs.Bar */
        this.chart = new Chartjs(ctx).Bar(data, this.getChartOptions());
    };


    this.after('initialize', function () {
        this.counters = {};
        this.$elements = this.select('dataElementSelector');

        this.init();
        this.reset();
        this.on(document, "dashbordReset", this.reset);
        this.on(document, "dashbordCounterUpdate", this.counterUpdate);
    });

}