import defineComponent from '../../../components/flight/lib/component';
import axios from "axios";

export default defineComponent(StripeConnectAccountManager);

function StripeConnectAccountManager() {

    this.attributes({
        linkAccountBtn: '[data-type="link-account-btn"]',
        accountLoginBtn: '[data-type="account-login-btn"]',
        accountDetailsLoader: '[data-type="account-details-loader"]',
        accountDetailsError: '[data-type="account-details-error"]',
        bankName: '[data-type="bank-name"]',
        bankNameText: '[data-type="bank-name"] span',
        currencyCode: '[data-type="currency-code"]',
        countryCode: '[data-type="country-code"]',
        last4: '[data-type="last4"]',
        cardType: '[data-type="card-type"]',
        cardTypeText: '[data-type="card-type"] span',
        bankAccountDetails: '[data-type="bank-account-details"]',
        paymentTypeBank: '[data-type="payment-type-bank"]',
        paymentTypeCard: '[data-type="payment-type-card"]',
        accountLinkedText: '[data-type="account-linked-text"]',
        accountNotLinkedText: '[data-type="account-not-linked-text"]'
    });

    this.retrieveAccountDetails = function() {

        const manager = this;
        const memberId = manager.memberId ? manager.memberId : '';

        axios.get(`/stripe/account/api/account/${memberId}`).then(
            (response) => {

                if(response.data.hasAccount && response.data.chargesEnabled && response.data.detailsSubmitted) {
                    // Account Is Linked And Good To Go - Show Account Details
                    manager.select('accountLoginBtn').removeClass('hidden');
                    if(response.data.externalAccounts.data.length > 0) {
                        const paymentDetails = response.data.externalAccounts.data[0];
                        switch(paymentDetails.object) {
                            case 'bank_account':
                                manager.select('bankNameText').text(paymentDetails.bankName);
                                manager.select('bankName').removeClass('hidden');
                                manager.select('paymentTypeBank').removeClass('hidden');
                                break;
                            case 'card':
                                manager.select('cardTypeText').text(paymentDetails.brand);
                                manager.select('cardType').removeClass('hidden');
                                manager.select('paymentTypeCard').removeClass('hidden');
                                break;
                        }
                        manager.select('last4').text(paymentDetails.last4);
                        manager.select('countryCode').text(paymentDetails.country);
                        manager.select('currencyCode').text(String(paymentDetails.currency).toUpperCase());
                        manager.select('bankAccountDetails').removeClass('hidden');
                        manager.select('accountLinkedText').removeClass('hidden');
                    }
                } else {
                    // On-boarding Has Not Been Started or Completed
                    manager.select('accountNotLinkedText').removeClass('hidden');
                    manager.select('linkAccountBtn').removeClass('hidden');
                }
                manager.select('accountDetailsLoader').remove();
            }
        ).catch((error) => {
            manager.select('accountDetailsLoader').remove();
            manager.select('accountDetailsError').removeClass('hidden');
        });
    };

    this.after('initialize', function () {
        this.memberId = $(this.node).data('member-id');
        this.retrieveAccountDetails();
    });

}


