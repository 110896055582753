import defineComponent from '../../../components/flight/lib/component';
import mixam from '../../boot/mixam';
import axios from 'axios';

export default defineComponent(CountryStateSelector);

const apiKey = 'YXNoNGRtMGdkeXo5b2FFeGprODFEd0ZVRjNlZ1F1V0o3TmxaQVZTMg==';

function CountryStateSelector() {

    this.attributes({
        countrySelectSelector: '[data-field="country-code"]',
        countryLoaderSelector: '[data-type="country-loader"]',
        stateSelectSelector: '[data-field="region"]',
        stateContainerSelector: '[data-type="state-container"]',
    });

    this.getAllCountries = function () {
        const self = this;
        axios({
            method: 'get',
            url: 'https://api.countrystatecity.in/v1/countries',
            headers: {
                'X-CSCAPI-KEY': apiKey
            }
        })
            .then(function (response) {
                const sortedCountries = response.data.sort((a, b) => {
                    return a.name.localeCompare(b.name);
                });

                self.initCountrySelect(sortedCountries);
            })
            .catch(function (error) {
                console.error(error);
            });
    };

    this.getStatesOfCountry = async function (countryCode) {
        const self = this;
        try {
            if (countryCode) {
                const response = await axios({
                    method: 'get',
                    url: `https://api.countrystatecity.in/v1/countries/${countryCode}/states`,
                    headers: {
                        'X-CSCAPI-KEY': apiKey
                    }
                });

                const sortedStates = response.data.sort((a, b) => {
                    return a.name.localeCompare(b.name);
                });

                self.initStates(sortedStates);
            }
        } catch (error) {
            console.error("Error occurred:", error);
        }
    };

    this.initCountrySelect = function (allCountries) {
        let initialValue = this.select('countrySelectSelector').data('initial-value');
        if(!initialValue) {
            initialValue = mixam.shop.locale.slice(-2);
        }
        let countryOptions = '';
        allCountries.forEach(country => {
            let selected = initialValue === country.iso2 ? 'selected="selected"' : '';
            countryOptions += `<option value="${country.iso2}" ${selected}>${country.name}</option>`;
        });
        this.select('countrySelectSelector').empty().append(countryOptions);
        this.select('countrySelectSelector').prop('disabled', false);
        this.select('countryLoaderSelector').addClass('d-none');
        this.select('countrySelectSelector').removeClass('d-none');
        this.getStatesOfCountry(initialValue);
    };

    this.initStates = function (countryStates) {
        this.select('stateContainerSelector').addClass('d-none');
        let initialValue = this.select('stateSelectSelector').data('initial-value');
        let stateOptions = `<option value="">---Please Select---</option>`;
        countryStates.forEach(state => {
            let selected = initialValue === state.iso2 ? 'selected="selected"' : '';
            stateOptions += `<option value="${state.iso2}" ${selected}>${state.name}</option>`;
        });
        this.select('stateSelectSelector').empty().append(stateOptions);
        this.select('stateSelectSelector').prop('disabled', false);
        this.select('stateContainerSelector').removeClass('d-none');
    };

    this.after('initialize', function () {
        this.countryCode = $(this.node).data('country-code');
        this.getAllCountries();

        this.select('stateSelectSelector').prop('disabled', true);

        this.on('change', {
            countrySelectSelector: () => {
                this.select('stateSelectSelector').prop('disabled', true);
                this.getStatesOfCountry(this.select('countrySelectSelector').val());
            }
        });
    });

}
