import mixam from '../../boot/mixam';
import defineComponent from '../../../components/flight/lib/component';
import WithQueryParams from '../with-query-params';
import WithCatalogPaperTypes from './with-catalog-paper-types';

export default defineComponent(SantaEditor, WithQueryParams, WithCatalogPaperTypes);

function SantaEditor() {

    this.attributes({
        loadablePriceCalculator: '.tab-pane.active [data-type="price-calculator-loader"]'
    });

    this.changeProduct = function() {
        const $loadablePriceCalculator = this.select('loadablePriceCalculator');
        if ($loadablePriceCalculator.length !== 0) {
            const productName = $loadablePriceCalculator.data('productName'),
                productId = $loadablePriceCalculator.data('productId'),
                subProductId = $loadablePriceCalculator.data('subProductId');
            const html = `
                    <price-calculator
                            data-type="price-calculator"
                            secureDomain="${mixam.secureDomain}"
                            locale="${mixam.shop.locale.replace('_', '-')}"
                            theme='${mixam.shop.themeJson && mixam.shop.themeJson}'
                            data='{"productName":"${productName}", "santaType":"QUOTE", "queryResponseTopicId":"${mixam.queryResponseTopicId}", "data":[{"subProductId":${subProductId}, "productId":${productId}}]}'
                            showPrintTemplate="true"
                            timezone="${mixam.shop.timeZone}"
                            useReactCart="true"
                            redirectToCart="${mixam.shop.redirectToCart}"
                            >
                        <div class="text-center">
                            <div>Loading Price Calculator</div>
                            <div class="h1">
                                <i class="fa fa-spin fa-spinner"></i>
                            </div>
                        </div>
                    </price-calculator>
                `;
            $loadablePriceCalculator.parent().html(html);
        }
    };

    this.after('initialize', function () {
        this.on(document, 'santaProductChange', this.changeProduct);
        setTimeout(() => this.changeProduct(null, {}), 1);
    });

}
