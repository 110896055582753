var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useCallback, useState, useEffect } from 'react';
import update from 'immutability-helper';
import { FaqItem } from './FaqItem';
import withScrolling, { createVerticalStrength } from 'react-dnd-scrolling';
import { Button } from 'react-bootstrap';
import axios from "axios";
var ScrollZone = withScrolling('div');
var vStrength = createVerticalStrength(200);
var faqUrl = "/admin/api/faq";
export var FaqList = function (props) {
    {
        var _a = useState([]), faqItems_1 = _a[0], setFaqItems_1 = _a[1];
        var _b = useState(true), isDataLoading_1 = _b[0], setDataLoading_1 = _b[1];
        var getFaq_1 = function () {
            axios.get("".concat(faqUrl, "/").concat(props.faqType, "/").concat(props.id))
                .then(function (response) {
                setFaqItems_1(response.data);
                setDataLoading_1(false);
            });
        };
        useEffect(function () { getFaq_1(); }, []);
        var saveFaq_1 = function () { return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!!isDataLoading_1) return [3 /*break*/, 2];
                        setDataLoading_1(true);
                        return [4 /*yield*/, axios.post("".concat(faqUrl, "/").concat(props.faqType, "/").concat(props.id), faqItems_1).then(function () {
                                setDataLoading_1(false);
                                window.dispatchEvent(new CustomEvent('showFixedMessage', { detail: { message: { type: 'info',
                                            body: "Saved FAQs",
                                            interval: 30000 } } }));
                            }).catch(function () { return window.dispatchEvent(new CustomEvent('showFixedMessage', { detail: { message: { type: 'error',
                                        body: "Error, Failed to save FAQs",
                                        interval: 30000 } } })); })];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        }); };
        var addFaqItem_1 = function () {
            var newFaqItem = {
                id: faqItems_1.length > 0 ? Math.max.apply(Math, faqItems_1.map(function (item) { return item.id; })) + 1 : 1,
                content: '',
                title: '',
                newItem: true
            };
            setFaqItems_1(__spreadArray(__spreadArray([], faqItems_1, true), [newFaqItem], false));
        };
        var moveFaqItem_1 = useCallback(function (dragIndex, hoverIndex) {
            setFaqItems_1(function (prevItems) {
                return update(prevItems, {
                    $splice: [
                        [dragIndex, 1],
                        [hoverIndex, 0, prevItems[dragIndex]],
                    ],
                });
            });
        }, []);
        var updateFaqItem_1 = useCallback(function (id, title, content) {
            setFaqItems_1(function (prevItems) {
                return prevItems.map(function (item) {
                    return item.id === id ? __assign(__assign({}, item), { title: title, content: content }) : item;
                });
            });
        }, []);
        var deleteFaqItem_1 = useCallback(function (id) {
            setFaqItems_1(function (prevItems) {
                return prevItems.filter(function (item) { return item.id != id; });
            });
        }, []);
        var renderFaqItem_1 = useCallback(function (faqItem, index) {
            return (React.createElement(FaqItem, { key: faqItem.id, index: index, id: faqItem.id, title: faqItem.title, content: faqItem.content, newItem: faqItem.newItem, moveFaqItem: moveFaqItem_1, updateFaqItem: updateFaqItem_1, deleteFaqItem: deleteFaqItem_1 }));
        }, []);
        return (React.createElement("div", null,
            React.createElement(ScrollZone, { verticalStrength: vStrength }, faqItems_1.map(function (faqItem, i) { return renderFaqItem_1(faqItem, i); })),
            React.createElement(Button, { variant: "primary", className: "mt-3 me-1", onClick: function (e) { saveFaq_1(); } }, "Save"),
            React.createElement(Button, { variant: "warning", className: "mt-3", onClick: function (e) { addFaqItem_1(); } }, "Add Section")));
    }
};
