import defineComponent from '../../../../../components/flight/lib/component';
import DataTable from './../../../table/data-table-net/data-table-net.tsx';
import axios from "axios";

export default defineComponent(FeatureRequestsDataTable);

function FeatureRequestsDataTable() {

    this.attributes({
        url: "/admin/analytics/feature-requests",
        dataTableSelector: '[data-type="data-table"]'
    });

    this.createSchema = function () {
        const cols = [];
        cols.push({
            title: "Email",
            type: "text",
            data: "email"
        });
        cols.push({
            title: "First Name",
            type: "text",
            data: "firstName"
        });
        cols.push({
            title: "Surname",
            type: "text",
            data: "surname"
        });
        cols.push({
            title: "Product",
            type: "text",
            data: "productName"
        });
        cols.push({
            title: "Copies",
            type: "number",
            data: "copies"
        });
        cols.push({
            title: "Description",
            type: "text",
            data: "itemDescription"
        });
        cols.push({
            title: "Cost Price",
            type: "number",
            decimal: 2,
            currency: true,
            data: "costPrice"
        });
        cols.push({
            title: "Retail Price",
            type: "number",
            decimal: 2,
            currency: true,
            data: "retailPrice"
        });
        cols.push({
            title: "Feature Request Type",
            type: "text",
            data: "featureRequestType"
        });
        cols.push({
            title: "Locale",
            type: "text",
            data: "locale"
        });
        return cols;
    };


    this.paint = function () {
        const data = {
            columns: this.createSchema(),
            response: this.normalize(this.data)
        };

        if (this.dataReadyFired) {
            window.dispatchEvent(new CustomEvent('uiDataUpdate', { detail: data }));
        } else {
            this.dataReadyFired = true;
            this.trigger("uiDataReady", data);
        }
    };

    this.normalize = function (list) {
        return list.map(x => this.normalizePage(x));
    };

    this.normalizePage = function (page_) {
        return $.extend(true, {}, page_);
    };

    this.getData = function () {
        axios.get(this.attr.url)
            .then(response => {
                this.data = response.data;
                this.paint();
                setTimeout(() => this.getData(), 1000 * 60 * 15);
            }).catch(error => this.trigger("log", {message: error}));
    };

    this.after('initialize', function () {
        DataTable.attachTo(this.select('dataTableSelector'));
        setTimeout(() => this.getData(), 10);
    });
}