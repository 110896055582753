export default  WithChartData;

function WithChartData() {
    this.data2ChartData = function(rowData) {
        const data = {};
        let iColor = 0;

        rowData.forEach(c => {
            if (!data[c.year]) {
                data[c.year] = {
                    data: [0,0,0,0,0,0,0,0,0,0,0,0],
                    label: c.year,
                    fillColor: this.getColor(iColor, 0.5),
                    strokeColor: this.getColor(iColor, 0.95),
                    highlightFil: this.getColor(iColor, 0.75),
                    highlightStroke: this.getColor(iColor, 1),
                };
                iColor += 1;
            }

            data[c.year].data[c.month] = c.total;
        });

        const aKeys = Object.keys(data).sort();
        return aKeys.map(x => data[x]);
    };

}