export default {
    INIT: 0,
    PENDING: 1,
    RIPE: 4,
    ONHOLD: 5,
    LOCKED: 6,
    PROOF: 7,
    CONFIRMED: 10,
    INPRODUCTION: 11,
    ACCEPTED: 15,
    DISPATCHED: 20,
    CLOSED: 100,
    CANCELED: 120,

    getBadgeColour: function (orderStatus) {
        switch (orderStatus) {
            case this.INIT:
            case this.PENDING:
            case this.RIPE:
                return 'secondary';
            case this.ONHOLD:
            case this.CANCELED:
                return 'danger';
            case this.CONFIRMED:
            case this.INPRODUCTION:
            case this.ACCEPTED:
                return 'primary';
            case this.LOCKED:
            case this.PROOF:
                return 'warning';
            default:
                return 'info';
        }
    }
};
