import defineComponent from '../../../../components/flight/lib/component';

export default defineComponent(MachineAllowedProductsEditor);

function MachineAllowedProductsEditor() {

    this.attributes({
        saveAllowedProductsButton: '[data-type="save-allowed-products-btn"]',
        allowedProductsTableBody: '[data-type="allowed-products-table-body"]',
    });

    this.saveAllowedProducts = function(event) {

        const saveUrl = `/admin/catalogue/machines/${this.machineId}/update-allowed-products`;

        if(this.node.checkValidity()) {

            this.initSaveBtn();

            let editor = this;

            let tableRows = this.select('allowedProductsTableBody').children('tr');

            let productIds = [];
            tableRows.each(function(){
                let enabled = $(this).find('[data-field="allowed-product-is-enabled"]').val();
                if(enabled === 'true') {
                    productIds.push($(this).data('product-id'));
                }
            });

            $.ajax({
                url: saveUrl,
                type: 'POST',
                data: {
                    productIds: JSON.stringify(productIds)
                },
                success: function(data) {
                    editor.resetSaveBtn();
                },
                error: function(error) {
                    editor.resetSaveBtn();
                    alert('Error Saving Data');
                }
            });

            event.preventDefault();
        }

    };

    this.initSaveBtn = function(event) {
        let saveBtn = this.select('saveAllowedProductsButton');
        setTimeout( function() {
            saveBtn.addClass('disabled');
            saveBtn.find('[data-type="spinner"]').removeClass('d-none');
            saveBtn.find('[data-type="btn-label"]').text("Saving");
        }, 1);
    };

    this.resetSaveBtn = function(event) {
        let saveBtn = this.select('saveAllowedProductsButton');
        setTimeout( function() {
            saveBtn.find('[data-type="spinner"]').addClass('d-none');
            saveBtn.find('[data-type="btn-label"]').text("Save");
            saveBtn.removeClass('disabled');
        }, 2000);
    };

    this.after('initialize', function () {
        this.machineId = $(this.node).data('machine-id');
        this.catalogId = $(this.node).data('catalog-id');
        this.on('click', {
            saveAllowedProductsButton: this.saveAllowedProducts
        });
    });
}
