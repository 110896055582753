var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
// @ts-ignore
import defineComponent from '../../../../components/flight/lib/component.js';
import React, { useEffect, useState } from 'react';
import ReactDom from 'react-dom/client.js';
import Table from 'react-bootstrap/Table';
import axios from "axios";
import NexusCollectionType from "../../constants/nexusCollectionType.js";
var ReactTableNexus = function () {
    var _a = useState(), currentData = _a[0], setCurrentData = _a[1];
    var _b = useState(false), loading = _b[0], setLoading = _b[1];
    var options = [
        { value: 0, label: NexusCollectionType.getNexusCollectionTypeLabel(0), type: 'collectionType' },
        { value: 1, label: NexusCollectionType.getNexusCollectionTypeLabel(1), type: 'collectionType' }
    ];
    useEffect(function () {
        axios.get("/admin/shop/configuration/nexuses").then(function (_a) {
            var data = _a.data;
            setCurrentData(data);
        });
    }, []);
    function handleSave() {
        setLoading(true);
        axios.put("/admin/shop/configuration/nexuses", currentData).then(function () {
            setLoading(false);
        }).catch(function (error) { return console.log('There was an error saving nexuses', error); });
    }
    function handleInputChange(e, index) {
        var tempCurrentData = __spreadArray([], currentData, true);
        var id = e.currentTarget.id;
        tempCurrentData[index][id] = e.currentTarget.value;
        setCurrentData(tempCurrentData);
    }
    function handleSelectChange(e, index) {
        var tempCurrentData = __spreadArray([], currentData, true);
        tempCurrentData[index].collectionType = e.target.value;
        setCurrentData(tempCurrentData);
    }
    function handleCreate() {
        var tempCurrentData = __spreadArray([], currentData, true);
        var item = {
            prefixFrom: "",
            prefixTo: "",
            origin: "",
            name: "",
            province: "",
            tax: "",
            taxOnDelivery: "",
            collectionType: "0"
        };
        tempCurrentData.push(item);
        setCurrentData(tempCurrentData);
    }
    return React.createElement(React.Fragment, null,
        " ",
        currentData ?
            (React.createElement("div", null,
                React.createElement(Table, { striped: true, bordered: true, hover: true },
                    React.createElement("thead", null,
                        React.createElement("tr", null,
                            React.createElement("th", { key: "Name" }, "Name"),
                            React.createElement("th", { key: "Province" }, "Province"),
                            React.createElement("th", { key: "Collection Type" }, "Collection Type"),
                            React.createElement("th", { key: "Tax (%)" }, "Tax (%)"),
                            React.createElement("th", { key: "Tax On Delivery (%)" }, "Tax On Delivery (%)"),
                            React.createElement("th", { key: "Prefix From" }, "Prefix From"),
                            React.createElement("th", { key: "Prefix To" }, "Prefix To"),
                            React.createElement("th", { key: "Origin" }, "Origin"))),
                    React.createElement("tbody", null, currentData.map(function (nexus, index) { return (React.createElement("tr", { key: index },
                        React.createElement("td", null,
                            React.createElement("input", { className: "form-control", id: "name", onChange: function (e) { return handleInputChange(e, index); }, defaultValue: nexus.name, type: "string" })),
                        React.createElement("td", null,
                            React.createElement("input", { className: "form-control", id: "province", onChange: function (e) { return handleInputChange(e, index); }, defaultValue: nexus.province, type: "string" })),
                        React.createElement("td", null,
                            React.createElement("select", { className: "form-control", id: "collectionType", onChange: function (e) { return handleSelectChange(e, index); }, defaultValue: nexus.collectionType }, options.map(function (option) { return React.createElement("option", { key: option.value, value: option.value }, option.label); }))),
                        React.createElement("td", null,
                            React.createElement("input", { className: "form-control", id: "tax", onChange: function (e) { return handleInputChange(e, index); }, defaultValue: nexus.tax, type: "number" })),
                        React.createElement("td", null,
                            React.createElement("input", { className: "form-control", id: "taxOnDelivery", onChange: function (e) { return handleInputChange(e, index); }, defaultValue: nexus.taxOnDelivery, type: "number" })),
                        React.createElement("td", null,
                            React.createElement("input", { className: "form-control", id: "prefixFrom", onChange: function (e) { return handleInputChange(e, index); }, defaultValue: nexus.prefixFrom, type: "number" })),
                        React.createElement("td", null,
                            React.createElement("input", { className: "form-control", id: "prefixTo", onChange: function (e) { return handleInputChange(e, index); }, defaultValue: nexus.prefixTo, type: "number" })),
                        React.createElement("td", null,
                            React.createElement("input", { className: "form-control", id: "origin", onChange: function (e) { return handleInputChange(e, index); }, defaultValue: nexus.origin, type: "number" })),
                        React.createElement("td", null,
                            React.createElement("button", { className: "btn btn-danger", onClick: function () { return setCurrentData(function (prevData) {
                                    prevData.splice(index, 1);
                                    return __spreadArray([], prevData, true);
                                }); } }, "Delete")))); }))),
                React.createElement("div", null,
                    React.createElement("button", { className: "btn btn-primary", onClick: handleCreate }, "Add Nexus")),
                React.createElement("br", null),
                React.createElement("div", null,
                    React.createElement("button", { className: "btn btn-primary", onClick: handleSave, disabled: loading }, loading ? 'Saving...' : 'Save')))) : React.createElement("div", null, "Loading..."));
};
// flight component
function NexusTable() {
    this.attributes({
        containerSelector: 'section.data-table'
    });
    this.renderDataTable = function () {
        var root = ReactDom.createRoot(this.select('containerSelector')[0]);
        root.render(React.createElement(ReactTableNexus, null));
    };
    this.after('initialize', function () {
        this.renderDataTable();
    });
}
var flightNexusTable = defineComponent(NexusTable);
function initializeNexusTable() {
    this.attributes({
        dataTableSelector: '[data-type="nexus-data-table"]'
    });
    this.after('initialize', function () {
        flightNexusTable.attachTo(this.select('dataTableSelector'));
    });
}
export default defineComponent(initializeNexusTable);
