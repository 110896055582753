import defineComponent from '../../../../components/flight/lib/component';

export default defineComponent(ShopEditor);

function ShopEditor() {

    this.attributes({
        addShopDomainBtn: '[data-type="add-shop-domain-btn"]',
        shopDomain: '[data-field="shop-domain"]',
        shopDomainType:  '[data-field="shop-domain-type"]',
        shopDomainsContainer: '[data-type="shop-domains"]',
        removeShopDomainBtn: '[data-type="remove-shop-domain-btn"]'
    });

    this.addShopDomain = function(event) {
        const addUrl = `/admin/shop/configuration/${this.configurationId}/add-domain`;
        const shopDomainUrl = this.select('shopDomain').val();
        const shopDomainType = this.select('shopDomainType').val();
        const data = {
            configurationId: this.configurationId,
            domain: shopDomainUrl,
            type: shopDomainType
        };


        $.post(addUrl, data).done((response) => {
            this.select('shopDomainsContainer').html(response);
        });
    };

    this.removeShopDomain = function(event) {
        const removeUrl = `/admin/shop/configuration/${this.configurationId}/remove-domain`;
        const removeDomainUrl = $(event.target).data('domain-id');
        const data = {
            configurationId: this.configurationId,
            domain: removeDomainUrl
        };

        $.post(removeUrl, data).done((response) => {
            this.select('shopDomainsContainer').html(response);
        });
    };

    this.after('initialize', function () {
        this.configurationId = $(this.node).data('configuration-id');
        this.on('click', {
            addShopDomainBtn: this.addShopDomain,
            removeShopDomainBtn: this.removeShopDomain
        });
    });
}
