import Command from '@ckeditor/ckeditor5-core/src/command';

export default class UpdateWidgetConfigCommand extends Command {

    refresh() {
        const element = this.editor.model.document.selection.getSelectedElement();
        this.isEnabled = this.isWidget(element);
        this.value = this.isEnabled ? {
            name: element.getAttribute('name'),
            label: element.getAttribute('label'),
            'data-config': element.getAttribute('data-config')
        } : null;
    }

    execute(configData) {
        const widget = this.editor.model.document.selection.getSelectedElement();
        this.editor.model.change(writer => {
            writer.setAttribute('data-config', configData, widget);
        });
    }

    isWidget(modelElement) {
        return modelElement && modelElement.name === 'widget';
    }
}