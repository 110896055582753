import WithSubmitAnimation from '../../with-submit-zoom-animation';

import defineComponent from '../../../../components/flight/lib/component';
import mixam from "../../../boot/mixam";

export default defineComponent(VoltPay, WithSubmitAnimation);

function VoltPay() {

    this.attributes({
        paymentCreateUrl: '/api/volt/payment/create',
        payByVoltBtnSelector: '[data-type="pay-by-volt-btn"]',
        voltPaymentContainerSelector: '#volt-payment-component',
        voltIntroSelector: '[data-type="volt-intro"]'
    });

    this.initVoltPay = async function (event) {

        const language = mixam.shop.locale.substring(0,2);
        const country = mixam.shop.locale.substring(3,5).toUpperCase();
        const voltIntroContainer = this.select('voltIntroSelector');

        $.post(this.attr.paymentCreateUrl, { orderId:this.orderId })
            .done(payment => {
                const volt = new window.Volt({ mode: this.mode });
                const paymentContainer = volt.payment({
                    payment,
                    language: language,
                    country: country,
                });
                const paymentComponent = paymentContainer.createPayment();
                paymentComponent.mount(this.attr.voltPaymentContainerSelector);
                voltIntroContainer.hide();
            })
            .fail(err => this.trigger("log", { message: err }));
    };

    this.after('initialize', function () {
        this.orderId = $(this.node).data('order-id');
        this.mode = $(this.node).data('mode');
        this.on('click', {
            payByVoltBtnSelector: this.initVoltPay
        });
    });
}
