import mixam from '../boot/mixam';
import regional from '../boot/regional';

export default WithOffersDisplayList;

function WithOffersDisplayList() {

    this.getAlternativeList = function (data, price, pid, mid, days, santa) {
        var result = {};
        if (data.error) {
            return data;
        }
        result.firstChoice = data.offerTable.filter(x => x.pid === pid && x.mid === mid && x.days === days).map((x, i) => this.getItem(x, i, santa, data.vat));
        result.others = data.offerTable.
            filter(x => !(x.pid === pid && x.mid === mid && x.days === days)).
            sort((a, b) => b.days - a.days).
            map((x, i) => this.getItem(x, i, santa, data.vat, price));

        if (result.others.length) {
            result.hasOthers = true;
            result.others[result.others.length - 1].type = "priority";
        }
        if (mixam.user.hasRole("ROLE_MIXAM")) {
            result.IS_MIXAM = true;
        }

        return result;
    };


    this.getDisplayList = function(data, santa) {
        var result = {};
        const dict = this.createDict(regional().santa);
        if (data.error) {
            data.dict = dict;
            return data;
        }
        result.link = data.link;
        result.firstChoice = data.offerTable.filter(x => x.isBestPrice).map((x, i) => this.normalizeOffer(x, i, santa, data.vat));
        result.others = data.offerTable.filter(x => !x.isBestPrice).sort((a, b) => b.days - a.days).map((x, i) => this.normalizeOffer(x, i, santa, data.vat));
        if (data.approximatedOffers.length) {
            result.approximated = data.approximatedOffers.map((x, i) => this.normalizeOffer(x, i, santa, data.vat));
        }
        result.weight = data.weight;

        result.currency = data.currency;
        if (result.others.length) {
            result.hasOthers = true;
            result.others[result.others.length - 1].type = "express";
        }
        if (mixam.user.hasRole("ROLE_MIXAM")) {
            result.IS_MIXAM = true;
        }
        if (data.vat.included) {
            result.vatIncluded = true;
            result.vatRate = data.vat.rate;
        }
        if ("in-order" === this.mode) {
            result.IN_ORDER = 1;
        }
        result.query = santa;
        result.dict = dict;

        if (data.needDeliveryTask) {
            result.WAITING_FOR_DELIVERY_COST = true;
        }

        result.afterpayAvailable = data.afterpayAvailable;
        // console.log("result", result); // remove me
        return result;
    };


    this.createDict = function (dict) {
        const o = $.extend(true, {}, dict);

        o.addToCart = "in-order" === this.mode ? o.updateOrder : o.addToCart;
        o.kg = regional().weightSymbol;
        o.decimalSymbol = regional().decimalSymbol;
        return  o;
    };


    this.normalizeOffer = function(offer, i, santa, vat) {
        const item = this.getItem(offer, i, santa, vat);

        if (item.days < 1) {
            item.ONE = regional().santa.zeroProductionDay;
        } else if (item.days < 2) {
            item.ONE = regional().santa.oneProductionDay;
        }
        const price = parseFloat(item.price.replace(/,/g, ''));
        if (price > 9999) {
            item.LONG_PRICE = "long-price";
        }
        if (mixam.shop.isUsaShop()) {
            if (item.includeShipment) {
                item.INCLUDE_SHIPMENT = true;
            } else {
                item.NOT_INCLUDE_SHIPMENT = true;
            }
        }
        item.productionDays = item.days;

        item.afterpayAvailable = offer.afterpayAvailable;
        return item;
    };



    this.addVat = function (sum, vat) {
        if (vat.included) {
            sum *= (100 + vat.rate) / 100;
        }
        return sum;
    };

    this.getItem = function (x, i, santa, vat, originalPrice = 0) {
        var priceTemp = this.addVat(this.applyDiscount(x.price || 0, santa), vat),
            price = priceTemp.formatNumber(2),
            delta = originalPrice && ((priceTemp || 0) - originalPrice),
            deltaStr = delta && Math.abs(delta).formatNumber(2),
            parts = (deltaStr || price).split(regional().decimalSymbol),
            fullPrice = price.split(regional().decimalSymbol),
            title = ""; //x.speedy ? "express" : "";

        let priceBeforeDiscount;
        let acctualDiscount;
        if (!originalPrice && x.price > priceTemp) {
            priceBeforeDiscount = x.price.formatNumber(2);
            acctualDiscount =  ((x.price - priceTemp) / x.price * 100).formatNumber(2);
        }
        if (x.type) {
            title = regional().priceType[x.type];
        }

        let productionDaysText = `${x.days} ${regional().santa.order.alternativeDatesProductionDays}`;
        if (x.days === 0) {
            productionDaysText = regional().santa.zeroProductionDay;
        } else if (x.days === 1) {
            productionDaysText = regional().santa.oneProductionDay;
        }

        return {
            id: i + 1,
            includeShipment: x.includeShipment,
            isDigital: x.printType === "DIGITAL",
            isInkjet: x.printType === "INKJET",
            isWideFormat: x.printType === "WIDE_FORMAT",
            isLitho: x.printType === "LITHO",
            isExpress: x.isExpress,
            type: title,
            isBestValue: x.isBestValue,
            name: x.name,
            days: x.days,
            productionDaysText: productionDaysText,
            mid: x.mid,
            region: x.region,
            center:  x.printType === "DIGITAL" && santa.format < 2 ? "Cannon Colorado" : x.center,
            pid: x.pid,
            dueDate: x.days > 35 ? "Please call" : mixam.dateToLongString(x.date),
            rank: (x.rank || 0).formatNumber(2),
            relrank: (x.relrank * 100 || 0).formatNumber(2),
            price: price,
            delta: deltaStr,
            deltaSign: delta && (delta > 0 ? "Add" : "Less"),
            priceBeforeDiscount: priceBeforeDiscount,
            acctualDiscount: acctualDiscount,
            originalPrice: originalPrice ? originalPrice : "",
            price1: parts[0],
            price2: parts[1],
            fullPrice1: fullPrice[0],
            fullPrice2: fullPrice[1],
            nprice: (x.nprice || 0).formatNumber(2),
            daysSaved: x.daysSaved,
            costAdded: (x.costAdded || 0).formatNumber(2),
            costPerDayAdded: ((x.costAdded || 0) / x.daysSaved || 0).formatNumber(0),
            approximatedGain: x.approximatedGain,
            approximatationField: x.approximatationField,
            approximatationValue: x.approximatationValue,
            approximatationUrl: x.approximatationUrl,
            approximatationOriginalValue: x.approximatationOriginalValue,
            HAS_DELIVERIES: x.deliveryRates && x.deliveryRates.length,
            deliveries: this.normalizeDeliveryRates(x.deliveryRates),
            postCode: santa.postCode,
            displayCountryOfOrigin: x.countryOfOrigin && x.countryCode !== x.countryOfOrigin,
            countryOfOriginMessage: x.countryOfOrigin && x.countryCode !== x.countryOfOrigin ? regional().santa.offer.producedIn[x.countryOfOrigin] : ''
        };
    };

    this.normalizeDeliveryRates = function (delivaryRates) {
        if (delivaryRates && delivaryRates.length) {
            return delivaryRates.map(r => {
                r.date = mixam.dateToLongString(r.deliveryDate);
                return r;
            });
        }
    };
}
