import AnimatedCheckbox from '../animated/animated-checkbox';
import defineComponent from '../../../components/flight/lib/component';

export default defineComponent(MemberReviewInvite);

function MemberReviewInvite() {

    this.attributes({
        animatedCheckboxSelector: '[data-toggle="animated-checkbox"]'
    });


    this.after('initialize', function () {
        AnimatedCheckbox.attachTo(this.attr.animatedCheckboxSelector);
    });
}


