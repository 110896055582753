import defineComponent from '../../../../components/flight/lib/component';
import DataTable from './../../table/data-table-net/data-table-net.tsx';
import {Modal} from "bootstrap";
import axios from "axios";

export default defineComponent(SelfPublishingConfigDataTable);

function SelfPublishingConfigDataTable() {

    this.attributes({
        url: "/api/admin/self-publishing/list",
        dataTableSelector: '[data-type="data-table"]',
        confirmDeleteModal: '[data-type="delete-page-confirmation"]'
    });

    this.createSchema = function () {
        const cols = [];
        cols.push({
            title: "Language",
            type: "text",
            data: "language"
        });
        cols.push({
            title: "Active",
            type: "boolean",
            data: "active"
        });
        cols.push({
            title: "Number of FAQs",
            type: "number",
            data: "numberOfFaqs"
        });
        cols.push({
            title: "",
            type: "multiButton",
            width: "180px",
            data: "actions"
        });
        return cols;
    };


    this.paint = function () {
        const data = {
            columns: this.createSchema(),
            response: this.normalize(this.data)
        };
        if (this.dataReadyFired) {
            window.dispatchEvent(new CustomEvent('uiDataUpdate', { detail: data }));
        } else {
            this.dataReadyFired = true;
            this.trigger("uiDataReady", data);
        }
    };

    this.normalize = function (list) {
        return list.map(x => this.normalizePage(x));
    };

    this.normalizePage = function (page_) {
        const page = $.extend(true, {}, page_);

        page.actions = [
            {
                href: `/admin/self-publishing/settings/${page.id}/edit`,
                caption: "Edit",
                className: "btn btn-primary",
                iconClassname: "bi bi-pencil",
                target: "_self"
            },
            {
                href: `/api/admin/self-publishing/${page.id}`,
                caption: "Delete",
                className: "btn btn-danger",
                iconClassname: "bi bi-trash",
                key: "delete",
                role: "ROLE_MIXAM",
                target: "_self",
                callback: (event) => {
                    event.stopPropagation();
                    event.preventDefault();

                    let buttonTarget = $(event.target);

                    const confirmationDialogue = document.querySelector('[data-type="delete-page-confirmation"]');
                    let modal = Modal.getOrCreateInstance(confirmationDialogue);
                    modal.show();

                    $(document.querySelector('[data-type="confirm-button"]')).click(function() {
                        fetch(
                            buttonTarget.attr('href'),
                            {
                                method: 'DELETE',
                            }
                        ).then((response) => {
                            if(response.status === 200) {
                                buttonTarget.closest('.rdt_TableRow').remove();
                            }
                        });
                        modal.hide();
                    });
                }
            }
        ];
        return page;
    };

    this.getData = function () {
        axios.get(this.attr.url)
            .then(response => {
                this.data = response.data;
                this.paint();
                setTimeout(() => this.getData(), 1000 * 60 * 15);
            }).catch(error => this.trigger("log", {message: error}));
    };

    this.after('initialize', function () {
        DataTable.attachTo(this.select('dataTableSelector'));
        setTimeout(() => this.getData(), 10);
    });
}