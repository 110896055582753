export default WithWorkdaysCalculator;

function WithWorkdaysCalculator() {

    this.readWorkDays = function () {
        $.getJSON('/api/next/workingdays/list').done(response => this.setWorkdays(response)).fail((err) => this.trigger("log", {message: err}));
    };

    this.setWorkdays = function (list) {
        this.workdays = list;
        this.fireData();
    };

    this.fireData = function () {
        this.trigger("uiSetWorkdaysList", {list: this.workdays});
    };

    this.getHour = function () {
        return (new Date()).getHours();
    };

    this.addWorkingDays = function (start, days) {

        if (!this.workdays) {
            throw new Error('workdays collection is empty');
        }
        const hour = this.getHour();
        if (days === 0 && hour >= 10 && hour < 14) {
            days += 1;
        }
        return new Date(this.workdays[days]);
    };

    this.after('initialize', function () {
        this.readWorkDays();
        this.on(document, "uiRequestWorkdaysList", this.fireData);
    });
}
