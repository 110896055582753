import mixam from '../boot/mixam';
import defineComponent from '../../components/flight/lib/component';

export default defineComponent(AnalogClock);

function AnalogClock() {

    this.attributes({
        colorFace: '#6c6c6c',
        colorNumbers: '#fff',
        colorHands: '#fff',
        canvasSelector: "canvas",
        boxSelector: ".clock-box",
        timeSelector: ".time-text .time",
        dateSelector: ".time-text .date"
    });

    this.drawClock = function(ctx, radius) {
        this.drawFace(ctx, radius);
        this.drawNumbers(ctx, radius);
        this.drawTime(ctx, radius);
    };

    this.drawFace = function(ctx, radius) {
        ctx.beginPath();
        ctx.arc(0, 0, radius, 0, 2 * Math.PI);
        ctx.fillStyle = this.attr.colorFace;
        ctx.fill();
    };

    this.drawNumbers = function(ctx, radius) {
        ctx.fillStyle = this.attr.colorNumbers;
        ctx.font = radius * 0.15 + "px arial";
        ctx.textBaseline = "middle";
        ctx.textAlign = "center";
        for(let num = 1; num < 13; num++) {
            const ang = num * Math.PI / 6;
            ctx.rotate(ang);
            ctx.translate(0, -radius * 0.85);
            ctx.rotate(-ang);
            ctx.fillText("■", 0, 0);
            ctx.rotate(ang);
            ctx.translate(0, radius * 0.85);
            ctx.rotate(-ang);
        }
    };

    this.drawTime = function(ctx, radius){
        const now =  this.getNow();
        let hour = now.getHours();
        let minute = now.getMinutes();
        let second = now.getSeconds();

        this.select('timeSelector').text(`${mixam.padl(hour, "0", 2)}:${mixam.padl(minute, "0", 2)}:${mixam.padl(second, "0", 2)}`);
        this.select('dateSelector').text(mixam.dateToMediumDateString(now.getTime()));

        hour = hour % 12;
        hour = (hour * Math.PI / 6) +
            (minute * Math.PI / (6 * 60)) +
            (second * Math.PI / (360 * 60));
        this.drawHand(ctx, hour, radius * 0.5, radius * 0.07);
        //minute
        minute = (minute*Math.PI / 30) + (second*Math.PI / (30 * 60));
        this.drawHand(ctx, minute, radius * 0.8, radius * 0.07);
        // second
        second = (second * Math.PI / 30);
        this.drawHand(ctx, second, radius * 0.9, radius * 0.02);
    };

    this.getNow = function () {
        if (!this.timeZone) {
            return new Date();
        }
        const zonedTime = new Date().toLocaleString("en-US", {timeZone: this.timeZone});
        return new Date(zonedTime);
    };

    this.drawHand = function(ctx, pos, length, width) {
        ctx.beginPath();
        ctx.strokeStyle = this.attr.colorHands;
        ctx.lineWidth = width;
        ctx.lineCap = "round";
        ctx.moveTo(0,0);
        ctx.rotate(pos);
        ctx.lineTo(0, -length);
        ctx.stroke();
        ctx.rotate(-pos);
    };

    this.doSizeChange = function () {
        const canvas$ = this.select('canvasSelector');
        const boxSize = this.select("boxSelector").width();
        canvas$[0].width = boxSize;
        canvas$[0].height = boxSize;
        const radius = canvas$.height() / 2;
        const ctx = canvas$[0].getContext("2d");
        ctx.translate(radius, radius);
        if (this.timer) {
            clearInterval(this.timer);
        }
        this.timer = setInterval(() => this.drawClock( ctx, 0.9 * radius), 1000);
    };

    this.after('initialize', function () {
        this.timeZone = this.$node.data("timezone");
        $(window).resize(() => this.doSizeChange());
        this.doSizeChange();
    });
}
