import defineComponent from '../../../components/flight/lib/component';
import WithOffersDisplayList from './../with-offers-display-list';
import WithCustomerDiscount from "./../with-customer-discount";

import Mustache from '../../../components/mustache/mustache';
import templateAlternativeDates from 'text!../../../appes6/templates/order/alternative-dates.mustache';
import regional from '../../boot/regional';

export default defineComponent(DatesEditor, WithOffersDisplayList, WithCustomerDiscount);

function DatesEditor() {

    this.attributes({
        contentSelector: '.content',
        closeBtnSelector: '[data-type="close-alternatives"]',
        updateBtnSelector: '[data-type="update"]',
        otherBtnSelector: '[data-type="other-dates"]'
    });

    this.render = function (offer) {
        return Mustache.render(templateAlternativeDates, {
            orderId: this.order.id,
            itemId: this.item.id,
            dict: regional().santa.order,
            currency: this.item.response.currency,
            list: this.getAlternativeList(offer, this.item.discountTotal, this.item.response.pid, this.item.response.mid, this.item.response.days, this.item.query)
        });
    };

    this.open = function (/*event*/) {
        if (this.item) {
            this.select('otherBtnSelector').attr('data-loading', '*').attr('disabled', true);
            const query = this.item.query;
            Object.keys(query).forEach(x => query[x] = query[x] || (x === 'productName' || x === 'publisher' || x === 'url' ? "" : 0));
            this.trigger('uiNeedQuote', {id: this.id, query: query});
        } else {
            this.pendingOpen = true;
        }
    };

    this.close = function (/*event*/) {
        this.select('closeBtnSelector').addClass('hidden');
        this.select('otherBtnSelector').removeAttr('data-loading').attr('disabled', false).show();
        this.select('contentSelector').collapse('hide');
    };

    this.onQuoteReady = function (event, response) {
        if (this.id === response.id) {
            const $content = this.select('contentSelector');
            this.offer = response;
            $content.html(this.render(this.offer));
            this.select('otherBtnSelector').removeAttr('data-loading').attr('disabled', false).hide();
            $content.collapse('show');
            setTimeout(() => this.select('closeBtnSelector').addClass('active'), 10);
        }
    };

    this.clear = function () {
        this.select('contentSelector').html("");
    };

    this.onSubmit = function (event) {
        $(event.target).find(this.attr.updateBtnSelector).attr('data-loading', '*').attr('disabled', true);
    };

    this.setOrder = function (event, data) {
        this.order = data;
        this.item = this.order.getItem(this.itemId);
        if (this.pendingOpen) {
            this.pendingOpen = null;
            this.open();
        }
    };

    this.after('initialize', function () {
        this.itemId = this.$node.data('item');
        this.id = Math.floor(Math.random() * 2147483648).toString(36);

        this.on(document, "setOrderData", this.setOrder);
        this.on(document, 'dataQuoteReady', this.onQuoteReady);

        this.on("click", {
            otherBtnSelector: this.open,
            closeBtnSelector: this.close
        });
        this.on("submit", this.onSubmit);
        this.on('hidden.bs.collapse', {
            contentSelector: this.clear
        });
    });
}
