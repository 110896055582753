export default WithFancyTime;

function WithFancyTime() {

    function getStartOfDay(days) {
        const now = new Date();
        return new Date(now.getFullYear(), now.getMonth(), now.getDate() + (days || 0), 0, 0, 0, 0).getTime();
    }

    this.timeToDateString = function (time) {
        if (!time) {
            return "";
        }
        const cdate = new Date(time),
            dateTemplate = "d/m/yy",
            timeTemplate = "h:MM TT";

        if (time > getStartOfDay()) {
            return "<div class='timebox'><div class=date-part>Today</div><div class=time-part>" + cdate.format(timeTemplate) + "</div></div>";
        } else if (time > getStartOfDay(-1)) {
            return "<div class='timebox'><div class=date-part>Yesterday</div><div class=time-part>" + cdate.format(timeTemplate) + "</div></div>";
        }
        return "<div class='timebox'><div class=date-part>" + cdate.format(dateTemplate) + "</div><div class=time-part>" + cdate.format(timeTemplate) + "</div></div>";
    };

}
