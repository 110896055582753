const invoiceStatus = {
    '0': 'QUEUED',
    '1': 'AUTHENTICATED',
    '2': 'EXECUTING',
    '4': 'DEQUEUED',
    '8': 'COMPLETED',
    '11': 'MIGHT_FAILED',
    '15': 'FAILED'
};

export default invoiceStatus;