export default [
    {
        id: 0,
        value: "None"
    },
    {
        id: 1,
        value: "Full Refund"
    },
    {
        id: 2,
        value: "Partial Refund"
    },
    {
        id: 3,
        value: "Reprint"
    }
];