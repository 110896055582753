import ReactDom from  'react-dom';
import React from 'react';
const rd = ReactDom;

class TrustpilotStars extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const stars = this.props.data;
        let icons = [];
        const spanClassName = `stars stars-${stars}`;
        for (let i = 0; i < stars; i++) {
            icons.push(<div className="trustpilot-green-star">
                <img src="https://cdn.trustpilot.net/brand-assets/1.3.0/single-star-transparent.svg" />
            </div>);
        }

        return (<div className='trustpilot trustpilot-mini'>
            <div className="review-stars">
                <span className={spanClassName}>
                    {icons}
                </span>
            </div>
        </div>);
    }
}

export default TrustpilotStars;
