import defineComponent from '../../../components/flight/lib/component';

import WithNormalizeMap from '../with-normalize-map';
import Mustache from '../../../components/mustache/mustache';
import templateFlipPages from 'text!../../../appes6/templates/preview/preview-flip-pages.mustache';
import PageFlipper from '../page-flip';
import WithFolderUrl from '../with-get-folder-url';

export default defineComponent(PreviewPanel, WithNormalizeMap, WithFolderUrl);

function PreviewPanel() {

    this.attributes({
        bookPreviewSelector: '.preview-container',
        flipbookSelector: '.preview-container .contain'
    });

    this.render = function () {
        //console.log(data);

        /** @namespace this.order.orderStatusInt */
        return Mustache.render(templateFlipPages, {
            "map": this.normalizeMap(this.order, this.item, false),
            canRemove: !this.order.locked,
            showMore: this.select("pageListSelector").hasClass("show-all") ? 1 : null,
            isRtl: this.item.isRtl ? 1 : null
        });
    };

    this.getItemById = function (order, itemId) {
        return order.items.filter(x => x.id === itemId)[0];
    };

    this.setData = function (event, data) {
        this.order = data.order;
        this.item = this.getItemById(this.order, data.itemId);
        this.$node.html(this.render());
        PageFlipper.attachTo(this.select('flipbookSelector'), {
            orientation: this.item.map.orientation,
            pages: this.item.map.pages.length / 2 + 1,
            bookLength: this.item.map.pages.length,
            width: this.$node.width(),
            direction: this.item.isRtl ? "rtl" : "ltr",
            pageWidth: this.item.dimensions.width,
            pageHeight: this.item.dimensions.height,
            parentContainer: '[data-type="preview-panel"]'
        });
    };

    this.after('initialize', function () {
        this.on(document, "setPreviewData", this.setData);
    });
}
