import React, { useEffect, useState } from 'react';
import { Row, Col, Spinner } from 'react-bootstrap';
import axios from "axios";
import DiscountCodeForm from "./Components/DiscountCodeForm";
var discountCodeUrl = "/admin/finance/discount-codes";
var DiscountCodeEditor = function (props) {
    var _a = useState(true), isDataLoading = _a[0], setDataLoading = _a[1];
    var _b = useState(undefined), discountCode = _b[0], setDiscountCode = _b[1];
    var getConfig = function () {
        if (props.discountCodeId) {
            getDiscountCode();
        }
        else {
            setDiscountCode({
                shopId: props.shopId
            });
            setDataLoading(false);
        }
    };
    var getDiscountCode = function () {
        axios.get("".concat(discountCodeUrl, "/").concat(props.discountCodeId))
            .then(function (response) {
            setDiscountCode(response.data);
            setDataLoading(false);
        }).catch(function (error) {
            alert("Error Retrieving Discount Code Data: ".concat(error));
            setDataLoading(false);
        });
    };
    useEffect(function () {
        getConfig();
    }, []);
    return (React.createElement(Row, null,
        React.createElement(Col, null,
            isDataLoading &&
                React.createElement(Spinner, { animation: "grow", variant: "primary" }),
            !isDataLoading &&
                React.createElement(DiscountCodeForm, { discountCode: discountCode }))));
};
export default DiscountCodeEditor;
