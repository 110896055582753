import mixam from '../../../boot/mixam';
import defineComponent from '../../../../components/flight/lib/component';
import DataTable from '../../table/data-table-net/data-table-net.tsx';
import WithOrdersItemsMembers from '../with-orders-items-members';
import WithOrdersItems from '../with-order-items';
import WithSignDataRequest from '../../with-sign-data-request';
import WithPublicationSchema from "./with-publication-schema";

export default defineComponent(AdminActivePublicationsManager, WithPublicationSchema, WithOrdersItemsMembers, WithOrdersItems, WithSignDataRequest);

function AdminActivePublicationsManager() {

    this.attributes({
        firstTimeUrl: `${mixam.reporterOrigin}/reporter/admin/api/shop/${mixam.shop.id}/publications/first`,
        url: `${mixam.reporterOrigin}/reporter/admin/api/shop/${mixam.shop.id}/publications`,
        dataTableSelector: '[data-type="data-table"]'
    });

    this.after('initialize', function () {
        DataTable.attachTo(this.select('dataTableSelector'));
        setTimeout(() => this.getData(), 10);
        try {
            this.subscribe(this.updateOrderLine.bind(this));
        } catch (e) {

        }
    });

}
