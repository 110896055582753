var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
export var EditEquipment = function (_a) {
    var equipment = _a.equipment, setEquipment = _a.setEquipment;
    return (React.createElement("div", { className: "p-2 d-grid gap-2", style: { gridTemplateColumns: '1fr 1fr' } },
        React.createElement("div", { className: "mb-4" },
            React.createElement("label", { htmlFor: "name", className: "form-label" }, "Name"),
            React.createElement("input", { required: true, id: "name", name: "name", type: "text", className: "form-control", value: equipment.name, onChange: function (event) { return setEquipment(__assign(__assign({}, equipment), { name: event.target.value })); } })),
        React.createElement("div", { className: "mb-4" },
            React.createElement("label", { htmlFor: "purchaseCost", className: "form-label" }, "Purchase Cost"),
            React.createElement("input", { id: "purchaseCost", name: "purchaseCost", type: "number", className: "form-control", value: equipment.purchaseCost, step: "1", onChange: function (event) { return setEquipment(__assign(__assign({}, equipment), { purchaseCost: Number(event.target.value) })); } })),
        React.createElement("div", { className: "mb-4" },
            React.createElement("label", { htmlFor: "usefulLifeMonths", className: "form-label" }, "Useful Life (months)"),
            React.createElement("input", { id: "usefulLifeMonths", name: "usefulLifeMonths", type: "number", className: "form-control", value: equipment.usefulLifeMonths, step: "1", onChange: function (event) { return setEquipment(__assign(__assign({}, equipment), { usefulLifeMonths: Number(event.target.value) })); } })),
        React.createElement("div", { className: "mb-4" },
            React.createElement("label", { htmlFor: "monthlyRuntimeHours", className: "form-label" }, "Monthly Runtime (hours)"),
            React.createElement("input", { id: "monthlyRuntimeHours", name: "monthlyRuntimeHours", type: "number", className: "form-control", value: equipment.monthlyRuntimeHours, step: "1", onChange: function (event) { return setEquipment(__assign(__assign({}, equipment), { monthlyRuntimeHours: Number(event.target.value) })); } })),
        React.createElement("div", { className: "mb-4" },
            React.createElement("label", { htmlFor: "maintenanceRatio", className: "form-label" }, "Maintenance Ratio"),
            React.createElement("input", { id: "maintenanceRatio", name: "maintenanceRatio", type: "number", className: "form-control", value: equipment.maintenanceRatio, step: "0.01", onChange: function (event) { return setEquipment(__assign(__assign({}, equipment), { maintenanceRatio: Number(event.target.value) })); } })),
        React.createElement("div", { className: "mb-4" },
            React.createElement("label", { htmlFor: "makeReadyTimeMinutes", className: "form-label" }, "Make Ready Time (minutes)"),
            React.createElement("input", { id: "makeReadyTimeMinutes", name: "makeReadyTimeMinutes", type: "number", className: "form-control", value: equipment.makeReadyTimeMinutes, step: "1", onChange: function (event) { return setEquipment(__assign(__assign({}, equipment), { makeReadyTimeMinutes: Number(event.target.value) })); } })),
        React.createElement("div", { className: "mb-4" },
            React.createElement("label", { htmlFor: "active", className: "form-label" }, "Active"),
            React.createElement("select", { id: "active", name: "active", className: "form-select", defaultValue: equipment.active.toString(), onChange: function (event) { return setEquipment(__assign(__assign({}, equipment), { active: event.target.value === 'true' })); } },
                React.createElement("option", { value: "true" }, "YES"),
                React.createElement("option", { value: "false" }, "NO")))));
};
