var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
export var Breakpoints = {
    XXL: 1800,
    XL: 1500,
    LG: 1200,
    MD: 900,
    SM: 600,
};
export var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject([""], [""])));
export var DataTableWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n\n    div:nth-child(2) {\n        overflow: initial;\n    }\n\n    .rdt_Table {\n        box-shadow: ", ";\n        transition: box-shadow 0.5s ease-in-out;\n        \n        .rdt_TableHead {\n            top: 3.5rem;\n            position: sticky;\n            z-index: 100;\n        }\n    }\n    \n    .rdt_TableCol {\n        padding-left: 10px;\n    }\n    \n    \n    // Hides the dropdown arrows on rows if the largest breakpoint has not been hit \n    @media only screen and (min-width: ", "px) {\n        .rdt_TableRow > div:first-child, .rdt_TableHeadRow > div:first-child {\n            display: none;\n        }\n    }\n    \n"], ["\n\n    div:nth-child(2) {\n        overflow: initial;\n    }\n\n    .rdt_Table {\n        box-shadow: ", ";\n        transition: box-shadow 0.5s ease-in-out;\n        \n        .rdt_TableHead {\n            top: 3.5rem;\n            position: sticky;\n            z-index: 100;\n        }\n    }\n    \n    .rdt_TableCol {\n        padding-left: 10px;\n    }\n    \n    \n    // Hides the dropdown arrows on rows if the largest breakpoint has not been hit \n    @media only screen and (min-width: ", "px) {\n        .rdt_TableRow > div:first-child, .rdt_TableHeadRow > div:first-child {\n            display: none;\n        }\n    }\n    \n"])), function (_a) {
    var highlightTable = _a.highlightTable;
    return highlightTable ? '0px 0px 10px 10px #f7f76f' : '';
}, function (_a) {
    var largestBreakpoint = _a.largestBreakpoint;
    return largestBreakpoint;
});
export var Thumbnail = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    cursor: pointer;\n    max-width: 4rem;\n    margin: 0.5rem 0;\n    img {\n        width: 100%;\n    }\n"], ["\n    cursor: pointer;\n    max-width: 4rem;\n    margin: 0.5rem 0;\n    img {\n        width: 100%;\n    }\n"])));
export var BSIcon = styled.i(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    font-size: 1.5rem;\n"], ["\n    font-size: 1.5rem;\n"])));
export var ExpandTable = styled.table(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n    margin: 1rem;\n    td {\n        padding: 0.1rem 1rem 0.1rem 0;\n    }\n\n    // media queries to show/hide rows depending on which columns of main table are hidden\n    .hide-", " {\n        @media only screen and (min-width: ", "px) {\n            display: none;\n        }\n    }\n\n    .hide-", " {\n        @media only screen and (min-width: ", "px) {\n            display: none;\n        }\n    }\n\n    .hide-", " {\n        @media only screen and (min-width: ", "px) {\n            display: none;\n        }\n    }\n\n    .hide-", " {\n        @media only screen and (min-width: ", "px) {\n            display: none;\n        }\n    }\n\n    .hide-", " {\n        @media only screen and (min-width: ", "px) {\n            display: none;\n        }\n    }\n\n    .hide-undefined {\n        display: none;\n    }\n"], ["\n    margin: 1rem;\n    td {\n        padding: 0.1rem 1rem 0.1rem 0;\n    }\n\n    // media queries to show/hide rows depending on which columns of main table are hidden\n    .hide-", " {\n        @media only screen and (min-width: ", "px) {\n            display: none;\n        }\n    }\n\n    .hide-", " {\n        @media only screen and (min-width: ", "px) {\n            display: none;\n        }\n    }\n\n    .hide-", " {\n        @media only screen and (min-width: ", "px) {\n            display: none;\n        }\n    }\n\n    .hide-", " {\n        @media only screen and (min-width: ", "px) {\n            display: none;\n        }\n    }\n\n    .hide-", " {\n        @media only screen and (min-width: ", "px) {\n            display: none;\n        }\n    }\n\n    .hide-undefined {\n        display: none;\n    }\n"])), Breakpoints.XXL, Breakpoints.XXL, Breakpoints.XL, Breakpoints.XL, Breakpoints.LG, Breakpoints.LG, Breakpoints.MD, Breakpoints.MD, Breakpoints.SM, Breakpoints.SM);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
